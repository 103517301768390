import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLoyaltyStepFour = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={16} cy={16} r={16} fill="#16796F" fillOpacity={0.1} />
    <g clipPath="url(#loyalty-step-four_svg__a)">
      <path
        d="m17.956 9.904 4.14 4.14a1.752 1.752 0 0 1 .387 1.89l-2.072 5.181a1.752 1.752 0 0 1-1.406 1.087l-4.45.556c-.115.014-.235.03-.36.05l-.39.062-.202.037-.634.123-.434.092-.652.147-.837.2-1.36.347-.566.152a.884.884 0 0 1-1.109-.993l.03-.128.258-.975.232-.918.2-.837.146-.653.092-.434.123-.633.07-.4.057-.375.578-4.627a1.753 1.753 0 0 1 1.087-1.406l5.182-2.073a1.753 1.753 0 0 1 1.89.388Zm-3.251 4.911a1.752 1.752 0 0 0-.444 1.725.879.879 0 0 0-.176.134L10.53 20.23a.175.175 0 0 0-.046.086l-.078.338-.156.65-.075.308a.175.175 0 0 0 .214.214l.628-.154.668-.155a.176.176 0 0 0 .085-.048l3.556-3.556a.875.875 0 0 0 .135-.174 1.752 1.752 0 1 0-.754-2.924h-.001Zm4.082-6.558a.876.876 0 0 1 1.156-.073l.082.073 3.718 3.717a.875.875 0 0 1-1.156 1.313l-.084-.074-3.716-3.717a.876.876 0 0 1 0-1.239Z"
        fill="#16796F"
      />
    </g>
    <defs>
      <clipPath id="loyalty-step-four_svg__a">
        <path fill="#fff" transform="translate(5 5)" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLoyaltyStepFour;
