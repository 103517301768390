const KEYS = {
  SPLICE_BVN_RECIPIENT: 'splice:bvn_recipient',
  SPLICE_BVN_NUMBER: 'splice:bvn_number',
  SPLICE_BANK_RECIPIENT: 'splice:bank_recipient',
};

export const usePaymentSettings = () => {
  const addBvnRecipient = (recipient: string, bvn: string) => {
    localStorage.setItem(KEYS.SPLICE_BVN_RECIPIENT, recipient);
    localStorage.setItem(KEYS.SPLICE_BVN_NUMBER, bvn);
  };

  const getBvnRecipient = () => {
    return localStorage.getItem(KEYS.SPLICE_BVN_NUMBER);
  };

  const addBankRecipient = (recipient: string) => {
    localStorage.setItem(KEYS.SPLICE_BANK_RECIPIENT, recipient);
  };

  const getBankRecipient = () => {
    return localStorage.getItem(KEYS.SPLICE_BANK_RECIPIENT);
  };

  const removeBvn = () => {
    localStorage.removeItem(KEYS.SPLICE_BVN_RECIPIENT);
    localStorage.removeItem(KEYS.SPLICE_BVN_NUMBER);
  };

  const removeBankRecipient = () => {
    localStorage.removeItem(KEYS.SPLICE_BANK_RECIPIENT);
  };

  return {
    addBvnRecipient,
    getBvnRecipient,
    addBankRecipient,
    getBankRecipient,
    removeBvn,
    removeBankRecipient,
  };
}