import * as React from "react";
import { Skeleton } from "../..";

const accountSetupArrayShimmer = () => {
  return (
    <>
      {
        [...Array(4)].map((_, index) => {
          return (
            <div className='flex flex-col items-start py-4 px-2 space-y-4 border-b border-grey-20' key={index}>
              <Skeleton width="100px" height="10px" />
              <Skeleton width="100%" height="10px" />
            </div>
          )
        })
      }
    </>
  )
}

export default accountSetupArrayShimmer