import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNegativeIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.491 0c4.383 0 8.003 3.594 8.003 7.934C16.49 12.53 13.03 16.012 8.48 16 3.928 15.988.475 12.47.499 7.892.518 3.578 4.142 0 8.49 0Zm-.012 14.004c3.284.01 5.973-2.615 5.984-5.836.01-3.524-2.49-6.11-5.917-6.122-3.427-.012-6.02 2.567-6.029 5.966a5.96 5.96 0 0 0 5.963 5.992H8.48Z"
      fill="#E62347"
    />
    <path
      d="M10.332 10.89c-.552-.055-.94-.174-1.297-.113a5.458 5.458 0 0 0-1.604.489c-.675.352-1.241.358-1.58-.147-.357-.539-.182-1.123.486-1.545 1.29-.813 2.672-1.082 4.155-.652.587.17.852.615.75 1.173a.92.92 0 0 1-.91.795ZM6.473 5.02a1.034 1.034 0 0 1 1.041 1.002 1.085 1.085 0 0 1-.99 1.01 1.03 1.03 0 0 1-.968-1.356.97.97 0 0 1 .917-.656ZM10.466 7.022a1.04 1.04 0 0 1-1.009-1.04 1.058 1.058 0 0 1 1.021-.966.999.999 0 0 1 .689 1.724c-.189.18-.44.282-.7.282Z"
      fill="#E62347"
    />
  </svg>
);
export default SvgNegativeIcon;
