/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { RedeemPackageVoucherModalProps, RedeemVoucherNoteFormInput } from '../types';
import { convertAvailabilitiesToCalendarFormat, convertVoucherServicesIdsToString, formatVouchersInToOptions } from './utils';
import { Controller, useForm } from 'react-hook-form';
import { convertVoucherServicesNamesToString, convertVoucherServicesToAppointmentStaffService, hasHomeAsLocation } from '../../bookingSales/utils';
import { convertFullDateStringToDate, formatInToPrice } from '../../../utils/misc';
import DateRangeCarouselShimmer from '../../../ui/organism/dateRangeCarousel/DateRangeCarouselShimmer';
import DateRangeCarousel from '../../../ui/organism/dateRangeCarousel/DateRangeCarousel';
import Paragraph from '../../../ui/atoms/paragraph/Paragraph';
import { PAYMENT_TYPE_NAME_AND_VALUE } from '../../bookingSales/constants';
import { Modal } from '../../../ui/templates/modal/Modal';
import Button from '../../../ui/atoms/button/Button';
import SvgArrowBack from '../../../ui/icons/ArrowBack';
import SvgChevronLeft from '../../../ui/icons/ChevronLeft';
import { COLORS } from '../../../constants/colors';
import SvgGiftCardAmico from '../../../ui/icons/GiftCardAmico';
import Heading from '../../../ui/atoms/heading/Heading';
import SingleSelect from '../../../ui/molecules/singleSelect/SingleSelect';
import SvgSalon from '../../../ui/icons/Salon';
import { convertVoucherServicesNamesToStringToShowNonRedeemed } from '../Utils';
import Checkbox from '../../../ui/atoms/checkbox/Checkbox';
import Input from '../../../ui/molecules/input/Input';
import { API_ERRORS, ERRORS } from '../../../constants/errors';
import { getHelpTextForCharacterLeft, getHelperTextForReactHookFormErrors } from '../../../utils/form';
import FormTextarea from '../../../ui/molecules/input/FormTextarea';
import { CreateAppointmentDocument, PackageVoucher, Salon, SalonAvailabilityDocument } from '../../../graphql/generated';
import { print } from 'graphql'
import { generateDateRangeArray } from '../../utils';
import { logger } from '../../../core/logger';
import { BookingVoucherService } from '../../../modals/types';
import { SvgGreyMinus, SvgGreyPlus, SvgInfo } from '../../../ui/icons';

const RedeemVoucherModal = ({
  closeModal,
  addToast,
  voucherPackage,
  vouchers,
  isVisible,
  refetch,
  customerInfo
}: RedeemPackageVoucherModalProps) => {
  const token = localStorage.getItem('token');
  const [selectedVoucher, setSelectedVoucher] = useState<PackageVoucher | null>(null);
  const [selectedSalon, setSelectedSalon] = useState<Salon | null>(null);
  const [appointmentDateTime, setAppointmentDateTime] = useState<string | null>(null);
  const [voucherRedeemed, setVoucherRedeemed] = useState<boolean>(false);
  const [createAppointmentIsLoading, setCreateAppointmentIsLoading] = useState(false)
  const [loadDateRangeInitialShimmer, setLoadDateRangeInitialShimmer] = useState(false);
  const [voucherServices, setVoucherServices] = useState<BookingVoucherService[]>([])
  const [canGoNext, setCanGoNext] = useState(false)
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors: errors },
  } = useForm<RedeemVoucherNoteFormInput>({
    defaultValues: {
      isHomeService: hasHomeAsLocation(selectedSalon?.locationType),
    }
  });

  const voucherOptions = formatVouchersInToOptions(vouchers || [])

  const [currentDate, setCurrentDate] = useState(new Date());
  const [daySlots, setDaySlots] = useState<DateTimeRangeProps | null>(null);
  const [days, setDays] = useState<string[]>([]);
  const [day, setDay] = useState<string | null>(null);

  useEffect(() => {
    if (voucherPackage) {
      setSelectedVoucher(voucherPackage);
    }
  }, [voucherPackage])

  useEffect(() => {
    const generateDays = generateDateRangeArray(currentDate, addDays(currentDate, 180));
    setDays(generateDays);
    setDay(generateDays[0]);
  }, [])

  const getServicesAvailability = async ({ startDate, endDate }: {
    startDate: string,
    endDate: string,
  }) => {
    try {
      setLoadDateRangeInitialShimmer(true)
      axios
      .post(
        '/graphql',
        {
          query: print(SalonAvailabilityDocument),
          variables: {
            startDate, endDate, services: convertVoucherServicesIdsToString(voucherServices) as [], salonId: selectedSalon?.id
          }
        },
      )
        .then((res) => {
          setLoadDateRangeInitialShimmer(false)
        const {
          data: {
            data: { salonAvailability }
          }
        } = res

        if (salonAvailability) {
          const slots = convertAvailabilitiesToCalendarFormat(salonAvailability);
          setDaySlots(slots[0]);
        } else {
          addToast({
            message: API_ERRORS.FAILED_TO_FETCH_SALON_AVAILABILITY,
            variant: 'error'
          })
        }
      })
    } catch (error) {
      setLoadDateRangeInitialShimmer(false)
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message || API_ERRORS.FAILED_TO_FETCH_SALON_AVAILABILITY;
        addToast({
          variant: "error",
          message,
        });
      }
    }
  }

  const selectAvailableTimeSlot = async (date: string) => {
    setAppointmentDateTime(date);
  }

  const getDateRangeCarousel = () => {
    if (Array.isArray(days) && days.length > 0 && canGoNext) {
      return (
        <DateRangeCarousel days={days} daySlots={daySlots} setDay={setDay} isLoading={loadDateRangeInitialShimmer} selectAvailableTimeSlot={selectAvailableTimeSlot} selectedDate={appointmentDateTime} />
      )
    }

    return (
      <Paragraph className='text-center'>No available time slots</Paragraph>
    )
  }

  const chooseVoucher = (e: {
    label: string,
    value: string,
  }) => {
    const voucher = vouchers?.find(voucher => voucher.id === e.value) || null;
    if (!voucher) return;
    setSelectedVoucher(voucher);
    const formattedVoucherServices = Array.isArray(voucher?.voucherServices) ? voucher?.voucherServices.map(service => ({ 
      ...service, 
      cartQuantity: service?.quantity - (service?.redeemedCount || 0)
    })) : [];
    setVoucherServices(formattedVoucherServices)
  }

  const redeemVoucher = async (input: RedeemVoucherNoteFormInput) => {
    if (!selectedVoucher) return;
    if (!customerInfo) return;
    if (!appointmentDateTime) return;
    const appointmentData = {
      clientId: customerInfo?.id,
      startAt: appointmentDateTime,
      services: convertVoucherServicesToAppointmentStaffService(voucherServices),
      note: '',
      appointmentPaymentMethod: PAYMENT_TYPE_NAME_AND_VALUE.VOUCHER.value,
      appointmentType: 'online',
      source: "public",
      locationType: input?.isHomeService ? 'home' : 'salon',
      ...(input?.isHomeService && { address: input?.address }),
      paymentType: 'full_payment',
      voucherId: selectedVoucher?.id, 
      voucherRedemptionMode: 'services',
    }

    try {
      // logger.info(`Redeeming a voucher: ${JSON.stringify(appointmentData)}`);
      await axios.post(
        '/graphql',
        {
          query: print(CreateAppointmentDocument),
          variables: { input: { ...appointmentData, salonId: selectedSalon?.id } },
        }
      ).then(({ data }) => {
        setCreateAppointmentIsLoading(false)
        if (data?.data?.createAppointment?.status === 201) {
          const responseData = data?.data?.createAppointment.appointment;
          if (responseData) {
            setVoucherRedeemed(true);
            refetch()
          }
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.APPOINTMENT_CREATION_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }


        if (data?.data?.createAppointment?.errors?.length) {
          const message = data?.data?.createAppointment?.errors[0]?.message || API_ERRORS.APPOINTMENT_CREATION_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (appointmentError) {
      if (axios.isAxiosError(appointmentError)) {
        const message = appointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_CREATION_FAILED;
        addToast({
          variant: "error",
          message,
        });
        logger.error(`Failed to redeem a voucher: customer-dashboard: ${message}`)
        logger.exception(appointmentError as Error);
      }
    }
  }

  const resetInput = () => {
    setValue('isHomeService', false);
    setValue('address', '');
    setSelectedVoucher(null)
    setSelectedSalon(null)
    setAppointmentDateTime(null)
  }

  const closeRedeemModal = () => {
    resetInput()
    closeModal()
  }

  const getRedeemedServicesData = () => {
    let redeemed = '';
    const voucherServices = selectedVoucher?.voucherServices;
    for (let i = 0; i < voucherServices.length; i++) {
      const service = voucherServices[i];
      if (service?.isRedeemed) {
        redeemed += service?.name + ' x' + service?.quantity + ', ';
      } else if (service?.redeemedCount && service?.redeemedCount !== 0) {
        redeemed += service?.name + ' x' + service?.redeemedCount + ', ';
      }
    }
    return redeemed ? 'You have redeemed ' + redeemed : "";
  }

  const modifyServiceInCart = (serviceId: string, type: 'add' | 'remove') => {
    const newVoucherServices = voucherServices.map((service) => {
      const qty = service?.cartQuantity || service?.quantity - (service?.redeemedCount || 0);
      if (service.serviceId === serviceId) {
        if (type === 'add') {
          service.cartQuantity = qty + 1;
        } else if (type === 'remove' && qty !== 0) {
          service.cartQuantity = qty - 1;
        }
      }
      return service;
    });
    setVoucherServices(newVoucherServices);
  }

  const chooseService = (serviceId: string) => {
    const newVoucherServices = voucherServices.map((service) => {
      if (service.serviceId === serviceId) {
        service.selected = !service.selected;
      }
      return service;
    });
    setVoucherServices(newVoucherServices);
  }

  useEffect(() => {
    let canGo = false;
    voucherServices.forEach((service) => {
      if (service?.selected) {
        canGo = true;
      }
    });
    setCanGoNext(canGo);
  }, [voucherServices])

  useEffect(() => {
    if (day && Array.isArray(voucherServices) && voucherServices?.length) {
      getServicesAvailability({
        startDate: day,
        endDate: day,
      });
    }
  }, [day])

  const chooseServices = () => {
    if (selectedVoucher && selectedSalon && canGoNext) {
      getServicesAvailability({
        startDate: day,
        endDate: day,
      });
    }
  }

  return (
    <Modal
      show={isVisible}
      closeModal={closeRedeemModal}
      variant='right'
    >
      <div className='w-full relative my-[80px]'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>Voucher</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          {voucherRedeemed ? (
            <div className='w-full flex flex-col xl:flex-row justify-center pt-[30px] xl:pt-[60px] gap-4'>
              <div className='w-full h-fit flex flex-col items-center xl:max-w-[70%] border border-grey-50 rounded-xl py-8 px-6 gap-8 shadow-md'>
                <SvgGiftCardAmico height="100%" className="w-[250px]" />
                <Heading variant='h1' size='h6' weight='bold' color={COLORS.BLACK} className='text-center'>Voucher redeemed successfully</Heading>
                <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                  Your {selectedVoucher?.package?.name} Voucher with value of {selectedVoucher ? formatInToPrice(selectedVoucher?.price) : ''} at {selectedVoucher?.business?.name} was redeemed successfully. A confirmation email will be sent shortly.
                </Paragraph>

                <div className='w-full flex flex-col space-y-2'>
                  <Paragraph size='b5' weight='semiBold' color={COLORS.GREY['300']}>VOUCHER REDEMPTION SUMMARY</Paragraph>
                  {voucherServices && Array?.isArray(voucherServices) && voucherServices?.filter(service => service?.selected)?.map((service) => (
                    <div className='w-full flex justify-between items-center py-2' key={service?.id}>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>{service?.name} x{service?.cartQuantity}</Paragraph>
                    </div>
                  ))}
                </div>
                <Button
                  variant='primary'
                  className=''
                  size='lg'
                  rounded='lg'
                  onClick={() => {
                    setVoucherRedeemed(false)
                    closeRedeemModal()
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className='w-full flex flex-col md:flex-row items-center justify-between'>
                <div className="flex flex-col space-y-6">
                  <Heading variant='h1' size='h9' weight='semiBold'>Redeem Voucher</Heading>
                  <Paragraph size="b4">Choose date and time here</Paragraph>
                </div>
                <div className='flex space-x-4'>
                  <div className='flex flex-col space-y-6'>
                    <Heading variant='h1' size='h9' weight='bold'>{selectedVoucher?.business?.name} <span className='italic text-b4'>{selectedSalon?.branchName}</span></Heading>
                    {selectedSalon ? (<Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{selectedSalon?.address}, {selectedSalon?.city}, {selectedSalon?.state}</Paragraph>) : null}
                  </div>
                  {selectedVoucher?.business?.logoUrl ? (
                    <img
                      src={selectedVoucher?.business?.logoUrl}
                      alt="business logo"
                      className="w-full max-w-[80px]"
                      loading="lazy"
                    />
                  ) : null}
                </div>
              </div>
              <div className='w-full flex flex-col space-y-4'>
                <div className='flex w-full space-y-4 flex-col'>
                  <Paragraph size="b5" color={COLORS.GREY[300]} weight='bold' className='uppercase'>Choose voucher</Paragraph>
                  <SingleSelect
                    selected={selectedVoucher ? { label: selectedVoucher?.package?.name, value: selectedVoucher?.id } as unknown as [] : []}
                    options={voucherOptions}
                    setSelected={chooseVoucher}
                  />
                </div>
                {selectedVoucher ? (
                  <div className='flex w-full space-y-4 flex-col'>
                    <Paragraph size="b5" color={COLORS.GREY[300]} weight='bold' className='uppercase'>Select Location</Paragraph>
                    <div className='w-full flex flex-wrap gap-4'>
                      {Array.isArray(selectedVoucher?.business?.salons) && selectedVoucher?.business?.salons?.length && selectedVoucher?.business?.salons.map((salon) => {
                        return (
                          <div key={salon?.id} className={`flex bg-grey-50 border ${salon?.id === selectedSalon?.id ? 'border-grey-300' : 'border-grey-50'}  rounded-md p-3 space-x-3 cursor-pointer`} onClick={() => setSelectedSalon(salon)}>
                            <SvgSalon width="40px" height="40px" />
                            <div className='flex flex-col space-y-2'>
                              <Paragraph size="b5" color={COLORS.BLACK}>{salon?.branchName}</Paragraph>
                              <Paragraph size="b5" color={COLORS.GREY[300]}>{salon?.address}</Paragraph>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ) : null}
                {selectedVoucher && selectedSalon ? (
                  <div className='w-full flex flex-col md:flex-row gap-4'>
                    <div className='w-full flex flex-col gap-4'>
                      <div className='w-full h-fit flex flex-col border border-grey-20 gap-6 p-4 rounded-md'>
                        <Paragraph size='b5' weight='semiBold' color={COLORS.GREY['300']}>VOUCHER SUMMARY</Paragraph>
                        
                        <div className='w-full flex items-start justify-start gap-4 p-4 border border-grey-100 bg-grey-50 rounded-md'>
                          <SvgInfo width="20px" height="20px" />
                          <div className="flex flex-col space-y-2">
                            <Paragraph size="b5" color={COLORS.GREY[300]}>This {selectedVoucher?.package?.name} contains {convertVoucherServicesNamesToString(selectedVoucher?.voucherServices)}. {getRedeemedServicesData()}</Paragraph>
                          </div>
                        </div>  
                        <Paragraph size="b5" color={COLORS.GREY[400]}>Choose the services you want to redeem</Paragraph>

                        <div className='w-full flex flex-col space-y-6'>
                          {Array.isArray(voucherServices) && voucherServices.map((voucherService, index) => {
                            const quantity = voucherService?.cartQuantity || voucherService?.quantity - (voucherService?.redeemedCount || 0);
                            const orginalQty = voucherService?.quantity - (voucherService?.redeemedCount || 0)
                            return (
                              <div className='w-full flex justify-between' key={index}>
                                <div className='flex items-center space-x-4 cursor-pointer' onClick={!voucherService?.isRedeemed ? () => chooseService(voucherService?.serviceId) : undefined}>
                                  <Checkbox isChecked={voucherService?.selected} disabled={voucherService?.isRedeemed} />
                                  <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{voucherService?.name}</Paragraph>
                                </div>
                                {!voucherService?.isRedeemed ? (
                                  <div className="w-fit flex space-x-2 items-end">
                                    <div className="w-full flex gap-2">
                                      <Button
                                        variant='icon'
                                        size="none"
                                        type="button"
                                        className="border-0"
                                        onClick={() => modifyServiceInCart(voucherService.serviceId, 'add')}
                                        disabled={orginalQty === quantity}
                                      >
                                        <SvgGreyPlus width="22px" height="22px" />
                                      </Button>
                                      <div className="flex border border-grey-100 py-1 px-3 justify-center h-[22px] items-center">
                                        <Paragraph size='b6' weight='normal' color={COLORS.BLACK}>{quantity}</Paragraph>
                                      </div>
                                      <Button
                                        variant='icon'
                                        size="none"
                                        type="button"
                                        className="border-0"
                                        onClick={() => modifyServiceInCart(voucherService.serviceId, 'remove')}
                                        disabled={quantity === 1}
                                      >
                                        <SvgGreyMinus width="22px" height="22px" />
                                      </Button>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            )
                          })}
                        </div>

                        <Button
                          variant='primary'
                          className=''
                          size='lg'
                          rounded='lg'
                          disabled={!canGoNext}
                          type='button'
                          onClick={chooseServices}
                        >
                          Continue
                        </Button>
                      </div>
                      {appointmentDateTime ? (
                        <form onSubmit={handleSubmit(redeemVoucher)} className="w-full space-y-6" autoComplete='off'>
                          {selectedSalon?.locationType && hasHomeAsLocation(selectedSalon?.locationType) && (
                            <>
                              <Controller
                                control={control}
                                name="isHomeService"
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <div className="w-full flex flex-col space-y-4">
                                      <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                                        <div
                                          className="flex"
                                          onClick={() => onChange(!value)}
                                        >
                                          <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                            <Checkbox
                                              isChecked={value}
                                            />
                                            <span className="text-grey-900 whitespace-nowrap">
                                              Are you booking a home service?
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }}
                              />
                              {watch('isHomeService') || hasHomeAsLocation(selectedSalon?.locationType) ? (
                                <Input name="address" label="Home Address" id="home-address" type="text" placeholder='Enter home address' control={control} rules={{
                                  required: watch('isHomeService') ? ERRORS.HOME_ADDRESS_REQUIRED : false,
                                }} error={errors.address} />
                              ) : null}
                            </>
                          )}
                          <Controller
                            control={control}
                            name="note"
                            render={({
                              field: { onChange, onBlur, name, ref, value },
                              formState: { errors },
                            }) => {
                              const maxLength = 400;
                              const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                                errors?.note?.message as string
                              );
                              const helperTexts = [];
        
                              helperTexts.push(getHelpTextForCharacterLeft(maxLength, value));
                              if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts);
        
                              return (
                                <FormTextarea
                                  type="text"
                                  id="note"
                                  label="Note"
                                  placeholder="Add a note"
                                  {...{
                                    ref,
                                    name,
                                    value,
                                    onChange,
                                    onBlur,
                                    maxLength,
                                  }}
                                  helperText={helperTexts}
                                />
                              );
                            }}
                          />
                          <Button
                            variant='primary'
                            size="lg"
                            rounded='lg'
                            loading={createAppointmentIsLoading}
                            disabled={createAppointmentIsLoading}
                            className="border-0"
                          >
                            Redeem voucher
                          </Button>
                        </form>
                      ) : null}
                    </div>
                    <div className='w-full flex flex-col border border-grey-20 space-y-6 p-4 rounded-md'>
                      {canGoNext ? getDateRangeCarousel() : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default RedeemVoucherModal