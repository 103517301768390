import { useState } from "react";

export const useModal = ({ defaultValue = false } = {}) => {
  const [isVisible, setVisibility] = useState(defaultValue);

  const openModal = () => {
    setVisibility(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setVisibility(false);
    document.body.style.overflow = "unset";
  };

  return { isVisible, openModal, closeModal };
}