import React, { useEffect, useState } from 'react'
import { Button, CalendarPicker, Heading, Layout, Paragraph, ReportTable, SearchTerm, Skeleton, TableFilterProps, Tabs, ToastWrapper } from '../ui';
import { convertDateTimeStringToTimeZone, createFileNameFromDates, formatDateToOriginalDate, formatInToPrice, formatTableHeadersFilterArray, getDefaultQueryDates, getNumberMonthAndYearFromDate, handleDownload, handleExcelDownload } from '../utils/misc';
import { checkReportsTokenFromRoute } from '../utils/token';
import { useModal, useToast } from '../hooks';
import { COLORS } from '../constants/colors';
import axios from 'axios';
import { print } from 'graphql'
import Chart from 'react-google-charts'
import { LoyaltyOverviewData, LoyaltyOverviewDataDocument, LoyaltyProgram, LoyaltyProgramDocument, LoyaltySalesReport, LoyaltySalesReportDocument } from '../graphql/generated';
import { getClientRedeemedRewardFromTable } from '../uicomponents/utils';
import LoyaltyRedeemedDetails from '../uicomponents/LoyaltyRedeemedDetails';
import { LOYALTY_MEMBER_HEADINGS, LOYALTY_MEMBER_HEADINGS_MOBILE } from '../constants/information';
import FullTable from '../ui/organism/table/Table';
import { SvgArrowUpBox, SvgCalendar, SvgGreenSignal, SvgLoyaltyPinIcon } from '../ui/icons';
import LoyaltyCard from '../ui/molecules/loyaltyCard/LoyaltyCard';
import { SummaryData } from '../uicomponents/homeComponents/types';
import ExportDataToCsvOrXlsx from '../uicomponents/ExportDataToCsvOrXlsx';

const LOYALTY_REPORTS_TAB_NAME = {
  LOYALTY_OVERVIEW: "Loyalty overview",
  LOYALTY_LIST: "Loyalty list",
  REWARDS_REDEEMED: "Rewards redeemed",
};

const LOYALTY_REPORTS_TABS = [
  {
    key: LOYALTY_REPORTS_TAB_NAME.LOYALTY_OVERVIEW,
    title: LOYALTY_REPORTS_TAB_NAME.LOYALTY_OVERVIEW,
  }, {
    key: LOYALTY_REPORTS_TAB_NAME.REWARDS_REDEEMED,
    title: LOYALTY_REPORTS_TAB_NAME.REWARDS_REDEEMED,
  }
]

const LoyaltyReports = () => {
  const [activeTab, setActiveTab] = useState(LOYALTY_REPORTS_TAB_NAME.LOYALTY_OVERVIEW);

  const getTabView = () => {
    switch (activeTab) {
      case LOYALTY_REPORTS_TAB_NAME.LOYALTY_OVERVIEW:
        return <LoyaltyOverview />
      default:
        return <RewardsRedeemedReport />
    }
  }

  return (
    <Layout
      pageTitle={
        activeTab
      }
      pageLevel={3}
    >
      <Tabs
        tabs={LOYALTY_REPORTS_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className='w-full pb-20'>
        {getTabView()}
      </div>
    </Layout>
  )
}

const LoyaltyCardShimmer = () => {
  return (
    <div className='flex flex-wrap w-full gap-10'>
      {[1, 2, 3].map((item) => (
        <div
          className='w-full xl:max-w-[268px] flex flex-col p-6 space-y-6 border border-grey-100 rounded-md'
          key={item}
        >
          <Skeleton width='40px' height='40px' />
          <Skeleton width='80%' height='1.5rem' />
          <div className='flex flex-col space-y-2'>
            <Skeleton width='70%' height='1rem' />
            <Skeleton width='80%' height='1rem' />
          </div>
          <Skeleton width='4rem' height='2rem' />
        </div>
      ))}
    </div>
  )
}

const LoyaltyOverview = () => {
  const {addToast, toast} = useToast()
  const token = checkReportsTokenFromRoute()
  const [loading, setLoading] = useState(false)
  const [loyaltyOverview, setLoyaltyOverview] =
    useState<LoyaltyOverviewData>(null)
  const [loyalty, setLoyalty] = useState<LoyaltyProgram | null>(null)
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('')
  const [memberHeadings, setMemberHeadings] = useState(LOYALTY_MEMBER_HEADINGS)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingLoyaltyOverviewData, setLoadingLoyaltyOverviewData] =
    useState(false)
  
  const getLoyalty = () => {
    axios
      .post(
        '/graphql',
        { query: print(LoyaltyProgramDocument) },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { loyaltyProgram: l }
          }
        } = res
          setLoyalty(l)
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }
  const getLoyaltyAsync = async () => {
    setLoading(true)
    await getLoyalty()
    await getOverviewReview()
    setLoading(false)
  }

  useEffect(() => {
    getLoyaltyAsync()
  }, [])

  const getOverviewReview = async() => {
    setLoadingLoyaltyOverviewData(true)
    axios
      .post(
        '/graphql',
        {
          query: print(LoyaltyOverviewDataDocument),
          variables: {
            startDate: formatDateToOriginalDate(selectedDates[0], 'start'),
            endDate: formatDateToOriginalDate(selectedDates[1], 'end')
          }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { loyaltyOverviewData }
          }
        } = res
        setLoyaltyOverview(loyaltyOverviewData)
        setLoadingLoyaltyOverviewData(false)
      })
      .catch((err) => {
        setLoadingLoyaltyOverviewData(false)
        addToast({ message: err.message, variant: 'error' })
      })
  }

  useEffect(() => {
    getOverviewReview()
  }, [selectedDates])

  const chartData: SummaryData = [
    ['Status', 'Count'],
    ['Total issued', loyaltyOverview?.redemptionRate?.totalIssued],
    ['Total redeemed', loyaltyOverview?.redemptionRate?.totalRedeemed]
  ]
  const chartOptions = {
    pieHole: 0.4,
    is3D: false,
    pieSliceText: 'none',
    colors: ['#E2E2E2', '#7EA05C']
  }

  const generateMemberTableData = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    members: any,
    tableHeadings: TableFilterProps
  ) => {
    return members?.map?.((member) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = member?.name
              break
            case 'spendValue':
              rowData[heading.value] = '>' + formatInToPrice(member?.spendValue)
              break
            case 'pointsEarned':
              rowData[heading.value] = member?.pointsEarned
              break
            case 'discountValue':
              rowData[heading.value] = member?.discountValue
              break
          }
        }
      })
      return rowData
    })
  }

  const membersData = generateMemberTableData(
    loyaltyOverview?.topMembers,
    memberHeadings
  )

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in clientHeadings then update the show
    const updatedHeadings = memberHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setMemberHeadings(updatedHeadings)
  }

  const getLoyaltyContent = () => {
    if (loading) {
      return (
        <div className='flex flex-col space-y-6 p-6'>
          <LoyaltyCardShimmer />
        </div>
      )
    } else if (loyalty) {
      return (
        <div className='flex flex-col space-y-6'>
          <div className='flex flex-col w-full'>
            <div className='w-full flex flex-col lg:flex-row p-4 gap-4'>
              <div className='w-full max-w-[500px] flex flex-col space-y-4'>
                <div className='w-full flex justify-between p-4 border border-grey-100 rounded-md'>
                  <div className='flex space-x-4 items-start'>
                    <SvgLoyaltyPinIcon width="20px" height="20px" />
                    <div className='flex flex-col'>
                      <Paragraph size='b5' weight='normal' color={COLORS.BLACK}>Loyalty program status</Paragraph>
                      <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]}>Track your loyalty program status</Paragraph>
                    </div>
                  </div>

                  <div className="flex items-center space-x-2 rounded-full border border-grey-100 bg-grey-50 py-2 px-4">
                    <SvgGreenSignal />
                    <Paragraph size='b4' weight='semiBold' color={COLORS.GREEN[300]} className=''>Active</Paragraph>
                  </div>
                </div>
                <LoyaltyCard loyalty={loyalty} />
              </div>
              <div className='w-full lg:max-w/1/2 flex flex-col space-y-4'>
                <div className='w-full flex flex-col border border-grey-50 p-4 rounded-md'>
                  <Paragraph size='b4' color={COLORS.GREY[900]}>
                    Redemption rate
                  </Paragraph>
                  <Chart
                    chartType='PieChart'
                    width='100%'
                    height='100%'
                    data={chartData}
                    options={chartOptions}
                  />
                </div>
                <div className='w-full flex justify-between items-center border border-grey-50 p-4 rounded-md'>
                  <div className='flex space-x-3'>
                    <SvgArrowUpBox width='28px' height='28px' />
                    <div className=''>
                      <Paragraph size='b4' color={COLORS.GREY[900]}>
                        Top service purchased{' '}
                      </Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY[400]}>
                        Service with the highest amount of purchase
                      </Paragraph>
                    </div>
                  </div>
                  <Paragraph size='b1' color={COLORS.GREY[900]} weight='semiBold'>
                    {loyaltyOverview?.topServicePurchased}
                  </Paragraph>
                </div>
              </div>
            </div>
            <div className='w-full flex justify-between px-4 py-3 border-t border-grey-50'>
              <div className='w-full lg:max-w-[50%] flex space-x-3 items-center'>
                <SearchTerm setDebouncedSearchQuery={setDebouncedSearchQuery} />
              </div>
            </div>
            <FullTable
              headers={formatTableHeadersFilterArray(memberHeadings)}
              mobileHeaders={LOYALTY_MEMBER_HEADINGS_MOBILE}
              rows={membersData}
              tableOptions={{
                view: true,
                edit: true,
                delete: true,
                duplicate: false
              }}
            />
          </div>
        </div>
      )
    }
    
    return null
  }
  return (
    <>
      <ToastWrapper toast={toast} />
      <div className='w-full flex flex-col md:flex-row justify-between md:items-center p-3 md:p-6'>
        <div className='flex flex-col space-y-2'>
          <Heading size="h11" variant='h1' weight='bold'>Overview</Heading>
          <Paragraph size="b4" className='' color="text-grey-300">Find your total programs here</Paragraph>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
        </div>
      </div>
      {getLoyaltyContent()}
    </>
  )
}

const RewardsRedeemedReport = () => {
  const {addToast, toast} = useToast()
  const token = checkReportsTokenFromRoute()
  const [loadingLoyaltyRewardsData, setLoadingLoyaltyRewardsData] = useState(false)
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getDefaultQueryDates());
  const [loyaltyRewards, setLoyaltyRewards] = useState<LoyaltySalesReport[]>([])
  const [redeemedReward, setRedeemedReward] = useState<LoyaltySalesReport>(null)
  const Headers = [
    { name: "Services/Products" },
    { name: "Client" },
    { name: "Date reached" },
    { name: "Location" },
    { name: "Staff" },
    { name: "Promo value" }
  ];

  const getLoyaltyRedeemedApi = () => {
    const variables = {
      startDate: formatDateToOriginalDate(selectedDates[0], "start"),
      endDate: formatDateToOriginalDate(selectedDates[1], "end")
    }

    axios
      .post(
        '/graphql',
        {
          query: print(LoyaltySalesReportDocument),
          variables: variables
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { loyaltySalesReport }
          }
        } = res
        setLoyaltyRewards(loyaltySalesReport)
        setLoadingLoyaltyRewardsData(false)
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
        setLoadingLoyaltyRewardsData(false)
      })
  }

  useEffect(() => {
    setLoadingLoyaltyRewardsData(true)
    getLoyaltyRedeemedApi()
  }, [selectedDates])

  const formatLoyaltySalesTableData = (data: LoyaltySalesReport[]) => {
    if (!data) return [];
    return data.map((row) => ({
      services: row?.services,
      client: row?.client,
      dateReached: getNumberMonthAndYearFromDate(convertDateTimeStringToTimeZone(row?.dateReached, "NG") || "-"),
      location: row?.location,
      staff: row?.staff,
      promoValue: row?.promoValue
    }));
  }

  const formatLoyaltyOverviewCsvDownloadData = (data: LoyaltySalesReport[]) => {
    if (!data) return [];
    return data.map((row) => ({
      "Services/Products": row?.services,
      "Client": row?.client,
      "Date reached": getNumberMonthAndYearFromDate(convertDateTimeStringToTimeZone(row?.dateReached, "NG") || "-"),
      "Location": row?.location,
      "Staff": row?.staff,
      "Promo value": row?.promoValue
    }));
  }

  const rows = formatLoyaltySalesTableData(loyaltyRewards);

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_loyalty_redeemed_rewards_report`;
  const _handleDownload = () => {
    if (loyaltyRewards) {
      handleDownload(formatLoyaltyOverviewCsvDownloadData(loyaltyRewards), filename);
    }
  };

  const downloadExcel = () => {
    if (loyaltyRewards) {
      handleExcelDownload(formatLoyaltyOverviewCsvDownloadData(loyaltyRewards), filename, 'Redeemed rewards');
    }
  };

  const {
    isVisible: redeemedRewardDetailsModalIsVisible,
    openModal: openRedeemedRewardDetailsModal,
    closeModal: closeRedeemedRewardDetailsModal
  } = useModal()

  const initiateCloseRewardModal = () => {
    closeRedeemedRewardDetailsModal()
    setRedeemedReward(null)
  }

  const openRedeemedReward = (id: number) => {
    const reward = getClientRedeemedRewardFromTable(loyaltyRewards || [], id);

    if (!reward) {
      return;
    }
    setRedeemedReward(reward)
    openRedeemedRewardDetailsModal();
  }
  return (
    <>
      <ToastWrapper toast={toast} />
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
          Rewards redeemed
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className="w-full flex flex-col space-y-12 overflow-x-scroll">
        <div className='w-full hidden md:flex flex-col h-fit border-t border-grey-100'>
          <ReportTable rows={rows} headers={Headers} loading={loadingLoyaltyRewardsData} onClick={openRedeemedReward} />
        </div>
      </div>
      <LoyaltyRedeemedDetails
        isOpen={redeemedRewardDetailsModalIsVisible}
        closeModal={initiateCloseRewardModal}
        reward={redeemedReward}
      />
    </>
  )
}

export default LoyaltyReports