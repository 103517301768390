import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLocation = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      clipRule="evenodd"
      d="m10 17.657.601-.677a49.274 49.274 0 0 0 1.841-2.227l.45-.594c1.881-2.534 2.822-4.546 2.822-6.032 0-3.174-2.558-5.746-5.714-5.746-3.156 0-5.714 2.572-5.714 5.746 0 1.486.94 3.498 2.822 6.032l.45.594A52.177 52.177 0 0 0 10 17.657Z"
      stroke="#9AA2A5"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.477a2.38 2.38 0 1 0 0-4.762 2.38 2.38 0 0 0 0 4.762Z"
      stroke="#9AA2A5"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLocation;
