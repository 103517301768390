import * as React from "react";
import { AnchorProps } from "./types";

const Anchor = ({ to, children, target = "", className = "", id = "" }: AnchorProps) => {
  return (
    <a href={to} target={target} className={className} id={id}>
      {children}
    </a>
  );
};

export default Anchor;
