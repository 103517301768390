import * as React from "react";
import { VARIANT_TO_TEXT_COLOR_CLASS_NAME } from "./constants";
import { FormHelperTextProps } from "./types";

export const FormHelperText = ({
  variant,
  children,
  className,
}: FormHelperTextProps) => {
  return (
    <span
      className={`text-b6 mt-1 block ${VARIANT_TO_TEXT_COLOR_CLASS_NAME[variant] || ""
        } ${variant === "info" ? "float-right" : ""} ${className || ""}`}
    >
      {children}
    </span>
  );
};
