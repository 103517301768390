import React from 'react'
import ParagraphShimmer from '../../atoms/paragraph/ParagraphShimmer'
import Skeleton from '../../atoms/skeleton/Skeleton'

const VoucherCardShimmer = () => {
  return (
    <>
      {[...Array(3)].map((_, index) => (
        <div className={`flex bg-slate-50 border rounded-md p-3 space-x-3 cursor-pointer`} key={`tcs${index}`}>
          <Skeleton width="40px" height="40px" />
          <div className='flex flex-col space-y-2'>
            <ParagraphShimmer size="md" />
            <ParagraphShimmer size="lg" />
          </div>
        </div>
      ))}
    </>
  )
}

export default VoucherCardShimmer