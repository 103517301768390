export const FORM_ERRORS = {
  SERVICE_NAME_REQUIRED: "Service name is required",
  PRODUCT_NAME_REQUIRED: "Service name is required",
  DESCRIPTION_REQUIRED: "Description is required",
  CATEGORY_REQUIRED: "Category is required",
  PRICE_REQUIRED: "Price is required",
  CUSTOMER_CAPACITY_REQUIRED: "Customer capacity is required",
  THRESHOLD_REQUIRED: "Low stock alert is required",
  STOCK_COUNT_REQUIRED: "Stock count is required",
};

export const ACCOUNT_SETUP_ERRORS = {
  FIRST_NAME_REQUIRED: "First name is required",
  LAST_NAME_REQUIRED: "Last name is required",
  EMAIL_REQUIRED: "Email is required",
  EMAIL_INVALID: "Email is invalid",
  PHONE_REQUIRED: "Phone number is required",
  PHONE_INVALID: "Phone number is invalid",
  ROLE_REQUIRED: "Role is required",
  DOB_REQUIRED: "Date of birth is required",
  SERVICES_REQUIRED: "At least one service is required",
};

export const PAYMENT_FORM_STEPS = {
  BVN_FORM: "bvn-form",
  BANK_FORM: "bank-form",
  BVN_VERIFICATION: "bvn-verification",
  BANK_VERIFICATION: "bank-verification",
}

export const SHIMMER_ARRAY_LENGTH = 3;

export const YES_OR_NO_SETTINGS = [{
  label: "Yes",
  value: true,
}, {
  label: "No",
  value: false
}];

export const YES_SETTING_NAME = 'Yes';
export const NO_SETTING_NAME = 'No';

export const PRICE_TYPE_REQUIREMENTS = [
  {
    value: 'percentage',
    label: 'Percentage',
  }, {
    value: 'fixed_value',
    label: 'Fixed Amount',
  }
];

export const SALES_PAYMENT_METHODS = [
  {
    label: 'Cash',
    value: 'cash'
  },
  {
    label: 'POS',
    value: 'pos'
  },
  {
    label: 'Bank transfer',
    value: 'bank_transfer'
  },
  {
    label: 'Gift Card',
    value: 'gift_card'
  }
]

export const ONBOARDING_STATUS = {
  HOURS: 'business_hours_created',
  SERVICES: 'business_service_created',
  PRODUCTS: 'business_product_created',
  CLIENTS: 'business_client_created',
  SALON: 'salon_created',
  STAFF: 'business_salon_staff_created',
  COMPLETED: 'completed',
}