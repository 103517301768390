import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSpliceLogoRing = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 168 253"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      opacity={0.15}
      fillRule="evenodd"
      clipRule="evenodd"
      d="m74.297 132.676 58.219-58.29c10.718-10.73 10.718-28.129 0-38.86-10.717-10.73-28.095-10.73-38.813 0l-58.22 58.29c-10.717 10.731-10.717 28.129 0 38.86 10.719 10.731 28.096 10.731 38.814 0Zm77.626-116.58c-21.436-21.461-56.19-21.461-77.626 0l-58.22 58.29c-21.436 21.462-21.436 56.258 0 77.72 21.436 21.462 56.19 21.462 77.626 0l58.22-58.29c21.436-21.462 21.436-56.258 0-77.72ZM74.297 216.643l58.219-58.29c10.718-10.731 10.718-28.129 0-38.86-10.717-10.73-28.095-10.73-38.813 0l-58.22 58.29c-10.717 10.731-10.717 28.129 0 38.86 10.719 10.731 28.096 10.731 38.814 0Zm77.626-116.58c-21.436-21.461-56.19-21.461-77.626 0l-58.22 58.29c-21.436 21.462-21.436 56.258 0 77.72 21.436 21.462 56.19 21.462 77.626 0l58.22-58.29c21.436-21.462 21.436-56.258 0-77.72Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgSpliceLogoRing;
