import { useEffect } from 'react';

// ScrollDetector component for detecting end of page
const ScrollDetector = ({ onEndOfPage }: { onEndOfPage: () => void }) => {
  useEffect(() => {
    function handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 1) {
        onEndOfPage(); // Call the onEndOfPage function when reaching the end of the page
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [onEndOfPage]);

  return null;// This component doesn't render anything
}

export default ScrollDetector;