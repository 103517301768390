import React from 'react'
import { Paragraph } from '../..'
import { LoyaltyProps } from './types';
import { convertDateTimeStringToTimeZone, getNumberMonthAndYearFromDate } from '../../../utils/misc';

const LoyaltyBackCard = (props: LoyaltyProps) => {
  const appointment = props?.clientMilestone?.appointment || null;
  const redeemeedServices = props?.clientMilestone?.milestone?.services || [];
  const services = appointment?.appointmentServices || [];
  const rewardMap = props?.clientMilestone?.milestone ? [{
    title: 'Applicable Services',
    value: Array?.isArray(redeemeedServices) && redeemeedServices?.length ? redeemeedServices?.map((service) => service?.name).join(', ') : 'N/A'
  }, {
    title: 'Redeemed Services',
    value: Array?.isArray(services) && services?.length ? services?.map((service) => service?.name).join(', ') : 'N/A'
  }, {
    title: 'Date redeemed',
    value: appointment ? getNumberMonthAndYearFromDate(convertDateTimeStringToTimeZone(appointment?.startAt, "NG")) : "N/A"
  }, {
    title: `Points ${appointment ? 'used' : 'earned'}`,
    value: props?.clientMilestone?.milestone?.customerPointsAttained + 'pts'
  }] : [];
  return (
    <div className='w-full rounded-md bg-green-300 cursor-pointer' onClick={() => props?.onClick()}>
      <div className='flex justify-between px-8 py-5 h-[55px]'>
      </div>
      <div className="w-full flex flex-col bg-grey-50 rounded-b-md bg-cover bg-center bg-no-repeat h-[200px] lg:h-[280px]">
        {props?.clientMilestone && rewardMap?.length ? (
            <div className='w-full flex flex-col space-y-2 border border-grey-50 p-4'>
              {Array?.isArray(rewardMap) && rewardMap?.length ? rewardMap?.map((dataReward, index) => {
                return (
                  <div className='w-full flex justify-between items-center space-x-4' key={index}>
                    <Paragraph size="b6" color="grey-300" className="text-left">
                      {dataReward?.title}
                    </Paragraph>

                    <Paragraph size="b6" color="grey-800" weight="semiBold" className="text-right">
                      {dataReward?.value}
                    </Paragraph>
                  </div>
                )
              }) : null}
            </div>
          ) : null}
      </div>
    </div>
  )
}

export default LoyaltyBackCard
