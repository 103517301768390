import * as React from "react";
import { AuthTemplateProps } from './types'
import { COLORS } from "../../../constants/colors";
import { Heading, Paragraph } from "../..";

const GeneratePasswordTemplate: React.FunctionComponent<AuthTemplateProps> = (props: AuthTemplateProps) => {
  return (
    <main className="flex items-center justify-center h-screen bg-white xl:bg-grey-100">
      <div className="table-row">
        <div className="table-cell align-middle">
          <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-8 px-9 max-w-[400px] xl:w-[520px] xl:max-w-[620px]">
            <div className="flex flex-col items-center space-y-9">
              <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>
                Create password
              </Heading>
              <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                Create a secure password for your Splice account
              </Paragraph>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default GeneratePasswordTemplate