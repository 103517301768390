import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { CurrencyCode  } from "./types";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { CURRENCIES, DEFAULT_CURRENCY } from '../constants/currency';
import { MultiSelectOption, TableFilterProps } from '../ui';
import { DEFAULT_TME_ZONE } from '../constants/information';
import { SalonSocialKeyInput } from '../uicomponents/types';
import { Package, Service } from '../graphql/generated';
import { BookingPackages, SelectedProduct, SelectedService } from '../modals/types';

export const formatPhoneNumber = (phone = "") => {
  const formattedPhone = phone.startsWith("0")
    ? phone?.replace("0", "")
    : phone;
  return formattedPhone;
};

export const getCloudinaryUploadUrl = (cloudName: string) => {
  return `https://api.cloudinary.com/v1_1/${cloudName}/auto/upload`;
};

export const getFullTime = (minutes?: string, hours?: string): number => {
  if (!minutes && !hours) return 0

  const minutesValue = minutes ? parseInt(minutes) : 0
  const hoursValue = hours ? parseInt(hours) : 0

  return minutesValue + hoursValue * 60
}

export const getHoursAndMinutes = (
  time: number
): { hours: number; minutes: number } => {
  if (!time)
    return {
      hours: 0,
      minutes: 0
    }

  const hours = Math.floor(time / 60)
  const minutes = time % 60

  return { hours, minutes }
}

export const getHoursAndMinutesString = (time: number): string => {
  const { hours, minutes } = getHoursAndMinutes(time)

  if (hours === 0) {
    return `${minutes} mins`
  }

  if (minutes === 0) {
    return `${hours} hr(s)`
  }

  return `${hours} hr(s) ${minutes} mins`
}

export const limitString = (str?: string, limit?: number, dots = true) => {
  if (!str) return ''
  if (str && limit && str.length > limit) {
    return dots ? `${str.slice(0, limit)}...` : str.slice(0, limit)
  }
  return str
}

export const copyTextToClipboard = (text: string) => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const convertDateToString = (date: string) => {
  const [year, month, day] = date.split('-').map((val) => parseInt(val))
  return new Date(year, month - 1, day)
}

export const convertFullDateStringToDate = (date?: string) => {
  if (!date) return '-'
  return date.split('T')[0]
}

export const convertIsoStringDateStringToTime = (date?: string) => {
  if (!date) return '-'
  const time = date.split('T')[1]
  return time.split('.')[0]
}

export const convertFullDateStringToTime = (dateString: string) => {
  const dateObject = new Date(dateString)
  const hours = dateObject.getUTCHours()?.toString().padStart(2, '0')
  const minutes = dateObject.getUTCMinutes()?.toString().padStart(2, '0')

  const time = `${hours}:${minutes}`
  return time
}

export const stripTimeOnlyFromDateString = (dateString: string) => {
  const timePart = dateString.split('T')[1].split('.')[0]
  return timePart
}

export const getDayAndNumberFromDate = (date: Date) => {
  const day = new Date(date).toLocaleString('en-us', { weekday: 'short' })
  const number = new Date(date).getDate()

  return {
    day,
    number
  }
}

export const getDateInTimezone = (date: string | Date, timezone: string) => {
  return new Date(new Date(date).toLocaleString('en', { timeZone: timezone }))
}

export const getDayAndNumberFromDateString = (date: string) => {
  const day = getDateInTimezone(date, "Africa/Lagos").toLocaleString('en-us', { weekday: 'short' });
  const number = parseInt(date.split('-')[2]);
  return {
    day,
    number
  }
}

export const getFormDateAndTimeFromDate = (date: string) => {
  const formDate = new Date(date).toLocaleDateString('en-us', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  const formTime = new Date(date).toLocaleTimeString('en-us', {
    hour: '2-digit',
    minute: '2-digit'
  })

  return {
    formDate,
    formTime
  }
}

export const getDayMonthAndNumberFromDate = (date: Date | string) => {
  const dateInTimezone = getDateInTimezone(date, "Africa/Lagos");
  const day = dateInTimezone.toLocaleString('en-us', { weekday: 'long' })
  const number = dateInTimezone.getDate()
  const month = dateInTimezone.toLocaleString('en-us', { month: 'long' })

  return `${day}, ${month} ${number}`
}

export const getShortDate = (date: Date | string) => {
  const dateInTimezone = getDateInTimezone(date, "Africa/Lagos");
  const year = dateInTimezone.getFullYear();
  const number = dateInTimezone.getDate()
  const month = dateInTimezone.toLocaleString('en-us', { month: 'long' })

  return `${number} ${month} ${year}`
}

export const getDayAndMonthFromDate = (date: Date | string) => {
  if (!date) return '-'
  const number = new Date(date).getDate()
  const month = new Date(date).toLocaleString('en-us', { month: 'long' })

  return `${number} ${month}`
}

export const getNumberMonthAndYearFromDate = (date: Date | string) => {
  if (!date) return '-'
  const number = new Date(date).getDate()
  const month = new Date(date).toLocaleString('en-us', { month: 'short' })
  const year = new Date(date).getFullYear()

  return `${number} ${month}, ${year}`
}

export const getNumberMonthYearAndTimeFromDate = (date: Date | string) => {
  const number = new Date(date).getDate()
  const month = new Date(date).toLocaleString('en-us', { month: 'short' })
  const year = new Date(date).getFullYear()
  const time = new Date(date).toLocaleTimeString('en-us', {
    hour: '2-digit',
    minute: '2-digit'
  })

  return `${number} ${month} ${year}, ${time}`
}

export const getNumberMonthYearAndTimeFromDateForAppointmentPurchase = (
  dateString: string
) => {
  if (!dateString) return '-'
  const number = new Date(dateString).getDate()
  const month = new Date(dateString).toLocaleString('en-us', { month: 'short' })
  const year = new Date(dateString).getFullYear()
  const time = stripTimeOnlyFromDateString(dateString)

  return `${number} ${month} ${year}, ${time}`
}

export const getDayMonthAndNumberAndTimeFromDate = (dateString: string) => {
  if (!dateString) return ''
  const date = dateString.split('T')[0]
  const timeLocale = dateString.split('T')[1]
  if (!timeLocale) return ''
  const time = convert24HourTo12Hour(timeLocale.split('.')[0])
  const number = new Date(date).getDate()
  const month = new Date(date).toLocaleString('en-us', { month: 'short' })
  const year = new Date(date).getFullYear()

  return `${month} ${number}, ${year} at ${time}`
}

export const getTimeFromISODate = (dateString: string) => {
  if (!dateString || dateString === null) return;
  const timeLocale = dateString.split('T')[1]
  const time = convert24HourTo12Hour(timeLocale.split('.')[0])

  return time
}

export const getDayAndNumber = (date: Date | string) => {
  if (!date || date === null) return;
  const day = new Date(date).toLocaleString('en-us', { weekday: 'short' })
  const number = new Date(date).getDate()
  return `${day} ${number}`
}

export const fillTime = (start: string, end: string): string[] => {
  const startTime = new Date(`01/01/2000 ${start}`)
  const endTime = new Date(`01/01/2000 ${end}`)
  const timeArray: string[] = []

  // Set the start time to the first hour interval after the start time
  startTime.setMinutes(Math.ceil(startTime.getMinutes() / 60) * 60)

  // Loop through the time range and add each hour interval to the array
  while (startTime <= endTime) {
    const timeString = startTime.toLocaleTimeString('en-US', {
      hour: '2-digit',
      hour12: true
    })
    timeArray.push(timeString?.replace(/^0+/, ''))
    startTime.setTime(startTime.getTime() + 60 * 60 * 1000) // Add one hour to the start time
  }

  return timeArray
}

export const convertIsoStringDateStringToTimeAndAddAnHour = (date: string) => {
  if (!date || date === null) return;
  const localDateTime = new Date(date)

  // Add one hour to the local date and time
  const oneHourLaterDateTime = new Date(
    localDateTime.getTime() + 60 * 60 * 1000
  )

  // Convert the adjusted date and time to an ISO string
  const dateTime = oneHourLaterDateTime.toISOString()
  return dateTime.split('T')[1]
}

export const convert12HourTo24Hour = (timeString: string): string | null => {
  if (!timeString || timeString === null) return;
  const [time, meridiem] = timeString.split(' ')

  const [hours, minutes] = time.split(':').map(Number)
  if (isNaN(hours) || isNaN(minutes)) {
    return null // Invalid input format
  }

  if (meridiem === 'AM' || meridiem === 'am') {
    if (hours === 12) {
      return `00:${String(minutes).padStart(2, '0')}:00`
    } else {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}:00`
    }
  } else if (meridiem === 'PM' || meridiem === 'pm') {
    if (hours === 12) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}:00`
    } else {
      return `${String(hours + 12).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}:00`
    }
  } else {
    return timeString?.split(':')?.length === 2
      ? `${timeString}:00`
      : timeString // Invalid meridiem
  }
}

export const pickDefaultTime = () => {
  const date = new Date()
  const time = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  })
  return time
}

export const convert24HourTo12Hour = (timeString: string) => {
  if (!timeString || timeString === null) return;
  const [hours, minutes] = timeString.split(':').map(Number)
  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59
  ) {
    return null // Invalid input format
  }

  if (hours === 0) {
    return `12:${String(minutes).padStart(2, '0')} am`
  } else if (hours < 12) {
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )} am`
  } else if (hours === 12) {
    return `12:${String(minutes).padStart(2, '0')} pm`
  } else {
    return `${String(hours - 12).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )} pm`
  }
}

export const checkDatesValidity = (startAt: string, endAt: string) => {
  const endDate = new Date(endAt)
  const currentDate = new Date()

  return currentDate <= endDate
}

export const getTimeFromDate = (date: string) => {
  const time = date?.split('T')[1]
  return formatTime(time)
}

export const removeSpaceAndSpecialCharacters = (string: string) => {
  return string?.replace(/[^a-zA-Z0-9]/g, '')
}

export const formatNumber = (value?: number | string) => {
  if (!value) return ''
  const convertValueToString = value?.toString()
  const formattedValue = convertValueToString
    ?.replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return formattedValue
}

export const formatStringInToPrice = (amount?: string) => {
  if (!amount) return `${DEFAULT_CURRENCY}0.00`
  // convert string, to number. replace all commas
  const valueToNumber = amount?.replaceAll(',', "")
  return formatInToPrice(+valueToNumber);
}

export const formatInToPrice = (price?: number, currency?: string) => {
  if (!price) return `${currency || DEFAULT_CURRENCY}0.00`
  const convertValueToString = price?.toString()
  const [integerPart, decimalPart] = convertValueToString.split('.')
  const numberWithCommas = integerPart
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // Format the number with two decimal places
  const amount = `${currency || DEFAULT_CURRENCY}${numberWithCommas}.${
    decimalPart ? decimalPart.substring(0, 2) : '00'
  }`
  return amount
}

export const formatInToNumber = (price?: number) => {
  if (!price) return `0`
  const numberWithCommas = price
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // Format the number with two decimal places
  const amount = `${numberWithCommas}`
  return amount
}

export const stringOffCommaAndDotFromMoney = (moneyString: string): number => {
  if (typeof moneyString !== 'string' || !moneyString) {
    return 0
  }

  // Remove commas and dots and currency sign
  const cleanedString = moneyString.replace(new RegExp(`/[,${DEFAULT_CURRENCY}]/`, 'g'), '');
  return cleanedString ? +cleanedString : 0;
}

export const formatInToNumberString = (price: number) => {
  const numberWithCommas = price
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // Format the number with two decimal places
  const amount = `${numberWithCommas}`
  return amount
}

export const formatSnakeCaseText = (text: string) => {
  if (!text) return "";
  const words = text.split('_')
  const formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  )
  return formattedWords.join(' ')
}

export const getCurrencySign = (currency: CurrencyCode): string | undefined => {
  return currency ? CURRENCIES[currency] : CURRENCIES.NGN
}

export const getDefaultQueryDates = () => {
  return [startOfMonth(new Date()), endOfMonth(new Date())] as [Date, Date]
}

export const getTodayQueryDates = () => {
  return [startOfDay(new Date()), endOfDay(new Date())] as [Date, Date]
}

export const handleDownload = (
  data: Record<string, unknown>[],
  filename: string
) => {
  const csvData = Papa.unparse(data)
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', `${filename}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const handleExcelDownload = (
  data: Record<string, unknown>[],
  filename: string,
  sheetName: string
) => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)
  XLSX.writeFile(workbook, `${filename}.xlsx`)
}

export const createFileNameFromDates = (dates: [Date, Date]) => {
  return `${getNumberMonthAndYearFromDate(
    dates[0]
  )}_${getNumberMonthAndYearFromDate(dates[1])}`
}

export const getMinutesDiff = (startDate: string, endDate: string) => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const diffInMilliseconds = Math.abs(end.getTime() - start.getTime())
  const minutesDiff = Math.floor(diffInMilliseconds / (1000 * 60))
  return minutesDiff
}

export const convertToCommaSeparatedArray = (
  options: MultiSelectOption[]
): string[] => {
  return options.map((option) => option.value)
}

export const getTimeElapsed = (dateString: string): string => {
  const date = new Date(dateString)
  const now = new Date()

  const secondsElapsed = Math.floor((now.getTime() - date.getTime()) / 1000)
  const minutesElapsed = Math.floor(secondsElapsed / 60)
  const hoursElapsed = Math.floor(minutesElapsed / 60)
  const daysElapsed = Math.floor(hoursElapsed / 24)
  const weeksElapsed = Math.floor(daysElapsed / 7)
  const monthsElapsed = Math.floor(daysElapsed / 30)

  if (secondsElapsed < 60) {
    return `${secondsElapsed} secs ago`
  } else if (minutesElapsed < 60) {
    return `${minutesElapsed} mins ago`
  } else if (hoursElapsed < 24) {
    return `${hoursElapsed} ${hoursElapsed === 1 ? 'hour' : 'hrs'} ago`
  } else if (daysElapsed === 1) {
    return 'yesterday'
  } else if (daysElapsed < 7) {
    return `${daysElapsed} days ago`
  } else if (weeksElapsed < 4) {
    return `${weeksElapsed} weeks ago`
  } else {
    return `${monthsElapsed} months ago`
  }
}

export const formatTime = (timeString?: string) => {
  if (!timeString) return
  const [hours, minutes] = timeString.split(':')
  let period = 'AM'
  let formattedHours = parseInt(hours, 10)

  if (formattedHours > 12) {
    formattedHours -= 12
    period = 'PM'
  } else if (formattedHours === 12) {
    period = 'PM'
  }

  return `${formattedHours}:${minutes} ${period}`
}

export const convertDateToUTCString = (
  value: Date | null | string
): string | null => {
  if (!value) return null
  // Convert the date to UTC by subtracting the local time zone offset
  const date = new Date(value)
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)

  // Get the UTC values of the date components
  const year = utcDate.getUTCFullYear()
  const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0')
  const day = utcDate.getUTCDate().toString().padStart(2, '0')
  const hours = utcDate.getUTCHours().toString().padStart(2, '0')
  const minutes = utcDate.getUTCMinutes().toString().padStart(2, '0')
  const seconds = utcDate.getUTCSeconds().toString().padStart(2, '0')

  // Assemble the ISO 8601 formatted string
  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`

  return isoString
}

export const convertDateToTime = (dateString: Date | string) => {
  const dateObject = new Date(dateString)
  const hours = dateObject.getHours().toString().padStart(2, '0') // 10
  const minutes = dateObject.getMinutes().toString().padStart(2, '0') // 0

  return `${hours}:${minutes}`
}

export const convertDateToDateTimeInputForRSuiteTimePicker = (
  utcDateString: string | Date
) => {
  const utcDate = new Date(utcDateString)

  // Subtract one hour from the UTC date
  utcDate.setHours(utcDate.getHours() - 1)
  return utcDate
}

export const sanitizePhoneNumber = (phoneNumber: string, callingCode: string): string => {
  if (!phoneNumber) return '';
  const callCode = callingCode || '234'

  // Remove white spaces
  const trimmedNumber = phoneNumber.replace(/\s/g, '');

  // Remove letters and the '+' sign
  const sanitizedNumber = trimmedNumber?.replace(/[^\d]/g, '');

  // Remove '+' from the calling code if present
  const sanitizedCallingCode = callCode?.replace('+', '');

  // Check if sanitizedNumber starts with callingCode
  let resultNumber = sanitizedNumber;
  if (resultNumber.startsWith(sanitizedCallingCode)) {
    // Remove the calling code if it does
    resultNumber = resultNumber.slice(sanitizedCallingCode.length);
  }

  // Remove leading '0' if present
  if (resultNumber.startsWith('0')) {
    resultNumber = resultNumber.slice(1);
  }

  return resultNumber;
};

export const validatePhoneNumber = (phoneNumber: string, countryCode: string) => {
  const pn = parsePhoneNumber(phoneNumber, { regionCode: countryCode }); // Use the selected country code
  return pn;
}

export const validatePassword = (password: string) => {
  const hasDigit = /\d/.test(password) ? 1 : 0
  const hasLowerCase = /[a-z]/.test(password) ? 1 : 0
  const hasSymbol = /[^a-zA-Z0-9]/.test(password) ? 1 : 0
  const hasUpperCase = /[A-Z]/.test(password) ? 1 : 0
  const missingCharacters = 8 - password.length
  const missingComplexity =
    4 - (hasDigit + hasLowerCase + hasSymbol + hasUpperCase)
  const score =
    (hasDigit + hasLowerCase + hasSymbol + hasUpperCase) * 2 -
    missingCharacters -
    missingComplexity
  return score >= 8
}
export const formatTableHeadersFilterArray = (data: TableFilterProps) => {
  // return an array of string to be used in the table filter, select the labels only if show is true
  const results = data
    ?.filter((item) => item?.show)
    ?.map((item) => ({ label: item.label, value: item.value }))

  return results
}

export const getFileNameFromUrl = (url: string) => {
  const parts = url.split('/')
  const fileNameWithTimestamp = parts[parts.length - 1]
  const fileName = fileNameWithTimestamp.split('-')[0]
  return decodeURIComponent(fileName)
}

export const getUTCTimezone = (countryCode: string) => {
  // Mapping of country codes to IANA Time Zone identifiers
  const countryToTimezone: Record<string, string> = {
    US: 'America/New_York',
    CA: 'America/Toronto',
    GB: 'Europe/London',
    GH: 'Africa/Accra', // Timezone for Ghana
    KE: 'Africa/Nairobi', // Timezone for Kenya
    NG: 'Africa/Lagos' // Corrected timezone identifier for Nigeria
    // Add more country to timezone mappings as needed
  }

  // Get the IANA Time Zone identifier for the country code
  const timezone = countryToTimezone[countryCode]

  return timezone
}

export const getCurrentTimeInGMT = (countryCode: string): string => {
  const timezone = getUTCTimezone(countryCode)

  const currentTimeInTimeZone = moment.tz(timezone)
  const offsetInMinutes = currentTimeInTimeZone?.utcOffset()
  const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60)
  const offsetMinutes = Math.abs(offsetInMinutes) % 60
  const offsetSign = offsetInMinutes >= 0 ? '+' : '-'

  const currentTimeWithGMTOffset = currentTimeInTimeZone.format(
    `YYYY-MM-DDTHH:mm:ss [GMT]${offsetSign}${offsetHours}:${offsetMinutes}`
  )
  const gmt = currentTimeWithGMTOffset?.split(' ')[1]
  return gmt.split(':')[0]
}

export const convertDateTimeStringToTimeZone = (
  dateTimeString: string,
  countryCode?: string
) => {
  const countryTimezone = getUTCTimezone(countryCode || DEFAULT_TME_ZONE)
  const dateTime = moment
    .utc(dateTimeString)
    .tz(countryTimezone)
    .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  return dateTime
}

export const sanitizeClientUploadPhoneNumber = (
  phoneNumber: string
): string => {
  // Remove white spaces
  const trimmedNumber = phoneNumber?.replace(/\s/g, '')

  // Remove letters
  const sanitizedNumber = trimmedNumber?.replace(/[^\d]/g, '')

  return sanitizedNumber
}

const extractUsername = (url: string): string | null => {
  let username = url;

  // Remove protocol and www (if present)
  const cleanedUrl = url?.replace(/^(https?:\/\/)?(www\.)?/i, '');

  // Instagram username extraction
  if (cleanedUrl.includes('instagram.com/')) {
    const parts = cleanedUrl.split('/');
    const usernameIndex = parts.indexOf('instagram.com') + 1;
    username = parts[usernameIndex];
  }

  // Twitter username extraction
  if (cleanedUrl.includes('twitter.com/')) {
    const parts = cleanedUrl.split('/');
    const usernameIndex = parts.indexOf('twitter.com') + 1;
    username = parts[usernameIndex];
  }

  // Facebook username extraction
  if (cleanedUrl.includes('facebook.com/')) {
    const parts = cleanedUrl.split('/');
    const usernameIndex = parts.indexOf('facebook.com') + 1;
    username = parts[usernameIndex];
  }

  return username;
}


export const formatSocialLinksInput = (input: SalonSocialKeyInput): { siteName: string; username: string }[] => {
  // const socialMediaLinks: { name: string; username: string }[] = [];

  const socials: Record<string, string | null> = {
    instagram: null,
    facebook: null,
    twitter: null,
  };

  const links = Object.keys(input)
    .filter(key => input[key].isAvailable)
    .map(key => {
      return {
        name: input[key].name,
        url: input[input[key].url] as unknown as string,
      }
    });

  for (const item of links) {
    socials[item.name] = extractUsername(item.url);
  }

  // convert socials to socialMediaLinks
  return Object.keys(socials).map(key => socials[key] && { siteName: key, username: socials[key] }).filter(Boolean);
};

export const convertOptionsToCommaSeparatedString = (items: MultiSelectOption[]): string[] => {
  return Array.isArray(items) && items.length ? items?.map(({ value }) => value) : [];
}

export const convertProductsToCommaSeparatedString = (products: SelectedProduct[]): {
  id: string;
  quantity: number;
}[] => {
  return Array.isArray(products) && products.length ? products?.map(({ id, quantity }) => ({
    id,
    quantity: quantity || 1,
  })) : [];
}

export const convertSaleProductsToCommaSeparatedString = (products: SelectedProduct[]): {
  id: string;
  quantity: number;
}[] => {
  return Array.isArray(products) && products.length ? products?.map(({ id, quantity }) => ({
    id,
    quantity: quantity || 1,
  })) : [];
}

export const convertServicesToCommaSeparatedString = (services: Service[]): string[]  => {
  return Array.isArray(services) && services.length ? services?.map(({ id }) => id) : [];
}

export const convertStaffOptionsToCommaSeparatedStringForServices = (items: MultiSelectOption[], price: number, staffList: {
  price: number,
  staff: MultiSelectOption[]
}[]): {
  staffId: string;
  price: number;
  }[] => {
  return Array.isArray(items) && items?.length ? items?.map(({ value }) => {
      const staff = staffList.find(item => item.staff.find(staff => staff?.value === value));
      return {
        staffId: value,
        price: staff?.price ? Number(staff?.price.toString()?.replace(/,/g, '')) : price ? Number(price.toString()?.replace(/,/g, '')) : 0,
      }
    }) : [];
}

export const convertServicesToCommaSeparatedStringWithQuantity = (services: SelectedService[]): {
  id: string;
  quantity: number;
}[] => {
  return Array.isArray(services) && services.length ? services?.map(({ id, quantity }) => ({
    id,
    quantity: quantity || 1,
  })) : [];
}

export const getStartOfDate = (dateString: string) => {
  return `${dateString?.split("T")[0]}T00:00`;
}

export const getEndOfDate = (dateString: string) => {
  return `${dateString?.split("T")[0]}T23:59`;
}

export const formatDateToPickLateNight = (dateString: string) => {
  // convert 2023-12-21T00:00:00 to 2023-12-20T23:59:00
  const originalDate = new Date(dateString);

  // Check if the input is a valid date
  if (isNaN(originalDate.getTime())) {
    return dateString;
  }

  // Subtract one minute from the date to get the late-night time
  const lateNightDate = new Date(originalDate.getTime() - 60000); // 60000 milliseconds = 1 minute

  // Format the date to the desired string format (YYYY-MM-DDTHH:mm:00)
  const formattedLateNightDate = lateNightDate.toISOString().slice(0, 16) + "00";

  return formattedLateNightDate;
}

export const getDayMonthAndNumberFromDateString = (dateString: string) => {
  if (!dateString) return '';

  // Split the date string into date and time parts
  const date = dateString.split("T")[0];
  const number = new Date(date).getDate();
  const month = new Date(date).toLocaleString("en-us", { month: "short" });
  const year = new Date(date).getFullYear();

  return `${month} ${number}, ${year}`;
}

export const dateFormat = 'YYYY/MM/DD';

export const formatBookingPackages = (salonPackages: Package[]) => {
  const packages: BookingPackages = salonPackages.map(packageItem => ({
    ...packageItem,
    selected: false,
    quantity: 1,
  }));

  return packages;
}

export const getSalonAvailabilityDateTime = (currentDate: Date) => {
  const utcDate = new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000)).toISOString(); // Convert to UTC format
  return utcDate;
}

export const formatDateToOriginalDate = (dateString: Date, type: "start" | "end") => {
  const originalDate = new Date(dateString);

  // Extract year, month, and day
  const year = originalDate.getFullYear();
  const month = ('0' + (originalDate.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-based
  const day = ('0' + originalDate.getDate()).slice(-2);

  // Construct the formatted date string
  const formattedDateString = `${year}-${month}-${day}`;

  if (type === "start") {
    return `${formattedDateString}T00:00:00`;
  } else {
    return `${formattedDateString}T23:59:00`; // Add 23:59:00 to the end date to make it inclusive of the end date
  }
}

export const checkPromoDatesValidity = (startAt: string, endAt: string) => {
  const startDate = new Date(startAt);
  const endDate = new Date(endAt);
  const currentDate = new Date();

  return startDate >= currentDate && currentDate <= endDate;
}

export const formatTextIntoCaps = (text: string) => {
  // format all_clients into All clients
  // Convert text to lower case and split it into words
  const words = text?.toLowerCase().split('_');

  // Capitalize the first letter of each word
  const capitalizedWords = words?.map(word => {
      return word?.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words back into a sentence
  const capitalizedText = capitalizedWords?.join(' ');

  return capitalizedText;
}

export const formatServiceCustomPriceInToSelectOptions = (differentPriceStaffs): {
  price: number;
  staff: {
    label: string;
    value: string;
  }[]
}[] => {
  return Array?.isArray(differentPriceStaffs) && differentPriceStaffs.length ? differentPriceStaffs.map(staff => ({
    price: Number(staff.price),
    staff: [{
      label: `${staff?.salonStaff?.user.firstName} ${staff?.salonStaff?.user.lastName} ${
        staff?.salonStaff?.specialty ? staff?.salonStaff?.specialty : ""
      } - ${staff?.salonStaff?.role}`,
      value: staff?.salonStaff?.id,
    }]
  })) : [];
}

export const removeEmpty = (arr: string[]): string[] => {
  return arr.filter(item => item !== '');
}

export const calculatePercentages = (widths: number[]) => {
  if (!widths) {
    return null;
  }
  const totalWidth = widths.reduce((acc, width) => acc + width, 0);
  return widths.map(width => ({
    width,
    percentage: ((width / totalWidth) * 100).toFixed(2) + '%'
  }));
}

export const getStaffRoleName = (level?: string | number) => {
  if (!level) return 'staff';

  const staffLevel = level?.toString()
  switch (staffLevel) {
    case '3':
      return 'owner';
    case '2':
      return 'manager';
    case '1':
      return 'receptionist';
    default:
      return 'staff';
  }
}
