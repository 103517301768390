import React from 'react'
import { SvgBlackSignal, SvgGreenSignal, SvgRedSignal, SvgSpliceFaintLogo } from '../../icons'
import { COLORS } from '../../../constants/colors'
import { Paragraph } from '../..'
import { ClientMilestone } from '../../../graphql/generated'
import { LoyaltyProps } from './types'
import { convertDateTimeStringToTimeZone, formatInToNumber, formatInToPrice, formatNumber, getNumberMonthAndYearFromDate } from '../../../utils/misc'
import { DEFAULT_CURRENCY } from '../../../constants/currency'

const LoyaltyCard = (props: LoyaltyProps) => {

  const currency = props?.business ? props?.business?.country?.currency?.symbol : DEFAULT_CURRENCY ? DEFAULT_CURRENCY : "₦"
  const getRuleText = () => {
    if (!props?.loyalty) {
      return ""
    }

    const milestone = props?.loyalty?.milestones[0]
    const value = milestone?.customerPointsAttained * props?.loyalty?.ruleValue;

    if (props?.loyalty?.ruleRequirement === "client_visits") {
      return `${value} visits`
    }    

    if (props?.loyalty?.ruleRequirement === "client_spends") {
      return `${formatInToPrice(value, currency)?.replace(".00", "")} spent`
    }
  }

  const getDiscountValue = () => {
    if (!props?.loyalty) {
      return ""
    }

    const milestone = props?.loyalty?.milestones[0]

    if (milestone?.customerReceivesType === 'percentage') {
      return `${milestone?.customerReceives}% off`
    }

    if (milestone?.customerReceivesType === 'fixed_value') {
      return `${formatInToPrice(milestone?.customerReceives, currency)} off`
    }
  }

  const getSignal = (status: string) => {
    if (!status) {
      return null
    }

    if (status === 'active') {
      return <>
        <SvgGreenSignal width="20px" height="20px" />
        <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN[300]} className=''>Active</Paragraph>
      </>
    }

    if (status === 'expired') {
      return <>
        <SvgRedSignal width="20px" height="20px" />
        <Paragraph size='b6' weight='semiBold' color={COLORS.RED[300]} className=''>Expired</Paragraph>
      </>
    }


    if (status === 'redeemed') {
      return <>
        <SvgBlackSignal width="20px" height="20px" />
        <Paragraph size='b6' weight='semiBold' color={COLORS.GREY[900]} className=''>Redeemed</Paragraph>
      </>
    }
  }

  const getMilestoneStatusText = (clientMileStone: ClientMilestone) => {
    if (!clientMileStone) {
      return ""
    }

    if (clientMileStone?.status === 'active') {
      return `Active since ${getNumberMonthAndYearFromDate(convertDateTimeStringToTimeZone(clientMileStone?.createdAt, "NG") || "-")}`
    }

    if (clientMileStone?.status === 'Used') {
      const appointment = clientMileStone?.milestone?.appointmentServices[0]?.appointment
      return `Redeemed on ${getNumberMonthAndYearFromDate(convertDateTimeStringToTimeZone(appointment?.startAt, "NG") || "-")}`
    }
  }
  return (
    <div className='w-full rounded-md bg-green-300 cursor-pointer' onClick={() => props?.onClick && typeof props.onClick === 'function' && props.onClick()}>
      <div className='flex justify-between items-center px-8 py-5'>
        <Paragraph size='b4' color={COLORS.GREY[100]}>LOYALTY CARD</Paragraph>
        <div className='flex justify-center items-center space-x-2'>
          <SvgSpliceFaintLogo width="25px" height="35px" />
          <div className='flex flex-col gap-1'>
            <Paragraph size='b6' weight='normal' color={COLORS.WHITE} className='text-center'>{props?.loyalty?.business?.name}</Paragraph>
            <Paragraph size='b7' weight='normal' color={COLORS.GREY[400]} className='text-center'>Powered by Splice</Paragraph>
          </div>
        </div>
      </div>
      <div className="bg-[url('https://res.cloudinary.com/dqqrgidob/image/upload/v1722700311/lt4otfwy13i1lgnzfmre.png')] w-full flex flex-col bg-green-300/80 rounded-b-md bg-cover bg-center bg-no-repeat h-[200px] lg:h-[280px]">
        <div className='h-full w-full flex flex-col space-y-2 items-center justify-center'>
          <h1 className={`text-white text-center ${props?.loyalty?.milestones[0]?.customerReceivesType === 'percentage' ? 'text-[72px]' : 'text-[50px]'} mono-sans-bold-wide antialiased whitespace-normal`}>{getDiscountValue()}</h1>
          <Paragraph size='b2' weight='normal' color={COLORS.GREY[50]} className='text-center italic'>after {getRuleText()}/{formatInToNumber(props?.loyalty?.milestones[0]?.customerPointsAttained)} points earned</Paragraph>
        </div>
        {props?.clientMilestone ?
          <div className='w-full h-[60px] px-5 py-3 flex items-center justify-between'>
            {props?.clientMilestone?.status === 'active' || props?.clientMilestone?.status === 'redeemed'}
            <Paragraph size='b6' weight='semiBold' color={COLORS.WHITE} className='text-center'>{getMilestoneStatusText(props?.clientMilestone)}</Paragraph>
            
            <div className="flex items-center space-x-2 rounded-full border border-grey-100 bg-grey-50 py-1 px-2 justify-end">
              {getSignal(props?.clientMilestone?.status)}
            </div>
          </div>
          : (
          <div className='w-full flex justify-center items-center'>
            <div className='w-fit flex space-x-2 items-center bg-green-300 p-4 rounded-md'>
              <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE}>
                {props?.loyalty?.ruleRequirement === 'client_spends' ? currency + " " : ""}
                {formatNumber(props?.loyalty?.ruleValue)}
                {props?.loyalty?.ruleRequirement === 'client_spends' ? "" : " Visit(s)"}
              </Paragraph>
              <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE}> = </Paragraph>
              <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE}>1 Point</Paragraph>
            </div>
          </div>
         )}
      </div>
    </div>
  )
}

export default LoyaltyCard