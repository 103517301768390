import React from 'react'
import { PromoFormInput, PromoModalProps } from '../types';
import { useForm } from 'react-hook-form';
import { Modal } from '../../../ui/templates/modal/Modal';
import Button from '../../../ui/atoms/button/Button';
import Input from '../../../ui/molecules/input/Input';

const PromoCodeModal = (props: PromoModalProps) => {
  const {
    control,
    handleSubmit,
  } = useForm<PromoFormInput>({});

  const onSubmit = async (input: PromoFormInput) => {
    console.log({
      input
    })
    // try {
    //   const { data, success } = await redeemPromo.mutateAsync({
    //     client: {
    //       countryCode: props?.clientInput?.countryCode as string,
    //       callingCode: props?.clientInput?.callingCode as string,
    //       phone: props?.clientInput?.phone as string,
    //     },
    //     promoCode: input.promoCode,
    //     salonId: props.salonId,
    //     businessId: props.businessId,
    //   });

    //   if (success && data) {
    //     props?.addToast && props?.addToast({
    //       message: 'Promo code applied successfully',
    //       variant: 'success',
    //     });
    //     props.applyPromoCode({
    //       promoCode: input.promoCode,
    //       promoValue: data?.promoValue,
    //       promoType: data?.promoType,
    //     });
    //     props.closeModal();
    //   }
    // } catch (error) {
    //   if (axios.isAxiosError(error)) {
    //     const message = error?.response?.data?.message || 'Promo code could not be applied';
    //     props?.addToast && props?.addToast({
    //       variant: "error",
    //       message,
    //     });
    //   }
    // }
    // props.closeModal();
  }

  return (
    <Modal
      title='Enter Promo code'
      subTitle=''
      show={props.isVisible}
      closeModal={props.closeModal}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6 mt-6" autoComplete='off'>
        <Input name="promoCode" id="promo-code" type="text" placeholder='Enter Promo Code' className='uppercase' control={control} />
        <Button
          variant='primary'
          className=''
          // disabled={redeemPromo.isLoading}
          // loading={redeemPromo.isLoading}
          size='lg'
          rounded='lg'
        >
          Redeem Code
        </Button>

        <Button
          variant='text'
          className='mx-auto'
          disabled={false}
          loading={false}
          size='none'
          type='button'
          onClick={props.closeModal}
        >
          Close
        </Button>
      </form>
    </Modal>
  )
}

export default PromoCodeModal