import * as React from "react";
import { TableCellContentProps } from "./types";
import { FontSize, FontWeight } from "../utils/types";
import { Paragraph } from "../..";

const TableCellContent = ({ content: cellContent,  size = FontSize.b4, weight = FontWeight.normal }: TableCellContentProps) => {

  const formatCellContent = () => {
    if (typeof cellContent === "function") {
      const CellContent = cellContent;
      return <CellContent />;
    } else
      return cellContent ? (
        <Paragraph size={size} weight={weight} >
          {cellContent}
        </Paragraph>
      ) : (
        <span>-</span>
      );
  }
  return formatCellContent();
};

export default TableCellContent;
