import { OutstandingPaymentsDocument, PaymentCollectedDocument, PaymentsDocument, SalesOverviewByServiceReportDocument, TransactionSummaryDocument } from "../../graphql/generated";
import axios from "axios";
import { print } from "graphql";


export const fetchPaymentCollected = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(PaymentCollectedDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { paymentCollected }
      }
    } = response;

    return paymentCollected;
  } catch (error) {
    console.log(error);
  }
};

export const fetchTransactionSummary = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(TransactionSummaryDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { transactionSummary }
      }
    } = response;

    return transactionSummary;
  } catch (error) {
    console.log(error);
  }
};

export const fetchOutstandingPaymentsSummary = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(OutstandingPaymentsDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { outstandingPayments }
      }
    } = response;

    return outstandingPayments;
  } catch (error) {
    console.log(error);
  }
};

export const fetchPaymentTransactions = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(PaymentsDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { payments }
      }
    } = response;

    return payments;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSalesOverviewReport = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(SalesOverviewByServiceReportDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { salesOverviewByServiceReport }
      }
    } = response;

    return salesOverviewByServiceReport;
  } catch (error) {
    console.log(error);
  }
};