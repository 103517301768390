/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { MOBILE_TRANSACTION_SUMMARY_HEADINGS, MOBILE_PAYMENT_COLLECTED_HEADINGS, PAYMENT_COLLECTED_HEADINGS, TRANSACTION_SUMMARY_HEADINGS } from './constants';
import TableSkeleton from '../../ui/organism/tableSkeleton/TableSkeleton';
import SummaryTableCard from '../../ui/organism/summaryTableCard/SummaryTableCard';
import Table from '../../ui/organism/table/Table';
import { formatDateToOriginalDate, formatTableHeadersFilterArray, getTodayQueryDates } from '../../utils/misc';
import CalendarPicker from '../../ui/molecules/calendarPicker/CalendarPicker';
import { SalesPageProps } from './types';
import { generateOutstandingPaymentsSummaryTableData, generatePaymentCollectedTableData, generateTransactionSummaryTableData } from './utils';
import { fetchOutstandingPaymentsSummary, fetchPaymentCollected, fetchTransactionSummary } from './api';

const DailySales = (props: SalesPageProps) => {
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getTodayQueryDates());
  const [transactionSummaryHeadings, setTransactionSummaryHeadings] = useState(TRANSACTION_SUMMARY_HEADINGS);
  const [paymentCollectedHeadings, setPaymentCollectedHeadings] = useState(PAYMENT_COLLECTED_HEADINGS);

  const [start, end] = selectedDates || getTodayQueryDates();
  const [transactionSummary, setTransactionSummary] = useState([])
  const [paymentCollected, setPaymentCollected] = useState([])
  const [outstandingPaymentsSummary, setOutstandingPaymentsSummary] = useState([])
  const [paymentSummaryIsLoading, setPaymentSummaryIsLoading] = useState(false);
  const [transactionSummaryIsLoading, setTransactionSummaryIsLoading] = useState(false);
  const showInitialLoadingShimmer = paymentSummaryIsLoading && transactionSummaryIsLoading && !transactionSummary && !paymentCollected;

  const fetchTransactionSummaryAsync = async () => {
    setTransactionSummaryIsLoading(true);
    const response = await fetchTransactionSummary({
      salonId: props.salonId,
      startDate: formatDateToOriginalDate(start, "start"),
      endDate: formatDateToOriginalDate(end, "end")
    })
    setTransactionSummary(response)
    setTransactionSummaryIsLoading(false);
  }

  const fetchPaymentSummaryAsync = async () => {
    setPaymentSummaryIsLoading(true);
    const response = await fetchPaymentCollected({
      salonId: props.salonId,
      startDate: formatDateToOriginalDate(start, "start"),
      endDate: formatDateToOriginalDate(end, "end")
    })
    setPaymentCollected(response)
    setPaymentSummaryIsLoading(false);
  }

  const fetchOutstandingPaymentsSummaryAsync = async () => {
    setPaymentSummaryIsLoading(true);
    const response = await fetchOutstandingPaymentsSummary({
      salonId: props.salonId,
      startDate: formatDateToOriginalDate(start, "start"),
      endDate: formatDateToOriginalDate(end, "end")
    })
    setOutstandingPaymentsSummary(response)
    setPaymentSummaryIsLoading(false);
  }

  useEffect(() => {
    if (props?.salonId) {
      fetchTransactionSummaryAsync()
      fetchPaymentSummaryAsync()
      fetchOutstandingPaymentsSummaryAsync()
    }
  }, [selectedDates])

  useEffect(() => {
    if (props.salonBranchName) {
      fetchTransactionSummaryAsync()
      fetchPaymentSummaryAsync()
      fetchOutstandingPaymentsSummaryAsync()
    }
  }, [props.salonBranchName])

  useEffect(() => {
    if (props.refreshData) {
      fetchTransactionSummaryAsync()
      fetchPaymentSummaryAsync()
      fetchOutstandingPaymentsSummaryAsync()
    }
  }, [props.refreshData])

  const getShimmer = () => {
    if (showInitialLoadingShimmer) {
      return (
        <div className='w-full flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }
  }
  const getTransactionSummaryContent = () => {
    if (transactionSummary) {
      const tableTransactionData = generateTransactionSummaryTableData(transactionSummary, transactionSummaryHeadings)
      return (
        <SummaryTableCard title="Transaction summary">
          <Table
            headers={formatTableHeadersFilterArray(transactionSummaryHeadings)}
            mobileHeaders={MOBILE_TRANSACTION_SUMMARY_HEADINGS}
            rows={tableTransactionData}
          />
        </SummaryTableCard>
      )
    }
  }
  const getOutstandingPaymentSummaryContent = () => {
    if (outstandingPaymentsSummary) {
      const tablePaymentData = generateOutstandingPaymentsSummaryTableData(outstandingPaymentsSummary, transactionSummaryHeadings)
      return (
        <SummaryTableCard title='Outstanding payments'>
          <Table
            headers={formatTableHeadersFilterArray(transactionSummaryHeadings)}
            mobileHeaders={MOBILE_PAYMENT_COLLECTED_HEADINGS}
            rows={tablePaymentData}
          />
        </SummaryTableCard>
      )
    }
  }
  const getPaymentCollectedContent = () => {
    if (paymentCollected) {
      const tablePaymentData = generatePaymentCollectedTableData(paymentCollected || [], paymentCollectedHeadings)
      return (
        <SummaryTableCard title='Payment collected'>
          <Table
            headers={formatTableHeadersFilterArray(paymentCollectedHeadings)}
            mobileHeaders={MOBILE_PAYMENT_COLLECTED_HEADINGS}
            rows={tablePaymentData}
          />
        </SummaryTableCard>
      )
    }
  }
  return (
    <>
      <div className='flex justify-end items-center py-4 px-8 space-x-4'>
        <CalendarPicker {...{ selectedDates, setSelectedDates }} />
      </div>
      {getShimmer()}
      <div className='w-full flex flex-col xl:flex-row py-4 px-8 gap-4'>
        {getTransactionSummaryContent()}
        {getOutstandingPaymentSummaryContent()}
        {getPaymentCollectedContent()}
      </div>
    </>
  )
}

export default DailySales