import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTimeManagementRafiki = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 230 230"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M230 175.904H0v.115h230v-.115ZM206.954 183.305h-15.235v.115h15.235v-.115ZM152.362 184.557h-3.998v.115h3.998v-.115ZM191.259 179.037h-8.827v.115h8.827v-.115ZM44 179.809H24.131v.115h19.867v-.115ZM51.01 179.809h-2.912v.115h2.912v-.115ZM103.569 181.75H60.477v.115h43.092v-.115ZM109.02 155.388H20.198a2.627 2.627 0 0 1-2.622-2.627V27.903a2.626 2.626 0 0 1 2.622-2.603h88.822a2.628 2.628 0 0 1 2.626 2.626v124.835a2.626 2.626 0 0 1-2.626 2.627ZM20.198 25.392a2.512 2.512 0 0 0-2.507 2.511v124.858a2.512 2.512 0 0 0 2.507 2.512h88.822a2.515 2.515 0 0 0 2.511-2.512V27.903a2.514 2.514 0 0 0-2.511-2.511H20.198ZM208.523 155.388h-88.826a2.632 2.632 0 0 1-2.627-2.627V27.903a2.633 2.633 0 0 1 2.627-2.603h88.826a2.626 2.626 0 0 1 2.617 2.603v124.858a2.624 2.624 0 0 1-2.617 2.627ZM119.697 25.392a2.517 2.517 0 0 0-2.512 2.511v124.858a2.515 2.515 0 0 0 2.512 2.512h88.826a2.515 2.515 0 0 0 2.512-2.512V27.903a2.517 2.517 0 0 0-2.512-2.512h-88.826Z"
      fill="#EBEBEB"
    />
    <path d="M30.724 175.904h62.421V43.98H30.724v131.924Z" fill="#E6E6E6" />
    <path d="M29.339 175.904H91.76V43.98H29.339v131.924Z" fill="#F5F5F5" />
    <path d="M89.272 173.107V46.777h-57.44v126.33h57.44Z" fill="#FAFAFA" />
    <path
      d="M76.042 173.107 61.488 46.777H45.057l14.554 126.33h16.431Z"
      fill="#fff"
    />
    <path d="M32.88 173.107V46.777h-1.048v126.33h1.049Z" fill="#E6E6E6" />
    <path d="M30.37 61.474h60.36v-3.753H30.37v3.753Z" fill="#F5F5F5" />
    <path d="M126.146 175.904V99.36h-25.204v76.544h25.204Z" fill="#F0F0F0" />
    <path
      d="M124.761 124.029v-22.434h-22.434v22.434h22.434ZM124.761 148.479v-22.435h-22.434v22.435h22.434ZM124.761 172.927v-22.434h-22.434v22.434h22.434Z"
      fill="#F5F5F5"
    />
    <path
      d="M118.509 101.301h-9.949a2.309 2.309 0 0 0 .673 1.649 2.29 2.29 0 0 0 1.65.674h5.28a2.33 2.33 0 0 0 2.346-2.323ZM118.509 125.75h-9.949a2.327 2.327 0 0 0 2.323 2.328h5.28a2.332 2.332 0 0 0 2.346-2.328ZM118.509 150.199h-9.949c0 .617.244 1.208.68 1.644a2.323 2.323 0 0 0 1.643.684h5.28a2.331 2.331 0 0 0 2.346-2.328Z"
      fill="#E6E6E6"
    />
    <path d="M138.731 175.904V99.36h-12.59v76.544h12.59Z" fill="#F5F5F5" />
    <path
      d="M193.426 174.022h6.987v-57.628h-6.987v57.628ZM132.352 175.904h67.095v-1.882h-67.095v1.882Z"
      fill="#F0F0F0"
    />
    <path d="M193.426 116.394h-62.045v57.628h62.045v-57.628Z" fill="#FAFAFA" />
    <path
      d="M143.52 150.07h37.748a6.721 6.721 0 0 0 0-13.441H143.52a6.723 6.723 0 0 0-6.72 6.721 6.723 6.723 0 0 0 6.72 6.72Zm37.757-12.99a6.273 6.273 0 0 1 5.793 3.87 6.273 6.273 0 0 1-3.393 8.192 6.273 6.273 0 0 1-2.4.477H143.52a6.27 6.27 0 1 1 0-12.539h37.757ZM143.52 166.791h37.748a6.721 6.721 0 0 0 0-13.441H143.52a6.72 6.72 0 0 0 0 13.441Zm37.748-12.99a6.278 6.278 0 0 1 4.543 1.774 6.284 6.284 0 0 1 1.897 4.493 6.269 6.269 0 0 1-6.44 6.268H143.52a6.264 6.264 0 0 1-4.542-1.775 6.257 6.257 0 0 1-1.897-4.493 6.271 6.271 0 0 1 6.439-6.267h37.748Z"
      fill="#F0F0F0"
    />
    <path
      d="M156.709 141.901h11.389a1.407 1.407 0 0 0 1.403-1.403 1.406 1.406 0 0 0-1.403-1.403h-11.389a1.406 1.406 0 0 0-1.403 1.403 1.407 1.407 0 0 0 1.403 1.403Z"
      fill="#EBEBEB"
    />
    <path
      d="M143.52 133.349h37.748a6.721 6.721 0 0 0 0-13.441H143.52a6.72 6.72 0 0 0 0 13.441Zm37.748-12.99a6.27 6.27 0 0 1 0 12.54H143.52a6.27 6.27 0 0 1 0-12.54h37.748Z"
      fill="#F0F0F0"
    />
    <path
      d="M156.709 125.179h11.389a1.406 1.406 0 0 0 1.403-1.403 1.4 1.4 0 0 0-1.403-1.38h-11.389a1.404 1.404 0 0 0-1.403 1.38 1.406 1.406 0 0 0 1.403 1.403ZM156.709 158.617h11.389a1.404 1.404 0 0 0 1.403-1.38 1.407 1.407 0 0 0-1.403-1.38h-11.389a1.406 1.406 0 0 0-1.403 1.38 1.4 1.4 0 0 0 1.403 1.38Z"
      fill="#EBEBEB"
    />
    <path d="M130.102 116.394h63.319v-2.931h-63.319v2.931Z" fill="#F0F0F0" />
    <path d="M201.784 113.468h-8.358v2.93h8.358v-2.93Z" fill="#E6E6E6" />
    <path
      d="M148.12 67.34c1.413.707 5.24-.056 3.966 2.534a21.544 21.544 0 0 1-2.618 3.8c-1.426 1.761-1.154 3.68.529 4.36.361.124.68.347.92.644.778 1.113-1.053 2.604-1.955 3.717-.901 1.113-1.26 2.208-.598 3.036.736.92 2.263.575 3.478.85 2.916.668-.083 4.693.225 6.758.212 1.417 1.38 1.813 2.921 2.01 1.274.166 2.972.281 3.041 1.583.064 1.131-1.408 2.41-1.794 3.597a3.717 3.717 0 0 0 .285 3.128 3.422 3.422 0 0 0 2.3 1.5c1.444.303 3.629 0 3.735 1.619.046.727-.46 1.38-.11 2.042a2.343 2.343 0 0 0 1.697.998c1.384.212 2.793.187 4.168-.073 1.697-.313 4.204-1.808 3.284-3.248a1.973 1.973 0 0 0-1.315-.754c-.405-.083-1.592.046-1.744-.23-.152-.276.258-1.339.272-1.721a8.081 8.081 0 0 0-.493-3.114 9.488 9.488 0 0 0-2.189-3.468c-.405-.41-1.044-.709-1.127-1.302-.083-.593.29-1.246.704-1.84.648-.92 1.656-1.785 2.019-2.76.57-1.536-.814-2.024-2.098-2.374-1.159-.317-2.686-.722-2.3-2.13.286-1.016 1.569-1.84 1.951-2.842.52-1.33-.741-1.38-1.638-1.84-1.173-.616-1.467-1.72-1.044-3.114.704-2.3 4.922-5.553 2.76-7.047-.547-.382-1.058-.507-1.38-1.082-.322-.575-.405-1.38-.865-1.922-.727-.879-2.231-1.026-3.013-1.84-1.614-1.675 1.868-4.642-1.311-5.35a3.771 3.771 0 0 1-1.84-.768c-.29-.272-.529-1.583-.855-1.675-1.459-.404-4.085 2.425-4.913 3.294-1.008 1.049-4.729 4.177-3.055 5.023Z"
      fill="#E0E0E0"
    />
    <path
      d="M173.139 106.356c-.865-1.605-4.651-3.22-2.139-5.06a21.178 21.178 0 0 1 4.342-2.267c2.199-.92 2.981-3.036 1.868-4.789a2.653 2.653 0 0 1-.46-1.242c-.092-1.62 2.323-2.006 3.712-2.59 1.389-.584 2.3-1.476 2.148-2.737-.156-1.38-1.683-1.996-2.608-3.031-2.217-2.493 2.585-4.752 3.418-7.061.57-1.583-.23-2.7-1.5-3.873-1.035-.962-2.474-2.13-1.84-3.51.552-1.196 2.535-1.592 3.51-2.567a4.754 4.754 0 0 0 1.426-3.381 4.412 4.412 0 0 0-1.219-2.958c-1.113-1.21-3.22-2.245-2.433-3.979.349-.773 1.145-1.118 1.191-2.024a2.95 2.95 0 0 0-.961-2.074 12.524 12.524 0 0 0-3.717-2.517c-1.665-.736-4.678-.759-4.637 1.288.052.603.32 1.167.755 1.587.317.34 1.43.943 1.416 1.32-.013.378-.92 1.21-1.163 1.592a10.693 10.693 0 0 0-1.229 3.496 13.073 13.073 0 0 0 .079 4.913c.133.667.542 1.38.294 2.033-.248.653-.92 1.095-1.596 1.43-1.063.534-2.42.806-3.262 1.579-1.329 1.219-.368 2.58.58 3.735.856 1.044 1.983 2.406.883 3.606-.796.865-2.373.92-3.22 1.698-1.168 1.044-.087 1.876.46 2.925.699 1.38.373 2.668-.75 3.832-1.84 1.923-7.314 2.631-6.205 5.52.281.731.658 1.178.644 1.969-.014.791-.377 1.656-.267 2.507.17 1.352 1.417 2.438 1.675 3.758.524 2.719-4.14 3.597-1.712 6.293.568.532.989 1.2 1.224 1.941.115.46-.382 1.946-.143 2.245 1.072 1.32 4.904.06 6.1-.322 1.412-.483 6.371-1.376 5.336-3.285Z"
      fill="#F5F5F5"
    />
    <path
      d="M182.837 102.736s-.46 10.732-18.023 10.732-18.023-10.732-18.023-10.732h36.046Z"
      fill="#F0F0F0"
    />
    <path
      d="M115 196.678c49.258 0 89.189-2.332 89.189-5.208 0-2.875-39.931-5.207-89.189-5.207-49.258 0-89.19 2.332-89.19 5.207 0 2.876 39.932 5.208 89.19 5.208Z"
      fill="#F5F5F5"
    />
    <g opacity={0.2} fill="#7EA05C">
      <path d="m165.936 187.62-29.298-54.151-29.297 54.151h58.595Z" />
      <path d="m165.936 96.011-29.298 54.147-29.297-54.147h58.595Z" />
    </g>
    <path
      d="m142.007 127.981-4.287 7.922a1.218 1.218 0 0 1-.452.471 1.221 1.221 0 0 1-1.258 0 1.218 1.218 0 0 1-.452-.471l-4.287-7.922a4.688 4.688 0 0 1 .101-4.64 4.696 4.696 0 0 1 4.039-2.287h2.479a4.696 4.696 0 0 1 4.117 6.927ZM137.103 173.949l13.317 5.474a1.226 1.226 0 0 1 .311 2.074 1.23 1.23 0 0 1-.771.286h-26.62a1.226 1.226 0 0 1-1.188-.993 1.226 1.226 0 0 1 .728-1.367l13.28-5.474a1.213 1.213 0 0 1 .943 0ZM136.638 163.535a1.707 1.707 0 1 0 0-3.413 1.707 1.707 0 0 0 0 3.413ZM136.638 144.882a.879.879 0 1 0 0-1.758.879.879 0 0 0 0 1.758ZM136.639 153.497a.998.998 0 1 0 0-1.996.998.998 0 0 0 0 1.996ZM165.789 184.948h-58.301a2.675 2.675 0 0 0-2.668 2.672 2.675 2.675 0 0 0 2.668 2.668h58.301a2.675 2.675 0 0 0 2.668-2.668 2.674 2.674 0 0 0-2.668-2.672ZM165.789 93.343h-58.301a2.677 2.677 0 0 0-2.668 2.668 2.678 2.678 0 0 0 2.668 2.668h58.301a2.678 2.678 0 0 0 2.668-2.668 2.677 2.677 0 0 0-2.668-2.668Z"
      fill="#7EA05C"
    />
    <path
      d="m89.53 186.613-3.243-1.054 2.015-7.649 3.243 1.053-2.015 7.65Z"
      fill="#FF8B7B"
    />
    <path
      d="m86.236 185.127 3.643 1.182a.251.251 0 0 1 .17.299l-.758 2.944a.602.602 0 0 1-.754.391c-1.261-.432-1.84-.703-3.446-1.223-.99-.322-4.237-1.159-5.598-1.601-1.362-.442-.888-1.776-.272-1.711 2.76.29 5.244.161 6.394-.262a.958.958 0 0 1 .621-.019Z"
      fill="#263238"
    />
    <path
      opacity={0.2}
      d="m91.545 178.968-3.243-1.058-1.04 3.946 3.248 1.054 1.035-3.942Z"
      fill="#000"
    />
    <path
      d="M100.409 101.531s-5.819 26.933-7.185 36.616c-1.495 10.603-7.112 42.444-7.112 42.444l6.022 1.666s8.344-30.88 10.782-41.189c2.645-11.191 9.66-39.56 9.66-39.56l-12.167.023Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.3}
      d="M107.604 110.364c-2.3 5.216-3.436 16.56-2.544 21.781a6282.561 6282.561 0 0 1 5.102-20.774c-.511-1.776-1.357-3.731-2.558-1.007Z"
      fill="#000"
    />
    <path
      d="m92.286 182.376-6.45-1.775.13-2.627 7.259 2.507-.939 1.895ZM84.903 185.321c.3.087.608.143.92.165a.076.076 0 0 0 .044-.01.081.081 0 0 0 .03-.119c-.084-.105-.797-1.03-1.243-1.002a.297.297 0 0 0-.248.156.46.46 0 0 0-.037.46c.13.176.32.3.534.35Zm.731 0c-.616-.074-1.04-.23-1.14-.428a.288.288 0 0 1 .031-.29.153.153 0 0 1 .05-.055.156.156 0 0 1 .07-.023c.24-.028.69.423.99.782v.014Z"
      fill="#263238"
    />
    <path
      d="M85.79 185.481h.037a.072.072 0 0 0 .036-.017.084.084 0 0 0 .024-.033c0-.037.39-.948.165-1.38a.39.39 0 0 0-.285-.207.301.301 0 0 0-.35.138c-.188.331.074 1.164.336 1.463l.037.036Zm0-1.458a.227.227 0 0 1 .143.12c.077.37.033.755-.124 1.099a1.62 1.62 0 0 1-.235-1.164c0-.041.064-.087.18-.064l.036.009ZM106.601 75.96c-.175.414-.322.727-.483 1.076-.161.35-.336.672-.506 1.003-.34.667-.699 1.316-1.072 1.964a37.795 37.795 0 0 1-2.484 3.763 28.539 28.539 0 0 1-3.058 3.492 17.713 17.713 0 0 1-3.993 2.953l-.115.06a2.378 2.378 0 0 1-.59.211 6.537 6.537 0 0 1-3.16-.124 9.202 9.202 0 0 1-2.41-1.122 15.643 15.643 0 0 1-3.33-3.018 27.678 27.678 0 0 1-2.424-3.363 30.221 30.221 0 0 1-1.9-3.61 1.49 1.49 0 0 1 2.516-1.542l.046.056c.76.92 1.582 1.9 2.41 2.792.807.888 1.664 1.73 2.567 2.52a12.635 12.635 0 0 0 2.64 1.84c.358.184.74.311 1.137.378.222.041.45.022.662-.055l-.704.271a13.772 13.772 0 0 0 2.581-2.254c.83-.933 1.599-1.92 2.3-2.953a46.016 46.016 0 0 0 2.065-3.308c.332-.565.649-1.14.948-1.725l.46-.869c.143-.28.299-.603.409-.828l.028-.064a2.99 2.99 0 0 1 5.451 2.456h.009Z"
      fill="#263238"
    />
    <path
      opacity={0.3}
      d="M99.08 82.207c1.444-3.836 4.512-3.9 5.842-2.884-.097.18-.198.359-.299.538a39.241 39.241 0 0 1-2.369 3.638 30.1 30.1 0 0 1-2.894 3.395c-.331.327-.671.649-1.025.962a14.72 14.72 0 0 1 .745-5.65Z"
      fill="#000"
    />
    <path
      d="m80.96 79.484-2.99.11.746-3.197c1.03.051 2.051.205 3.05.46a1.21 1.21 0 0 1 .648 1.84 1.8 1.8 0 0 1-1.453.787Z"
      fill="#FF8B7B"
    />
    <path
      d="M82.635 76.784a2.89 2.89 0 0 0-.99-.511c-.248 1.306-1.84 3.51-1.84 3.51h1.247a1.097 1.097 0 0 0 .842-.391l.874-1.05a1.089 1.089 0 0 0-.133-1.558Z"
      fill="#7EA05C"
    />
    <path
      d="m75.68 78.122.837-2.254 2.19.534-.746 3.192-2.281-1.472ZM113.275 188.103h-3.408l-.12-7.898h3.409l.119 7.898Z"
      fill="#FF8B7B"
    />
    <path
      d="M109.687 187.708h3.832a.244.244 0 0 1 .253.234l.184 3.032a.608.608 0 0 1-.598.607c-1.334-.023-1.969-.101-3.68-.101-1.035 0-4.499.105-5.93.105-1.43 0-1.38-1.416-.786-1.545 2.723-.57 5.152-1.38 6.113-2.121a.952.952 0 0 1 .612-.211Z"
      fill="#263238"
    />
    <path
      opacity={0.2}
      d="M113.16 180.21h-3.413l.064 4.071h3.409l-.06-4.071Z"
      fill="#000"
    />
    <path
      d="M107.061 101.531s-1.302 29.629-.75 39.395c.607 10.69 1.877 42.37 1.877 42.37h6.247s1.573-30.82 1.95-41.4c.41-11.5 2.263-40.365 2.263-40.365h-11.587Z"
      fill="#7EA05C"
    />
    <path
      d="M114.687 183.296h-6.748l-.515-2.511 7.447.372-.184 2.139ZM108.478 188.301c.31-.008.618-.048.92-.119a.09.09 0 0 0 .038-.025.088.088 0 0 0 .022-.04.09.09 0 0 0-.006-.043.092.092 0 0 0-.026-.035c-.111-.078-1.077-.736-1.495-.57a.3.3 0 0 0-.184.22.46.46 0 0 0 .101.433.918.918 0 0 0 .63.179Zm.69-.234c-.612.119-1.058.101-1.219-.056a.294.294 0 0 1-.055-.285.138.138 0 0 1 .028-.067.148.148 0 0 1 .059-.043c.226-.088.791.198 1.187.451Z"
      fill="#263238"
    />
    <path
      d="M109.37 188.181a.059.059 0 0 0 .037 0 .075.075 0 0 0 .029-.027.08.08 0 0 0 .012-.037c0-.041.079-1.021-.266-1.352a.414.414 0 0 0-.155-.095.412.412 0 0 0-.181-.016.305.305 0 0 0-.285.24c-.083.372.428 1.085.768 1.287a.058.058 0 0 0 .041 0Zm-.46-1.38c.032 0 .063.005.093.017.029.012.055.03.078.052.19.329.269.709.225 1.086-.295-.253-.635-.796-.584-1.04 0-.046.036-.101.151-.115h.037ZM120.355 74.032a2.183 2.183 0 0 0-.382-1.656 2.18 2.18 0 0 0-1.458-.874h-.037a40.51 40.51 0 0 0-3.832-.39 68.032 68.032 0 0 0-7.751 0c-1.191.11-2.355.308-3.284.487h-.037a3.258 3.258 0 0 0-2.479 2.272c-1.114 3.75-2.443 11.404-.704 27.656h18.239c.129-1.629-.235-6.928.032-13.676.267-4.638.832-9.254 1.693-13.819Z"
      fill="#263238"
    />
    <path
      opacity={0.3}
      d="M120.18 75.035c-.377 2.18-.841 5.148-1.163 8.28 0 0-1.666-3.266-1.969-6.058-.304-2.792 2.093-3.004 3.132-2.222Z"
      fill="#000"
    />
    <g opacity={0.2} fill="#fff">
      <path d="M107.949 75.468a.173.173 0 0 1-.103-.04.172.172 0 0 1-.049-.205.174.174 0 0 1 .074-.082l5.685-2.949a.176.176 0 0 1 .23.074c.021.04.025.088.012.132a.178.178 0 0 1-.085.103l-5.686 2.939a.217.217 0 0 1-.078.028Z" />
      <path d="M110.553 75.735a.153.153 0 0 1-.12-.051l-3.119-3.096a.182.182 0 0 1 0-.244.177.177 0 0 1 .122-.05c.046 0 .09.019.122.05l3.114 3.1a.174.174 0 0 1 0 .245.16.16 0 0 1-.119.046Z" />
      <path d="M114.66 71.107a67.944 67.944 0 0 0-7.746 0h-.129a1.833 1.833 0 0 0 .198 1.412c.432.69 1.338 1.077 2.7 1.15h.51c3.68 0 5.102-2.235 5.162-2.332l.128-.207-.823-.023Z" />
    </g>
    <path
      d="M107.282 71.08c2.254-.534 2.65-1.928 2.498-3.46a9.158 9.158 0 0 0-.18-1.14l2.825-2.347 2.074-1.72c-.575 2.498-1.223 7.047.46 8.713 0 0-1.38 2.3-5.257 2.097-3.253-.18-2.42-2.144-2.42-2.144Z"
      fill="#FF8B7B"
    />
    <path
      opacity={0.2}
      d="M109.6 66.48c.083.375.143.756.179 1.14 1.053-.212 2.461-1.334 2.608-2.41.052-.357.064-.718.037-1.077L109.6 66.48Z"
      fill="#000"
    />
    <path
      d="M108.1 54.51c-1.531.92-1.288 3.22-1.702 4.867.976.097 2.512-1.638 2.512-1.638l.92-2.635-1.73-.594Z"
      fill="#263238"
    />
    <path
      d="M116.247 59.138c-.98 3.247-1.38 5.193-3.46 6.55a4.407 4.407 0 0 1-4.559.209 4.416 4.416 0 0 1-2.304-3.94c0-3.095 1.592-7.888 5.143-8.55a4.46 4.46 0 0 1 5.18 5.73Z"
      fill="#FF8B7B"
    />
    <path
      d="M113.189 61.893c2.3-2.668-.575-3.983 1.775-5.837-2.645.124-7.001-.065-7.778-1.877-.778-1.812 1.614-4.269 3.606-4.499 2.3-.267 1.803 2.58 1.803 2.58s8.8-.547 6.008 3.22c2.116-.128 1.532 3.294-2.148 6.183-.925.308-3.266.23-3.266.23Z"
      fill="#263238"
    />
    <path
      d="M118.073 55.527s1.813-.539 1.753-1.974c.653.451.722 1.822-1.753 1.974Z"
      fill="#263238"
    />
    <path
      d="M116.785 62.528a3.638 3.638 0 0 1-2.093 1.275c-1.044.188-1.288-.778-.768-1.72.46-.847 1.578-1.965 2.516-1.73.939.234 1.012 1.352.345 2.175Z"
      fill="#FF8B7B"
    />
    <path
      d="M111.008 59.055c-.064.267-.257.46-.437.419-.179-.042-.267-.281-.202-.548.064-.267.257-.46.432-.423.175.037.272.28.207.552ZM107.958 58.443c-.064.267-.257.46-.432.423-.175-.037-.272-.28-.207-.547.064-.267.257-.46.432-.423.175.036.272.28.207.547Z"
      fill="#263238"
    />
    <path
      d="M108.897 58.77a10.24 10.24 0 0 1-1.84 2.033 1.567 1.567 0 0 0 1.232.502l.608-2.535Z"
      fill="#FF5652"
    />
    <path
      d="M109.227 62.243a2.359 2.359 0 0 0 1.771-.57.083.083 0 0 0 .019-.025.079.079 0 0 0 0-.06.083.083 0 0 0-.019-.026.082.082 0 0 0-.057-.023.084.084 0 0 0-.058.023 2.246 2.246 0 0 1-1.941.483.077.077 0 0 0-.031 0 .086.086 0 0 0-.029.013.093.093 0 0 0-.021.023.076.076 0 0 0-.011.029.08.08 0 0 0 .013.061c.012.018.03.03.052.035l.312.037ZM112.185 58.31a.126.126 0 0 0 .079 0 .16.16 0 0 0 .084-.09.162.162 0 0 0-.006-.122 1.542 1.542 0 0 0-1.136-.92.165.165 0 0 0-.184.138.15.15 0 0 0 .005.063.15.15 0 0 0 .077.097c.019.01.039.017.06.02a1.24 1.24 0 0 1 .883.726.172.172 0 0 0 .058.062.164.164 0 0 0 .08.026ZM107.204 56.613a.145.145 0 0 0 .147-.028 1.328 1.328 0 0 1 1.159-.271.165.165 0 0 0 .124-.01.164.164 0 0 0 .089-.157.184.184 0 0 0-.017-.06.171.171 0 0 0-.039-.05.168.168 0 0 0-.056-.032 1.672 1.672 0 0 0-1.458.327.162.162 0 0 0-.027.225.135.135 0 0 0 .078.056ZM120.521 72.722l.381.52.345.487c.23.327.437.653.654.98.418.658.814 1.325 1.196 1.996a44.11 44.11 0 0 1 2.079 4.14c.322.7.598 1.426.892 2.14.295.712.543 1.453.768 2.198a40.788 40.788 0 0 1 1.196 4.522l.037.175a2.392 2.392 0 0 1-.326 1.812 13.183 13.183 0 0 1-2.093 2.48 10.831 10.831 0 0 1-2.76 1.84 9.916 9.916 0 0 1-3.156.874 9.43 9.43 0 0 1-1.61.027c-.267 0-.534-.05-.8-.092l-.405-.078-.46-.115a.21.21 0 0 1-.13-.094.21.21 0 0 1-.027-.159l.635-2.668c0-.023.12-.028.193-.046l.253-.05c.175-.037.345-.079.52-.125.341-.092.675-.209.998-.35a7.146 7.146 0 0 0 1.744-1.025 8.808 8.808 0 0 0 2.419-3.151l-.289 1.987c-.396-1.302-.815-2.59-1.321-3.864a55.63 55.63 0 0 0-3.601-7.397c-.341-.593-.7-1.177-1.058-1.757l-.548-.856-.271-.414-.253-.368-.092-.138a2.986 2.986 0 1 1 4.89-3.431Z"
      fill="#263238"
    />
    <path
      d="m114.439 96.361-.989 1.62 4.292-.171s1.127-2.889-.409-3.344l-.598.16a3.937 3.937 0 0 0-2.296 1.735Z"
      fill="#FF8B7B"
    />
    <path
      d="M116.206 94.093a2.88 2.88 0 0 0-.782.787c1.168.635 2.797 2.797 2.797 2.797l.377-1.187a1.086 1.086 0 0 0-.124-.92l-.713-1.15a1.093 1.093 0 0 0-1.149-.5 1.09 1.09 0 0 0-.406.173Z"
      fill="#7EA05C"
    />
    <path
      d="m113.827 100.473 3.538-.312.377-2.35-4.292.17.377 2.492Z"
      fill="#FF8B7B"
    />
    <path
      d="m70.407 44.316-.234-.395c.674-.4 1.377-.75 2.102-1.049l.175.428c-.705.288-1.388.627-2.043 1.017ZM63.691 52.44l-.46-.156a16.013 16.013 0 0 1 5.663-7.526l.271.373a15.503 15.503 0 0 0-5.474 7.31Z"
      fill="#7EA05C"
    />
    <path
      d="M78.292 71.838c7.82 0 14.158-6.339 14.158-14.159S86.111 43.52 78.292 43.52c-7.82 0-14.16 6.34-14.16 14.16 0 7.819 6.34 14.158 14.16 14.158Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.6}
      d="M90.118 57.68a11.826 11.826 0 1 1-3.091-7.977.028.028 0 0 1 .023 0 11.803 11.803 0 0 1 3.068 7.977Z"
      fill="#fff"
    />
    <path
      d="M90.118 57.68a11.827 11.827 0 0 1-19.849 8.689 11.827 11.827 0 0 1 16.758-16.666.027.027 0 0 1 .023 0 11.804 11.804 0 0 1 3.068 7.976Z"
      fill="#fff"
    />
    <path
      d="M78.292 58.37a.684.684 0 0 1-.488-.203L72.036 52.4a.691.691 0 1 1 .975-.975l5.281 5.28 3.05-3.05a.69.69 0 0 1 .975.976l-3.537 3.537a.695.695 0 0 1-.488.203Z"
      fill="#263238"
    />
  </svg>
);
export default SvgTimeManagementRafiki;
