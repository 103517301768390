import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFluentSearch20Regular = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.35 3.8A6.05 6.05 0 0 1 14 13.722l4.54 4.539a.55.55 0 0 1-.702.841l-.077-.063-4.539-4.54A6.05 6.05 0 1 1 9.35 3.8Zm0 1.1a4.95 4.95 0 1 0 0 9.9 4.95 4.95 0 0 0 0-9.9Z"
      fill="#9AA2A5"
    />
  </svg>
);
export default SvgFluentSearch20Regular;
