import * as React from "react";
import Base from "./Base";
import { API_ERROR_TOAST_TIMEOUT } from "./constants";
import { ToastWrapperProps } from "./types";
import { removeQuotes } from "./utils";
import { TOAST_TEXT_COLORS } from "../../../constants/colors";
import { Paragraph } from "../../atoms/paragraph";
import { ToastTextColor } from "../../atoms/toast/types";

const ToastWrapper = ({ toast }: ToastWrapperProps) => {
  return toast ? (
    <Base toast={toast} duration={API_ERROR_TOAST_TIMEOUT}>
      <Paragraph
        size="b4"
        color={TOAST_TEXT_COLORS[toast.variant] as ToastTextColor}
      >
        {removeQuotes(toast.message)}
      </Paragraph>
    </Base>
  ) : null;
};

export default ToastWrapper;
