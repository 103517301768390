import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgArrowBox = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect x={0.333} width={32} height={32} rx={4} fill="#F3F6F6" />
    <path
      d="M20.834 11.5h-7.285a.562.562 0 0 1 0-1.125h7.847a.562.562 0 0 1 .563.563v7.874a.562.562 0 1 1-1.125 0V11.5Z"
      fill="#667368"
    />
    <path
      d="M20.998 10.54a.563.563 0 0 1 .796.796l-9.562 9.562a.563.563 0 1 1-.796-.796l9.562-9.563Z"
      fill="#667368"
    />
  </svg>
);
export default SvgArrowBox;
