import React from 'react'
import { SvgSale } from '../../icons'
import { Paragraph } from '../..'
import { COLORS } from '../../../constants/colors'

const EmptySales = () => {
  return (
    <div className='w-full flex flex-col p-4 items-center justify-center space-y-4'>
      <div className='bg-grey-50 rounded-full p-4'>
        <SvgSale width="40px" height="40px" />
      </div>

      <div className='w-full flex flex-col space-y-2'>
        <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
        You have no sales
        </Paragraph> 
        <Paragraph size='b4' color={COLORS.GREY[400]} className='w-full flex space-x-1 items-center'>
        Create new sale to get started
        </Paragraph>
      </div>
    </div>
  )
}

export default EmptySales