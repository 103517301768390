import * as React from "react";
import { ParagraphShimmer, Skeleton } from "../..";

const DateRangeCarouselShimmer = () => {
  return (
    <>
      <div className='flex justify-center items-center py-4'>
        <ParagraphShimmer size='md' />
      </div>
      <div className="w-full flex flex-col mt-6 space-y-2">
        {[1, 2, 3, 4, 5].map((item) => (
          <Skeleton width="100%" height="50px" className='rounded-full' key={item} />
        ))}
      </div>
    </>
  )
}

export default DateRangeCarouselShimmer