import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMinusGrey = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect x={0.5} y={0.5} width={47} height={47} rx={3.5} fill="#F3F6F6" />
    <path
      d="M14 24h20"
      stroke="#667368"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={0.5} y={0.5} width={47} height={47} rx={3.5} stroke="#E9E9E9" />
  </svg>
);
export default SvgMinusGrey;
