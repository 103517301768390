import * as React from "react";
import Base from "./Base";
import { ToastBaseProps } from "./types";
import { TOAST_BG_COLORS } from "../../../constants/colors";

const Warning = (props: ToastBaseProps) => {
  return (
    <Base {...props} bgColor={TOAST_BG_COLORS.WARNING}>
      <div className="bg-yellow-100 px-4 py-2 rounded-md flex justify-center items-center space-x-2.5 cursor-pointer">
        {props.children}
      </div>
    </Base>
  );
};

export default Warning;
