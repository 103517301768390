import React, { useContext } from 'react'
import { AddServiceProps } from '../types'
import BookContext from '../context'
import Heading from '../../../ui/atoms/heading/Heading';
import { Modal } from '../../../ui/templates/modal/Modal';
import SvgArrowUp from '../../../ui/icons/ArrowUp';
import SvgArrowDown from '../../../ui/icons/ArrowDown';
import { COLORS } from '../../../constants/colors';
import Paragraph from '../../../ui/atoms/paragraph/Paragraph';
import Button from '../../../ui/atoms/button/Button';
import { FormatNumber } from '../../../ui/atoms/formatNumber/FormatNumber';
import { DEFAULT_CURRENCY } from '../../../constants/currency';
import { getHoursAndMinutesString } from '../../../utils/misc';

export const ServiceModal = ({
  isVisible,
  closeModal,
}: AddServiceProps) => {
  const { formattedServices: services, selectService, showOrHideCategory } = useContext(BookContext);

  const selectServ = (serviceId: string) => {
    selectService(serviceId);
  }
  return (
    <Modal
      title=""
      subTitle=''
      show={isVisible}
      closeModal={closeModal}
    >
      <div className='space-y-6'>
        <Heading variant='h1' size='h8' weight='bold'>Select Services</Heading>


        <div className='flex flex-col space-y-6'>
          {Array.isArray(services) && services.map((category) => {
            return (
              <>
                <div className='flex flex-col space-y-4'>
                  <div className='flex justify-between cursor-pointer items-center' onClick={() => showOrHideCategory(category?.category)}>
                    <Heading variant='h3' size='h10' weight='semiBold' className='capitalize'>{category?.name}</Heading>
                    {category?.show ? (
                      <SvgArrowUp />
                    ) : (
                      <SvgArrowDown />
                    )}
                  </div>
                  {category?.show && Array.isArray(category?.services) && category?.services.map((service) => {
                    return (
                      <div className='flex justify-between items-center'>
                        <div className='flex flex-col justify-start'>
                          <Paragraph size='b4' weight='semiBold' color={COLORS.GREY[300]}>{service?.name}</Paragraph>
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]}>{service?.description}</Paragraph>
                          <Paragraph size='b5' weight='normal' color={COLORS.BLACK}>
                            {service?.pricingType === 'from' ? (<span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full mr-2'>from</span>) : null} {DEFAULT_CURRENCY}<FormatNumber value={service?.price} />{DEFAULT_CURRENCY}<FormatNumber value={service?.price} /> · <span className='text-grey-300'>{getHoursAndMinutesString(service?.duration)}</span></Paragraph>
                        </div>
                        <Button
                          variant={service?.selected ? 'primary' : 'secondary'}
                          className=''
                          size='sm'
                          rounded='lg'
                          type='button'
                          fontWeight='bold'
                          onClick={() => selectServ(service?.id)}
                        >
                          {service?.selected ? 'Added' : 'Add to Booking'}
                        </Button>
                      </div>
                    )
                  })}
                </div>
                {category?.name !== services[services.length - 1]?.name && (
                  <div className='border-t border-grey-100'></div>
                )}
              </>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
