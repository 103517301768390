import * as React from "react";
import { FormHelperText } from "./FormHelperText";
import { FormHelperTextListProps } from "./types";

export const FormHelperTextList = ({
  helperTexts,
}: FormHelperTextListProps) => {
  return (
    <>
      {Array.isArray(helperTexts)
        ? helperTexts.map((text) => {
          return (
            <FormHelperText key={text.message} variant={text.variant}>
              {text.message}
            </FormHelperText>
          );
        })
        : null}
    </>
  );
};
