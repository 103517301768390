import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDeleteRegular = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6.75 3.25v.25h2.5v-.25a1.25 1.25 0 0 0-2.5 0Zm-1 .25v-.25a2.25 2.25 0 1 1 4.5 0v.25H14a.5.5 0 0 1 0 1h-.754l-.946 8.284A2.5 2.5 0 0 1 9.815 15h-3.63A2.5 2.5 0 0 1 3.7 12.784L2.754 4.5H2a.5.5 0 1 1 0-1h3.75Zm-1.056 9.17A1.5 1.5 0 0 0 6.184 14h3.631a1.5 1.5 0 0 0 1.49-1.33l.935-8.17H3.76l.934 8.17ZM6.5 6.25a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0v-5a.5.5 0 0 1 .5-.5Zm3.5.5a.5.5 0 1 0-1 0v5a.5.5 0 0 0 1 0v-5Z"
      fill="#000"
    />
  </svg>
);
export default SvgDeleteRegular;
