/* eslint-disable @typescript-eslint/no-explicit-any */
import { APPOINTMENT_COLORS } from "../../constants/colors";
import { AppointmentService, ClosedPeriod, ServiceStaff, StaffBlockedTime, StaffTimeOff } from "../../graphql/generated";
import { ICalendarEventProps } from "../../ui/organism/calendar/types";
import { APPOINTMENT_STATUS } from "../bookingSales/constants";
import { generateDateRangeArray } from "../utils";
import { ICalendarClosePeriodProps, ICalendarClosedPeriodStripedData } from "./types";

export const formatClosedPeriodsToCalendarEventData = (closedPeriods: ICalendarClosePeriodProps[]): ICalendarClosedPeriodStripedData[] => {
  return closedPeriods.map((calendarClosePeriod) => {
    const { closedPeriod, date } = calendarClosePeriod;

    if (closedPeriod) {

      const { id, title } = closedPeriod;

      // Format the data into ICalendarClosedPeriodStripedData
      return {
        startAt: `${date}T00:00`,
        endAt: `${date}T23:59`,
        id: `${date}T${id}`,
        title,
      };
    }
  });
}

export const groupToDates = (closedPeriods: ClosedPeriod[]): ICalendarClosePeriodProps[] => {
  const result = [];

  if (Array.isArray(closedPeriods) && closedPeriods?.length) {
    // loop through closedPeriods
    for (const closedPeriod of closedPeriods) {
      const { id, title, startAt, endAt } = closedPeriod;
      const dates = generateDateRangeArray(new Date(startAt), new Date(endAt));

      // map through dates array
      for (const date of dates) {
        result.push({
          date,
          closedPeriod: {
            id,
            title,
            startAt,
            endAt,
          }
        });
      }
    }
  }
  return result;
};

export const formatClosedPeriodDataToCalendarEvents = (
  periods,
): ICalendarEventProps[] => {
  // const closedPeriods = groupToDates(periods)

  const formattedClosedPeriodData =  formatClosedPeriodsToCalendarEventData(periods)
  const data = formattedClosedPeriodData?.map((period) => ({
    id: period?.id,
    groupId: "",
    title: 'Business Closed',
    start: period?.startAt,
    end: period?.endAt,
    backgroundColor: "#eee", textColor: "#000", borderColor: "#eee",
    resourceId: "unassigned-appointments",
    extendedProps: {
      staff: "",
      client: "",
      status: '',
      type: "closed"
    },
  }));
  return data || [];
}

export const pickClosedPeriodForSpecificDate = (closedPeriodDateGroup: ICalendarClosePeriodProps[], businessClosedPeriodData: ClosedPeriod[], startDate: string) => {
  if (!startDate) return null;
  const currentDate = startDate.split("T")[0];
  // fetch same date in closedPeriodDateGroup array
  const closedPeriod = closedPeriodDateGroup.find((period) => period?.date === currentDate);

  if (closedPeriod) {
    // fetch same date in closedPeriodData array;
    const periodId = closedPeriod?.closedPeriod?.id;
    const closedPeriodData = businessClosedPeriodData.find((period) => period?.id === periodId)
    if (closedPeriodData) {
      return closedPeriodData
    }
  }
}

export const getStaffWithHighestPay = (serviceStaffs: ServiceStaff[], staff: { staffId: string; }[]) => {
  if (!Array.isArray(serviceStaffs) || !serviceStaffs.length) return null
  if (!Array.isArray(staff) || !staff.length) return null

  const mappedStaff = staff?.map((s) => s?.staffId);

  // check if all ids in mappedStaff exists in serviceStaffs
  const allStaffExists = serviceStaffs?.every((serviceStaff) => mappedStaff?.includes(serviceStaff?.salonStaff?.id));
  if (allStaffExists) {
    const staffWithHighestPay = serviceStaffs?.reduce((prev, current) => {
      if (prev?.price > current?.price) {
        return prev;
      }
      return current;
    });
    return staffWithHighestPay;
  }

  // check if one exist
  const staffWithOne = serviceStaffs?.find((serviceStaff) => mappedStaff?.includes(serviceStaff?.salonStaff?.id));
  if (staffWithOne) {
    return staffWithOne;
  }

  return null;
}

export const formatTimeOffDataToCalendarEvents = (timeOffData: StaffTimeOff[]): ICalendarEventProps[] => {
  return timeOffData?.map((timeOff) => ({
    id: timeOff?.id,
    groupId: "",
    title: timeOff?.title,
    start: timeOff?.startAt,
    end: timeOff?.endAt,
    backgroundColor: "#ddd", textColor: "#000", borderColor: "#ddd",
    resourceId: timeOff?.salonStaff?.id || "unassigned-appointments",
    extendedProps: {
      staff: timeOff?.salonStaff?.user?.firstName + " " + timeOff?.salonStaff?.user?.lastName,
      client: timeOff?.salonStaff?.user?.firstName + " " + timeOff?.salonStaff?.user?.lastName,
      status: '',
      type: "timeOff"
    },
  }));
}

export const formatBlockedTimeDataToCalendarEvents = (blockedTime: StaffBlockedTime[]): ICalendarEventProps[] => {
  return blockedTime?.map((timeOff) => ({
    id: timeOff?.id,
    groupId: "",
    title: timeOff?.title,
    start: timeOff?.startAt,
    end: timeOff?.endAt,
    backgroundColor: "#999", textColor: "#000", borderColor: "#999",
    resourceId: timeOff?.salonStaff?.id || "unassigned-appointments",
    extendedProps: {
      staff: timeOff?.salonStaff?.user?.firstName + " " + timeOff?.salonStaff?.user?.lastName,
      client: timeOff?.salonStaff?.user?.firstName + " " + timeOff?.salonStaff?.user?.lastName,
      status: '',
      type: "blocked"
    },
  }));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const formatAppointmentsToCalendarEvents = (appointments: AppointmentService[], currentView: string, countryCode?: string): ICalendarEventProps[] => {
  // Map appointments and group them by status
  const appointmentsByStatus: Record<string, ICalendarEventProps[]> = {};
  appointments?.forEach((appointment) => {
    const status = appointment?.appointment?.appointmentStatus;
    const staffList = currentView === 'timeGridWeek' ? [appointment?.appointmentServiceStaff[0]] : appointment?.appointmentServiceStaff

    // Loop through each staff member in the appointment
    Array?.isArray(staffList) && staffList?.forEach((staff) => {
      const appointmentEvent = {
        id: `${appointment?.id}::${staff?.salonStaff?.id}`,
        groupId: appointment?.appointment?.id,
        title: `${appointment?.name} x ${appointment?.quantity}`,
        start: appointment?.startAt,
        end: appointment?.endAt,
        ...getEventColor(appointment?.appointment?.appointmentStatus),
        resourceId: staff?.salonStaff?.id,
        extendedProps: {
          staff: (staff?.salonStaff?.user?.firstName ?? "") + " " + (staff?.salonStaff?.user?.lastName ?? ""),
          client: (appointment?.appointment?.client?.firstName ?? "") + " " + (appointment?.appointment?.client?.lastName ?? ""),
          status: appointment?.appointment?.appointmentStatus,
          type: 'appointment'
        },
      };

      // Create an array for the status if it doesn't exist and push the appointment
      if (!appointmentsByStatus[status]) {
        appointmentsByStatus[status] = [];
      }
      if (appointment?.appointment?.isActive) {
        appointmentsByStatus[status].push(appointmentEvent);
      }
    });
  });

  // Define the order of status categories
  const statusOrder = [
    APPOINTMENT_STATUS.pending,
    APPOINTMENT_STATUS.deposit_pending,
    APPOINTMENT_STATUS.confirmed,
    APPOINTMENT_STATUS.checked_in,
    APPOINTMENT_STATUS.completed,
    APPOINTMENT_STATUS.deposit_abandoned,
    APPOINTMENT_STATUS.no_show,
    APPOINTMENT_STATUS.cancelled,
  ];

  // Sort the appointments by status order
  const sortedAppointments: ICalendarEventProps[] = [];
  statusOrder.forEach((status) => {
    if (appointmentsByStatus[status]) {
      sortedAppointments.push(...appointmentsByStatus[status]);
    }
  });

  return sortedAppointments;
}

export const getEventColor = (status: string) => {
  switch (status) {
    case "pending":
      return { backgroundColor: APPOINTMENT_COLORS.PENDING, textColor: APPOINTMENT_COLORS.PENDING_TEXT, borderColor: APPOINTMENT_COLORS.PENDING };
    case "checked_in":
      return { backgroundColor: APPOINTMENT_COLORS.CHECKED_IN, textColor: APPOINTMENT_COLORS.CHECKED_IN_TEXT, borderColor: APPOINTMENT_COLORS.CHECKED_IN };
    case "completed":
      return { backgroundColor: APPOINTMENT_COLORS.DONE, textColor: APPOINTMENT_COLORS.DONE_TEXT, borderColor: APPOINTMENT_COLORS.DONE };
    case "no_show":
      return { backgroundColor: APPOINTMENT_COLORS.NO_SHOW, textColor: APPOINTMENT_COLORS.NO_SHOW_TEXT, borderColor: APPOINTMENT_COLORS.NO_SHOW };
    case "cancelled":
      return { backgroundColor: APPOINTMENT_COLORS.CANCELLED, textColor: APPOINTMENT_COLORS.CANCELLED_TEXT, borderColor: APPOINTMENT_COLORS.CANCELLED };
    case "confirmed":
      return { backgroundColor: APPOINTMENT_COLORS.CONFIRMED, textColor: APPOINTMENT_COLORS.CONFIRMED_TEXT, borderColor: APPOINTMENT_COLORS.CONFIRMED };
    default:
      return { backgroundColor: "#fff", textColor: "#AC321D", borderColor: "#AC321D" };
  }
};
