import React from 'react';

const EmptyState = () => {
  return (
    <div className="flex flex-col items-center justify-center h-fit py-4">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-12 h-12 text-gray-400 mx-auto mb-4">
        <path fillRule="evenodd" d="M5 3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1h3a1 1 0 0 1 1 1v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3V2a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h3zm2 5h6v2H7V8zm0 4h6v2H7v-2z" clipRule="evenodd" />
      </svg>
      <p className="text-grey-500">No items</p>
    </div>
  );
};

export default EmptyState;