import * as React from "react";
import { ToastVariantProps } from "./types";
import InfoToast from "./Info";
import { TOAST_VARIANT_TO_COMPONENT_MAPPING } from "./constants";

const Toast = ({ variant, ...props }: ToastVariantProps) => {
  const ToastComponent = TOAST_VARIANT_TO_COMPONENT_MAPPING[variant]
    ? TOAST_VARIANT_TO_COMPONENT_MAPPING[variant]
    : InfoToast;
  return (
    <React.Suspense fallback={null}>
      <ToastComponent {...props} />
    </React.Suspense>
  );
};

export default Toast;
