import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMastercard = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 562 398"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M338.924 94.698H222.768v208.605h116.156V94.698Z" fill="#FF5F00" />
    <path
      d="M230.143 199a132.359 132.359 0 0 1 13.322-57.991 132.447 132.447 0 0 1 37.381-46.31 132.822 132.822 0 0 0-140.054-15.023 132.726 132.726 0 0 0-54.508 48.939 132.6 132.6 0 0 0 0 140.77 132.726 132.726 0 0 0 54.508 48.941 132.824 132.824 0 0 0 140.054-15.023 132.448 132.448 0 0 1-37.381-46.311A132.377 132.377 0 0 1 230.143 199Z"
      fill="#EB001B"
    />
    <path
      d="M495.625 199a132.605 132.605 0 0 1-20.221 70.385 132.719 132.719 0 0 1-54.507 48.94 132.808 132.808 0 0 1-140.051-15.022 132.7 132.7 0 0 0 37.356-46.323 132.618 132.618 0 0 0 0-115.959 132.699 132.699 0 0 0-37.356-46.323 132.816 132.816 0 0 1 140.051-15.022 132.718 132.718 0 0 1 54.507 48.94A132.604 132.604 0 0 1 495.625 199ZM482.96 281.208v-4.271h1.723v-.87h-4.388v.87h1.724v4.271h.941Zm8.52 0v-5.149h-1.345l-1.547 3.541-1.548-3.541h-1.346v5.149h.95v-3.884l1.451 3.348h.985l1.451-3.357v3.893h.949Z"
      fill="#F79E1B"
    />
  </svg>
);
export default SvgMastercard;
