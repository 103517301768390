import React, { useEffect, useState } from 'react'
import { ClosedPeriodInput, ModalProps } from '../types'
import { Controller, useForm } from 'react-hook-form';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { checkDatesValidity, dateFormat } from '../../../utils/misc';
import { API_ERRORS, ERRORS } from '../../../constants/errors';
import { CreateClosedPeriodDocument, DeleteClosedPeriodDocument } from '../../../graphql/generated';
import { Modal } from '../../../ui/templates/modal/Modal';
import Button from '../../../ui/atoms/button/Button';
import SvgArrowBack from '../../../ui/icons/ArrowBack';
import SvgChevronLeft from '../../../ui/icons/ChevronLeft';
import Paragraph from '../../../ui/atoms/paragraph/Paragraph';
import Heading from '../../../ui/atoms/heading/Heading';
import Input from '../../../ui/molecules/input/Input';
import { FormLabel } from '../../../ui/atoms/formLabel';
import { FormHelperText } from '../../../ui/atoms/helperText/FormHelperText';
import axios from 'axios';
import { print } from 'graphql'

const BusinessClosePeriodModal = (props: ModalProps) => {
  const token = localStorage.getItem('token');
  const [createClosedPeriodIsLoading, setCreateClosedPeriodIsLoading] = useState(false);
  const [deleteClosedPeriodIsLoading, setDeleteClosedPeriodIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ClosedPeriodInput>()
  const submitData = async (input: ClosedPeriodInput) => {
    const startAt = input.startAt;
    const endAt = input.endAt;
    const validateDates = checkDatesValidity(startAt, endAt);
    if (!validateDates) {
      props?.addToast && props.addToast({
        message: ERRORS.PROMO_INVALID_DATES,
        variant: 'error',
      })
      return;
    }

    try {
      setCreateClosedPeriodIsLoading(true)
      if (!props?.closedPeriod && !props?.closedPeriodId) {
        axios
        .post(
          '/graphql',
          {
            query: print(CreateClosedPeriodDocument),
            variables: {
              input: {
                title: input.title,
                startAt,
                endAt,
            } }
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
          .then((res) => {
          setCreateClosedPeriodIsLoading(false)
          const {
            data: {
              data: { createClosedPeriod }
            }
          } = res

          if (createClosedPeriod.status === 200) {
            props?.addToast && props.addToast({
              message: "Created closed period successfully",
              variant: 'success',
            })
            closeModal();
          } else {
            props?.addToast && props.addToast({
              message: createClosedPeriod.errors[0].message,
              variant: 'error'
            })
          }
        })
      } else {
        axios
        .post(
          '/graphql',
          {
            query: print(CreateClosedPeriodDocument),
            variables: {
              input: {
                id: props.closedPeriod?.id as string,
                title: input.title,
                startAt,
                endAt,
            } }
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
          .then((res) => {
          setCreateClosedPeriodIsLoading(false)
          const {
            data: {
              data: { createClosedPeriod }
            }
          } = res

          if (createClosedPeriod.status === 200) {
            props?.addToast && props.addToast({
              message: "Closed period updated successfully",
              variant: 'success',
            })
            closeModal();
          } else {
            props?.addToast && props.addToast({
              message: createClosedPeriod.errors[0].message,
              variant: 'error'
            })
          }
        })
      }
    } catch (addStaffTimeOffError) {
      setCreateClosedPeriodIsLoading(false)
      if (axios.isAxiosError(addStaffTimeOffError)) {
        const message = addStaffTimeOffError?.response?.data?.message || API_ERRORS.CLOSE_PERIOD_CREATION_FAILED;
        props?.addToast && props.addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const resetFormInput = () => {
    setValue('startAt', '');
    setValue('endAt', '');
    setValue('title', '');
  }

  useEffect(() => {
    if (props?.closedPeriod) {
      const data = props?.closedPeriod
      setValue('title', data.title);
      setValue('startAt', data?.startAt);
      setValue('endAt', data?.endAt);
    }
  }, [props?.closedPeriod, setValue])

  const deleteClosePeriod = async () => {
    try {
      setDeleteClosedPeriodIsLoading(true)
      axios
        .post(
          '/graphql',
          {
            query: print(DeleteClosedPeriodDocument),
            variables: {
              input: {
                closedPeriodId: props.closedPeriod?.id as string,
            } }
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          setDeleteClosedPeriodIsLoading(false)
          const {
            data: {
              data: { deleteClosedPeriod }
            }
          } = res

          if (deleteClosedPeriod.status === 200) {
            props?.addToast && props.addToast({
              message: "Close period deleted successfully",
              variant: 'success',
            })
            closeModal();
          } else {
            props?.addToast && props.addToast({
              message: deleteClosedPeriod.errors[0].message,
              variant: 'error'
            })
          }
        })
    } catch (deleteClosePeriodError) {
      setDeleteClosedPeriodIsLoading(false)
      if (axios.isAxiosError(deleteClosePeriodError)) {
        const message = deleteClosePeriodError?.response?.data?.message || API_ERRORS.STAFF_TIME_OFF_DELETE_FAILED;
        props?.addToast && props.addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const closeModal = () => {
    resetFormInput();
    props.closeModal();
  }

  const onChangeStartDate: DatePickerProps['onChange'] = (date, dateString) => {
    setValue('startAt', dateString);
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date, dateString) => {
    setValue('endAt', dateString);
  };

  return (
    <Modal
      show={props.isVisible}
      closeModal={closeModal}
      variant='right'
    >
      <form onSubmit={handleSubmit(submitData)} className="w-full relative my-[80px]" autoComplete='off'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>Closed Period</Paragraph>
          </div>
        </div>
        <div className="w-full flex flex-col px-6 py-4 space-y-6">
          <Heading variant='h1' size='h9' weight='semiBold'>Business closed period</Heading>
          <Paragraph size="b4">{!props?.closedPeriod || !props?.closedPeriodId ? 'Add business closed period' : 'Edit business closed period'}</Paragraph>
        </div>
        <div className="w-full p-6 space-y-6">
          <Input name="title" label="Title" id="title" type="text" placeholder='e.g Closed for renovation' control={control} rules={{
            required: ERRORS.TITLE_REQUIRED,
          }} error={errors.title} />
          <div className='w-full flex gap-x-4'>
            <Controller
              control={control}
              name="startAt"
              render={({
                field: { value },
                formState: { errors },
              }) => {
                const errorMessage: string = errors?.startAt?.message;
                return (
                  <div className="w-full flex flex-col space-y-2">
                    <FormLabel htmlFor="start-date">
                      Start Date
                    </FormLabel>
                    <DatePicker className="splice-time-input" value={value ? dayjs(value, dateFormat) : undefined} onChange={onChangeStartDate} format={dateFormat} />
                    {errorMessage && (
                      <FormHelperText variant="error">
                        {errorMessage}
                      </FormHelperText>
                    )}
                  </div>
                );
              }}
              rules={{
                required: ERRORS.DATE_REQUIRED,
              }}
            />
          </div>
          <div className='w-full flex gap-x-4'>
            <Controller
              control={control}
              name="endAt"
              render={({
                field: { value },
                formState: { errors },
              }) => {
                const errorMessage: string = errors?.endAt?.message;
                return (
                  <div className="w-full flex flex-col space-y-2">
                    <FormLabel htmlFor="appointment-date">
                      End Date
                    </FormLabel>
                    <DatePicker className="splice-time-input" value={value ? dayjs(value, dateFormat) : undefined} onChange={onChangeEndDate} format={dateFormat} />
                    {errorMessage && (
                      <FormHelperText variant="error">
                        {errorMessage}
                      </FormHelperText>
                    )}
                  </div>
                );
              }}
              rules={{
                required: ERRORS.DATE_REQUIRED,
              }}
            />
          </div>
        </div>
        <div className="w-full max-w-[800px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer">
          <div className='w-full flex justify-between py-4 px-8'>
            <Button
              variant="text"
              size='none'
              rounded='none'
              fontSize='b5'
              type="button"
              className='mx-0 text-grey-900'
              onClick={closeModal}
            >
              Cancel
            </Button>
            <div className='flex space-x-4'>
              {props?.closedPeriod && (
                <Button
                  variant="text"
                  size='none'
                  rounded='none'
                  fontSize='b5'
                  type="button"
                  className='mx-0 text-red-600'
                  disabled={deleteClosedPeriodIsLoading}
                  loading={deleteClosedPeriodIsLoading}
                  onClick={deleteClosePeriod}
                >
                  Delete
                </Button>
              )}
              <Button
                variant='primary'
                className=''
                disabled={createClosedPeriodIsLoading || deleteClosedPeriodIsLoading}
                loading={createClosedPeriodIsLoading || deleteClosedPeriodIsLoading}
                size='md'
                rounded='lg'
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default BusinessClosePeriodModal