import * as React from "react";
import BasePill from "./BasePill";
import { BasePillProps } from "./types";

const DangerPill = ({ className, style, children }: BasePillProps) => {
  return (
    <BasePill
      style={style}
      className={`flex text-center text-red-900 bg-red-50 ${className || ""}`}
    >
      {children}
    </BasePill>
  );
};

export default DangerPill;
