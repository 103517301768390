export const FORM_VARIANT = {
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
  WARNING: "warning",
};
export const MAXIMUM_NOTE_LENGTH = 1000
export const DEFAULT_FORM_CLEAR_ERROR_TIMEOUT_MS = 4000;

export const MAX_PHONE_NUMBER_LENGTH_WITHOUT_CALLING_CODE = 10;

export const DEPOSIT_DURATION_INTERVAL = Array.from({ length: 24 }, (_, index) => ({ value: index + 1, label: `${index + 1} hour` }));