import * as React from "react";
import { TextProps } from "./types";
import { FormHelperTextList, FormLabel } from "../..";
import useHelperText from "../../atoms/helperText/useHelperText";

const FormTextarea = (
  {
    name,
    rules,
    register,
    label,
    id,
    helperText,
    ...props
  }: TextProps<HTMLTextAreaElement>,
  ref: React.Ref<HTMLTextAreaElement>
) => {
  const { helperTexts, borderColorClassName } = useHelperText({ helperText });

  return (
    <div className='w-full flex flex-col space-y-2'>
      {label && (
        <FormLabel htmlFor={id}>{label}</FormLabel>
      )}
      <textarea
        className={`w-full appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 focus:shadow-purple-50 placeholder:text-grey-400 h-[116px] overflow-y-scroll resize-none ${borderColorClassName}`}
        {...{
          ref,
          id,
          name,
          ...props,
        }}
        {...(register && register(name, rules))}
      ></textarea>

      <FormHelperTextList helperTexts={helperTexts} />
    </div>
  );
};

export default React.forwardRef(FormTextarea);
