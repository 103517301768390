import * as React from "react";
const SuccessToast = React.lazy(() => import("./Success"));
const ErrorToast = React.lazy(() => import("./Danger"));
const InfoToast = React.lazy(() => import("./Info"));
const WarningToast = React.lazy(() => import("./Warning"));

export const TOAST_VARIANT_TO_COMPONENT_MAPPING = {
  success: SuccessToast,
  error: ErrorToast,
  info: InfoToast,
  warning: WarningToast,
} as const;
