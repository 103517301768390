import * as React from 'react';
// import { FaStar } from 'react-icons/fa';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { RatingProps } from './types';


const Rating = ({ initialRating, size = 24 }: RatingProps) => {
  // Calculate full, half, and empty stars
  const fullStars = Math.floor(initialRating);
  const halfStar = initialRating % 1 >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStar;

  return (
    <div className="flex space-x-2">
      {[...Array(fullStars)].map((_, index) => (
        <FaStar key={`full-${index}`} color="#F4B832" size={size} />
      ))}
      {halfStar > 0 && <FaStarHalfAlt key="half" color="#F4B832" size={size} />}
      {[...Array(emptyStars)].map((_, index) => (
        <FaRegStar key={`empty-${index}`} color="#E2E8E9" size={size} />
      ))}
    </div>
  );
};

export default Rating;