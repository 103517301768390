import React from 'react'
import SLogo from '../../ui/icons/SLogo'
import Heading from '../../ui/atoms/heading/Heading'
import Paragraph from '../../ui/atoms/paragraph/Paragraph'
import { COLORS } from '../../constants/colors'
import { getNumberMonthYearAndTimeFromDate } from '../../utils/misc'
import { BookingConfirmationProps } from './types'

const BookingConfirmed = (props: BookingConfirmationProps) => {
  return (
    <main className='fixed top-0 left-0 w-full h-screen bg-grey-100 flex items-center justify-center'>
      <div className="table-row">
        <div className="table-cell align-middle">
          <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
            <div className="flex flex-col items-center space-y-6">
              <SLogo width="100%" height="100%" className="w-[32px]" />
              <Heading variant='h1' size='h4' weight='bold' color={COLORS.BLACK}>Booking confirmed</Heading>
              <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                Your booking at {props?.businessInfo?.name} for {getNumberMonthYearAndTimeFromDate(props.appointmentDateTime)} was successful. A confirmation email will be sent shortly.
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default BookingConfirmed