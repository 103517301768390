import * as Sentry from "@sentry/browser";
// @ts-expect-error Value set in application.html.erb
const isProduction = envVariables.environment === 'production'
const business = JSON.parse(localStorage.getItem('splice:business'))

export const logger = {
  debug: (message: string) => {
    if (!isProduction) {
      console.log(message);
    }
  },
  info: (message: string) => {
    if (isProduction) {
      Sentry.captureMessage(message, "info");
    } else {
      console.log(message);
    }
  },
  error: (message: string, context = {}) => {
    const errorMessage = business?.name ? `${business.name}: ${message}` : message;
    if (isProduction) {
      Sentry.withScope((scope) => {
        // Add the entire context object to the scope
        scope.setContext("data", context);
        Sentry.captureMessage(errorMessage, "error");
      });
    } else {
      console.error(errorMessage, context);
    }
  },
  exception: (error?: Error, context = {}) => {
    if (isProduction) {
      Sentry.withScope((scope) => {
        // Add business name as a tag
        if (business?.name) {
          scope.setTag("business", business.name);
        }

        // Add the entire context object to the scope
        scope.setContext("data", context);

        Sentry.captureException(error);
      });
    } else {
      console.error(error, context);
    }
  },
};
