import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPositiveIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.476 16C3.912 15.994.493 12.526.5 7.91.507 3.61 4.145 0 8.483 0c4.358 0 8.024 3.626 8.013 7.946-.006 4.603-3.453 8.061-8.02 8.054ZM2.531 8.105c-.087 2.693 2.095 5.931 5.96 5.937 3.664 0 5.972-2.967 5.983-5.927.01-3.451-2.611-6.093-6.005-6.072-3.394.02-5.938 2.616-5.938 6.062Z"
      fill="#10B10F"
    />
    <path
      d="M8.463 12.175c-1.494 0-2.777-.878-3.272-2.23-.239-.651-.027-1.26.506-1.456.574-.213 1.102.046 1.365.706.263.659.748.955 1.422.97.673.014 1.163-.343 1.446-.956.317-.687.836-.956 1.405-.705.526.23.694.811.43 1.484-.533 1.36-1.78 2.197-3.302 2.187ZM7.404 6.041a1.01 1.01 0 0 1-.956 1.01.971.971 0 0 1-1.05-.966.94.94 0 0 1 .943-.998c.61-.022 1.047.376 1.063.954ZM11.371 6.065a.971.971 0 0 1-1.032.983 1.04 1.04 0 0 1-.991-.99.996.996 0 0 1 1.05-.977.948.948 0 0 1 .973.984Z"
      fill="#10B10F"
    />
  </svg>
);
export default SvgPositiveIcon;
