import { useState } from "react";
import { ToastProps } from "../ui";

export const useToast = () => {
  const [toast, setToast] = useState<ToastProps>();

  const addToast = (toast: ToastProps) => {
    setToast(toast);
  };

  return {
    toast,
    addToast,
  };
};
