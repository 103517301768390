import React, { useContext } from 'react'
import { PHONE_FORM_STEP, TIME_AND_DATE, USER_DETAILS_STEP } from './constants';
import Heading from '../../ui/atoms/heading/Heading';
import BookContext from './context';

const BookingBreadcrumb = () => {
  const { step, selectedServices, appointmentDateTime, selectedPackages, voucherToBeRedeemed } = useContext(BookContext);

  const getTitle = () => {
    if (Array.isArray(selectedServices) && selectedServices?.length) {
      if (step === USER_DETAILS_STEP) {
        // fill user details
        return "";
      } else if (step === TIME_AND_DATE && !appointmentDateTime) {
        // select date and time
        return "";
      } else if (step === TIME_AND_DATE && appointmentDateTime) {
        // review appointment
        return "";
      } else if (step === PHONE_FORM_STEP) {
        // phone form
        return "";
      }
    } else if (Array.isArray(selectedPackages) && selectedPackages?.length) {
      if (step === USER_DETAILS_STEP) {
        // fill user details
        return "";
      }
    } else if (voucherToBeRedeemed) {
      if (step === USER_DETAILS_STEP) {
        // fill user details
        return "";
      } else if (step === TIME_AND_DATE && !appointmentDateTime) {
        // select date and time
        return "";
      } else if (step === TIME_AND_DATE && appointmentDateTime) {
        // review appointment
        return "";
      }
    }
  }
  return (
    <div className='py-[10px] xl:py-[20px]'>
      <Heading variant='h1' size='h7'>
        {getTitle()}
      </Heading>
    </div>
  )
}

export default BookingBreadcrumb
