import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNotificationImage = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={16} fill="#F3F6F6" />
    <path
      d="M21 10.5H11A1.5 1.5 0 0 0 9.5 12v8a1.5 1.5 0 0 0 1.5 1.5h10a1.5 1.5 0 0 0 1.5-1.5v-8a1.5 1.5 0 0 0-1.5-1.5Z"
      stroke="#667368"
      strokeWidth={1.2}
      strokeLinejoin="round"
    />
    <path
      d="M18.5 14.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      stroke="#667368"
      strokeWidth={1.2}
      strokeMiterlimit={10}
    />
    <path
      d="m17.5 18.493-2.833-2.827a1 1 0 0 0-1.371-.041L9.5 19m5.5 2.5 3.854-3.854a1 1 0 0 1 1.348-.063L22.5 19.5"
      stroke="#667368"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgNotificationImage;
