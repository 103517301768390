import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWorld = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#world_svg__a)">
      <path
        d="M13.653 2.934A7.969 7.969 0 0 0 8 .61c-2.135 0-4.141.825-5.65 2.325A7.854 7.854 0 0 0 .006 8.55c0 2.122.831 4.116 2.344 5.616A7.975 7.975 0 0 0 8 16.49c2.134 0 4.14-.825 5.65-2.325a7.854 7.854 0 0 0 2.343-5.616 7.864 7.864 0 0 0-2.34-5.616ZM14.237 6h-2.966c-.003-.01-.003-.019-.006-.028a22.514 22.514 0 0 0-1.037-3.738A6.756 6.756 0 0 1 14.238 6Zm-7.14-4.078c.296-.04.596-.06.903-.06.253 0 .5.016.743.041.27.622.872 2.153 1.253 4.097H6.128c.325-2.163.865-3.778.968-4.078ZM1.255 8.55c0-.444.044-.878.128-1.3h3.334a20.377 20.377 0 0 0-.028 3.594H1.665a6.575 6.575 0 0 1-.41-2.294Zm1.028 3.544h2.562c.157.953.391 1.853.71 2.687a6.767 6.767 0 0 1-3.272-2.687ZM4.865 6h-3.1a6.759 6.759 0 0 1 3.906-3.728A26.85 26.85 0 0 0 4.865 6ZM8.9 15.178a6.925 6.925 0 0 1-1.819-.003 11.708 11.708 0 0 1-.963-3.081h3.941a10.702 10.702 0 0 1-1.16 3.084Zm1.353-4.334H5.95a19.568 19.568 0 0 1 .025-3.594H10.2a15.53 15.53 0 0 1 .053 3.594Zm.256 3.915c.369-.834.647-1.725.825-2.665h2.384a6.766 6.766 0 0 1-3.209 2.665Zm1.006-3.915.01-.113c.1-1.106.078-2.275-.066-3.481h3.16a6.627 6.627 0 0 1-.282 3.594h-2.822Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="world_svg__a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWorld;
