import React, { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const SvgSendEmailIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g id='streamline:send-email' clipPath='url(#clip0_8457_1804)'>
      <path
        id='Vector'
        d='M22.4676 1.35421L6.10991 14.9862M9.96362 18.8571L13.6973 22.5736C13.9266 22.8087 14.2133 22.9797 14.529 23.0699C14.8447 23.1601 15.1785 23.1663 15.4973 23.0879C15.8179 23.0132 16.1139 22.8573 16.3568 22.6351C16.5997 22.413 16.7813 22.132 16.8842 21.8194L23.0145 3.44221C23.1427 3.09802 23.1694 2.72422 23.0912 2.36532C23.0131 2.00642 22.8335 1.67752 22.5738 1.4178C22.314 1.15807 21.9851 0.978446 21.6262 0.900311C21.2673 0.822176 20.8935 0.84882 20.5493 0.977071L2.17219 7.10907C1.84875 7.21929 1.56062 7.41391 1.3376 7.6728C1.11457 7.93169 0.964752 8.24545 0.903621 8.58164C0.840481 8.88762 0.854426 9.2045 0.944201 9.50375C1.03398 9.80301 1.19676 10.0752 1.41791 10.2959L6.10991 14.9879L5.95562 20.9296L9.96362 18.8588V18.8571Z'
        stroke='#667368'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8457_1804'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default SvgSendEmailIcon
