import axios from 'axios';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useToast } from '../../../hooks/useToast';
import { PageProps, SalonSocialInput, SocialLinkInput } from '../types';
import AccountSetupTemplate from '../AccountSetupTemplate';
import ToastWrapper from '../../../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../../../ui/atoms/toast/types';
import { SOCIALS } from '../../../constants/information';
import Checkbox from '../../../ui/atoms/checkbox/Checkbox';
import Input from '../../../ui/molecules/input/Input';
import { REGEX_PATTERNS } from '../../../constants/pattern';
import { API_ERRORS, ERRORS } from '../../../constants/errors';
import { COLORS } from '../../../constants/colors';
import Button from '../../../ui/atoms/button/Button';
import { print } from 'graphql'
import { formatSocialLinksInput } from '../../../utils/misc';
import { UpdateSocialDocument } from '../../../graphql/generated';

const SocialLinks = ({ onNextPage, onPrevPage }: PageProps) => {
  const {
    control,
    handleSubmit,
  } = useForm<SocialLinkInput>({
    defaultValues: {
      facebook: {
        isAvailable: false,
        url: 'facebookUrl',
        name: 'facebook'
      },
      instagram: {
        isAvailable: false,
        url: 'instagramUrl',
        name: 'instagram'
      },
      twitter: {
        isAvailable: false,
        url: 'twitterUrl',
        name: 'twitter'
      },
    },
  });
  const { addToast, toast } = useToast();
  const [isLoading, setIsLoading] = useState(false)

  const hasLinksOrText = (input: SocialLinkInput): boolean => {
    return SOCIALS.some((socialInput: SalonSocialInput) => {
      return input[socialInput.name as keyof SocialLinkInput]?.isAvailable &&
             input[socialInput.name as keyof SocialLinkInput]?.url;
    });
  };

  const onSubmitData = async (input: SocialLinkInput) => {
    try {
      if (!hasLinksOrText(input)) {
        onNextPage()
      } else {
        const socials = formatSocialLinksInput(input);

        setIsLoading(true)

        await axios.post(
          '/graphql',
          {
            query: print(UpdateSocialDocument),
            variables: { input: {socials} },
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        ).then(({ data }) => {

          if (data?.data?.updateSocial?.socials) {
            setIsLoading(false)
            onNextPage()
          }

          if (data?.errors?.length) {
            const message = data?.errors[0]?.message || API_ERRORS.BUSINESS_SOCIAL_LINKS_FAILED;
            addToast({
              variant: "error",
              message,
            });
            setIsLoading(false)
          }
        })
      }
    } catch (errorCreateSocials) {
      setIsLoading(false)
      const message = errorCreateSocials?.response?.data?.message || API_ERRORS.BUSINESS_SOCIAL_LINKS_FAILED;
      addToast({
        variant: "error",
        message,
      });
    }
  }

  return (
    <AccountSetupTemplate title="Social handles" subtitle='' skip={{
      text: 'Skip Business Setup',
    }}>
      <ToastWrapper toast={toast as ToastProps} />
      <form onSubmit={handleSubmit(onSubmitData)} className="w-full space-y-6 pt-6">
        {Array.isArray(SOCIALS) && SOCIALS.length
          ? SOCIALS
            .map((socialInput: SalonSocialInput) => {
              const Icon = socialInput?.icon;
              return (
                <Controller
                  control={control}
                  name={socialInput.name as keyof SocialLinkInput}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className="w-full flex flex-col space-y-4">
                        <div className="w-full flex">
                          <div className="flex w-2/5"
                            onClick={() => onChange(
                              {
                                ...value,
                                isAvailable: !value?.isAvailable
                              }
                            )}
                            key={socialInput.id}
                          >
                            <div className="flex items-center cursor-pointer text-b6 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value?.name === socialInput?.name ? value?.isAvailable : false}
                              />
                              <span className="text-grey-900 capitalize font-semibold whitespace-nowrap">
                                {socialInput?.name}
                              </span>
                            </div>
                          </div>
                          <div className="w-3/5 flex space-y-2">

                            <div className='w-full flex items-center gap-x-4'>
                              <Icon width="1.5em" height="1.5em" color={!value?.isAvailable ? COLORS.GREY[400] : COLORS.BLACK} />
                              {
                                !value?.isAvailable ? (
                                  <span className='text-b4 text-grey-400'>
                                    Not selected
                                  </span>
                                ) : (
                                  <Input name={value?.url} id={value?.url} type="text" placeholder='Enter username or link' value={
                                    value?.name === socialInput?.name ? value?.name : ''
                                  } control={control} rules={{
                                    required: value?.isAvailable ? ERRORS.SOCIAL_URL_REQUIRED : false,
                                    pattern: REGEX_PATTERNS.ALPHANUMERIC
                                  }} />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
              )
            })
          : null}
        <div className='w-full flex space-x-2'>
          <Button
            variant='secondary'
            className=''
            disabled={false}
            onClick={onPrevPage}
            size='lg'
            rounded='lg'
            type='button'
          >
            Prev
          </Button>
          <Button
            variant='primary'
            className=''
            disabled={isLoading}
            loading={isLoading}
            size='lg'
            rounded='lg'
          >
            Proceed
          </Button>
        </div>
      </form>
    </AccountSetupTemplate>
  )
}

export default SocialLinks