import * as React from 'react'
import { TableHeaderProps } from './types'
import { FontSize, FontWeight } from '../utils/types'
import { getFontSize, getFontWeight } from '../utils/utils'
import { SvgInfo } from '../../icons'

const TableHeader = ({
  children,
  colSpan,
  size = FontSize.b4,
  weight = FontWeight.normal,
  onClick,
}: TableHeaderProps) => {
  return (
    <th
      align='left'
      colSpan={colSpan || 1}
      className={`first:pl-6 xl:first:pl-11 ${getFontSize(
        size
      )} ${getFontWeight(
        weight
      )} text-grey-300 antialiased text-left py-4 px-2 whitespace-nowrap lg:whitespace-normal`}
    >
      <div className='flex items-center cursor-pointer' onClick={onClick}>
        {children}
        {children === 'Stock adjusted' && (
          <span className='group flex relative text-grey-300'>
            <SvgInfo width='15px' height='15px' />
            <span className='w-[200px] group-hover:opacity-100 transition-opacity bg-grey-400 p-2 text-b6 text-grey-20 rounded-md absolute left-2 -translate-x-2 opacity-0 m-4 mx-auto z-30'>
              Others including damaged, expired, returned etc.
            </span>
          </span>
        )}
      </div>
    </th>
  )
}

export default TableHeader
