import * as React from 'react'
import ReactModal from "react-modal";
import { ModalProps } from './types';

const ModalSafeForReact18 = ReactModal as React.ComponentType<ReactModal['props']>;

const Right = ({ children, show, closeModal, size = 'lg', }: ModalProps) => {
  return (
    <ModalSafeForReact18
      isOpen={show}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className={`fixed top-0 right-0 w-full rounded-0 block outline-0 h-[100%] overflow-auto
        ${size === 'md' ? 'max-w-[600px]' : size === 'lg' ? 'max-w-[850px]' : 'max-w-[1200px]'}
      `}
      overlayClassName="fixed inset-0 bg-black/70 backdrop-blur-[2px] z-30 flex justify-center"
      shouldCloseOnOverlayClick={true}
    >
      <section className="overflow-y-scroll h-screen bg-white border border-grey-100 p-0  w-full flex flex-col">
        {/* <div className="overflow-y-scroll h-screen px-0"> */}
          {children}
        {/* </div> */}
      </section>
    </ModalSafeForReact18>
  )
}
export default Right