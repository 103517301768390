import * as React from 'react'
import { ModalVariantProps } from './types';
import { MODAL_VARIANT_TO_COMPONENT_MAPPING } from './constants';
const TopCenter = React.lazy(() => import("./TopCenter"));

export const Modal = ({ variant = 'topCenter', ...props }: ModalVariantProps) => {
  const ModalComponent = MODAL_VARIANT_TO_COMPONENT_MAPPING[variant] ? MODAL_VARIANT_TO_COMPONENT_MAPPING[variant] : TopCenter;
  return (
    <React.Suspense fallback={null}>
      <ModalComponent {...props} />
    </React.Suspense>
  )
}