import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { BookingRedeemVoucherModalProps, RedeemVoucherBookingPageFormInput } from '../types';
import { Modal } from '../../../ui/templates/modal/Modal';
import SvgGiftBox from '../../../ui/icons/GiftBox';
import Button from '../../../ui/atoms/button/Button';
import { API_ERRORS, ERRORS } from '../../../constants/errors';
import Heading from '../../../ui/atoms/heading/Heading';
import Input from '../../../ui/molecules/input/Input';
import { RedeemVoucherCodeDocument } from '../../../graphql/generated';
import axios from 'axios';
import { print } from 'graphql';
import { logger } from '../../../core/logger';

const BookingRedeemVoucherModal = ({
  isVisible,
  closeModal,
  addToast
}: BookingRedeemVoucherModalProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors: errors },
  } = useForm<RedeemVoucherBookingPageFormInput>({
  });

  const [redeemVoucherCodeIsLoading, setRedeemVoucherCodeIsLoading] = useState(false)

  const redeemVoucher = async (input: RedeemVoucherBookingPageFormInput) => {
    setRedeemVoucherCodeIsLoading(true)
    try {
      await axios.post(
        '/graphql',
        {
          query: print(RedeemVoucherCodeDocument),
          variables: { input: { voucherCode: input?.code } },
        }
      ).then(({ data }) => {
        setRedeemVoucherCodeIsLoading(false)
        if (data?.data?.redeemVoucherCode?.voucher) {
          const voucher = data?.data?.redeemVoucherCode?.voucher;
          if (voucher) {
            const client = {
              fullName: voucher?.client?.firstName + " " + voucher?.client?.lastName,
              email: voucher?.client?.email,
              phone: voucher?.client?.phone,
              countryCode: voucher?.client?.countryCode,
              callingCode: voucher?.client?.callingCode,
              note: ''
            }
            closeModal({
              voucher, client
            })
          }
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.REDEEM_VOUCHER_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }


        if (data?.data?.redeemVoucherCode?.errors?.length) {
          const message = data?.data?.redeemVoucherCode?.errors[0]?.message || API_ERRORS.REDEEM_VOUCHER_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (error) {
      setRedeemVoucherCodeIsLoading(false)
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message || API_ERRORS.REDEEM_VOUCHER_FAILED;
        addToast({
          variant: "error",
          message,
        });
        logger.error(`failed to redeem voucher: code: ${input.code} - message: ${message}`);
        logger.exception(error as Error);
      }
    }
  }

  return (
    <Modal
      show={isVisible}
      closeModal={() => closeModal()}
    >
      <div className='w-full flex flex-col gap-6 items-center'>
        <div className='flex flex-col space-y-4 w-full items-center'>
          <SvgGiftBox height="100%" className="w-[72px]" />
          <Heading variant='h1' size='h8' weight='bold'>Redeem your voucher</Heading>
        </div>
        <form onSubmit={handleSubmit(redeemVoucher)} className="w-full space-y-6" autoComplete='off'>
          <div className='w-full'>
            <Input name="code" label="Enter voucher code" className='uppercase' id="voucher-code" type="text" placeholder='VKWTIVB' control={control} rules={{
              required: ERRORS.VOUCHER_CODE_REQUIRED
            }} error={errors.code} />
          </div>
          <Button
            variant='primary'
            size="lg"
            rounded='lg'
            loading={redeemVoucherCodeIsLoading}
            disabled={redeemVoucherCodeIsLoading}
            className="border-0">Redeem</Button>
          <Button
            variant='text'
            size="xs"
            rounded='none'
            type='button'
            onClick={() => closeModal()}
            className="mx-auto">Back</Button>
        </form>
      </div>
    </Modal>
  )
}

export default BookingRedeemVoucherModal