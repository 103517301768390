import axios from 'axios';
import { useEffect, useState } from 'react';
import { PageProps } from '../types';
import { useUserCache } from '../../../hooks/useUserCache';
import { useToast } from '../../../hooks/useToast';
import { API_ERRORS } from '../../../constants/errors';
import { SPLICE_BOOKING_LINK } from '../../../constants/information';
import {
  copyTextToClipboard,
  removeSpaceAndSpecialCharacters,
} from '../../../utils/misc';
import AccountSetupTemplate from '../AccountSetupTemplate';
import React from 'react';
import ToastWrapper from '../../../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../../../ui/atoms/toast/types';
import { COLORS } from '../../../constants/colors';
import Paragraph from '../../../ui/atoms/paragraph/Paragraph';
import SvgGroupLink from '../../../ui/icons/GroupLink';
import BookingPageSetup from '../../../ui/organism/debounceQuery/BookingPageSetup';
import Anchor from '../../../ui/atoms/anchor/Anchor';
import SvgExternal from '../../../ui/icons/External';
import Button from '../../../ui/atoms/button/Button';
import {
  MarkOnboardingAsCompleteDocument,
  UpdateBusinessDocument,
} from '../../../graphql/generated';
import { print } from 'graphql';
import { PAGE_ROUTES } from '../../../constants/routes';
import { useSalonCache } from '../../../hooks/useSalonCache';

const AppointmentLink = ({ onNextPage, onPrevPage }: PageProps) => {
  const { getBusinessData, setBusinessData, skipSetUp } = useUserCache();
  const _business = getBusinessData();
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const { addToast, toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [bookingPageLinkStatus, setBookingPageLinkStatus] = useState(false);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const country = JSON.parse(localStorage.getItem('country') as string);

  // const businessId = business?.businessId;

  console.log(country);
  console.log(JSON.parse(localStorage.getItem('userData') as string));

  const checkBusinessIdentifier = async () => {
    try {
      setIsLoading(true);
      await axios
        .post(
          '/graphql',
          {
            query: print(UpdateBusinessDocument),
            variables: {
              input: {
                headerImageUrl: _business.headerImageUrl,
                about: _business.about,
                cancellationPolicy: _business?.cancellationPolicy,
                acceptsOnlineBookings:
                  _business?.acceptsOnlineBookings || false,
                pointValue: 0,
                bookingUrlIdentifier:
                  removeSpaceAndSpecialCharacters(debouncedSearchQuery),
                logoUrl: _business?.logoUrl,
              },
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        .then(({ data }) => {
          if (data?.data?.updateBusiness) {
            setIsLoading(false);
            setBusinessData(data?.data?.updateBusiness?.business);
            setBookingPageLinkStatus(true);
          }

          if (data?.data?.updateBusiness?.errors) {
            const message =
              data?.data?.updateBusiness?.errors[0]?.message ||
              API_ERRORS.BUSINESS_HEADER_IMAGE_UPLOAD_FAILED;
            addToast({
              variant: 'error',
              message,
            });
            setIsLoading(false);
          }

          if (data?.errors) {
            const message =
              data?.errors[0]?.message ||
              API_ERRORS.BUSINESS_HEADER_IMAGE_UPLOAD_FAILED;
            addToast({
              variant: 'error',
              message,
            });
            setIsLoading(false);
          }
        });
    } catch (errorBusinessBookingUrl) {
      setBookingPageLinkStatus(false);
      if (axios.isAxiosError(errorBusinessBookingUrl)) {
        const message =
          errorBusinessBookingUrl?.response?.data?.message ||
          API_ERRORS.APPOINTMENT_LINK_FAILED;
        addToast({
          variant: 'error',
          message,
        });
      }
    }
  };

  useEffect(() => {
    if (
      debouncedSearchQuery &&
      debouncedSearchQuery !== _business?.bookingUrlIdentifier
    ) {
      checkBusinessIdentifier();
    }
  }, [debouncedSearchQuery]);

  const copyBookingLink = () => {
    copyTextToClipboard(`${SPLICE_BOOKING_LINK}${debouncedSearchQuery}`);
    addToast({
      variant: 'success',
      message: 'Booking link copied to clipboard',
    });
  };

  const redirectToHome = async () => {
    axios
      .post(
        '/graphql',
        {
          query: print(MarkOnboardingAsCompleteDocument),
          variables: { input: { salonId } },
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then((res) => {
        if (res.data.data.markOnboardingAsComplete.status === 200) {
          window.location.href = PAGE_ROUTES.HOME;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (_business) {
      setDebouncedSearchQuery(_business?.bookingUrlIdentifier);
      setBookingPageLinkStatus(true);
    }
  }, [_business]);

  return (
    <AccountSetupTemplate
      title="Create your custom appointment booking site"
      subtitle="This is the link where customers make reservations and appointment for your services."
      skip={{
        text: 'Skip Business Setup',
      }}
    >
      <ToastWrapper toast={toast as ToastProps} />
      <div className="w-full mt-6 space-y-6">
        <div className="w-full flex flex-col gap-y-4 items-center">
          <div className="flex justify-center items-center gap-x-1">
            <Paragraph size="b4" weight="normal" color={COLORS.GREY[400]}>
              {SPLICE_BOOKING_LINK}
            </Paragraph>
            <BookingPageSetup
              defaultSearchTerm={_business?.bookingUrlIdentifier || ''}
              setDebouncedSearchQuery={setDebouncedSearchQuery}
              successIcon={bookingPageLinkStatus}
            />
            {bookingPageLinkStatus && (
              <SvgGroupLink
                width="60px"
                height="60px"
                className="cursor-pointer"
                onClick={copyBookingLink}
              />
            )}
          </div>

          {bookingPageLinkStatus && (
            <>
              <Anchor
                to={`${SPLICE_BOOKING_LINK}${debouncedSearchQuery}`}
                target="_blank"
              >
                <div className="flex justify-center items-center space-x-2">
                  <Paragraph size="b5">Preview </Paragraph>
                  <SvgExternal width="2em" height="2em" />
                </div>
              </Anchor>
            </>
          )}
        </div>
        <div className="w-full flex space-x-2">
          <Button
            variant="secondary"
            className=""
            disabled={false}
            onClick={onPrevPage}
            size="lg"
            rounded="lg"
            type="button"
          >
            Prev
          </Button>
          {country?.collectionsSupported === true &&
          country?.payoutsSupported === true ? (
            <Button
              variant="primary"
              className=""
              disabled={isLoading || !bookingPageLinkStatus}
              loading={isLoading}
              size="lg"
              rounded="lg"
              type="button"
              onClick={onNextPage}
            >
              Proceed
            </Button>
          ) : (
            <Button
              variant="primary"
              className=""
              disabled={isLoading || !bookingPageLinkStatus}
              loading={isLoading}
              onClick={() => {
                redirectToHome();
                skipSetUp({
                  skip: true,
                });
              }}
              size="lg"
              rounded="lg"
            >
              Finish
            </Button>
          )}
        </div>
      </div>
    </AccountSetupTemplate>
  );
};

export default AppointmentLink;
