import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSalon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M63.5 0H.5C.22 0 0 .22 0 .5v19.42a4.98 4.98 0 0 0 2.85 4.49v35.47H.5c-.28 0-.5.22-.5.5v3.12c0 .28.22.5.5.5h63c.28 0 .5-.22.5-.5v-3.12c0-.28-.22-.5-.5-.5h-2.35V24.41C62.83 23.6 64 21.9 64 19.92V.5c0-.28-.22-.5-.5-.5ZM63 17.16h-8V1h8v16.16Zm-9 2.76c0 2.21-1.79 4-4 4s-4-1.79-4-4v-1.76h8v1.76Zm-9 0c0 2.21-1.79 4-4 4s-4-1.79-4-4v-1.76h8v1.76Zm-9 0c0 2.21-1.79 4-4 4s-4-1.79-4-4v-1.76h8v1.76Zm-9 0c0 2.21-1.79 4-4 4s-4-1.79-4-4v-1.76h8v1.76Zm-9 0c0 2.21-1.79 4-4 4s-4-1.79-4-4v-1.76h8v1.76Zm36-2.76h-8V1h8v16.16Zm-9 0h-8V1h8v16.16Zm-9 0h-8V1h8v16.16Zm-9 0h-8V1h8v16.16Zm-9 0h-8V1h8v16.16ZM1 1h8v16.16H1V1Zm0 18.92v-1.76h8v1.76a3.999 3.999 0 1 1-8 0ZM63 63H1v-2.12h62V63Zm-7.22-3.12H40.82V37.59c0-4.12 3.35-7.48 7.48-7.48 4.13 0 7.48 3.35 7.48 7.48v22.29Zm4.37 0h-3.38V37.59c0-4.67-3.8-8.48-8.48-8.48-4.68 0-8.48 3.8-8.48 8.48v22.29H3.85V24.77c.37.09.76.15 1.15.15 1.99 0 3.69-1.18 4.5-2.86.81 1.68 2.51 2.86 4.5 2.86 1.99 0 3.69-1.18 4.5-2.86.81 1.68 2.51 2.86 4.5 2.86 1.99 0 3.69-1.18 4.5-2.86.81 1.68 2.51 2.86 4.5 2.86 1.99 0 3.69-1.18 4.5-2.86.81 1.68 2.51 2.86 4.5 2.86 1.99 0 3.69-1.18 4.5-2.86.81 1.68 2.51 2.86 4.5 2.86 1.99 0 3.69-1.18 4.5-2.86.81 1.68 2.51 2.86 4.5 2.86.4 0 .78-.06 1.15-.15v35.11ZM63 19.92c0 2.21-1.79 4-4 4s-4-1.79-4-4v-1.76h8v1.76Z"
      fill="#000"
    />
    <path
      d="M48.3 31.61c-3.3 0-5.98 2.68-5.98 5.98v.84c0 .28.22.5.5.5h10.96c.28 0 .5-.22.5-.5v-.84c0-3.3-2.69-5.98-5.98-5.98Zm4.98 6.32h-9.96v-.34a4.98 4.98 0 0 1 9.96 0v.34ZM35.02 29.33H7.92c-.28 0-.5.22-.5.5v21.01c0 .28.22.5.5.5h27.09c.28 0 .5-.22.5-.5V29.83c.01-.27-.22-.5-.49-.5Zm-.5 21.01H8.42V30.33h26.09v20.01h.01ZM43.48 47.68c-.28 0-.5.22-.5.5v3.48c0 .28.22.5.5.5s.5-.22.5-.5v-3.48c0-.28-.23-.5-.5-.5Z"
      fill="#000"
    />
    <path
      d="M24.48 46.48c.28 0 .5-.22.5-.5v-4.25h3.1c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-3.1v-1.02h3.1c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-3.1v-1.02h3.1c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-3.1v-1.02h3.1c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-3.6c-.28 0-.5.22-.5.5v10.81c0 .27.22.5.5.5ZM16.88 46.48c.28 0 .5-.22.5-.5v-3.84l.16-.19c.26-.31.5-.65.73-.99.23.34.46.67.73.99l.16.19v3.84c0 .28.22.5.5.5 1.39 0 2.52-1.12 2.52-2.5 0-1.3-.99-2.36-2.26-2.49l-.15-.18c-.34-.41-.64-.84-.92-1.29a13.04 13.04 0 0 0 1.32-3.4l.4-1.81a.503.503 0 0 0-.98-.22l-.4 1.81c-.2.9-.51 1.77-.92 2.6-.41-.83-.72-1.7-.92-2.6l-.4-1.81a.503.503 0 0 0-.98.22l.4 1.81c.27 1.19.72 2.33 1.32 3.4-.28.45-.58.89-.92 1.29l-.15.18a2.507 2.507 0 0 0-2.26 2.49c0 1.38 1.13 2.5 2.52 2.5Zm3.27-3.93c.59.21 1.02.77 1.02 1.43 0 .65-.42 1.21-1.02 1.42v-2.85Zm-3.77 0v2.85c-.59-.21-1.02-.76-1.02-1.42 0-.66.43-1.23 1.02-1.43Z"
      fill="#000"
    />
  </svg>
);
export default SvgSalon;
