import React from 'react';
import FrontChat from '../ui/atoms/frontChat/FrontChat';

const Chat = () => {
  return (
    <div>
      <FrontChat />
    </div>
  );
};

export default Chat;