import * as React from 'react'
import { TextAreaProps } from './types';
import { useController } from 'react-hook-form';
import { getHelperTextForReactHookFormErrors } from '../../../utils/form';
import { FormHelperTextList, HelperTextProps } from '../..';

const TextInput: React.FC<TextAreaProps> = ({ name, label, id, control, className, rules, error, ...rest }) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
    rules,
  });

  const helperText = getHelperTextForReactHookFormErrors(error?.message as string);

  const helperTexts: HelperTextProps[] = React.useMemo(() => {
    if (Array.isArray(helperText)) {
      return helperText;
    }

    if (helperText) {
      return [helperText];
    }

    return [];
  }, [helperText]);

  return (
    <div className='w-full flex flex-col space-y-2'>
      {label && (
        <label htmlFor={name} className="block text-b4 xl:text-b5 font-normal text-black">
          {label}
        </label>
      )}
      <textarea
        className={`w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-r-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400 overflow-y-auto resize-none rounded-md ${className || ""}`}
        {...rest}
        {...inputProps}
        id={id}
        ref={ref}
      >

      </textarea>
      {error && (
        <FormHelperTextList helperTexts={helperTexts} />
      )}
    </div>
  )
}

export default TextInput