import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPassed = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={8} fill="#F2F8F4" />
    <g clipPath="url(#passed_svg__a)">
      <path
        d="m19.648 12.648.704.704-5.852 5.851-2.852-2.851.704-.704 2.148 2.149 5.148-5.149ZM16 8c.734 0 1.443.094 2.125.281.682.188 1.32.456 1.914.805a8.035 8.035 0 0 1 1.617 1.25 8.055 8.055 0 0 1 2.063 7.79 7.887 7.887 0 0 1-.805 1.913 8.038 8.038 0 0 1-1.25 1.617 8.055 8.055 0 0 1-7.79 2.063 7.887 7.887 0 0 1-1.913-.805 8.038 8.038 0 0 1-1.617-1.25 8.092 8.092 0 0 1-2.055-3.531 8.112 8.112 0 0 1-.008-4.258c.188-.682.456-1.32.805-1.914a8.035 8.035 0 0 1 1.25-1.617 8.092 8.092 0 0 1 3.531-2.055A8.112 8.112 0 0 1 16 8Zm0 15c.64 0 1.258-.083 1.852-.25a7.191 7.191 0 0 0 1.671-.703c.521-.302.995-.67 1.422-1.102a7.062 7.062 0 0 0 1.805-3.086A6.564 6.564 0 0 0 23 16c0-.64-.083-1.258-.25-1.852a7.191 7.191 0 0 0-.703-1.671 6.755 6.755 0 0 0-1.102-1.422A7.058 7.058 0 0 0 17.86 9.25 6.564 6.564 0 0 0 16 9a6.82 6.82 0 0 0-1.852.25 7.19 7.19 0 0 0-1.671.703c-.521.302-.995.67-1.422 1.102A7.058 7.058 0 0 0 9.25 14.14 6.564 6.564 0 0 0 9 16c0 .64.083 1.258.25 1.852a7.19 7.19 0 0 0 .703 1.671c.302.521.67.995 1.102 1.422a7.062 7.062 0 0 0 3.086 1.805A6.564 6.564 0 0 0 16 23Z"
        fill="#027A48"
      />
    </g>
    <defs>
      <clipPath id="passed_svg__a">
        <path fill="#fff" transform="translate(8 8)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPassed;
