import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const Svg404 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 361 155"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m68.897 153.575-.203-33.582H.114V97.605L61.571 6.832h37.24V95.57h19.13l-.204 24.423H98.812v33.582H68.897ZM29.825 95.57h39.072V38.786L29.825 95.57ZM311.843 153.575l-.204-33.582H243.06V97.605l61.457-90.773h37.24V95.57h19.129l-.204 24.423h-18.925v33.582h-29.914ZM272.771 95.57h39.072V38.786L272.771 95.57Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m176.573 81.08 35.622-35.621c6.558-6.558 6.558-17.19 0-23.748s-17.19-6.558-23.748 0l-35.622 35.622c-6.558 6.558-6.558 17.19 0 23.748s17.19 6.558 23.748 0Zm47.496-71.243c-13.116-13.116-34.38-13.116-47.496 0l-35.622 35.622c-13.116 13.115-13.116 34.38 0 47.496 13.116 13.115 34.38 13.115 47.496 0l35.622-35.622c13.116-13.116 13.116-34.38 0-47.496Zm-47.496 122.557 35.622-35.622c6.558-6.558 6.558-17.19 0-23.748s-17.19-6.558-23.748 0l-35.622 35.622c-6.558 6.558-6.558 17.19 0 23.748s17.19 6.558 23.748 0Zm47.496-71.244c-13.116-13.116-34.38-13.116-47.496 0l-35.622 35.622c-13.116 13.116-13.116 34.38 0 47.496s34.38 13.116 47.496 0l35.622-35.622c13.116-13.115 13.116-34.38 0-47.496Z"
      fill="#16796F"
    />
  </svg>
);
export default Svg404;
