import * as React from 'react'
import { Logo } from '../ui/atoms/logo'
import Heading from '../ui/atoms/heading/Heading'
import Button from '../ui/atoms/button/Button'
import { PAGE_ROUTES } from '../constants/routes'
import { ToastProps } from '../ui/atoms/toast/types'
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper'
import { ForgotPasswordInput } from '../core/types/auth'
import { useToast } from '../hooks/useToast'
import { useForm } from 'react-hook-form'
import { ForgotPasswordDocument } from '../graphql/generated'
import axios from 'axios';
import { print } from 'graphql';
import { API_ERRORS, ERRORS } from '../constants/errors'
import Input from '../ui/molecules/input/Input'
import { REGEX_PATTERNS } from '../constants/pattern'
import FullStory from 'react-fullstory'

const ForgotPassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordInput>();
  const { addToast, toast } = useToast();
  const [forgotPasswordIsLoading, setForgotPasswordIsLoading] = React.useState(false);

  const onSubmit = async (input: ForgotPasswordInput) => {
    try {
      setForgotPasswordIsLoading(true)
      await axios.post('/graphql', {
        query: print(ForgotPasswordDocument),
        variables: {
          input: {
            email: input?.email?.toLowerCase(),
          }
        }
      }).then(({ data }) => {
        setForgotPasswordIsLoading(false)
        if (data?.data?.forgotPassword?.status === 200) {
          // go to otp page
          localStorage.setItem('token', data.data.forgotPassword.token)
          localStorage.setItem('forgotEmailData', JSON.stringify({ email: input?.email?.toLowerCase(), token: data?.data?.forgotPassword?.token }))
          window.location.href = PAGE_ROUTES.FORGOT_PASSWORD_OTP
        } else if (data?.data?.forgotPassword?.errors?.length) {
          addToast({
            variant: "error",
            message: data?.data?.forgotPassword?.errors[0]?.message || API_ERRORS.FORGOT_PASSWORD_FAILED,
          });
        } else {
          addToast({
            variant: "error",
            message: API_ERRORS.FORGOT_PASSWORD_FAILED,
          });
        }
      })
    } catch (error) {
      setForgotPasswordIsLoading(false)
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message || API_ERRORS.FORGOT_PASSWORD_FAILED;
        addToast({
          variant: "error",
          message,
        });
      }
    }
  }

  const goToLoginPage = () => {
    window.location.href = PAGE_ROUTES.LOGIN
  }
  // @ts-expect-error Env variables has been set in the application.html.erb
	const ORG_ID = envVariables.fullstoryOrgId

  return (
    <>
      <FullStory org={ORG_ID} />
      <main className="flex items-center justify-center min-h-screen bg-white xl:bg-grey-100">
        <div className="table-row">
          <div className="table-cell align-middle">
            <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-8 px-9 max-w-[480px] xl:w-[580px] xl:max-w-[700px] space-y-6">
              <div className="flex flex-col items-center space-y-6">
                <Logo />
                <Heading variant='h1' size="h8" weight='medium' className='w-full text-center'>Forgot Password</Heading>
              </div>
              <ToastWrapper toast={toast as ToastProps} />
              <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6" autoComplete='off'>
                <Input name="email" label="Email" id="email" type="email" placeholder='email@acme.com' control={control} rules={{
                  required: ERRORS.EMAIL_REQUIRED,
                  pattern: REGEX_PATTERNS.EMAIL,
                }} error={errors.email} />
                <Button
                  variant="primary"
                  className=''
                  disabled={forgotPasswordIsLoading}
                  loading={forgotPasswordIsLoading}
                  size='lg'
                  rounded='lg'
                >
                  Forgot password
                </Button>
              </form>
              <div className='w-full flex justify-center space-x-2'>
                <Button
                  variant="text"
                  className='text-b5 mx-auto'
                  fontWeight='bold'
                  size='none'
                  type='button'
                  onClick={goToLoginPage}
                >Back to login</Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ForgotPassword