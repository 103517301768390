import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgGroupLink = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M19.5 28.958h-3.167c-3.658 0-5.291-1.633-5.291-5.291V20.5c0-3.658 1.633-5.292 5.291-5.292h2.5a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625h-2.5c-2.983 0-4.041 1.059-4.041 4.042v3.167c0 2.983 1.058 4.041 4.041 4.041H19.5c2.983 0 4.042-1.058 4.042-4.041v-2.5a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v2.5c0 3.658-1.634 5.291-5.292 5.291Z"
      fill="#150326"
    />
    <path
      d="M24.167 21.792H21.5c-2.342 0-3.292-.95-3.292-3.292v-2.667c0-.25.15-.483.384-.575a.63.63 0 0 1 .683.134l5.333 5.333a.627.627 0 0 1-.441 1.067Zm-4.709-4.45V18.5c0 1.658.384 2.042 2.042 2.042h1.158l-3.2-3.2ZM23 12.292h-3.333a.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625H23a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625Z"
      fill="#150326"
    />
    <path
      d="M15.833 14.792a.63.63 0 0 1-.625-.625c0-1.725 1.4-3.125 3.125-3.125h2.184a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625h-2.184a1.878 1.878 0 0 0-1.875 1.875.63.63 0 0 1-.625.625ZM25.992 24.792a.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625 1.72 1.72 0 0 0 1.716-1.717v-5.158a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v5.158a2.973 2.973 0 0 1-2.966 2.967Z"
      fill="#150326"
    />
    <path
      d="M28.333 17.292h-2.5c-2.216 0-3.125-.909-3.125-3.125v-2.5c0-.25.15-.484.384-.575a.63.63 0 0 1 .683.133l5 5a.627.627 0 0 1-.442 1.067Zm-4.375-4.117v.992c0 1.525.35 1.875 1.875 1.875h.992l-2.867-2.867Z"
      fill="#150326"
    />
    <rect x={0.5} y={0.5} width={39} height={39} rx={19.5} stroke="#F2F2F4" />
  </svg>
);

export default SvgGroupLink;
