import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUserBlock = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      stroke="#000"
      strokeWidth={1.5}
    />
    <path
      d="M15.5 13.535C14.47 13.195 13.275 13 12 13c-3.866 0-7 1.79-7 4 0 .345 0 .68.027 1M13 20.987c-.316.009-.65.013-1 .013-1.722 0-3.02-.108-4-.305m11.95-3.645-3.9 3.9"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M18 22a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      stroke="#000"
      strokeWidth={1.5}
    />
  </svg>
);
export default SvgUserBlock;
