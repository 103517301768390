import React from 'react'
import { RedeemVoucherCardProps } from './types'
import Heading from '../../ui/atoms/heading/Heading'
import { COLORS } from '../../constants/colors'
import Paragraph from '../../ui/atoms/paragraph/Paragraph'

const RedeemVoucherCard = (props: RedeemVoucherCardProps) => {
  return (
    <div className='w-full flex flex-col justify-between md:h-[135px] p-4 md:border border-grey-50 rounded-md'>
      <div className='w-full flex flex-col'>
        <Heading variant='h1' size='h11' weight='bold' color={COLORS.GREEN['300']}>{props.title}</Heading>
        <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']} className='hidden md:flex'>{props.subTitle}</Paragraph>
      </div>

      <Paragraph size='b1' weight='normal' color={COLORS.GREY['900']}>{props.digits}</Paragraph>
    </div>
  )
}

export default RedeemVoucherCard
