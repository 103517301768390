
import React from 'react'
import { PAGE_ROUTES } from '../constants/routes';
import Svg404 from '../ui/icons/404';
import Heading from '../ui/atoms/heading/Heading';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import Button from '../ui/atoms/button/Button';
import { COLORS } from '../constants/colors';

const NotFound = () => {
  const goHome = () => {
    window.location.href = PAGE_ROUTES.HOME
  }

  return (
    <div className="w-full h-screen flex justify-center items-center p-6">
      <div className='w-full max-w-[480px] flex flex-col items-center space-y-4'>
        <div className='w-full max-w-[360px] flex flex-col items-center'>
          <Svg404 width="100%" height="154px" />
          <Heading size='h4' variant='h1' weight='bold'>Error</Heading>
        </div>
        <Paragraph size="b2" weight="semiBold" className="text-center" color={COLORS.GREY[400]}>Opps, the page you’re looking for was not found</Paragraph>
        <Button
          variant='primary'
          type='button'
          size='lg'
          rounded='lg'
          onClick={goHome}
          fontWeight='semiBold'
        >GO BACK</Button>
      </div>
    </div>
  )
}

export default NotFound