export const COLORS = {
  GREY: {
    10: '#FBFBFB',
    15: '#E4E4E9',
    20: '#E9E9E9',
    25: '#FCFCFC',
    50: '#F3F6F6',
    75: '#F3F3F6',
    100: '#E2E8E9',
    125: '#BBBBCB',
    200: '#686585',
    300: '#667368',
    400: '#9AA2A5',
    500: '#565656',
    600: '#342C58',
    700: '#383838',
    800: '#4D486E',
    900: '#150326'
  },
  BLUE: {
    25: '#F2F7F8',
    50: '#E6F8FF',
    100: '#B8E6F7',
    150: '#E5FFFE',
    200: '#9BD9EB',
    250: '#85D0D1',
    300: '#5FBCC5',
    350: '#4B9FA4',
    400: '#02487A',
    450: '#008080',
    500: '#007D8A',
    600: '#007774',
    700: '#00605D',
    800: '#005D4D',
    900: '#004E3F'
  },
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  RED: {
    25: "#FCFAFF",
    50: "#FEECED",
    100: "#FCCFD1",
    200: "#EA9E99",
    300: "#DD7972",
    400: "#E65E4E",
    500: "#DA4A34",
    600: "#EE475C",
    700: "#C9402E",
    800: "#BC3A28",
    900: "#AC321D",
  },
  YELLOW: {
    25: "#FEFBF1",
    50: "#FCF7E3",
    100: "#F8E9B8",
    200: "#F4DB8B",
    300: "#F0CF60",
    400: "#EDC346",
    500: "#EABA3B",
    600: "#E9AD35",
    700: "#E58D2D",
    800: "#E58D2D",
    900: "#E17328",
  },
  GREEN: {
    25: '#E9F5F0',
    50: '#E2FBE8',
    75: '#F2F7ED',
    100: '#C8E6C9',
    150: '#E7FFCF',
    200: '#A5D6A7',
    250: '#99d161',
    300: '#16796F',
    400: '#66BB6B',
    500: '#4CAF51',
    600: '#43A048',
    700: '#388E3C',
    800: '#6F934C',
    850: '#025E0C',
    900: '#09423B'
  },
  ORANGE: {
    25: '#FFF9EC',
    50: '#FFDDBB',
    100: '#FFC288',
    200: '#FFA155',
    300: '#DC6803',
    400: '#FF7A00',
    500: '#FF6A00',
    600: '#FF6100',
    700: '#FF5700',
    800: '#FF4D00',
    900: '#FF3D00'
  },
} as const;

export const BRAND_COLORS = {
  PRIMARY: "#5C28AE",
  WHITE: "#FFFFFF",
};

export const TOAST_BG_COLORS = {
  SUCCESS: "#C8E6C9",
  ERROR: "#FCCFD1",
  INFO: "#D9F5F7",
  WARNING: "#F0CF60",
} as const;

export const TOAST_TEXT_COLORS = {
  success: "#1B5E21",
  error: "#AC321D",
  info: "#342C58",
  warning: "#E58D2D",
} as const;

export const APPOINTMENT_COLORS = {
  CONFIRMED: "#B9EDFD",
  CONFIRMED_TEXT: "#198DB1",
  DONE: "#DDF3E3",
  DONE_TEXT: "#4A7759",
  NO_SHOW: "#000000",
  NO_SHOW_TEXT: "#FFFFFF",
  CANCELLED: "#AC321D",
  CANCELLED_TEXT: "#FFFFFF",
  PENDING: "#F0CF60",
  PENDING_TEXT: "#000000",
  CHECKED_IN: "#E6D9F2",
  CHECKED_IN_TEXT: "#663399",
}
