import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCardSelection = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 480 311"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M454.406 0H25.589A25.584 25.584 0 0 0 0 25.519v153.906a4.266 4.266 0 0 0 8.53 0V117.9h462.935v167.581a17.056 17.056 0 0 1-17.059 17.013H25.589A17.057 17.057 0 0 1 8.53 285.481v-52.04a4.266 4.266 0 0 0-8.53 0v52.04A25.583 25.583 0 0 0 25.589 311h428.817a25.58 25.58 0 0 0 25.589-25.519V25.519A25.584 25.584 0 0 0 454.406 0ZM25.589 8.506h428.817a17.055 17.055 0 0 1 17.059 17.013v40.518H8.53V25.519A17.056 17.056 0 0 1 25.589 8.506ZM8.53 109.39V74.544h462.935v34.846H8.53Z"
      fill="#16796F"
    />
    <path
      d="M133.957 242.494a45.972 45.972 0 0 0 48.307 4.715 45.978 45.978 0 1 0-48.307-77.734 45.98 45.98 0 0 0-73.925 36.51 45.978 45.978 0 0 0 73.925 36.509Zm0-61.488a37.312 37.312 0 0 1 0 49.957 37.308 37.308 0 0 1 0-49.957Zm65.549 24.978a37.555 37.555 0 0 1-20.297 33.269 37.563 37.563 0 0 1-38.882-2.641 45.774 45.774 0 0 0 0-61.255 37.561 37.561 0 0 1 38.881-2.64 37.558 37.558 0 0 1 20.298 33.267Zm-131.1 0a37.56 37.56 0 0 1 20.299-33.267 37.56 37.56 0 0 1 38.881 2.64 45.776 45.776 0 0 0 0 61.255 37.56 37.56 0 0 1-53.683-11.155 37.563 37.563 0 0 1-5.497-19.473Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgCardSelection;
