import { PAGE_ROUTES } from '../../constants/routes';
import { AppLoader } from './pages/AppLoader';
import Forgot from './pages/Forgot';
import GeneratePassword from './pages/GeneratePassword';
import Login from './pages/Login';
import Register from './pages/Register';
import Otp from './pages/Otp'


export const routes = [
    {
      path: PAGE_ROUTES.APP_LOADER,
      name: "App Loader",
      Component: AppLoader,
      meta: {
        requiresAuth: false,
    },
    },
    {
        path: PAGE_ROUTES.LOGIN,
        Component: Login,
        name: 'Login',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: PAGE_ROUTES.FORGOT_PASSWORD,
        Component: Forgot,
        name: 'Forgot',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: PAGE_ROUTES.REGISTER,
        Component: Register,
        name: 'Register',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: PAGE_ROUTES.OTP,
        Component: Otp,
        name: 'Otp',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: PAGE_ROUTES.GENERATE_PASSWORD,
        Component: GeneratePassword,
        name: 'GeneratePassword',
        meta: {
            requiresAuth: false,
        },
    },
];