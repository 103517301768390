import * as React from "react";
import BasePill from "./BasePill";
import { BasePillProps } from "./types";

const PendingPill = ({ className, style, children }: BasePillProps) => {
  return (
    <BasePill
      style={style}
      className={`text-blue-400 border border-blue-25 bg-blue-25 ${
        className || ""
      }`}
    >
      {children}
    </BasePill>
  );
};

export default PendingPill;