import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { addDays } from 'date-fns';
import BookingDetailsCard from './BookingDetailsCard';
import BookContext from './context';
import { INITIAL_STEP, PHONE_FORM_STEP, STAFF_SELECTION, USER_DETAILS_STEP } from './constants';
import { convertAvailabilitiesToCalendarFormat, convertServicesToTimeAvailabilityPayload } from './utils';
import DateRangeCarousel from '../../ui/organism/dateRangeCarousel/DateRangeCarousel';
import Paragraph from '../../ui/atoms/paragraph/Paragraph';
import { COLORS } from '../../constants/colors';
import Button from '../../ui/atoms/button/Button';
import BookingBreadcrumb from './BookingBreadcrumb';
import { print } from 'graphql';
import { SalonAvailabilityDocument } from '../../graphql/generated';
import { API_ERRORS } from '../../constants/errors';
import { convertVoucherServicesToTimeAvailabilityPayloadFromBookingSite } from '../customerDasboard/modals/utils';
import BookingSteps from './BookingSteps';
import { generateDateRangeArray } from '../utils';
import moment from 'moment-timezone';
import { SvgArrowLeft, SvgArrowRight, SvgCartBag } from '../../ui/icons';
import { Heading } from '../../ui';


const CheckoutDateTime = () => {
  const { addToast, handleNextStep, selectedServices, voucherServices, selectedSalon, appointmentDateTime, setAppointmentDateTime, voucherToBeRedeemed, businessInfo, isMobile } = useContext(BookContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentDate, setCurrentDate] = useState(new Date());
  const [daySlots, setDaySlots] = useState<DateTimeRangeProps | null>(null);
  const [days, setDays] = useState<string[]>([]);
  const [day, setDay] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentDateTimeZone = moment().tz(businessInfo?.timezone || 'Africa/Lagos');

  // Get the date components in the local time zone
  const currentYear = currentDateTimeZone.year();
  const currentMonth = currentDateTimeZone.month(); // 0-based index for month
  const currentDay = currentDateTimeZone.date();

  // Create a new Date object using the local time components
  const currentBusinessDate = new Date(Date.UTC(currentYear, currentMonth, currentDay));

  useEffect(() => {
    const generateDays = generateDateRangeArray(currentBusinessDate, addDays(currentBusinessDate, 180));
    setDays(generateDays);
    setDay(generateDays[0]);
  }, [])

  useEffect(() => {
    if (day && !voucherToBeRedeemed && Array.isArray(selectedServices) && selectedServices?.length) {
      getServicesAvailability({
        startDate: day,
        endDate: day,
        services: convertServicesToTimeAvailabilityPayload(selectedServices) as []
      });
    }
  }, [day])

  useEffect(() => {
    if (day && voucherToBeRedeemed && Array.isArray(voucherServices)) {
      getServicesAvailability({
        startDate: day,
        endDate: day,
        services: convertVoucherServicesToTimeAvailabilityPayloadFromBookingSite(voucherServices) as []
      });
    }
  }, [day, voucherToBeRedeemed])

  const getServicesAvailability = async ({ startDate, endDate, services }: {
    startDate: string,
    endDate: string,
    services: string[]
  }) => {
    setIsLoading(true)
    axios
      .post(
        '/graphql',
        {
          query: print(SalonAvailabilityDocument),
          variables: {
            startDate, endDate, services, salonId: selectedSalon?.id
          }
        },
      )
      .then((res) => {
        setIsLoading(false)
        const {
          data: {
            data: { salonAvailability }
          }
        } = res

        if (salonAvailability) {
          const slots = convertAvailabilitiesToCalendarFormat(salonAvailability);
          setDaySlots(slots[0]);
        } else {
          addToast({
            message: API_ERRORS.FAILED_TO_FETCH_SALON_AVAILABILITY,
            variant: 'error'
          })
        }
      }).catch((err) => {
        setIsLoading(false)
        addToast({
          message: err[0]?.meessage || API_ERRORS.FAILED_TO_FETCH_SALON_AVAILABILITY,
          variant: 'error'
        })
      })
  }

  const selectAvailableTimeSlot = async (date: string) => {
    setAppointmentDateTime(date);
  }

  const continueToNextStep = () => {
    if (voucherToBeRedeemed) {
      handleNextStep(USER_DETAILS_STEP);
    } else {
      handleNextStep(PHONE_FORM_STEP);
    }
  }
  const getDateRangeCarousel = () => {
    if (Array.isArray(days) && days.length > 0) {
      return (
        <DateRangeCarousel days={days} daySlots={daySlots} setDay={setDay} isLoading={isLoading} selectAvailableTimeSlot={selectAvailableTimeSlot} selectedDate={appointmentDateTime} />
      )
    }

    return (
      <Paragraph className='text-center'>No available time slots</Paragraph>
    )
  }
  return (
    <>
      <div className='w-full flex flex-col px-[10px] xl:px-[80px] pb-[50px]'>
        <BookingBreadcrumb />
        <div className='w-full flex flex-col-reverse xl:flex-row gap-4'>
          {/* Card */}
          {!isMobile ? <BookingDetailsCard /> : null}
          <div className='w-full h-fit flex flex-col xl:max-w-[40%] border border-grey-50 rounded-xl py-8 px-6 gap-8'>
            <div className='w-full flex justify-between items-start'>
              <div className="lg:flex flex-col gap-2 hidden">
                <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>Select date and time</Paragraph>
                {selectedSalon ? (
                  <Paragraph size='b5' color={COLORS.GREY['300']}>Times displayed are in {businessInfo?.country?.utcOffset} time zone</Paragraph>
                ) : null}
              </div>
              <BookingSteps page={"time"} />
            </div>
            {getDateRangeCarousel()}
            <Button
              variant='primary'
              className='hidden lg:flex'
              disabled={!appointmentDateTime}
              size='lg'
              rounded='lg'
              type='button'
              onClick={continueToNextStep}
            >
              Continue
            </Button>
          </div>
          <div className='w-full flex lg:hidden flex-col gap-y-6'>
            <div className='w-full flex flex-col space-y-2'>
              <div className='w-full flex space-x-2 items-center' onClick={() => handleNextStep(STAFF_SELECTION)}>
                <SvgArrowLeft />
                <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]} className=''>Back to Staff Selection</Paragraph>
              </div>
              <Heading variant='h1' size='h9' weight='bold'>Checkout - Select preferred time</Heading>
              <Paragraph size='b5' color={COLORS.GREY['300']}>Times displayed are in {businessInfo?.country?.utcOffset} time zone</Paragraph>
            </div>
            <div className='w-full flex justify-center items-center py-3 gap-2 bg-green-300/10 rounded-sm text-green-300'>
              <SvgCartBag />
              <Button
                variant='text'
                size='none'
                type='button'
                className='border-0 text-green-300 underline'
                fontSize='b4'
                fontWeight='semiBold'
                onClick={() => handleNextStep(INITIAL_STEP)}
              >
                Click to manage cart
              </Button>
            </div>
          </div>
        </div>
      </div>
      {appointmentDateTime ?
        <div className="w-full flex lg:hidden fixed bottom-[20px] h-[60px] px-[20px]">
          <Button
            variant='primary'
            className=''
            size='lg'
            rounded='lg'
            onClick={() => continueToNextStep()}
          >
            <div className='w-full flex justify-between items-center px-4 py-2'>
              <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE} className='w-full text-center'>Proceed to enter your details</Paragraph>
              <SvgArrowRight width="16px" height="16px" />
            </div>
          </Button>
        </div> : null}
    </>
  )
}

export default CheckoutDateTime
