/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller, set, useForm } from 'react-hook-form';
// import axios from 'axios';
import dayjs from 'dayjs';
import type { DatePickerProps } from 'antd';
import { DatePicker, TimePicker } from 'antd';
import ReplaceServiceModal from './ReplaceServiceModal';
import {
  ActionModalProps,
  AppointmentInput,
  AppointmentPackageServiceInputProps,
} from '../uicomponents/appointmentComponents/types';
import { useUserCache } from '../hooks/useUserCache';
import { useSalonCache } from '../hooks/useSalonCache';
import {
  AppointmentPreview,
  AppointmentPreviewDocument,
  AppointmentProduct,
  AppointmentService,
  Client,
  ClientMilestone,
  CreateAppointmentDocument,
  CreateClientDocument,
  FeedbackMessage,
  Milestone,
  PackageVoucher,
  PosDevice,
  Product,
  Salon,
  SalonStaff,
  Service,
  ServiceStaff,
  TransferAccount,
  VoucherService,
} from '../graphql/generated';
import { useModal } from '../hooks/useModal';
import {
  convertSalonServicesNamesToString,
  hasHomeAsLocation,
  hasHomeAsOnlyLocation,
} from '../uicomponents/bookingSales/utils';
import {
  ADD_APPOINTMENT_TYPES,
  APPOINTMENT_PAYMENT_METHOD,
  PAYMENT_METHODS,
  PAYMENT_METHODS_OFFLINE_ONLY,
  PAYMENT_TYPE_NAME_AND_VALUE,
} from '../uicomponents/bookingSales/constants';
import { PACKAGE_STATUS } from '../constants/status';
import {
  checkIfDepositIsRequiredInServices,
  convertServicesToAppointment,
  fetchClientsData,
  fetchPosDevicesData,
  fetchProductsData,
  fetchServicesData,
  fetchStaffListData,
  fetchTransferAccountsData,
  getAppointmentPackagesServiceInputData,
  getAppointmentServiceInputData,
} from './utils';
import { MultiSelectOption } from '../ui/molecules/multiselect/types';
import {
  convertServicesToAppointmentStaffServiceForPreviewPrice,
  formatBankAccountsToSelectField,
  formatClientToSelectFieldAppointmentCreation,
  formatPosTerminalsToSelectField,
  formatProductsToSelectField,
  formatServicesToSelectField,
  formatStaffToSelectField,
  formatStaffToSelectFieldWithEmptyFirstValue,
} from '../utils/utils';
import {
  convert12HourTo24Hour,
  convertFullDateStringToDate,
  convertIsoStringDateStringToTime,
  convertProductsToCommaSeparatedString,
  formatInToPrice,
  formatNumber,
  getHoursAndMinutesString,
  sanitizePhoneNumber,
  validatePhoneNumber,
} from '../utils/misc';
import { Modal } from '../ui/templates/modal/Modal';
import Button from '../ui/atoms/button/Button';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import { COLORS } from '../constants/colors';
import SelectInput from '../ui/molecules/input/SelectInput';
import { DEFAULT_CURRENCY } from '../constants/currency';
import { FormatNumber } from '../ui/atoms/formatNumber/FormatNumber';
import { FormLabel } from '../ui/atoms/formLabel';
import Checkbox from '../ui/atoms/checkbox/Checkbox';
import {
  YES_OR_NO_SETTINGS,
  YES_SETTING_NAME,
} from '../uicomponents/accountSetupComponents/constants';
import {
  getHelpTextForCharacterLeft,
  getHelperTextForReactHookFormErrors,
} from '../utils/form';
import FormTextarea from '../ui/molecules/input/FormTextarea';
import { MAXIMUM_NOTE_LENGTH } from '../constants/form';
import SearchTerm from '../ui/organism/debounceQuery/SearchTerm';
import { API_ERRORS, ERRORS } from '../constants/errors';
import { FormHelperText } from '../ui/atoms/helperText/FormHelperText';
import Input from '../ui/molecules/input/Input';
import MultiSelect from '../ui/molecules/multiselect/multiselect';
import SvgPlus from '../ui/icons/Plus';
import SelectOnchangeField from '../ui/molecules/input/SelectOnchangeField';
import SvgClose from '../ui/icons/Close';
import SingleSelect from '../ui/molecules/singleSelect/SingleSelect';
import SvgFluentEdit from '../ui/icons/FluentEdit';
import Heading from '../ui/atoms/heading/Heading';
import CollapseRow from '../ui/organism/collapseRow/CollapseRow';
import SvgTrash from '../ui/icons/Trash';
import { REGEX_PATTERNS } from '../constants/pattern';
import FormPhone from '../ui/molecules/input/FormPhone';
import SvgChevronLeft from '../ui/icons/ChevronLeft';
import SvgArrowBack from '../ui/icons/ArrowBack';
import VoucherCard from '../ui/organism/voucherCard/VoucherCard';
import { print } from 'graphql';
import axios from 'axios';
import { AppointmentServiceInputProps, SelectedProduct } from './types';
import VoucherCardShimmer from '../ui/organism/voucherCard/VoucherCardShimmer';
import { createSalonClient } from './api';
import { logger } from '../core/logger';
import { Skeleton } from '../ui';
import {
  SvgWhiteDiamond,
  SvgEdit,
  SvgGreyMinus,
  SvgGreyPlus,
  SvgInfo,
} from '../ui/icons';
import Disclaimer from '../uicomponents/bookingSales/Disclaimer';
import { canPerformAction } from '../utils/permission';
import { PERMISSION_CONSTANTS } from '../constants/permission';
import { getStaffWithHighestPay } from '../uicomponents/appointmentComponents/utils';

const AddAppointmentModal = ({
  isVisible,
  closeModal,
  createAppointmentDetails,
  ...props
}: ActionModalProps) => {
  let searchTimeout: ReturnType<typeof setTimeout>;
  const { getSalonData } = useSalonCache();
  const salon: Salon = getSalonData();
  const salonId = salon?.id;
  const [newClient, setNewClient] = useState(false);
  const [serviceStaffSelected, setServiceStaffSelected] = useState<
    string | null
  >(null);
  const [selectedStaff, setSelectedStaff] = useState('');
  const [selectedServices, setSelectedServices] = useState<
    AppointmentServiceInputProps[]
  >([]);
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    []
  );
  const [selectedPackage, setSelectedPackage] = useState<PackageVoucher | null>(
    null
  );
  const [selectedPackageServices, setSelectedPackageServices] = useState<
    AppointmentPackageServiceInputProps[]
  >([]);
  const actions = {
    addAppointment: canPerformAction(
      PERMISSION_CONSTANTS?.calendar?.addAppointment
    ),
    applyDiscounts: canPerformAction(
      PERMISSION_CONSTANTS?.calendar?.applyDiscounts
    ),
    applyPromo: canPerformAction(PERMISSION_CONSTANTS?.calendar?.applyPromo),
  };
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [searchVoucher, setSearchVoucher] = useState<string>('');
  const [enableAddOns, setEnableAddons] = useState(false);
  const [enablePromo, setEnablePromo] = useState(false);
  const [enableDiscount, setEnableDiscount] = useState(false);
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [discountType, setDiscountType] = useState('');
  const [enableProductAddition, setEnableProductAddition] = useState(false);
  const [selectedReward, setSelectedReward] = useState<ClientMilestone>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [promoCode, setPromoCode] = useState<{
    promoType: string;
    promoValue: number;
  } | null>(null);
  const [appointmentSummary, setAppointmentSummary] =
    useState<AppointmentPreview | null>(null);
  const [showAllPackages, setShowAllPackages] = useState(false);
  const [createAppointmentIsLoading, setCreateAppointmentIsLoading] =
    useState(false);
  const defaultVisiblePackages = 3;

  const {
    isVisible: isReplaceServiceModalVisible,
    openModal: openReplaceServiceModalModal,
    closeModal: closeReplaceServiceModalModal,
  } = useModal();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<AppointmentInput>({
    defaultValues: {
      appointmentType: ADD_APPOINTMENT_TYPES[0].value,
      paymentMethod: PAYMENT_TYPE_NAME_AND_VALUE.NOT_PAID.value,
      newClient: false,
      isDepositCompleted: false,
      redeemPackageServicesAsItIs: true,
      isHomeService: hasHomeAsOnlyLocation(salon?.locationType),
    },
  });
  const [countryCode, setCountryCode] = useState('');
  const [callingCode, setCallingCode] = useState('');
  const [depositRequired, setDepositRequired] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [replaceService, setReplaceService] = useState<VoucherService | null>(
    null
  );
  const [replacementService, setReplacementService] = useState<Service | null>(
    null
  );
  const [voucherBaseAppointmentTotal, setVoucherBaseAppointmentTotal] =
    useState<number>(0);
  const [filteredVouchers, setFilteredVouchers] = useState<PackageVoucher[]>(
    []
  );
  const [clients, setClients] = useState<Client[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [bankTransfers, setBankTransfers] = useState<TransferAccount[]>([]);
  const [posTerminals, setPosTerminals] = useState<PosDevice[]>([]);
  const [staff, setStaff] = useState<SalonStaff[]>([]);
  const [clientsOptions, setClientsOptions] = useState<MultiSelectOption[]>([]);
  const [servicesOptions, setServicesOptions] = useState<MultiSelectOption[]>(
    []
  );
  const [productsOptions, setProductsOptions] = useState<MultiSelectOption[]>(
    []
  );
  const [transfersOptions, setTransfersOptions] = useState<MultiSelectOption[]>(
    []
  );
  const [posTerminalsOptions, setPosTerminalsOptions] = useState<
    MultiSelectOption[]
  >([]);
  const [staffMembersOptions, setStaffMembersOptions] = useState<
    MultiSelectOption[]
  >([]);
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [alerts, setAlerts] = useState<FeedbackMessage[]>([]);
  const [appointmentHasNoClient, setAppointmentHasNoClient] =
    useState<boolean>(false);

  const [isPackagesLoading, setIsPackagesLoading] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  const [milestones, setMilestones] = useState<ClientMilestone[]>([]);
  const showVoucherInitialLoadingShimmer = isPackagesLoading && !vouchers;
  const visiblePackages =
    showAllPackages && vouchers ? vouchers.length : defaultVisiblePackages;

  useEffect(() => {
    if (selectedClient) {
      // fetch the selectedClient in clients via id
      setIsPackagesLoading(true);
      const selectedClientData = clients?.find(
        (client) => client.id === selectedClient
      );
      setMilestones(selectedClientData?.activeClientMilestones);
      if (
        selectedClientData &&
        Array.isArray(selectedClientData?.activeClientMilestones) &&
        selectedClientData?.activeClientMilestones?.length
      ) {
        setMilestones(selectedClientData?.activeClientMilestones);
      } else {
        setMilestones([]);
      }
      if (
        selectedClientData &&
        Array.isArray(selectedClientData?.packageVouchers) &&
        selectedClientData?.packageVouchers?.length
      ) {
        setVouchers(selectedClientData?.packageVouchers);
      } else {
        setVouchers([]);
      }
      setIsPackagesLoading(false);
    }
  }, [selectedClient]);

  const getClients = async (q: string) => {
    if (q) {
      setIsLoadingClients(true);
      const clientsData = await fetchClientsData({ salonId, q });
      setClients(clientsData);
      setIsLoadingClients(false);
    }
  };

  const getClientsAsync = async () => {
    setIsLoadingClients(true);
    const clientsData = await fetchClientsData({ salonId }, 10);
    setClients(clientsData);
    setIsLoadingClients(false);
  };

  useEffect(() => {
    if (isVisible && salon) {
      const fetchData = async () => {
        try {
          const productsData = await fetchProductsData({
            salonId,
            isPurchasable: true,
          });
          setProducts(productsData);

          const servicesData = await fetchServicesData({ salonId });
          setServices(servicesData);

          const bankTransfersData = await fetchTransferAccountsData({
            salonId,
          });
          setBankTransfers(bankTransfersData);

          const posTerminalsData = await fetchPosDevicesData({ salonId });
          setPosTerminals(posTerminalsData);

          const staffData = await fetchStaffListData({ salonId });
          setStaff(staffData?.staffs);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      if (salonId) {
        fetchData();
        getClientsAsync();
        setDepositRequired(salon?.isDepositRequired);
        setValue('isDepositCompleted', salon?.isDepositRequired);
      }
    }
  }, [isVisible]);

  useEffect(() => {
    const updateOptions = () => {
      if (Array?.isArray(clients) && clients?.length) {
        setClientsOptions(
          formatClientToSelectFieldAppointmentCreation(clients)
        );
      }
      if (Array?.isArray(services) && services?.length) {
        setServicesOptions(formatServicesToSelectField(services));
      }
      if (Array?.isArray(products) && products?.length) {
        setProductsOptions(formatProductsToSelectField(products));
      }
      if (Array?.isArray(bankTransfers) && bankTransfers?.length) {
        setTransfersOptions(formatBankAccountsToSelectField(bankTransfers));
      }
      if (Array?.isArray(posTerminals) && posTerminals?.length) {
        setPosTerminalsOptions(formatPosTerminalsToSelectField(posTerminals));
      }
      if (Array?.isArray(staff) && staff?.length) {
        setStaffMembersOptions(
          formatStaffToSelectFieldWithEmptyFirstValue(staff, true)
        );
      }
    };

    updateOptions();
  }, [clients, products, services, staff, posTerminals, bankTransfers]);

  useEffect(() => {
    if (vouchers && Array.isArray(vouchers) && vouchers?.length > 0) {
      // get vouchers that status is still active
      const activeVouchers = vouchers.filter(
        (voucher) => voucher?.status === 'active'
      );
      setFilteredVouchers(activeVouchers);
    }
  }, [vouchers]);

  const loadClientsShimmer = isLoadingClients && !clients?.length;

  const removeService = (index?: number) => {
    const selectedServicesWatched = watch('services');
    if (!Array.isArray(selectedServicesWatched) || typeof index !== 'number')
      return;
    if (selectedServicesWatched?.length && selectedServicesWatched?.[index]) {
      selectedServicesWatched.splice(index, 1);
    }
    setValue('services', selectedServicesWatched);

    if (selectedServices?.length && selectedServices?.[index]) {
      selectedServices.splice(index, 1);
    }
    setSelectedServices(selectedServices);
  };

  const removeProduct = (index?: number) => {
    const selectedProductsWatched = watch('products');
    if (!Array.isArray(selectedProductsWatched) || typeof index !== 'number')
      return;
    if (selectedProducts?.length && selectedProducts?.[index]) {
      selectedProducts.splice(index, 1);
    }
    setSelectedProducts(selectedProducts);

    if (selectedProductsWatched?.length && selectedProductsWatched?.[index]) {
      selectedProductsWatched.splice(index, 1);
    }
    setValue('products', selectedProductsWatched);
  };

  useEffect(() => {
    if (watch('clientId')) {
      const clientIdField = watch('clientId') as unknown as {
        label: string;
        value: string;
      };
      if (clientIdField?.value === 'new-client') {
        setNewClient(true);
        setValue('clientEmail', '');
        setValue('clientPhone', '');
        setValue('clientName', '');
      } else {
        if (clientIdField?.value) {
          setSelectedClient(clientIdField?.value);
          const client = clients?.find(
            (client) => client?.id === clientIdField?.value
          ) as Client;
          if (client) {
            setValue('clientEmail', client?.email);
            setValue('clientPhone', client?.phone);
            setValue('address', client?.address);
            setValue('clientName', `${client?.firstName} ${client?.lastName}`);
            setCallingCode(client?.callingCode);
            setCountryCode(client?.countryCode);
            setNewClient(true);
          }
        }
      }
      if (
        (Array.isArray(selectedServices) && selectedServices?.length) ||
        (Array.isArray(selectedProducts) && selectedProducts?.length)
      ) {
        previewPrice();
      }
    }
  }, [watch('clientId')]);

  useEffect(() => {
    if (!depositRequired) {
      const depositIsRequired =
        checkIfDepositIsRequiredInServices(selectedServices);
      setDepositRequired(depositIsRequired);
    }
  }, [selectedServices]);

  useEffect(() => {
    const appointmentServices =
      Array?.isArray(services) && services?.length
        ? convertServicesToAppointment(services)
        : [];
    if (watch('services') && appointmentServices?.length) {
      const serviceSelectedFromOptions = appointmentServices
        .filter((service) => {
          const serviceId = service?.id;
          const existsInServicesField = watch('services')?.some(
            (serviceSelected: MultiSelectOption) =>
              serviceSelected?.value === serviceId
          );
          const existsInSelectedServices = selectedServices.some(
            (selectedService) => selectedService.id === serviceId
          );

          if (!existsInServicesField && existsInSelectedServices) {
            // Return null to indicate that this service should be removed
            setSelectedServices((prevSelectedServices) =>
              prevSelectedServices.filter((s) => s.id !== serviceId)
            );
            return false;
          }
          return existsInServicesField && !existsInSelectedServices;
        })
        .filter((service) => service !== null); // Remove the null values
      if (Array?.isArray(serviceSelectedFromOptions)) {
        setSelectedServices((prevSelectedServices) => [
          ...prevSelectedServices,
          ...serviceSelectedFromOptions,
        ]);
        if (!depositRequired) {
          const depositIsRequired = checkIfDepositIsRequiredInServices(
            serviceSelectedFromOptions
          );
          setDepositRequired(depositIsRequired);
        }
      }
    }
  }, [watch('services')]);

  useEffect(() => {
    if (watch('products')) {
      const productSelectedFromOptions =
        Array?.isArray(products) &&
        products
          ?.filter((product) => {
            const productId = product?.id;
            const existsInProductsField = watch('products')?.some(
              (selectedProduct: MultiSelectOption) =>
                selectedProduct?.value === productId
            );
            const existsInSelectedProducts = selectedProducts.some(
              (selectedProduct) => selectedProduct.id === productId
            );

            if (!existsInProductsField && existsInSelectedProducts) {
              // Return null to indicate that this service should be removed
              setSelectedProducts((prevSelectedProducts) =>
                prevSelectedProducts.filter((p) => p.id !== productId)
              );
              return false;
            }
            return existsInProductsField && !existsInSelectedProducts;
          })
          .filter((product) => product !== null);
      if (
        Array?.isArray(productSelectedFromOptions) &&
        productSelectedFromOptions?.length
      ) {
        setSelectedProducts((prevSelectedProducts) => [
          ...prevSelectedProducts,
          ...productSelectedFromOptions,
        ]);
      }
    }
  }, [watch('products')]);

  useEffect(() => {
    if (watch('redeemPackageServicesAsItIs')) {
      setSelectedServices([]);
      setValue('services', []);
    }
  }, [watch('redeemPackageServicesAsItIs')]);

  useEffect(() => {
    if (watch('addOnAmount')) {
      setVoucherBaseAppointmentTotal(
        parseFloat(watch('addOnAmount')?.toString()?.replace(',', ''))
      );
    }
  }, [watch('addOnAmount')]);

  useEffect(() => {
    if (appointmentHasNoClient) {
      setValue('clientEmail', '');
      setValue('clientPhone', '');
      setValue('clientName', '');
      setValue('clientId', []);
      previewPrice();
    }
  }, [appointmentHasNoClient]);

  const getServiceSummary = () => {
    return getServiceSummaryUtils(
      selectedServices,
      services,
      selectedProducts,
      products,
      removeService,
      removeProduct
    );
  };

  const previewPrice = async () => {
    const client = getClientDetails({
      clientId: watch('clientId'),
      clientName: watch('clientName'),
      clientEmail: watch('clientEmail'),
      clientPhone: watch('clientPhone'),
    } as AppointmentInput);

    let startAt = new Date().toISOString();
    if (watch('appointmentDate') && watch('appointmentTime')) {
      startAt = `${watch('appointmentDate').replace(
        /\//g,
        '-'
      )}T${convert12HourTo24Hour(watch('appointmentTime'))}`;
    }

    if (Array.isArray(selectedServices) && selectedServices.length === 0)
      return;

    const appointmentData = {
      client: !appointmentHasNoClient
        ? {
            phone: client?.phone,
            countryCode: client?.countryCode,
          }
        : null,
      services: convertServicesToAppointmentStaffServiceForPreviewPrice(
        selectedServices,
        startAt
      ),
      ...(selectedProducts.length && {
        products: convertProductsToCommaSeparatedString(selectedProducts),
      }),
      ...(watch('addOnAmount') && {
        addOnAmount: parseFloat(
          watch('addOnAmount')?.toString()?.replace(',', '')
        ),
      }),
      ...(debouncedSearchQuery ? { promoCode: debouncedSearchQuery } : {}),
      paymentMethod: watch('paymentMethod'),
      milestoneId: selectedReward?.milestoneId || null,
      discountType,
      discountValue: Number(watch('discountValue')),
    };
    setCreateAppointmentIsLoading(true);
    setAlerts([]);
    try {
      await axios
        .post(
          '/graphql',
          {
            query: print(AppointmentPreviewDocument),
            variables: { input: { ...appointmentData, salonId: salon?.id } },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        .then(({ data }) => {
          setCreateAppointmentIsLoading(false);
          const appointmentSummary: AppointmentPreview =
            data?.data?.appointmentPreview?.data;
          if (appointmentSummary) {
            setAppointmentSummary(appointmentSummary);

            if (
              selectedPackage &&
              appointmentSummary?.totalPaid > selectedPackage?.price
            ) {
              setVoucherBaseAppointmentTotal(
                appointmentSummary?.totalPaid - selectedPackage?.price
              );
            }
          }

          if (appointmentSummary?.appointmentPromo) {
            setPromoCode({
              promoType: 'amount',
              promoValue: appointmentSummary?.appointmentPromo?.amount,
            });
          }

          if (debouncedSearchQuery && !appointmentSummary?.appointmentPromo) {
            props?.addToast({
              variant: 'error',
              message: 'Promo code could not be applied',
            });
          }

          if (
            Array?.isArray(appointmentSummary?.feedbackMessages) &&
            appointmentSummary?.feedbackMessages?.length
          ) {
            setAlerts(appointmentSummary?.feedbackMessages);
          } else {
            setAlerts([]);
          }
        });
    } catch (appointmentError) {
      setCreateAppointmentIsLoading(false);
      // if (axios.isAxiosError(appointmentError)) {
      //   // const message = appointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_PRICE_SUMMARY_FAILED;
      // }
    }
  };

  useEffect(() => {
    previewPrice();
  }, [
    selectedServices,
    selectedProducts,
    debouncedSearchQuery,
    watch('paymentMethod'),
    watch('appointmentTime'),
    watch('appointmentDate'),
    watch('addOnAmount'),
    selectedReward,
  ]);

  const removeStaff = (staffId: string, serviceId: string) => {
    const serviceSelectedFromOptions = selectedServices.map((service) => {
      if (service?.id === serviceId) {
        return {
          ...service,
          staff: service?.staff?.filter(
            (staffItem) => staffItem?.staffId !== staffId
          ),
        };
      }
      return service;
    });
    setSelectedServices(serviceSelectedFromOptions);
  };

  const removePackageServiceStaff = (staffId: string, serviceId: string) => {
    if (!staff) return;
    if (!staff?.length) return;
    // remove from staff
    const serviceSelectedFromOptions = selectedPackageServices.map(
      (service) => {
        if (service?.id === serviceId) {
          return {
            ...service,
            staff: service?.staff?.filter(
              (staffItem) => staffItem?.staffId !== staffId
            ),
          };
        }
        return service;
      }
    );
    setSelectedPackageServices(serviceSelectedFromOptions);
  };

  useEffect(() => {
    if (createAppointmentDetails) {
      setValue(
        'appointmentDate',
        convertFullDateStringToDate(createAppointmentDetails?.startAt)
      );
      setValue(
        'appointmentTime',
        convertIsoStringDateStringToTime(createAppointmentDetails?.startAt)
      );
    }
  }, [createAppointmentDetails]);

  const setDefaultServices = (appointmentServices: AppointmentService[]) => {
    const serviceSelectedFromOptions = appointmentServices.map(
      (appointmentService) => {
        const service = props.appointment.services.find(
          (x) => x.id === appointmentService.serviceId
        );

        const staff = appointmentService.appointmentServiceStaff?.map(
          (staff) => {
            return {
              firstName: staff?.salonStaff?.user?.firstName,
              staffId: staff?.salonStaff?.id,
            };
          }
        );

        return {
          ...service,
          staff,
          startAt: null,
          quantity: 1,
        };
      }
    );

    if (Array?.isArray(serviceSelectedFromOptions)) {
      setSelectedServices((prevSelectedServices) => [
        ...prevSelectedServices,
        ...serviceSelectedFromOptions,
      ]);
      if (!depositRequired) {
        const depositIsRequired = checkIfDepositIsRequiredInServices(
          serviceSelectedFromOptions
        );
        setDepositRequired(depositIsRequired);
      }
    }
  };

  const setDefaultProducts = (appointmentProducts: AppointmentProduct[]) => {
    const productsSelectedFromOptions = appointmentProducts.map(
      (appointmentProduct) => {
        const product = props.appointment.products.find(
          (x) => x.id === appointmentProduct.productId
        );
        return {
          ...product,
          quantity: 1,
        };
      }
    );

    setSelectedProducts((prevSelectedProducts) => [
      ...prevSelectedProducts,
      ...productsSelectedFromOptions,
    ]);
  };

  const changeReward = () => {
    setSelectedReward(null);
    const element = document.getElementById('rewards-section');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (props.appointment && isVisible) {
      setValue('clientId', {
        label: `${
          props.appointment?.client?.firstName +
          ' ' +
          props.appointment?.client?.lastName
        } - ${props.appointment?.client?.phone}`,
        value: props.appointment.clientId,
      } as unknown as []);
      setValue('clientPhone', props.appointment?.client?.phone);
      setValue('clientEmail', props.appointment?.client?.email);
      setValue(
        'clientName',
        props.appointment?.client?.firstName +
          ' ' +
          props?.appointment?.client?.lastName
      );
      setValue(
        'services',
        formatServicesToSelectField(props.appointment.services) as []
      );
      setNewClient(true);
      setDefaultServices(props.appointment.appointmentServices);
      setDefaultProducts(props.appointment.appointmentProducts);
      setError('appointmentDate', { message: 'Appointment date is required' });
      setError('appointmentTime', { message: 'Add appointment time' });
    }
  }, [props.appointment, isVisible]);

  const onChangeTime = (value: any, dateString: string) => {
    setValue('appointmentTime', dateString);
    clearErrors('appointmentTime');
  };

  const modifyCart = (
    id: string,
    type: 'add' | 'remove',
    inventory: 'product' | 'service' | 'voucher'
  ) => {
    // modify cart for services
    if (inventory === 'service') {
      let remove = false;
      const serviceSelectedFromOptions = selectedServices.map((service) => {
        if (service?.id === id) {
          const quantity = service?.quantity || 1;
          if (type === 'add') {
            return {
              ...service,
              quantity: quantity + 1,
            };
          }
          if (type === 'remove') {
            if (quantity > 1) {
              return {
                ...service,
                quantity: quantity - 1,
              };
            } else {
              remove = true;
            }
          }
        }
        return service;
      });
      if (remove) {
        // get serive index from watch("services") via id
        const serviceIndex = watch('services')?.findIndex(
          (serviceSelected: MultiSelectOption) => serviceSelected?.value === id
        );
        removeService(serviceIndex);
        return;
      }
      setSelectedServices(serviceSelectedFromOptions);
    }

    // modify cart for products
    if (inventory === 'product') {
      let remove = false;
      const productSelectedFromOptions = selectedProducts.map((product) => {
        if (product?.id === id) {
          const quantity = product?.quantity || 1;
          if (type === 'add') {
            const newQuantity = quantity + 1;
            if (newQuantity > product?.stockCount) {
              // flag error stockCount
              props?.addToast &&
                props.addToast({
                  variant: 'error',
                  message: 'Product quantity exceeded',
                });
            } else {
              return {
                ...product,
                quantity: newQuantity,
              };
            }
          }
          if (type === 'remove') {
            if (quantity > 1) {
              return {
                ...product,
                quantity: quantity - 1,
              };
            } else {
              remove = true;
            }
          }
        }
        return product;
      });
      if (remove) {
        // get serive index from watch("services") via id
        const productIndex = watch('products')?.findIndex(
          (productSelected: MultiSelectOption) => productSelected?.value === id
        );
        removeProduct(productIndex);
        return;
      }
      setSelectedProducts(productSelectedFromOptions);
    }

    if (inventory === 'voucher') {
      let remove = false;
      // use selectedPackageServices
      const voucherServicesFromOptions = selectedPackageServices.map(
        (voucherService) => {
          if (voucherService?.id === id) {
            const cartQuantity = voucherService?.cartQuantity || 1;
            if (type === 'add') {
              if (cartQuantity === voucherService?.quantity) {
                // flag error
                props?.addToast &&
                  props.addToast({
                    variant: 'error',
                    message: 'Voucher quantity exceeded',
                  });
              } else {
                return {
                  ...voucherService,
                  cartQuantity: cartQuantity + 1,
                };
              }
            }
            if (type === 'remove') {
              if (cartQuantity > 1) {
                return {
                  ...voucherService,
                  cartQuantity: cartQuantity - 1,
                };
              } else {
                remove = true;
              }
            }
          }
          return voucherService;
        }
      );

      if (remove) {
        // remove service
        removeServiceFromSelectedPackageServices(id);
        return;
      }

      setSelectedPackageServices(voucherServicesFromOptions);
    }
  };

  const applyDiscount = async () => {
    await previewPrice();
    setIsDiscountApplied(true);
  };

  const formatServiceStaff = (
    selectedStaff: { staffId: string }[]
  ): MultiSelectOption[] => {
    if (
      !staff ||
      !staff.length ||
      !staffMembersOptions?.length ||
      !selectedStaff ||
      !selectedStaff?.length
    )
      return [];

    const staffOptions: MultiSelectOption[] = [];

    selectedStaff.forEach((selected) => {
      const matchingStaff = staffMembersOptions.find(
        (staffOption) => staffOption.value === selected.staffId
      );
      if (matchingStaff) {
        staffOptions.push(matchingStaff);
      }
    });

    return staffOptions;
  };

  const formatServiceStaffOptions = (
    service: AppointmentServiceInputProps
  ): MultiSelectOption[] => {
    if (!staff || !staff.length || !staffMembersOptions?.length) return [];

    if (!service?.staff?.length) {
      return staffMembersOptions;
    }

    const staffOptions: MultiSelectOption[] = [];
    const selectedStaffIds = service.staff.map((staff) => staff.staffId);
    // show all staff in staffMembersOptions that is not in selectedStaffIds
    staffMembersOptions.forEach((staffOption) => {
      if (!selectedStaffIds.includes(staffOption.value)) {
        staffOptions.push(staffOption);
      }
    });

    return staffOptions;
  };

  const formatPackageServiceStaffOptions = (
    service: AppointmentPackageServiceInputProps
  ): MultiSelectOption[] => {
    if (!staff || !staff.length || !staffMembersOptions?.length) return [];

    if (!service?.staff?.length) {
      return staffMembersOptions;
    }

    const staffOptions: MultiSelectOption[] = [];
    const selectedStaffIds = service.staff?.map((staff) => staff.staffId);
    // show all staff in staffMembersOptions that is not in selectedStaffIds
    staffMembersOptions.forEach((staffOption) => {
      if (!selectedStaffIds?.includes(staffOption.value)) {
        staffOptions.push(staffOption);
      }
    });

    return staffOptions;
  };

  const onChangeStaff = (selectedStaff: string, serviceId: string) => {
    const matchedItem = staff.find((item) => item.id === selectedStaff);
    // changeStaff(value, serviceId)
    const serviceSelectedFromOptions = selectedServices.map((service) => {
      if (service?.id === serviceId) {
        return {
          ...service,
          staff:
            Array?.isArray(service?.staff) && service?.staff?.length
              ? [
                  ...service.staff,
                  {
                    firstName: matchedItem?.user?.firstName,
                    staffId: matchedItem?.id,
                  },
                ]
              : [
                  {
                    firstName: matchedItem?.user?.firstName,
                    staffId: matchedItem?.id,
                  },
                ],
        };
      }

      return service;
    });
    setSelectedServices(serviceSelectedFromOptions);
    previewPrice();
  };

  const onChangePackageServiceStaff = (
    selectedStaff: string,
    serviceId: string
  ) => {
    const matchedItem = staff.find((item) => item.id === selectedStaff);

    const serviceSelectedFromOptions = selectedPackageServices.map(
      (service) => {
        if (service?.id === serviceId) {
          return {
            ...service,
            staff:
              Array?.isArray(service?.staff) && service?.staff?.length
                ? [
                    ...service.staff,
                    {
                      firstName: matchedItem?.user?.firstName,
                      staffId: matchedItem?.id,
                    },
                  ]
                : [
                    {
                      firstName: matchedItem?.user?.firstName,
                      staffId: matchedItem?.id,
                    },
                  ],
          };
        }

        return service;
      }
    );

    setSelectedPackageServices(serviceSelectedFromOptions);
    previewPrice();
  };

  const chooseReward = (reward: ClientMilestone) => {
    if (selectedReward) {
      setSelectedReward(null);
      return;
    }
    setSelectedReward(reward);
    setDiscountType(null);
    setValue('discountValue', 0);
    setValue('discountType', null);
    setEnableDiscount(false);
  };

  const getServiceSummaryUtils = (
    selectedServices: AppointmentServiceInputProps[],
    services: Service[],
    selectedProducts: SelectedProduct[],
    products: SelectedProduct[],
    servicesCanBeRemoved?: (index?: number) => void,
    productsCanBeRemoved?: (index?: number) => void
  ) => {
    if (
      (Array.isArray(selectedProducts) && selectedProducts?.length) ||
      (Array.isArray(selectedServices) && selectedServices?.length)
    ) {
      return (
        <div className="flex flex-col p-3 space-y-6 border border-grey-20 rounded-md">
          <div className="flex flex-col space-y-4">
            <div className="flex space-x-2">
              <Heading
                variant="h3"
                size="b6"
                color={COLORS.GREY[300]}
                weight="bold"
                className="w-[50%]"
              >
                SELECTED SERVICES
              </Heading>
              {/* <Heading variant='h3' size='b6' color={COLORS.GREY[300]} weight="bold" className='w-[20%]'>SELECT QUANTITY</Heading> */}
              <Heading
                variant="h3"
                size="b6"
                color={COLORS.GREY[300]}
                weight="bold"
                className="w-[30%]"
              >
                ASSIGN STAFF
              </Heading>
              <Heading
                variant="h3"
                size="b6"
                color={COLORS.GREY[300]}
                weight="bold"
                className="w-[20%] flex justify-end"
              >
                PRICE
              </Heading>
              <div className="w-[10%] flex justify-end"></div>
            </div>
            <>
              {selectedServices?.map((service, index) => {
                const quantity = service?.quantity || 1;
                const staff = getStaffWithHighestPay(
                  service?.serviceStaffs,
                  service?.staff
                );
                return (
                  <div className="flex items-start space-x-2" key={service?.id}>
                    <div className="flex flex-col w-[50%]">
                      <Paragraph
                        size="b4"
                        color={COLORS.GREY[300]}
                        className=""
                      >
                        {service?.name}
                      </Paragraph>{' '}
                      <span className="text-grey-300 text-b6">
                        {getHoursAndMinutesString(service?.duration)}
                      </span>
                    </div>
                    {/* <div className="w-[20%] flex space-x-2">
                      <div className="w-full flex pt-2">
                        <Button
                          variant='icon'
                          size="none"
                          type="button"
                          className="border-0"
                          onClick={() => modifyCart(service?.id, 'add', 'service')}
                        >
                          <SvgGreyPlus width="22px" height="22px" />
                        </Button>
                        <div className="flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center">
                          <Paragraph size='b6' weight='normal' color={COLORS.BLACK}>{service?.quantity || 1}</Paragraph>
                        </div>
                        <Button
                          variant='icon'
                          size="none"
                          type="button"
                          className="border-0"
                          onClick={() => modifyCart(service?.id, 'remove', 'service')}
                        >
                          <SvgGreyMinus width="22px" height="22px" />
                        </Button>
                      </div>
                    </div> */}
                    <div className="flex gap-2 flex-wrap w-[30%]">
                      {Array.isArray(service?.staff) && service?.staff.length
                        ? service?.staff.map((eachStaff, index) => (
                            <div
                              className="flex space-x-2 items-center bg-white border border-grey-50 px-[10px] py-2 rounded-full"
                              key={index}
                            >
                              <Paragraph size="b6" color={COLORS.GREY[300]}>
                                {eachStaff.firstName}
                              </Paragraph>
                              <span
                                className="text-b6 cursor-pointer"
                                onClick={() => {
                                  removeStaff(eachStaff?.staffId, service?.id);
                                }}
                              >
                                <SvgClose width="14px" height="14px" />
                              </span>
                            </div>
                          ))
                        : null}
                      <div className="w-full max-w-[80px]">
                        <SelectOnchangeField
                          options={formatServiceStaffOptions(service) || []}
                          onChange={(selectedValue) => {
                            onChangeStaff(selectedValue, service?.id); // Call the changeStaff function with the selected value
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex justify-end w-[20%]">
                      <Paragraph
                        size="b4"
                        color={COLORS.GREY[300]}
                        className=""
                      >
                        {DEFAULT_CURRENCY}
                        <FormatNumber
                          value={(staff?.price || service?.price) * quantity}
                        />
                        {service?.pricingType === 'from' ? (
                          <span className="text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full ml-2">
                            from
                          </span>
                        ) : null}
                      </Paragraph>
                    </div>
                    <div className="w-[10%] flex justify-end items-center">
                      <span
                        className="text-red-600 text-b5 cursor-pointer"
                        onClick={() => {
                          servicesCanBeRemoved && servicesCanBeRemoved(index);
                        }}
                      >
                        <SvgTrash width="14px" height="14px" />
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex space-x-2 mt-4">
              <Heading
                variant="h3"
                size="b6"
                color={COLORS.GREY[300]}
                weight="bold"
                className="w-[50%]"
              >
                SELECTED PRODUCTS
              </Heading>
              <Heading
                variant="h3"
                size="b6"
                color={COLORS.GREY[300]}
                weight="bold"
                className="w-[30%]"
              >
                SELECT QUANTITY
              </Heading>
              <Heading
                variant="h3"
                size="b6"
                color={COLORS.GREY[300]}
                weight="bold"
                className="w-[20%] flex justify-end"
              >
                PRICE
              </Heading>
              <div className="w-[10%] flex justify-end"></div>
            </div>
            <>
              {selectedProducts?.map((product, index) => {
                const quantity = product?.quantity || 1;
                return (
                  <div className="flex items-start space-x-2" key={product?.id}>
                    <div className="flex w-[50%]">
                      <Paragraph
                        size="b4"
                        color={COLORS.GREY[300]}
                        className="flex-1"
                      >
                        {product?.name}
                      </Paragraph>
                    </div>
                    <div className="w-[30%] flex space-x-2">
                      <div className="w-full flex">
                        <Button
                          variant="icon"
                          size="none"
                          type="button"
                          className="border-0"
                          onClick={() =>
                            modifyCart(product?.id, 'add', 'product')
                          }
                        >
                          <SvgGreyPlus width="22px" height="22px" />
                        </Button>
                        <div className="flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center">
                          <Paragraph
                            size="b6"
                            weight="normal"
                            color={COLORS.BLACK}
                          >
                            {product?.quantity || 1}
                          </Paragraph>
                        </div>
                        <Button
                          variant="icon"
                          size="none"
                          type="button"
                          className="border-0"
                          onClick={() =>
                            modifyCart(product?.id, 'remove', 'product')
                          }
                        >
                          <SvgGreyMinus width="22px" height="22px" />
                        </Button>
                      </div>
                    </div>
                    <div className="flex justify-end w-[20%]">
                      <Paragraph
                        size="b4"
                        color={COLORS.GREY[300]}
                        className=""
                      >
                        {DEFAULT_CURRENCY}
                        <FormatNumber value={product?.retailPrice * quantity} />
                      </Paragraph>
                    </div>
                    <div className="w-[10%] flex justify-end items-center">
                      <span
                        className="text-red-600 text-b5 cursor-pointer"
                        onClick={() => {
                          productsCanBeRemoved && productsCanBeRemoved(index);
                        }}
                      >
                        <SvgTrash width="14px" height="14px" />
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          </div>
          {appointmentSummary ? (
            <div className="w-full flex flex-col space-y-2 border-t border-grey-100 py-3">
              <div className="flex justify-between items-center">
                <Paragraph size="b4" color={COLORS.GREY[300]} weight="semiBold">
                  Subtotal
                </Paragraph>
                <Paragraph size="b4" color={COLORS.GREY[300]} weight="semiBold">
                  {formatInToPrice(
                    ((appointmentSummary?.totalServicesAmount as number) +
                      appointmentSummary?.totalProductsAmount) as number
                  )}
                </Paragraph>
              </div>

              {watch('addOnAmount') ? (
                <div className="flex justify-between items-center">
                  <Paragraph size="b4" color={COLORS.GREY[300]}>
                    Addon
                  </Paragraph>
                  <Paragraph size="b4" color={COLORS.GREY[300]}>
                    +{DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.addOnAmount} />
                  </Paragraph>
                </div>
              ) : null}
              <div className="flex justify-between items-center">
                <Paragraph size="b4" color={COLORS.GREY[300]}>
                  Tax
                </Paragraph>
                <Paragraph size="b4" color={COLORS.GREY[300]}>
                  {DEFAULT_CURRENCY}
                  <FormatNumber value={appointmentSummary?.taxAmount} />
                </Paragraph>
              </div>

              {appointmentSummary?.cancellationFee > 0 ? (
                <div className="flex justify-between items-center">
                  <Paragraph size="b4" color={COLORS.GREY[300]}>
                    Outstanding cancellation fee
                  </Paragraph>
                  <Paragraph size="b4" color={COLORS.GREY[300]}>
                    {DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.cancellationFee} />
                  </Paragraph>
                </div>
              ) : null}

              {enableDiscount && !isDiscountApplied ? (
                <div className="flex justify-between items-center">
                  <div>
                    <Paragraph size="b4" color={COLORS.GREY[300]}>
                      Discount
                    </Paragraph>
                  </div>
                  <div className="flex w-1/2 border rounded-lg">
                    <div className="pl-2 py-2 rounded-l ">
                      <Button
                        rounded="sm"
                        size="sm"
                        type="button"
                        variant="light"
                        className={
                          discountType === 'percentage'
                            ? 'border-2 text-green-500'
                            : ''
                        }
                        onClick={() => setDiscountType('percentage')}
                      >
                        %
                      </Button>
                    </div>
                    <div className=" py-2">
                      <Button
                        rounded="sm"
                        size="sm"
                        type="button"
                        variant="light"
                        className={
                          discountType === 'fixed_value'
                            ? 'border-2 text-green-500'
                            : ''
                        }
                        onClick={() => setDiscountType('fixed_value')}
                      >
                        {DEFAULT_CURRENCY}
                      </Button>
                    </div>
                    <div className="py-2 flex-1">
                      <Input
                        control={control}
                        type="number"
                        id="discount"
                        name="discountValue"
                        className="h-5 py-4 border-0"
                        rules={{
                          pattern: REGEX_PATTERNS.NUMBER,
                        }}
                      />
                    </div>
                    <div className="py-2 rounded-r">
                      <Button
                        rounded="sm"
                        size="sm"
                        type="button"
                        variant="transparent"
                        onClick={() => applyDiscount()}
                        disabled={!discountType}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}

              {appointmentSummary.discountAmount && isDiscountApplied ? (
                <div className="flex justify-between items-center">
                  <Paragraph size="b4" color={COLORS.GREY[300]}>
                    Discount
                  </Paragraph>
                  <div className="flex items-center">
                    <Paragraph
                      className="mr-4"
                      size="b4"
                      color={COLORS.GREY[300]}
                    >
                      -{DEFAULT_CURRENCY}
                      <FormatNumber
                        value={appointmentSummary?.discountAmount}
                      />
                    </Paragraph>

                    <span
                      className="mr-3"
                      style={{
                        marginRight: '10px',
                        marginLeft: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setIsDiscountApplied(false);
                        setEnableDiscount(true);
                      }}
                    >
                      <SvgEdit width="15px" height="15px" />
                    </span>
                    <span
                      className="text-red-600 cursor-pointer"
                      onClick={() => {
                        setIsDiscountApplied(false);
                        setEnableDiscount(false);
                        setValue('discountValue', null);
                        setDiscountType(null);
                      }}
                    >
                      <SvgTrash width="15px" height="15px" />
                    </span>
                  </div>
                </div>
              ) : null}

              {appointmentSummary?.appointmentPromo ? (
                <div className="flex justify-between items-center">
                  <Paragraph size="b4" color={COLORS.GREY[300]}>
                    Promo code
                  </Paragraph>
                  <Paragraph size="b4" color={COLORS.GREY[300]}>
                    -{DEFAULT_CURRENCY}
                    <FormatNumber
                      value={appointmentSummary?.appointmentPromo?.amount}
                    />
                  </Paragraph>
                </div>
              ) : null}

              {selectedReward &&
              appointmentSummary?.loyaltyDiscountAmount > 0 ? (
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <div className="flex space-x-3 items-center">
                      <Paragraph size="b4" color={COLORS.GREY[300]}>
                        Reward applied
                      </Paragraph>
                      <Button
                        variant="text"
                        size="none"
                        type="button"
                        className="border-0 text-red-600"
                        fontSize="b4"
                        fontWeight="semiBold"
                        onClick={changeReward}
                      >
                        <SvgTrash />
                      </Button>
                    </div>
                    <Paragraph size="b5" color={COLORS.GREY[400]}>
                      {selectedReward?.milestone?.customerReceivesType ===
                      'percentage'
                        ? `${selectedReward?.milestone?.customerReceives}%`
                        : formatInToPrice(
                            selectedReward?.milestone?.customerReceives
                          )}{' '}
                      off • Points used:{' '}
                      {selectedReward?.milestone?.customerPointsAttained}pts
                    </Paragraph>
                  </div>
                  <Paragraph size="b4" color={COLORS.GREY[300]}>
                    -{DEFAULT_CURRENCY}
                    <FormatNumber
                      value={appointmentSummary?.loyaltyDiscountAmount}
                    />
                  </Paragraph>
                </div>
              ) : null}

              <div className="flex justify-between items-center">
                <Paragraph size="b4" color={COLORS.GREY[300]} weight="bold">
                  Total
                </Paragraph>
                <Paragraph size="b4" color={COLORS.GREY[300]} weight="bold">
                  {DEFAULT_CURRENCY}
                  <FormatNumber value={appointmentSummary?.totalPaid} />
                </Paragraph>
              </div>
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  const getClientDetails = (input: AppointmentInput) => {
    const clientId = input?.clientId as unknown as {
      label: string;
      value: string;
    };

    if (clientId?.value === 'new-client') {
      return {
        firstName: input?.clientName?.split(' ')[0],
        lastName: input?.clientName?.split(' ')[1] || ' ',
        email: input?.clientEmail || null,
        phone: input?.clientPhone,
        callingCode,
        countryCode,
      };
    }
    // check client details from the list of clients
    const client = clients?.find(
      (client) => client?.id === clientId?.value
    ) as Client;
    return {
      firstName: client?.firstName,
      lastName: client?.lastName,
      email: client?.email,
      phone: client?.phone,
      callingCode: client?.callingCode,
      countryCode: client?.countryCode,
    };
  };

  const processClientDetails = async (input: AppointmentInput) => {
    const clientId = input?.clientId as unknown as {
      label: string;
      value: string;
    };

    if (clientId?.value === 'new-client') {
      let _clientId = null;
      const clientInput = {
        firstName: input?.clientName?.split(' ')[0],
        lastName: input?.clientName?.split(' ')[1] || ' ',
        email: input?.clientEmail || null,
        phone: input?.clientPhone,
        callingCode,
        countryCode,
      };
      try {
        await axios
          .post(
            '/graphql',
            {
              query: print(CreateClientDocument),
              variables: { input: { ...clientInput, salonId } },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          )
          .then(({ data }) => {
            if (data?.data?.createClient?.client) {
              _clientId = data?.data?.createClient?.client?.id;
            }
            if (data?.errors?.length) {
              const message =
                data?.errors[0]?.message ||
                API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
              props?.addToast &&
                props.addToast({
                  variant: 'error',
                  message,
                });
            }
            if (data?.data?.createClient?.errors?.length) {
              const message =
                data?.data?.createClient?.errors[0]?.message ||
                API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
              props?.addToast &&
                props.addToast({
                  variant: 'error',
                  message,
                });
            }
          });
      } catch (createClientError) {
        if (axios.isAxiosError(createClientError)) {
          const message =
            createClientError?.response?.data?.message ||
            API_ERRORS.BUSINESS_CLIENTS_CREATE_FAILED;
          props?.addToast &&
            props.addToast({
              message,
              variant: 'error',
            });
        }
      }
      return _clientId;
    }
    // check client details from the list of clients
    const client = clients?.find(
      (client) => client?.id === clientId?.value
    ) as Client;
    return client?.id;
  };

  const addAppointment = async (input: AppointmentInput) => {
    let inputData = {};
    try {
      const appointmentDate = input?.appointmentDate.replace(/\//g, '-');
      const startAt = `${appointmentDate}T${convert12HourTo24Hour(
        input.appointmentTime
      )}`;
      let appointmentServices;
      if (
        selectedPackage &&
        selectedPackageServices?.length &&
        input?.redeemPackageServicesAsItIs
      ) {
        appointmentServices = getAppointmentPackagesServiceInputData(
          selectedPackageServices
        );
      } else {
        appointmentServices = getAppointmentServiceInputData(selectedServices);
      }

      // throw an error if appointmentServices is empty
      if (!appointmentServices || !appointmentServices?.length) {
        props?.addToast &&
          props.addToast({
            message: 'Kindly select at least one service',
            variant: 'error',
          });
        return;
      }

      const servicesWithoutStaff = appointmentServices?.filter(
        (service) =>
          service?.staffIds === null || service?.staffIds?.length === 0
      );

      if (servicesWithoutStaff && servicesWithoutStaff.length) {
        props?.addToast &&
          props.addToast({
            message: 'Kindly select a staff for each service',
            variant: 'error',
          });
        return;
      }

      // throw an error if client firstName is empty
      if (!appointmentHasNoClient && !input?.clientName?.split(' ')[0]) {
        props?.addToast &&
          props.addToast({
            message: 'Kindly choose a client',
            variant: 'error',
          });
        return;
      }

      if (
        appointmentHasNoClient &&
        depositRequired &&
        input?.paymentMethod === 'unpaid'
      ) {
        props?.addToast &&
          props.addToast({
            message: 'Kindly choose a payment method',
            variant: 'error',
          });
        return;
      }

      let paymentMethodsInput;

      if (input?.isDepositCompleted && !selectedPackage) {
        paymentMethodsInput = {
          appointmentPaymentMethodId: input?.paymentMethodId,
          appointmentPaymentMethod: input?.paymentMethod,
          paymentType: 'deposit_payment',
        };
      } else if (selectedPackage && input?.redeemPackageServicesAsItIs) {
        paymentMethodsInput = {
          appointmentPaymentMethodId: null,
          appointmentPaymentMethod: 'voucher',
          paymentType: 'full_payment',
        };
      } else if (
        selectedPackage &&
        selectedPackage?.price - selectedPackage.amountRedeemed <
          appointmentSummary?.totalPrice &&
        !input?.redeemPackageServicesAsItIs
      ) {
        paymentMethodsInput = {
          appointmentPaymentMethodId: null,
          appointmentPaymentMethod: 'voucher',
          paymentType: 'deposit_payment',
        };
      } else {
        paymentMethodsInput = {
          appointmentPaymentMethod: input?.paymentMethod,
          appointmentPaymentMethodId: input?.paymentMethodId,
          paymentType: 'full_payment',
        };
      }

      inputData = {
        clientInput: !appointmentHasNoClient
          ? {
              firstName: input?.clientName?.split(' ')[0],
              lastName: input?.clientName?.split(' ')[1] || ' ',
              email: input?.clientEmail || null,
              phone: input?.clientPhone,
              callingCode,
              countryCode,
            }
          : null,
        startAt,
        services: appointmentServices,
        products: selectedProducts?.length
          ? convertProductsToCommaSeparatedString(selectedProducts)
          : [],
        note: input?.note || watch('note'),
        ...paymentMethodsInput,
        appointmentType: input?.appointmentType,
        locationType: input?.isHomeService ? 'home' : 'salon',
        ...(input?.isHomeService && { address: input?.address }),
        ...(input?.addOnAmount
          ? {
              addOnAmount: parseFloat(
                input?.addOnAmount?.toString()?.replace(',', '')
              )
            }
          : {}),
        addOnReason: input?.addOnReason,
        ...(selectedReward && {
          milestoneId: selectedReward?.milestoneId,
          clientMilestoneId: selectedReward?.id,
        }),
        discountType,
        ...(discountType && watch('discountValue') ? { discountValue: Number(watch('discountValue')) } : {}),
        source: 'private',
        ...(debouncedSearchQuery ? { promoCode: debouncedSearchQuery } : {}),
        ...(selectedPackage
          ? {
              voucherId: selectedPackage?.id,
              voucherRedemptionMode: input?.redeemPackageServicesAsItIs
                ? 'services'
                : 'amount',
            }
          : {}),
      };

      setCreateAppointmentIsLoading(true);
      // logger.info(`Creating an appointment: Business Page: ${JSON.stringify(inputData)}`);
      await axios
        .post(
          '/graphql',
          {
            query: print(CreateAppointmentDocument),
            variables: {
              input: { ...inputData, salonId: props?.salonId as string },
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        .then(({ data }) => {
          setCreateAppointmentIsLoading(false);
          if (data?.data?.createAppointment?.status === 201) {
            const responseData = data?.data?.createAppointment.appointment;
            if (responseData) {
              resetFormInput();
              getClientsAsync();
              closeModal('addedAppointment');
            }
          }

          if (data?.errors?.length) {
            const message =
              data?.errors[0]?.message ||
              API_ERRORS.APPOINTMENT_CREATION_FAILED;
            props?.addToast &&
              props.addToast({
                variant: 'error',
                message,
              });
          }

          if (data?.data?.createAppointment?.errors?.length) {
            const message =
              data?.data?.createAppointment?.errors[0]?.message ||
              API_ERRORS.APPOINTMENT_CREATION_FAILED;
            props?.addToast &&
              props.addToast({
                variant: 'error',
                message,
              });
          }
        });
    } catch (addAppointmentError) {
      setCreateAppointmentIsLoading(false);
      if (axios.isAxiosError(addAppointmentError)) {
        const message =
          addAppointmentError?.response?.data?.message ||
          API_ERRORS.APPOINTMENT_CREATION_FAILED;
        props?.addToast &&
          props.addToast({
            message,
            variant: 'error'
          })
        logger.error(`Failed to add appointment: ${message}`, inputData)
        logger.exception(addAppointmentError, inputData)
      }
    }
  };

  const resetFormInput = () => {
    setValue('appointmentDate', '');
    setValue('appointmentTime', '');
    setValue('clientId', []);
    setValue('clientPhone', '');
    setValue('clientEmail', '');
    setValue('newClient', false);
    setValue('address', '');
    setValue('isHomeService', false);
    setValue('clientName', '');
    setValue('note', '');
    setValue('addOnReason', '');
    setValue('addOnAmount', 0);
    setValue('services', []);
    setValue('products', []);
    setValue('paymentMethod', PAYMENT_METHODS[0].value);
    setValue('appointmentType', ADD_APPOINTMENT_TYPES[0].value);
    setValue('isDepositCompleted', false);
    setEnableAddons(false);
    setEnablePromo(false);
    setIsDiscountApplied(false);
    setDiscountType(null);
    setValue('discountValue', 0);
    setValue('discountType', null);
    setEnableDiscount(false);
    setSelectedServices([]);
    setSelectedProducts([]);
    setNewClient(false);
    setSelectedPackage(null);
    setSelectedPackageServices([]);
    setShowAllPackages(false);
    setSelectedClient(null);
    setDebouncedSearchQuery('');
    setAlerts([]);
    setAppointmentSummary(null);
    setSelectedReward(null);
    setMilestones([]);
    setClients([]);
    setVouchers([]);
    setFilteredVouchers([]);
    setAppointmentHasNoClient(false);
  };

  const handleSeeAllClick = () => {
    setShowAllPackages(!showAllPackages);
  };

  useEffect(() => {
    if (vouchers && Array.isArray(vouchers) && vouchers?.length > 0) {
      if (searchVoucher !== '') {
        const _searchVoucher = searchVoucher.toLowerCase();
        const _vouchers = vouchers.filter((packageItem) => {
          const _packageItemName = packageItem.package?.name.toLowerCase();
          return _packageItemName.includes(_searchVoucher);
        });
        setFilteredVouchers(_vouchers);
      } else {
        setFilteredVouchers(vouchers);
      }
    }
  }, [searchVoucher]);

  useEffect(() => {
    const clientId = watch('clientId') as unknown as {
      label: string;
      value: string;
    };
    const clientPhone = watch('clientPhone');

    if (
      clientId?.value &&
      clientId?.value !== undefined &&
      debouncedSearchQuery &&
      ((clientId?.value === 'new_client' && clientPhone !== '') ||
        clientId?.value !== 'new_client')
    ) {
      previewPrice();
    }
  }, [debouncedSearchQuery, watch('clientId'), watch('clientPhone')]);

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue('clientPhone', _phoneNumber);

    // Validate the phone number using `awesome-phonenumber`
    const pn = validatePhoneNumber(_phoneNumber, countryCode);

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('clientPhone', {
        type: 'manual',
        message: 'Invalid phone number',
      });
    } else {
      // Clear the error if the phone number is valid
      clearErrors('clientPhone');
    }
  };

  const closeAddAppointmentModal = () => {
    resetFormInput();
    closeModal('close');
  };

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setValue('appointmentDate', dateString);
    clearErrors('appointmentDate');
  };

  const searchClient = (e: any) => {
    // Clear any previously set timeout to avoid multiple calls
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout to delay the execution of getClients
    searchTimeout = setTimeout(() => {
      getClients(e);
    }, 500);
  };

  const chooseSalonPackage = (salonPackage: PackageVoucher) => {
    if (selectedPackage?.id === salonPackage?.id) {
      setSelectedPackage(null);
      setSelectedPackageServices([]);
      setValue('paymentMethod', PAYMENT_TYPE_NAME_AND_VALUE.NOT_PAID.value);
      return;
    }
    setSelectedPackage(salonPackage);
    setShowAllPackages(false);
    setValue(
      'redeemPackageServicesAsItIs',
      salonPackage?.redemptionMode === 'amount' ? false : true
    );
    const services = salonPackage.voucherServices
      .filter((service) => !service?.isRedeemed)
      .map((service) => ({
        ...service,
        staff: null,
        replacementService: null,
        cartQuantity: 1,
      }));
    setSelectedPackageServices(services);
    setValue('paymentMethod', PAYMENT_TYPE_NAME_AND_VALUE.VOUCHER.value);
  };

  const removeServiceFromSelectedPackageServices = (serviceId: string) => {
    if (!selectedPackageServices) return;
    const newSelectedPackageServices = selectedPackageServices.filter(
      (service) => service.id !== serviceId
    );
    setSelectedPackageServices(newSelectedPackageServices);
  };

  const replaceServiceAsync = (service: VoucherService) => {
    setReplaceService(service);
  };

  const replacePackageService = (e: { label: string; value: string }) => {
    if (!replaceService) return;
    const service = services.find((service) => service.id === e.value);
    if (!service) return;
    setReplacementService(service);
    openReplaceServiceModalModal();
  };

  const initiateCloseReplaceServiceModal = (action: string) => {
    if (action === 'replace') {
      const newSelectedPackageServices = selectedPackageServices.map(
        (service) => {
          if (service.id === replaceService?.id) {
            return {
              ...service,
              replacementService: replacementService,
            };
          }
          return service;
        }
      );

      setSelectedPackageServices(newSelectedPackageServices);
    }
    setReplaceService(null);
    setReplacementService(null);
    closeReplaceServiceModalModal();
  };

  const removeReplacementServiceAsync = (serviceId?: string) => {
    if (!serviceId) return;
    const newSelectedPackageServices = selectedPackageServices.map(
      (service) => {
        if (service.id === serviceId) {
          return {
            ...service,
            replacementService: null,
          };
        }
        return service;
      }
    );
    setSelectedPackageServices(newSelectedPackageServices);
  };

  const dateFormat = 'YYYY/MM/DD';

  const closeAlert = (index) => {
    const newAlerts = alerts.filter((alert, i) => i !== index);
    setAlerts(newAlerts);
  };

  const country = JSON.parse(localStorage.getItem('country'));
  const APPOINTMENT_PAYMENT_METHODS =
    country?.payoutsSupported === true && country?.collectionsSupported === true
      ? PAYMENT_METHODS
      : PAYMENT_METHODS_OFFLINE_ONLY;

  return (
    <>
      <Modal
        show={isVisible}
        closeModal={closeAddAppointmentModal}
        variant="right"
      >
        <form
          onSubmit={handleSubmit(addAppointment)}
          className="w-full relative my-[80px]"
          autoComplete="off"
        >
          <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
            <div className="w-full hidden xl:flex space-x-2 px-6 py-5 items-start">
              <Button
                variant="text"
                size="none"
                type="button"
                className="w-fit"
                fontSize="b4"
                onClick={closeAddAppointmentModal}
              >
                <SvgArrowBack width="24px" height="24px" /> <span>Back</span>
              </Button>
            </div>
            <div className="w-full flex xl:hidden space-x-[69px] p-4 items-center">
              <Button
                variant="icon"
                size="square"
                type="button"
                rounded="md"
                className="w-fit"
                fontSize="b4"
                onClick={closeAddAppointmentModal}
              >
                <SvgChevronLeft width="24px" height="24px" />
              </Button>
              <Paragraph size="b3" weight="bold">
                Add Appointment
              </Paragraph>
            </div>
          </div>
          <div className="w-full flex justify-between items-start px-6 py-4">
            <div className="w-full xl:w-1/2 flex flex-col space-y-6">
              <Heading variant="h1" size="h9" weight="semiBold">
                Add an Appointment
              </Heading>
              <Paragraph size="b4">
                Create a new appointment for a client
              </Paragraph>
            </div>
            <div className="w-full xl:w-[400px] fixed flex flex-col h-[100px] right-0 top-8 z-[99999] overflow-y-scroll">
              {Array?.isArray(alerts) && alerts?.length
                ? alerts?.map((alert, index) => (
                    <div
                      className={`w-full rounded-md p-3 flex space-x-2 bg-orange-25 text-orange-300`}
                      key={index}
                    >
                      <SvgInfo width="25px" height="25px" />
                      <div className="flex flex-col space-y-2">
                        <Heading
                          variant="h2"
                          size="h11"
                          weight="semiBold"
                          color={COLORS.ORANGE[300]}
                        >
                          {alert?.message}
                        </Heading>
                        <Paragraph size="b6" color={COLORS.ORANGE[300]}>
                          Kindly reassign the service to another staff or you
                          may choose to proceed with your selection.
                        </Paragraph>
                      </div>
                      <Button
                        variant="text"
                        size="none"
                        type="button"
                        className="w-fit text-orange-300"
                        fontSize="b4"
                        onClick={() => closeAlert(index)}
                      >
                        <SvgClose width="24px" height="24px" />
                      </Button>
                    </div>
                  ))
                : null}
            </div>
          </div>

          {appointmentSummary?.cancellationFeeMessage && (
            <div className="w-full p-6 space-y-6">
              <div
                className={`w-full rounded-md p-3 flex space-x-2 bg-orange-25 text-orange-300`}
                key={1}
              >
                <SvgInfo width="25px" height="25px" />
                <div className="flex flex-col space-y-2">
                  <Heading
                    variant="h2"
                    size="h11"
                    weight="semiBold"
                    color={COLORS.ORANGE[300]}
                  >
                    Outstanding cancellation fee
                  </Heading>
                  <Paragraph size="b6" color={COLORS.ORANGE[300]}>
                    {appointmentSummary?.cancellationFeeMessage}
                  </Paragraph>
                </div>
              </div>
            </div>
          )}

          <div className="w-full p-6 space-y-6">
            <Controller
              control={control}
              name="appointmentType"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="w-full flex flex-col space-y-4">
                    <label className="text-b6 xl:text-b4 text-grey-900 font-normal w-full block subpixel-antialiased">
                      Appointment type
                    </label>
                    <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                      {Array.isArray(ADD_APPOINTMENT_TYPES) &&
                      ADD_APPOINTMENT_TYPES.length
                        ? ADD_APPOINTMENT_TYPES.map(
                            (appointmentType: {
                              label: string;
                              value: string;
                            }) => {
                              return (
                                <div
                                  className="flex"
                                  onClick={() =>
                                    onChange(appointmentType?.value)
                                  }
                                  key={appointmentType?.value}
                                >
                                  <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                    <Checkbox
                                      isChecked={
                                        value === appointmentType?.value
                                      }
                                    />
                                    <span className="text-grey-900 whitespace-nowrap">
                                      {appointmentType?.label}
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )
                        : null}
                    </div>
                  </div>
                );
              }}
              rules={{
                required: 'Select where you serve clients',
              }}
            />
            <div className="w-full flex flex-col xl:flex-row gap-4">
              <div className="w-full xl:w-1/2">
                <Controller
                  control={control}
                  name="appointmentDate"
                  render={({ field: { value }, formState: { errors } }) => {
                    const errorMessage: string =
                      errors?.appointmentDate?.message;
                    const dateValue = value || null;
                    return (
                      <div className="flex flex-col space-y-2">
                        <FormLabel htmlFor="appointment-date">
                          Appointment Date
                        </FormLabel>
                        <DatePicker
                          className="splice-time-input"
                          defaultValue={
                            dateValue ? dayjs(dateValue, dateFormat) : undefined
                          }
                          onChange={onChangeDate}
                          format={dateFormat}
                        />
                        {errorMessage && (
                          <FormHelperText variant="error">
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </div>
                    );
                  }}
                  rules={{
                    required: ERRORS.APPOINTMENT_DATE_REQUIRED,
                  }}
                />
              </div>
              <div className="w-full xl:w-1/2">
                <Controller
                  control={control}
                  name="appointmentTime"
                  render={({ field: { value }, formState: { errors } }) => {
                    const errorMessage: string =
                      errors?.appointmentTime?.message;
                    const timeValue = value || null;
                    return (
                      <div className="flex flex-col space-y-2">
                        <FormLabel htmlFor="appointment-time">
                          Appointment Time
                        </FormLabel>
                        <TimePicker
                          className="splice-time-input"
                          use12Hours
                          format="h:mm a"
                          defaultValue={
                            timeValue ? dayjs(value, 'HH:mm') : undefined
                          }
                          onChange={onChangeTime}
                        />
                        {errorMessage && (
                          <FormHelperText variant="error">
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </div>
                    );
                  }}
                  rules={{
                    required: 'Add appointment time',
                  }}
                />
              </div>
            </div>
            <CollapseRow title="Clients">
              {/* Client content goes here */}
              <div className="w-full flex flex-col space-y-2">
                <Controller
                  control={control}
                  name="clientId"
                  render={({
                    field: { onChange, value },
                    formState: { errors },
                  }) => {
                    const errorMessage: string = errors?.clientId?.message;
                    return (
                      <>
                        <FormLabel htmlFor="client">Select a client</FormLabel>
                        <SingleSelect
                          selected={value || []}
                          options={clientsOptions}
                          setSelected={onChange}
                          isLoading={loadClientsShimmer}
                          onInputChange={searchClient}
                          placeholder={'Type client name'}
                          isDisabled={appointmentHasNoClient}
                        />
                        {errorMessage && (
                          <FormHelperText variant="error">
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </>
                    );
                  }}
                  rules={{
                    required: appointmentHasNoClient
                      ? false
                      : 'Select a client',
                  }}
                />
                <div
                  className="w-full flex space-x-2 cursor-pointer"
                  onClick={() => {
                    setAppointmentHasNoClient(!appointmentHasNoClient);
                  }}
                >
                  <Checkbox isChecked={appointmentHasNoClient} />
                  <span className="text-b5 text-grey-900">
                    Leave blank for walk-ins
                  </span>
                </div>
              </div>
              {newClient && !appointmentHasNoClient ? (
                <>
                  <Input
                    name="clientName"
                    label="Client"
                    id="clientName"
                    type="text"
                    placeholder="Enter client name"
                    control={control}
                    rules={{
                      required: newClient && !appointmentHasNoClient ? ERRORS.NAME_REQUIRED : false,
                    }}
                    error={errors.clientName}
                  />
                  <div className="w-full flex gap-x-4">
                    <div className="w-full xl:w-1/2">
                      <FormPhone
                        country={{
                          disabled: false,
                          onSelect: (code, country: string) => {
                            setCallingCode(code);
                            setCountryCode(country);
                          },
                          value: countryCode,
                        }}
                        phone={{
                          name: 'clientPhone',
                          type: 'text',
                          placeholder: '9151930463',
                          rules: {
                            required: !appointmentHasNoClient ? ERRORS.PHONE_REQUIRED : false,
                            pattern: REGEX_PATTERNS.NUMBER,
                            onChange: (e) => {
                              _sanitizePhoneNumber(e.target.value);
                            },
                            disabled: false,
                          },
                          register,
                          id: 'phone-number',
                          label: 'Phone Number',
                        }}
                        helperText={getHelperTextForReactHookFormErrors(
                          errors?.clientPhone?.message as string
                        )}
                      />
                    </div>
                    <div className="w-full xl:w-1/2">
                      <Input
                        name="clientEmail"
                        label="Client email"
                        id="clientEmail"
                        type="email"
                        placeholder="email@acme.com"
                        control={control}
                        rules={{
                          pattern: REGEX_PATTERNS.EMAIL,
                        }}
                        error={errors.clientEmail}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              {salon?.locationType &&
                hasHomeAsLocation(salon?.locationType) && (
                  <div className="w-full flex flex-col gap-4">
                    <Controller
                      control={control}
                      name="isHomeService"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <div className="w-full flex flex-col space-y-4">
                            <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                              <div
                                className="flex"
                                onClick={() => onChange(!value)}
                              >
                                <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                  <Checkbox isChecked={value} />
                                  <span className="text-grey-900 whitespace-nowrap">
                                    Are you booking a home service?
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    />
                    {watch('isHomeService') &&
                    hasHomeAsLocation(salon?.locationType) ? (
                      <Input
                        name="address"
                        label="Home Address"
                        id="home-address"
                        type="text"
                        placeholder="Enter home address"
                        control={control}
                        rules={{
                          required: watch('isHomeService')
                            ? ERRORS.HOME_ADDRESS_REQUIRED
                            : false,
                        }}
                        error={errors.address}
                      />
                    ) : null}
                  </div>
                )}
            </CollapseRow>
            {/* Packages */}
            {showVoucherInitialLoadingShimmer ? (
              <div className="w-full grid grid-cols-3 gap-4 h-full max-h-[200px] overflow-y-auto">
                <VoucherCardShimmer />
              </div>
            ) : null}
            {vouchers && vouchers?.length && selectedClient ? (
              <div className="w-full flex-col space-y-4">
                <div className="w-full flex justify-between">
                  <Paragraph size="b6">Active Vouchers</Paragraph>
                  {!showAllPackages &&
                  filteredVouchers?.length > defaultVisiblePackages ? (
                    <Button
                      variant="text"
                      size="none"
                      className="text-grey-300"
                      fontWeight="semiBold"
                      type="button"
                      onClick={handleSeeAllClick}
                    >
                      See all
                    </Button>
                  ) : null}
                </div>
                {showAllPackages ? (
                  <SearchTerm
                    setDebouncedSearchQuery={setSearchVoucher}
                    placeholder="Search by name"
                  />
                ) : null}
                <div className="w-full grid grid-cols-3 gap-4 h-full max-h-[200px] overflow-y-auto">
                  {Array.isArray(filteredVouchers) && filteredVouchers?.length
                    ? filteredVouchers
                        .slice(0, visiblePackages)
                        .map((salonPackage) => {
                          return (
                            salonPackage?.status === 'active' && (
                              <VoucherCard
                                isActive={
                                  salonPackage?.id === selectedPackage?.id
                                }
                                voucher={salonPackage}
                                chooseVoucher={() =>
                                  chooseSalonPackage(salonPackage)
                                }
                                key={salonPackage?.id}
                              />
                            )
                          );
                        })
                    : null}
                </div>
                {selectedPackage && !selectedPackage?.redemptionMode ? (
                  <Controller
                    control={control}
                    name="redeemPackageServicesAsItIs"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div className="w-full flex flex-col space-y-2">
                          <FormLabel htmlFor="redeemPackageServicesAsItIs">
                            How do you want to redeem voucher?
                          </FormLabel>
                          <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                            {Array.isArray(YES_OR_NO_SETTINGS) &&
                            YES_OR_NO_SETTINGS.length
                              ? YES_OR_NO_SETTINGS.map(
                                  (redeemPackageServicesAsItIs: {
                                    label: string;
                                    value: boolean;
                                  }) => {
                                    return (
                                      <div
                                        className="flex"
                                        onClick={() =>
                                          onChange(
                                            redeemPackageServicesAsItIs?.value
                                          )
                                        }
                                        key={redeemPackageServicesAsItIs?.label}
                                      >
                                        <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                          <Checkbox
                                            isChecked={
                                              value ===
                                              redeemPackageServicesAsItIs?.value
                                            }
                                          />
                                          <span className="text-grey-900 whitespace-nowrap">
                                            {redeemPackageServicesAsItIs?.label ===
                                            YES_SETTING_NAME
                                              ? 'As it is (including services)'
                                              : 'Only monetary value'}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              : null}
                          </div>
                        </div>
                      );
                    }}
                  />
                ) : null}
              </div>
            ) : null}
            {!selectedPackage ||
            (selectedPackage && !watch('redeemPackageServicesAsItIs')) ? (
              <CollapseRow title="Service">
                <div className="w-full flex flex-col xl:flex-row gap-4">
                  <Controller
                    control={control}
                    name="services"
                    render={({
                      field: { onChange, value },
                      formState: { errors },
                    }) => {
                      const errorMessage: string = errors?.services?.message;
                      return (
                        <div className="w-full flex flex-col space-y-2">
                          <FormLabel htmlFor="service">
                            Add Service(s)
                          </FormLabel>
                          <MultiSelect
                            selected={value || []}
                            options={servicesOptions}
                            setSelected={onChange}
                          />
                          {errorMessage && (
                            <FormHelperText variant="error">
                              {errorMessage}
                            </FormHelperText>
                          )}
                        </div>
                      );
                    }}
                    rules={{
                      required: 'Add a service',
                    }}
                  />
                </div>
              </CollapseRow>
            ) : (
              <div className="w-full">
                {Array.isArray(selectedPackageServices) &&
                selectedPackageServices?.length ? (
                  <div className="w-full border border-grey-50 rounded-md p-3 flex flex-col gap-4">
                    <Paragraph
                      size="b5"
                      color={COLORS.GREY[300]}
                      weight="bold"
                      className="uppercase"
                    >
                      {selectedPackage?.package?.name} • {selectedPackage?.code}{' '}
                      Applied
                    </Paragraph>
                    <div className="flex flex-col space-y-4">
                      <div className="flex space-x-2">
                        <Heading
                          variant="h3"
                          size="b6"
                          color={COLORS.GREY[300]}
                          weight="bold"
                          className="w-[30%]"
                        >
                          SERVICES
                        </Heading>
                        <Heading
                          variant="h3"
                          size="b6"
                          color={COLORS.GREY[300]}
                          weight="bold"
                          className="w-[30%]"
                        >
                          ASSIGN STAFF
                        </Heading>
                        <Heading
                          variant="h3"
                          size="b6"
                          color={COLORS.GREY[300]}
                          weight="bold"
                          className="w-[20%]"
                        >
                          SELECT QUANTITY
                        </Heading>
                        <Heading
                          variant="h3"
                          size="b6"
                          color={COLORS.GREY[300]}
                          weight="bold"
                          className="w-[20%] flex justify-end"
                        >
                          PRICE
                        </Heading>
                        <div className="w-[10%] flex justify-end"></div>
                      </div>
                      <>
                        {Array?.isArray(selectedPackageServices) &&
                          selectedPackageServices?.map((service) => {
                            const cartQuantity = service?.cartQuantity || 1;
                            return (
                              <div
                                className="flex items-start space-x-2"
                                key={service?.id}
                              >
                                {replaceService?.id !== service?.id ? (
                                  <div className="flex space-x-2 w-[30%]">
                                    {service?.replacementService ? (
                                      <>
                                        <Paragraph
                                          size="b4"
                                          color={COLORS.GREY[300]}
                                          className=""
                                        >
                                          {service?.replacementService?.name}
                                        </Paragraph>
                                        <Button
                                          variant="transparent"
                                          size="none"
                                          type="button"
                                          onClick={() => {
                                            removeReplacementServiceAsync(
                                              service?.id
                                            );
                                          }}
                                        >
                                          <SvgClose
                                            width="14px"
                                            height="14px"
                                          />
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <Paragraph
                                          size="b4"
                                          color={COLORS.GREY[300]}
                                          className=""
                                        >
                                          {service?.name}
                                        </Paragraph>
                                        <Button
                                          variant="transparent"
                                          size="none"
                                          type="button"
                                          onClick={() => {
                                            replaceServiceAsync(service);
                                          }}
                                        >
                                          <SvgFluentEdit
                                            width="14px"
                                            height="14px"
                                          />
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div className="flex space-x-2 w-[30%]">
                                    <SingleSelect
                                      selected={
                                        service?.replacementService
                                          ? [
                                              {
                                                label:
                                                  service?.replacementService
                                                    ?.name,
                                                value:
                                                  service?.replacementService
                                                    ?.id,
                                              },
                                            ]
                                          : []
                                      }
                                      options={servicesOptions}
                                      setSelected={replacePackageService}
                                    />
                                  </div>
                                )}
                                <div className="flex gap-2 flex-wrap w-[30%]">
                                  {Array.isArray(service?.staff) &&
                                  service?.staff.length
                                    ? service?.staff.map((eachStaff, index) => (
                                        <div
                                          className="flex space-x-2 items-center bg-white border border-grey-50 px-[10px] py-2 rounded-full"
                                          key={index}
                                        >
                                          <Paragraph
                                            size="b6"
                                            color={COLORS.GREY[300]}
                                          >
                                            {eachStaff.firstName}
                                          </Paragraph>
                                          <span
                                            className="text-b6 cursor-pointer"
                                            onClick={() => {
                                              removePackageServiceStaff(
                                                eachStaff?.staffId,
                                                service?.id
                                              );
                                            }}
                                          >
                                            <SvgClose
                                              width="14px"
                                              height="14px"
                                            />
                                          </span>
                                        </div>
                                      ))
                                    : null}
                                  <div className="w-full max-w-[80px]">
                                    <SelectOnchangeField
                                      options={
                                        formatPackageServiceStaffOptions(
                                          service
                                        ) || []
                                      }
                                      onChange={(selectedValue) => {
                                        onChangePackageServiceStaff(
                                          selectedValue,
                                          service?.id
                                        ); // Call the changeStaff function with the selected value
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="w-[20%] flex space-x-2">
                                  <div className="w-full flex pt-2">
                                    <Button
                                      variant="icon"
                                      size="none"
                                      type="button"
                                      className="border-0"
                                      onClick={() =>
                                        modifyCart(
                                          service?.id,
                                          'add',
                                          'voucher'
                                        )
                                      }
                                    >
                                      <SvgGreyPlus width="22px" height="22px" />
                                    </Button>
                                    <div className="flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center">
                                      <Paragraph
                                        size="b6"
                                        weight="normal"
                                        color={COLORS.BLACK}
                                      >
                                        {cartQuantity}
                                      </Paragraph>
                                    </div>
                                    <Button
                                      variant="icon"
                                      size="none"
                                      type="button"
                                      className="border-0"
                                      onClick={() =>
                                        modifyCart(
                                          service?.id,
                                          'remove',
                                          'voucher'
                                        )
                                      }
                                    >
                                      <SvgGreyMinus
                                        width="22px"
                                        height="22px"
                                      />
                                    </Button>
                                  </div>
                                </div>
                                <div className="flex justify-end w-[20%]">
                                  <Paragraph
                                    size="b4"
                                    color={COLORS.GREY[300]}
                                    className=""
                                  >
                                    -
                                  </Paragraph>
                                </div>
                                <div className="w-[10%] flex justify-end items-center">
                                  <span
                                    className="text-red-600 text-b5 cursor-pointer"
                                    onClick={() => {
                                      removeServiceFromSelectedPackageServices(
                                        service?.id
                                      );
                                    }}
                                  >
                                    <SvgTrash width="14px" height="14px" />
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </>
                      <div className="border-t border-grey-50"></div>
                      <div className="flex justify-between items-center xl:pr-[10%]">
                        <Paragraph
                          size="b4"
                          color={COLORS.GREY[300]}
                          weight="semiBold"
                        >
                          Subtotal
                        </Paragraph>
                        <Paragraph
                          size="b4"
                          color={COLORS.GREY[300]}
                          weight="semiBold"
                        >
                          {formatInToPrice(
                            selectedPackage?.package?.usesCustomPrice
                              ? selectedPackage?.package?.customPrice
                              : selectedPackage?.package?.totalPrice
                          )}
                        </Paragraph>
                      </div>
                      <div className="flex justify-between items-start xl:pr-[10%]">
                        <div className="w-full xl:max-w-[50%]">
                          <Paragraph size="b5" color={COLORS.GREY[300]}>
                            Voucher applied
                          </Paragraph>
                          <Paragraph size="b5" color={COLORS.GREY[300]}>
                            {selectedPackage?.code} •{' '}
                            {convertSalonServicesNamesToString(
                              selectedPackage?.services
                            )}
                          </Paragraph>
                        </div>
                        <Paragraph
                          size="b4"
                          color={COLORS.GREY[300]}
                          weight="semiBold"
                        >
                          -
                          {formatInToPrice(
                            selectedPackage?.package?.usesCustomPrice
                              ? selectedPackage?.package?.customPrice
                              : selectedPackage?.package?.totalPrice
                          )}
                        </Paragraph>
                      </div>
                      <div className="border-t border-grey-50"></div>
                      <div className="flex justify-between items-center xl:pr-[10%]">
                        <Paragraph
                          size="b4"
                          color={COLORS.GREY[300]}
                          weight="semiBold"
                        >
                          Total
                        </Paragraph>
                        <Paragraph
                          size="b4"
                          color={COLORS.GREY[300]}
                          weight="semiBold"
                        >
                          <span className="line-through">
                            {formatInToPrice(
                              selectedPackage?.package?.usesCustomPrice
                                ? selectedPackage?.package?.customPrice
                                : selectedPackage?.package?.totalPrice
                            )}
                          </span>
                          {voucherBaseAppointmentTotal > 0 ? (
                            <span>
                              {' '}
                              • {formatInToPrice(voucherBaseAppointmentTotal)}
                            </span>
                          ) : null}
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            {watch('services') || watch('products')
              ? getServiceSummary()
              : null}
            {/* const price = selectedPackage?.amountRedeemed < selectedPackage?.price && selectedPackage?.amountRedeemed !== 0 ? selectedPackage?.price - selectedPackage?.amountRedeemed : selectedPackage?.price; */}
            {selectedPackage && !watch('redeemPackageServicesAsItIs') ? (
              <div className="w-full xl:max-w-[50%] border border-grey-50 px-4 py-2 rounded-sm">
                <Paragraph size="b5" color={COLORS.BLACK}>
                  Package value applied
                </Paragraph>
                <Paragraph size="b5" color={COLORS.GREY[300]}>
                  {selectedPackage?.code} •{' '}
                  <span className="line-through">
                    {formatInToPrice(
                      (selectedPackage?.package?.usesCustomPrice
                        ? selectedPackage?.package?.customPrice
                        : selectedPackage?.package?.totalPrice) -
                        selectedPackage?.amountRedeemed
                    )}
                  </span>{' '}
                  {appointmentSummary && selectedServices?.length ? (
                    <span
                      className={`font-semiBold ${
                        (selectedPackage?.package?.usesCustomPrice
                          ? selectedPackage?.package?.customPrice
                          : selectedPackage?.package?.totalPrice) -
                          selectedPackage?.amountRedeemed -
                          appointmentSummary?.totalPaid <
                        0
                          ? 'text-red-400'
                          : 'text-green-400'
                      }`}
                    >
                      {formatInToPrice(
                        selectedPackage?.price -
                          selectedPackage?.amountRedeemed -
                          appointmentSummary?.totalPaid
                      )}
                    </span>
                  ) : null}
                </Paragraph>
              </div>
            ) : null}
            <div className="w-full flex flex-wrap gap-4">
              {!enableAddOns ? (
                <Button
                  variant="light"
                  size="xs"
                  rounded="lg"
                  type="button"
                  className="border border-grey-100"
                  onClick={() => {
                    setEnableAddons(true);
                  }}
                >
                  Add add-on
                  <SvgPlus width="14px" height="14px" />
                </Button>
              ) : null}
              {!enableProductAddition ? (
                <Button
                  variant="light"
                  size="xs"
                  rounded="lg"
                  type="button"
                  className="border border-grey-100"
                  onClick={() => {
                    setEnableProductAddition(true);
                  }}
                >
                  Add product
                  <SvgPlus width="14px" height="14px" />
                </Button>
              ) : null}
              {!enablePromo && actions?.applyPromo ? (
                <Button
                  variant="light"
                  size="xs"
                  rounded="lg"
                  type="button"
                  className="border border-grey-100"
                  onClick={() => {
                    setEnablePromo(true);
                  }}
                >
                  Add promo code
                  <SvgPlus width="14px" height="14px" />
                </Button>
              ) : null}
              {!selectedReward && !enableDiscount && actions?.applyDiscounts ? (
                <Button
                  variant="light"
                  size="xs"
                  rounded="lg"
                  type="button"
                  className="border border-grey-100"
                  onClick={() => setEnableDiscount(true)}
                >
                  Add discount
                  <SvgPlus width="14px" height="14px" />
                </Button>
              ) : null}
            </div>
            {enableProductAddition ? (
              <Controller
                control={control}
                name="products"
                render={({
                  field: { onChange, value },
                  formState: { errors },
                }) => {
                  const errorMessage: string = errors?.products?.message;
                  return (
                    <div className="w-full flex flex-col space-y-2">
                      <FormLabel htmlFor="product">Add Product(s)</FormLabel>
                      <MultiSelect
                        selected={value || []}
                        options={productsOptions}
                        setSelected={onChange}
                      />
                      {errorMessage && (
                        <FormHelperText variant="error">
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </div>
                  );
                }}
              />
            ) : null}
            {enableAddOns ? (
              <div className="w-full flex space-x-4">
                <div className="w-1/2">
                  <Input
                    name="addOnReason"
                    label="Add-ons"
                    id="addons"
                    type="text"
                    placeholder="Addon reason"
                    control={control}
                    error={errors.addOnReason}
                  />
                </div>
                <Controller
                  control={control}
                  name="addOnAmount"
                  rules={{
                    required: watch('addOnReason')
                      ? ERRORS.ADDON_AMOUNT_REQUIRED
                      : false,
                  }}
                  render={({ field }) => (
                    <div className="w-1/2 flex flex-col space-y-2">
                      <FormLabel htmlFor="adds-on-amount">
                        Add-ons amount
                      </FormLabel>
                      <input
                        className="w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400"
                        type="text"
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder="addon amount"
                      />
                      {errors?.addOnAmount?.message && (
                        <FormHelperText variant="error">
                          {errors.addOnAmount.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                />
              </div>
            ) : null}
            {enablePromo ? (
              <div className="w-1/2 pr-2">
                <SearchTerm
                  placeholder="Enter Promo code"
                  label="Promo code"
                  showIcon={false}
                  setDebouncedSearchQuery={setDebouncedSearchQuery}
                />
              </div>
            ) : null}
            {/* reward */}
            {Array?.isArray(milestones) && milestones?.length ? (
              <div
                className="w-full flex flex-col space-y-3"
                id="rewards-section"
              >
                <Paragraph
                  size="b5"
                  color={COLORS.BLACK}
                  className="flex items-center gap-3"
                >
                  Reward available
                </Paragraph>
                <div className="w-full flex flex-wrap">
                  {milestones?.map((reward) => {
                    return (
                      <div
                        key={reward?.id}
                        className={`w-full max-w-[200px] px-4 py-3 bg-green-300 rounded-lg cursor-pointer flex space-x-4 ${
                          reward.id === selectedReward?.id
                            ? 'shadow-md shadow-green-200 border border-black'
                            : ''
                        }`}
                        style={{
                          backgroundImage:
                            'url("https://res.cloudinary.com/dqqrgidob/image/upload/v1722591262/ru2wmnxkhjrybwpwrr1v.png")',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '20%',
                          backgroundPosition: 'bottom right',
                        }}
                        onClick={() => {
                          chooseReward(reward);
                        }}
                      >
                        <span>
                          <SvgWhiteDiamond width="30px" height="30px" />
                        </span>
                        <div className="flex flex-col space-y-1">
                          <Paragraph size="b5" color={COLORS.WHITE}>
                            {watch('clientName')} has a reward available
                          </Paragraph>
                          <Paragraph
                            size="b5"
                            color={COLORS.WHITE}
                            weight="semiBold"
                          >
                            {reward?.milestone?.customerReceivesType ===
                            'percentage'
                              ? `${reward?.milestone?.customerReceives}%`
                              : formatInToPrice(
                                  reward?.milestone?.customerReceives
                                )}{' '}
                            off
                          </Paragraph>
                          {/* <Paragraph size='b5' color={COLORS.GREY[300]}>
                            Points used: {reward?.customerPointsAttained} pts
                          </Paragraph> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <Controller
              control={control}
              name="note"
              render={({
                field: { onChange, onBlur, name, ref, value },
                formState: { errors },
              }) => {
                const maxLength = MAXIMUM_NOTE_LENGTH;
                const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                  errors?.note?.message as string
                );
                const helperTexts = [];

                helperTexts.push(getHelpTextForCharacterLeft(maxLength, value));
                if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts);

                return (
                  <FormTextarea
                    type="text"
                    id="about"
                    label="Note"
                    placeholder="Add a note"
                    {...{
                      ref,
                      name,
                      value,
                      onChange,
                      onBlur,
                      maxLength,
                    }}
                    helperText={helperTexts}
                  />
                );
              }}
            />
            {(depositRequired && !selectedPackage) ||
            (depositRequired &&
              selectedPackage &&
              appointmentSummary &&
              selectedPackage?.price < appointmentSummary?.totalPaid) ? (
              <Controller
                control={control}
                name="isDepositCompleted"
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="w-full flex flex-col space-y-4">
                      <FormLabel htmlFor="isDepositCompleted">
                        How would client like to pay?
                      </FormLabel>
                      <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                        {Array.isArray(YES_OR_NO_SETTINGS) &&
                        YES_OR_NO_SETTINGS.length
                          ? YES_OR_NO_SETTINGS.map(
                              (isDepositCompleted: {
                                label: string;
                                value: boolean;
                              }) => {
                                return (
                                  <div
                                    className="flex"
                                    onClick={() =>
                                      onChange(isDepositCompleted?.value)
                                    }
                                    key={isDepositCompleted?.label}
                                  >
                                    <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                      <Checkbox
                                        isChecked={
                                          value === isDepositCompleted?.value
                                        }
                                      />
                                      <span className="text-grey-900 whitespace-nowrap">
                                        {isDepositCompleted?.label?.toLowerCase() ===
                                        'yes'
                                          ? 'Partial payment (deposit)'
                                          : 'Full payment'}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          : null}
                      </div>
                    </div>
                  );
                }}
              />
            ) : null}
            <Controller
              control={control}
              name="paymentMethod"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="w-full flex flex-col space-y-4">
                    <FormLabel htmlFor="isDepositCompleted">
                      Select{' '}
                      {watch('isDepositCompleted') ? ' deposit ' : 'full'}{' '}
                      payment method
                    </FormLabel>
                    <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                      {Array.isArray(APPOINTMENT_PAYMENT_METHODS) && APPOINTMENT_PAYMENT_METHODS.length
                        ? APPOINTMENT_PAYMENT_METHODS.map(
                            (paymentMethod: {
                              label: string;
                              value: string;
                            }) => {
                              return (
                                <div
                                  className="flex"
                                  onClick={() => onChange(paymentMethod?.value)}
                                  key={paymentMethod?.value}
                                >
                                  <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                    <Checkbox
                                      isChecked={value === paymentMethod?.value}
                                    />
                                    <span className="text-grey-900 whitespace-nowrap">
                                      {paymentMethod?.label}
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )
                        : null}
                    </div>
                  </div>
                );
              }}
              rules={{
                required: 'Select payment method',
              }}
            />
            {watch('paymentMethod') === 'pos' &&
            posTerminals &&
            posTerminals.length ? (
              <SelectInput
                name="paymentMethodId"
                id="transfers"
                label="Point-of-Sale Terminal (POS)"
                rules={{
                  required: ERRORS.POS_REQUIRED,
                }}
                control={control}
                error={errors.paymentMethodId}
                options={posTerminalsOptions}
                placeholder="Select Point-of-Sale Terminal"
              />
            ) : null}
            {watch('paymentMethod') === 'bank_transfer' &&
            bankTransfers &&
            bankTransfers.length ? (
              <SelectInput
                name="paymentMethodId"
                id="pos"
                label="Bank"
                control={control}
                rules={{
                  required: ERRORS.TRANSFER_ACCOUNT_REQUIRED,
                }}
                error={errors.paymentMethodId}
                options={transfersOptions}
                placeholder="Select Bank"
              />
            ) : null}
          </div>
          <div className="w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer">
            <div className="w-full flex justify-between py-4 px-8">
              <div className="bg-grey-50 py-2 px-4 rounded-md border border-grey-20 hidden xl:flex justify-center items-center">
                <Paragraph size="b5" color={COLORS.GREY[300]}>
                  TOTAL: {DEFAULT_CURRENCY}
                  <FormatNumber
                    value={
                      selectedPackage
                        ? voucherBaseAppointmentTotal
                        : appointmentSummary?.totalPaid
                    }
                  />
                </Paragraph>
              </div>
              <div className="flex space-x-2">
                <Button
                  variant="text"
                  className="text-red-600 mr-6"
                  size="none"
                  fontWeight="semiBold"
                  onClick={() => {
                    closeModal('close');
                    reset();
                  }}
                >
                  Cancel
                </Button>
                {actions?.addAppointment ? (
                  <Button
                    variant="primary"
                    className=""
                    disabled={createAppointmentIsLoading}
                    loading={createAppointmentIsLoading}
                    size="md"
                    rounded="lg"
                  >
                    Add appointment
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <ReplaceServiceModal
        isVisible={isReplaceServiceModalVisible}
        closeModal={initiateCloseReplaceServiceModal}
        oldService={replaceService}
        newService={replacementService}
      />
    </>
  );
};

export default AddAppointmentModal;
