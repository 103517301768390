import * as React from "react";
import BasePill from "./BasePill";
import { BasePillProps } from "./types";
import { ButtonRounded } from "../button/types";

const LightPill = ({ className, style, children, rounded = ButtonRounded.md }: BasePillProps) => {
  return (
    <BasePill
      style={style}
      rounded={rounded}
      className={`text-grey-200 bg-grey-50 border border-grey-100 ${className || ""}`}
    >
      {children}
    </BasePill>
  );
};

export default LightPill;
