import React, { createContext, useContext, useState } from 'react'
import {
  Bank,
  BanksDocument,
  CreateExpenseDocument,
  CreateExpenseInput,
  CreatePayoutDocument,
  CreatePayoutInput,
  CreatePosDeviceDocument,
  CreatePosDeviceInput,
  CreateTransferAccountDocument,
  CreateTransferAccountInput,
  DeleteExpenseDocument,
  DeleteExpenseInput,
  DeletePosDeviceDocument,
  DeletePosDeviceInput,
  DeleteTransferAccountDocument,
  DeleteTransferAccountInput,
  Expense,
  ExpenseConnection,
  ExpensesDocument,
  ExpenseSummaryReport,
  ExpenseSummaryReportDocument,
  Payment,
  PaymentsDocument,
  PosDevice,
  PosDevicesDocument,
  TransferAccount,
  TransferAccountsDocument,
  WalletTransaction,
  WalletTransactionsDocument
} from '../graphql/generated'
import { useSalonCache } from '../hooks/useSalonCache'
import axios from 'axios'
import { print } from 'graphql'
import { useToast } from '../hooks'
import { formatDateToOriginalDate, getDefaultQueryDates } from '../utils/misc'
import { checkReportsTokenFromRoute, checkSalonIdFromRoute } from '../utils/token'

const MoneyContext = createContext(undefined)

const MoneyProvider = ({ children }) => {
  const token = checkReportsTokenFromRoute()
  const _salonId = checkSalonIdFromRoute()
  const { getSalonFieldValue } = useSalonCache()
  const [payments, setPayments] = useState<Payment[]>([])
  const [expenses, setExpenses] = useState<ExpenseConnection>()
  const [banks, setBanks] = useState<Bank[]>([])
  const [posDevices, setPosDevices] = useState<PosDevice[]>([])
  const { toast, addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [transferAccounts, setTransferAccounts] = useState<TransferAccount[]>(
    []
  )
  const [expensesSummary, setExpensesSummary] = useState<ExpenseSummaryReport>()
  const [walletTransactions, setWalletTransactions] = useState<
    WalletTransaction[]
  >([])

  const getPayments = () => {
    axios
      .post(
        '/graphql',
        {
          query: print(PaymentsDocument),
          variables: { salonId: getSalonFieldValue('id') }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { payments: p }
          }
        } = res
        setPayments(p)
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'success' })
      })
  }

  const getExpensesSummary = (start: Date, end: Date) => {
    axios
      .post(
        '/graphql',
        {
          query: print(ExpenseSummaryReportDocument),
          variables: { salonId: getSalonFieldValue('id'), startDate: start, endDate: end }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { expenseSummaryReport }
          }
        } = res
        setExpensesSummary(expenseSummaryReport)
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'success' })
      })
  }

  const getExpenses = (start: Date, end: Date, before?: string, after?: string) => {
    const startDate = formatDateToOriginalDate(start, "start");
    const endDate = formatDateToOriginalDate(end, "end");
    axios
      .post(
        '/graphql',
        {
          query: print(ExpensesDocument),
          variables: { salonId: getSalonFieldValue('id') || _salonId, startDate, endDate, before, after }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { expenses: e }
          }
        } = res
        setExpenses(e)
      })
      .catch((err) => addToast({ message: err.message, variant: 'error' }))
  }

  const createExpense = async (input: CreateExpenseInput, handleClose: () => void) => {
    const [d1, d2] =getDefaultQueryDates()
    try {
      const {
        data: {
          data: { createExpense: ce }
        }
      } = await axios.post(
        '/graphql',
        { query: print(CreateExpenseDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (ce.status === 200) {
        getExpenses(d1, d2)
        getExpensesSummary(d1, d2)
        addToast({ message: 'Expense saved successfully', variant: 'success' })
        handleClose()
      } else {
        addToast({ message: ce.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const deleteExpense = async (input: DeleteExpenseInput, handleClose: () => void) => {
    const [d1, d2] = getDefaultQueryDates()
    try {
      const {
        data: {
          data: { deleteExpense: ce }
        }
      } = await axios.post(
        '/graphql',
        { query: print(DeleteExpenseDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (ce.status === 200) {
        getExpenses(d1, d2)
        getExpensesSummary(d1, d2)
        addToast({
          message: 'Expense deleted successfully',
          variant: 'success'
        })
        handleClose()
      } else {
        addToast({ message: ce.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const getPosDevices = () => {
    axios
      .post(
        '/graphql',
        {
          query: print(PosDevicesDocument),
          variables: { salonId: getSalonFieldValue('id') }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { posDevices: pd }
          }
        } = res
        setPosDevices(pd)
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const getBanks = () => {
    axios
      .post(
        '/graphql',
        {
          query: print(BanksDocument)
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { banks: b }
          }
        } = res
        setBanks(b)
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const createPosDevice = async (input: CreatePosDeviceInput, handleClose: () => void) => {
    try {
      const {
        data: {
          data: { createPosDevice: ce }
        }
      } = await axios.post(
        '/graphql',
        { query: print(CreatePosDeviceDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (ce.status === 201) {
        getPosDevices()
        addToast({
          message: 'POS device saved successfully',
          variant: 'success'
        })
        handleClose()
      } else {
        addToast({ message: ce.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const deletePosDevice = async (input: DeletePosDeviceInput, handleClose: () => void) => {
    try {
      const {
        data: {
          data: { deletePosDevice: ce }
        }
      } = await axios.post(
        '/graphql',
        { query: print(DeletePosDeviceDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (ce.status === 200) {
        getPosDevices()
        addToast({
          message: 'POS device deleted successfully',
          variant: 'success'
        })
        handleClose()
      } else {
        addToast({ message: ce.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const getTransferAccounts = () => {
    axios
      .post(
        '/graphql',
        {
          query: print(TransferAccountsDocument),
          variables: { salonId: getSalonFieldValue('id') }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { transferAccounts: ta }
          }
        } = res
        setTransferAccounts(ta)
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const createTransferAccount = async (input: CreateTransferAccountInput, handleClose: () => void) => {
    try {
      const {
        data: {
          data: { createTransferAccount: ce }
        }
      } = await axios.post(
        '/graphql',
        { query: print(CreateTransferAccountDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (ce.status === 201) {
        getTransferAccounts()
        addToast({
          message: 'Transfer account saved successfully',
          variant: 'success'
        })
        handleClose()
      } else {
        addToast({ message: ce.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const deleteTransferAccount = async (input: DeleteTransferAccountInput, handleClose: () => void) => {
    try {
      const {
        data: {
          data: { deleteTransferAccount: ce }
        }
      } = await axios.post(
        '/graphql',
        { query: print(DeleteTransferAccountDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (ce.status === 200) {
        getTransferAccounts()
        addToast({
          message: 'Transfer account deleted successfully',
          variant: 'success'
        })
        handleClose()
      } else {
        addToast({ message: ce.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const getWalletTransactions = () => {
    axios
      .post(
        '/graphql',
        {
          query: print(WalletTransactionsDocument),
          variables: { salonId: getSalonFieldValue('id') }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { walletTransactions: ta }
          }
        } = res
        setWalletTransactions(ta)
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const createPayout = async (input: CreatePayoutInput, closeModal: () => void) => {
    try {
      setIsLoading(true)
      const {
        data: {
          data: { createPayout: ce }
        }
      } = await axios.post(
        '/graphql',
        { query: print(CreatePayoutDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      setIsLoading(false)
      if (ce.status === 200) {
        getWalletTransactions()
        addToast({
          message: ce.message,
          variant: 'success'
        })
        closeModal()
      } else {
        addToast({ message: ce.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      setIsLoading(false)
      addToast({ message: error.message, variant: 'error' })
    }
  }

  return (
    <MoneyContext.Provider
      value={{
        payments,
        expenses,
        toast,
        posDevices,
        banks,
        transferAccounts,
        walletTransactions,
        getPayments,
        addToast,
        getExpenses,
        createExpense,
        deleteExpense,
        getPosDevices,
        getBanks,
        createPosDevice,
        deletePosDevice,
        getTransferAccounts,
        createTransferAccount,
        deleteTransferAccount,
        getWalletTransactions,
        createPayout,
        getExpensesSummary,
        expensesSummary,
        isLoading,
      }}
    >
      {children}
    </MoneyContext.Provider>
  )
}

// Money Context custom hook
export const useMoneyContext = () => {
  const context = useContext(MoneyContext)
  if (!context) {
    throw new Error('useMoneyContext must be used with a MoneyProvider')
  }

  return context
}

export default MoneyProvider
