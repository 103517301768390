import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { PageProps } from '../types';
import { useModal } from '../../../hooks/useModal';
import { useToast } from '../../../hooks/useToast';
import { useSalonCache } from '../../../hooks/useSalonCache';
import { API_ERRORS } from '../../../constants/errors';
import AccountSetupArrayShimmer from '../../../ui/organism/accountSetupArrayShimmer/accountSetupArrayShimmer';
import { COLORS } from '../../../constants/colors';
import Heading from '../../../ui/atoms/heading/Heading';
import Paragraph from '../../../ui/atoms/paragraph/Paragraph';
import SvgEdit from '../../../ui/icons/Edit';
import SvgTrash from '../../../ui/icons/Trash';
import AccountSetupTemplate from '../AccountSetupTemplate';
import ToastWrapper from '../../../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../../../ui/atoms/toast/types';
import Button from '../../../ui/atoms/button/Button';
import SvgPlus from '../../../ui/icons/Plus';
import AddOrEditTransfer from '../../../modals/AddOrEditTransfer';
import { DeleteTransferAccountDocument, TransferAccount, TransferAccountsDocument } from '../../../graphql/generated';
import { print } from 'graphql';

const BankTransfers = ({ onNextPage, onPrevPage }: PageProps) => {
  const [selectedBank, setSelectedBank] = useState<TransferAccount | null>(null);
  const {
    isVisible,
    openModal,
    closeModal,
  } = useModal();
  const { addToast, toast } = useToast();
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const [bankTransfers, setBankTransfers] = useState<TransferAccount[] | null>(null);
  const [bankTransfersLoading, setBankTransfersLoading] = useState(false)

  const showInitialLoadingShimmer = bankTransfersLoading && !bankTransfers && salonId;

  const getBankTransfers = async () => {
    setBankTransfersLoading(true);
    axios
      .post(
        '/graphql',
        {
          query: print(TransferAccountsDocument),
          variables: { salonId }
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { transferAccounts }
          }
        } = res
        setBankTransfersLoading(false);
        setBankTransfers(transferAccounts);
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
        setBankTransfersLoading(false);
      })
  }

  useEffect(() => {
    getBankTransfers()
  }, [salonId])

  const deleteBank = (bankId: string) => async () => {
    try {
      await axios.post(
        '/graphql',
        {
          query: print(DeleteTransferAccountDocument),
          variables: { input: { id: bankId } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        if (data?.data?.deleteTransferAccount?.status === 200) {
          addToast({
            message: 'Account deleted successfully',
            variant: 'success',
          })
          getBankTransfers();
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.SALON_BANK_ACCOUNT_DELETION_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
  
  
        if (data?.data?.deleteTransferAccount?.errors?.length) {
          const message = data?.data?.deleteTransferAccount?.errors[0]?.message || API_ERRORS.SALON_BANK_ACCOUNT_DELETION_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (deletePosError) {
      if (axios.isAxiosError(deletePosError)) {
        const message = deletePosError?.response?.data?.message || API_ERRORS.SALON_BANK_ACCOUNT_DELETION_FAILED;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const editBank = (bankId: string) => () => {
    const bank = bankTransfers?.find((bank) => bank?.id === bankId);
    if (!bank) return;
    setSelectedBank(bank);
    openModal();
  }

  const getBankContent = () => {
    if (showInitialLoadingShimmer) {
      return <AccountSetupArrayShimmer />
    }
    if (Array.isArray(bankTransfers) && bankTransfers.length) {
      return (
        <>
          {bankTransfers.map((bank) => (
            <div className='flex items-center justify-between p-2 border-b border-grey-20'>
              <div className='flex flex-col space-y-1'>
                <Heading variant='h2' size='h10' weight='normal' className='text-black'>{bank?.accountName}</Heading>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]} className=''>{bank?.bankName}</Paragraph>
              </div>
              <div className='flex space-x-6'>
                <SvgEdit width="24px" height="24px" className='cursor-pointer' onClick={editBank(bank?.id)} />
                <SvgTrash width="24px" height="24px" className='cursor-pointer' onClick={deleteBank(bank?.id)} />
              </div>
            </div>
          ))}
        </>
      )
    }

    return (
      <div className='flex w-full justify-center items-center py-4'>
        <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]} className=''>You haven't added any bank transfer accounts yet.</Paragraph>
      </div>
    )
  }
  return (
    <>
      <AccountSetupTemplate
        title='Bank Transfers'
        subtitle='Add Bank Transfers'
        skip={{
          text: 'Skip Business Setup',
        }}
      >
        <ToastWrapper toast={toast as ToastProps} />
        <div className='flex space-x-4'>
          <Button
            variant='primary'
            className=''
            size='sm'
            rounded='md'
            type='button'
            onClick={openModal}
          >
            <SvgPlus width="24px" height="24px" color='text-white' />
            Add Bank Transfer
          </Button>
        </div>
        <div className='w-full flex flex-col space-y-2 max-h-[400px] overflow-y-scroll'>
          {getBankContent()}
        </div>
        <div className='w-full flex space-x-2'>
          <Button
            variant='secondary'
            className=''
            disabled={false}
            onClick={onPrevPage}
            size='lg'
            rounded='lg'
            type='button'
          >
            Prev
          </Button>
          <Button
            variant='primary'
            className=''
            disabled={false}
            onClick={onNextPage}
            size='lg'
            rounded='lg'
          >
            Proceed
          </Button>
        </div>
      </AccountSetupTemplate>
      <AddOrEditTransfer
        isVisible={isVisible}
        closeModal={closeModal}
        addToast={addToast}
        transferInfo={selectedBank}
        refetch={getBankTransfers}
      />
    </>
  )
}

export default BankTransfers