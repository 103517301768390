import React from 'react'
import { ActionModalProps } from '../types'
import { COLORS } from '../../../constants/colors'
import Paragraph from '../../../ui/atoms/paragraph/Paragraph'
import Heading from '../../../ui/atoms/heading/Heading'
import Button from '../../../ui/atoms/button/Button'
import { SvgCheckMarkGreen } from '../../../ui/icons'
import { useUserCache } from '../../../hooks/useUserCache'
import { formatInToPrice } from '../../../utils/misc'

const CancelAppointmentModal = ({
  isVisible,
  closeModal
}: ActionModalProps) => {
  const [applyCancellationFee, setApplyCancellationFee] = React.useState(false)
  const {getBusinessData} = useUserCache()
  const business = getBusinessData()

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50'>
      <div className='table-row'>
        {/* <div className="table-cell align-middle"> */}
        <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9'>
          <div className='flex flex-col space-y-6'>
            <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>
              Confirm cancellation
            </Heading>
            <Paragraph
              size='b5'
              weight='medium'
              className='w-full'
              color={COLORS.GREY[400]}
            >
              Select the applicable option
            </Paragraph>

            {business.cancellationFee > 0 && <div
              className={`p-5 min-h-10 rounded-md mb-6 cursor-pointer w-[600px] ${
                applyCancellationFee
                  ? 'border-2'
                  : 'border-solid border border-grey-100'
              }`}
              onClick={() => setApplyCancellationFee(true)}
            >
              <div className='flex items-center gap-2'>
                <Paragraph weight={applyCancellationFee ? 'bold' : 'normal'}>
                  Confirm and charge cancellation fee of {formatInToPrice(business.cancellationFee)}
                </Paragraph>
                {applyCancellationFee && <SvgCheckMarkGreen />}
              </div>
              <Paragraph color={COLORS.GREY[400]} size='b5'>
                The payment link will be sent to the client
              </Paragraph>
            </div>}

            <div
              className={`p-5 min-h-10 rounded-md mb-10 cursor-pointer w-[600px] ${
                !applyCancellationFee
                  ? 'border-2'
                  : 'border-solid border border-grey-100'
              }`}
              onClick={() => setApplyCancellationFee(false)}
            >
              <div className='flex items-center gap-2  '>
              <Paragraph weight={!applyCancellationFee ? 'bold' : 'normal'}>
                Confirm and waive cancellation fee
              </Paragraph>
              {!applyCancellationFee && <SvgCheckMarkGreen />}

              </div>
              <Paragraph color={COLORS.GREY[400]} size='b5'>
                The cancellation fee will not be charged
              </Paragraph>

            </div>

            <Button
              variant='primary'
              size='lg'
              rounded='lg'
              fontSize='b5'
              className='capitalize'
              onClick={() => closeModal('cancel', applyCancellationFee)}
            >
              Save
            </Button>
            <Button
              variant='text'
              size='none'
              rounded='none'
              fontSize='b5'
              className='mx-0'
              onClick={() => closeModal('back', applyCancellationFee)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  ) : null
}

export default CancelAppointmentModal
