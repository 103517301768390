import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useToast } from '../hooks/useToast';
import { API_ERRORS, ERRORS } from '../constants/errors';
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../ui/atoms/toast/types';
import { REGEX_PATTERNS } from '../constants/pattern';
import Input from '../ui/molecules/input/Input';
import SelectInput from '../ui/molecules/input/SelectInput';
import Button from '../ui/atoms/button/Button';
import { BankFormProps, BankInput } from './types';
import { print } from 'graphql'
import { BanksDocument, CreatePayoutAccountDocument } from '../graphql/generated';
import { useUserCache } from '../hooks/useUserCache';

const BankForm = (props: BankFormProps) => {
  const { getBusinessData, setBusinessData } = useUserCache();
  const _business = getBusinessData();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<BankInput>({});
  const [isLoading, setIsLoading] = useState(false);
  const [bankListOptions, setBankListOptions] = useState<{
    value: string;
    label: string;
  }[]>([])

  const getBanks = async () => {
    try {
      await axios.post(
        '/graphql',
        { query: print(BanksDocument) },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        const banks = data?.data?.banks;
        const list = Array.isArray(banks)
          ? banks.map((bank) => ({
            value: bank.code,
            label: bank.name,
          }))
          : [];
        setBankListOptions(list);
      })
    } catch (getBanksError) {
      const message = getBanksError?.response?.data?.message || API_ERRORS.BANK_ACCOUNT_LIST_FAILED;
      addToast({
        variant: "error",
        message,
      });
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  const { addToast, toast } = useToast();

  const onSubmitData = async (input: BankInput) => {
    setIsLoading(true)
    axios.post(
      '/graphql',
      {
        query: print(CreatePayoutAccountDocument),
        variables: { input },
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    ).then(({ data }) => {
      if (data?.data?.createPayoutAccount?.payoutAccount) {
        addToast({
          variant: "success",
          message: "Account added successfully",
        });
        setBusinessData({
          ..._business,
          payoutAccount: data?.data?.createPayoutAccount?.payoutAccount,
        })
        props?.refetch && props?.refetch();
        props?.onNextPage && props?.onNextPage();
      }

      if (data?.errors) {
        const message = data?.errors[0]?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
      if (data?.data?.createPayoutAccount?.errors) {
        const message = data?.data?.createPayoutAccount?.errors[0]?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
      setIsLoading(false);
    }).catch(createBusinessPayoutError => {
      setIsLoading(false);
      const message = createBusinessPayoutError?.response?.data?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
      addToast({
        variant: "error",
        message,
      });
    })
  };
  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <form onSubmit={handleSubmit(onSubmitData)} className="w-full space-y-6 pt-6">
        <Input name="accountNumber" id="account-number" label="Account Number" type="text" placeholder='Enter account number here' control={control} rules={{
          required: ERRORS.ACCOUNT_NUMBER_REQUIRED,
          pattern: REGEX_PATTERNS.ACCOUNT_NUMBER
        }} error={errors.accountNumber} />
        <SelectInput name="bankCode" id="bank-code" label="Bank Name " control={control} rules={{
          required: ERRORS.BANK_NAME_REQUIRED,
        }} error={errors.bankCode} options={bankListOptions} placeholder="Select Bank" />
        <div className='w-full flex space-x-2'>
          {props?.onPrevPage && (
            <Button
              variant='secondary'
              className=''
              disabled={false}
              onClick={props.onPrevPage}
              size='lg'
              rounded='lg'
              type='button'
            >
              Prev
            </Button>
          )}
          <Button
            variant='primary'
            className=''
            disabled={isLoading}
            loading={isLoading}
            size='lg'
            rounded='lg'
          >
            {props?.buttonText || 'Proceed'}
          </Button>
        </div>
      </form>
    </>
  )
}

export default BankForm