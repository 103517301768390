import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPackage = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m5 3.346 6 3.434m3-.947a1.333 1.333 0 0 0-.667-1.153L8.667 2.013a1.333 1.333 0 0 0-1.334 0L2.667 4.68A1.333 1.333 0 0 0 2 5.833v5.333a1.333 1.333 0 0 0 .667 1.154l4.666 2.666a1.333 1.333 0 0 0 1.334 0l4.666-2.666A1.333 1.333 0 0 0 14 11.166V5.833ZM2.2 5.167 8 8.5m0 0 5.8-3.333M8 8.5v6.666"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPackage;
