import React, { useState, useEffect } from 'react'
import {
  Button,
  CalendarPicker,
  Heading,
  Layout,
  Paginator,
  Paragraph,
  ReportTable,
  Tabs,
  ToastWrapper
} from '../ui'
import { createFileNameFromDates, formatDateToOriginalDate, getDayMonthAndNumberAndTimeFromDate, getDefaultQueryDates, getNumberMonthAndYearFromDate, handleDownload, handleExcelDownload } from '../utils/misc'
import { COLORS } from '../constants/colors'
import axios from 'axios'
import { print } from 'graphql'
import {
  ClientOverview,
  ClientOverviewConnection,
  ClientOverviewReportDocument,
  ClientOverviewSummaryReport,
  ClientOverviewSummaryReportDocument,
  ClientRetention,
  ClientRetentionConnection,
  ClientRetentionReportDocument,
  ClientRetentionSummaryReport,
  ClientRetentionReportSummaryDocument
} from '../graphql/generated'
import { useToast } from '../hooks'
import { checkReportsTokenFromRoute, checkSalonIdFromRoute } from '../utils/token'
import { useSalonCache } from '../hooks/useSalonCache'
import { SvgCalendar, SvgCreditCard, SvgInfo, SvgLocationPin, SvgPercent, SvgRetentionClientsImg, SvgUser } from '../ui/icons'
import ContentLoader from '../ui/atoms/contentLoader/ContentLoader'
import ExportDataToCsvOrXlsx from '../uicomponents/ExportDataToCsvOrXlsx'

const CLIENT_REPORTS_TAB_NAME = {
  CLIENTS_OVERVIEW: 'Clients overview',
  CLIENTS_RETENTION: 'Client retention'
}

const ClientsReports = () => {
  const CLIENT_REPORTS_TABS = [
    {
      key: CLIENT_REPORTS_TAB_NAME.CLIENTS_OVERVIEW,
      title: CLIENT_REPORTS_TAB_NAME.CLIENTS_OVERVIEW,
      show: true
    },
    {
      key: CLIENT_REPORTS_TAB_NAME.CLIENTS_RETENTION,
      title: CLIENT_REPORTS_TAB_NAME.CLIENTS_RETENTION,
      show: true
    }
  ]

  const [activeTab, setActiveTab] = useState<string>(
    CLIENT_REPORTS_TABS.filter((tab) => tab.show)[0].key
  )

  const getTabView = () => {
    switch (activeTab) {
      case CLIENT_REPORTS_TAB_NAME.CLIENTS_OVERVIEW:
        return <ClientsOverview />
      default:
        return <ClientRetention_ />
    }
  }

  return (
    <Layout pageTitle={activeTab} pageLevel={3}>
      <Tabs
        tabs={CLIENT_REPORTS_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className='w-full pb-20'>{getTabView()}</div>
    </Layout>
  )
}

const ClientsOverview = () => {
  const token = checkReportsTokenFromRoute()
  const _salonId = checkSalonIdFromRoute()
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id') || _salonId;
  const { toast, addToast } = useToast()
  const [clientOverview, setClientOverview] = useState<ClientOverviewConnection>()
  const [clientSummaryOverview, setClientSummaryOverview] = useState<ClientOverviewSummaryReport>()
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [loadingClientsOverviewData, setLoadingClientOverviewData] = useState(false)
  const Headers = [
    { name: 'Customer' },
    { name: 'Appointments' },
    { name: 'Completed' },
    { name: 'No Show' },
    { name: 'Cancelled' },
    { name: 'Outstanding Payments' },
    { name: 'Total sales' },
    { name: 'Points Earned' }
  ]

  const getClientOverviewAsync = (after?: string, before?: string) => {
    setLoadingClientOverviewData(true)
    axios
      .post(
        '/graphql',
        { query: print(ClientOverviewReportDocument),
          variables: {
            salonId,
            startDate: formatDateToOriginalDate(selectedDates[0], "start"),
            endDate: formatDateToOriginalDate(selectedDates[1], "end"),
            first: 30, after, before
          } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { clientOverviewReport }
          }
        } = res
        setClientOverview(clientOverviewReport)
        setLoadingClientOverviewData(false);
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
        setLoadingClientOverviewData(false)
      })
  }

  useEffect(() => {
    getClientOverviewAsync()
  }, [selectedDates])

  useEffect(() => {
    setLoadingClientOverviewData(true)
    axios
      .post(
        '/graphql',
        { query: print(ClientOverviewSummaryReportDocument),
          variables: {
            salonId,
            startDate: formatDateToOriginalDate(selectedDates[0], "start"),
            endDate: formatDateToOriginalDate(selectedDates[1], "end")
          } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { clientOverviewSummaryReport }
          }
        } = res
        setClientSummaryOverview(clientOverviewSummaryReport)
        setLoadingClientOverviewData(false);
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
        setLoadingClientOverviewData(false)
      })
  }, [selectedDates])

  const generateClientsOverviewTableData = (clients: ClientOverview[]) => {
    return clients?.map?.((client) => ({
      clientName: client.customer,
      appointments: client?.appointments,
      colmpleted: client?.completed,
      noShow: client?.noShow,
      cancelled: client?.cancelled,
      outstandingPayments: client?.outstandingPayments,
      totalSales: client?.totalSales,
      totalPointsEarned: client?.pointsEarned
    }))
  }

  const formatClientsOverviewCsvDownloadData = (
    clients: ClientOverview[]
  ) => {
    return clients?.map?.((client) => ({
      Customer: client?.customer,
      Appointments: client?.appointments,
      "Completed": client?.completed,
      "No Show": client?.noShow,
      "Cancelled": client?.cancelled,
      "Outstanding Payments": client?.outstandingPayments,
      "Total sales": client?.totalSales,
      "Points Earned": client?.pointsEarned,
    }));
  };

  const rows = generateClientsOverviewTableData(clientOverview?.nodes)
  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_clients_overview_report`;
  const _handleDownload = () => {
    if (clientOverview) {
      handleDownload(formatClientsOverviewCsvDownloadData(clientOverview?.nodes), filename);
    }
  };

  const downloadExcel = () => {
    if (clientOverview) {
      handleExcelDownload(formatClientsOverviewCsvDownloadData(clientOverview?.nodes), filename, 'Clients overview');
    }
  }

  const goToNext = () => {
    if (clientOverview?.pageInfo?.hasNextPage) {
      const after = clientOverview?.pageInfo?.endCursor;
      getClientOverviewAsync(after);
    }
  }

  const goToPrev = () => {
    if (clientOverview?.pageInfo?.hasPreviousPage) {
      const before = clientOverview?.pageInfo?.startCursor;
      getClientOverviewAsync(undefined, before);
    }
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {loadingClientsOverviewData ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Client overview by appointments
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full border-t border-grey-100 grid grid-col-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 p-3 md:p-6'>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[216px] border border-grey-100 bg-grey-50/20 p-3 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgUser width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total clients
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {clientSummaryOverview?.totalClients}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[216px] border border-grey-100 bg-grey-50/20 p-3 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgUser width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              New clients
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {clientSummaryOverview?.newClients}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[216px] border border-grey-100 bg-grey-50/20 p-3 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCalendar width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total appointments
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {clientSummaryOverview?.totalAppointments}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[216px] border border-grey-100 bg-grey-50/20 p-3 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCreditCard width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Outstanding payments
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {clientSummaryOverview?.outstandingPayments}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[216px] border border-grey-100 bg-grey-50/20 p-3 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgPercent width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total points earned
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {clientSummaryOverview?.totalPointsEarned}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-between items-center h-fit border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
          <Paragraph size='b4' weight='semiBold' className=''>
            Overview
          </Paragraph>
        <div className='flex items-center space-x-3'>
          <Paginator pageInfo={clientOverview?.pageInfo} { ...{ goToNext, goToPrev } } />
        </div>
      </div>
      <div className='w-full hidden md:flex flex-col space-y-12 overflow-x-scroll'>
        <div className='w-full flex flex-col h-fit border-t border-grey-100'>
          <ReportTable rows={rows} headers={Headers} />
        </div>
      </div>
    </>
  )
}

const ClientRetention_ = () => {
  const token = checkReportsTokenFromRoute()
  const _salonId = checkSalonIdFromRoute()
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id') || _salonId;
  const {toast, addToast} = useToast()
  const [clientRetention, setClientRetention] = useState<ClientRetentionConnection>()
  const [clientRetentionSummary, setClientRetentionSummary] = useState<ClientRetentionSummaryReport>()
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [loadingClientsRetentionData, setLoadingClientRetentionData] = useState(false)
  const Headers = [
    { name: 'Customer' },
    { name: 'Phone number' },
    { name: 'Email' },
    { name: 'Last Appointment' },
    { name: 'Last Service Amount' }
  ]

  const generateClientsRetentionTableData = (
    clients: ClientRetention[]
  ) => {
    return clients?.map?.((client) => ({
      customer: client.customer,
      phone: client.phoneNumber,
      email: client.email,
      lastVisit: client?.lastAppointment
        ? getDayMonthAndNumberAndTimeFromDate(client?.lastAppointment)
        : null,
      amount: client?.lastServiceAmount,
    }));
  };

  useEffect(() => {
    setLoadingClientRetentionData(true)
    axios
      .post(
        '/graphql',
        { query: print(ClientRetentionReportSummaryDocument),
          variables: {
            salonId,
            startDate: formatDateToOriginalDate(selectedDates[0], "start"),
            endDate: formatDateToOriginalDate(selectedDates[1], "end")
          } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { clientRetentionSummaryReport }
          }
        } = res
        setClientRetentionSummary(clientRetentionSummaryReport)
        setLoadingClientRetentionData(false);
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
        setLoadingClientRetentionData(false)
      })
  }, [selectedDates])

  const rows = generateClientsRetentionTableData(clientRetention?.nodes)

  const formatClientsRetentionCsvDownloadData = (
    clients: ClientRetention[]
  ) => {
    return clients?.map?.((client) => ({
      Customer: client?.customer,
      Phone: client?.phoneNumber,
      Email: client?.email,
      "Last Appointment": client?.lastAppointment
        ? getDayMonthAndNumberAndTimeFromDate(client?.lastAppointment)
        : null,
      "Last Service Amount": client?.lastServiceAmount,
    }));
  };

      const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_clients_retention_report`;
  const _handleDownload = () => {
    if (clientRetention) {
      handleDownload(formatClientsRetentionCsvDownloadData(clientRetention?.nodes), filename);
    }
  };

  const downloadExcel = () => {
    if (clientRetention) {
      handleExcelDownload(formatClientsRetentionCsvDownloadData(clientRetention?.nodes), filename, 'Client Retention')
    }
  }

  const getClientRetentionAsync = (after?: string, before?: string) => {
    setLoadingClientRetentionData(true)
    axios.post(
      '/graphql',
      { query: print(ClientRetentionReportDocument),
        variables: {
          salonId,
          startDate: formatDateToOriginalDate(selectedDates[0], "start"),
          endDate: formatDateToOriginalDate(selectedDates[1], "end"),
          first: 30, after, before
        } },
      { headers: { Authorization: `Bearer ${token}` } }
    ).then(res => {
      const { data: { data: { clientRetentionReport } } } = res
      setClientRetention(clientRetentionReport)
      setLoadingClientRetentionData(false)
    }).catch(err => {
      addToast({ message: err.message, variant: 'error' })
      setLoadingClientRetentionData(false)
    })
  }

  useEffect(() => {
    getClientRetentionAsync()
  }, [selectedDates])

  const goToNext = () => {
    if (clientRetention?.pageInfo?.hasNextPage) {
      getClientRetentionAsync(clientRetention?.pageInfo?.endCursor)
    }
  }

  const goToPrev = () => {
    if (clientRetention?.pageInfo?.hasPreviousPage) {
      getClientRetentionAsync(undefined, clientRetention?.pageInfo?.startCursor)
    }
  }

  const visitCustomMessage = () => {
    // href
    localStorage.setItem('retentionClients', JSON.stringify(clientRetention?.nodes))
    window.location.href = '/messaging?action=send';
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {loadingClientsRetentionData ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Client retention
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full flex gap-2 p-34 md:p-6 border-t border-grey-100'>
        <div className='w-1/2 flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-3 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgUser width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className='flex space-x-2 items-center'>
              <span>Total clients </span>
              <div className='group flex relative text-grey-300'>
                <SvgInfo width='15px' height='15px' />
                <span className='w-[200px] group-hover:opacity-100 transition-opacity bg-grey-50 p-2 text-b6 text-grey-300 rounded-md absolute left-2 -translate-x-2 opacity-0 m-4 mx-auto z-30'>
                  This is the total number of clients who have not booked a service within the selected date range.
                </span>
              </div>
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {clientRetentionSummary?.totalClients}
            </Paragraph>
          </div>
        </div>
        <div className='w-1/2 flex justify-between space-x-4 border border-grey-100 bg-grey-50/20 rounded-md'>
          <div className='w-full flex flex-col space-y-3 p-4'>
            <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
              Send email to audience
            </Paragraph>
            <Paragraph size='b4' color={COLORS.GREY[400]} className=''>
              Start by composing an email then choosing your desired recipients.
            </Paragraph>
            <Button
              variant={'primary'}
              size={'sm'}
              type='button'
              rounded='md'
              className='w-fit'
              onClick={visitCustomMessage}
            >
              Send email
            </Button>
          </div>
          <SvgRetentionClientsImg width="163px" height="100%" />
        </div>
      </div>
      <div className='w-full flex justify-between items-center h-fit border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
          <Paragraph size='b4' weight='semiBold' className=''>
            Overview
          </Paragraph>
        <div className='flex items-center space-x-3'>
          <Paginator pageInfo={clientRetention?.pageInfo} { ...{ goToNext, goToPrev } } />
        </div>
      </div>
      <div className='w-full hidden md:flex flex-col space-y-12 overflow-x-scroll'>
        <div className='w-full flex flex-col h-fit border-t border-grey-100'>
          <ReportTable rows={rows} headers={Headers} />
        </div>
      </div>
    </>
  )
}

export default ClientsReports
