import * as React from 'react'
import { useMemo, useState } from "react";
import { DateRangeCarouselShimmer, Paragraph } from '../..';
import { SvgArrowRight, SvgEmptyCalendar } from '../../icons';
import { getDayMonthAndNumberFromDate, getDayAndNumberFromDateString } from '../../../utils/misc';
import { COLORS } from '../../../constants/colors';

const DateRangeCarousel: React.FC<DateRangeCarouselProps> = ({ days, isLoading, setDay, daySlots, selectAvailableTimeSlot, selectedDate }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [activeDate, setActiveDate] = useState({ date: days[startIndex], index: startIndex });

  const handleNextClick = () => {
    const index = startIndex + 5;
    setStartIndex(index);
  };

  const handlePrevClick = () => {
    const index = startIndex - 5;
    setStartIndex(index);
  };

  const visibleDates = useMemo(() => days.slice(startIndex, startIndex + 5), [days, startIndex]);


  const handleDateClick = (date: string, index: number) => {
    setActiveDate({ date, index });
    setDay(date);
  };

  React.useEffect(() => {
    if (selectedDate) {
      const date = selectedDate?.split("T")[0];
      const indexDay = Math.round(days.indexOf(date) / 5);
      const selectedIndex = indexDay === 0 ? 0 : indexDay - 1;
      setStartIndex(selectedIndex * 5);
      const dateIndex = visibleDates?.indexOf(date);
      handleDateClick(date, dateIndex)
    }
  }, [selectedDate])

  // const selectTimeSlot = (time: string) => {
  //   const selectedDate = visibleDates[activeDate.index - startIndex];
  //   const dateTime = new Date(`${selectedDate.day.toISOString().split('T')[0]}T${time}`);
  //   selectAvailableTimeSlot(dateTime);
  // };

  const selectTimeSlot = (time: string) => {
    const selectedDate = days[startIndex + activeDate.index];
    const dateTime = `${selectedDate}T${time}`;
    selectAvailableTimeSlot(dateTime);
  };

  const getDateMonth = () => {
    const firstDay = visibleDates[0];
    const monthIndex = parseInt(firstDay?.split('-')[1]) - 1;
    // Get the month index (0-11)

    // Get the month name in human-readable format
    const months = [
      "January", "February", "March",
      "April", "May", "June",
      "July", "August", "September",
      "October", "November", "December"
    ];
    const monthName = months[monthIndex];

    return monthName;
  }

  const getSlotsContent = () => {
    if (isLoading) {
      return (
        <DateRangeCarouselShimmer />
      );
    } else if (Array.isArray(daySlots?.timeSlots) && daySlots?.timeSlots.length) {
      return daySlots?.timeSlots.map((timeSlot) => {
        const { time, isAvailable } = timeSlot;
        const isSelectedDateTime = selectedDate && new Date(selectedDate).toTimeString() === new Date(`${activeDate.date}T${time}`).toTimeString();
        const isSelectedDateDay = selectedDate && new Date(selectedDate).getDate() === new Date(`${activeDate.date}`).getDate();

        return (
          <div
            className={`w-full border border-grey-100 rounded-full py-4 ${isSelectedDateTime && isSelectedDateDay ? 'bg-green-300' : 'bg-white'
              } ${!isAvailable ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
            key={activeDate.date + time}
            onClick={() => selectTimeSlot(time)}
          >
            <Paragraph
              size="b5"
              weight="normal"
              className="text-center"
              color={isSelectedDateTime && isSelectedDateDay ? COLORS.WHITE : COLORS.BLACK}
            >
              {time}
            </Paragraph>
          </div>
        );
      });
    } else {
      return (
        <div className="w-full flex flex-col space-y-4 justify-center items-center">
          <SvgEmptyCalendar />
          <Paragraph size="b5" weight="semiBold" className="text-center">There are no time slots for this day</Paragraph>
        </div>
      );
    }
  }

  return (

    <>
      <Paragraph size="b4" weight="semiBold">
        {getDateMonth()}
      </Paragraph>
      <div className="relative flex gap-4 scroll-smooth snap-x" style={{ transition: "transform 0.3s ease" }}>
        {startIndex > 0 && (
          <button onClick={handlePrevClick} className='text-green-300 transform rotate-180'>
            <SvgArrowRight />
          </button>
        )}
        <div className="flex gap-4 flex-row overflow-x-scroll w-full transition-all duration-500 ease-in-out"
          style={{ scrollSnapType: "x mandatory", scrollBehavior: "smooth" }}>
          {Array.isArray(visibleDates) && visibleDates?.map((visibleDate, index) => {
            // const { day: date, isAvailable } = visibleDate
            let isAvailable = true;
            if (daySlots?.date === visibleDate) {
              isAvailable = daySlots?.isAvailable;
            }
            const { day, number } = getDayAndNumberFromDateString(visibleDate);
            const isActive = visibleDate === activeDate.date;
            return (
              <div
                className={`flex flex-col justify-center cursor-pointer items-center w-[49px] p-2 border border-grey-100 rounded-lg `
                  + (isActive ? 'bg-green-300' : '')
                  + (!isAvailable ? ' opacity-50' : '')
                }
                key={visibleDate}
                onClick={() => handleDateClick(visibleDate, index)}
              >
                <Paragraph size="b4" className={`uppercase ${!isAvailable ? 'line-through' : ''}`} color={
                  isActive ? COLORS.WHITE : COLORS.GREY[300]
                }>{day}</Paragraph>
                <Paragraph size="b5" className={`${!isAvailable ? 'line-through' : ''}`} weight={
                  isActive ? 'semiBold' : 'normal'
                } color={
                  isActive ? COLORS.WHITE : COLORS.GREEN[300]
                }>{number}</Paragraph>
              </div>
            )
          })}
        </div>
        {startIndex + 5 < days.length && (
          <button onClick={handleNextClick} className='text-green-300'>
            <SvgArrowRight />
          </button>
        )}
      </div>

      {activeDate?.date && (
        <Paragraph size="b5" weight="normal" color={COLORS.GREY[300]} className="w-full flex items-center xl:items-start mt-4">
          {getDayMonthAndNumberFromDate(activeDate?.date)}
        </Paragraph>
      )}
      <div className="w-full flex flex-col space-y-2 h-[300px] overflow-y-auto">
        {getSlotsContent()}
      </div>
    </>
  );
}

export default DateRangeCarousel;
