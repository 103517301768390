import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { OTP_COUNTER } from '../../../../constants/otpCounter';
import { BvnPageProps } from '../../types';
import { usePaymentSettings } from '../../../../hooks/usePaymentSettings';
import { useToast } from '../../../../hooks/useToast';
import { API_ERRORS } from '../../../../constants/errors';
import { PAYMENT_FORM_STEPS } from '../../constants';
import AccountSetupTemplate from '../../AccountSetupTemplate';
import ToastWrapper from '../../../../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../../../../ui/atoms/toast/types';
import OtpInput from 'react18-input-otp';
import { COLORS } from '../../../../constants/colors';
import Paragraph from '../../../../ui/atoms/paragraph/Paragraph';
import Button from '../../../../ui/atoms/button/Button';
import { CompleteBvnVerificationDocument, RequestBvnVerificationDocument } from '../../../../graphql/generated';
import { print } from 'graphql';
import { useUserCache } from '../../../../hooks/useUserCache';

const BvnVerification = ({ onNextPage, ...props }: BvnPageProps) => {
  // States
  const [otp, setOtp] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState(OTP_COUNTER.TIMER);
  const [errorMessage, setErrorMessage] = useState("");

  const { removeBvn, getBvnRecipient } = usePaymentSettings();
  const bvnRecipient = getBvnRecipient();
  
  const { getBusinessData, setBusinessData } = useUserCache();
  const _business = getBusinessData();

  const { addToast, toast } = useToast();

  // const businessId = business.businessId;

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [seconds]);

  const handleChange = (otp: string) => {
    setOtp(otp);
    if (otp.length === OTP_COUNTER.INPUTS) {
      setDisabled(false);
    }
  };

  const resendOtp = async () => {
    setIsLoading(true)
    axios.post(
      '/graphql',
      {
        query: print(RequestBvnVerificationDocument),
        variables: { input: { bvn: bvnRecipient as string } },
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    ).then(({ data }) => {
      if (data?.data?.requestBvnVerification?.status === 200) {
        setSeconds(OTP_COUNTER.TIMER);
      }

      if (data?.data?.errors?.length) {
        const message = data?.data?.errors[0]?.message || API_ERRORS.BUSINESS_SERVICES_DELETE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }


      if (data?.data?.requestBvnVerification?.errors?.length) {
        const message = data?.data?.requestBvnVerification?.errors[0]?.message || API_ERRORS.BUSINESS_SERVICES_DELETE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
      setIsLoading(false)
    }).catch (resendBvnVerificationCodeError => {
      const message = resendBvnVerificationCodeError?.response?.data?.message || API_ERRORS.RESEND_VERIFICATION_EMAIL_FAILED;
      addToast({
        variant: "error",
        message,
      });
      setIsLoading(false)
    })
  };

  const canRequestNewCode = seconds === 0;

  const gotBack = () => {
    props?.setStep && props.setStep(PAYMENT_FORM_STEPS.BVN_FORM);
    // removeBvn();
  }

  const verifyBvnOtp = async () => {
    setIsLoading(true)
    axios.post(
      '/graphql',
      {
        query: print(CompleteBvnVerificationDocument),
        variables: {
          input: {
            otpCode: otp
        } },
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    ).then(({ data }) => {
      if (data?.data?.completeBvnVerification?.status === 200) {
        setBusinessData({
          ..._business,
          bvn: bvnRecipient
        });
        removeBvn();
        onNextPage();
      }

      if (data?.errors) {
        const message = data?.errors[0]?.message || API_ERRORS.BUSINESS_SERVICES_DELETE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }

      if (data?.data?.completeBvnVerification?.errors) {
        const message = data?.data?.completeBvnVerification?.errors[0]?.message || API_ERRORS.BUSINESS_SERVICES_DELETE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
      setIsLoading(false)
    }).catch (verifyBvnError => {
      const message = verifyBvnError?.response?.data?.message || API_ERRORS.VERIFY_EMAIL_FAILED;
      setErrorMessage(message);
      setIsLoading(false)
    })
  }
  return (

    <AccountSetupTemplate
      title='BVN Verification'
      subtitle={`A One-time Passcode (OTP) has been sent to the
      phone number assigned to your BVN. Enter code below.`}
      skip={{
        text: 'Skip Business Setup',
      }}
    >
      <ToastWrapper toast={toast as ToastProps} />
      <OtpInput
        value={otp}
        onChange={handleChange}
        id="otp-code-input"
        numInputs={OTP_COUNTER.INPUTS}
        separator={<span></span>}
        isInputNum={true}
        className={`custom-otp-input`}
      />
      {errorMessage ? (
        <Paragraph size="b5" color={COLORS.RED[600]}>
          {errorMessage}
        </Paragraph>
      ) : null}
      <div className='w-full flex justify-center space-x-2'>
        <Paragraph size="b5" className="" color={COLORS.GREY[400]}>
          Haven’t received an OTP?
        </Paragraph>
        <Button
          variant="text"
          className='text-b5 mx-0'
          fontWeight='bold'
          disabled={!canRequestNewCode}
          loading={false}
          size='none'
          type='button'
          onClick={() => (canRequestNewCode ? resendOtp() : null)}
        >Resend OTP {!canRequestNewCode ? `${seconds}s` : null}</Button>
      </div>
      <Button
        variant='primary'
        className=''
        disabled={isLoading || disabled}
        loading={isLoading}
        size='lg'
        rounded='lg'
        onClick={verifyBvnOtp}
      >
        Confirm
      </Button>
      <Button
        variant='text'
        className=''
        onClick={gotBack}
        size='none'
      >
        Back
      </Button>
    </AccountSetupTemplate>
  )
}

export default BvnVerification