import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMdiLightImage = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m3.5 16.5 7-3 11 5m0-14.4v16.8a.6.6 0 0 1-.6.6H4.1a.6.6 0 0 1-.6-.6V4.1a.6.6 0 0 1 .6-.6h16.8a.6.6 0 0 1 .6.6Zm-5 6.4a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      stroke="#9AA2A5"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgMdiLightImage;
