import { useEffect } from 'react';

import axios from 'axios';
import {
  LiveChatUserHashDocument,
  UserDocument,
} from '../../../graphql/generated';
import { print } from 'graphql';

declare global {
  interface Window {
    FrontChat: (action: string, options?: any) => void;
  }
}

const FrontChat = () => {
  useEffect(() => {
    if (window.location.search.includes('token')) {
      const token = window.location.search.split('=')[1];
      localStorage.clear();
      axios
        .post(
          '/graphql',
          {
            query: print(UserDocument),
            variables: {
              token,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          localStorage.setItem(
            'userData',
            JSON.stringify(response.data.data.user)
          );
          getHash();
        });
    }
    else {
      getHash();
    }
  }, []);

  function getHash() {
    const email = JSON.parse(localStorage.getItem('userData') as string).email;
    axios
      .post(
        '/graphql',
        {
          query: print(LiveChatUserHashDocument),
          variables: {
            email,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((response) => {
        const hash = response.data.data.liveChatUserHash;
        localStorage.setItem('frontChatHash', hash);

        const script = document.createElement('script');
        script.id = 'front-chat-script';
        script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
        script.onload = initFrontChat;
        document.body.appendChild(script);
      });

    // Cleanup the script when the component is unmounted
    return () => {
      if (document.getElementById('front-chat-script')) {
        document.getElementById('front-chat-script')?.remove();
      }
    };
  }

  // Function to initialize the FrontChat script
  function initFrontChat() {
    const user = JSON.parse(localStorage.getItem('userData') as string);
    window.FrontChat('init', {
      chatId: '645c22f08e9c5bb898deb12f7bd1983c',
      useDefaultLauncher: false,
      userHash: localStorage.getItem('frontChatHash'),
      email: user.email,
      name:
        user.firstName + ' ' + user.lastName + ' (' + user.business.name + ')',
    });
  }

  // Function to manually open the chat
  const openChat = () => {
    if (window.FrontChat) {
      window.FrontChat('show'); // This opens the chat
    }
  };

  // open chat after 3 seconds
  setTimeout(openChat, 3000);

  return null;
};

export default FrontChat;
