import * as React from 'react';
import { DateRangePicker } from 'rsuite';
import { CalendarDateProps } from './types';
import 'rsuite/dist/rsuite.min.css';

const CalendarPicker = ({
  selectedDates,
  setSelectedDates
}: CalendarDateProps) => {
  const handleDateChange = (value: [Date, Date] | null) => {
    setSelectedDates(value);
  };

  // const renderValue = (dates: [Date, Date] | null) => {
  //   if (dates) {
  //     return (<span className='text-grey-400 font-semibold'>{calculateDaysBetweenDates(dates)}</span>)
  //   }
  //   return 'Select Date';
  // };

  return (
    <div className="relative">
      <DateRangePicker
        value={selectedDates}
        onChange={handleDateChange}
        // renderValue={renderValue}
        placeholder="Select date"
        cleanable={false}
        placement='bottomEnd'
        preventOverflow
      />
    </div>
  );
};

export default CalendarPicker;