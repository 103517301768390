import * as React from 'react';
import { SearchTermProps } from './types';
import SearchTerm from './SearchTerm';

const BookingPageSetup = (props: SearchTermProps) => {
  return (
    <SearchTerm
      setDebouncedSearchQuery={props.setDebouncedSearchQuery}
      placeholder="Enter name"
      successIcon={props?.successIcon || false}
      defaultSearchTerm={props?.defaultSearchTerm}
      disabled={props?.disabled}
    />
  );
};

export default BookingPageSetup;