import React from 'react'

const ContentLoader = () => {
  return (
    <div className='fixed w-full h-screen top-0 left-0 flex justify-center items-center bg-black/80 z-[99999999]'>
      <svg width="72" height="75" className="animate-pulse" viewBox="0 0 72 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.7 71.7C21.0333 73.7667 27.8 74.8 36 74.8C47.3333 74.8 56 72.7667 62 68.7C68.0667 64.5667 71.1 58.9 71.1 51.7C71.1 46.3 69.9667 42.0667 67.7 39C65.5 35.9333 62.0333 33.6333 57.3 32.1C52.6333 30.5 46.6 29.3 39.2 28.5C34.8 27.9667 31.3667 27.4667 28.9 27C26.5 26.4667 24.8333 25.7333 23.9 24.8C23.2589 24.1589 22.838 23.3291 22.6373 22.3106L4.61738 12.3756C2.73913 15.4515 1.8 19.0597 1.8 23.2C1.8 27.9333 2.96667 31.7667 5.3 34.7C7.63333 37.6333 11.1667 39.9 15.9 41.5C20.6333 43.1 26.6 44.3333 33.8 45.2C37.9333 45.6667 41.2 46.1667 43.6 46.7C46 47.1667 47.7 47.9 48.7 48.9C49.7667 49.8333 50.3 51.3 50.3 53.3C50.3 55.7667 49.1667 57.6667 46.9 59C44.6333 60.3333 41.2667 61 36.8 61C31.6 61 27.7 60.0333 25.1 58.1C22.5 56.1667 21.1333 53.6 21 50.4H0C0.0666653 55.1333 1.36667 59.3667 3.9 63.1C6.5 66.7667 10.4333 69.6333 15.7 71.7ZM7.83556 8.44029C8.72533 7.60445 9.71347 6.82435 10.8 6.1C16.8667 2.03333 25.1667 0 35.7 0C45.2333 0 52.9 1.93333 58.7 5.8C64.5667 9.6 67.8333 15.0667 68.5 22.2H48.1C47.7667 19.1333 46.4 16.9333 44 15.6C41.6667 14.2667 38.6333 13.6 34.9 13.6C30.9667 13.6 27.9 14.2667 25.7 15.6C24.9022 16.0737 24.2536 16.6126 23.7542 17.2168L7.83556 8.44029Z" fill="#16796F"/>
      </svg>
    </div>
  )
}

export default ContentLoader
