import React, { ChangeEvent } from 'react';
import { SelectFieldProps } from './types';
import SvgIonAddOutline from '../../icons/IonAddOutline';

const SelectOnchangeField: React.FC<SelectFieldProps> = ({ id, options, onChange }) => {
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <div className='relative'>
      <select id={id} className='w-full text-grey-300 flex items-center appearance-none bg-grey-50 border border-grey-100 px-[10px] py-2 rounded-full font-normal text-b6 xl:text-b6 focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400' onChange={handleSelectChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className='absolute top-[10px] right-[10%]'><SvgIonAddOutline width="14px" height="14px" /></div>
    </div>
  );
}

export default SelectOnchangeField