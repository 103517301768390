import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUserCheck = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7.333 6.667a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333Z"
      stroke="#000"
    />
    <path
      d="m11.333 6.868.889.8 1.778-2"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.665 12.001c.002-.109.002-.22.002-.333 0-1.657-2.388-3-5.334-3-2.945 0-5.333 1.343-5.333 3s0 3 5.333 3c1.488 0 2.56-.105 3.334-.291"
      stroke="#000"
      strokeLinecap="round"
    />
  </svg>
);
export default SvgUserCheck;
