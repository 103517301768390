import React from 'react'
import ParagraphShimmer from '../../ui/atoms/paragraph/ParagraphShimmer'

const UnassignedAppointmentsLoadingComponent = () => {
  return (
    <div className='w-full grid grid-cols-1 md:grid-cols-3 xl:flex xl:flex-col gap-2 p-4'>
      {[...Array(3)].map((_, index) => (
        <div className={`flex bg-slate-50 border rounded-md p-3 space-x-3 cursor-pointer h-[50px]`} key={`tcs${index}`}>
          <div className='flex flex-col space-y-2'>
            <ParagraphShimmer size="md" />
            <ParagraphShimmer size="lg" />
          </div>
        </div>
      ))}
    </div>
  )
}

export default UnassignedAppointmentsLoadingComponent