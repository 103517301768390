import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNotificationPayment = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={16} fill="#F3F6F6" />
    <path
      d="M22 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM17 22.5h-3.5v-1H17c2.482 0 4.5-2.018 4.5-4.5v-2h1v2c0 3.032-2.468 5.5-5.5 5.5Z"
      fill="#667368"
    />
    <path
      d="M16 18.5a2.503 2.503 0 0 1-2.5-2.5c0-1.379 1.121-2.5 2.5-2.5 1.378 0 2.5 1.121 2.5 2.5 0 1.378-1.122 2.5-2.5 2.5Zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5ZM10 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="#667368"
    />
    <path
      d="M10.5 17h-1v-2c0-3.033 2.467-5.5 5.5-5.5h3.5v1H15a4.505 4.505 0 0 0-4.5 4.5v2Z"
      fill="#667368"
    />
  </svg>
);
export default SvgNotificationPayment;
