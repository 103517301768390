/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { AccountSetupArrayShimmer } from '../../../ui/organism/accountSetupArrayShimmer';
import Heading from '../../../ui/atoms/heading/Heading';
import Paragraph from '../../../ui/atoms/paragraph/Paragraph';
import SvgEdit from '../../../ui/icons/Edit';
import SvgTrash from '../../../ui/icons/Trash';
import AccountSetupTemplate from '../AccountSetupTemplate';
import ToastWrapper from '../../../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../../../ui/atoms/toast/types';
import Button from '../../../ui/atoms/button/Button';
import SvgPlus from '../../../ui/icons/Plus';
import { PageProps } from '../types';
import { useModal } from '../../../hooks/useModal';
import { useToast } from '../../../hooks/useToast';
import { COLORS } from '../../../constants/colors';
import ImportClients from '../../ImportClients';
import ImportFileLink from '../../ImportFileLink';
import AddClientModal from '../../../modals/AddClientModal';
import { useSalonCache } from '../../../hooks/useSalonCache';
import axios from 'axios';
import { Client, DeleteClientDocument, SalonClientsDocument } from '../../../graphql/generated';
import { API_ERRORS } from '../../../constants/errors';
import { print } from 'graphql'

const ClientComponent = ({ onNextPage, onPrevPage, business }: PageProps) => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const {
    isVisible,
    openModal,
    closeModal,
  } = useModal();
  const { addToast, toast } = useToast();
  const [clients, setClients] = useState<Client[] | null>(null);
  const [clientsLoading, setClientsLoading] = useState(false)
  const businessId = business?.businessId;

  const getClients = async () => {
    setClientsLoading(true);
    axios
      .post(
        '/graphql',
        {
          query: print(SalonClientsDocument),
          variables: { salonId }
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { salonClients }
          }
        } = res
        setClientsLoading(false);
        setClients(salonClients?.nodes);
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
        setClientsLoading(false);
      })
  }

  useEffect(() => {
    getClients()
  }, [salonId])

  const showInitialLoadingShimmer = clientsLoading && !clients;

  const deleteClient = (clientId: string) => async () => {
    try {
      await axios.post(
        '/graphql',
        {
          query: print(DeleteClientDocument),
          variables: { input: { id: clientId } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {

        if (data?.data?.deleteClient?.status === 200) {
          addToast({
            variant: "success",
            message: "Client deleted successfully",
          });
          // refresh page
          getClients();
        } else if (data?.data?.deleteClient?.errors?.length) {
          addToast({
            variant: "error",
            message: data?.data?.deleteClient?.errors[0]?.message || API_ERRORS.BUSINESS_CLIENTS_DELETE_FAILED,
          });
        } else {
          const message = data?.errors[0]?.message || API_ERRORS.BUSINESS_CLIENTS_DELETE_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (deleteClientError) {
      if (axios.isAxiosError(deleteClientError)) {
        const message = deleteClientError?.response?.data?.message || API_ERRORS.BUSINESS_CLIENTS_DELETE_FAILED;
        addToast({
          variant: "error",
          message,
        });
      }
    }
  }

  const editClient = (clientId: string) => () => {
    const client = clients?.find((client) => client.id === clientId);
    if (!client) return;
    setSelectedClient(client);
    openModal();
  }

  const getClientsContent = () => {
    if (showInitialLoadingShimmer) {
      return <AccountSetupArrayShimmer />
    }
    if (Array.isArray(clients) && clients.length) {
      return (
        <>
          {clients.map((client) => {
            return (
              <div className='flex items-center justify-between py-4 px-2 border-b border-grey-20'>
                <div className='flex flex-col space-y-1'>
                  <Heading variant='h2' size='h10' weight='normal' className='text-black'>{client?.firstName} {client?.lastName}</Heading>
                  <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]} className=''>Phone: {client?.phone} | Email: {client?.email}</Paragraph>
                </div>
                <div className='flex space-x-6'>
                  <SvgEdit width="24px" height="24px" className='cursor-pointer' onClick={editClient(client?.id)} />
                  <SvgTrash width="24px" height="24px" className='cursor-pointer' onClick={deleteClient(client.id)} />
                </div>
              </div>
            )
          })}
        </>
      )
    }

    return (
      <div className='flex w-full justify-center items-center py-4'>
        <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]} className=''>You have no clients yet.</Paragraph>
      </div>
    )
  }

  return (
    <>
      <AccountSetupTemplate
        title='Clients'
        subtitle='Import or add your clients'
        skip={{
          text: 'Skip Business Setup',
        }}
      >
        <ToastWrapper toast={toast as ToastProps} />
        <div className='flex space-x-4'>
          <ImportClients size='md' addToast={addToast} refetch={getClients} />
          <Button
            variant='primary'
            className=''
            size='md'
            rounded='md'
            type='button'
            onClick={openModal}
          >
            <SvgPlus width="24px" height="24px" color='text-white' />
            Add Client
          </Button>
        </div>
        <ImportFileLink modules='client' />
        <div className='w-full flex flex-col space-y-4 max-h-[400px] overflow-y-scroll'>
          {getClientsContent()}
        </div>
        <div className='w-full flex space-x-2'>
          <Button
            variant='secondary'
            className=''
            disabled={false}
            onClick={onPrevPage}
            size='lg'
            rounded='lg'
            type='button'
          >
            Prev
          </Button>
          <Button
            variant='primary'
            className=''
            disabled={false}
            onClick={onNextPage}
            size='lg'
            rounded='lg'
          >
            Proceed
          </Button>
        </div>
      </AccountSetupTemplate>
      <AddClientModal
        isVisible={isVisible}
        closeModal={closeModal}
        businessId={businessId}
        salonId={salonId}
        client={selectedClient}
        refetch={getClients}
        addToast={addToast}
        removeClientFromState={() => setSelectedClient(null)}
      />
    </>
  )
}

export default ClientComponent