/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import WelcomeMessage from '../uicomponents/WelcomeMessage'
import Layout from '../ui/layout/Layout'
import Button from '../ui/atoms/button/Button'
import { endOfDay, startOfDay } from 'date-fns';
import { useModal } from '../hooks/useModal'
import { useUserCache } from '../hooks/useUserCache'
import { useSalonCache } from '../hooks/useSalonCache'
import { useToast } from '../hooks/useToast'
import { Fragment, useEffect, useState } from 'react'
import { Appointment, AppointmentService, DeleteAppointmentDocument, Sale, TopSeller, UpdateAppointmentStatusDocument, UserDocument } from '../graphql/generated'
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper'
import { ToastProps } from '../ui/atoms/toast/types'
import { Menu, Transition } from '@headlessui/react'
import SvgSelectDropDown from '../ui/icons/SelectDropDown'
import { COLORS } from '../constants/colors'
import Heading from '../ui/atoms/heading/Heading'
import Paragraph from '../ui/atoms/paragraph/Paragraph'
import Anchor from '../ui/atoms/anchor/Anchor'
import SvgCharmMenuKebab from '../ui/icons/CharmMenuKebab'
import { copyTextToClipboard, formatDateToOriginalDate, formatInToPrice, getTimeFromISODate, limitString } from '../utils/misc'
import Chart from 'react-google-charts'
import HomeEmptyCard from '../uicomponents/homeComponents/HomeEmptyCard'
import { createAppointmentSummaryGroup, createTopSellersGroup, topSellersColors } from '../uicomponents/homeComponents/utils'
import HomeCardsShimmer from '../uicomponents/homeComponents/HomeCardsShimmer'
import { PAGE_ROUTES } from '../constants/routes'
import { convertAppointmentServicesNamesToString, convertServicesNamesToStringForBooking } from '../uicomponents/bookingSales/utils'
import AddAppointmentModal from '../modals/AddAppointmentModal'
import AddSaleModal from '../modals/AddSale'
import SuccessModal from '../modals/SuccessModal'
import DeleteAppointmentModal from '../uicomponents/appointmentComponents/modals/DeleteAppointmentModal'
import ViewSaleReceiptModal from '../modals/ViewSaleReceiptModal'
import ViewAppointmentModal from '../modals/ViewAppointmentModal';
import { fetchSales, fetchTodayProjectedSale, fetchTopSellers, fetchUnassignedAppointments } from '../modals/utils';
import { fetchAppointments } from '../uicomponents/appointmentComponents/api';
import { API_ERRORS } from '../constants/errors';
import axios from 'axios';
import { print } from 'graphql'
import { SvgArrowRight, SvgCalendar, SvgClose, SvgCopy, SvgInfo, SvgRoundEye, SvgSale, SvgUsers } from '../ui/icons';
import { CalendarPicker, NameAvatar } from '../ui';
import AddClientModal from '../modals/AddClientModal';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
import { PERMISSION_CONSTANTS } from '../constants/permission';
import { canPerformAction, canShowPage } from '../utils/permission';

const Dashboard = () => {
  // @ts-expect-error Env set in application.html.erb
  const spliceBookingLink = envVariables.spliceBookingLink
  const { getBusinessData, skipSetUp, checkBusinessSetUp } = useUserCache();
  const { getSalonData } = useSalonCache();
  const salon = getSalonData();
  const salonId = salon?.id;
  const branchName = salon?.branchName;
  const { addToast, toast } = useToast();
  const [appointmentId, setAppointmentId] = useState<string | null>(null);
  const [salonBranchName, setSalonBranchName] = useState(branchName);
  const [selectedSale, setSelectedSale] = useState<Sale | null>(null)
  const [totalProjectedSales, setTotalProjectedSales] = useState(0);
  const business = getBusinessData();
  const [businessIdentifier, setBusinessIdentifier] = useState<string | null>(business?.bookingUrlIdentifier || null)
  const checkIfSetupIsSkipped = checkBusinessSetUp();
  const [unassignedAppointmentsData, setUnassignedAppointmentsData] = useState<Appointment[]>([])
  const [unassignedAppointmentIsLoading, setUnassignedAppointmentIsLoading] = useState(false)
  const {
    isVisible,
    openModal,
    closeModal,
  } = useModal();
  const [actions, setActions] = useState({
    addAppointment: false,
    addSale: false,
    addClient: false,
    viewAppointment: false,
    setupProfile: false,
  })

  // const startDate = startOfDay(new Date()).toISOString();
  // const endDate = endOfDay(new Date()).toISOString();

  const startDateTime = formatDateToOriginalDate(startOfDay(new Date()), "start");
  const endDateTime = formatDateToOriginalDate(endOfDay(new Date()), "end");

  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>([new Date(startDateTime), new Date(endDateTime)])
  const todayAppointmentData = [] as any;
  const [todayAppointmentServices, setTodayAppointmentServices] = useState<Appointment[]>([]);
  const [salesData, setSalesData] = useState<Sale[]>([]);
  const [topSellersData, setTopSellersData] = useState<TopSeller[]>([]);
  const [todayAppointmentIsLoading, setTodayAppointmentIsLoading] = useState(false);
  const [isSalesLoading, setIsSalesLoading] = useState(false);
  const showInitialLoadingShimmer = todayAppointmentIsLoading && !todayAppointmentServices;
  const showSalesInitialLoadingShimmer = isSalesLoading && !salesData;
  const showNewBookingInitialLoadingShimmer = unassignedAppointmentIsLoading && !unassignedAppointmentsData;

  useEffect(() => {
    getTodaySales()
    getTodayAppointmentServices()
  }, [selectedDates])

  const getTodaySales = async () => {
    const startDate = formatDateToOriginalDate(selectedDates[0], "start");
    const endDate = formatDateToOriginalDate(selectedDates[1], "end");
    const amount = await fetchTodayProjectedSale({ salonId, startDate, endDate });
    const topSellers = await fetchTopSellers({ salonId, startDate, endDate });
    setTopSellersData(topSellers)
    setTotalProjectedSales(amount)
  }

  const getTodayAppointmentServices = async () => {
    const startDate = formatDateToOriginalDate(selectedDates[0], "start");
    const endDate = formatDateToOriginalDate(selectedDates[1], "end");
    setTodayAppointmentIsLoading(true)
    const appointments = await fetchAppointments({ salonId, startDate, endDate }) || [];
    // filter appointment return appointments where appointment?.appointment?.isActive
    const todayAppointments = Array?.isArray(appointments) && appointments?.filter((appointment: Appointment) => appointment?.isActive)
    setTodayAppointmentIsLoading(false)
    setTodayAppointmentServices(todayAppointments)
  }

  const getUnassignedAppointmentAsync = async () => {
    setUnassignedAppointmentIsLoading(true)
    const getAppointmentServicesData = await fetchUnassignedAppointments({ salonId, status: "pending" });
    setUnassignedAppointmentIsLoading(false)
    setUnassignedAppointmentsData(getAppointmentServicesData)
  }

  useEffect(() => {
    if (checkIfSetupIsSkipped?.skip) {
      closeModal();
      return;
    }

    if (business) {
      setBusinessIdentifier(business?.bookingUrlIdentifier || ''); // Set businessIdentifier if it exists
      if (business?.isAccountSetupComplete === false) {
        openModal();
      }
    }
  }, [checkIfSetupIsSkipped, business]);

  const goToAccountSetup = () => {
    closeModal();
    window.location.href = '/account-setup'
  }

  useEffect(() => {
    if (!canPerformAction('Home::ViewPage')) {
      window.location.href = '/calendar'
    }
  }, [])

  const initiateDeleteAppointment = () => {
    closeViewAppointmentModal();
    openDeleteAppointmentModal();
  }

  const finalizeAppointmentDeletion = (action: string) => {
    if (action === 'delete') {
      deleteAppointmentAsync(appointmentId)
      closeDeleteAppointmentModal();
    } else {
      closeDeleteAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const initiateCancelAppointment = () => {
    closeViewAppointmentModal();
    openCancelAppointmentModal();
  }

  const {
    isVisible: isAddAppointmentModalVisible,
    openModal: openAddAppointmentModal,
    closeModal: closeAddAppointmentModal,
  } = useModal();

  const {
    isVisible: isAddClientModalVisible,
    openModal: openAddClientModal,
    closeModal: closeAddClientModal,
  } = useModal();

  const {
    isVisible: isViewAppointmentModalVisible,
    openModal: openViewAppointmentModal,
    closeModal: closeViewAppointmentModal,
  } = useModal();

  const {
    isVisible: isAddSaleModalVisible,
    openModal: openAddSaleModal,
    closeModal: closeAddSaleModal,
  } = useModal();

  const {
    isVisible: successModalIsVisible,
    openModal: openSuccessModal,
    closeModal: closeSuccessModal,
  } = useModal();

  const {
    isVisible: isDeleteAppointmentModalVisible,
    openModal: openDeleteAppointmentModal,
    closeModal: closeDeleteAppointmentModal,
  } = useModal();

  const {
    isVisible: isCancelAppointmentModalVisible,
    openModal: openCancelAppointmentModal,
    closeModal: closeCancelAppointmentModal,
  } = useModal();

  const {
    isVisible: isSaleReceiptModalVisible,
    openModal: openSaleReceiptModal,
    closeModal: closeSaleReceiptModal,
  } = useModal();

  const initiateSaleReceipt = (sale: Sale) => {
    setSelectedSale(sale);
    openSaleReceiptModal();
  }

  const initiateCloseAddAppointmentModal = (action: string) => {
    closeAddAppointmentModal();
    if (action === 'addedAppointment') {
      openSuccessModal();
    }
  }

  const initiateCloseAddSaleModal = (action: string, sale?: Sale | null) => {
    closeAddSaleModal();
    refetchData();
    if (action === 'addedSale' && sale) {
      initiateSaleReceipt(sale);
    }
  }

  const closeSuccessModalAndReloadCalendar = () => {
    closeSuccessModal();
    refetchData();
  }

  useEffect(() => {
    if (salonId) {
      refetchData()
    }
  }, [salonId])

  useEffect(() => {
    setActions({
      addAppointment: canPerformAction(`Home::${PERMISSION_CONSTANTS.appointment.add}`),
      addSale: canPerformAction(`Home::${PERMISSION_CONSTANTS.sale.add}`),
      addClient: canPerformAction(`Home::${PERMISSION_CONSTANTS.client.add}`),
      viewAppointment: canPerformAction(PERMISSION_CONSTANTS.calendar.viewAppointment),
      setupProfile: canPerformAction(`Home::${PERMISSION_CONSTANTS.account.setup}`)
    })
  }, [])

  const initiateViewAppointment = (appointmentId: string) => {
    setAppointmentId(appointmentId);
    openViewAppointmentModal();
  }

  const initiateCloseViewAppointmentModal = (action: string) => {
    setAppointmentId(null);
    closeViewAppointmentModal();
    if (action === 'updatedAppointment') {
      refetchData();
    }
  }

  const refetchData = () => {
    getUnassignedAppointmentAsync();
    getTodaySales();
    getTodayAppointmentServices();
  }

  const getTodayAppointmentList = () => {
    if (showInitialLoadingShimmer) {
      return <HomeCardsShimmer />
    }

    if (todayAppointmentServices && Array.isArray(todayAppointmentServices) && todayAppointmentServices?.filter(serviceAppointment => serviceAppointment?.appointmentStatus === 'pending' || serviceAppointment?.appointmentStatus === 'confirmed').length > 0) {
      return (todayAppointmentServices?.filter(serviceAppointment => serviceAppointment?.appointmentStatus === 'pending' || serviceAppointment?.appointmentStatus === 'confirmed')?.slice(0, 3) || []).map((appointment) => (
        <div className='w-full flex items-start justify-between gap-3 cursor-pointer' onClick={() => actions?.viewAppointment ? initiateViewAppointment(appointment?.id) : null} key={appointment?.id}>
          <div className='flex gap-3'>
            <NameAvatar
              name={
                appointment?.client?.firstName?.charAt(0).toLocaleUpperCase() +
                appointment?.client?.lastName?.charAt(0).toLocaleUpperCase()
              }
            />
            <div className='flex flex-col'>
              <Paragraph size='b5'>{`${appointment?.client?.firstName} ${appointment?.client?.lastName}`}</Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[300]}>{getTimeFromISODate(appointment?.startAt)} · {limitString(appointment?.appointmentServices?.map(({ name }) => name).join(', '), 25)}</Paragraph>
            </div>
          </div>
          <SvgRoundEye width="24px" height="24px" />
        </div>
      ));
    }
    return <HomeEmptyCard variant='appointment' title="No appointments. Visit your calendar to add some appointments for today" />;
  }

  const getUnassignedBookingList = () => {
    if (showNewBookingInitialLoadingShimmer) {
      return <HomeCardsShimmer />
    }

    if (unassignedAppointmentsData && Array.isArray(unassignedAppointmentsData) && unassignedAppointmentsData?.length > 0) {
      return (unassignedAppointmentsData?.slice(0, 3) || []).map((booking, index) => (
        <div className='w-full flex items-start space-x-4' key={index}>
          <Paragraph size='b5' weight='bold' color={COLORS.GREY[300]}>{`${index + 1}`}</Paragraph>
          <div className='w-full flex flex-col'>
            <Paragraph size='b5'>{`${booking?.client?.firstName} ${booking?.client?.lastName}`}</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[300]}>{limitString(convertAppointmentServicesNamesToString(booking?.appointmentServices), 35)}</Paragraph>
          </div>
        </div>
      ));
    }

    return <HomeEmptyCard variant="appointment" title="No bookings to assign. Share your booking link with customers" />
  }

  const goToCalendarPage = () => {
    window.location.href = PAGE_ROUTES.CALENDAR
  }

  const goToSalesPage = () => {
    window.location.href = PAGE_ROUTES.SALES
  }

  const data = createAppointmentSummaryGroup(todayAppointmentServices || []);
  const sellersChartData = createTopSellersGroup(topSellersData || []);

  const copyBookingLink = () => {
    copyTextToClipboard(`${spliceBookingLink}${businessIdentifier}`);
    addToast({
      variant: "success",
      message: 'Booking link copied to clipboard',
    });
  };

  const checkInOrOutAppointmentAsync = async ({
    appointmentId, status, paymentMethod,
  }: {
    appointmentId: string,
    status: string,
    paymentMethod?: string,
  }) => {
    try {
      const payload = {
        appointmentStatus: status,
        id: appointmentId,
        salonId,
      }
      await axios.post(
        '/graphql',
        {
          query: print(UpdateAppointmentStatusDocument),
          variables: { input: { ...payload } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        if (data?.data?.updateAppointmentStatus?.status === "200") {
          refetchData();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment updated successfully",
            variant: 'success',
          })
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
        }


        if (data?.data?.updateAppointmentStatus?.errors?.length) {
          const message = data?.data?.updateAppointment?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (checkoutAppointmentError) {
      if (axios.isAxiosError(checkoutAppointmentError)) {
        const message = checkoutAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  };

  const deleteAppointmentAsync = async (id: string | null) => {
    if (!id) return;

    try {
      await axios.post(
        '/graphql',
        {
          query: print(DeleteAppointmentDocument),
          variables: {
            salonId,
            id,
          },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        const clientDetailsData = data?.data?.deleteAppointment;
        if (clientDetailsData) {
          refetchData();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment removed successfully",
            variant: 'success',
          })
        } else {
          const message = API_ERRORS.APPOINTMENT_DELETION_FAILED;
          addToast({
            message,
            variant: 'error',
          })
        }
      })
      } catch (deleteAppointmentError) {
      if (axios.isAxiosError(deleteAppointmentError)) {
        const message = deleteAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_DELETION_FAILED;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }
  return (
    <>
      <Layout
        pageTitle="Home"
        setSalonBranchName={setSalonBranchName}
      >
        <ToastWrapper toast={toast as ToastProps} />
        <div className='w-full space-y-6'>
          <div className="w-full flex justify-between items-center py-4 px-6 border-b border-grey-50">
            <Heading variant={'h1'} size='h11' weight='bold'>Home</Heading>
            <div className='flex space-x-4 items-center relative'>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-green-300/10 px-4 py-2 text-b5 font-semibold text-green-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                    <span>Booking link</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50 w-[292px]">
                    <div className='flex-col w-full space-y-2 bg-white shadow-md max-w-[292px] p-3'>
                      <div className='flex w-full space-x-3'>
                        <div className='w-[24px] h-[24px] bg-grey-50 rounded-full flex justify-center items-center'>
                          <SvgCopy width="16px" height="16px" />
                        </div>
                        <div className='flex flex-col justify-start items-start'>
                          <Paragraph size='b6' color={COLORS.GREY[300]} className="text-center">Shareable booking site link</Paragraph>
                          <Paragraph size='b5' color={COLORS.GREY[900]} weight='semiBold' className="text-center">{business?.name}</Paragraph>
                        </div>
                      </div>
                      <div className='w-full border border-grey-50 rounded-md p-3 overflow-x-scroll'>
                        <Paragraph size='b4' color={COLORS.GREY[400]} className="">{spliceBookingLink + "" + businessIdentifier}</Paragraph>
                      </div>
                      <div className="flex space-x-4">
                        <Button
                          variant='light'
                          size='md'
                          rounded='md'
                          type="button"
                          onClick={copyBookingLink}
                        >
                          Copy
                        </Button>
                        <Anchor
                          to={`${spliceBookingLink}${businessIdentifier}`}
                          target="_blank"
                        >
                          <Button
                            variant='primary'
                            className=''
                            size='md'
                            rounded='md'
                            type="button"
                          >
                            Share
                          </Button>
                        </Anchor>
                      </div>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              {actions?.addAppointment || actions?.addSale ?
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                      <span>Add</span>
                      <SvgSelectDropDown width="10px" height="10px" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                      <div className="flex flex-col p-4 space-y-4">
                        {actions?.addAppointment ?
                          <Menu.Item>
                            <span className="text-b4 cursor-pointer" onClick={openAddAppointmentModal}>New Appointment</span>
                          </Menu.Item>
                        : null}
                        {actions?.addSale ?
                          <Menu.Item>
                            <span className="text-b4 cursor-pointer" onClick={openAddSaleModal}>New Sale</span>
                          </Menu.Item>
                        : null}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                : null}
            </div>
          </div>
          {isVisible && actions?.setupProfile ? (
            <div className='w-full xl:w-[400px] xl:fixed flex flex-col h-fit right-[20px] top-[80px] z-[9] overflow-y-scroll shadow-md'>
              <div
                className={`w-full rounded-md p-3 flex space-x-2 items-start bg-orange-25 text-orange-300`}
              >
                <SvgInfo width='25px' height='25px' />
                <div className='flex flex-col space-y-2'>
                  <Heading
                    variant='h2'
                    size='h11'
                    weight='semiBold'
                    color={COLORS.ORANGE[300]}
                  >
                    You haven’t completed setup yet
                  </Heading>
                  <Paragraph size='b6' color={COLORS.ORANGE[300]}>
                  Finish setup to unlock the full experience. You can cancel and continue later in settings.
                  </Paragraph>
                  <Button
                    variant="text"
                    size='sm'
                    rounded='lg'
                    fontSize='b7'
                    className='capitalize w-fit text-orange-300 border border-orange-300'
                    onClick={goToAccountSetup}
                  >
                    complete setup
                  </Button>
                </div>
                <Button
                  variant='text'
                  size='none'
                  type='button'
                  className='w-fit text-orange-300'
                  fontSize='b4'
                  onClick={() => {
                    skipSetUp({
                      skip: true
                    })
                    closeModal();
                  }}
                >
                  <SvgClose width='24px' height='24px' />
                </Button>
              </div>
            </div>
          ) : null}
          <div className='w-full flex py-2 px-6 justify-end'>
            <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          </div>
          <div className='w-full flex flex-col space-y-6 p-6'>
            <div className='w-full flex flex-col md:flex-row gap-4'>
              <div className='w-full flex border border-grey-100 p-4 space-x-4 rounded-md'>
                <div className='w-[48px] h-[48px] bg-grey-50 rounded-full flex justify-center items-center'>
                  <SvgCalendar width="24px" height="24px" />
                </div>
                <div className='flex flex-col'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>Total appointments</Paragraph>
                  <Paragraph size='h8'>{todayAppointmentServices?.length}</Paragraph>
                </div>
              </div>

              <div className='w-full flex border border-grey-100 p-4 space-x-4 rounded-md'>
                <div className='w-[48px] h-[48px] bg-grey-50 rounded-full flex justify-center items-center'>
                  <SvgSale width="24px" height="24px" />
                </div>
                <div className='flex flex-col'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>Projected sales</Paragraph>
                  <Paragraph size='h8'>{formatInToPrice(totalProjectedSales || 0)}</Paragraph>
                </div>
              </div>
            </div>
            <div className='w-full flex flex-col xl:flex-row gap-4'>
              {canShowPage('Reports::') ?
                <>
                  <div className='flex flex-col w-full xl:w-4/12 border border-grey-100 p-4 rounded-md space-y-3 overflow-y-scroll h-[280px]'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>TOTAL APPOINTMENTS</Paragraph>
                    {todayAppointmentServices && todayAppointmentServices.length ? (
                      <Doughnut data={data} options={
                        {
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              position: 'right',
                            },
                            title: {
                              display: false,
                              text: 'Total Appointments'
                            }
                          }
                        }
                      } />
                    ) : (
                      <HomeEmptyCard variant='appointment' title="No appointments. Visit your calendar to add some appointments for today" />
                    )}
                  </div>
                  <div className='flex flex-col w-full xl:w-4/12 border border-grey-100 p-4 rounded-md space-y-3 h-[280px]'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>TOP SELLERS</Paragraph>
                    {topSellersData && topSellersData.length ? (
                      <>
                        <div className='flex flex-wrap gap-3'>
                          {topSellersData.map((seller, index) => (
                            <div key={index} className='flex space-x-2 items-center'>
                              <span style={{
                                  backgroundColor: topSellersColors[index], // Use backgroundColor instead of bgColor
                                }} className={`w-[12px] h-[12px] rounded-sm`}></span>
                              <Paragraph size='b5' color={COLORS.GREY[300]}>{seller.name}</Paragraph>
                            </div>
                          ))}
                        </div>
                        <div className='w-full h-[180px]'>
                          <Doughnut data={sellersChartData} options={
                            {
                              responsive: true,
                              maintainAspectRatio: false,
                              plugins: {
                                legend: {
                                  display: false,
                                  position: 'top',
                                },
                                title: {
                                  display: false,
                                  text: 'Top Sellers'
                                }
                              }
                            }
                          }
                            height="100%"  // Make sure height and width are managed by the container
                            width="100%" />
                        </div>
                      </>
                    ) : (
                      <HomeEmptyCard variant='appointment' title="Top seller data is empty. Visit your calendar to add some appointments for today" />
                    )}
                  </div>
                </> : null}
              <div className='flex flex-col w-full xl:w-4/12 border border-grey-100 p-4 rounded-md space-y-6 h-[280px]'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>UPCOMING APPOINTMENTS</Paragraph>
                {getTodayAppointmentList()}
              </div>
            </div>
            {actions?.addAppointment || actions?.addClient || actions?.addSale ?
              <div className='w-full flex flex-col border border-grey-100 p-4 gap-4 rounded-md'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>QUICK ACTIONS</Paragraph>
                <div className='w-full flex flex-col lg:flex-row gap-6'>
                  {actions?.addAppointment ?
                    <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 bg-grey-10 rounded-md cursor-pointer' onClick={openAddAppointmentModal}>
                      <div className='flex space-x-3 items-center'>
                        <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                          <SvgCalendar width="16px" height="16px" />
                        </div>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Add new appointment</Paragraph>
                      </div>
                      <SvgArrowRight width="16px" height="16px" />
                    </div>
                    : null}
                  {actions?.addSale ?
                    <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 bg-grey-10 rounded-md cursor-pointer' onClick={openAddSaleModal}>
                      <div className='flex space-x-3 items-center'>
                        <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                          <SvgSale width="16px" height="16px" />
                        </div>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Add new sale</Paragraph>
                      </div>
                      <SvgArrowRight width="16px" height="16px" />
                    </div>
                    : null}
                  {actions?.addSale ?
                    <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 bg-grey-10 rounded-md cursor-pointer' onClick={openAddClientModal}>
                      <div className='flex space-x-3 items-center'>
                        <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                          <SvgUsers width="16px" height="16px" />
                        </div>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Add new client</Paragraph>
                      </div>
                      <SvgArrowRight width="16px" height="16px" />
                    </div>
                    : null}
                </div>
              </div>
              : null}
          </div>
        </div >
      </Layout>
      <AddAppointmentModal
        isVisible={isAddAppointmentModalVisible}
        closeModal={initiateCloseAddAppointmentModal}
        addToast={addToast}
        salonId={salonId}
      />
      <AddSaleModal
        isVisible={isAddSaleModalVisible}
        closeModal={initiateCloseAddSaleModal}
        addToast={addToast}
        salonId={salonId}
      />
      <AddClientModal
        isVisible={isAddClientModalVisible}
        closeModal={closeAddClientModal}
        addToast={addToast}
        salonId={salonId}
        businessId=''
      />
      <SuccessModal
        isVisible={successModalIsVisible}
        closeModal={closeSuccessModalAndReloadCalendar}
      />
      <ViewAppointmentModal
        isVisible={isViewAppointmentModalVisible}
        closeModal={initiateCloseViewAppointmentModal}
        cancelAppointment={initiateCancelAppointment}
        deleteAppointment={initiateDeleteAppointment}
        salonId={salonId}
        addToast={addToast}
        appointmentId={appointmentId}
        checkInOrOutAppointment={checkInOrOutAppointmentAsync}
      />
      <DeleteAppointmentModal
        isVisible={isDeleteAppointmentModalVisible}
        closeModal={finalizeAppointmentDeletion}
      />
      <ViewSaleReceiptModal sale={selectedSale} salonId={salonId} closeModal={() => {
        closeSaleReceiptModal(), setSelectedSale(null)
      }} isVisible={isSaleReceiptModalVisible} addToast={addToast} />
    </>
  )
}

export default Dashboard
