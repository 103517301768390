export const DEFAULT_CURRENCY = JSON.parse(localStorage.getItem('country'))?.currency?.symbol;
export const DEFAULT_CURRENCY_CODE = JSON.parse(localStorage.getItem('country'))?.currency?.code;
export const SMS_PRICE = 4;

export const CURRENCIES = {
  USD: '$', // United States Dollar
  EUR: '€', // Euro
  GBP: '£', // British Pound Sterling
  NGN: '₦', // Nigerian Naira
  // Add more currencies as needed
} as const;
