import React from 'react'
import { VoucherCardProps } from './types';
import { COLORS } from '../../../constants/colors';
import { formatInToPrice, limitString } from '../../../utils/misc';
import { convertVoucherServicesNamesToString } from '../../../uicomponents/bookingSales/utils';
import Paragraph from '../../atoms/paragraph/Paragraph';
import { SvgSolarGiftLinearWhite } from '../../icons';

const VoucherCard = ({
  isActive,
  voucher,
  chooseVoucher
}: VoucherCardProps) => {
  const price = voucher?.amountRedeemed < voucher?.price && voucher?.amountRedeemed !== 0 ? voucher?.price - voucher?.amountRedeemed : voucher?.price;
  return (
    <div className={`flex bg-green-300 border ${isActive ? 'border-grey-700 shadow-md shadow-green-200' : 'border-green-50'}  rounded-md p-3 space-x-3 cursor-pointer`} onClick={() => chooseVoucher()}>
      <SvgSolarGiftLinearWhite width="24px" height="24px" />
      <div className='flex flex-col space-y-2'>
        <Paragraph size="b5" color={COLORS.WHITE}>{voucher?.package?.name}</Paragraph>
        <Paragraph size="b5" color={COLORS.WHITE}>{formatInToPrice(price)} • {limitString(convertVoucherServicesNamesToString(voucher?.voucherServices), 10)}</Paragraph>
      </div>
    </div>
  )
}

export default VoucherCard