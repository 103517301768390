import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCarbonView = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15.47 7.83A8.345 8.345 0 0 0 8 2.5 8.345 8.345 0 0 0 .53 7.83a.5.5 0 0 0 0 .34A8.345 8.345 0 0 0 8 13.5a8.346 8.346 0 0 0 7.47-5.33.5.5 0 0 0 0-.34ZM8 12.5c-2.65 0-5.45-1.965-6.465-4.5C2.55 5.465 5.35 3.5 8 3.5s5.45 1.965 6.465 4.5C13.45 10.535 10.65 12.5 8 12.5Z"
      fill="#000"
    />
    <path
      d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 5a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      fill="#000"
    />
  </svg>
);
export default SvgCarbonView;
