import React from 'react'
import Skeleton from '../../ui/atoms/skeleton/Skeleton'
import ParagraphShimmer from '../../ui/atoms/paragraph/ParagraphShimmer'

const BookingPageShimmer = () => {
  return (
    <div className='flex space-x-5'>
      <div className='w-full flex flex-col space-y-6 max-w-[360px]'>
        <Skeleton width="150px" height="50px" className="rounded-md" />

        <div className='flex flex-col space-y-2'>
          <ParagraphShimmer size="md" />
          <ParagraphShimmer size="md" />
          <ParagraphShimmer size="md" />
        </div>

        <Skeleton width="150px" height="30px" className="rounded-full" />
      </div>
      <div className='hidden xl:flex w-full max-w-[490px] h-[290px]'>
        <Skeleton width="450px" height="250px" className="rounded-md" />
      </div>
    </div>
  )
}

export default BookingPageShimmer