import React, { useEffect, useState } from 'react'
import { Appointment } from '../graphql/generated';
import { fetchAppointmentSummaryByBookingId } from '../uicomponents/appointmentComponents/api';
import Heading from '../ui/atoms/heading/Heading';
import { COLORS } from '../constants/colors';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import { formatInToPrice } from '../utils/misc';
import SvgLogo from '../ui/icons/SvgLogo';

const AppointmentReceipt = () => {
  const [appointment, setAppointment] = useState<Appointment | null>(null)
  // Get the current URL
  const currentUrl = window.location.href;

  // Split the URL using '/' as the delimiter and get the last part
  const parts = currentUrl.split('/');
  const bookingId = parts[parts.length - 1];

  const getAppointmentSummaryAsync = async () => {
    const res = await fetchAppointmentSummaryByBookingId({
      bookingId
    })
    if (res?.appointment) {
      setAppointment(res?.appointment)
    }
  }

  useEffect(() => {
    if (bookingId) {
      // Fetch the appointment details from the server using the booking ID
      // and update the appointment state accordingly
      getAppointmentSummaryAsync()
    }
  }, [bookingId])
  return (
    <>
      <main className='w-full flex bg-grey-100 h-screen p-0 xl:py-[49px] xl:px-0 justify-center'>
        {appointment ? (
          <div className='w-full max-w-[490px] py-[40px] bg-white h-full flex flex-col items-center space-y-5 rounded-md overflow-y-auto'>
            <Heading variant='h1' size='h8' weight='bold'>{appointment?.salon?.business?.name}</Heading>
            <div className='space-y-2 flex flex-col items-center px-12'>
              <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{appointment?.address}</Paragraph>
            </div>
            <div className='w-full border-t border-grey-100'></div>
            <div className='space-y-3 flex flex-col items-center px-12'>
              <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>Billed to: {appointment?.client?.firstName} {appointment?.client?.lastName}</Paragraph>
            </div>
            <div className='space-y-3 flex flex-col items-center px-12'>
              <Heading variant='h2' size='h9' weight='bold'>Thank you for your order.</Heading>
            </div>
            <div className='w-full flex flex-col space-y-2 px-[40px]'>
              <div className='flex justify-between items-center bg-grey-50 px-4 py-2'>
                <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Item</Heading>
                <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Amount</Heading>
              </div>

              {appointment?.appointmentServices?.map((service) => (
                <div className='flex justify-between items-center px-4 py-2' key={service?.id}>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>{service?.name} x{service?.quantity}</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(service?.price, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
              ))}

              {appointment?.appointmentProducts?.map((product) => (
                <div className='flex justify-between items-center px-4 py-2' key={product?.id}>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>{product?.name} x{product?.quantity}</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(product?.price * (product?.quantity || 1), appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
              ))}

              <div className='border-t border-grey-50'></div>
              <div className='flex justify-between items-center px-4 py-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Subtotal</Paragraph>
                <Paragraph size='b5'>{formatInToPrice(appointment?.totalServicesAmount as number + appointment?.totalProductsAmount as number, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
              </div>
              {appointment?.taxAmount ? (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Tax</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(appointment?.taxAmount, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
              ) : null}
              {appointment?.processingFeeAmount ? (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Discount</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(appointment?.processingFeeAmount, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
              ) : null}
              <div className='flex justify-between items-center px-4 py-2'>
                <Paragraph size='b4' color={COLORS.GREY[400]}>Discount</Paragraph>
                <Paragraph size='b4'>-{formatInToPrice(appointment?.appointmentPromo?.amount || 0, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
              </div>
              <div className='border-t border-grey-50'></div>
              <div className='flex justify-between items-center px-4 py-2'>
                <Paragraph size='b4' weight='bold' className='inline' color={COLORS.GREY[300]}>Total</Paragraph>
                <Paragraph size='b4' weight='bold' className='inline'>{formatInToPrice(appointment?.totalPaid, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
              </div>
            </div>

            <div className='flex justify-center items-end space-x-2 mt-[20px]'>
              <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Powered by</Paragraph> <SvgLogo width="90px" height="100%" />
            </div>
          </div>
        ) : null}
      </main>
    </>
  )
}

export default AppointmentReceipt