export const calculateDaysBetweenDates = (dates: [Date, Date]) => {
  const startDate = dates[0];
  const endDate = dates[1];
  const currentDate = new Date();
  const yesterday = new Date();
  yesterday.setDate(currentDate.getDate() - 1);
  const tomorrow = new Date();
  tomorrow.setDate(currentDate.getDate() + 1);
  const thisWeekStart = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
  const todayStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  const thisMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  const dateDiff = Math.abs(endDate.getTime() - startDate.getTime());
  const daysDiff = Math.ceil(dateDiff / (1000 * 60 * 60 * 24));

  if (startDate.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  } else if (startDate.toDateString() === currentDate.toDateString()) {
    return "Today";
  } else if (startDate.toDateString() === tomorrow.toDateString()) {
    return "Tomorrow";
  } else if (startDate >= thisWeekStart && endDate <= todayStart) {
    return "This week";
  } else if (startDate >= thisMonthStart && endDate <= todayStart) {
    return "This month";
  } else {
    return `${daysDiff} Days`;
  }
}
