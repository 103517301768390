import * as React from "react";
import BasePill from "./BasePill";
import { BasePillProps } from "./types";

const SecondaryPill = ({ className, style, children }: BasePillProps) => {
  return (
    <BasePill
      style={style}
      className={`text-grey-900 border border-grey-100 bg-grey-100 ${
        className || ""
      }`}
    >
      {children}
    </BasePill>
  );
};

export default SecondaryPill;
