import * as React from "react";
import { ContactLinkProps } from './types'

const ContactLink = (props: ContactLinkProps) => {
  return (
    <a className='text-green-850 hover:text-green-850 text-b4' href={`${props.variant}:${props.value}`}>
      {props.label}
    </a>
  )
}

export default ContactLink