import React from 'react'
import SvgInfo from '../../ui/icons/Info'
import { Heading, Paragraph } from '../../ui'
import { DisclaimerProps } from './types'
import { COLORS } from '../../constants/colors'

const Disclaimer = (props: DisclaimerProps) => {
  return (
    <>
      <div className={`hidden w-full rounded-md p-3 xl:flex space-x-2 ${props.type === 'orange' ? 'bg-orange-25 text-orange-300': 'bg-grey-100 text-grey-300'}`}>
        <SvgInfo width="25px" height="25px" />
        <div className="flex flex-col space-y-2">
          <Heading variant='h2' size='h11' weight='semiBold' color={props.type === 'orange' ? COLORS.ORANGE[300] : COLORS.GREY[300]}>{props.title}</Heading>
          <Paragraph size='b6' color={props.type === 'orange' ? COLORS.ORANGE[300] : COLORS.GREY[300]}>{props.message}</Paragraph>
        </div>
      </div>
      <div className={`w-full p-3 flex flex-col justify-center items-center xl:hidden space-x-2 ${props.type === 'orange' ? 'bg-orange-25 text-orange-300' : 'bg-grey-100 text-grey-300'}`}>
        <div className={`flex space-x-3 items-center ${props.type === 'orange' ? 'text-orange-300' : 'text-grey-300'}`}>
          <SvgInfo width="25px" height="25px" />
          <Heading variant='h2' size='h11' weight='semiBold' color={props.type === 'orange' ? COLORS.ORANGE[300] : COLORS.GREY[300]}>{props.title}</Heading>
        </div>
        <Paragraph size='b6' color={props.type === 'orange' ? COLORS.ORANGE[300] : COLORS.GREY[300]}>{props.message}</Paragraph>
      </div>
      {/* {isOpen ? (
        <div className="w-full h-full fixed top-0 bg-black/70 backdrop-blur-[2px] left-0 flex z-40 no-doc-scroll items-center justify-center">
          <div className="table-row">
            <div className="table-cell align-middle">
              <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
                <div className="flex flex-col items-center space-y-6">
                  <Heading variant='h2' size='h11' weight='semiBold' color={props.type === 'orange' ? COLORS.ORANGE[300] : COLORS.GREY[300]}>{props.title}</Heading>
                  <Paragraph size='b6' color={props.type === 'orange' ? COLORS.ORANGE[300] : COLORS.GREY[300]}>{props.message}</Paragraph>
                  
                  <Button
                    variant="text"
                    size='none'
                    rounded='none'
                    fontSize='b5'
                    className='mx-0'
                    onClick={() => setIsOpen(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
    </>
  )
}

export default Disclaimer