import * as React from "react";
const PrimaryPill = React.lazy(() => import("./Primary"));
const SuccessPill = React.lazy(() => import("./Success"));
const WarningPill = React.lazy(() => import("./Warning"));
const DangerPill = React.lazy(() => import("./Danger"));
const LightPill = React.lazy(() => import("./Light"));
const SecondaryPill = React.lazy(() => import("./Secondary"));
const PendingPill = React.lazy(() => import("./Pending"));
const CustomPill = React.lazy(() => import("./Custom"));
const BlackPill = React.lazy(() => import("./Black"));

export const PILL_VARIANT_TO_COMPONENT_MAPPING = {
  primary: PrimaryPill,
  success: SuccessPill,
  warning: WarningPill,
  danger: DangerPill,
  light: LightPill,
  secondary: SecondaryPill,
  custom: CustomPill,
  pending: PendingPill,
  black: BlackPill,
} as const;
