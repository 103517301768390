import React, { createContext, useContext, useState } from 'react'
import {
  AutomatedMessage,
  AutomatedMessagesDocument,
  Client,
  CreateMessageDocument,
  CreateMessageInput,
  CustomMessagesDocument,
  DeleteMessageDocument,
  Message,
  PageInfo,
  SalonClientsDocument
} from '../graphql/generated'
import axios from 'axios'
import { print } from 'graphql'
import { useToast } from '../hooks'
import { useSalonCache } from '../hooks/useSalonCache'

const MessageContext = createContext(undefined)

type SalonClientConnection = {
  nodes: Client[]
  pageInfo: PageInfo
}

const MessageProvider = ({ children }) => {
  const token = localStorage.getItem('token')
  const [automatedMessages, setAutomatedMessages] = useState<
    AutomatedMessage[]
  >([])
  const [clients, setClients] = useState<SalonClientConnection>({
    nodes: [],
    pageInfo: null
  })
  const [customMessages, setCustomMessages] = useState<Message[]>([])
  const { toast, addToast } = useToast()
  const { getSalonFieldValue } = useSalonCache()

  const getAutomatedMessages = () => {
    axios
      .post(
        '/graphql',
        { query: print(AutomatedMessagesDocument) },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((res) => {
        setAutomatedMessages(res.data.data.automatedMessages)
      })
      .catch((error) => {
        addToast({ message: error.message, variant: 'error' })
      })
  }

  const getCustomMessages = () => {
    axios
      .post(
        '/graphql',
        {
          query: print(CustomMessagesDocument),
          variables: { salonId: getSalonFieldValue('id') }
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((res) => {
        setCustomMessages(res.data.data.customMessages)
      })
      .catch((error) => {
        addToast({ message: error.message, variant: 'error' })
      })
  }

  const getClients = (after?: string) => {
    axios
      .post(
        '/graphql',
        {
          query: print(SalonClientsDocument),
          variables: { salonId: getSalonFieldValue('id'), first: 50, after }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { salonClients }
          }
        } = res;
        // update clients, merge with new salonClients?.nodes with existing data
        setClients(salonClients)
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  const createMessage = async (input: CreateMessageInput, handleCancel: () => void) => {
    try {
      const {
        data: {
          data: { createMessage: cm }
        }
      } = await axios.post(
        '/graphql',
        { query: print(CreateMessageDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (cm.status === 200) {
        getCustomMessages()
        handleCancel()
        addToast({ message: 'Message saved successfully', variant: 'success' })
      } else {
        addToast({ message: cm.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const deleteMessage = async (id: string) => {
    try {
      const {
        data: {
          data: { deleteMessage: dm }
        }
      } = await axios.post(
        '/graphql',
        { query: print(DeleteMessageDocument), variables: { input: { id } } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (dm.status === 200) {
        getCustomMessages()
        addToast({
          message: 'Message deleted successfully',
          variant: 'success'
        })
      } else {
        addToast({ message: dm.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  return (
    <MessageContext.Provider
      value={{
        toast,
        automatedMessages,
        customMessages,
        clients,
        getCustomMessages,
        addToast,
        getAutomatedMessages,
        getClients,
        createMessage,
        deleteMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  )
}

// Message Context custom hook
export const useMessageContext = () => {
  const context = useContext(MessageContext)
  if (!context) {
    throw new Error('useMessageContext must be used with a MessageProvider')
  }

  return context
}

export default MessageProvider
