import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRoundEye = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect y={0.5} width={29} height={29} rx={14.5} fill="#F3F6F6" />
    <path
      d="M21.97 14.83A8.345 8.345 0 0 0 14.5 9.5a8.345 8.345 0 0 0-7.47 5.33.5.5 0 0 0 0 .34 8.345 8.345 0 0 0 7.47 5.33 8.346 8.346 0 0 0 7.47-5.33.5.5 0 0 0 0-.34ZM14.5 19.5c-2.65 0-5.45-1.965-6.465-4.5 1.015-2.535 3.815-4.5 6.465-4.5s5.45 1.965 6.465 4.5c-1.015 2.535-3.815 4.5-6.465 4.5Z"
      fill="#696969"
    />
    <path
      d="M14.5 12a3 3 0 1 0 0 5.999 3 3 0 0 0 0-5.999Zm0 5a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      fill="#696969"
    />
  </svg>
);
export default SvgRoundEye;
