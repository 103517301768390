import { ClientVouchersDocument, CustomerLoyaltyOverviewDataDocument } from "../../graphql/generated";
import axios from "axios";
import { print } from "graphql";

export const fetchClientVouchersData = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(ClientVouchersDocument), variables },
    );

    const {
      data: {
        data: { clientVouchers }
      }
    } = response;

    return clientVouchers;
  } catch (error) {
    console.log(error);
  }
};

export const fetchClientLoyaltyOverviewData = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(CustomerLoyaltyOverviewDataDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('customer-token')}` } }
    );

    const {
      data: {
        data: { customerLoyaltyOverviewData }
      }
    } = response;

    return customerLoyaltyOverviewData;
  } catch (error) {
    console.log(error);
  }
};