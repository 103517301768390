import React, { useContext } from 'react'
import BookContext from './context';
import { Paragraph } from '../../ui';
import { COLORS } from '../../constants/colors';

const BookingSteps = ({ page } : { page: string }) => {
  const { selectedServices, selectedPackages, voucherToBeRedeemed } = useContext(BookContext);

  const getSteps = () => {
    if (Array.isArray(selectedServices) && selectedServices?.length) {
      if (page === 'user-details') {
        // fill user details
        return "4 of 4";
      } else if (page === 'time') {
        // select date and time
        return "2 of 4";
      } else if (page === 'phone') {
        // phone form
        return "3 of 4";
      }
    } else if (Array.isArray(selectedPackages) && selectedPackages?.length) {
      if (page === 'user-details') {
        // fill user details
        return "2 of 2";
      }
    } else if (voucherToBeRedeemed) {
      if (page === 'user-details') {
        // fill user details
        return "3 of 3";
      } else if (page === 'time' ) {
        // select date and time
        return "2 of 3";
      } else if (page === 'voucher-services') {
        // phone form
        return "1 of 3";
      }
    }
  }
  return (
    <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']} className='uppercase'>step {getSteps()}</Paragraph>
  )
}

export default BookingSteps
