import * as React from "react";
import BasePill from "./BasePill";
import { BasePillProps } from "./types";

const PrimaryPill = ({ className, style, children }: BasePillProps) => {
  return (
    <BasePill
      style={style}
      className={`text-white bg-black ${className || ""}`}
    >
      {children}
    </BasePill>
  );
};

export default PrimaryPill;
