import * as React from 'react';
import SalonLocation from "../uicomponents/accountSetupComponents/business/Location";
import Hours from '../uicomponents/accountSetupComponents/business/Hours';
import ServicesComponent from '../uicomponents/accountSetupComponents/business/Services';
import StaffComponent from '../uicomponents/accountSetupComponents/business/Staff';
import ClientComponent from '../uicomponents/accountSetupComponents/business/Client';
import SocialLinks from '../uicomponents/accountSetupComponents/appointment/SocialLinks';
import AppointmentLink from '../uicomponents/accountSetupComponents/appointment/AppointmentLink';
import Bank from '../uicomponents/accountSetupComponents/appointment/Bank';
import BankTransfers from '../uicomponents/accountSetupComponents/appointment/BankTransfers';
import Pos from '../uicomponents/accountSetupComponents/appointment/Pos';
import BusinessAboutAndHeaderImage from '../uicomponents/accountSetupComponents/appointment/BusinessAboutAndHeaderImage';
// import Location from "modules/account/components/business/Location";

const PAGE_KEYS = {
  CURRENT_PAGE: "account-setup:current-page",
  CURRENT_SUB_PAGE: "account-setup:current-sub-page",
};

const country = JSON.parse(localStorage.getItem('country') as string);

// let pages = [];

// if (country?.collectionsSupported === true && country?.payoutsSupported === true) {
const pages = [
  {
    name: "Business",
    key: 'business',
    subPages: [
      { component: SalonLocation, key: 'location', name: 'Location' },
      { component: Hours, key: 'hours', name: 'Hours' },
      { component: ServicesComponent, key: 'services', name: 'Services' },
      { component: StaffComponent, key: 'staff', name: 'Staff' },
      { component: ClientComponent, key: 'client', name: 'Client' },
    ],
  },
  {
    name: "Appointment Link Setup",
    key: 'appointment-link-setup',
    subPages: [
      { component: BusinessAboutAndHeaderImage, key: 'headerImage', name: 'Upload cover image' },
      { component: SocialLinks, key: 'social', name: 'Add Socials' },
      { component: AppointmentLink, key: 'appointmentLink', name: 'Setup Bookings Page' },
      ...(country?.collectionsSupported === true && country?.payoutsSupported === true
      ? [
          { component: Bank, key: 'bankSetup', name: 'Bank Setup' },
          { component: BankTransfers, key: 'bankTransferSetup', name: 'Bank Transfers' },
          { component: Pos, key: 'posSetup', name: 'POS' },
        ]
      : []),
    ]
  },
]

export const useAccountSetupNav = () => {
  const initialPageValues = React.useMemo(() => {
    const storedPageKey = localStorage.getItem(PAGE_KEYS.CURRENT_PAGE);
    const storedSubPageKey = localStorage.getItem(PAGE_KEYS.CURRENT_SUB_PAGE);
    const currentPage = storedPageKey ? parseInt(storedPageKey) : 0;
    const currentSubPage = storedSubPageKey ? parseInt(storedSubPageKey) :
      pages[currentPage].subPages.length > 0 ? 0 : -1;
    return { currentPage, currentSubPage };
  }, [])

  const [currentPage, setCurrentPage] = React.useState(initialPageValues.currentPage);
  const [currentSubPage, setCurrentSubPage] = React.useState(initialPageValues.currentSubPage);

  const currentSubPageComp = React.useMemo(() => {
    return pages[currentPage].subPages[currentSubPage];
  }, [currentPage, currentSubPage]);

  const setCurrentPageKey = (key: number) => {
    localStorage.setItem(PAGE_KEYS.CURRENT_PAGE, key.toString());
    setCurrentPage(key);
  };

  const setCurrentSubPageKey = (key: number) => {
    localStorage.setItem(PAGE_KEYS.CURRENT_SUB_PAGE, key.toString());
    setCurrentSubPage(key);
  };

  const getCurrentPageKey = () => {
    const key = localStorage.getItem(PAGE_KEYS.CURRENT_PAGE);
    if (key) {
      setCurrentPage(parseInt(key));
    }
    return key;
  };

  const getCurrentSubPageKey = () => {
    const key = localStorage.getItem(PAGE_KEYS.CURRENT_SUB_PAGE);
    if (key) {
      setCurrentSubPage(parseInt(key));
    }
    return key;
  };

  const getCurrentPage = () => {
    return pages[currentPage];
  };

  const getCurrentSubPage = () => {
    return pages[currentPage].subPages[currentSubPage];
  };

  const moveToSpecificPage = (key: number) => {
    setCurrentPageKey(key);
  }

  const moveToSpecificSubPage = (key: number) => {
    setCurrentSubPageKey(key);
  }

  // get next page
  const getNextSubPageOrPage = () => {
    const next = currentSubPage + 1;
    if (currentPage === pages.length - 1 && next >= pages[currentPage].subPages.length) {
      // We are on the last page and last sub-page, navigate to home
      // TODO: Navigate to home
    } else if (next >= pages[currentPage].subPages.length) {
      // We are at the end of the current page, navigate to the first sub-page of the next page
      setCurrentSubPageKey(0);
      setCurrentPageKey(currentPage + 1);
    } else {
      // Navigate to the next sub-page within the current page
      setCurrentSubPageKey(next);
      setCurrentPageKey(currentPage);
    }
  }

  const getPrevSubPageOrPage = () => {
    const prev = currentSubPage - 1;

    if (currentPage === 0 && prev < 0) {
      // We are on the first page and first sub-page, navigate to a different page or perform any necessary action
      // TODO: Navigate to a different page or perform an action
    } else if (prev < 0) {
      // We are at the beginning of the current page, navigate to the last sub-page of the previous page
      setCurrentPageKey(currentPage - 1);
      const prevPage = pages[currentPage - 1];
      setCurrentSubPageKey(prevPage.subPages.length - 1);
    } else {
      // Navigate to the previous sub-page within the current page
      setCurrentSubPageKey(prev);
      setCurrentPageKey(currentPage);
    }
  }

  const moveToNextPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage >= pages.length) {
      // We are on the last page, navigate to home
    }
    setCurrentPageKey(nextPage);
    setCurrentSubPageKey(0);
  }

  const deleteAccountSetupNav = () => {
    localStorage.removeItem(PAGE_KEYS.CURRENT_PAGE);
    localStorage.removeItem(PAGE_KEYS.CURRENT_SUB_PAGE);
  }

  return {
    setCurrentPageKey,
    setCurrentSubPageKey,
    getCurrentPageKey,
    getCurrentSubPageKey,
    pages,
    getCurrentPage,
    currentSubPageComp,
    getNextSubPageOrPage,
    getPrevSubPageOrPage,
    initialPageValues,
    deleteAccountSetupNav,
    moveToNextPage,
    moveToSpecificPage,
    moveToSpecificSubPage,
    getCurrentSubPage
  };
}
