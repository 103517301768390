import React, { useContext, useEffect, useState } from 'react'
import BookingDetailsCard from './BookingDetailsCard';
import BookingBreadcrumb from './BookingBreadcrumb';
import Paragraph from '../../ui/atoms/paragraph/Paragraph';
import { COLORS } from '../../constants/colors';
import Button from '../../ui/atoms/button/Button';
import BookContext from './context';
import BookingSteps from './BookingSteps';
import { SvgGreyMinus, SvgGreyPlus } from '../../ui/icons';
import { Checkbox } from '../../ui';
import { STAFF_SELECTION, TIME_AND_DATE } from './constants';

const SelectVoucherService = () => {
  const { voucherServices, setVoucherServices, voucherToBeRedeemed, handleNextStep, businessInfo } = useContext(BookContext);
  
  const [canGoNext, setCanGoNext] = useState(false)

  const modifyServiceInCart = (serviceId: string, type: 'add' | 'remove') => {
    const newVoucherServices = voucherServices.map((service) => {
      const qty = service?.cartQuantity || service?.quantity - (service?.redeemedCount || 0);
      if (service.serviceId === serviceId) {
        if (type === 'add') {
          service.cartQuantity = qty + 1;
        } else if (type === 'remove' && qty !== 0) {
          service.cartQuantity = qty - 1;
        }
      }
      return service;
    });
    setVoucherServices(newVoucherServices);
  }

  const chooseService = (serviceId: string) => {
    const newVoucherServices = voucherServices.map((service) => {
      if (service.serviceId === serviceId) {
        service.selected = !service.selected;
      }
      return service;
    });
    setVoucherServices(newVoucherServices);
  }

  useEffect(() => {
    let canGo = false;
    voucherServices.forEach((service) => {
      if (service?.selected) {
        canGo = true;
      }
    });
    setCanGoNext(canGo);
  }, [voucherServices])

  const handleNext = () => {
    if (businessInfo?.allowServiceStaffSelection) {
      handleNextStep(STAFF_SELECTION);
      return;
    }
    handleNextStep(TIME_AND_DATE);
  }

  return (
    <>
      <div className='w-full flex flex-col px-[10px] xl:px-[80px]'>
        <BookingBreadcrumb />
        <div className='w-full flex flex-col xl:flex-row gap-4'>
          {/* Card */}
          <BookingDetailsCard />
          {/* Cart Details */}
          {voucherToBeRedeemed && Array.isArray(voucherServices) && voucherServices?.length > 0 ? (
            <div className='w-full h-fit flex flex-col xl:max-w-[40%] border border-grey-50 rounded-xl py-8 px-6 gap-8'>
              <div className='w-full flex justify-between'>
                <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>Select services to redeem</Paragraph>
                <BookingSteps page={"voucher-services"} />
              </div>

              <Paragraph size="b5" color={COLORS.GREY[400]}>Choose the services you want to redeem</Paragraph>

              <div className='w-full flex flex-col space-y-6'>
                {Array.isArray(voucherServices) && voucherServices.map((voucherService, index) => {
                  const quantity = voucherService?.cartQuantity || 1;
                  const orginalQty = voucherService?.quantity - (voucherService?.redeemedCount || 0)
                  return (
                    <div className='w-full flex justify-between' key={index}>
                      <div className='flex items-center space-x-4 cursor-pointer' onClick={!voucherService?.isRedeemed ? () => chooseService(voucherService?.serviceId) : undefined}>
                        <Checkbox isChecked={voucherService?.selected} disabled={voucherService?.isRedeemed} />
                        <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{voucherService?.name}</Paragraph>
                      </div>
                      {!voucherService?.isRedeemed ? (
                        <div className="w-fit flex space-x-2 items-end">
                          <div className="w-full flex gap-2">
                            <Button
                              variant='icon'
                              size="none"
                              type="button"
                              className="border-0"
                              onClick={() => modifyServiceInCart(voucherService.serviceId, 'add')}
                              disabled={orginalQty === quantity}
                            >
                              <SvgGreyPlus width="22px" height="22px" />
                            </Button>
                            <div className="flex border border-grey-100 py-1 px-3 justify-center h-[22px] items-center">
                              <Paragraph size='b6' weight='normal' color={COLORS.BLACK}>{quantity}</Paragraph>
                            </div>
                            <Button
                              variant='icon'
                              size="none"
                              type="button"
                              className="border-0"
                              onClick={() => modifyServiceInCart(voucherService.serviceId, 'remove')}
                              disabled={quantity === 1}
                            >
                              <SvgGreyMinus width="22px" height="22px" />
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )
                })}
              </div>

              <Button
                variant='primary'
                className=''
                size='lg'
                rounded='lg'
                disabled={!canGoNext}
                type='button'
                onClick={handleNext}
              >
                Continue
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default SelectVoucherService
