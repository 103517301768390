import React, { useEffect, useState } from 'react'
import BvnFormComponent from './bvn/BvnForm'
import BvnVerification from './bvn/BvnVerification'
import { usePaymentSettings } from '../../../hooks/usePaymentSettings'
import { PAYMENT_FORM_STEPS } from '../constants'
import { PageProps } from '../types'

const Bvn = ({ onNextPage, onPrevPage, business }: PageProps) => {
  const [step, setStep] = useState(PAYMENT_FORM_STEPS.BVN_FORM)

  const { getBvnRecipient } = usePaymentSettings()
  const bvnRecipient = getBvnRecipient()

  useEffect(() => {
    if (!bvnRecipient) {
      setStep(PAYMENT_FORM_STEPS.BVN_FORM)
    } else {
      setStep(PAYMENT_FORM_STEPS.BVN_VERIFICATION)
    }
  }, [bvnRecipient])
    
  return (
    step === PAYMENT_FORM_STEPS.BVN_VERIFICATION
      ? <BvnVerification onNextPage={onNextPage} onPrevPage={onPrevPage} business={business} setStep={setStep} />
      : <BvnFormComponent onNextPage={onNextPage} onPrevPage={onPrevPage} business={business} setStep={setStep} />
  )
}

export default Bvn