/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useContext, useEffect, useState } from 'react'
import BookingBreadcrumb from './BookingBreadcrumb';
import BookingDetailsCard from './BookingDetailsCard';
import { Button, Heading, Modal, Paragraph } from '../../ui';
import BookingSteps from './BookingSteps';
import { COLORS } from '../../constants/colors';
import BookContext from './context';
import { TIME_AND_DATE } from './constants';
import { Listbox, Transition } from '@headlessui/react';
import { SvgArrowLeft, SvgArrowRight, SvgClose, SvgDropDown, SvgUser } from '../../ui/icons';
import { formatInToPrice, formatTextIntoCaps, getHoursAndMinutesString } from '../../utils/misc';
import axios from 'axios';
import { print } from 'graphql';
import { SalonStaff, ServiceStaff, ServiceStaffsDocument } from '../../graphql/generated';
import { BookingService } from './types';
import { findCommonStaff } from './utils';

const CheckoutStaffSelection = () => {
  const { addToast, selectedServices, voucherServices, setVoucherServices, services, voucherToBeRedeemed, handleNextStep, setSelectedServices, selectedSalon, staffOption, setStaffOption, isMobile, businessInfo } = useContext(BookContext);
  const [selectedStaff, setSelectedStaff] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isStaffOptionOpen, setIsStaffOptionOpen] = useState(false)
  const [currentServiceStaffOption, setCurrentServiceStaffOption] = useState(null)
  const [serviceStaff, setServiceStaff] = useState<ServiceStaff | null>(null)
  const [serviceStaffList, setServiceStaffList] = useState<ServiceStaff[]>([])
  const [goToNext, setGoToNext] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVoucherServiceWithStaffOptions, setSelectedVoucherServicesWithStaffOptions] = useState<BookingService[]>([])
  const STAFF_SELECTION_LIST_OPTIONS = [
    {
      label: "Select any professional - no preference",
      value: "any"
    },
    // Select a professional per service
    {
      label: "Select a professional per service",
      value: "per-service"
    },
    // Select professional that can do all services
    {
      label: "Select professional that can do all services",
      value: "all-services"
    },
  ]

  const getStaffSelectionOption = () => {
    if (staffOption === null) {
      return ''
    }

    return STAFF_SELECTION_LIST_OPTIONS.find(option => option.value === staffOption)?.label
  }

  const cancelOption = () => {
    setStaffOption(null)
    setIsOpen(false)
  }

  const continueToNextStep = () => {
    handleNextStep(TIME_AND_DATE)
  }

  useEffect(() => {
    if (Array?.isArray(voucherServices) && voucherServices?.length && voucherToBeRedeemed && Array?.isArray(services) && services?.length) {
      // Filter the voucherServices to only include services that are in the services array
      const voucherServicesInServices = services.filter(service => voucherServices.some(voucher => voucher?.selected && voucher?.serviceId === service?.id));

      setSelectedVoucherServicesWithStaffOptions(voucherServicesInServices)
    }
    if (staffOption !== null) {
      setGoToNext(true)
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeStaffSelection = (serviceId: string, index: number) => (selectedServiceStaff: any) => {
    // arrange setSelectedStaff based of index, adding selectedServiceStaff, using index sent
    const newSelectedStaff = [...selectedStaff]
    newSelectedStaff[index] = selectedServiceStaff
    setSelectedStaff(newSelectedStaff)

    // search selected service base serviceId, and add the staffId to selectedServiceStaff?.salonStaff?.id, then update the selectedServices
    const selectedService = selectedServices?.find(service => service?.id === serviceId)
    if (selectedService) {
      // Clone the selectedService to avoid mutating the original object
      const updatedSelectedService = { ...selectedService };

      // Add the staffId to the selectedService
      updatedSelectedService.staff = selectedServiceStaff;
      
      const selectedServicesStaffRecord = selectedService?.serviceStaffs?.find(service => service?.salonStaff?.id === selectedServiceStaff?.id);

      if (selectedServicesStaffRecord) {
        updatedSelectedService.staffPrice = selectedServicesStaffRecord?.price;
      }

      // Replace the selectedService in the selectedServices array
      const updatedSelectedServices = selectedServices.map(service => {
        // If this is the selectedService, return the updatedSelectedService
        if (service.id === selectedService.id) {
            return updatedSelectedService;
        }
        // Otherwise, return the original service
        return service;
      });

      setSelectedServices(updatedSelectedServices)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeStaffSelectionForVoucher = (serviceId: string, index: number) => (selectedServiceStaff: any) => {
    // arrange setSelectedStaff based of index, adding selectedServiceStaff, using index sent
    const newSelectedStaff = [...selectedStaff]
    newSelectedStaff[index] = selectedServiceStaff
    setSelectedStaff(newSelectedStaff)

    // search selected service base serviceId, and add the staffId to selectedServiceStaff?.salonStaff?.id, then update the selectedServices
    const selectedService = selectedVoucherServiceWithStaffOptions?.find(service => service?.id === serviceId)
    if (selectedService) {
      // Clone the selectedService to avoid mutating the original object
      const updatedSelectedService = { ...selectedService };

      // Add the staffId to the selectedService
      updatedSelectedService.staff = selectedServiceStaff;

      // Replace the selectedService in the selectedServices array
      const updatedSelectedServices = selectedVoucherServiceWithStaffOptions.map(service => {
        // If this is the selectedService, return the updatedSelectedService
        if (service.id === selectedService.id) {
            return updatedSelectedService;
        }
        // Otherwise, return the original service
        return service;
      });
      setSelectedVoucherServicesWithStaffOptions(updatedSelectedServices)
    }

    // do same for voucher services
    const voucherService = voucherServices?.find(service => service?.serviceId === serviceId)
    if (voucherService) {
      // Clone the selectedService to avoid mutating the original object
      const updatedVoucherService = { ...voucherService };

      // Add the staffId to the selectedService
      updatedVoucherService.staff = selectedServiceStaff;

      // Replace the selectedService in the selectedServices array
      const updatedVoucherServices = voucherServices.map(voucher => {
        // If this is the selectedService, return the updatedSelectedService
        if (voucher.serviceId === voucherService.serviceId) {
          return updatedVoucherService;
        }
        // Otherwise, return the original service
        return voucher;
      });
      
      setVoucherServices(updatedVoucherServices)
    }
  }

  const makeServicesOrVoucherServiceStaffToNull = () => {
    if (Array?.isArray(selectedServices) && selectedServices?.length) {
      // remove staff and staffPrice in selectedServices
      const updatedSelectedServices = selectedServices?.map(service => {
        // Clone the selectedService to avoid mutating the original object
        const updatedSelectedService = { ...service };

        // Remove the staffId from the selectedService
        updatedSelectedService.staff = null;
        updatedSelectedService.staffPrice = null;
        updatedSelectedService.staffIds = null;

        // Replace the selectedService in the selectedServices array
        return updatedSelectedService;
      });
      setSelectedServices(updatedSelectedServices)
    }

    // do same for voucher services
    if (Array?.isArray(selectedVoucherServiceWithStaffOptions) && selectedVoucherServiceWithStaffOptions?.length) {
      const updatedVoucherServiceWithStaffOptions = selectedVoucherServiceWithStaffOptions?.map(service => {
        // Clone the selectedService to avoid mutating the original object
        const updatedSelectedService = { ...service };

        // Add the staffId to the selectedService
        updatedSelectedService.staff = null;
        updatedSelectedService.staffIds = null;

        // Replace the selectedService in the selectedServices array
        return updatedSelectedService;
      });

      setSelectedVoucherServicesWithStaffOptions(updatedVoucherServiceWithStaffOptions)
    }

    if (Array?.isArray(voucherServices) && voucherServices?.length) {
      // remove staff and staffPrice in selectedServices
      const updatedVoucherServices = voucherServices?.map(service => {
        // Clone the selectedService to avoid mutating the original object
        const updatedVoucherService = { ...service };

        // Remove the staffId from the selectedService
        updatedVoucherService.staff = null;

        return updatedVoucherService;
      });
      setVoucherServices(updatedVoucherServices)
    }
  }

  const makeServicesOrVoucherServiceToAllStaffList = () => {
    if (Array?.isArray(selectedServices) && selectedServices?.length) {
      // remove staff and staffPrice in selectedServices
      const updatedSelectedServices = selectedServices?.map(service => {
        // Clone the selectedService to avoid mutating the original object
        const updatedSelectedService = { ...service };

        // Remove the staffId from the selectedService
        updatedSelectedService.staff = null;
        updatedSelectedService.staffPrice = null;

        // check if service has service staffs
        if (Array?.isArray(service?.serviceStaffs) && service?.serviceStaffs?.length) {
          // get first staff from service staffs
          const staffIds = service?.serviceStaffs?.map(staff => staff?.salonStaff?.id)
          updatedSelectedService.staffIds = staffIds
        }

        // Replace the selectedService in the selectedServices array
        return updatedSelectedService;
      });
      setSelectedServices(updatedSelectedServices)
    }

    // do same for voucher services
    if (Array?.isArray(selectedVoucherServiceWithStaffOptions) && selectedVoucherServiceWithStaffOptions?.length) {
      const updatedVoucherServiceWithStaffOptions = selectedVoucherServiceWithStaffOptions?.map(service => {
        // Clone the selectedService to avoid mutating the original object
        const updatedSelectedService = { ...service };

        // Add the staffId to the selectedService
        updatedSelectedService.staff = null;

        // check if service has service staffs
        if (Array?.isArray(service?.serviceStaffs) && service?.serviceStaffs?.length) {
          // get first staff from service staffs
          const staffIds = service?.serviceStaffs?.map(staff => staff?.salonStaff?.id)
          updatedSelectedService.staffIds = staffIds
        }

        // Replace the selectedService in the selectedServices array
        return updatedSelectedService;
      });

      setSelectedVoucherServicesWithStaffOptions(updatedVoucherServiceWithStaffOptions)
    }

    if (Array?.isArray(voucherServices) && voucherServices?.length) {
      // remove staff and staffPrice in selectedServices
      const updatedVoucherServices = voucherServices?.map(service => {
        // Clone the selectedService to avoid mutating the original object
        const updatedVoucherService = { ...service };

        // Remove the staffId from the selectedService
        updatedVoucherService.staff = null;

        return updatedVoucherService;
      });
      setVoucherServices(updatedVoucherServices)
    }
  }

  useEffect(() => {
    if (staffOption && staffOption === "any") {
      makeServicesOrVoucherServiceToAllStaffList()
      setGoToNext(true)
    }

    if (staffOption && staffOption === "per-service") {
      makeServicesOrVoucherServiceStaffToNull()
    }

    if (staffOption && staffOption === "all-services") {
      getStaffListForAllServicesOption()
    }
  }, [staffOption])

  useEffect(() => {
    if (!voucherToBeRedeemed && Array?.isArray(selectedStaff) && selectedStaff?.length && staffOption === 'per-service' && selectedServices?.length) {
      if (selectedStaff?.length === selectedServices?.length) {
        setGoToNext(true)
      }
    }

    if (voucherToBeRedeemed && Array?.isArray(selectedStaff) && selectedStaff?.length && staffOption === 'per-service' && selectedVoucherServiceWithStaffOptions?.length) {
      if (selectedStaff?.length === selectedVoucherServiceWithStaffOptions?.length) {
        setGoToNext(true)
      }
    }
  }, [selectedStaff])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeAllServicesStaffSelection = (staff: any) => {
    setServiceStaff(staff)
    if (Array?.isArray(selectedServices) && selectedServices?.length) {
      // update services for staff
      const updatedSelectedServices = selectedServices?.map(service => {
        // Clone the selectedService to avoid mutating the original object
        const updatedSelectedService = { ...service };

        // Add the staffId to the selectedService
        updatedSelectedService.staff = staff?.salonStaff;

        // Replace the selectedService in the selectedServices array
        return updatedSelectedService;
      });

      setSelectedServices(updatedSelectedServices)
    }

    // voucher services
    if (Array?.isArray(selectedVoucherServiceWithStaffOptions) && selectedVoucherServiceWithStaffOptions?.length) {
      const updatedVoucherServiceWithStaffOptions = selectedVoucherServiceWithStaffOptions?.map(service => {
        // Clone the selectedService to avoid mutating the original object
        const updatedSelectedService = { ...service };

        // Add the staffId to the selectedService
        updatedSelectedService.staff = staff?.salonStaff;

        // Replace the selectedService in the selectedServices array
        return updatedSelectedService;
      });

      setSelectedVoucherServicesWithStaffOptions(updatedVoucherServiceWithStaffOptions)
    }

    if (Array?.isArray(voucherServices) && voucherServices?.length) {
      const updatedVoucherService = voucherServices?.map(service => {
        // Clone the selectedService to avoid mutating the original object
        const updatedSelectedService = { ...service };

        // Add the staffId to the selectedService
        updatedSelectedService.staff = updatedSelectedService?.selected ? staff?.salonStaff : null;

      // Replace the selectedService in the selectedServices array
        return updatedSelectedService;
      });

      setVoucherServices(updatedVoucherService)
    }

    setGoToNext(true)
  }

  const getStaffListForAllServicesOption = () => {
    setIsLoading(true)
    axios
      .post(
        '/graphql',
        {
          query: print(ServiceStaffsDocument),
          variables: {
            serviceIds: voucherToBeRedeemed ? selectedVoucherServiceWithStaffOptions?.map(service => service?.id) : selectedServices?.map(service => service?.id)
          }
        },
      )
      .then((res) => {
        setIsLoading(false)
        const {
          data: {
            data: { serviceStaffs }
          }
        } = res

        if (serviceStaffs) {
          const serviceStaffListMembers = findCommonStaff(serviceStaffs);
          setServiceStaffList(serviceStaffListMembers);
        } else {
          addToast({
            message: "Failed to get staff",
            variant: 'error'
          })
        }
      }).catch((err) => {
        setIsLoading(false)
        addToast({
          message: err[0]?.meessage || "Failed to get staff",
          variant: 'error'
        })
      })
  }

  const getStaffListOptions = (serviceId?: string) => {
    if (!voucherToBeRedeemed && staffOption === "per-service" && Array?.isArray(selectedServices)) {
      return selectedServices?.map((service, index) => {
        const staffList = service?.serviceStaffs?.length ? service?.serviceStaffs?.map(obj => obj.salonStaff) : selectedSalon?.salonStaffs;

        return (
          <div className='w-full flex flex-col space-y-2' key={index}>
            <Heading variant='h2' size='b5' weight='bold' color={COLORS.GREY[300]}>{service?.name} x{service?.quantity}</Heading>
  
            <div className='w-full flex justify-between items-center space-y-2 p-3 bg-white border border-grey-50 rounded-lg'>
              <Listbox value={selectedStaff} onChange={onChangeStaffSelection(service?.id, index)}>
                <div className="relative mt-1 w-full">
                  <div className="flex flex-col w-full">
                    <Listbox.Button
                      className="relative w-full cursor-pointer bg-transparent flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-b6"
                    >
                      
                      <div className='w-full flex space-x-2'>
                        {service?.staff ? (
                          <div className='w-full flex space-x-3'>
                            <img
                              src="https://res.cloudinary.com/dqqrgidob/image/upload/v1711326670/ebloupo48mkxqwn6nm5g.png"
                              alt="staff image"
                              className='w-[32px] h-[32px] rounded-sm'
                            />
                            <div className='w-full flex flex-col'>
                              <span className='text-left text-grey-300 text-b6'>{service?.staff?.user?.fullName}</span>
                              <span className='text-left text-grey-900 text-b6'>{formatTextIntoCaps(service?.staff?.specialty)}</span>
                            </div>
                          </div>
                        ) : (
                          <div className='flex flex-col justify-start space-y-1'>
                            <span className='text-grey-400 text-b6'>Choose</span>
                          </div>    
                        )}
                        <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center'>
                          <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                        </span>
                      </div>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        className="absolute max-h-60 w-full min-w-[100px] overflow-auto rounded-sm bg-white py-2 text-b4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-b6 z-[90]"
                        style={{
                          top: 50,
                          bottom: 'auto',
                        }}
                      >
                        {Array?.isArray(staffList) && staffList.map((staff, viewIdx) => {
                          return (
                            <Listbox.Option
                              key={viewIdx}
                              className={({ active }) =>
                                `relative cursor-pointer select-none p-2 ${active ? 'bg-grey-900 text-white' : 'text-grey-900'
                                }`
                              }
                              value={staff}
                            >
                              {({ selected }) => (
                                <div className='w-full flex space-x-2'>
                                  <img
                                    src="https://res.cloudinary.com/dqqrgidob/image/upload/v1711326670/ebloupo48mkxqwn6nm5g.png"
                                    alt="staff image"
                                    className='w-[32px] h-[32px] rounded-sm'
                                  />
                                  <div className='flex flex-col'>
                                    <span
                                      className='text-b5 block truncate font-medium'
                                    >
                                      {staff?.user?.fullName}
                                    </span>
                                    <span className='text-b6 block font-normal text-grey-400'>
                                      {formatTextIntoCaps(staff?.specialty || "")}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Listbox.Option>
                          )
                        })}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </div>
              </Listbox>
            </div>
          </div>
        )
      })
    }

    if (voucherToBeRedeemed && staffOption === "per-service" && Array?.isArray(selectedVoucherServiceWithStaffOptions)) {
      return selectedVoucherServiceWithStaffOptions?.map((service, index) => {
        const staffList = service?.serviceStaffs?.length ? service?.serviceStaffs?.map(obj => obj.salonStaff) : selectedSalon?.salonStaffs;
        return (
          <div className='w-full flex flex-col space-y-2' key={index}>
            <Heading variant='h2' size='b5' weight='bold' color={COLORS.GREY[300]}>{service?.name}</Heading>
  
            <div className='w-full flex justify-between items-center space-y-2 p-3 bg-white border border-grey-50 rounded-lg'>
              <Listbox value={selectedStaff} onChange={onChangeStaffSelectionForVoucher(service?.id, index)}>
                <div className="relative mt-1 w-full">
                  <div className="flex flex-col w-full">
                    <Listbox.Button
                      className="relative w-full cursor-pointer bg-transparent flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-b6"
                    >
                      
                      <div className='w-full flex space-x-2'>
                        {service?.staff ? (
                          <div className='w-full flex space-x-3'>
                            <img
                              src="https://res.cloudinary.com/dqqrgidob/image/upload/v1711326670/ebloupo48mkxqwn6nm5g.png"
                              alt="staff image"
                              className='w-[32px] h-[32px] rounded-sm'
                            />
                            <div className='w-full flex flex-col'>
                              <span className='text-left text-grey-300 text-b6'>{service?.staff?.user?.fullName}</span>
                              <span className='text-left text-grey-900 text-b6'>{formatTextIntoCaps(service?.staff?.specialty)}</span>
                            </div>
                          </div>
                        ) : (
                          <div className='flex flex-col justify-start space-y-1'>
                            <span className='text-grey-400 text-b6'>Choose</span>
                          </div>    
                        )}
                        <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center'>
                          <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                        </span>
                      </div>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        className="absolute max-h-60 w-full min-w-[100px] overflow-auto rounded-sm bg-white py-2 text-b4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-b6 z-[90]"
                        style={{
                          top: 50,
                          bottom: 'auto',
                        }}
                      >
                        {Array?.isArray(staffList) && staffList.map((staff, viewIdx) => {
                          return (
                            <Listbox.Option
                              key={viewIdx}
                              className={({ active }) =>
                                `relative cursor-pointer select-none p-2 ${active ? 'bg-grey-900 text-white' : 'text-grey-900'
                                }`
                              }
                              value={staff}
                            >
                              {({ selected }) => (
                                <div className='w-full flex space-x-2'>
                                  <img
                                    src="https://res.cloudinary.com/dqqrgidob/image/upload/v1711326670/ebloupo48mkxqwn6nm5g.png"
                                    alt="staff image"
                                    className='w-[32px] h-[32px] rounded-sm'
                                  />
                                  <div className='flex flex-col'>
                                    <span
                                      className='text-b5 block truncate font-medium'
                                    >
                                      {staff?.user?.fullName}
                                    </span>
                                    <span className='text-b6 block font-normal text-grey-400'>
                                      {formatTextIntoCaps(staff?.specialty || "")}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Listbox.Option>
                          )
                        })}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </div>
              </Listbox>
            </div>
          </div>
        )
      })
    }

    if (staffOption === "all-services" && (Array?.isArray(selectedServices) && selectedServices?.length || Array?.isArray(selectedVoucherServiceWithStaffOptions) && selectedVoucherServiceWithStaffOptions?.length)) {
      return (
        <div className='w-full flex flex-col space-y-2'>
          <div className='w-full flex justify-between items-center space-y-2 p-3 bg-white border border-grey-50 rounded-lg'>
            <Listbox value={serviceStaff} onChange={onChangeAllServicesStaffSelection}>
              <div className="relative mt-1 w-full">
                <div className="flex flex-col w-full">
                  <Listbox.Button
                    className="relative w-full cursor-pointer bg-transparent flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-b6"
                  >
                      
                    <div className='w-full flex space-x-2'>
                      {serviceStaff ? (
                        <div className='w-full flex space-x-3'>
                          <img
                            src="https://res.cloudinary.com/dqqrgidob/image/upload/v1711326670/ebloupo48mkxqwn6nm5g.png"
                            alt="staff image"
                            className='w-[32px] h-[32px] rounded-sm'
                          />
                          <div className='w-full flex flex-col'>
                            <span className='text-left text-grey-300 text-b6'>{serviceStaff?.salonStaff?.user?.fullName}</span>
                            <span className='text-left text-grey-900 text-b6'>{formatTextIntoCaps(serviceStaff?.salonStaff?.specialty)}</span>
                          </div>
                        </div>
                      ) : (
                        <div className='flex flex-col justify-start space-y-1'>
                          <span className='text-grey-400 text-b6'>Choose</span>
                        </div>
                      )}
                      <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center'>
                        <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                      </span>
                    </div>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      className="absolute max-h-60 w-full min-w-[100px] overflow-auto rounded-sm bg-white py-2 text-b4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-b6 z-[90]"
                      style={{
                        top: 50,
                        bottom: 'auto',
                      }}
                    >
                      {Array?.isArray(serviceStaffList) && serviceStaffList.map((staff, viewIdx) => {
                        return (
                          <Listbox.Option
                            key={viewIdx}
                            className={({ active }) =>
                              `relative cursor-pointer select-none p-2 ${active ? 'bg-grey-900 text-white' : 'text-grey-900'
                              }`
                            }
                            value={staff}
                          >
                            {({ selected }) => (
                              <div className='w-full flex space-x-2'>
                                <img
                                  src="https://res.cloudinary.com/dqqrgidob/image/upload/v1711326670/ebloupo48mkxqwn6nm5g.png"
                                  alt="staff image"
                                  className='w-[32px] h-[32px] rounded-sm'
                                />
                                <div className='flex flex-col'>
                                  <span
                                    className='text-b5 block truncate font-medium'
                                  >
                                    {staff?.salonStaff?.user?.fullName}
                                  </span>
                                  <span className='text-b6 block font-normal text-grey-400'>
                                    {formatTextIntoCaps(staff?.salonStaff?.specialty)}
                                  </span>
                                </div>
                              </div>
                            )}
                          </Listbox.Option>
                        )
                      })}
                    </Listbox.Options>
                  </Transition>
                </div>
              </div>
            </Listbox>
          </div>
        </div>
      )
    }

    if (!voucherToBeRedeemed && staffOption === 'per-service' && Array?.isArray(selectedServices)) {
      const service = selectedServices?.find((selectedService) => selectedService?.id === serviceId);
      // find service index
      const index = selectedServices?.findIndex((selectedService) => selectedService?.id === serviceId);
      const staffList = service?.serviceStaffs?.length ? service?.serviceStaffs?.map(obj => obj.salonStaff) : selectedSalon?.salonStaffs;

      return (
        <div className='w-full flex flex-col space-y-2'>
          <div className='w-full flex justify-between items-center space-y-2 p-3 bg-white border border-grey-50 rounded-lg'>
            <Listbox value={selectedStaff} onChange={onChangeStaffSelection(service?.id, index)}>
              <div className="relative mt-1 w-full">
                <div className="flex flex-col w-full">
                  <Listbox.Button
                    className="relative w-full cursor-pointer bg-transparent flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-b6"
                  >
                    
                    <div className='w-full flex space-x-2'>
                      {service?.staff ? (
                        <div className='w-full flex space-x-3'>
                          <img
                            src="https://res.cloudinary.com/dqqrgidob/image/upload/v1711326670/ebloupo48mkxqwn6nm5g.png"
                            alt="staff image"
                            className='w-[32px] h-[32px] rounded-sm'
                          />
                          <div className='w-full flex flex-col'>
                            <span className='text-left text-grey-300 text-b6'>{service?.staff?.user?.fullName}</span>
                            <span className='text-left text-grey-900 text-b6'>{formatTextIntoCaps(service?.staff?.specialty)}</span>
                          </div>
                        </div>
                      ) : (
                        <div className='flex flex-col justify-start space-y-1'>
                          <span className='text-grey-400 text-b6'>Choose</span>
                        </div>    
                      )}
                      <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center'>
                        <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                      </span>
                    </div>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      className="absolute max-h-60 w-full min-w-[100px] overflow-auto rounded-sm bg-white py-2 text-b4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-b6 z-[90]"
                      style={{
                        top: 50,
                        bottom: 'auto',
                      }}
                    >
                      {Array?.isArray(staffList) && staffList.map((staff, viewIdx) => {
                        return (
                          <Listbox.Option
                            key={viewIdx}
                            className={({ active }) =>
                              `relative cursor-pointer select-none p-2 ${active ? 'bg-grey-900 text-white' : 'text-grey-900'
                              }`
                            }
                            value={staff}
                          >
                            {({ selected }) => (
                              <div className='w-full flex space-x-2'>
                                <img
                                  src="https://res.cloudinary.com/dqqrgidob/image/upload/v1711326670/ebloupo48mkxqwn6nm5g.png"
                                  alt="staff image"
                                  className='w-[32px] h-[32px] rounded-sm'
                                />
                                <div className='flex flex-col'>
                                  <span
                                    className='text-b5 block truncate font-medium'
                                  >
                                    {staff?.user?.fullName}
                                  </span>
                                  <span className='text-b6 block font-normal text-grey-400'>
                                    {formatTextIntoCaps(staff?.specialty || "")}
                                  </span>
                                </div>
                              </div>
                            )}
                          </Listbox.Option>
                        )
                      })}
                    </Listbox.Options>
                  </Transition>
                </div>
              </div>
            </Listbox>
          </div>
        </div>
      )
    }
  }

  const openStaffOption = (service) => {
    setIsStaffOptionOpen(true)
    setCurrentServiceStaffOption(service)
  }

  const closeStaffOption = () => {
    setIsStaffOptionOpen(false)
    setCurrentServiceStaffOption(null)
  }

  const checkIfServiceNowHasStaff = () => {
    if (!voucherToBeRedeemed) {
      const service = selectedServices?.find((selectedService) => selectedService?.id === currentServiceStaffOption?.id);
      if (service?.staff) {
        return false
      }
      return true
    }
  }

  return (
    <>
      <div className='w-full flex flex-col px-[10px] xl:px-[80px] pb-[70px]'>
        <BookingBreadcrumb />
        <div className='w-full flex flex-col-reverse xl:flex-row gap-5'>
          {/* Card */}
          {!isMobile ? <BookingDetailsCard /> : null}
          {/* Cart Details */}
          {Array.isArray(selectedServices) && selectedServices?.length > 0 || voucherToBeRedeemed && Array.isArray(voucherServices) && voucherServices?.length > 0 ? (
            <>
              <div className='w-full h-fit hidden lg:flex flex-col xl:max-w-[40%] border border-grey-50 rounded-xl py-8 px-6 gap-8'>
                <div className='w-full flex justify-between'>
                  <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>Select staff</Paragraph>
                  <BookingSteps page={"phone"} />
                </div>
                {Array?.isArray(STAFF_SELECTION_LIST_OPTIONS) && STAFF_SELECTION_LIST_OPTIONS.map((option, index) => (
                  <div className='w-full flex flex-col space-y-4' key={index}>
                    <div key={option.value} className='w-full cursor-pointer flex justify-between items-center gap-4' onClick={() => {
                      setStaffOption(option.value)
                    }}>
                      <Paragraph size='b4' weight='normal'>{option.label}</Paragraph>
                      <div className={`min-w-[20px] h-[20px] border border-grey-300 rounded-full flex justify-center items-center`}>
                        { option.value === staffOption ? (
                          <div className='min-w-[16px] h-[16px] bg-grey-300 rounded-full'></div>
                        ) : null}
                      </div>
                    </div>

                    {option.value === staffOption ? getStaffListOptions() : null}
                  </div>
                ))}
                <Button
                  variant='primary'
                  className=''
                  disabled={!goToNext}
                  size='lg'
                  rounded='lg'
                  type='button'
                  onClick={continueToNextStep}
                >
                  Continue
                </Button>
              </div>
              <div className='w-full flex lg:hidden flex-col gap-3 overflow-auto'>
                <div className='w-full flex justify-between py-4 border-y border-grey-50'>
                  <div className='flex flex-col'>
                    <Heading variant='h2' size='h8' weight='bold'>{businessInfo?.name}</Heading>
                    <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{selectedSalon?.address}, {selectedSalon?.city}, {selectedSalon?.state}</Paragraph>
                  </div>

                  {businessInfo?.logoUrl ? (
                    <img
                      src={businessInfo?.logoUrl}
                      alt="business logo"
                      className="w-full max-w-[80px]"
                      loading="lazy"
                    />
                  ) : null}
                </div>
                <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['300']}>SERVICES</Paragraph>
                {Array.isArray(selectedServices) && selectedServices.map((service) => {
                  const quantity = service?.quantity || 1;
                  return (
                    <div className='w-full flex justify-between items-center py-2' key={service?.id}>
                      <div className='w-full flex flex-col gap-2'>
                        <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{service?.name}</Paragraph>
                        <div className='w-full flex gap-2'>
                          <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN['900']}>{service?.staffPrice ? formatInToPrice(service?.staffPrice * quantity, businessInfo?.country?.currency?.symbol) : service?.pricingType === 'from' ? (<span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full mr-2'>from</span>) : null} {!service?.staffPrice ? formatInToPrice(service?.price * quantity, businessInfo?.country?.currency?.symbol) : null}</Paragraph> ·
                          <Paragraph size='b6' weight='normal' color={COLORS.BLACK}><span className='text-grey-300'>{getHoursAndMinutesString(service?.duration)}</span></Paragraph>
                        </div>
                      </div>
                      {staffOption && staffOption === 'per-service' ?
                        <div className='flex items-center py-2 px-4 rounded-full text-green-300 bg-green-300/10 cursor-pointer' onClick={() => openStaffOption(service)}>
                          <SvgUser width="16px" height="16px" />
                          {service?.staff ? (
                            <span className='text-green-300 text-b6 font-semibold'>{service?.staff?.user?.firstName}</span>
                          ) : (
                            <span className='text-green-300 text-b6 font-semibold'>Staff</span>
                          )}
                        </div> : null}
                    </div>
                  )
                })}
                {Array.isArray(voucherServices) && voucherServices.map((service) => {
                  const quantity = service?.quantity || 1;
                  return (
                    <div className='w-full flex justify-between items-center py-2' key={service?.id}>
                      <div className='w-full flex flex-col gap-2'>
                        <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{service?.name}</Paragraph>
                        <div className='w-full flex gap-2'>
                          <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN['900']}>{formatInToPrice(0, businessInfo?.country?.currency?.symbol)}</Paragraph> ·
                          <Paragraph size='b6' weight='normal' color={COLORS.BLACK}><span className='text-grey-300'>{getHoursAndMinutesString(service?.duration)}</span></Paragraph>
                        </div>
                      </div>
                      {staffOption && staffOption === 'per-service' ?
                        <div className='flex items-center py-2 px-4 rounded-full text-green-300 bg-green-300/10 cursor-pointer' onClick={() => openStaffOption(service)}>
                          <SvgUser width="16px" height="16px" />
                          {service?.staff ? (
                            <span className='text-green-300 text-b6 font-semibold'>{service?.staff?.user?.firstName}</span>
                          ) : (
                            <span className='text-green-300 text-b6 font-semibold'>Staff</span>
                          )}
                        </div> : null}
                    </div>
                  )
                })}
                <BookingDetailsCard showOnlyPrices={true} hidePromoApplication={true} />
              </div>
              <div className='w-full flex lg:hidden flex-col space-y-4'>
                <div className='w-full flex flex-col space-y-2'>
                  <div className='w-full flex space-x-2 items-center' onClick={() => handleNextStep(1)}>
                    <SvgArrowLeft />
                    <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]} className=''>Back</Paragraph>
                  </div>
                  <Heading variant='h1' size='h9' weight='bold'>Checkout - Select staff for services</Heading>
                </div>
              </div>
            </>
          ) : (
            <div className='flex justify-center'>
              <div className='w-full my-20 justify-center items-center xl:max-w-[400px] space-y-6'>
                <Paragraph size='b4' weight='normal' className='text-center'>
                  You haven't selected any services. Please make a selection in order to proceed.
                </Paragraph>
              </div>
            </div>
          )}
        </div>
        <Modal
          show={isOpen} closeModal={cancelOption} size='md' variant='popOver'
        >
          <div className='w-full h-fit flex flex-col xl:max-w-[40%] border border-grey-50 rounded-xl py-8 px-6 gap-8'>
            <div className='w-full flex items-center justify-between'>
              <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>Select staff</Paragraph>
              <Button
                variant='icon'
                size='square'
                rounded='md'
                type='button'
                className='flex max-w-[50px]'
                onClick={cancelOption}
              >
                <SvgClose width='24px' height='24px' />
              </Button>
            </div>
            {Array?.isArray(STAFF_SELECTION_LIST_OPTIONS) && STAFF_SELECTION_LIST_OPTIONS.map((option, index) => (
              <div className='w-full flex flex-col space-y-4' key={index}>
                <div key={option.value} className='w-full cursor-pointer flex justify-between items-center gap-4' onClick={() => {
                  setStaffOption(option.value)
                }}>
                  <Paragraph size='b4' weight='normal'>{option.label}</Paragraph>
                  <div className={`min-w-[20px] h-[20px] border border-grey-300 rounded-full flex justify-center items-center`}>
                    { option.value === staffOption ? (
                      <div className='min-w-[16px] h-[16px] bg-grey-300 rounded-full'></div>
                    ) : null}
                  </div>
                </div>
                {option?.value === staffOption && staffOption === 'all-services' ? getStaffListOptions() : null}
              </div>
              
            ))}
            <Button
              variant='primary'
              className=''
              disabled={!staffOption}
              size='lg'
              rounded='lg'
              type='button'
              onClick={() => setIsOpen(false)}
            >
              Continue
            </Button>
            <Button
              variant='text'
              className='text-center'
              disabled={!goToNext}
              size='lg'
              type='button'
              onClick={cancelOption}
            >
              Cancel
            </Button>
          </div>
        </Modal>
        <Modal
          show={isStaffOptionOpen} closeModal={closeStaffOption} size='md' variant='popOver'
        >
          <div className='w-full h-fit flex flex-col xl:max-w-[40%] border border-grey-50 rounded-xl py-8 px-6 gap-8'>
            <div className='w-full flex items-center justify-between'>
              <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>Select staff for {currentServiceStaffOption?.name}</Paragraph>
              <Button
                variant='icon'
                size='square'
                rounded='md'
                type='button'
                className='flex max-w-[50px]'
                onClick={closeStaffOption}
              >
                <SvgClose width='24px' height='24px' />
              </Button>
            </div>
            <div className='w-full flex flex-col space-y-4'>
              {getStaffListOptions(currentServiceStaffOption?.id)}
            </div>
            <Button
              variant='primary'
              className=''
              disabled={checkIfServiceNowHasStaff()}
              size='lg'
              rounded='lg'
              type='button'
              onClick={closeStaffOption}
            >
              Save
            </Button>
            <Button
              variant='text'
              className='text-center'
              disabled={!goToNext}
              size='lg'
              type='button'
              onClick={closeStaffOption}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
      <div className="w-full flex bg-white z-30 pt-2 flex-col space-y-4 lg:hidden fixed bottom-[20px] h-fit px-[20px]">
        <div className='w-full flex flex-col gap-2 bg-green-300/10 rounded-sm'>
          {staffOption ? (
            <div className='w-full flex flex-col justify-center items-center text-green-300 px-3 py-2 gap-2'>
              <SvgUser />
              <Paragraph size='b4' weight='semiBold' color={COLORS.GREEN[300]} className=''>{getStaffSelectionOption()}</Paragraph>
              <Button
                variant='text'
                size='none'
                type='button'
                className='border-0 text-green-300 underline'
                fontSize='b4'
                fontWeight='semiBold'
                onClick={() => setIsOpen(true)}
              >
                  Click to change
              </Button>
            </div>
          ) : <div className='w-full flex justify-center items-center text-green-300 gap-4 py-3' onClick={() => setIsOpen(true)}>
            <SvgUser />
            <Paragraph size='b4' weight='semiBold' color={COLORS.GREEN[300]} className=''>Select staff</Paragraph>
          </div>}
        </div>
        {staffOption ?
        <Button
          variant='primary'
          className=''
          size='lg'
          rounded='lg'
          onClick={() => continueToNextStep()}
        >
          <div className='w-full flex justify-between items-center px-4 py-2'>
            <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE} className='w-full text-center'>Proceed to select preferred time</Paragraph>
            <SvgArrowRight width="16px" height="16px" />
          </div>
        </Button> : null}
      </div>
    </>
  )
}

export default CheckoutStaffSelection