import React, { ComponentType } from 'react'
import ReactModal from "react-modal";
import { ModalProps } from './types';

const ModalSafeForReact18 = ReactModal as ComponentType<ReactModal['props']>;

const MobileBackgroundModal = ({ children, show, closeModal }: ModalProps) => {
  return (
    <ModalSafeForReact18
      isOpen={show}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className={`absolute mx-auto mt-[50px] w-[90%] h-[80%] outline-0 shadow-2xl overflow-auto flex justify-center items-center`}
      overlayClassName="fixed inset-0 bg-black/70 backdrop-blur-[2px] z-40 flex justify-center xl:items-end xl:py-[40px] "
      shouldCloseOnOverlayClick={true}
    >
      <div className="w-full h-full overflow-auto flex flex-col px-4 justify-center items-center bg-white">
        {children}
      </div>
    </ModalSafeForReact18>
  )
}

export default MobileBackgroundModal
