import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMdiCouponOutline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m9.867 5.333.8.8-4.534 4.533-.8-.8 4.534-4.533Zm-7.2-2.667h10.666c.74 0 1.334.593 1.334 1.333v2.667a1.333 1.333 0 1 0 0 2.667v2.666c0 .74-.594 1.334-1.334 1.334H2.667a1.333 1.333 0 0 1-1.334-1.334V9.333a1.333 1.333 0 1 0 0-2.667V3.999a1.333 1.333 0 0 1 1.334-1.333Zm0 1.333v1.694a2.663 2.663 0 0 1 0 4.613v1.693h10.666v-1.693a2.663 2.663 0 0 1 0-4.613V3.999H2.667Zm3.666 1.334c.554 0 1 .446 1 1 0 .553-.446 1-1 1-.553 0-1-.447-1-1 0-.554.447-1 1-1Zm3.334 3.333c.553 0 1 .447 1 1 0 .553-.447 1-1 1-.554 0-1-.447-1-1 0-.553.446-1 1-1Z"
      fill="#667368"
    />
  </svg>
);
export default SvgMdiCouponOutline;
