import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSLogo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g filter="url(#SLogo_svg__a)">
      <rect width={150} height={150} rx={75} fill="#16796F" />
      <rect
        x={0.75}
        y={0.75}
        width={148.5}
        height={148.5}
        rx={74.25}
        stroke="#fff"
        strokeWidth={1.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.15 109.3c5.334 2.067 12.1 3.1 20.3 3.1 11.334 0 20-2.033 26-6.1 6.067-4.133 9.1-9.8 9.1-17 0-5.4-1.133-9.633-3.4-12.7-2.2-3.067-5.666-5.367-10.4-6.9-4.666-1.6-10.7-2.8-18.1-3.6-4.4-.533-7.833-1.033-10.3-1.5-2.4-.533-4.066-1.267-5-2.2-.64-.641-1.062-1.47-1.263-2.49l-18.02-9.934C41.19 53.05 40.25 56.66 40.25 60.8c0 4.733 1.167 8.567 3.5 11.5 2.334 2.933 5.867 5.2 10.6 6.8 4.733 1.6 10.7 2.833 17.9 3.7 4.133.467 7.4.967 9.8 1.5 2.4.467 4.1 1.2 5.1 2.2 1.067.933 1.6 2.4 1.6 4.4 0 2.467-1.133 4.367-3.4 5.7-2.266 1.333-5.633 2-10.1 2-5.2 0-9.1-.967-11.7-2.9-2.6-1.933-3.966-4.5-4.1-7.7h-21c.067 4.733 1.367 8.967 3.9 12.7 2.6 3.667 6.533 6.533 11.8 8.6Zm-7.864-63.26a22.725 22.725 0 0 1 2.964-2.34c6.067-4.067 14.367-6.1 24.9-6.1 9.533 0 17.2 1.933 23 5.8 5.867 3.8 9.134 9.267 9.8 16.4h-20.4c-.333-3.067-1.7-5.267-4.1-6.6-2.333-1.333-5.367-2-9.1-2-3.933 0-7 .667-9.2 2-.798.474-1.446 1.013-1.946 1.617L46.286 46.04Z"
        fill="url(#SLogo_svg__b)"
      />
    </g>
    <defs>
      <linearGradient
        id="SLogo_svg__b"
        x1={98}
        y1={118.5}
        x2={221}
        y2={382}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <filter
        id="SLogo_svg__a"
        x={-4}
        y={-4}
        width={158}
        height={158}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={4} dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend in2="shape" result="effect1_innerShadow_20368_238" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-4} dy={-4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend
          in2="effect1_innerShadow_20368_238"
          result="effect2_innerShadow_20368_238"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgSLogo;
