import * as React from "react";
import { AuthTemplateProps } from './types'
import { Heading, Logo } from "../..";

const LoginTemplate: React.FunctionComponent<AuthTemplateProps> = (props: AuthTemplateProps) => {
  return (
    <main className="flex items-center justify-center min-h-screen bg-white xl:bg-grey-100">
      <div className="table-row">
        <div className="table-cell align-middle">
          <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-8 px-9 max-w-[480px] xl:w-[580px] xl:max-w-[700px] space-y-6">
            <div className="flex flex-col items-center space-y-6">
              <Logo />
              <Heading variant='h1' size="h8" weight='medium' className='w-full text-center'>Login to your account</Heading>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </main>
  )

}

export default LoginTemplate
