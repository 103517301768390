export const API_ROUTES = {
  LOGIN: "/login",
  LOGOUT: "/auth/logout",
  REGISTER: "/register",
  SEND_EMAIL_VERIFICATION_CODE: "/resend-email-verification",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  CHANGE_PASSWORD: "/password-change",
  VERIFY_EMAIL: "/auth/email-verification/complete",
  ME: "/auth/me",
  ACCOUNT_INFORMATION: "/users/me",
  BUSINESS_INFORMATION: "/business/me",
  UPDATE_BUSINESS_LOGO: "/business/logo",
  UPDATE_BUSINESS_BOOKING_URL: "/business/booking-url",
  UPDATE_BUSINESS_BVN: "/business/bvn-verification",
  VERIFY_BUSINESS_BVN: "/business/bvn-verification/complete",
  RESEND_BUSINESS_BVN_VERIFICATION_CODE: "/business/bvn/resend",
  BUSINESS_PAYOUT: "/business/payout-accounts",
  SALONS: "/salons",
  INITIATE_SALON_WITHDRAWAL: "/salons/:salonId/payouts",
  CREATE_SALON_CLIENT: "/salons/:salonId/clients",
  UPLOAD_SALON_CLIENTS: "/salons/:salonId/clients/batch",
  DELETE_SALON_CLIENT: "/salons/:salonId/clients/:clientId",
  UPDATE_SALON_CLIENT: "/salons/:salonId/clients/:clientId",
  SALON_CLIENTS: "/salons/:salonId/clients",
  SALON_CLIENT: "/salons/:salonId/clients/:clientId",
  SALON_CLIENT_APPOINTMENTS: "/salons/:salonId/clients/:clientId/appointments",
  SALON_CLIENT_NOTES: "/salons/:salonId/clients/:clientId/notes",
  UPDATE_SALON_CLIENT_NOTES: "/salons/:salonId/clients/:clientId/notes/:noteId",
  SALON_HOURS: "/salons/:salonId/hours",
  UPDATE_SALON: "/salons/:salonId",
  CREATE_SALON_SERVICE: "/salons/:salonId/services",
  UPLOAD_SALON_SERVICES: "/salons/:salonId/services/batch",
  UPDATE_SALON_SERVICE: "/salons/:salonId/services/:serviceId",
  DELETE_SALON_SERVICE: "/salons/:salonId/services/:serviceId",
  SALON_SERVICES: "/salons/:salonId/services",
  CREATE_SALON_PRODUCT: "/salons/:salonId/products",
  UPLOAD_SALON_PRODUCTS: "/salons/:salonId/products/batch",
  UPDATE_SALON_PRODUCT: "/salons/:salonId/products/:productId",
  UPDATE_SALON_PRODUCT_STOCK: "/salons/:salonId/products/:productId/stocks",
  DELETE_SALON_PRODUCT: "/salons/:salonId/products/:productId",
  SALON_PRODUCTS: "/salons/:salonId/products",
  SALON_PRODUCT: "/salons/:salonId/products/:productId",
  CREATE_SALON_STAFF: "/salons/:salonId/staff",
  UPLOAD_SALON_STAFF: "/salons/:salonId/staff/batch",
  UPDATE_SALON_STAFF: "/salons/:salonId/staff/:staffId",
  DELETE_SALON_STAFF: "/salons/:salonId/staff/:staffId",
  SALON_STAFF: "/salons/:salonId/staff",
  SALON_STAFF_BY_ID: "/salons/:salonId/staff/:staffId",
  SIGN_CLOUDINARY_MEDIA_ASSET_UPLOAD: "/sign-cloudinary-media-asset-upload",
  GET_LOYALTIES: "/business/loyalties",
  CREATE_LOYALTY: "/business/loyalties",
  LOYALTY_POINT: "/business/point-value",
  UPDATE_LOYALTY: "/business/loyalties/:loyaltyId",
  GET_LOYALTY: "/business/loyalties/:loyaltyId",
  DELETE_LOYALTY: "/business/loyalties/:loyaltyId",
  CREATE_PROMO: "/business/promos",
  UPDATE_PROMO: "/business/promos/:promoId",
  GET_PROMO: "/business/promos/:promoId",
  GET_PROMOS: "/business/promos",
  DELETE_PROMO: "/business/promos/:promoId",
  CREATE_CLOSED_PERIOD: "/business/closed-periods",
  CLOSED_PERIOD_ID_ROUTE: "/business/closed-periods/:closedPeriodId",
  CREATE_STAFF_TIME_OFF: "/salons/:salonId/staff-time-off",
  STAFF_TIME_OFF_ID_ROUTE: "/salons/:salonId/staff-time-off/:timeOffId",
  AUTOMATED_MESSAGES: "/salons/:salonId/automated-messages",
  AUTOMATED_MESSAGE_ID_ROUTE: "/salon/automated-messages/:messageId",
  CUSTOM_MESSAGES: "/salons/:salonId/custom-messages",
  CUSTOM_MESSAGE_ID_ROUTE: "/salons/custom-messages/:messageId",
  GET_BUSINESS_BY_APPOINTMENT_LINK: "/business/:appointmentLink",
  BILLING_HISTORY: "/business/subscription-transactions",
  GET_SALON_AVAILABILITY: "/salons/:salonId/availability",
  CURRENT_PLAN: "/business/current-plan",
  CARD_DETAILS: "/business/card-details",
  GET_CLIENT_BY_PHONE_NUMBER: "/salons/:salonId/clients/search",
  CREATE_APPOINTMENT: "/salons/:salonId/appointments",
  PREVIEW_APPOINTMENT_PRICE: "/salons/:salonId/appointment-payment-preview",
  PREVIEW_PACKAGES_PRICE: "/salons/:salonId/package-vouchers/payment-preview",
  RECORD_APPOINTMENT_REFUND: "/salons/:salonId/appointment-refunds",
  APPOINTMENTS: "/salons/:salonId/appointments",
  APPOINTMENTS_SERVICES: "/salons/:salonId/appointment-services",
  APPOINTMENT_ID_ROUTE: "/salons/:salonId/appointments/:appointmentId",
  APPOINTMENT_SERVICE_ID_ROUTE:
    "/salons/:salonId/appointment-services/:appointmentServiceId",
  APPOINTMENT_TODAY_SUMMARY:
    "/salons/:salonId/appointment-services/today-summary",
  ADD_SOCIALS: "/business/socials",
  REDEEM_PROMO: "/business/promos/redemption",
  SALON_STAFF_WORKING_HOURS:
    "/salons/:salonId/staff-working-hours/:startDate/:endDate",
  GET_PLANS: "/subscription-plans",
  ONLINE_DETAILS: "/business/online-details",
  SALON_PAYMENTS: "/salons/:salonId/payments",
  SALON_PAYMENT_ID: "/salons/:salonId/payments/:paymentId",
  SALON_EXPENSES: "/salons/:salonId/expenses",
  SALON_EXPENSE_ID: "/salons/:salonId/expenses/:expenseId",
  WALLET_BALANCE: "/salons/:salonId/balance",
  BANKS: "/banks",
  SALON_STAFF_PERFORMANCE: "/salons/:salonId/staff/performance-summary",
  SALON_WALLET_TRANSACTIONS: "/salons/:salonId/wallet-transactions",
  SALON_PERFORMANCE: "/salons/:salonId/performance-summary",
  CLIENTS_OVERVIEW: "/salons/:salonId/clients/appointments-overview",
  CLIENTS_RETENTION: "/salons/:salonId/clients/retention-summary",
  SALON_STAFF_APPOINTMENTS_OVERVIEW:
    "/salons/:salonId/staff/appointments-overview",
  SALON_SERVICES_APPOINTMENTS_OVERVIEW:
    "/salons/:salonId/services/appointments-overview",
  MAIL_APPOINTMENT_RECEIPT:
    "/salons/:salonId/appointments/:appointmentId/email-receipt",
  MAIL_SALE_RECEIPT: "/salons/:salonId/sales/:saleId/email-receipt",
  SALON_SERVICES_SALES_OVERVIEW: "/salons/:salonId/services/sales-overview",
  SALON_SALES_REPORT: "/salons/:salonId/sales-summary",
  SALON_STAFF_SALES_OVERVIEW: "/salons/:salonId/staff/sales-overview",
  STAFF_APPOINTMENTS_SUMMARY: "/salons/:salonId/staff/appointment-summary",
  SALON_NOTIFICATION_COUNT: "/salons/:salonId/notifications/unread-count",
  SALON_NOTIFICATIONS: "/salons/:salonId/notifications",
  BUSINESS_SUBSCRIPTION: "/business/subscription",
  SALON_APPOINTMENT_REVIEWS: "/salons/:salonId/reviews",
  SALON_APPOINTMENT_REVIEW: "/reviewable-appointments/:id",
  SALON_APPOINTMENT_RECEIPT: "/business-appointments/:id",
  SALON_APPOINTMENT_SUMMARY: "/salon-appointments/:id",
  SALON_DEPOSIT_APPOINTMENT: "/deposit-appointments/:id",
  SALON_SALES_RECEIPT: "/business-sales/:id",
  STAFF_ROSTER: "/salons/:salonId/staff/roster",
  SALON_POS: "/salons/:salonId/pos-devices",
  SALON_POS_ID: "/salons/:salonId/pos-devices/:posId",
  SALON_BANK_TRANSFERS: "/salons/:salonId/transfer-accounts",
  SALON_BANK_TRANSFERS_ID: "/salons/:salonId/transfer-accounts/:accountId",
  GET_PROMO_ACTIVITY_REPORT: "/salons/:salonId/appointments/promo-summary",
  STAFF_BLOCKED_TIMES: "/salons/:salonId/staff-blocked-times",
  STAFF_BLOCKED_TIME: "/salons/:salonId/staff-blocked-times/:blockedTimeId",
  ACCOUNT_SETUP_SUMMARY: "/business/account-setup-summary",
  CREATE_SALE: "/salons/:salonId/sales",
  SALES: "/salons/:salonId/sales",
  CLIENT_SALES: "/salons/:salonId/clients/:clientId/sales",
  SALON_SALES_PAYMENTS: "/salons/:salonId/payment-transactions",
  SALON_SALES_REPORTS: "/salons/:salonId/sales-report",
  SALON_SALES_PAYMENT_SUMMARY: "/salons/:salonId/sales/payments-summary",
  SALON_SALES_TRANSACTION_SUMMARY: "/salons/:salonId/sales/transaction-summary",
  CREATE_SALON_PACKAGE: "/salons/:salonId/packages",
  SALON_PACKAGES: "/salons/:salonId/packages",
  CLIENT_SALON_PACKAGES: "/salons/:salonId/clients/:clientId/package-vouchers",
  SALON_PACKAGE: "/salons/:salonId/packages/:packageId",
  PURCHASE_SALON_PACKAGE: "/salons/:salonId/package-vouchers",
  SALON_VOUCHER: "/salons/:salonId/vouchers/:voucherId",
  LOGIN_CUSTOMER: "/voucher-verification",
  CUSTOMER_VOUCHER_PACKAGES: "/clients/:clientId/vouchers-summary",
  PACKAGES_REPORTS_OVERVIEW: "/salons/:salonId/packages/overview",
  PACKAGES_LIST_OVERVIEW: "/salons/:salonId/package-vouchers",
  PACKAGES_SALES_OVERVIEW:
    "/salons/:salonId/package-vouchers/appointments-overview",
  REDEEM_VOUCHER_CODE: "/package-vouchers/code-redemption",
  CREATE_VOUCHER_SALE: "/salons/:salonId/package-vouchers/sales",
  GET_BUSINESS_SERVICES: "/business/services",
  GET_BUSINESS_PRODUCTS: "/business/products",
  GET_BUSINESS_PACKAGES: "/business/packages",
};
