import React, { ComponentType } from 'react'
import ReactModal from "react-modal";
import { ModalProps } from './types';

const ModalSafeForReact18 = ReactModal as ComponentType<ReactModal['props']>;

const PopOver = ({ children, show, closeModal }: ModalProps) => {
  return (
    <ModalSafeForReact18
      isOpen={show}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className={`absolute bottom-0 left-0 w-full xl:w-[275px] xl:bottom-[10%] xl:left-[20%] block outline-0 max-h-[100%] xl:h-fit xl:max-h-[calc(100%-200px)] shadow-2xl overflow-auto`}
      overlayClassName="fixed inset-0 bg-black/70 backdrop-blur-[2px] z-40 flex justify-center xl:items-end xl:py-[40px]"
      shouldCloseOnOverlayClick={true}
    >
      <div className="w-full min-h-fit bg-white flex flex-col">
        {children}
      </div>
    </ModalSafeForReact18>
  )
}

export default PopOver