import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgShoppingTagCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={40} height={40} rx={20} fill="#7EA05C" />
    <g clipPath="url(#shopping-tag-circle_svg__a)">
      <path
        d="m14.908 14.909-6-6m21.806 15.137-6.669 6.668a1.285 1.285 0 0 1-1.817 0l-11.16-11.16a.618.618 0 0 1-.188-.497l1.011-6.583a.634.634 0 0 1 .583-.582l6.583-1.012a.618.618 0 0 1 .497.189l11.16 11.16a1.286 1.286 0 0 1 0 1.817Z"
        stroke="#FEFEFE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="shopping-tag-circle_svg__a">
        <path fill="#fff" transform="translate(8 8)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgShoppingTagCircle;
