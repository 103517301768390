import axios from 'axios'
import {
  ActivateLoyaltyProgramDocument,
  CreateLoyaltyInput,
  CreateLoyaltyProgramDocument,
  DeleteLoyaltyProgramDocument,
  DeleteLoyaltyProgramInput,
  DeleteMilestoneDocument,
  DeleteMilestoneInput,
  LoyaltyProgram,
  LoyaltyProgramDocument,
} from '../graphql/generated'
import { useToast } from '../hooks'
import React, { createContext, useContext, useState } from 'react'
import { print } from 'graphql'

const LoyaltyContext = createContext(undefined)

const LoyaltyProvider = ({ children }) => {
  const token = localStorage.getItem('token')
  const [loyalty, setLoyalty] = useState<LoyaltyProgram | null>(null)
  const { toast, addToast } = useToast()

  const getLoyalty = () => {
    axios
      .post(
        '/graphql',
        { query: print(LoyaltyProgramDocument) },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { loyaltyProgram: l }
          }
        } = res
          setLoyalty(l)
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  const activateLoyalty = async () => {
    try {
      const {
        data: {
          data: { activateLoyaltyProgram: cl }
        }
      } = await axios.post(
        '/graphql',
        {
          query: print(ActivateLoyaltyProgramDocument), variables: {
            input: { id: loyalty?.id } } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (cl.status === 200) {
        getLoyalty()
        addToast({ message: 'Loyalty activated successfully', variant: 'success' })
      } else {
        addToast({ message: cl.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const createLoyalty = async (input: CreateLoyaltyInput) => {
    try {
      const {
        data: {
          data: { createLoyaltyProgram: cl }
        }
      } = await axios.post(
        '/graphql',
        { query: print(CreateLoyaltyProgramDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (cl.status === 200) {
        getLoyalty()
        addToast({ message: 'Loyalty saved successfully', variant: 'success' })
      } else {
        addToast({ message: cl.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const deleteLoyalty = async (input: DeleteLoyaltyProgramInput, closeModal: () => void, reset: () => void) => {
    try {
      const {
        data: {
          data: { deleteLoyaltyProgram: dl }
        }
      } = await axios.post(
        '/graphql',
        { query: print(DeleteLoyaltyProgramDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (dl.status == 200) {
        getLoyalty()
        addToast({ message: 'Loyalty deleted successfully', variant: 'success' })
        closeModal()
        reset()
      } else {
        addToast({message: dl.errors[0].message, variant: 'error'})
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const deleteLoyaltyMilestone = async (input: DeleteMilestoneInput) => {
    try {
      const {
        data: {
          data: { deleteMilestone: dl }
        }
      } = await axios.post(
        '/graphql',
        { query: print(DeleteMilestoneDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (dl.status == 200) {
        addToast({ message: 'Milestone deleted successfully', variant: 'success' })
      } else {
        addToast({message: dl.errors[0].message, variant: 'error'})
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  return (
    <LoyaltyContext.Provider
      value={{ loyalty, toast, addToast, getLoyalty, createLoyalty, deleteLoyalty, deleteLoyaltyMilestone, activateLoyalty }}
    >
      {children}
    </LoyaltyContext.Provider>
  )
}

// Loyalty Context custom hook
export const useLoyaltyContext = () => {
  const context = useContext(LoyaltyContext)
  if (!context) {
    throw new Error('useLoyaltyContext must be used with a LoyaltyProvider')
  }

  return context
}

export default LoyaltyProvider
