export const sortTableDataRows = (data, key, direction = 'asc') => {
  const sortedData = [...data].sort((a, b) => {
    let valA = a[key];
    let valB = b[key];

    // Remove '₦' symbol if it exists in valA and valB
    if (typeof valA === 'string' && valA.includes('₦')) {
      valA = valA?.replace('₦', '').trim();
    }
    if (typeof valB === 'string' && valB.includes('₦')) {
      valB = valB?.replace('₦', '').trim();
    }

    if (typeof valA === 'string' && valA.includes('mins')) {
      valA = valA?.replace('mins', '').trim();
    }
    if (typeof valB === 'string' && valB.includes('mins')) {
      valB = valB?.replace('mins', '').trim();
    }

    // Handle numeric sorting for 'quantity' and any other numeric fields
    if (!isNaN(valA) && !isNaN(valB)) {
      valA = parseFloat(valA?.replace(/,/g, ''));
      valB = parseFloat(valB?.replace(/,/g, ''));
    }

    if (direction === 'asc') {
      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    } else if (direction === 'desc') {
      if (valA > valB) return -1;
      if (valA < valB) return 1;
      return 0;
    } else {
      throw new Error("Invalid sort direction. Use 'asc' or 'desc'.");
    }
  });
  return sortedData;
};