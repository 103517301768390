import axios from "axios";
import { print } from "graphql"
import { BusinessDocument, FaqsDocument } from "../../graphql/generated";

export const fetchBusinessByUrl = async (variables, setData) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(BusinessDocument), variables },
    );

    const {
      data: {
        data: { business }
      }
    } = response;
    setData(business);
  } catch (error) {
    console.log(error);
  }
};

export const fetchBusinessFaq = async (variables, setData) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(FaqsDocument), variables },
    );

    const {
      data: {
        data: { faqs }
      }
    } = response;
    setData(faqs);
  } catch (error) {
    console.log(error);
  }
};