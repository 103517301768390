import * as React from "react";
import { SummaryTableCardProps } from './types'
import { Heading } from "../..";

const SummaryTableCard = (props: SummaryTableCardProps) => {
  return (
    <div className='w-full flex flex-col border border-grey-100 rounded-md'>
      <div className='px-4 py-6'><Heading variant={'h2'} size='h11' weight='semiBold'>{props.title}</Heading></div>
      {props.children}
    </div>
  )
}

export default SummaryTableCard