import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCancelled = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={8} fill="#F8F2F2" />
    <path
      d="M16 9.5A6.508 6.508 0 0 0 9.5 16c0 3.584 2.916 6.5 6.5 6.5s6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5Zm0 1c3.044 0 5.5 2.457 5.5 5.5a5.468 5.468 0 0 1-1.313 3.563l-7.672-7.828A5.477 5.477 0 0 1 16 10.5Zm-4.188 1.938 7.673 7.827A5.477 5.477 0 0 1 16 21.5a5.492 5.492 0 0 1-5.5-5.5c0-1.363.495-2.603 1.313-3.563Z"
      fill="#7A0202"
    />
  </svg>
);
export default SvgCancelled;
