import React, { createContext, useContext, useState } from 'react'
import axios from 'axios'
import {
  ClosedPeriod,
  ClosedPeriodsDocument,
  CreateClosedPeriodDocument,
  CreateClosedPeriodInput,
  DeleteClosedPeriodDocument,
} from '../graphql/generated'
import { print } from 'graphql'
import { useToast } from '../hooks'
import { useSalonCache } from '../hooks/useSalonCache'
import { ToastProps } from '../ui'

const ClosedBusinessPeriodContext = createContext(undefined)

const ClosedBusinessProvider = ({ children }) => {
  const token = localStorage.getItem('token')
  const [closedPeriods, setClosedPeriods] = useState<ClosedPeriod[]>()
  const { toast, addToast } = useToast()
  const { getSalonFieldValue } = useSalonCache()

  const getClosedBusinessPeriods = () => {
    axios
      .post(
        '/graphql',
        {
          query: print(ClosedPeriodsDocument),
          variables: { salonId: getSalonFieldValue('id') }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { closedPeriods: cp }
          }
        } = res
        setClosedPeriods(cp)
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  const deleteClosedPeriod = (closedPeriodId: string, close: () => void) => {
    axios
      .post(
        '/graphql',
        {
          query: print(DeleteClosedPeriodDocument),
          variables: { input: { closedPeriodId } }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { deleteClosedPeriod }
          }
        } = res

        if (deleteClosedPeriod.status === 200) {
          close()
          getClosedBusinessPeriods()
          addToast({
            message: 'Closed period deleted successfully',
            variant: 'success'
          })
        } else {
          addToast({
            message: deleteClosedPeriod.errors[0].message,
            variant: 'error'
          })
        }
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  const createClosedPeriod = (input: CreateClosedPeriodInput, handleClose: () => void, addToast: (toast: ToastProps) => void) => {
    axios
      .post(
        '/graphql',
        {
          query: print(CreateClosedPeriodDocument),
          variables: { input }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { createClosedPeriod }
          }
        } = res

        if (createClosedPeriod.status === 200) {
          getClosedBusinessPeriods()
          addToast({
            message: 'Closed period saved successfully',
            variant: 'success'
          })
          handleClose()
        } else {
          addToast({
            message: createClosedPeriod.errors[0].message,
            variant: 'error'
          })
        }
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  return (
    <ClosedBusinessPeriodContext.Provider
      value={{
        getClosedBusinessPeriods,
        closedPeriods,
        toast,
        deleteClosedPeriod,
        createClosedPeriod
      }}
    >
      {children}
    </ClosedBusinessPeriodContext.Provider>
  )
}

// Client Context custom hook
export const useClosedBusinessPeriodContext = () => {
  const context = useContext(ClosedBusinessPeriodContext)
  if (!context) {
    throw new Error(
      'useClosedBusinessPeriodContext must be used with a ClosedBusinessPeriodProvider'
    )
  }
  return context
}

export default ClosedBusinessProvider
