import Select from 'react-select';
import React from 'react'
import { SingleSelectProps } from './types';

const SingleSelect = ({
  options,
  selected,
  setSelected,
  onInputChange,
  placeholder,
  isLoading,
  isDisabled
}: SingleSelectProps) => {
  return (
    <Select
      options={options}
      onChange={setSelected}
      value={selected}
      isLoading={isLoading}
      onInputChange={onInputChange}
      isDisabled={isDisabled}
      placeholder={placeholder || "Search..."}
      styles={{
        control: provided => ({
          ...provided,
          padding: "4px",
          backgroundColor: isDisabled ? '#E9E9E9' : 'white',
          border: '1px solid #E9E9E9', // add border to match the "border" class
          borderRadius: '8px', // add border radius to match the "rounded-md" class
          fontSize: '16px', // add font size to match the "text-b6 xl:text-b4 3xl:text-b2" classes
          lineHeight: '22px', // add line height to match the "text-b6 xl:text-b4 3xl:text-b2" classes
          color: '#9AA2A5', // add color to match the "text-grey-400" class
        }),
        placeholder: provided => ({
          ...provided,
          color: '#667368', // add color to match the "placeholder:text-grey-900" class
        }),
        singleValue: provided => ({
          ...provided,
          color: '#9AA2A5', // add color to match the "text-grey-900" class
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? '#667368' : '#000', // color based on disabled state
          backgroundColor: state.isDisabled ? '#f5f5f5' : '#fff', // background based on disabled state
          '&:hover': {
            backgroundColor: state.isDisabled ? '#f5f5f5' : '#E2E8E9',
          },
        }),
      }}
      className='border border-grey-20 font-normal appearance-none rounded-md focus:outline-none focus:border-grey-20 focus:shadow-grey-100'
    />
  )
}

export default SingleSelect