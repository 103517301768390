import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import React from 'react'
import { useAuth } from '../../hooks/useAuth';
import { useUserCache } from '../../hooks/useUserCache';
import { PAGE_ROUTES } from '../../constants/routes';
import { Logo } from '../../ui/atoms/logo';
import NameAvatar from '../../ui/atoms/nameAvatar/NameAvatar';
import { COLORS } from '../../constants/colors';
import Paragraph from '../../ui/atoms/paragraph/Paragraph';
import { SvgLoyalty, SvgPackage } from '../../ui/icons';
import { CustomerDashboardHeader } from './types';

const RedeemHeader = ({
  setCurrentTab,
  currentTab
}: CustomerDashboardHeader) => {
  const { getCustomerData } = useUserCache();
  const { logoutCustomer } = useAuth();
  const customerInfo = getCustomerData();

  const _logoutCustomer = () => {
    logoutCustomer();
    window.location.href = PAGE_ROUTES.REDEEM_VOUCHER
  }

  const initials = `${customerInfo?.firstName?.charAt(0)} ${customerInfo?.lastName?.charAt(0)}`;
  const activeClassNames = "border-b-2 border-green-600 cursor-text text-green-300 font-semibold";
  const notActiveClassNames = "border-b-2 border-transparent cursor-pointer text-grey-400";
  return (
    <>
      <header className={`w-full flex justify-between items-center md:items-start px-4 border-b h-[70px] border-grey-20`}>
        {customerInfo ? (
          <>
            <div className='flex h-full items-center'>
              <Logo className='max-w-[60px] xl:max-w-[65px]' />
            </div>
            <div className='hidden lg:flex space-x-4 h-full items-end'>
              <button
                className={`w-fit xl:w-auto text-b5 3xl:text-b4 flex py-2 space-x-2 ${currentTab === 'loyalty' ? activeClassNames : notActiveClassNames} px-3 antialiased whitespace-normal`}
                type="button"
                onClick={() => setCurrentTab('loyalty')}
              >
                <SvgLoyalty width="16px" height="16px" />
                <span className='text-grey-900'>Loyalty</span>
              </button>

              <button
                className={`w-fit xl:w-auto text-b5 3xl:text-b4 flex py-2 space-x-2 ${currentTab === 'package' ? activeClassNames : notActiveClassNames} px-3 antialiased whitespace-normal`}
                type="button"
                onClick={() => setCurrentTab('package')}
              >
                <SvgPackage width="16px" height="16px" />
                <span className='text-grey-900'>Voucher</span>
              </button>
            </div>
            <Menu menuButton={
              <MenuButton className="flex h-full space-x-4 items-center py-2">
                <NameAvatar mobile={true} name={initials} size='sm' />
                <Paragraph size='b4' color={COLORS.GREY[300]}>{customerInfo?.firstName} {customerInfo?.lastName?.charAt(0)}.</Paragraph>
              </MenuButton>}
              key="bottom"
              direction="bottom"
              transition>
              <MenuItem>
                <span className="text-b4 cursor-pointer" onClick={_logoutCustomer}>Logout</span>
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </header>
      {customerInfo ? (
        <div className='w-full flex lg:hidden space-x-4 border-b border-grey-20 h-full items-end'>
          <button
            className={`w-full xl:w-auto text-b5 3xl:text-b4 flex justify-center p-4 space-x-2 ${currentTab === 'loyalty' ? activeClassNames : notActiveClassNames} px-3 antialiased whitespace-normal`}
            type="button"
            onClick={() => setCurrentTab('loyalty')}
          >
            <SvgLoyalty width="16px" height="16px" />
            <span className='text-grey-900'>Loyalty</span>
          </button>

          <button
            className={`w-full xl:w-auto text-b5 3xl:text-b4 flex justify-center p-4 space-x-2 ${currentTab === 'package' ? activeClassNames : notActiveClassNames} px-3 antialiased whitespace-normal`}
            type="button"
            onClick={() => setCurrentTab('package')}
          >
            <SvgPackage width="16px" height="16px" />
            <span className='text-grey-900'>Voucher</span>
          </button>
        </div>) : null}
    </>
  )
}

export default RedeemHeader