import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgProductsEmpty = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 380 380"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M190 363.227c98.885 0 179.048-46.283 179.048-103.375 0-57.093-80.163-103.375-179.048-103.375-98.886 0-179.049 46.282-179.049 103.375 0 57.092 80.163 103.375 179.049 103.375Z"
      fill="#F5F5F5"
    />
    <path
      d="m249.599 290.07 39.147 22.717 33.965-19.608-39.444-22.884-33.668 19.775Z"
      fill="#E0E0E0"
    />
    <path
      d="m285.729 279.543 38 21.979 33.957-19.608-38.296-22.146-33.661 19.775ZM202.061 321.017c25.205 0 45.638-12.406 45.638-27.71 0-15.303-20.433-27.709-45.638-27.709s-45.638 12.406-45.638 27.709c0 15.304 20.433 27.71 45.638 27.71Z"
      fill="#E0E0E0"
    />
    <path
      d="m115.801 310.703 43.221-24.951-52.485-30.301-43.222 24.951 52.486 30.301Z"
      fill="#E0E0E0"
    />
    <path
      d="m45.311 294.835-27.786-16.044 58.748-33.812 27.695 15.99-58.657 33.866Z"
      fill="#E0E0E0"
    />
    <path
      d="m45.41 291.916 60.481-34.861v-56.909L45.411 235v56.916Z"
      fill="#16796F"
    />
    <path
      opacity={0.75}
      d="m45.41 291.916 60.481-34.861v-56.909L45.411 235v56.916Z"
      fill="#fff"
    />
    <path
      d="m28.979 225.516 16.43 9.485 60.482-34.854-16.424-9.485-60.489 34.854Z"
      fill="#16796F"
    />
    <path
      opacity={0.9}
      d="m28.979 225.516 16.43 9.485 60.482-34.854-16.424-9.485-60.489 34.854Z"
      fill="#fff"
    />
    <path
      d="M28.979 225.516 45.409 235v56.917l-16.43-9.485v-56.916Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M28.979 225.516 45.409 235v56.917l-16.43-9.485v-56.916Z"
      fill="#fff"
    />
    <path
      opacity={0.1}
      d="M105.899 215.135 61.697 240.61v-14.987l-.44.251v14.987l-15.847 9.135v4.742l15.846-9.135v37.179l.441-.258v-37.172l44.194-25.475.008-4.742Z"
      fill="#000"
    />
    <path
      d="m45.41 216.053 16.287 9.568v57.213l4.104-2.364v-57.22l-16.287-9.568-4.104 2.371Z"
      fill="#16796F"
    />
    <path
      opacity={0.1}
      d="m65.8 242.213-4.104 2.371v.76l4.104-2.371v-.76ZM28.979 240.51v4.742l16.43 9.485v-4.742l-16.43-9.485Z"
      fill="#000"
    />
    <path
      d="m28.979 239.744 16.423 9.485 60.489-34.854v4.742l-60.489 34.854-16.424-9.485v-4.742Z"
      fill="#16796F"
    />
    <path
      opacity={0.15}
      d="M45.41 253.969v-4.742l-16.431-9.485v4.743l16.43 9.484Z"
      fill="#000"
    />
    <path
      opacity={0.5}
      d="m65.801 223.25-4.104 2.371-16.287-9.568 4.104-2.371 16.287 9.568Z"
      fill="#fff"
    />
    <path
      opacity={0.1}
      d="m44.832 216.389 16.424 9.485.44-.251-16.286-9.568-.578.334Z"
      fill="#000"
    />
    <path
      d="m75.658 265.082 42.986 24.814v16.545l-42.986-24.814v-16.545Z"
      fill="#16796F"
    />
    <path
      opacity={0.15}
      d="m75.658 265.082 42.986 24.814v16.545l-42.986-24.814v-16.545Z"
      fill="#000"
    />
    <path
      d="m118.644 289.896 35.818-20.679v16.545l-35.818 20.68v-16.546ZM154.463 269.216l-42.986-24.814-35.819 20.68 42.986 24.814 35.819-20.68Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="m154.463 269.216-42.986-24.814-35.819 20.68 42.986 24.814 35.819-20.68Z"
      fill="#fff"
    />
    <path
      d="M135.873 250.604H85.219v16.545c0 3.747 2.477 7.486 7.425 10.343 9.88 5.708 25.924 5.708 35.811 0 4.948-2.857 7.418-6.596 7.418-10.343v-16.545Z"
      fill="#37474F"
    />
    <path
      d="M110.2 281.772a41.452 41.452 0 0 0 9.66-1.01v-21.174h-9.66v22.184Z"
      fill="#16796F"
    />
    <path
      opacity={0.15}
      d="M110.2 281.772a41.452 41.452 0 0 0 9.66-1.01v-21.174h-9.66v22.184Z"
      fill="#000"
    />
    <path
      d="M92.637 240.268c-9.88 5.715-9.88 14.972 0 20.68 9.88 5.707 25.923 5.707 35.811 0 9.887-5.708 9.88-14.965 0-20.68s-25.924-5.708-35.811 0Z"
      fill="#455A64"
    />
    <path
      d="M109.357 236.004a40.64 40.64 0 0 0-9.591 1.368l10.434 27.869a41.022 41.022 0 0 0 9.66-1.011l-10.503-28.226Z"
      fill="#16796F"
    />
    <path
      d="M315.332 192.759c1.459-.844 3.245-.76 5.039.296 4.134 2.387 7.494 9.28 7.494 15.368v14.356a.652.652 0 0 1-.654.654.652.652 0 0 1-.654-.654v-14.356c0-5.67-3.04-12.062-6.84-14.235-1.39-.806-2.682-.912-3.731-.297-1.52.897-2.455 3.177-2.455 6.263v14.288a.653.653 0 0 1-1.3 0v-14.288c0-3.565 1.125-6.255 3.101-7.395Z"
      fill="#37474F"
    />
    <path
      d="m283.268 275.052 4.02-68.993 11.506-6.627 3.77 64.478-19.296 11.142Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="m283.268 275.052 4.02-68.993 11.506-6.627 3.77 64.478-19.296 11.142Z"
      fill="#fff"
    />
    <path
      opacity={0.05}
      d="m283.268 275.052 4.02-68.993 11.506-6.627 3.77 64.478-19.296 11.142Z"
      fill="#000"
    />
    <path
      d="m339.226 222.779 3.77 64.471-40.432-23.34-3.77-64.478 40.432 23.347Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m339.226 222.779 3.77 64.471-40.432-23.34-3.77-64.478 40.432 23.347Z"
      fill="#fff"
    />
    <path
      opacity={0.05}
      d="m339.226 222.779 3.77 64.471-40.432-23.34-3.77-64.478 40.432 23.347Z"
      fill="#000"
    />
    <path
      d="m339.226 222.779-11.514 6.62-4.013 69 19.297-11.149-3.77-64.471Z"
      fill="#16796F"
    />
    <path
      opacity={0.55}
      d="m339.226 222.779-11.514 6.62-4.013 69 19.297-11.149-3.77-64.471Z"
      fill="#fff"
    />
    <path
      opacity={0.05}
      d="m323.699 298.398 8.292-11.65 1.041-40.668 1.034 39.961 8.93 1.208-19.297 11.149Z"
      fill="#000"
    />
    <path
      d="m283.268 275.051 40.432 23.348 4.012-69.001-40.432-23.339-4.012 68.992Zm30.97-46.542c0-.927.646-1.307 1.444-.844a3.224 3.224 0 0 1 1.444 2.501c0 .919-.654 1.299-1.444.851a3.172 3.172 0 0 1-1.444-2.508Zm-14.334-8.36c0-.935.646-1.307 1.444-.851a3.19 3.19 0 0 1 1.444 2.5c0 .92-.646 1.3-1.444.851a3.16 3.16 0 0 1-1.444-2.47v-.03Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="m283.268 275.051 40.432 23.348 4.012-69.001-40.432-23.339-4.012 68.992Zm30.97-46.542c0-.927.646-1.307 1.444-.844a3.224 3.224 0 0 1 1.444 2.501c0 .919-.654 1.299-1.444.851a3.172 3.172 0 0 1-1.444-2.508Zm-14.334-8.36c0-.935.646-1.307 1.444-.851a3.19 3.19 0 0 1 1.444 2.5c0 .92-.646 1.3-1.444.851a3.16 3.16 0 0 1-1.444-2.47v-.03Z"
      fill="#fff"
    />
    <path
      d="M303.803 199.318c1.459-.836 3.245-.76 5.039.304 4.126 2.386 7.486 9.272 7.486 15.367v14.349a.665.665 0 0 1-.384.667.659.659 0 0 1-.533 0 .663.663 0 0 1-.383-.667v-14.349c0-5.677-3.04-12.061-6.84-14.243-1.391-.798-2.683-.904-3.739-.296-1.52.897-2.447 3.184-2.447 6.262V221a.654.654 0 1 1-1.308 0v-14.28c0-3.564 1.133-6.262 3.109-7.402Z"
      fill="#455A64"
    />
    <path
      d="M281.443 217.481a4.285 4.285 0 0 1 4.4.259c3.602 2.082 6.536 8.094 6.536 13.414v12.525a.57.57 0 0 1-1.14 0v-12.525c0-4.955-2.675-10.526-5.966-12.426a3.184 3.184 0 0 0-3.26-.266c-1.361.76-2.144 2.781-2.144 5.472v12.464a.57.57 0 0 1-1.14 0v-12.464c0-3.109.988-5.465 2.714-6.453Z"
      fill="#37474F"
    />
    <path
      d="m253.46 289.301 3.504-60.215 10.047-5.783 3.291 56.27-16.842 9.728Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="m253.46 289.301 3.504-60.215 10.047-5.783 3.291 56.27-16.842 9.728Z"
      fill="#000"
    />
    <path
      d="m302.29 243.678 3.298 56.271-35.286-20.376-3.291-56.27 35.279 20.375Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m302.29 243.678 3.298 56.271-35.286-20.376-3.291-56.27 35.279 20.375Z"
      fill="#000"
    />
    <path
      d="m302.29 243.678-10.04 5.776-3.511 60.215 16.849-9.721-3.298-56.27Z"
      fill="#16796F"
    />
    <path
      opacity={0.15}
      d="m302.29 243.678-10.04 5.776-3.511 60.215 16.849-9.721-3.298-56.27Z"
      fill="#000"
    />
    <path
      opacity={0.15}
      d="m288.739 309.669 7.243-10.161.904-35.492.905 34.876 7.797 1.056-16.849 9.721Z"
      fill="#000"
    />
    <path
      d="m253.46 289.301 35.279 20.368 3.511-60.215-35.286-20.368-3.504 60.215Zm27.026-40.615c0-.813.562-1.147 1.261-.76a2.793 2.793 0 0 1 1.254 2.182c0 .805-.562 1.14-1.254.76a2.76 2.76 0 0 1-1.261-2.182Zm-12.51-7.28c0-.806.562-1.14 1.262-.76a2.774 2.774 0 0 1 1.261 2.181c0 .805-.562 1.132-1.261.76a2.751 2.751 0 0 1-1.262-2.181Z"
      fill="#16796F"
    />
    <path
      d="M271.374 223.204a4.305 4.305 0 0 1 4.4.266c3.61 2.075 6.544 8.094 6.544 13.406v12.525a.57.57 0 0 1-1.14 0v-12.525c0-4.947-2.683-10.526-5.974-12.426a3.176 3.176 0 0 0-3.26-.258c-1.353.76-2.136 2.774-2.136 5.464v12.472a.57.57 0 0 1-1.14 0v-12.472c0-3.108.988-5.456 2.706-6.452Z"
      fill="#455A64"
    />
    <path
      d="m148.071 169.77-19-5.153-3.23 13.171 18.483 4.697 6.156-4.644-2.409-8.071Zm-1.52 8.079a1.376 1.376 0 0 1-1.283-.849 1.378 1.378 0 0 1 .294-1.51 1.388 1.388 0 0 1 2.131.206c.152.228.233.496.233.77a1.374 1.374 0 0 1-1.345 1.375l-.03.008Z"
      fill="#16796F"
    />
    <path
      d="M165.877 187.273c-5.32 0-8.474-2.987-11.233-5.624-2.226-2.128-4.324-4.134-7.311-4.476a.403.403 0 0 1-.349-.441.407.407 0 0 1 .448-.357c3.253.38 5.442 2.477 7.76 4.696 2.834 2.706 5.768 5.533 10.974 5.404 7.691-.16 12.092-10.769 12.16-10.876a.398.398 0 0 1 .212-.218.397.397 0 0 1 .523.215.399.399 0 0 1 .002.307c-.182.456-4.643 11.195-12.851 11.4l-.335-.03ZM138.32 169.617c.337.149.65.349.927.592.235.204.437.442.6.707.141.234.239.492.289.76.041.216.041.438 0 .654a.29.29 0 0 1-.144.213.384.384 0 0 1-.259 0l-.821-.213a.496.496 0 0 1-.288-.152.751.751 0 0 1-.13-.251 1.512 1.512 0 0 0-.41-.646 2.113 2.113 0 0 0-1.011-.486 2.342 2.342 0 0 0-.6-.084c-.18-.009-.36.014-.532.068a.99.99 0 0 0-.403.221.75.75 0 0 0-.22.372.693.693 0 0 0 0 .471c.076.163.194.301.342.403.218.155.446.295.684.418l1.048.525c.387.186.756.407 1.102.661.267.191.499.428.684.699.153.228.249.488.282.76a2.66 2.66 0 0 1-.084.897c-.032.31-.133.609-.296.874-.191.24-.426.441-.692.593-.298.162-.622.27-.958.319a3.955 3.955 0 0 1-1.17 0l-.198.805a.332.332 0 0 1-.402.236l-.958-.236a.334.334 0 0 1-.236-.402l.198-.806a4.806 4.806 0 0 1-.927-.524 3.3 3.3 0 0 1-.692-.684 2.286 2.286 0 0 1-.395-.798 1.828 1.828 0 0 1 0-.882.277.277 0 0 1 .144-.205c.081-.03.17-.03.251 0l.829.205a.601.601 0 0 1 .288.152.516.516 0 0 1 .122.258c.034.123.077.242.129.358.056.132.138.251.243.349.135.129.289.237.456.319.241.122.497.211.76.266.23.059.464.099.7.122.206.028.416.028.623 0 .173-.037.336-.109.479-.213a.759.759 0 0 0 .273-.41.62.62 0 0 0-.061-.471 1.416 1.416 0 0 0-.433-.411 13.166 13.166 0 0 0-.76-.433 25.336 25.336 0 0 1-1.079-.555 6.695 6.695 0 0 1-.95-.6 2.587 2.587 0 0 1-.608-.661 1.74 1.74 0 0 1-.266-.76 2.666 2.666 0 0 1-.03-1.011c.069-.293.201-.568.387-.806a2.33 2.33 0 0 1 .669-.577 2.57 2.57 0 0 1 .882-.335 3.834 3.834 0 0 1 1.026-.061l.205-.805a.31.31 0 0 1 .152-.198.292.292 0 0 1 .117-.044.298.298 0 0 1 .126.006l.958.236a.342.342 0 0 1 .249.273.317.317 0 0 1-.006.13l-.205.798Z"
      fill="#455A64"
    />
    <path
      d="m219.443 130.82-38.654-.32.479-58.52a18.528 18.528 0 0 1 11.58-17.045 18.534 18.534 0 0 1 7.108-1.354h3.299a16.823 16.823 0 0 1 15.454 10.508 16.812 16.812 0 0 1 1.228 6.447l-.494 60.284Z"
      fill="#263238"
    />
    <path
      d="M246.673 203.499c-3.192-8.086-9.515-21.417-18.46-39.664l-.874-1.627c-3.838-7.797-7.767-13.436-8.36-18.665l.251-6.08c.463-2.508 1.284-8.702 1.52-12.16 6.528-5.928 4.051-10.899 1.292-15.352a36.725 36.725 0 0 0-3.542-4.355c-1.14-8.892-6.505-9.378-8.238-9.325l-12.92.365c-6.149.912-13.285 1.717-14.782 2.044-2.865.638-3.58 3.694-3.686 7.737-.107 4.423.57 6.916 3.724 12.502L188.123 130l.669 6.9s-3.549 5.092-5.693 8.528c-9.484 13.748-7.539 22.86-5.084 48.784 1.178 12.365 2.774 26.6 2.774 26.6s-5.001 7.106-3.8 21.356c.54 6.293 3.04 26.866 4.431 35.082.296 2.28.479 4.56.562 6.672 0 .57.091 1.087.091 1.642v1.383s9.455 7.494 9.455-2.713v-1.444l.418-6.08c.562-7.159 2.508-20.626 3.45-29.161 1.611-14.547 3.952-23.469 4.811-31.016.844-7.379 2.903-26.554 4.005-36.829l23.34 31.578a59.405 59.405 0 0 0-5.503 10.244c-2.409 5.716-4.02 11.796-7.09 24.457-1.315 5.419-2.364 9.721-4.173 15.124-1.52 4.028 6.787 6.247 6.787 6.247 4.727-7.402 11.636-19.927 17.48-30.81 5.046-9.363 9.226-19.281 10.176-21.485 2.06-4.712 2.873-7.273 1.444-11.56Z"
      fill="#B16668"
    />
    <path
      d="M210.314 96.3c6.134-.312 7.479 0 15.048 10.457 7.327 10.116 14.167 18.514 14.167 18.514l16.591-19.289 7.326 5.571s-14.607 22.002-17.86 26.129c-2.326 2.956-4.856 5.228-11.499-.487-5.031-4.332-21.234-21.28-21.234-21.28l-2.539-19.616Z"
      fill="#B16668"
    />
    <path
      d="M256.12 105.982c3.261-4.058 5.981-5.365 9.325-5.654 3.344-.289 3.147-9.485 6.483-8.124 3.337 1.36 3.481 2.91 4.188 5.32.707 2.409 2.759 8.565-2.045 10.556-4.803 1.991-10.024 2.645-13.497 7.813-3.473 5.168-4.454-9.911-4.454-9.911Z"
      fill="#B16668"
    />
    <path
      d="m181.716 303.696 1.026-.213c-.137-3.253-.502-6.209.205-7.79l-1.718-3.853.487 11.856ZM178.258 289.074c.372 2.212 1.778 4.416 2.143 8.793.218 1.864.294 3.742.228 5.617l1.087.213-.487-11.856-2.971-2.767Z"
      fill="#263238"
    />
    <path
      d="M204.546 305.225a2.951 2.951 0 0 1-.608 1.672c-.532.456-2.227 2.007-7.957 2.022-5.731.015-7.821-1.269-9.88-2.926-2.06-1.657-2.865-6.308-3.291-8.497-.836-4.241-2.751-6.369-4.56-8.421a3.798 3.798 0 0 1-.304-2.204s4.704-.555 7.076 4.56c2.371 5.115 4.347 11.081 9.059 12.244a72.746 72.746 0 0 0 10.465 1.55Z"
      fill="#263238"
    />
    <path
      d="M201.346 300.437c1.84 1.398 3.2 1.983 3.139 3.397-.061 1.414-1.52 2.128-2.675 2.554-2.751 1.018-5.951.866-8.786.539-2.834-.327-6.08-1.451-7.425-4.058a10.886 10.886 0 0 1-.828-2.425c-.684-2.728-1.33-5.517-2.653-8.018a18.06 18.06 0 0 0-1.763-3.04c-.805-1.01-1.771-2.112-1.991-3.442-.182-1.102.578-2.721 1.588-4.469v-.038a9.674 9.674 0 0 0 1.194-6.301l-1.574-11.4 13.452 1.437-1.117 13.224c-.319 2.979.76 6.118 1.596 8.922a40.338 40.338 0 0 0 3.8 8.953 8.712 8.712 0 0 0 1.444 1.991 25.21 25.21 0 0 0 2.599 2.174Z"
      fill="#B16668"
    />
    <path
      d="M178.478 283.595a36.12 36.12 0 0 0 1.992-3.177 9.306 9.306 0 0 1 1.474 2.28c1.619 2.774 2.174 7.243 3.8 11.62 1.239 3.284 3.093 4.948 5.54 5.716a9.558 9.558 0 0 0 4.743.235c2.941-.593 2.113-2.69 2.113-2.69.76.904 4.081 3.199 5.912 4.309 1.163.707 1.102 2.364.441 3.344-.76.76-3.488 2.675-10.64 2.082-6.353-.524-8.003-3.336-8.687-4.613a31.232 31.232 0 0 1-1.672-5.829 18.694 18.694 0 0 0-2.591-6.133 29.483 29.483 0 0 0-.456-.639 27.608 27.608 0 0 0-1.619-1.907c-.471-.502-.828-.76-.996-1.231a4.458 4.458 0 0 1 .646-3.367Z"
      fill="#37474F"
    />
    <path
      d="m201.271 283.002 1.048.19c1.14-3.116 2.607-7.509 4.652-7.844l-1.52-3.746-4.18 11.4Z"
      fill="#263238"
    />
    <path
      d="M203.292 267.316c-.509 2.227.373 5.412-.995 9.675a38.974 38.974 0 0 1-1.961 5.381l.935.631 4.172-11.4-2.151-4.287Z"
      fill="#263238"
    />
    <path
      d="M223.243 291.91a1.891 1.891 0 0 1-1.118 1.976c-1.276.646-4.772 1.071-8.892-.646-4.56-1.908-5.669-3.474-6.262-6.08-.646-2.873.418-6.582-.213-9.744-.798-4.035-2.28-6.84-3.481-9.933.137-1.132.289-1.786.289-1.786s5.366 1.307 5.609 7.038c.243 5.73-.22 12.16 3.762 15.063 3.982 2.903 10.306 4.112 10.306 4.112Z"
      fill="#263238"
    />
    <path
      d="M221.03 287.569c1.194 2.037 2.189 3.093 1.642 4.416-.426 1.018-2.462.904-4.431.676a10.64 10.64 0 0 1-5.32-2.021 10.237 10.237 0 0 1-4.066-5.685c-.539-2.174-.19-4.423-.129-6.62a12.582 12.582 0 0 0-.243-3.245 22.784 22.784 0 0 0-1.201-2.858 62.46 62.46 0 0 0-1.657-3.891 4.724 4.724 0 0 1-.592-3.405c.258-1.109 1.603-2.34 3.237-3.594a10.695 10.695 0 0 0 3.8-5.617l6.156-22.891 12.92 10.898S222.68 259.449 221 263.204a31.814 31.814 0 0 0-2.227 8.937 41.655 41.655 0 0 0 .114 9.918c.083.834.28 1.652.586 2.432.446 1.062.966 2.09 1.557 3.078Z"
      fill="#B16668"
    />
    <path
      d="M217.193 293.179a14.96 14.96 0 0 1-5.913-2.204c-1.307-.897-2.614-2.052-3.04-3.588-.509-1.74-.236-3.374-.198-4.932a28.887 28.887 0 0 0-.456-6.384 22.392 22.392 0 0 0-1.558-4.849c-.456-1.003-.904-2.014-1.36-3.04a11.287 11.287 0 0 1-1.079-2.531 4.015 4.015 0 0 1 1.983-3.214c1.368-.905 2.6-1.087 3.603-1.847.197.585.292 1.199.281 1.816.654 4.408 1.14 8.854 2.47 13.133a37.115 37.115 0 0 0 2.516 6.08c1.337 2.561 4.24 3.412 4.772 2.052.867 2.348 2.41 4.317 3.884 6.316 1.018 1.383.479 2.394-.935 2.933a10.508 10.508 0 0 1-4.97.259Z"
      fill="#37474F"
    />
    <path
      d="M211.987 61.371c2.143 1.2 4.886 6.452 4.894 17.602 0 9.447-2.994 11.856-4.514 12.563-1.52.706-4.5.372-7.395 0l.137 5.67s8.177 8.511 1.573 9.514c-3.299.502-7.517-1.52-10.382-3.64a11.595 11.595 0 0 1-3.8-5.867l-.228-12.328s-1.657 1.688-4.56-.76c-2.424-2.013-3.306-5.494-1.52-7.417a4.139 4.139 0 0 1 5.38-.35c.523.395.946.908 1.232 1.498 0 0 1.687.92 3.306-2.28a20.822 20.822 0 0 0 8.93-2.41 12.747 12.747 0 0 0 6.947-11.795Z"
      fill="#B16668"
    />
    <path
      d="M204.607 76.298a1.184 1.184 0 0 1-2.332.334 1.188 1.188 0 0 1 .42-1.236c.183-.142.404-.228.635-.246a1.208 1.208 0 0 1 1.277 1.148Z"
      fill="#263238"
    />
    <path
      d="m206.256 84.603 3.086.905a1.575 1.575 0 0 1-1.961 1.148 1.686 1.686 0 0 1-1.125-2.053Z"
      fill="#9A4A4D"
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.5}
    />
    <path
      d="m215.255 72.345-2.455-1.612a1.4 1.4 0 0 1 1.459-.666 1.4 1.4 0 0 1 .524.21 1.518 1.518 0 0 1 .472 2.068ZM203.27 71.441l-2.706 1.117a1.414 1.414 0 0 1-.03-1.114 1.4 1.4 0 0 1 .79-.785 1.522 1.522 0 0 1 1.946.782ZM214.685 75.795a1.204 1.204 0 0 1-.642 1.181c-.14.074-.294.12-.452.133a1.225 1.225 0 0 1-1.277-1.147 1.195 1.195 0 0 1 1.581-1.312 1.19 1.19 0 0 1 .79 1.145Z"
      fill="#263238"
    />
    <path
      d="m208.97 75.248.357 7.144 3.739-1.254-4.096-5.89ZM204.972 91.498c-3.04-.327-9.333-1.969-10.367-4.416a6.44 6.44 0 0 0 2.28 3.1c1.908 1.52 8.148 3.208 8.148 3.208l-.061-1.892Z"
      fill="#9A4A4D"
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.5}
    />
    <path
      d="m192.781 77.817.114 4.97a3.383 3.383 0 0 0 3.299-3.458l-.107-4.56-3.306 3.048Z"
      fill="#263238"
    />
    <path
      d="M234.429 176.001c-3.389-6.962-6.931-14.144-8.922-18.194-1.991-4.051-5.221-10.777-6.361-14.114.083-1.52.251-3.336.228-5.973.6-3.686 1.147-7.752 1.52-12.388 7.037-4.857 3.724-12.16 2.105-14.577-2.964-4.423-11.712-12.996-13.68-14.44a17.647 17.647 0 0 0-4.18.106s4.78 4.135 9.515 13.87c2.744 5.647 1.209 11.902 1.209 11.902a111.486 111.486 0 0 0-11.887-12.57c-4.279-3.8-12.867-11.461-14.63-11.872a20.907 20.907 0 0 0-4.203.555c3.884 3.557 7.859 14.949 7.312 20.186-.548 5.236-3.04 6.954-5.944 8.048l1.467 3.42.927 6.947a117.655 117.655 0 0 0-8.071 11.628c-5.829 9.439-7.661 18.141-6.787 70.459 3.215.342 6.643.669 10.238.874l3.868-43.236 6.946 43.419a79.281 79.281 0 0 0 29.861-6.354c14.402-6.323 18.635-13.148 19.957-16.294-3.085-5.883-7.417-15.086-10.488-21.402Z"
      fill="#16796F"
    />
    <path
      opacity={0.1}
      d="M195.745 122.977a10.069 10.069 0 0 0 8.991 6.224 10.637 10.637 0 0 0 9.584-4.689s-1.004 7.227-9.744 6.49c-9.256-.783-8.831-8.025-8.831-8.025Z"
      fill="#000"
    />
    <path
      d="M188.837 158.902a21.058 21.058 0 0 1-5.776-3.458c1.25.151 2.513.151 3.762 0a2.28 2.28 0 0 0 2.045-2.28c-.069-.486-1.262-.235-3.162-.828-2.424-.76-3.967-1.619-6.711-1.771-1.026-.053-1.854-.851-2.606-1.52-.753-.669-4.203-6.779-7.319-13.399l14.181-19c5.085-2.28 6.164-10.822 1.847-18.24-10.359 1.125-9.029 2.28-28.31 31.768-3.306 5.138-3.177 7.6 9.014 22.663a107.868 107.868 0 0 0 8.36 9.212c7.037 6.566 11.856 5.32 15.078 4.18 6.164-2.311 4.393-5.396-.403-7.327Z"
      fill="#B16668"
    />
    <path
      d="m288.755 161.539-2.987-47.75-8.527-4.91-.829 46.573 12.343 6.087Z"
      fill="#37474F"
    />
    <path
      d="m247.251 126.192-2.797 44.399 31.958-15.139.829-46.573-29.99 17.313Z"
      fill="#263238"
    />
    <path
      d="m247.251 126.191 8.535 4.91 2.979 47.751-14.311-8.261 2.797-44.4Z"
      fill="#37474F"
    />
    <path
      d="m258.765 178.853-6.148-8.634-.776-26.744-.767 26.22-6.62.896 14.311 8.262Z"
      fill="#263238"
    />
    <path
      d="m285.761 113.789-29.975 17.313 2.979 47.751 29.99-17.313-2.994-47.751Zm-21.052 18.514c-.593.334-1.072.053-1.072-.631a2.357 2.357 0 0 1 1.072-1.855c.592-.342 1.071-.06 1.071.631a2.365 2.365 0 0 1-1.064 1.855h-.007Zm10.64-6.187c-.593.335-1.072.053-1.072-.631a2.375 2.375 0 0 1 1.072-1.854c.592-.342 1.071-.061 1.071.631a2.331 2.331 0 0 1-1.079 1.854h.008Z"
      fill="#455A64"
    />
    <path
      d="M264.716 131.397a.342.342 0 0 1-.342-.342v-25.916c0-3.04.304-6.133 2.592-6.133h.235c-.164.26-.355.502-.57.722-1.087.273-1.573 1.976-1.573 5.411v25.916a.348.348 0 0 1-.1.242.343.343 0 0 1-.242.1Z"
      fill="#E6E6E6"
    />
    <path
      d="M256.728 125.211h-.076a.34.34 0 0 1-.25-.41c0-.137 3.176-13.353 4.111-18.103.859-4.37 1.695-7.243 5.32-7.243a.341.341 0 1 1 0 .684c-3.04 0-3.8 2.143-4.659 6.696-.942 4.757-4.088 17.989-4.119 18.126a.33.33 0 0 1-.12.181.329.329 0 0 1-.207.069ZM275.341 125.21a.34.34 0 0 1-.335-.281l-2.781-16.196.646-.243 2.804 16.325a.344.344 0 0 1-.053.256.336.336 0 0 1-.221.139h-.06Z"
      fill="#E6E6E6"
    />
    <path
      d="M138.023 86.571v-9.325l7.866-.684a.576.576 0 0 1 .327.06l6.741 3.877a.589.589 0 0 1 .183.174.589.589 0 0 1 .071.488.583.583 0 0 1-.124.22l-8.36 9.043-6.704-3.853Z"
      fill="#16796F"
    />
    <path
      d="m144.742 90.44 8.36-9.043a.574.574 0 0 0 .093-.643.577.577 0 0 0-.564-.323l-7.874.692-.015 9.318Z"
      fill="#16796F"
    />
    <path
      d="m138.023 77.246 6.719 3.86 7.873-.69a.646.646 0 0 1 .274.045c-.631-.373-6.384-3.679-6.673-3.838a.576.576 0 0 0-.327-.061l-7.866.684Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="m138.023 77.246 6.719 3.86 7.873-.69a.646.646 0 0 1 .274.045c-.631-.373-6.384-3.679-6.673-3.838a.576.576 0 0 0-.327-.061l-7.866.684Z"
      fill="#fff"
    />
    <path
      d="M144.75 69.822a4.311 4.311 0 0 0-1.954-3.383l-1.588-.92a4.317 4.317 0 0 0-3.906 0l-24.389 14.106a4.323 4.323 0 0 0-1.953 3.382v26.828a4.325 4.325 0 0 0 1.953 3.382l1.589.92a4.315 4.315 0 0 0 3.906 0l24.388-14.106a4.311 4.311 0 0 0 1.954-3.382V69.822Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M110.96 109.835a4.32 4.32 0 0 0 1.953 3.382l1.589.919a4.359 4.359 0 0 0 3.572.16c-.92.35-1.619-.175-1.619-1.284V86.176a4.03 4.03 0 0 1 .57-1.923l-5.495-3.169a4.006 4.006 0 0 0-.57 1.923v26.828Z"
      fill="#000"
    />
    <path
      opacity={0.5}
      d="M144.681 69.266c-.228-.828-.981-1.094-1.885-.577l-24.388 14.15a3.84 3.84 0 0 0-1.376 1.452l-5.495-3.169c.327-.595.8-1.098 1.376-1.459l24.381-14.106a4.361 4.361 0 0 1 3.906 0l1.596.92a4.324 4.324 0 0 1 1.885 2.79Z"
      fill="#fff"
    />
    <path
      d="M122.968 92.424c.015-1.514.332-3.01.935-4.4a7.687 7.687 0 0 1 2.812-3.595c1.915-1.132 3.518 1.17 3.8 3.876.228-2.987 1.801-7.152 3.716-8.276a2.098 2.098 0 0 1 2.827.28 5.052 5.052 0 0 1 .958 3.292c0 4.56-2.736 8.732-5.039 13.094l-2.424 4.56-2.463-1.694c-2.325-1.65-5.107-2.554-5.122-7.137Z"
      fill="#FAFAFA"
    />
  </svg>
);
export default SvgProductsEmpty;
