import { FormHelperVariant } from "./types";

export const VARIANT_TO_TEXT_COLOR_CLASS_NAME: Record<
  FormHelperVariant,
  string
> = {
  success: "text-green-500",
  error: "text-red-500",
  info: "text-grey-300",
  warning: "text-yellow-500",
};

export const VARIANT_TO_BORDER_COLOR_CLASS_NAME: Record<
  FormHelperVariant,
  string
> = {
  success: "border-green-500",
  error: "border-red-500",
  info: "border-grey-[normal]",
  warning: "border-yellow-500",
};
