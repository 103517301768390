import { BasePillProps } from "./types";
import BasePill from "./BasePill";
import * as React from "react";

const SuccessPill = ({ className, style, children }: BasePillProps) => {
  return (
    <BasePill
      style={style}
      className={`text-white bg-green-300 ${className || ""}`}
    >
      {children}
    </BasePill>
  );
};

export default SuccessPill;
