import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { Logo } from "../../../ui/atoms/logo";
import { PAGE_ROUTES } from "../../../constants/routes";

export const AppLoader = () => {
  const { getAppAuthToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (getAppAuthToken()) {
      navigate(PAGE_ROUTES.HOME);
    } else {
      navigate(PAGE_ROUTES.LOGIN);
    }
  }, [getAppAuthToken, navigate]);

  return (
    <div className="flex items-center justify-center h-screen">
      <Logo />
    </div>
  );
};
