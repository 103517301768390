import * as React from "react";
import { TableRowProps } from "./types";

const TableRow = ({ children, hasBorder, onClick, hasFooterBg }: TableRowProps) => {
  return (
    <tr
      className={`${hasBorder
          ? "border-b border-grey-100"
          : ""
        } ${hasFooterBg
          ? "bg-grey-50" : ""
        } ${onClick ? "cursor-pointer" : ""}`}
      {...{ onClick }}
    >
      {children}
    </tr>
  );
};

export default TableRow;
