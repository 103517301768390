import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLoyaltyStepTwo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={16} cy={16} r={16} fill="#16796F" fillOpacity={0.1} />
    <path
      d="M9.583 7.75h12.834a1.833 1.833 0 0 1 1.833 1.833v12.834c0 .485-.192.953-.54 1.292-.34.349-.808.541-1.293.541H9.583c-.486 0-.953-.192-1.292-.54a1.802 1.802 0 0 1-.541-1.293V9.583c0-1.017.816-1.833 1.833-1.833ZM16 20.583l1.43-3.135L20.583 16l-3.153-1.43L16 11.417l-1.44 3.153L11.418 16l3.144 1.448L16 20.583Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgLoyaltyStepTwo;
