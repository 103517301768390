import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWhiteDiamond = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m17.279 7.286-7.946 9.782-7.945-9.782 4.72-5.036h6.45l4.72 5.036Zm-5.854-.536-1.266-3.375H8.507L7.241 6.75h4.184ZM7.18 7.875l2.153 7.541 2.154-7.541H7.18Zm-.817-4.5L3.207 6.75h3.015l1.266-3.375H6.363Zm-3.27 4.5 4.764 5.862-1.679-5.862H3.093Zm7.717 5.862 4.763-5.862h-3.085l-1.678 5.862Zm4.65-6.987-3.156-3.375h-1.125l1.266 3.375h3.014Z"
      fill="#fff"
    />
  </svg>
);
export default SvgWhiteDiamond;
