import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBrandLoyalty = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 550 550"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M444.808 90.116a12.815 12.815 0 0 0-.713-1.693c-3.986-8.216-13.879-11.643-22.094-7.657a16.53 16.53 0 0 0-7.603 7.545 16.65 16.65 0 0 0-1.705 7.44c-.102 9.13 7.219 16.616 16.35 16.717 9.131.102 16.616-7.218 16.718-16.35.002-.198 0-.398-.005-.598a16.283 16.283 0 0 0-.948-5.406v.002Z"
      fill="#F0F0F0"
    />
    <path
      d="M429.079 101.806c-2.746 0-5.244-1.389-6.199-3.453a.47.47 0 0 1 .223-.62.459.459 0 0 1 .614.21l.007.013c.825 1.748 2.988 2.92 5.381 2.92 2.392 0 4.557-1.171 5.381-2.92a.465.465 0 0 1 .618-.225h.002c.231.112.33.387.224.62-1.005 2.065-3.503 3.454-6.249 3.454l-.002.001ZM435.279 91.826a1.47 1.47 0 1 1 0 2.94 1.47 1.47 0 0 1 0-2.94ZM424.349 93.296a1.469 1.469 0 1 1-2.938.001 1.469 1.469 0 0 1 2.938-.001Z"
      fill="#E0E0E0"
    />
    <path
      d="M488.312 343.406H289.491a6.456 6.456 0 0 1-6.448-6.448V47.356a6.456 6.456 0 0 1 6.448-6.448h198.821a6.455 6.455 0 0 1 6.448 6.448v289.601a6.455 6.455 0 0 1-6.448 6.448v.001ZM289.491 41.183a6.18 6.18 0 0 0-6.173 6.173v289.601a6.18 6.18 0 0 0 6.173 6.173h198.821a6.18 6.18 0 0 0 6.173-6.173v-289.6a6.18 6.18 0 0 0-6.173-6.174H289.491ZM259.042 343.406H60.221a6.456 6.456 0 0 1-6.448-6.448V47.356a6.455 6.455 0 0 1 6.448-6.448h198.821a6.455 6.455 0 0 1 6.449 6.448v289.601a6.455 6.455 0 0 1-6.449 6.448v.001ZM60.221 41.183a6.18 6.18 0 0 0-6.173 6.173v289.601a6.18 6.18 0 0 0 6.173 6.173h198.821a6.18 6.18 0 0 0 6.174-6.173v-289.6a6.181 6.181 0 0 0-6.174-6.174H60.221ZM550 410.582H0v.275h550v-.275ZM74.505 426.273H56.839v.275h17.666v-.275ZM246.27 423.293h-58.883v.275h58.883v-.275ZM115.48 436.492H75.606v.275h39.874v-.275ZM463.697 423.148h-47.509v.275h47.509v-.275ZM480.46 423.148h-6.963v.275h6.963v-.275ZM407.112 430.002h-59.279v.275h59.279v-.275Z"
      fill="#E0E0E0"
    />
    <path
      d="M349.515 248.688c6.129-5.73 6.452-15.344.722-21.473-5.729-6.129-15.343-6.453-21.472-.723-6.129 5.73-6.453 15.344-.723 21.473s15.344 6.453 21.473.723Z"
      fill="#E0E0E0"
    />
    <path
      d="M330.369 248.131c.292-.627 3.035-5.433 9.152-5.434 6.786 0 9.245 4.823 9.245 4.823s-3.831 3.442-9.015 3.707c-5.025.257-9.382-3.097-9.382-3.097v.001ZM345.44 235.134a6.03 6.03 0 1 1-12.06 0 6.03 6.03 0 0 1 12.06 0Z"
      fill="#F5F5F5"
    />
    <path
      d="M76.22 383.404c1.343-8.282-4.281-16.085-12.563-17.429-8.282-1.344-16.086 4.28-17.43 12.562-1.344 8.282 4.28 16.086 12.563 17.43 8.282 1.343 16.085-4.281 17.43-12.563Z"
      fill="#E0E0E0"
    />
    <path
      d="M52.452 391.512c.292-.628 3.035-5.433 9.152-5.434 6.786 0 9.245 4.823 9.245 4.823s-3.83 3.441-9.015 3.707c-5.025.257-9.382-3.096-9.382-3.096ZM67.523 378.515a6.03 6.03 0 0 1-12.06 0 6.03 6.03 0 1 1 12.06 0Z"
      fill="#F5F5F5"
    />
    <path
      d="M352.592 437.989c14.681 0 26.582-11.901 26.582-26.581 0-14.681-11.901-26.582-26.582-26.582-14.68 0-26.581 11.901-26.581 26.582 0 14.68 11.901 26.581 26.581 26.581Z"
      fill="#E0E0E0"
    />
    <path
      d="M337.246 429.846c.509-1.098 5.31-9.505 16.013-9.506 11.872-.001 16.174 8.437 16.174 8.437s-6.701 6.021-15.772 6.487c-8.791.451-16.415-5.418-16.415-5.418ZM353.066 417.655c5.826 0 10.55-4.724 10.55-10.55 0-5.827-4.724-10.55-10.55-10.55-5.827 0-10.55 4.723-10.55 10.55 0 5.826 4.723 10.55 10.55 10.55Z"
      fill="#F5F5F5"
    />
    <path
      d="M324.735 86.062c5.73-5.73 5.73-15.022 0-20.752-5.731-5.731-15.022-5.731-20.752 0-5.731 5.73-5.731 15.021 0 20.752 5.73 5.73 15.021 5.73 20.752 0Z"
      fill="#E0E0E0"
    />
    <path
      d="M305.887 85.865c.281-.606 2.93-5.248 8.839-5.248 6.555 0 8.929 4.658 8.929 4.658s-3.699 3.324-8.707 3.58c-4.853.249-9.061-2.99-9.061-2.99ZM320.444 73.31a5.825 5.825 0 1 1-11.65 0 5.825 5.825 0 0 1 11.65 0Z"
      fill="#F5F5F5"
    />
    <path
      d="M484.679 308.63a24.585 24.585 0 0 0-1.31-3.083c-7.267-14.957-25.283-21.19-40.239-13.923-14.957 7.267-21.19 25.284-13.923 40.239 7.267 14.957 25.282 21.189 40.239 13.923a30.107 30.107 0 0 0 16.949-27.309 29.644 29.644 0 0 0-1.716-9.848v.001Z"
      fill="#E0E0E0"
    />
    <path
      d="M476.384 310.038c-1.846-2.874-5.242-1.686-6.968.61-1.809-2.226-5.26-3.271-6.984-.33-2.922 4.967 3.393 9.383 6.357 11.098l.851.487.827-.522c2.906-1.833 9.035-6.495 5.918-11.342l-.001-.001ZM450.444 310.526c-1.808-2.9-5.219-1.767-6.974.498-1.783-2.245-5.213-3.351-6.991-.439-2.993 4.922 3.244 9.439 6.2 11.203l.829.499.844-.51c2.924-1.785 9.136-6.36 6.092-11.251Z"
      fill="#FAFAFA"
    />
    <path
      d="M454.747 335.517c-5.725-1.314-7.781-7.833-8.052-11.381.01-.463.428-.831.933-.822.443.008.816.306.886.707 0 .104.858 9.872 8.109 10.099 7.25.228 8.436-10.171 8.448-10.285.047-.455.488-.788.984-.745h.009c.492.037.857.432.816.882l-.003.028c0 .497-1.377 12.075-10.311 11.786a9.204 9.204 0 0 1-1.819-.27v.001Z"
      fill="#EBEBEB"
    />
    <path
      d="M475.939 322.646c-.588.598-1.287.881-1.559.609-.27-.27 0-.971.564-1.581.565-.609 1.276-.869 1.559-.609.283.26.022.982-.564 1.581ZM478.422 322.939c-.588.609-1.287.881-1.559.609-.272-.271 0-.971.564-1.569.565-.599 1.276-.881 1.548-.61.271.271.045.972-.553 1.57ZM438.071 323.051c.643.542 1.366.734 1.604.44.237-.294-.08-.96-.723-1.502-.644-.542-1.366-.733-1.615-.44-.249.294.079.972.734 1.502ZM435.62 323.617c.643.542 1.366.734 1.615.44.248-.294-.08-.971-.734-1.501-.655-.532-1.366-.734-1.604-.44-.237.293.079.96.723 1.501Z"
      fill="#F5F5F5"
    />
    <path
      d="M102.901 101.325a24.693 24.693 0 0 0-1.31-3.083c-7.267-14.957-25.282-21.19-40.24-13.923-14.956 7.268-21.19 25.283-13.922 40.239 7.267 14.957 25.284 21.19 40.24 13.923a30.106 30.106 0 0 0 16.948-27.308 29.64 29.64 0 0 0-1.716-9.848Z"
      fill="#EBEBEB"
    />
    <path
      d="M94.605 102.733c-1.846-2.873-5.242-1.685-6.968.61-1.809-2.226-5.26-3.271-6.983-.33-2.924 4.967 3.392 9.383 6.356 11.098l.85.487.828-.523c2.906-1.833 9.034-6.494 5.917-11.342ZM68.664 103.221c-1.807-2.9-5.218-1.767-6.974.498-1.783-2.245-5.212-3.35-6.99-.439-2.993 4.923 3.244 9.439 6.2 11.204l.829.498.844-.509c2.923-1.786 9.136-6.36 6.091-11.252Z"
      fill="#FAFAFA"
    />
    <path
      d="M72.968 128.213c-5.725-1.315-7.781-7.833-8.052-11.382.01-.463.428-.83.933-.822.443.008.816.306.885.708 0 .103.858 9.871 8.11 10.099 7.25.227 8.435-10.172 8.448-10.285.047-.456.488-.789.984-.746h.009c.492.037.857.432.816.882l-.003.029c0 .496-1.377 12.076-10.312 11.785a9.196 9.196 0 0 1-1.818-.268Z"
      fill="#E0E0E0"
    />
    <path
      d="M94.159 115.34c-.587.598-1.287.881-1.559.609-.271-.272 0-.971.565-1.581.564-.609 1.276-.87 1.558-.609.283.259.022.982-.564 1.581ZM96.644 115.633c-.588.611-1.287.881-1.559.611-.27-.272 0-.972.565-1.57.564-.598 1.275-.881 1.547-.609.272.271.045.97-.553 1.568ZM56.291 115.748c.644.543 1.367.734 1.604.44.238-.293-.079-.96-.723-1.501-.643-.543-1.366-.734-1.614-.44-.249.293.079.97.733 1.501ZM53.842 116.312c.643.543 1.366.734 1.614.44.249-.293-.079-.971-.733-1.501-.656-.531-1.367-.734-1.604-.44-.238.294.079.96.723 1.501ZM509.956 484.179c0 3.725-102.868 6.745-229.763 6.745-126.895 0-229.762-3.021-229.762-6.745 0-3.725 102.867-6.745 229.762-6.745s229.763 3.02 229.763 6.745Z"
      fill="#F5F5F5"
    />
    <path
      d="m463.669 457.18-4.736 25.985h-28.021l-5.02-25.985h37.777Z"
      fill="#263238"
    />
    <path
      d="M441.664 454.546c-.785-9.882-6.208-19.878-14.932-26.664-2.179-1.69-6.755-5.039-3.045-6.808 4.239-2.024 12.37 7.427 13.509 9.175-2.406-7.53-9.698-12.982-17.61-16.106-3.911-1.544-7.639-3.008-3.473-5.301 4.165-2.294 13.181 3.269 15.269 5.657-4.941-5.512-11.185-11.031-17.585-14.33-7.064-3.641-3.874-9.713 3.409-5.017 4.512 2.913 11.933 10.194 11.933 10.194s-11.659-18.705-8.14-20.387c4.558-2.185 12.543 23.3 14.749 28.325-.593-4.166-1.149-14.243 2.734-14.68 3.547-.393 2.68 24.698 3.501 29.126a76.803 76.803 0 0 1 1.167 4.856c.383-3.341 2.734-12.152 6.253-11.133 2.99.859 1.14 7.456.364 10.588-1.877 7.58-3.774 14.913-3.791 22.688l-4.312-.182v-.001Z"
      fill="#16796F"
    />
    <path
      d="M438.749 455.889c2.836-5.038 4.294-10.476 5.855-16.069.646-2.31 1.864-7.255 4.113-6.716 2.65.629.945 7.19-.031 9.493a56.333 56.333 0 0 1 2.534-2.715c2.151-2.564 10.78-19.116 12.93-17.563 2.353 1.705-1.697 8.021-3.606 10.499 3.267-2.444 17.767-16.008 19.916-12.925 1.661 2.377-12.733 10.21-12.733 10.21s7.467-1.993 11.454-2.226c6.432-.371 6.272 4.726.368 4.495-5.348-.209-11.409 1.075-16.624 2.831 2.225-.781 10.096-1.078 11.95 1.932 1.856 3.01-1.092 2.592-4.189 2.158-6.263-.876-12.978-.018-17.293 3.971 1.378-.713 10.099-3.851 12.1-.989 1.753 2.504-2.434 2.995-4.462 3.29-8.129 1.196-15.3 5.679-19.425 11.785l-2.857-1.461Z"
      fill="#16796F"
    />
    <path
      d="M448.077 458.183c.912-14.676 7.357-29.526 17.711-39.599 2.58-2.507 8.004-7.484 3.647-10.109-5.023-2.999-14.677 11.03-16.016 13.619 2.843-11.176 11.503-19.271 20.883-23.911 4.64-2.296 9.061-4.457 4.121-7.867-4.942-3.409-15.643 4.859-18.104 8.395 5.861-8.203 13.263-16.408 20.847-21.276 8.378-5.396 4.557-14.421-4.038-7.448-5.405 4.321-14.193 15.104-14.193 15.104s13.819-27.766 9.644-30.318c-5.36-3.281-14.867 34.639-17.483 42.051.693-6.181 1.349-21.149-3.3-21.796-4.202-.583-3.172 36.663-4.148 43.246-.619 2.871-.912 4.312-1.376 7.21-.465-4.958-3.228-18.04-7.43-16.526-3.536 1.276-1.348 11.066-.427 15.716 2.233 11.248 4.484 22.142 4.493 33.683l5.169-.174Z"
      fill="#16796F"
    />
    <path
      d="M448.077 458.183c.912-14.676 7.357-29.526 17.711-39.599 2.58-2.507 8.004-7.484 3.647-10.109-5.023-2.999-14.677 11.03-16.016 13.619 2.843-11.176 11.503-19.271 20.883-23.911 4.64-2.296 9.061-4.457 4.121-7.867-4.942-3.409-15.643 4.859-18.104 8.395 5.861-8.203 13.263-16.408 20.847-21.276 8.378-5.396 4.557-14.421-4.038-7.448-5.405 4.321-14.193 15.104-14.193 15.104s13.819-27.766 9.644-30.318c-5.36-3.281-14.867 34.639-17.483 42.051.693-6.181 1.349-21.149-3.3-21.796-4.202-.583-3.172 36.663-4.148 43.246-.619 2.871-.912 4.312-1.376 7.21-.465-4.958-3.228-18.04-7.43-16.526-3.536 1.276-1.348 11.066-.427 15.716 2.233 11.248 4.484 22.142 4.493 33.683l5.169-.174Z"
      fill="#16796F"
    />
    <path d="M421.996 458.355h45.058v-8.67h-45.058v8.67Z" fill="#263238" />
    <path
      d="M436.602 275.001c22.909 0 41.48-18.571 41.48-41.48 0-22.909-18.571-41.48-41.48-41.48-22.909 0-41.48 18.571-41.48 41.48 0 22.909 18.571 41.48 41.48 41.48Z"
      fill="#16796F"
    />
    <path
      d="M478.835 253.446a.551.551 0 0 1-.5-.779c5.843-12.825 4.636-29.789-2.936-41.252a.548.548 0 0 1 .156-.761.548.548 0 0 1 .761.156c7.771 11.762 9.011 29.163 3.02 42.315a.552.552 0 0 1-.501.321Z"
      fill="#16796F"
    />
    <path
      d="M461.335 248.008a9.729 9.729 0 0 1-.373.649c4.615 3.274.259 8.033-1.088 8.801a12.563 12.563 0 0 1-3.283 1.342c-5.448 1.416-10.286-1.286-12.877-2.61-3.726-1.911-3.875-9.245-6.112-12.954-2.923-4.853-8.016-16.932-4.295-26.319 1.787-4.417 3.545-6.19 3.545-6.19 2.161-2.301 5.577-2.801 8.634-1.96 3.057.842 5.657 2.89 7.963 5.179 4.164 4.054 7.263 9 9.056 14.449 1.642 5.158.045 6.952-.948 10.308l.025.103c1.051 3.005 1.18 6.505-.247 9.202Z"
      fill="#263238"
    />
    <path
      d="M464.344 261.849c-2.149-2.734-21.617-4.949-21.617-4.949l-11.961 1.373s-15.655 2.794-20.737 5.529c-.359.193-.7.422-1.03.671 7.333 6.544 17.001 10.526 27.602 10.526 11.158 0 21.279-4.413 28.733-11.58-.354-.646-.689-1.189-.991-1.572l.001.002Z"
      fill="#16796F"
    />
    <path
      opacity={0.4}
      d="M464.344 261.849c-2.149-2.734-21.617-4.949-21.617-4.949l-11.961 1.373s-15.655 2.794-20.737 5.529c-.359.193-.7.422-1.03.671 7.333 6.544 17.001 10.526 27.602 10.526 11.158 0 21.279-4.413 28.733-11.58-.354-.646-.689-1.189-.991-1.572l.001.002Z"
      fill="#fff"
    />
    <path
      d="M446.666 250.289c-1.409 3.207-1.398 6.303.531 9.244 0 0 7.26 7.371 2.216 7.675-5.043.304-14.473-4.43-16.517-8.54 0 0 2.745-4.454 1.985-10.252-.76-5.799-1.6-8.09-1.6-8.09l13.387 9.963h-.002Z"
      fill="#B97964"
    />
    <path
      d="M428.351 227.602c.65 9.885.705 14.068 5.638 19.238 7.427 7.763 19.187 5.098 21.496-4.73 2.006-8.845.658-23.496-8.866-27.633-9.524-4.137-18.909 3.229-18.267 13.125h-.001Z"
      fill="#B97964"
    />
    <path
      d="M431.956 227.699c2.042.918 2.124 10.233-2.661 10.049-4.785-.183-4.039-13.062 2.661-10.049Z"
      fill="#263238"
    />
    <path
      d="M424.337 236.265c1.093 2.092 2.953 3.455 4.689 3.967 2.611.77 4.148-3.416 3.356-6.095-.713-2.413-3.241-4.095-5.829-3.682-2.551.407-3.466 3.418-2.216 5.81Z"
      fill="#B97964"
    />
    <path
      opacity={0.2}
      d="M429.934 236.239a.275.275 0 0 1-.275-.269 3.37 3.37 0 0 0-1.036-2.337 3.356 3.356 0 0 0-2.385-.924c-.137.008-.279-.117-.282-.27a.275.275 0 0 1 .269-.28 3.955 3.955 0 0 1 2.777 1.077 3.928 3.928 0 0 1 1.207 2.722.275.275 0 0 1-.269.282h-.006v-.001Z"
      fill="#000"
    />
    <path
      d="M447.591 215.763c-.925-2.791-4.094-5.072-9.756-6.064l-.273-.048c-5.396-.89-11.209-2.366-13.235 3.633-5.315 15.66-14.198 13.924-12.079 18.562a3.497 3.497 0 0 0 3.314 1.807c.341-.015.676-.098.985-.242-.379.375-.825.766-1.226 1.177-2.199 2.273-4.313 4.931-5.087 8.076a4.452 4.452 0 0 0 4.361 5.905c-.195.226-2.434 2.759-2.779 3.397-3.812 7.021-.81 14.741 9.019 11.527 0 0 6.631 2.408 9.543-4.203 0 0 4.742-.378 6.267-4.695.052-.099.096-.2.134-.305a11.618 11.618 0 0 0-.077-9.889c-.86-1.762-1.802-3.473-2.662-5.236-.586-1.054-.946-2.204-1.12-3.385.178-.379.351-.764.541-1.13l.234-.413a16.05 16.05 0 0 1 5.775-6.25c6.55-3.685 9.483-8.455 8.12-12.224h.001Z"
      fill="#263238"
    />
    <path
      d="M441.425 231.304c.005 1.057.541 1.945 1.196 1.986.654.041 1.181-.783 1.174-1.839-.005-1.056-.541-1.946-1.195-1.987-.655-.04-1.182.784-1.176 1.84h.001ZM451.256 231.679c.008 1.024.543 1.886 1.196 1.927.652.041 1.176-.757 1.168-1.781-.008-1.024-.544-1.886-1.196-1.927-.652-.041-1.176.757-1.168 1.781ZM439.497 227.546a.448.448 0 0 0 .358-.099c1.594-1.359 3.302-.789 3.322-.781a.451.451 0 0 0 .588-.308.535.535 0 0 0-.339-.63c-.089-.03-2.233-.748-4.211.939-.197.168-.213.479-.037.695.085.103.2.166.317.185l.002-.001ZM454.767 226.814a.374.374 0 0 0 .237-.208c.1-.222 0-.513-.222-.65-2.225-1.374-3.954-.471-4.027-.43-.205.111-.265.392-.134.628s.404.337.609.228c.067-.036 1.418-.715 3.188.378.115.071.24.087.347.055l.002-.001Z"
      fill="#263238"
    />
    <path
      d="M448.601 231.084s1.406 3.795 3.069 6.193c.29.419.076 1.004-.409 1.16-1.25.403-2.673-.067-2.673-.067l.013-7.286Z"
      fill="#A24E3F"
    />
    <path
      d="m442.224 229.493 2.31-.446s-1.321 1.654-2.31.446ZM452.188 229.913l2.309-.446s-1.321 1.654-2.309.446ZM447.847 241.167c-.781.956-1.803 2.861-1.803 2.861-.189-.062-.378-.123-.564-.194-2.547-.897-3.432-2.155-3.641-3.278-.113-.613-.014-1.192.125-1.643.155-.524.386-.86.386-.86 1.066 1.282 3.376 2.302 4.632 2.795.524.212.864.32.864.32l.001-.001Z"
      fill="#263238"
    />
    <path
      d="m446.983 240.847-.553.83c-2.397-.851-3.97-1.772-4.465-2.764.155-.524.386-.86.386-.86 1.066 1.282 3.376 2.302 4.632 2.795v-.001Z"
      fill="#fff"
    />
    <path
      d="M445.481 243.833c-2.547-.898-3.432-2.155-3.641-3.278.856.338 1.85.843 2.63 1.557a3.264 3.264 0 0 1 1.01 1.721h.001Z"
      fill="#D7504D"
    />
    <path
      d="M381.434 357.312c23.862-10.194 34.942-37.802 24.749-61.663-10.194-23.862-37.802-34.943-61.664-24.749-23.862 10.194-34.942 37.801-24.748 61.663 10.194 23.862 37.801 34.942 61.663 24.749Z"
      fill="#16796F"
    />
    <path
      opacity={0.4}
      d="M381.434 357.312c23.862-10.194 34.942-37.802 24.749-61.663-10.194-23.862-37.802-34.943-61.664-24.749-23.862 10.194-34.942 37.801-24.748 61.663 10.194 23.862 37.801 34.942 61.663 24.749Z"
      fill="#fff"
    />
    <path
      d="M394.4 346.198c-2.435-3.098-24.578-6.313-24.578-6.313l-13.455 2.264s-17.732 3.164-23.488 6.262c-.407.219-.843.9-.843 1.046 8.306 7.412 18.932 11.637 30.941 11.637 12.638 0 24.101-5 32.545-13.115-.402-.731-.783-1.347-1.122-1.78v-.001Z"
      fill="#263238"
    />
    <path
      d="M317.985 342.407a.546.546 0 0 1-.458-.248c-8.788-13.304-10.193-32.983-3.415-47.857a.553.553 0 0 1 .729-.273.548.548 0 0 1 .272.728c-6.628 14.549-5.26 33.791 3.33 46.796a.55.55 0 0 1-.459.853l.001.001ZM316.399 291.146a.542.542 0 0 1-.52-.712c.103-.304 1.606-2.859 2.495-4.354a.55.55 0 1 1 .946.561c-1.083 1.826-2.294 3.905-2.403 4.16a.566.566 0 0 1-.518.345Z"
      fill="#16796F"
    />
    <path
      d="M397.466 341.761c1.24-9.976-8.444-14.752-8.008-29.159.746-24.722-22.627-25.927-31.747-25.002-9.119.925-9.22 10.854-9.22 10.854s-13.398 5.001-7.084 23.893c5.734 17.16-6.91 9.405-2.065 22.82 18.89-4.896 31.901-9.189 47.739-4.527l.278-7.283 2.796 8.5 7.312-.097-.001.001Z"
      fill="#263238"
    />
    <path
      d="M374.317 353.018c2.602-.983.853-8.648-1.506-10.202-2.66-1.753-4.008-3.336-3.8-6.051.099-1.538.507-3.152 1.012-4.73l-3.02-1.464-11.141-9.395c1.735 6.217 2.534 17.379-2.511 21.598 0 0 3.81 2.541 9.355 6.517 0 0 8.335 4.962 11.609 3.727h.002Z"
      fill="#FFAF6B"
    />
    <path
      opacity={0.2}
      d="M368.98 337.36c-2.959-1.415-6.3-4.032-6.228-7.371.04-1.407.222-2.752.48-3.628l6.384 7.045c-.36 1.259-.459 2.08-.636 3.954Z"
      fill="#000"
    />
    <path
      d="M352.842 309.73c1.901 10.411 2.487 14.845 8.397 19.863 8.89 7.55 21.175 3.64 22.4-6.957 1.104-9.539-2.193-24.961-12.894-28.449-10.542-3.437-19.806 5.132-17.903 15.543Z"
      fill="#FFAF6B"
    />
    <path
      d="M366.744 311.927c.151 1.106.834 1.963 1.526 1.916.69-.047 1.129-.982.978-2.089-.151-1.105-.834-1.963-1.526-1.916-.692.047-1.13.982-.978 2.089ZM377.093 310.968c.149 1.071.829 1.901 1.518 1.854.69-.047 1.128-.955.979-2.026-.148-1.071-.828-1.902-1.518-1.855-.689.048-1.127.955-.979 2.027ZM364.206 308.255a.469.469 0 0 0 .363-.152c1.483-1.643 3.351-1.281 3.373-1.275a.476.476 0 0 0 .573-.404.564.564 0 0 0-.441-.613c-.098-.02-2.442-.477-4.282 1.562-.184.204-.159.532.056.734a.548.548 0 0 0 .358.15v-.002ZM380.103 305.389a.393.393 0 0 0 .22-.251c.074-.246-.069-.538-.321-.651-2.52-1.133-4.206.052-4.278.103-.2.144-.224.447-.054.677.17.229.469.297.669.155.065-.047 1.388-.944 3.392-.042.13.058.263.057.371.01l.001-.001Z"
      fill="#263238"
    />
    <path
      d="M374.231 310.709s1.995 3.782 4.067 6.065c.362.4.218 1.042-.269 1.273-1.253.593-2.81.298-2.81.298l-.988-7.636Z"
      fill="#E97F3F"
    />
    <path
      d="m367.331 309.92 2.358-.785s-1.156 1.915-2.358.785ZM377.826 308.989l2.359-.786s-1.157 1.915-2.359.786Z"
      fill="#263238"
    />
    <path
      d="M356.81 313.028s4.684-8.636 3.814-15.212c0 0 10.122-3.631 19.378 6.669 1.474 1.642 2.522 4.505 3.928 6.928 0 0 .188-20.15-17.682-20.198-17.841-.048-19.089 25.14-9.706 32.737 0 0-2.628-5.524.268-10.925v.001Z"
      fill="#263238"
    />
    <path
      d="M350.432 318.569c1.432 2.042 3.569 3.213 5.457 3.511 2.842.448 3.876-4.148 2.678-6.847-1.078-2.429-3.958-3.844-6.612-3.055-2.616.778-3.162 4.057-1.523 6.391Z"
      fill="#FFAF6B"
    />
    <path
      opacity={0.2}
      d="M356.254 317.758a.276.276 0 0 1-.272-.232 3.572 3.572 0 0 0-1.413-2.317 3.583 3.583 0 0 0-2.637-.643.275.275 0 1 1-.084-.544 4.155 4.155 0 0 1 3.046.743 4.122 4.122 0 0 1 1.633 2.676.277.277 0 0 1-.272.318l-.001-.001Z"
      fill="#000"
    />
    <path
      d="M374.317 320.634c-.767 1.031-1.737 3.062-1.737 3.062-.2-.055-.401-.11-.596-.175-2.692-.811-3.673-2.077-3.944-3.234-.145-.633-.071-1.24.052-1.716.136-.551.359-.913.359-.913 1.171 1.283 3.622 2.232 4.953 2.684.555.195.913.292.913.292Z"
      fill="#263238"
    />
    <path
      d="m373.404 320.343-.536.89c-2.533-.77-4.214-1.652-4.776-2.66.136-.551.359-.913.359-.913 1.171 1.283 3.622 2.231 4.953 2.683Z"
      fill="#fff"
    />
    <path
      d="M371.984 323.523c-2.692-.811-3.673-2.077-3.944-3.234.906.311 1.966.789 2.811 1.493a3.39 3.39 0 0 1 1.133 1.741Z"
      fill="#D7504D"
    />
    <path
      d="M398.628 186.445c19.988-19.989 19.988-52.397 0-72.386-19.989-19.988-52.397-19.988-72.386 0-19.988 19.989-19.988 52.397 0 72.386 19.989 19.988 52.397 19.988 72.386 0Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="M398.628 186.445c19.988-19.989 19.988-52.397 0-72.386-19.989-19.988-52.397-19.988-72.386 0-19.988 19.989-19.988 52.397 0 72.386 19.989 19.988 52.397 19.988 72.386 0Z"
      fill="#fff"
    />
    <path
      d="M396.666 185.208c-2.652-3.375-25.055-5.831-25.055-5.831l-16.378 1.419s-19.317 3.447-25.588 6.822c-.444.239-.864.522-1.271.828 9.049 8.074 20.978 12.989 34.059 12.989 13.768 0 26.256-5.446 35.456-14.288-.437-.796-.852-1.467-1.223-1.939Z"
      fill="#16796F"
    />
    <path
      d="M370.129 112.718c-15.951-5.423-21.677 7.645-21.677 7.645-2.168.599-3.49 1.466-4.253 2.442-1.236.558-2.539 1.545-3.663 3.279-3.341 5.158-2.025 11.772-.049 15.192 1.391 2.409 7.863-2.268 9.519-9.276 7.929 2.688 23.286 7.797 26.016 7.987 3.71.259 9.54-4.247 10.245-10.282 1.062-9.077-6.084-13.568-16.138-16.987Z"
      fill="#263238"
    />
    <path
      d="M354.794 180.691s1.843-7.844-1.535-22.048c.001-.016.754-.306.755-.322a86.517 86.517 0 0 0-1.456-5.422c-.011-.046-.009-.061-.009-.061l18.582 12.774c-.667 4.457.518 13.19 1.298 16.892 0 0 2.097 6.737-6.723 6.598-6.444-.103-10.911-8.412-10.911-8.412l-.001.001Z"
      fill="#FFB2A8"
    />
    <path
      d="M361.144 168.836c2.302 2.636 6.306 4.121 9.976 4.199-.053-.327-.043-.817-.081-1.143-.063-.511-.058-1.294-.086-1.83-.03-.66-.041-1.378-.007-2.061.032-.27.141-.232.172-.502l.065-.558-11.117-3.773c-.902.658-.779 3.528 1.078 5.668Z"
      fill="#000"
      opacity={0.2}
    />
    <path
      d="M346.097 141.558c-2.664-11.935 7.704-22.925 20.082-20.054 12.563 2.914 17.063 20.543 16.202 31.785-.956 12.49-14.952 20.384-25.595 12.539-7.074-5.215-8.026-12.335-10.689-24.27Z"
      fill="#FFB2A8"
    />
    <path
      d="M355.1 122.36c-4.438.9-13.549 9.733-13.549 9.733s.579 3.426 1.746 5.468c-.498.422-.963.875-1.373 1.367a9.31 9.31 0 0 0-1.438 2.349c.16.44.367.893.628 1.358 1.293 2.308 5.395 7.406 8.967 11.695-.3-.6-.569-1.249-.822-1.963-.843-2.381-1.887-5.692-2.969-9.685.964.03 1.66.294 2.291.628 4.608 2.442 6.288 4.73 8.227 2.481 1.178-1.368-.302-5.73-1.274-9.44-.244-.974-.729-3.167-.759-5.317-.039-2.878 2.846-5.24 2.846-5.24s.167-.205.339-.511c3.947 2.539 10.904 5.692 17.757 2.319 10.235-5.036-11.823-15.007-20.619-5.243l.002.001ZM363.375 144.196c.11 1.244.859 2.188 1.673 2.108.814-.08 1.385-1.154 1.275-2.398-.11-1.244-.859-2.188-1.672-2.108-.814.08-1.385 1.152-1.275 2.398h-.001ZM375.436 143.382c.11 1.244.858 2.188 1.672 2.109.814-.08 1.385-1.153 1.275-2.398-.11-1.244-.858-2.188-1.672-2.108-.814.081-1.385 1.153-1.275 2.398v-.001Z"
      fill="#263238"
    />
    <path
      d="M371.846 141.889s4.484 5.834 5.969 8.24c-1.593 1.717-4.64 1.259-4.64 1.259l-1.329-9.499Z"
      fill="#E8675B"
    />
    <path
      d="M360.178 137.467a.59.59 0 0 0 .577-.134c1.872-1.745 3.832-1.349 4.036-1.304a.592.592 0 0 0 .711-.43.58.58 0 0 0-.427-.703c-.111-.026-2.742-.627-5.117 1.59a.58.58 0 0 0 .22.981Z"
      fill="#263238"
    />
    <path
      d="M342.766 150.234c1.294 3.157 3.93 5.405 6.54 6.426 3.927 1.537 4.966-2.7 4.258-6.64-.637-3.547-2.064-7.808-6.189-7.661-4.065.146-6.089 4.265-4.609 7.874v.001Z"
      fill="#FFB2A8"
    />
    <path
      opacity={0.2}
      d="M351.426 150.037a.275.275 0 0 1-.269-.22 6.026 6.026 0 0 0-1.382-2.779c-.451-.511-1.187-1.153-2.126-1.298-.845-.131-1.715.244-2.072.889a.277.277 0 0 1-.374.108.276.276 0 0 1-.107-.374c.471-.853 1.555-1.332 2.635-1.166 1.102.17 1.944.897 2.454 1.478a6.58 6.58 0 0 1 1.508 3.033.275.275 0 0 1-.269.33l.002-.001Z"
      fill="#000"
    />
    <path
      d="M380.884 136.501a.588.588 0 0 0 .202-1.014c-2.487-2.053-5.049-1.24-5.169-1.218a.592.592 0 0 0-.383.737.579.579 0 0 0 .725.382c.106-.039 2.087-.64 4.083 1.008.157.129.36.162.541.106l.001-.001Z"
      fill="#263238"
    />
    <path
      d="M364.238 153.994s3.005 1.868 8.272 1.873c0 0-.955 3.61-4.332 3.311-4.083-.36-3.94-5.184-3.94-5.184Z"
      fill="#fff"
    />
    <path
      d="M368.044 152.414c1.931.058 3.821.722 5.257 2.016.554.499 1.024 1.073 1.216 1.686.616 1.96-6.339.589-9.191-.505-2.853-1.093-2.194-2.21-.584-2.869.771-.315 2.113-.365 3.302-.328Z"
      fill="#263238"
    />
    <path
      opacity={0.3}
      d="M355.027 133.824s-5.073 3.261-10.147 2.323c-5.074-.937-6.39-2.057-6.39-2.057l.368 2.763s4.281 2.521 9.522 2.235c5.242-.286 7.462-1.595 7.462-1.595l-.817-3.669h.002Z"
      fill="#fff"
    />
    <path
      d="M313.424 181.032a.548.548 0 0 1-.459-.247c-9.565-14.481-11.094-35.901-3.717-52.09a.55.55 0 0 1 1.001.457c-7.228 15.862-5.734 36.845 3.633 51.026a.55.55 0 0 1-.458.854ZM311.694 125.188a.523.523 0 0 1-.186-.034.54.54 0 0 1-.334-.678c.11-.326 1.746-3.106 2.713-4.734a.55.55 0 0 1 .946.562c-1.185 1.996-2.506 4.266-2.62 4.537a.567.567 0 0 1-.519.347ZM89.297 140.799h-6.894v42.37h6.894v-42.37ZM289.048 140.799h-6.894v42.37h6.894v-42.37Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M289.048 140.799h-6.894v42.37h6.894v-42.37Z"
      fill="#000"
    />
    <path d="M288.506 189.559h-6.894v43.286h6.894v-43.286Z" fill="#16796F" />
    <path
      opacity={0.2}
      d="M288.506 189.559h-6.894v43.286h6.894v-43.286Z"
      fill="#000"
    />
    <path
      d="M118.769 88.566H253.01c17.127 0 31.032 13.906 31.032 31.033v340.935c0 13.049-10.595 23.644-23.644 23.644H107.882c-11.118 0-20.145-9.027-20.145-20.144V119.599c0-17.127 13.905-31.033 31.032-31.033Z"
      fill="#16796F"
    />
    <path
      d="M119.076 96.557h133.628c12.921 0 23.41 10.489 23.41 23.41v341.677c0 8.007-6.501 14.507-14.506 14.507H110.173c-8.007 0-14.507-6.5-14.507-14.507V119.968c0-12.921 10.49-23.411 23.41-23.411Z"
      fill="#fff"
    />
    <path
      d="M157.766 94.507c0 .228 4.686 6.896 7.004 10.346a4.588 4.588 0 0 0 3.81 2.024h45.622a4.583 4.583 0 0 0 3.984-2.304c2.07-3.609 5.858-10.177 5.957-10.066.135.153-66.377 0-66.377 0Z"
      fill="#16796F"
    />
    <path
      d="M230.89 173.107c12.24 0 22.162-9.923 22.162-22.162 0-12.24-9.922-22.162-22.162-22.162-12.239 0-22.161 9.922-22.161 22.162 0 12.239 9.922 22.162 22.161 22.162Z"
      fill="#E0E0E0"
    />
    <path
      d="M218.095 166.319c.424-.915 4.426-7.925 13.35-7.926 9.899 0 13.485 7.034 13.485 7.034s-5.588 5.02-13.149 5.408c-7.33.376-13.686-4.517-13.686-4.517v.001ZM231.284 156.154a8.797 8.797 0 1 0 0-17.594 8.797 8.797 0 0 0 0 17.594Z"
      fill="#F5F5F5"
    />
    <path
      d="M206.407 476.324c-2.002-12.694-5.083-30.514-9.778-49.5-5.951-23.952-13.39-49.836-19.484-70.114-6.447-21.366-11.335-36.471-11.335-36.471l52.571-3.305s21.52 106.168 26.569 147.158c.579 4.011.537 8.032 0 12.06l-38.543.172Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M206.407 476.324c-2.002-12.694-5.083-30.514-9.778-49.5-5.951-23.952-13.39-49.836-19.484-70.114-6.447-21.366-11.335-36.471-11.335-36.471l52.571-3.305s21.52 106.168 26.569 147.158c.579 4.011.537 8.032 0 12.06l-38.543.172Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="m183.081 348.383-4.918 10.603c5.493 20.278 11.859 42.55 18.109 66.403 2.331-23.504-1.375-63.05-13.19-77.006h-.001Z"
      fill="#000"
    />
    <path
      d="M178.013 473.594c6.324-39.216 12.008-154.887 12.008-154.887l-51.614 3.274s5.297 99.59.726 147.493l-.726 6.679h39.258l.348-2.559Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M178.013 473.594c6.324-39.216 12.008-154.887 12.008-154.887l-51.614 3.274s5.297 99.59.726 147.493l-.726 6.679h39.258l.348-2.559Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M189.244 332.44a2.301 2.301 0 1 1-4.603 0 2.301 2.301 0 0 1 4.603 0ZM141.781 350.515a.551.551 0 0 1-.002-1.1 18.927 18.927 0 0 0 13.414-5.698 18.924 18.924 0 0 0 5.319-13.567.551.551 0 0 1 .537-.563.536.536 0 0 1 .563.537 20.028 20.028 0 0 1-5.631 14.36 20.025 20.025 0 0 1-14.197 6.03h-.003v.001ZM186.941 352.345a.55.55 0 0 1-.083-1.094c1.469-.226 3.843-.623 4.639-.831.321-1.777.092-13.194-.137-20.264a.551.551 0 0 1 .531-.569c.258-.019.558.228.568.531.677 20.85.086 21.086-.345 21.258-.688.275-4.36.849-5.089.961a.488.488 0 0 1-.085.007l.001.001ZM210.069 331.163c-.114.682.095 1.475.217 2.141.261 1.42.643 2.821 1.133 4.179 1.22 3.384 3.175 6.737 6.296 8.684 2.009 1.252 4.35 1.733 6.697 1.58.527-.034.531-.859 0-.825-3.976.259-7.398-1.546-9.691-4.76-1.795-2.517-2.905-5.513-3.531-8.526a18.455 18.455 0 0 1-.266-1.565 11.291 11.291 0 0 1-.045-.487c-.006-.067.015-.375-.013-.203.087-.519-.708-.743-.796-.219l-.001.001Z"
      fill="#000"
    />
    <path
      d="m218.781 231.27 11.269 20.078 24.87-31.243 11.783 4.394s-30.645 53.49-38.353 54.396c-7.709.906-26.952-34.542-26.952-34.542l17.383-13.083Z"
      fill="#8E4B4C"
    />
    <path
      d="M207.76 204.518c8.098 11.992 21.466 34.768 21.466 34.768l-30.695 17.556s-19.826-32.68-18.836-45.55c1.086-13.94 19.731-14.678 28.065-6.774Z"
      fill="#263238"
    />
    <path
      d="M142.937 201.185c1.9-.304 3.848-.576 5.781-.814a268.164 268.164 0 0 1 30.642-2.172c1.267.011 2.512.059 3.736.144a142.198 142.198 0 0 1 17.245 2.124s11.634 53.496 21.521 124.663c-32.206 4.947-63.951 6.145-89.123 1.769-.639-5.477 4.275-26.634 3.157-47.871-.478-9.069-1.245-19.065-2.523-29.524-1.753-15.01-5.58-30.03-9.546-44.613 0 0 9.45-2.187 19.11-3.704v-.002Z"
      fill="#263238"
    />
    <path
      opacity={0.2}
      d="m142.147 258.378 1.559-.253-4.341-23.924 12.426 40.458-6.745 2.268-2.899-18.549Z"
      fill="#000"
    />
    <path
      d="M242.577 294.863c-21.561 0-40.877-14.619-45.462-36.009-2.578-12.024-.176-24.345 6.765-34.694 6.872-10.246 17.386-17.331 29.605-19.951l54.117-11.602 4.151 19.36-54.118 11.602c-7.169 1.537-13.317 5.663-17.312 11.619-3.925 5.854-5.293 12.784-3.85 19.514 3.006 14.021 17.394 22.872 32.064 19.723l54.118-11.601 4.15 19.36-54.118 11.603a48.234 48.234 0 0 1-10.112 1.076h.002Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="m302.452 261.353-30.953 6.635 4.473 20.864 30.952-6.636-4.472-20.863Z"
      fill="#fff"
    />
    <path
      d="M306.818 282.184 252.7 293.786c-26.195 5.616-51.961-10.595-57.437-36.137-5.476-25.543 11.38-50.892 37.576-56.508l54.118-11.602 4.808 22.43-54.118 11.602c-13.827 2.964-22.779 16.095-19.955 29.269 2.825 13.174 16.372 21.482 30.199 18.517l54.118-11.602 4.808 22.43.001-.001Z"
      fill="#000"
      opacity={0.3}
    />
    <path
      d="M240.175 283.655c-21.561 0-40.877-14.619-45.463-36.009-5.293-24.696 11.022-49.21 36.37-54.644l54.117-11.602 4.15 19.361-54.117 11.602c-14.674 3.146-24.167 17.113-21.161 31.133 3.005 14.021 17.388 22.872 32.064 19.723l54.117-11.601 4.151 19.36-54.118 11.602a48.248 48.248 0 0 1-10.112 1.076l.002-.001Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="m300.239 251.552-30.952 6.636 4.164 19.424 30.952-6.635-4.164-19.425ZM285.186 181.398l-30.952 6.635 6.555 30.576 30.952-6.635-6.555-30.576Z"
      fill="#fff"
    />
    <path
      d="M256.244 217.953s1.468-10.527 3.886-12.181c2.417-1.656 2.9 2.498 2.9 2.498s-.251-4.603 1.689-4.973c2.328-.446 2.815 3.908 2.815 3.908s-.052-3.766 1.696-3.981c1.813-.222 2.711 4.653 2.711 4.653s.466-2.659 1.765-2.121c1.299.539 3.406 10.847 1.973 14.226-1.434 3.377-14.766 5.681-17.77 5.491-.863-.055-2.315-.406-4.144-3.927a1.617 1.617 0 0 1 .562-2.105l1.868-1.175.047-.313h.002Z"
      fill="#8E4B4C"
    />
    <path
      opacity={0.2}
      d="m263.25 208.244.001.02.054 8.044c0 .108-.098.199-.22.202-.122.004-.225-.084-.223-.189l-.054-8.044c0-.108.098-.199.22-.203.115-.003.21.072.222.17ZM267.781 207.405v.005l.666 7.723c.009.108-.082.204-.203.215-.123.011-.207-.053-.239-.174l-.667-7.723c-.008-.108.083-.203.204-.216.12-.011.227.065.239.17ZM272.193 208.113l.659 5.725c.012.108-.076.205-.198.219-.121.014-.23-.062-.243-.169l-.659-5.724c-.012-.108.077-.206.198-.219.121-.014.23.062.243.168Z"
      fill="#000"
    />
    <path
      d="M147.981 200.473c6.38-7.506 3.199-24.953.334-34.258l24.045 14.873a26.138 26.138 0 0 0-.763 3.532 12.688 12.688 0 0 0 .5 6.411c.662 1.686 3.817 7.167 3.817 7.167s5.605 10.327-7.814 11.943c-13.294.995-20.119-9.668-20.119-9.668Z"
      fill="#8E4B4C"
    />
    <path
      opacity={0.2}
      d="M153.658 175.289c.533 11.312 12.536 14.896 18.451 15.76a12.7 12.7 0 0 1-.5-6.411l-17.951-9.349Z"
      fill="#000"
    />
    <path
      d="M169.285 130.654c11.017-2.284 5.269 6.611 17.404 8.207.319 7.377-8.063 15.696-11.258 11.177-3.512-4.966-15.313-17.58-6.147-19.384h.001Z"
      fill="#263238"
    />
    <path
      d="M143.359 158.501c4.724 12.705 7.125 19.965 15.861 24.552 13.139 6.9 27.5-3.655 26.779-17.561-.648-12.517-8.265-31.191-22.865-32.348-14.382-1.139-24.501 12.652-19.775 25.357Z"
      fill="#8E4B4C"
    />
    <path
      d="M161.787 156.201c.399 1.324 1.445 2.177 2.337 1.904.89-.273 1.29-1.569.889-2.894-.399-1.325-1.445-2.177-2.336-1.904-.891.272-1.289 1.568-.89 2.894ZM175.365 153.198c.399 1.326 1.445 2.178 2.336 1.905.891-.273 1.29-1.568.89-2.894-.399-1.324-1.445-2.178-2.336-1.904-.891.273-1.289 1.568-.89 2.893Z"
      fill="#263238"
    />
    <path
      d="M172.463 153.434s6.306 5.304 8.5 7.574c-1.397 2.229-4.902 2.43-4.902 2.43l-3.598-10.003v-.001Z"
      fill="#703131"
    />
    <path
      d="M158.356 151.327a.686.686 0 0 0 .615-.277c1.74-2.376 4.191-2.339 4.216-2.339a.676.676 0 0 0 .698-.629.642.642 0 0 0-.633-.665c-.13-.005-3.201-.052-5.359 2.897a.631.631 0 0 0 .149.901.656.656 0 0 0 .314.112ZM181.259 145.537a.675.675 0 0 0 .27-.27.638.638 0 0 0-.269-.877c-3.234-1.657-5.914-.189-6.043-.138a.652.652 0 0 0-.262.888.662.662 0 0 0 .894.248c.109-.066 2.188-1.171 4.784.159a.677.677 0 0 0 .627-.009l-.001-.001ZM176.285 168.012a38.72 38.72 0 0 0-2.319 5.097c-.321-.062-.651-.117-.969-.195-4.38-.906-6.12-2.766-6.699-4.568a5.924 5.924 0 0 1-.163-2.734 5.636 5.636 0 0 1 .445-1.493c2.037 1.869 6.044 3.027 8.218 3.554a19.22 19.22 0 0 1 1.486.339h.001Z"
      fill="#263238"
    />
    <path
      d="m174.807 167.674-.726 1.486c-4.114-.86-6.895-2.006-7.936-3.546a5.597 5.597 0 0 1 .444-1.493c2.05 1.888 6.044 3.027 8.218 3.553Z"
      fill="#fff"
    />
    <path
      d="M173.016 173.009c-4.38-.906-6.12-2.766-6.699-4.568a14.391 14.391 0 0 1 4.664 1.964 5.414 5.414 0 0 1 2.035 2.604Z"
      fill="#D7504D"
    />
    <path
      d="M182.436 120.429c-8.941-.863-11.96.113-19.331 3.046 0 0 1.844-3.784 1.093-8.207-17.037 14.674-24.446 7.792-32.717 16.19-5.285 5.365-.102 12.144-.102 12.144s-13.741 8.923 6.974 24.368c.657 1.697.831 3.334.159 4.403-1.243 1.974-3.524 2.993-5.672 3.902 3.513.998 7.367.749 10.722-.697a13.594 13.594 0 0 1-3.373 5.8c4.655.302 9.397-1.958 12.094-5.764l-3.427-9.347a9.539 9.539 0 0 1 2.57.064c.622-3.154.8-6.378.527-9.581a28.695 28.695 0 0 0-2.347-7.234s7.648-4.519 7.824-9.964c0 0 13.669 4.024 20.966-2.124 7.298-6.148.307-14.288 4.043-17.003l-.003.004Z"
      fill="#263238"
    />
    <path
      d="M141.347 170.853c2.072 3.018 5.395 4.789 8.425 5.281 4.559.74 4.769-3.924 3.161-7.891-1.447-3.571-3.9-7.717-8.312-6.651-4.346 1.052-5.642 5.809-3.274 9.26v.001Z"
      fill="#8E4B4C"
    />
    <path
      opacity={0.2}
      d="M150.573 168.721a.276.276 0 0 1-.252-.166 6.388 6.388 0 0 0-2.09-2.61c-.598-.438-1.524-.948-2.588-.894-.948.052-1.818.643-2.067 1.406a.275.275 0 1 1-.522-.171c.322-.985 1.375-1.72 2.561-1.784 1.221-.072 2.271.508 2.942 1a6.929 6.929 0 0 1 2.269 2.835.275.275 0 0 1-.252.385l-.001-.001Z"
      fill="#000"
    />
    <path
      d="M114.443 247.557s11.801 46.214 19.699 51.219c7.898 5.005 64.39-19.07 64.39-19.07l-6.776-10.981-45.862 6.812c-1.469-1.599-7.176-37.547-7.176-37.547l-24.277 9.569.002-.002Z"
      fill="#8E4B4C"
    />
    <path
      d="M137.777 224.052c2.416 16.516 5.929 34.073 5.929 34.073l-31.256 5.066s-4.016-52.179 11.688-58.302c5.386 0 11.008 1.169 13.639 19.163Z"
      fill="#263238"
    />
    <path
      d="m211.84 266.408.833-1.614c.964-2.687-1.565-4.504-1.565-4.504.14-2.287-2-4.753-2.866-5.333-.865-.58-9.961 6.618-9.961 6.618l-2.177-5.608c-.361.131-3.991 4.17-5.607 7.318l-4.844 7.667c1.35 6.311 4.459 8.107 7.873 9.558.576.245 7.855-1.361 11.351-2.311 3.195-.869 8.086-3.375 9.168-7.619 1.082-4.243-2.204-4.172-2.204-4.172h-.001Z"
      fill="#8E4B4C"
    />
    <path
      opacity={0.2}
      d="m145.893 275.535-4.106 10.374 7.878-10.934-3.772.56Z"
      fill="#000"
    />
    <path
      d="M503.847 139.588a24.516 24.516 0 0 0-1.297-3.052c-7.195-14.807-25.03-20.978-39.837-13.784-14.807 7.195-20.978 25.03-13.784 39.838 7.195 14.808 25.03 20.979 39.837 13.785a29.809 29.809 0 0 0 16.781-27.036 29.298 29.298 0 0 0-1.7-9.751Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="M505.547 149.316a29.292 29.292 0 0 0-1.7-9.728 24.516 24.516 0 0 0-1.297-3.052c-7.195-14.807-25.03-20.978-39.837-13.784-14.807 7.195-20.978 25.03-13.784 39.838 7.195 14.808 25.03 20.979 39.837 13.785a29.809 29.809 0 0 0 16.781-27.036v-.023Z"
      fill="#fff"
    />
    <path
      d="M495.293 140.528c-2.013-2.717-5.289-1.319-6.843 1.063-1.934-2.08-5.412-2.885-6.921.134-2.561 5.098 3.969 9.045 7.01 10.543l.873.425.782-.57c2.75-2.001 8.497-7.01 5.098-11.595h.001ZM470.204 141.836c-1.633-2.963-5.065-2.024-6.921.124-1.644-2.315-4.976-3.59-6.887-.806-3.22 4.707 2.706 9.504 5.534 11.405l.794.537.861-.459c2.986-1.61 9.37-5.802 6.619-10.801Z"
      fill="#16796F"
    />
    <path
      d="M476.191 161.918c-3.781-.742-5.361-5.31-5.668-7.826a.596.596 0 0 1 1.18-.143c.005.074.924 6.994 5.659 6.91 4.734-.083 5.123-7.521 5.126-7.603a.59.59 0 0 1 .613-.564h.006c.321.01.574.278.564.599v.021c.018.353-.453 8.638-6.286 8.733-.402 0-.803-.043-1.195-.13l.001.003Z"
      fill="#263238"
    />
    <path
      d="M495.193 153.463c-.582.593-1.275.873-1.543.604-.269-.268 0-.961.559-1.565.558-.604 1.263-.861 1.543-.604.279.258.022.973-.559 1.565ZM497.653 153.754c-.582.604-1.275.873-1.543.604-.269-.268 0-.961.559-1.554.558-.593 1.263-.872 1.531-.604s.046.961-.547 1.554ZM457.703 153.866c.637.536 1.353.727 1.587.435.235-.291-.078-.95-.715-1.487-.637-.537-1.353-.727-1.598-.436-.246.291.078.962.727 1.488h-.001ZM455.278 154.426c.637.537 1.353.727 1.598.436.246-.292-.078-.962-.727-1.488-.648-.525-1.353-.727-1.587-.435-.234.29.078.95.716 1.487ZM121.4 301.62a27.3 27.3 0 0 0-1.404-3.493c-8.096-16.928-28.382-24.087-45.31-15.991a33.98 33.98 0 0 0-15.72 15.437 35.939 35.939 0 0 0-1.688 4.028c-6.292 17.686 2.945 37.123 20.63 43.415 17.686 6.292 37.123-2.944 43.415-20.63 1.335-3.751 2-7.708 1.965-11.69a33.212 33.212 0 0 0-1.888-11.076Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="M121.4 301.62a27.3 27.3 0 0 0-1.404-3.493c-8.096-16.928-28.382-24.087-45.31-15.991a33.98 33.98 0 0 0-15.72 15.437 35.939 35.939 0 0 0-1.688 4.028c-6.292 17.686 2.945 37.123 20.63 43.415 17.686 6.292 37.123-2.944 43.415-20.63 1.335-3.751 2-7.708 1.965-11.69a33.212 33.212 0 0 0-1.888-11.076Z"
      fill="#fff"
    />
    <path
      d="M92.936 304.781s2.231-5.183 6.175-5.444c5.09-.302 7.04 5.556 7.04 5.556l-1.987.073s-1.812-4.549-4.873-4.181c-3.809.447-5.255 4.441-5.255 4.441l-1.1-.445ZM83.983 304.781s-2.51-5.068-6.486-5.092c-5.1-.016-6.708 5.931-6.708 5.931l1.988.056s1.558-4.648 4.625-4.447c3.818.236 5.493 4.146 5.493 4.146l1.088-.594ZM77.014 310.606s14.225 1.834 24.567-.655c0 0-.565 14.069-12.71 13.26-12.145-.809-11.857-12.605-11.857-12.605Z"
      fill="#263238"
    />
    <path
      d="M77.374 311.193s13.55 1.812 23.408-.66c0 0-.243 1.635-.645 1.948a8.261 8.261 0 0 1-1.711.561c-.068.21-.196.397-.368.536-.954.596-4.556.487-4.556.487s-.045.286-.167.375c-.805.603-3.157.81-3.993.263a2.714 2.714 0 0 1-.493-.841s-.147.756-.334.892c-.61.473-2.416.254-3.074-.152a1.906 1.906 0 0 1-.486-.721s-2.399.298-3.09-.039a3.027 3.027 0 0 1-.622-.752s-2.674.178-3.33-.34c-.335-.259-.538-1.557-.538-1.557h-.001Z"
      fill="#fff"
    />
    <path
      d="M89.426 322.689c2.474.131 7.482-1.718 7.885-3.406.405-1.689-1.555-2.564-3.636-2.051a9.972 9.972 0 0 0-3.47 1.679s-3.703-1.905-6.552-1.083c-1.789.536-2.054 4.444 5.773 4.861Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="M72.296 311.346c-.292 1.945-3.001 3.13-6.056 2.663-3.054-.468-5.272-2.449-4.994-4.331.279-1.883 3.013-3.128 6.041-2.601 3.029.526 5.31 2.338 5.009 4.269ZM117.477 307.734c.601 1.873-1.302 4.136-4.248 5.071-2.946.935-5.812.141-6.396-1.67-.585-1.81 1.316-4.139 4.264-5.008 2.948-.87 5.795-.255 6.38 1.608v-.001Z"
      fill="#fff"
    />
    <path
      d="M298.481 343.363a14.81 14.81 0 0 0-.83-1.971c-4.642-9.567-16.162-13.559-25.729-8.917a19.253 19.253 0 0 0-8.853 8.787 19.368 19.368 0 0 0-1.986 8.663c-.119 10.634 8.405 19.349 19.039 19.468 10.634.119 19.349-8.405 19.468-19.039.002-.232 0-.464-.005-.696a18.92 18.92 0 0 0-1.104-6.295Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M298.481 343.363a14.81 14.81 0 0 0-.83-1.971c-4.642-9.567-16.162-13.559-25.729-8.917a19.253 19.253 0 0 0-8.853 8.787 19.368 19.368 0 0 0-1.986 8.663c-.119 10.634 8.405 19.349 19.039 19.468 10.634.119 19.349-8.405 19.468-19.039.002-.232 0-.464-.005-.696a18.92 18.92 0 0 0-1.104-6.295Z"
      fill="#fff"
    />
    <path
      d="M280.165 356.973c-3.198 0-6.107-1.617-7.22-4.021a.549.549 0 0 1 .26-.722.536.536 0 0 1 .715.244l.007.016c.96 2.036 3.479 3.4 6.266 3.4 2.788 0 5.307-1.364 6.267-3.4a.541.541 0 0 1 .719-.261h.003a.55.55 0 0 1 .259.723c-1.169 2.404-4.079 4.021-7.277 4.021h.001ZM287.384 345.355a1.71 1.71 0 1 1 0 3.422 1.71 1.71 0 0 1 0-3.422ZM274.656 347.066a1.71 1.71 0 1 1-3.422.002 1.71 1.71 0 0 1 3.422-.002Z"
      fill="#263238"
    />
  </svg>
);
export default SvgBrandLoyalty;
