import React from 'react'
import { SalonPackageModalProps } from '../types';
import Button from '../../../ui/atoms/button/Button';
import { Modal } from '../../../ui/templates/modal/Modal';
import { showRedeemPackageDetails } from './utils';
import SvgArrowBack from '../../../ui/icons/ArrowBack';
import SvgChevronLeft from '../../../ui/icons/ChevronLeft';
import Paragraph from '../../../ui/atoms/paragraph/Paragraph';
import Heading from '../../../ui/atoms/heading/Heading';
import { COLORS } from '../../../constants/colors';

const RedeemVoucherDetailsModal = (props: SalonPackageModalProps) => {
  const packageContent = props?.salonPackage ? showRedeemPackageDetails(props.salonPackage) : [];
  const closeModal = () => {
    props.closeModal()
  }

  return (
    <Modal
      show={props.isVisible}
      closeModal={closeModal}
      variant='right'
    >
      <div className='w-full relative my-[80px]'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>View Voucher Details</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          <div className='w-full flex items-center justify-between'>
            <div className="flex flex-col space-y-6">
              <Heading variant='h1' size='h9' weight='semiBold'>Voucher details</Heading>
              <Paragraph size="b4">Find information about voucher here</Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col space-y-4'>
            <div className='w-full flex flex-col border border-grey-20 space-y-6 p-4 rounded-md'>
              {Array.isArray(packageContent) && packageContent.map((salonPackageContent) => {
                return (
                  <>
                    <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{salonPackageContent?.label}</Paragraph>
                    <Paragraph size='b5' weight='normal' color={COLORS.GREY['900']}>{salonPackageContent?.value}</Paragraph>
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RedeemVoucherDetailsModal