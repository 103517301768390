import * as React from 'react'
import axios from 'axios'
import { Logo } from '../ui/atoms/logo'
import { Heading } from '../ui/atoms/heading'
import { useToast } from '../hooks/useToast'
import { ToastProps } from '../ui/atoms/toast/types'
import { useForm } from 'react-hook-form'
import { LoginInput } from '../core/types/auth'
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper'
import { ERRORS } from '../constants/errors'
import { REGEX_PATTERNS } from '../constants/pattern'
import Input from '../ui/molecules/input/Input'
import PasswordInput from '../ui/molecules/input/PasswordInput'
import Button from '../ui/atoms/button/Button'
import { print } from 'graphql'
import { LoginDocument, UserDocument } from '../graphql/generated'
import { useSalonCache } from '../hooks/useSalonCache'
import { useUserCache } from '../hooks/useUserCache'
import { jwtDecode } from 'jwt-decode'
import { PAGE_ROUTES } from '../constants/routes'
import FullStory from 'react-fullstory';
import { checkIfCanPerformAction } from '../utils/permission'
import FrontChat from '../ui/atoms/frontChat/FrontChat'

const Login = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const { setBusinessData } = useUserCache();
  const { addToast, toast } = useToast()
  const { setSalonData } = useSalonCache()

  React.useEffect(() => {

    if (window.location.search.includes('token')) {
      localStorage.clear();
      const token = window.location.search.split('=')[1]
      axios.post('/graphql',
        {
          query: print(UserDocument),
        },
        {
          headers: { Authorization: `Bearer ${token}`}
        }
      ).then((response) => {
        const { data: { data: { user } } } = response
        localStorage.setItem('token', token)
        localStorage.setItem('userData', JSON.stringify(user))
        localStorage.setItem('role', JSON.stringify(user.salonStaff.staffRole))
        localStorage.setItem('country', JSON.stringify(user.business.country))

        if (!user.emailVerified) {
          window.location.href = '/otp'
        } else {
          setSalonData(user.business.salons[0])
          setBusinessData(user.business)
          checkIfCanPerformAction("Home::ViewPage", user?.salonStaff?.staffRole?.authorizations) ? window.location.href = '/home' : window.location.href = '/calendar'
        }
      })

    }

    const token = localStorage.getItem("token");

    if (token) {
      // decode token using jwt-decode
      const decodedToken = token ? jwtDecode(token) : null;
      if (decodedToken && decodedToken.exp * 1000 > Date.now()) {
        window.location.href = PAGE_ROUTES.HOME;
      } else {
        localStorage.clear();
      }
    }
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<LoginInput>({
    delayError: 100,
    mode: 'onChange'
  })

  const onSubmit = async (input: LoginInput) => {
    try {
      setIsLoading(true)
      const {
        data: {
          data: { login }
        }
      } = await axios.post('/graphql', {
        query: print(LoginDocument),
        operationName: "Login",
        variables: {
          input: {
            email: input?.email?.toLowerCase(),
            password: input?.password
          }
        }
      })

      if (login.status === 200) {
        localStorage.setItem('token', login.token)
        localStorage.setItem('userData', JSON.stringify(login.user))
        localStorage.setItem('country', JSON.stringify(login.user.business.country))
        localStorage.setItem('role', JSON.stringify(login.user?.salonStaff?.staffRole))

        if (!login.user.emailVerified) {
          window.location.href = '/otp'
        } else {
          if (login.user?.business?.salons?.length) {
            setSalonData(login.user.business.salons[0])
            setBusinessData(login.user.business)
            checkIfCanPerformAction("Home::ViewPage", login.user?.salonStaff?.staffRole?.authorizations) ? window.location.href = '/home' : window.location.href = '/calendar'
          } else {
            window.location.href = '/account-setup'
          }
        }
      } else {
        addToast({
          variant: 'error',
          message: login.errors[0].message
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      addToast({
        variant: 'error',
        message: error.message
      })
    }
  }

  // const goToRegisterPage = () => {
  //   window.location.href = '/register'
  // }

  const goToForgotPasswordPage = () => {
    window.location.href = '/forgot_password'
  }
	// @ts-expect-error Env variables has been set in the application.html.erb
	const ORG_ID = envVariables.fullstoryOrgId

  return (
    <>
      <FullStory org={ORG_ID} />
      {/* <FrontChat /> */}
      <main className='flex items-center justify-center min-h-screen bg-white xl:bg-grey-100'>
        <div className='table-row'>
          <div className='table-cell align-middle'>
            <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-8 px-9 max-w-[480px] xl:w-[580px] xl:max-w-[700px] space-y-6'>
              <div className='flex flex-col items-center space-y-6'>
                <Logo />
                <Heading
                  variant='h1'
                  size='h8'
                  weight='medium'
                  className='w-full text-center'
                >
                  Login to your account
                </Heading>
              </div>
              <ToastWrapper toast={toast as ToastProps} />
              <form
                onSubmit={handleSubmit(onSubmit)}
                className='w-full space-y-6'
                autoComplete='off'
              >
                <Input
                  name='email'
                  label='Email'
                  id='email'
                  type='email'
                  placeholder='email@acme.com'
                  control={control}
                  rules={{
                    required: ERRORS.EMAIL_REQUIRED,
                    pattern: REGEX_PATTERNS.EMAIL
                  }}
                  error={errors.email}
                />
                <PasswordInput
                  name='password'
                  label='Password'
                  id='password'
                  type='password'
                  placeholder='**********'
                  control={control}
                  rules={{
                    required: ERRORS.PASSWORD_REQUIRED,
                    minLength: {
                      message: 'Password must be at least 6 characters',
                      value: 6
                    }
                  }}
                  error={errors.password}
                />

                <Button
                  variant='primary'
                  className='uppercase'
                  disabled={!isValid}
                  loading={isLoading}
                  size='lg'
                  rounded='lg'
                >
                  Log in
                </Button>
              </form>
              <Button
                variant='text'
                className='text-b5 mx-auto'
                fontWeight='bold'
                size='none'
                type='button'
                onClick={goToForgotPasswordPage}
              >
                Forgot password?
              </Button>
              {/* <div className='w-full flex justify-center space-x-2'>
                <Paragraph size='b5' className='' color={COLORS.GREY[400]}>
                  Don't have a Splice Business account?
                </Paragraph>
                <Button
                  variant='text'
                  className='text-b5 mx-0'
                  fontWeight='bold'
                  size='none'
                  type='button'
                  onClick={goToRegisterPage}
                >
                  Register
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Login
