import React, { Fragment, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Layout,
  Modal,
  Paragraph,
  SearchTerm,
  TableFilterProps,
  TableSkeleton,
  ToastWrapper
} from '../ui'
import { SvgArrowBack, SvgChevronLeft, SvgFilter } from '../ui/icons'
import { Menu, Transition } from '@headlessui/react'
import ClosedBusinessProvider, {
  useClosedBusinessPeriodContext
} from '../context/closedBusinessPeriodContext'
import Label from '../ui/atoms/formLabel/Label'
import { ClosedPeriod, CreateClosedPeriodInput } from '../graphql/generated'
import {
  convertFullDateStringToDate,
  formatTableHeadersFilterArray,
  getDayMonthAndNumberFromDateString
} from '../utils/misc'
import FullTable from '../ui/organism/table/Table'
import { useModal, useToast } from '../hooks'
import { COLORS } from '../constants/colors'
import { Controller, useForm } from 'react-hook-form'
import { ERRORS } from '../constants/errors'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import type { DatePickerProps } from 'antd';
import { useSalonCache } from '../hooks/useSalonCache'

const CLOSED_PERIOD_HEADINGS = [
  {
    label: 'Title',
    value: 'title',
    show: true
  },
  {
    label: 'Start Date',
    value: 'startDate',
    show: true
  },
  {
    label: 'End Date',
    value: 'endDate',
    show: true
  }
]

const MOBILE_CLOSED_PERIOD_HEADINGS = [
  {
    title: 'Title',
    key: 'title'
  },
  {
    title: 'Start Date',
    key: 'startDate'
  },
  {
    title: 'End Date',
    key: 'endDate'
  }
]

const BusinessClosedPeriod = () => {
  return (
    <>
      <ClosedBusinessProvider>
        <MainContent />
      </ClosedBusinessProvider>
    </>
  )
}

const MainContent = () => {
  const [loading, setLoading] = useState(false)
  const { closedPeriods, getClosedBusinessPeriods, toast } =
    useClosedBusinessPeriodContext()
  const [closedPeriod, setClosedPeriod] = useState<ClosedPeriod>()
  const [businessClosedPeriodHeadings, setBusinessClosedPeriodHeadings] =
    useState(CLOSED_PERIOD_HEADINGS)

  const {
    isVisible: isDeleteClosedPeriodDialogModalVisible,
    openModal: openDeleteClosedPeriodDialogModal,
    closeModal: closeDeleteClosedPeriodDialogModal
  } = useModal()

  const {
    isVisible: isAddClosedPeriodModalVisible,
    openModal: openAddClosedPeriodModal,
    closeModal: closeAddClosedPeriodModal
  } = useModal()

  const handleCloseAddClosedPeriodModal = () => {
    setClosedPeriod(null)
    closeAddClosedPeriodModal()
  }

  useEffect(() => {
    setLoading(true)
    getClosedBusinessPeriods()
    setLoading(false)
  }, [])

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in productHeadings then update the show
    const updatedHeadings = businessClosedPeriodHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setBusinessClosedPeriodHeadings(updatedHeadings)
  }

  const generateBusinessClosedPeriodsTableData = (
    periods: ClosedPeriod[],
    tableHeadings: TableFilterProps
  ) => {
    return periods?.map?.((period) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'title':
              rowData[heading.value] = period?.title
              break
            case 'startDate':
              rowData[heading.value] = getDayMonthAndNumberFromDateString(
                period?.startAt
              )
              break
            case 'endDate':
              rowData[heading.value] = getDayMonthAndNumberFromDateString(
                period?.endAt
              )
              break
          }
        }
      })
      return rowData
    })
  }

  const openBusinessClosedPeriod = (id: number, action?: string) => {
    setClosedPeriod(closedPeriods[id])

    switch (action) {
      case 'delete':
        openDeleteClosedPeriodDialogModal()
        break
      default:
        openAddClosedPeriodModal()
        break
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const initiateCloseDeleteClosedPeriodDialogModal = (action?: string) => {
    closeDeleteClosedPeriodDialogModal()
    setClosedPeriod(null)
  }

  const getBusinessClosedPeriodContent = () => {
    if (loading) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    } else if (Array.isArray(closedPeriods) && closedPeriods.length > 0) {
      const tableClientsData = generateBusinessClosedPeriodsTableData(
        closedPeriods,
        businessClosedPeriodHeadings
      )
      return (
        <FullTable
          headers={formatTableHeadersFilterArray(businessClosedPeriodHeadings)}
          mobileHeaders={MOBILE_CLOSED_PERIOD_HEADINGS}
          rows={tableClientsData}
          onClick={openBusinessClosedPeriod}
          tableOptions={{
            view: true,
            delete: true,
            edit: true,
            duplicate: false
          }}
        />
      )
    }
  }

  return (
    <Layout pageTitle='Staff'>
      <ToastWrapper toast={toast} />
      <div className='flex flex-col w-full h-full'>
        <div className='flex flex-col'>
          <div className='flex items-center justify-between px-6 py-3 border-b border-grey-100'>
            {/* day */}
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              fontWeight='semiBold'
              onClick={() => history.back()}
            >
              <SvgArrowBack width='24px' height='24px' />{' '}
              <span>Business closed period</span>
            </Button>

            {/* button */}
            <Button
              variant='primary'
              size='sm'
              type='button'
              rounded='md'
              fontSize='b4'
              onClick={openAddClosedPeriodModal}
            >
              Add
            </Button>
          </div>
        </div>

        <div className='flex flex-col xl:flex-row py-4 px-8 space-x-4'>
          <div className='w-full xl:w-6/12 flex items-center space-x-4'>
            <SearchTerm
              placeholder='Search by name, date'
              setDebouncedSearchQuery={() => {}}
            />
            <Menu as='div' className='relative inline-block text-left'>
              <div>
                <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                  <SvgFilter width='20px' height='20px' />
                  <span className='hidden xl:inline'>Filter</span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                  <div className='flex flex-col p-4 space-y-4'>
                    {Array.isArray(businessClosedPeriodHeadings) &&
                      businessClosedPeriodHeadings.length &&
                      businessClosedPeriodHeadings.map((heading, index) => (
                        <Label
                          className='flex space-x-2 items-center cursor-pointer'
                          key={index}
                          htmlFor={heading.value}
                          onClick={() =>
                            handleHeadingCheckboxChange(heading.value)
                          }
                        >
                          <Checkbox
                            isChecked={heading.show}
                            borderType='Black'
                            size='Sm'
                          />
                          <span>{heading.label}</span>
                        </Label>
                      ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        {getBusinessClosedPeriodContent()}
        <DeleteClosedPeriodModal
          isVisible={isDeleteClosedPeriodDialogModalVisible}
          closeModal={initiateCloseDeleteClosedPeriodDialogModal}
          closedPeriod={closedPeriod}
        />
        <AddClosedPeriodModal
          isVisible={isAddClosedPeriodModalVisible}
          closeModal={handleCloseAddClosedPeriodModal}
          closedPeriod={closedPeriod}
        />
      </div>
    </Layout>
  )
}

type DeleteClosedPeriodDialogProps = {
  isVisible: boolean
  closeModal: (action?: string) => void
  closedPeriod?: ClosedPeriod
}
const DeleteClosedPeriodModal = ({
  closedPeriod,
  isVisible,
  closeModal
}: DeleteClosedPeriodDialogProps) => {
  const { deleteClosedPeriod } = useClosedBusinessPeriodContext()
  const [loading, setLoading] = useState(false)
  const handleDelete = () => {
    setLoading(true)
    deleteClosedPeriod(closedPeriod.id, _closeModal)
    setLoading(false)
  }

  const _closeModal = () => {
    closeModal('delete')
  }
  return isVisible && closedPeriod ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Closed Period
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {closedPeriod?.title}? This
                means that this period will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={loading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => closeModal('back')}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export const AddClosedPeriodModal = ({
  isVisible,
  closeModal,
  closedPeriod
}: DeleteClosedPeriodDialogProps) => {

  const {
    isVisible: isDeleteClosedPeriodDialogModalVisible,
    openModal: openDeleteClosedPeriodDialogModal,
    closeModal: closeDeleteClosedPeriodDialogModal
  } = useModal()
  const [loading, setLoading] = useState(false)
  const { getSalonFieldValue } = useSalonCache();
  const { toast, addToast } = useToast();
  const { createClosedPeriod } = useClosedBusinessPeriodContext()
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<CreateClosedPeriodInput>({delayError: 100, mode: 'onChange'})

  const onChangeStartDate: DatePickerProps['onChange'] = (date, dateString) => {
    setValue('startAt', dateString);
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date, dateString) => {
    setValue('endAt', dateString);
  };

  const clearForm = () => {
    setValue('title', '')
    setValue('startAt', '')
    setValue('endAt', '')
  }

  const handleClose = () => {
    clearForm()
    closeModal()
  }

  const submitData = async (input: CreateClosedPeriodInput) => {
    setLoading(true)
    await createClosedPeriod({...input, id: closedPeriod ? closedPeriod.id : null, salonId: getSalonFieldValue('id')}, handleClose, addToast)
    setLoading(false)
  }

  useEffect(() => {
    if (closedPeriod) {
      const data = closedPeriod
      const startAt = convertFullDateStringToDate(data.startAt);
      const endAt = convertFullDateStringToDate(data.endAt);
      setValue('title', data.title);
      setValue('startAt', startAt);
      setValue('endAt', endAt);
    } else {
      clearForm()
    }
  }, [closedPeriod])

  const initiateCloseDeleteClosedPeriodDialogModal = (action: string) => {
    switch (action) {
      case 'delete':
        closeDeleteClosedPeriodDialogModal()
        handleClose()
        break
      case 'back':
        closeDeleteClosedPeriodDialogModal()
        break
      default:
        break
    }
  } 

  return (
    <>
      <ToastWrapper toast={toast} />
      <Modal show={isVisible} closeModal={handleClose} variant='right'>
        <form
          style={{marginTop: '80px', marginBottom: '80px'}}
          onSubmit={handleSubmit(submitData)}
          className='w-full relative my-[80px]'
          autoComplete='off'
        >
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={handleClose}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={handleClose}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                Closed Period
              </Paragraph>
            </div>
          </div>
          <div style={{padding: '1rem 1.5rem 1rem 1.5rem'}} className='w-full flex flex-col px-6 py-4 space-y-6'>
            <Heading variant='h1' size='h9' weight='semiBold'>
              Business closed period
            </Heading>
            <Paragraph size='b4'>
              {!closedPeriod
                ? 'Add business closed period'
                : 'Edit business closed period'}
            </Paragraph>
          </div>
          <div className='w-full p-6 space-y-6'>
            <Input
              name='title'
              label='Title'
              id='title'
              type='text'
              placeholder='e.g Closed for renovation'
              control={control}
              rules={{
                required: ERRORS.TITLE_REQUIRED
              }}
              error={errors.title}
            />
            <div className='w-full flex gap-x-4'>
              <Controller
                control={control}
                name='startAt'
                render={({ field: { value }, formState: { errors } }) => {
                  // @ts-expect-error services is of type array but the error payload will be an object with "message" property
                  const errorMessage: string = errors?.startAt?.message
                  return (
                    <div className='w-full flex flex-col space-y-2'>
                      <FormLabel htmlFor='start-date'>Start Date</FormLabel>
                      <DatePicker
                        style={{padding: '13px'}}
                        className='splice-time-input'
                        value={value ? dayjs(value, 'YYYY-MM-DD') : undefined}
                        onChange={onChangeStartDate}
                        format={'YYYY-MM-DD'}
                      />
                      {errorMessage && (
                        <FormHelperText variant='error'>
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </div>
                  )
                }}
                rules={{
                  required: ERRORS.DATE_REQUIRED
                }}
              />
            </div>
            <div className='w-full flex gap-x-4'>
              <Controller
                control={control}
                name='endAt'
                render={({ field: { value }, formState: { errors } }) => {
                  // @ts-expect-error services is of type array but the error payload will be an object with "message" property
                  const errorMessage: string = errors?.endAt?.message
                  return (
                    <div className='w-full flex flex-col space-y-2'>
                      <FormLabel htmlFor='appointment-date'>End Date</FormLabel>
                      <DatePicker
                        style={{padding: '13px'}}
                        className='splice-time-input'
                        value={value ? dayjs(value, 'YYYY-MM-DD') : undefined}
                        onChange={onChangeEndDate}
                        format={'YYYY-MM-DD'}
                      />
                      {errorMessage && (
                        <FormHelperText variant='error'>
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </div>
                  )
                }}
                rules={{
                  required: ERRORS.DATE_REQUIRED
                }}
              />
            </div>
          </div>
          <div style={{maxWidth: '800px', bottom: '0px'}} className='w-full max-w-[800px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
            <div className='w-full flex justify-between py-4 px-8'>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                type='button'
                className='mx-0 text-grey-900'
                onClick={handleClose}
              >
                Cancel
              </Button>
              <div className='flex space-x-4'>
                {closedPeriod && (
                  <Button
                    variant='text'
                    size='none'
                    rounded='none'
                    fontSize='b5'
                    type='button'
                    className='mx-0 text-red-600'
                    disabled={false}
                    onClick={openDeleteClosedPeriodDialogModal}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  variant='primary'
                  className=''
                  disabled={false}
                  loading={loading}
                  size='md'
                  rounded='lg'
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <DeleteClosedPeriodModal
        isVisible={isDeleteClosedPeriodDialogModalVisible}
        closeModal={initiateCloseDeleteClosedPeriodDialogModal}
        closedPeriod={closedPeriod}
      />
    </>
  )
}

export default BusinessClosedPeriod
