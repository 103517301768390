import * as React from "react";
import { TableProps } from './types';
import { TableBody, TableHead, TableSkeleton } from '../..';
import EmptyState from "../reportTable/EmptyState";

const FullTable = ({
  rows,
  headers,
  mobileHeaders,
  onClick,
  hasBorder,
  hasFooterBg,
  tableOptions,
  loading
}: TableProps) => {

  return (
    <>
      <table className="w-full table-auto">
        <TableHead headers={headers} mobileHeaders={mobileHeaders} />
        {!loading && (
          <TableBody
            rows={rows}
            mobileRows={mobileHeaders}
            onClick={onClick}
            tableOptions={tableOptions}
            hasBorder={hasBorder}
            hasFooterBg={hasFooterBg}
          />
        )}
      </table>
      {loading && (
        <TableSkeleton />
      )}
      {rows && !loading && !rows?.length && (
        <EmptyState />
      )}
    </>
  );
}

export default FullTable
