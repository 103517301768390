import { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import {
  REACT_FLAGS_COUNTRY_CODES,
  REACT_FLAGS_DEFAULT_COUNTRY_CODE,
} from "./constants";
import { CountrySelectProps } from "./types";
import * as React from "react";

const CountrySelect = ({
  disabled,
  onSelect,
  value,
}: CountrySelectProps) => {
  const [selected, setSelected] = useState<string>("");
  const defaultCountryCode = value || REACT_FLAGS_DEFAULT_COUNTRY_CODE;

  const onSelectCountry = (alpha2Code: string) => {
    typeof onSelect === "function" &&
      onSelect(
        REACT_FLAGS_COUNTRY_CODES[alpha2Code]?.secondary as string,
        alpha2Code
      );
  };

  useEffect(() => {
    _onSelect(defaultCountryCode);
  }, []);

  const _onSelect = (alpha2Code: string): void => {
    setSelected(alpha2Code);
    onSelectCountry(alpha2Code);
  };

  return (
    <div className="flex items-center" style={{height: '49px', padding: '2px 2px 2px 2px', backgroundColor: 'rgb(233 233 233 / 1)'}}>
      <ReactFlagsSelect
        disabled={disabled}
        showSelectedLabel={false}
        showSecondarySelectedLabel
        searchable
        selected={value || selected}
        onSelect={_onSelect}
        placeholder="+"
        customLabels={REACT_FLAGS_COUNTRY_CODES}
        className="menu-flags"
        // className='w-2 flex items-center px-2 py-1.5 bg-grey-20 border text-grey-900 border-grey-100 border-r-0 rounded-md rounded-tr-none rounded-br-none text-b6 xl:text-b5'
        // className="react-flag-select"
      />

    </div>
  );
};

export default CountrySelect