import React, { useContext, useEffect } from 'react'
import { INITIAL_STEP, ITEM_PURCHASED_OR_ACTION } from './constants';
import SvgTimeManagementRafiki from '../../ui/icons/TimeManagementRafiki';
import SvgSuccessfulPurchasePana from '../../ui/icons/SuccessfulPurchasePana';
import SvgGiftCardAmico from '../../ui/icons/GiftCardAmico';
import { formatInToPrice, getDayMonthAndNumberAndTimeFromDate, getHoursAndMinutesString, getNumberMonthYearAndTimeFromDate } from '../../utils/misc';
import Heading from '../../ui/atoms/heading/Heading';
import Paragraph from '../../ui/atoms/paragraph/Paragraph';
import Button from '../../ui/atoms/button/Button';
import { COLORS } from '../../constants/colors';
import { getVoucherRecipientsNamesString, getVouchersNamesString, getVouchersServicesNamesString } from './utils';
import BookContext from './context';
import BookingDetailsCard from './BookingDetailsCard';
import { SvgCalendar, SvgCartBag, SvgLogo, SvgUser } from '../../ui/icons';
import { useModal } from '../../hooks';
import { Modal } from '../../ui';

const PurchaseSuccessful = () => {
  const { handleNextStep, selectedServices, voucherServices, setVoucherServices, businessInfo, selectedSalon, priceSummary, modifyPackageInCart, selectedPackages, setAppointmentDateTime, voucherToBeRedeemed, setVoucherToBeRedeemed, clearServicesInCart, bookedAppointment, serviceClient, setServiceClient, voucherPurchasedData, setVoucherPurchasedData, setOpenVoucherRedemptionModal, appointmentDateTime, setPromoCodeApplication, appointmentPaymentType, setPackagesPriceSummary, setBookedAppointment, setPriceSummary, setRewards, setSelectedReward, setRewardSelected, isMobile } = useContext(BookContext);
  const [itemPurchased, setItemPurchased] = React.useState<string | null>(null);

  const {
    isVisible: receiptModalIsVisible,
    openModal: openReceiptModal,
    closeModal: closeReceiptModal,
  } = useModal();

  useEffect(() => {
    if (selectedPackages && Array.isArray(selectedPackages) && selectedPackages.length) {
      setItemPurchased(ITEM_PURCHASED_OR_ACTION.VOUCHER);
    } else if (selectedServices && Array.isArray(selectedServices) && selectedServices.length) {
      setItemPurchased(ITEM_PURCHASED_OR_ACTION.SERVICE);
    } else {
      setItemPurchased(ITEM_PURCHASED_OR_ACTION.REDEEMED);
    }
  }, [])

  const getSuccessfulLogoView = () => {
    if (itemPurchased === ITEM_PURCHASED_OR_ACTION.SERVICE) {
      return <SvgTimeManagementRafiki height="100%" className="w-[250px]" />
    } else if (itemPurchased === ITEM_PURCHASED_OR_ACTION.VOUCHER) {
      return <SvgSuccessfulPurchasePana height="100%" className="w-[250px]" />
    } else {
      return <SvgGiftCardAmico height="100%" className="w-[250px]" />
    }
  }

  const getHeadingText = () => {
    if (itemPurchased === ITEM_PURCHASED_OR_ACTION.SERVICE) {
      return 'Appointment booked  successfully'
    } else if (itemPurchased === ITEM_PURCHASED_OR_ACTION.VOUCHER) {
      return 'Purchase successful'
    } else {
      return 'Voucher redeemed successfully'
    }
  }

  const getSuccessfulText = () => {
    if (itemPurchased === ITEM_PURCHASED_OR_ACTION.SERVICE) {
      return `Your appointment at ${businessInfo?.name} for ${appointmentDateTime ? getNumberMonthYearAndTimeFromDate(appointmentDateTime) : ''} was successful. A confirmation email will be sent shortly.`
    } else if (itemPurchased === ITEM_PURCHASED_OR_ACTION.VOUCHER) {
      return 'Your purchase of ' + selectedPackages.map(({ name }) => name).join(', ') + ' voucher(s) at ' + businessInfo?.name + ' was successful. An email will be sent shortly.'
    } else {
      return `Your ${voucherToBeRedeemed?.package?.name} Voucher with value of ${voucherToBeRedeemed ? formatInToPrice(voucherToBeRedeemed?.price, businessInfo?.country?.currency?.symbol) : ''} at ${businessInfo?.name} was redeemed successfully. An email will be sent shortly.`
    }
  }

  const getActionButtonText = () => {
    if (itemPurchased === ITEM_PURCHASED_OR_ACTION.SERVICE) {
      return 'Make another appointment'
    } else if (itemPurchased === ITEM_PURCHASED_OR_ACTION.VOUCHER) {
      return 'Buy another voucher'
    } else {
      return 'Purchase another voucher'
    }
  }

  const goToBookingInitialPage = () => {
    setAppointmentDateTime(null);
    setVoucherToBeRedeemed(null);
    setServiceClient(null)
    clearServicesInCart();
    setVoucherPurchasedData(null)
    modifyPackageInCart('', "clear");
    handleNextStep(INITIAL_STEP);
    setPromoCodeApplication(null)
    setPackagesPriceSummary(null)
    setBookedAppointment(null)
    setPriceSummary(null)
    setVoucherServices([])
    setRewards([])
    setSelectedReward(null)
    setRewardSelected(false)
  }

  const goToRedeemVoucher = () => {
    goToBookingInitialPage()
    setOpenVoucherRedemptionModal(true)
  }
  
  return (
    <>
      <div>
        {Array?.isArray(selectedPackages) && selectedPackages?.length || !isMobile && Array?.isArray(selectedServices) && selectedServices?.length ? (
          <div className='w-full flex flex-col pt-[10px] md:pt-[30px]'>
            <div className='w-full max-w-[950px] flex flex-col xl:flex-row items-center justify-center p-[10px] xl:p-[48px] md:border border-grey-50 rounded-xl gap-20 md:gap-8 md:shadow-md mx-auto'>
              <div className='w-full h-fit flex flex-col items-center gap-8 p-0 md:pr-4 border-b mb:border-b-0 pb-6 md:pb-0 md:border-r border-grey-100'>
                {getSuccessfulLogoView()}
                <Heading variant='h1' size='h6' weight='bold' color={COLORS.BLACK} className='text-center'>{getHeadingText()}</Heading>
                <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                  {getSuccessfulText()}
                </Paragraph>

                {Array.isArray(selectedPackages) && selectedPackages?.length ? (
                  <Button
                    variant='secondary'
                    className=''
                    size='lg'
                    rounded='lg'
                    onClick={goToRedeemVoucher}
                  >
                    Redeem Voucher
                  </Button>
                ) : null}
                <Button
                  variant='primary'
                  className=''
                  size='lg'
                  rounded='lg'
                  onClick={goToBookingInitialPage}
                >
                  {getActionButtonText()}
                </Button>
              </div>
              <div className='w-full h-fit flex flex-col items-center xl:max-w-[250px]'>
                <div className='w-full max-w-[490px] py-8 bg-white h-full flex flex-col items-center space-y-5 rounded-md overflow-y-auto'>
                  <div className='w-full gap-4 flex flex-col items-center'>
                    <Heading variant='h1' size='h9' weight='bold' className='text-center'>{businessInfo?.name}</Heading>
                    <Paragraph size='b7' weight='normal' color={COLORS.GREY['300']} className='text-center'>{`${selectedSalon?.address}, ${selectedSalon?.city}, ${selectedSalon?.state}`}</Paragraph>
                  </div>
                  <div className='w-full border-t border-grey-100'></div>
                  {bookedAppointment && (itemPurchased === ITEM_PURCHASED_OR_ACTION.SERVICE || itemPurchased === ITEM_PURCHASED_OR_ACTION.REDEEMED) ? (
                    <div className="w-full flex flex-col px-6">
                      <div className='w-full space-y-3 flex flex-col items-center mx-auto'>
                        <Heading variant='h2' size='b5' weight='bold' className='text-center'>{!voucherToBeRedeemed ? 'Thank you for your order.' : 'Your voucher ' + voucherToBeRedeemed?.code + ' has just been redeemed'}</Heading>
                      </div>
                      <div className='w-full flex flex-col space-y-2 py-4 border-b-2 border-dashed border-grey-50'>
                        <div className='w-full flex justify-between items-center py-2'>
                          <Paragraph size='b7' color={COLORS.GREY[300]}>Customer name</Paragraph>
                          <Paragraph size='b7'>{serviceClient?.fullName}</Paragraph>
                        </div>
                        {appointmentDateTime ? (
                          <div className='w-full flex justify-between items-center py-2'>
                            <Paragraph size='b7' color={COLORS.GREY[300]}>Day</Paragraph>
                            <Paragraph size='b7'>{getNumberMonthYearAndTimeFromDate(appointmentDateTime)}</Paragraph>
                          </div>
                        ) : null}
                        <div className='w-full flex justify-between items-center py-2'>
                          <Paragraph size='b7' color={COLORS.GREY[300]}>Booking ID</Paragraph>
                          <Paragraph size='b7'>{bookedAppointment?.bookingId}</Paragraph>
                        </div>
                      </div>
                      <div className='w-full flex flex-col space-y-2'>
                        <div className='w-full flex justify-between items-center bg-grey-50 p-2'>
                          <Paragraph size='b7' color={COLORS.GREY[300]}>Item</Paragraph>
                          <Paragraph size='b7' color={COLORS.GREY[300]}>Amount</Paragraph>
                        </div>
                        {!voucherToBeRedeemed ? (
                          <>
                            {selectedServices?.map((service) => (
                              <div className='w-full flex justify-between items-center py-2' key={service?.id}>
                                <div className='flex flex-col'>
                                  <Paragraph size='b7' color={COLORS.GREY[300]}>{service?.name} x{service?.quantity}</Paragraph>
                                  {service?.staff ? <Paragraph size='b7' color={COLORS.GREY[300]}>Staff: {service?.staff?.user?.firstName} {service?.staff?.user?.lastName}</Paragraph> : null}
                                </div>
                                <Paragraph size='b7'>{formatInToPrice(service?.staffPrice || service?.price, businessInfo?.country?.currency?.symbol)}</Paragraph>
                              </div>
                            ))}
                            <div className='border-t border-grey-50'></div>
                            <div className='w-full flex justify-between items-center py-2'>
                              <Paragraph size='b7' color={COLORS.GREY[400]}>Subtotal</Paragraph>
                              <Paragraph size='b7'>{formatInToPrice(bookedAppointment?.totalServicesAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                            </div>
                            {bookedAppointment?.taxAmount ? (
                              <div className='w-full flex justify-between items-center py-2'>
                                <Paragraph size='b7' color={COLORS.GREY[400]}>Tax</Paragraph>
                                <Paragraph size='b7'>{formatInToPrice(bookedAppointment?.taxAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                              </div>
                            ) : null}
                            {bookedAppointment?.processingFeeAmount || priceSummary?.processingFeeAmount ? (
                              <div className='w-full flex justify-between items-center py-2'>
                                <Paragraph size='b7' color={COLORS.GREY[400]}>Processing Fee</Paragraph>
                                <Paragraph size='b7'>{formatInToPrice(bookedAppointment?.processingFeeAmount || priceSummary?.processingFeeAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                              </div>
                            ) : null}
                            {bookedAppointment?.discountAmount && bookedAppointment?.discountAmount > 0 ? (
                              <div className='w-full flex justify-between items-center py-2'>
                                <Paragraph size='b7' color={COLORS.GREY[400]}>Discount</Paragraph>
                                <Paragraph size='b7'>-{formatInToPrice(bookedAppointment?.discountAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                              </div>
                            ) :
                              <div className='w-full flex justify-between items-center py-2'>
                                <Paragraph size='b7' color={COLORS.GREY[400]}>Discount</Paragraph>
                                <Paragraph size='b7'>-{bookedAppointment?.appointmentPromo && bookedAppointment?.appointmentPromo?.amount != 0.0 ? formatInToPrice(bookedAppointment?.appointmentPromo?.amount) : formatInToPrice(0)}</Paragraph>
                              </div>}
                            {bookedAppointment?.depositAmount > 0 && appointmentPaymentType === 'deposit_payment' ? (
                              <div className='w-full flex justify-between items-center py-2'>
                                <Paragraph size='b7' color={COLORS.GREY[400]}>Deposit Made</Paragraph>
                                <Paragraph size='b7'>{formatInToPrice(bookedAppointment?.depositAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {voucherToBeRedeemed && voucherServices && Array?.isArray(voucherServices) && voucherServices?.filter(service => service?.selected)?.map((service) => (
                              <div className='w-full flex justify-between items-center py-2' key={service?.id}>
                                <div className='flex flex-col'>
                                  <Paragraph size='b7' color={COLORS.GREY[300]}>{service?.name} x{service?.quantity}</Paragraph>
                                  {service?.staff ? <Paragraph size='b7' color={COLORS.GREY[300]}>Staff: {service?.staff?.user?.firstName} {service?.staff?.user?.lastName}</Paragraph> : null}
                                </div>
                                <Paragraph size='b7'>-</Paragraph>
                              </div>
                            ))}
                          </>
                        )}
                        <div className='border-t border-grey-50'></div>
                        <div className='w-full flex justify-between items-center py-2'>
                          <Paragraph size='b7' weight='bold' className='inline' color={COLORS.GREY[300]}>Total</Paragraph>
                          <Paragraph size='b7' weight='bold' className='inline'>{voucherToBeRedeemed ? formatInToPrice(0, businessInfo?.country?.currency?.symbol) : formatInToPrice(priceSummary?.totalPaid, businessInfo?.country?.currency?.symbol)}</Paragraph>
                        </div>

                        {bookedAppointment?.depositAmount > 0 && appointmentPaymentType === 'deposit_payment' ? (
                          <div className='w-full flex justify-between items-center py-2'>
                            <Paragraph size='b7' color={COLORS.GREY[300]} weight="bold">Outstanding Balance</Paragraph>
                            <Paragraph size='b7' weight="bold">{formatInToPrice(bookedAppointment?.totalPaid - bookedAppointment?.depositAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : Array.isArray(selectedPackages) && selectedPackages?.length && voucherPurchasedData ? (
                    <div className="w-full flex flex-col px-6">
                      <div className='w-full space-y-3 flex flex-col items-center mx-auto'>
                        <Heading variant='h2' size='b5' weight='bold' className='text-center'>Thank you for your purchase.</Heading>
                        <Paragraph size='b7' weight='normal' color={COLORS.GREY['300']} className='text-center'>Your purchase was successful, kindly find the attached details below.</Paragraph>
                      </div>
                      <div className='w-full flex flex-col space-y-2 py-6 border-b-2 border-dashed border-grey-50'>
                        <div className='w-full flex justify-between gap-4 items-center py-2'>
                          <Paragraph size='b7' color={COLORS.GREY[300]} className="w-1/2">Customer name</Paragraph>
                          <Paragraph size='b7' className="w-1/2 flex justify-end">{getVoucherRecipientsNamesString(voucherPurchasedData?.recipients)}</Paragraph>
                        </div>
                      </div>
                      <div className='w-full flex flex-col space-y-2 py-4 border-b-2 border-dashed border-grey-50'>
                        <div className='w-full flex justify-between gap-4 items-center py-2'>
                          <Paragraph size='b7' color={COLORS.GREY[300]} className="w-1/2">Vouchers</Paragraph>
                          <Paragraph size='b7' className="w-1/2 flex justify-end">{getVouchersNamesString(selectedPackages)}</Paragraph>
                        </div>
                        <div className='w-full flex justify-between gap-4 items-center py-2'>
                          <Paragraph size='b7' color={COLORS.GREY[300]} className="w-1/2">Contents of voucher</Paragraph>
                          <Paragraph size='b7' className="w-1/2 flex justify-end">{getVouchersServicesNamesString(selectedPackages)}</Paragraph>
                        </div>
                      </div>
                      <div className='w-full flex flex-col space-y-2'>
                        <div className='w-full flex justify-between items-center py-2'>
                          <Paragraph size='b7' color={COLORS.GREY[400]}>Subtotal</Paragraph>
                          <Paragraph size='b7'>{formatInToPrice(voucherPurchasedData?.subTotal, businessInfo?.country?.currency?.symbol)}</Paragraph>
                        </div>
                        {voucherPurchasedData?.taxAmount ? (
                          <div className='w-full flex justify-between items-center py-2'>
                            <Paragraph size='b7' color={COLORS.GREY[400]}>Tax</Paragraph>
                            <Paragraph size='b7'>{formatInToPrice(voucherPurchasedData?.taxAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                          </div>
                        ) : null}
                        {voucherPurchasedData?.processingFeeAmount ? (
                          <div className='w-full flex justify-between items-center py-2'>
                            <Paragraph size='b7' color={COLORS.GREY[400]}>Processing Fee</Paragraph>
                            <Paragraph size='b7'>{formatInToPrice(voucherPurchasedData?.processingFeeAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                          </div>
                        ) : null}
                        <div className='w-full flex justify-between items-center py-2'>
                          <Paragraph size='b7' color={COLORS.GREY[400]}>Discount</Paragraph>
                          <Paragraph size='b7'>-{voucherPurchasedData?.discountAmount && typeof voucherPurchasedData?.discountAmount === 'number' ? formatInToPrice(voucherPurchasedData?.discountAmount, businessInfo?.country?.currency?.symbol) : formatInToPrice(0, businessInfo?.country?.currency?.symbol)}</Paragraph>
                        </div>
                        <div className='border-t border-grey-50'></div>
                        <div className='w-full flex justify-between items-center py-2'>
                          <Paragraph size='b7' weight='bold' className='inline' color={COLORS.GREY[300]}>Total</Paragraph>
                          <Paragraph size='b7' weight='bold' className='inline'>{formatInToPrice(voucherPurchasedData?.totalAmountPayable, businessInfo?.country?.currency?.symbol)}</Paragraph>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>) : null}
        {isMobile && (Array.isArray(selectedServices) && selectedServices?.length > 0 || voucherToBeRedeemed) ? (
          <>
            <div className='w-full h-full flex flex-col px-2 gap-8 mt-[60px] mb-[200px]'>
              {/* logo */}
              <div className='w-full flex flex-col items-center space-y-3'>
                <Heading variant='h1' size='h6' weight='bold' color={COLORS.BLACK} className='text-center'>Success</Heading>
                <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                  {getSuccessfulText()}
                </Paragraph>
              </div>
              {/* appointment details */}
              <div className='w-full px-5 py-4 flex flex-col bg-grey-50 rounded-sm space-y-3'>
                <div className='w-full flex justify-start items-center gap-2 text-green-300'>
                  <SvgCalendar width="20px" height="20px" />
                  <Paragraph size='b4' weight='semiBold' color={COLORS.GREEN[300]} className=''>Appointment Details</Paragraph>
                </div>
                {appointmentDateTime ?
                  <div className='w-full bg-green-300/10 border border-green-300/20 rounded-sm px-4 py-3 flex justify-between items-center'>
                    <div className='flex flex-col space-y-2'>
                      <Paragraph size='b6' weight='semiBold' color={COLORS.GREY[900]}>Date</Paragraph>
                      <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN[900]}>{getNumberMonthYearAndTimeFromDate(appointmentDateTime)}</Paragraph>
                    </div>
                  </div> : null}
                {voucherToBeRedeemed || serviceClient ?
                  <div className='w-full bg-green-300/10 border border-green-300/20 rounded-sm px-4 py-3 flex justify-between items-center'>
                    <div className='flex flex-col space-y-2'>
                      <Paragraph size='b6' weight='semiBold' color={COLORS.GREY[900]}>Who</Paragraph>
                      {voucherToBeRedeemed ?
                        <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN[900]}>
                        {voucherToBeRedeemed?.client?.firstName} {voucherToBeRedeemed?.client?.firstName} · <span className='text-grey-300'>{voucherToBeRedeemed?.client?.phone} | {voucherToBeRedeemed?.client?.email}</span>
                      </Paragraph> :
                        <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN[900]}>
                          {serviceClient?.fullName} · <span className='text-grey-300'>{serviceClient?.phone} | {serviceClient?.email}</span>
                        </Paragraph>}
                    </div>
                  </div> : null}
              </div>

              {/* cart details */}
              <div className='w-full px-5 py-4 flex flex-col bg-grey-50 rounded-sm space-y-3'>
                <div className='w-full flex justify-between items-center'>
                  <div className='w-full flex justify-start items-center gap-2 text-green-300'>
                    <SvgCartBag width="20px" height="20px" />
                    <Paragraph size='b4' weight='semiBold' color={COLORS.GREEN[300]} className=''>Order Summary</Paragraph>
                  </div>
                </div>
                {Array.isArray(selectedServices) && selectedServices.map((service) => {
                  const quantity = 1
                  return (
                    <div className='relative'>
                      <div className='w-full bg-green-300/10 border border-green-300/20 rounded-sm px-4 py-3 flex justify-between items-center'>
                        <div className='flex flex-col space-y-2'>
                          <Paragraph size='b6' weight='normal' color={COLORS.GREY['300']}>{service?.name}</Paragraph>
                          <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN['900']}>{service?.staffPrice ? formatInToPrice(service?.staffPrice * quantity, businessInfo?.country?.currency?.symbol) : service?.pricingType === 'from' ? (<span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full mr-2'>from</span>) : null} {!service?.staffPrice ? formatInToPrice(service?.price * quantity, businessInfo?.country?.currency?.symbol) : null} · <span className='text-grey-300'>{getHoursAndMinutesString(service?.duration)}</span></Paragraph>
                        </div>

                        <div className='flex items-center py-2 px-4 rounded-xl text-green-300 bg-green-300/10'>
                          <SvgUser width="16px" height="16px" />
                          <span className='text-green-300 text-b6 font-semibold'>{service?.staff?.user?.firstName}</span>
                        </div>
                      </div>
                    </div>
                  ) 
                })}
                {Array.isArray(voucherServices) && voucherServices.map((service) => {
                  return (
                    <div className='relative'>
                      <div className='w-full bg-green-300/10 border border-green-300/20 rounded-sm px-4 py-3 flex justify-between items-center'>
                        <div className='flex flex-col space-y-2'>
                          <Paragraph size='b6' weight='normal' color={COLORS.GREY['300']}>{service?.name}</Paragraph>
                          <Paragraph size='b6' weight='semiBold' color={COLORS.GREEN['900']}>{formatInToPrice(0, businessInfo?.country?.currency?.symbol)}</Paragraph>
                        </div>

                        <div className='flex items-center py-2 px-4 rounded-xl text-green-300 bg-green-300/10'>
                          <SvgUser width="16px" height="16px" />
                          <span className='text-green-300 text-b6 font-semibold'>{service?.staff?.user?.firstName}</span>
                        </div>
                      </div>
                    </div>
                  ) 
                })}

                <BookingDetailsCard showOnlyPrices={true} hidePromoApplication={true} />
              </div>
            </div>
          </>
        ) : null}
        {isMobile && (Array.isArray(selectedServices) && selectedServices?.length > 0 || voucherToBeRedeemed) ?
          <div className="w-full flex flex-col lg:hidden fixed bottom-0 h-[130px] px-[20px] space-y-3 bg-white justify-center items-center">
            <Button
              variant='primary'
              className='text-green-300'
              size='lg'
              rounded='lg'
              onClick={openReceiptModal}
            >
              Download receipt
            </Button>
            <Button
              variant='light'
              className='text-green-300'
              size='lg'
              rounded='lg'
              onClick={goToBookingInitialPage}
            >
              {getActionButtonText()}
            </Button>
          </div> : null}
      </div>
      <Modal
        title=""
        show={receiptModalIsVisible}
        closeModal={closeReceiptModal}
        variant='mobileCenter'
      >
        {bookedAppointment && (
          <div className='w-full flex flex-col space-y-[40px] p-4 bg-white'>
            <div className='space-y-2 flex flex-col justify-center items-center px-12'>
              <Heading variant='h1' size='h9' weight='bold'>{businessInfo?.name}</Heading>
              <Paragraph size='b6' weight='normal'>{selectedSalon?.address}</Paragraph>
            </div>
            <div className='w-full border-t border-grey-100'></div>
            <div className='space-y-3 flex flex-col items-center px-12'>
              <Heading variant='h2' size='h11' weight='bold' className='text-centter'>Thank you for your order.</Heading>
              <Paragraph size='b6' weight='normal' color={COLORS.GREY[300]} className='text-center'>Your order is confirmed, get ready to experience beauty at its best!</Paragraph>
            </div>
            <div className='w-full space-y-4'>
              <div className='flex justify-between'>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Booking ID: #{bookedAppointment?.bookingId}</Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Date: {getDayMonthAndNumberAndTimeFromDate(bookedAppointment?.startAt) || "-"}</Paragraph>
                </div>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Billed to: {serviceClient?.fullName}</Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Status: {bookedAppointment?.appointmentStatus}</Paragraph>
                </div>
              </div>
            </div>
            <div className='w-full flex flex-col space-y-2'>
              <div className='w-full flex justify-between items-center bg-grey-50 p-2'>
                <Paragraph size='b7' color={COLORS.GREY[300]}>Item</Paragraph>
                <Paragraph size='b7' color={COLORS.GREY[300]}>Amount</Paragraph>
              </div>
              {!voucherToBeRedeemed ? (
                <>
                  {selectedServices?.map((service) => (
                    <div className='w-full flex justify-between items-center py-2' key={service?.id}>
                      <div className='flex flex-col'>
                        <Paragraph size='b7' color={COLORS.GREY[300]}>{service?.name} x{service?.quantity}</Paragraph>
                        {service?.staff ? <Paragraph size='b7' color={COLORS.GREY[300]}>Staff: {service?.staff?.user?.firstName} {service?.staff?.user?.lastName}</Paragraph> : null}
                      </div>
                      <Paragraph size='b7'>{formatInToPrice(service?.staffPrice || service?.price, businessInfo?.country?.currency?.symbol)}</Paragraph>
                    </div>
                  ))}
                  <div className='border-t border-grey-50'></div>
                  <div className='w-full flex justify-between items-center py-2'>
                    <Paragraph size='b7' color={COLORS.GREY[400]}>Subtotal</Paragraph>
                    <Paragraph size='b7'>{formatInToPrice(bookedAppointment?.totalServicesAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                  </div>
                  {bookedAppointment?.taxAmount ? (
                    <div className='w-full flex justify-between items-center py-2'>
                      <Paragraph size='b7' color={COLORS.GREY[400]}>Tax</Paragraph>
                      <Paragraph size='b7'>{formatInToPrice(bookedAppointment?.taxAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                    </div>
                  ) : null}
                  {bookedAppointment?.processingFeeAmount || priceSummary?.processingFeeAmount ? (
                    <div className='w-full flex justify-between items-center py-2'>
                      <Paragraph size='b7' color={COLORS.GREY[400]}>Processing Fee</Paragraph>
                      <Paragraph size='b7'>{formatInToPrice(bookedAppointment?.processingFeeAmount || priceSummary?.processingFeeAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                    </div>
                  ) : null}
                  {bookedAppointment?.discountAmount && bookedAppointment?.discountAmount > 0 ? (
                    <div className='w-full flex justify-between items-center py-2'>
                      <Paragraph size='b7' color={COLORS.GREY[400]}>Discount</Paragraph>
                      <Paragraph size='b7'>-{formatInToPrice(bookedAppointment?.discountAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                    </div>
                  ) :
                    <div className='w-full flex justify-between items-center py-2'>
                      <Paragraph size='b7' color={COLORS.GREY[400]}>Discount</Paragraph>
                      <Paragraph size='b7'>-{bookedAppointment?.appointmentPromo && bookedAppointment?.appointmentPromo?.amount != 0.0 ? formatInToPrice(bookedAppointment?.appointmentPromo?.amount) : formatInToPrice(0)}</Paragraph>
                    </div>}
                  {bookedAppointment?.depositAmount > 0 && appointmentPaymentType === 'deposit_payment' ? (
                    <div className='w-full flex justify-between items-center py-2'>
                      <Paragraph size='b7' color={COLORS.GREY[400]}>Deposit Made</Paragraph>
                      <Paragraph size='b7'>{formatInToPrice(bookedAppointment?.depositAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {voucherToBeRedeemed && voucherServices && Array?.isArray(voucherServices) && voucherServices?.filter(service => service?.selected)?.map((service) => (
                    <div className='w-full flex justify-between items-center py-2' key={service?.id}>
                      <div className='flex flex-col'>
                        <Paragraph size='b7' color={COLORS.GREY[300]}>{service?.name} x{service?.quantity}</Paragraph>
                        {service?.staff ? <Paragraph size='b7' color={COLORS.GREY[300]}>Staff: {service?.staff?.user?.firstName} {service?.staff?.user?.lastName}</Paragraph> : null}
                      </div>
                      <Paragraph size='b7'>-</Paragraph>
                    </div>
                  ))}
                </>
              )}
              <div className='border-t border-grey-50'></div>
              <div className='w-full flex justify-between items-center py-2'>
                <Paragraph size='b7' weight='bold' className='inline' color={COLORS.GREY[300]}>Total</Paragraph>
                <Paragraph size='b7' weight='bold' className='inline'>{voucherToBeRedeemed ? formatInToPrice(0, businessInfo?.country?.currency?.symbol) : formatInToPrice(priceSummary?.totalPaid, businessInfo?.country?.currency?.symbol)}</Paragraph>
              </div>

              {bookedAppointment?.depositAmount > 0 && appointmentPaymentType === 'deposit_payment' ? (
                <div className='w-full flex justify-between items-center py-2'>
                  <Paragraph size='b7' color={COLORS.GREY[300]} weight="bold">Outstanding Balance</Paragraph>
                  <Paragraph size='b7' weight="bold">{formatInToPrice(bookedAppointment?.totalPaid - bookedAppointment?.depositAmount, businessInfo?.country?.currency?.symbol)}</Paragraph>
                </div>
              ) : null}
            </div>

            <div className='flex justify-center items-end space-x-2 mt-[20px]'>
              <Paragraph size='b5' weight='normal'>Powered by</Paragraph> <SvgLogo width="50px" height="100%" />
            </div>
          </div>
        )}
      </Modal >
    </>
  )
}

export default PurchaseSuccessful