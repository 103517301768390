import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCopy = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 58 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20.606 75h26.02c6.07 0 11.008-4.94 11.008-11.011V20.606c0-5.633-4.262-10.234-9.727-10.88C47.263 4.267 42.657 0 37.028 0h-26.02C4.938 0 0 4.941 0 11.012v43.43c0 5.633 4.266 10.238 9.73 10.883.665 5.434 5.262 9.676 10.876 9.676Zm32.859-54.394v43.383c0 3.77-3.067 6.847-6.84 6.847h-26.02c-3.77 0-6.848-3.07-6.848-6.847V20.606c0-3.77 3.07-6.84 6.848-6.84h26.023c3.77-.004 6.837 3.066 6.837 6.84ZM4.168 54.438V11.012c0-3.774 3.066-6.844 6.84-6.844h26.02c3.288 0 6.042 2.332 6.695 5.43H20.602c-6.07 0-11.012 4.937-11.012 11.007V61.14a6.863 6.863 0 0 1-5.422-6.702Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCopy;
