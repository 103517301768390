export const PAGE_ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  OTP: '/auth/otp',
  AUTH_INFO: '/auth/info',
  APP_LOADER: '/',
  FORGOT_PASSWORD: '/forgot_password',
  GENERATE_PASSWORD: '/generate_password',
  FORGOT_PASSWORD_OTP: '/forgot_password_otp',
  HOME: '/home',
  ACCOUNT_SETUP: '/setup/account',
  BOOKING: '/s/:id',
  CLIENTS: '/clients',
  INVENTORY: '/catalogue',
  CALENDAR: '/calendar',
  NOTIFICATIONS: '/notifications',
  STAFF: '/staff',
  SALES: '/sales',
  LOYALTY_AND_PROMOS: '/loyalty-and-promos',
  MONEY: '/money',
  MESSAGE: '/messaging',
  SETTINGS: '/settings',
  NOT_FOUND: '*',
  BUSINESS_PERFORMANCE: '/reports',
  CLIENTS_REPORTS: '/reports/clients',
  LOYALTY_REPORTS: '/reports/loyalty',
  SALES_REPORTS: '/reports/sales',
  STAFF_REPORTS: '/reports/staff',
  INVENTORY_REPORTS: '/reports/inventory',
  APPOINTMENT_REPORTS: '/reports/appointment',
  REVIEW: '/review/:appointmentId',
  RECEIPT: '/receipt/:appointmentId',
  SALE_RECEIPT: '/sale/:saleId',
  COMPLETE_PAYMENT: '/deposit/:appointmentId',
  APPOINTMENT: '/calendar/:appointmentId',
  APPOINTMENT_SUMMARY: '/b/:bookingId',
  REDEEM_VOUCHER: '/customer-dashboard',
  PACKAGES_REPORTS: '/reports/vouchers',
  SUPPORT: '/support'
}