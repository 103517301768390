import React, { Fragment, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  FormHelperText,
  FormLabel,
  FormPhone,
  Heading,
  Input,
  Layout,
  Modal,
  NameAvatar,
  Paragraph,
  ParagraphShimmer,
  SelectInput,
  Skeleton
} from '../ui'
import {
  convert12HourTo24Hour,
  convertFullDateStringToDate,
  convertFullDateStringToTime,
  formatTime,
  getNumberMonthAndYearFromDate,
  limitString,
  sanitizePhoneNumber
} from '../utils/misc'
import { addDays, isSameDay, startOfWeek, endOfWeek } from 'date-fns'
import { SvgChevronLeft, SvgChevronRight, SvgSelectDropDown } from '../ui/icons'
import { Menu, Transition } from '@headlessui/react'
import { useModal } from '../hooks'
import { Controller, useForm } from 'react-hook-form'
import {
  CreateStaffTimeOffInput,
  RoleEnum,
  SalonHoursDocument,
  SalonStaff,
  SalonStaffInput,
  StaffHour,
} from '../graphql/generated'
import Papa from 'papaparse'
import { ERRORS } from '../constants/errors'
import { CSV_UPLOAD_PATTERN, REGEX_PATTERNS } from '../constants/pattern'
import { TimePicker } from 'antd'
import dayjs from 'dayjs'
import ClosedBusinessProvider, {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useClosedBusinessPeriodContext
} from '../context/closedBusinessPeriodContext'
import StaffContextProvider, { useStaffContext } from '../context/staffContext'
import ToastWrapper from '../ui/molecules/toastWrapper/PlainToast'
import { AddClosedPeriodModal } from './BusinessClosedPeriod'
import {
  ACCOUNT_SETUP_ERRORS,
  STAFF_ROLES,
  YES_OR_NO_SETTINGS
} from '../constants/information'
import { print } from "graphql"
import { getHelperTextForReactHookFormErrors } from '../utils/form'
import { COLORS } from '../constants/colors'
import ImportFileLink from '../uicomponents/ImportFileLink'
import { formatStaffData, generateBusinessHoursFormValues, getWorkingHoursByStaffId } from '../uicomponents/utils'
import { useSalonCache } from '../hooks/useSalonCache'
import axios from 'axios'

const STAFF_SPECIALTIES = [
  { value: 'shampoo_assistant', label: 'Shampoo Assistant' },
  { value: 'salon_receptionist', label: 'Salon Receptionist' },
  { value: 'junior_hairstylist', label: 'Junior Hairstylist' },
  { value: 'senior_hairstylist', label: 'Senior Hairstylist' },
  { value: 'salon_manager', label: 'Salon Manager' },
  { value: 'social_media', label: 'Social Media' },
  { value: 'junior_makeup_artist', label: 'Junior Makeup Artist' },
  { value: 'senior_makeup_artist', label: 'Senior Makeup Artist' },
  { value: 'junior_photographer', label: 'Junior Photographer' },
  { value: 'senior_photographer', label: 'Senior Photographer' },
  { value: 'colourist', label: 'Colourist' },
  { value: 'assistant_hairstylist', label: 'Assistant Hairstylist' },
  { value: 'manicurist', label: 'Manicurist' },
  { value: 'pedicurist', label: 'Pedicurist' },
  { value: 'nail_technician_acrylic', label: 'Nail Technician - Acrylic' },
  { value: 'nail_technician_gel', label: 'Nail Technician - Gel' },
  {
    value: 'nail_technician_dipping_powder',
    label: 'Nail Technician - Dipping Powder'
  },
  {
    value: 'nail_technician_acrylic_design',
    label: 'Nail Technician - Acrylic Design'
  },
  { value: 'waxing_specialist', label: 'Waxing Specialist' },
  { value: 'junior_barber', label: 'Junior Barber' },
  { value: 'senior_barber', label: 'Senior Barber' },
  { value: 'junior_lash_technician', label: 'Junior Lash Technician' },
  { value: 'senior_lash_technician', label: 'Senior Lash Technician' },
  { value: 'beauty_therapist', label: 'Beauty Therapist' },
  { value: 'aesthetician', label: 'Aesthetician' },
  {
    value: 'hot_wax_treatment_specialist',
    label: 'Hot Wax & Treatment Specialist'
  },
  { value: 'sugaring_specialist', label: 'Sugaring Specialist' },
  { value: 'waxing_specialist_2', label: 'Waxing Specialist' }
]

const PERMISSION_LEVELS = [
  {
    label:
      'Receptionist - Access to everything but loyalty, service/products, settings, money',
    value: 1
  },
  {
    label: 'Manager - Access to everything but reports',
    value: 2
  },
  {
    label: 'Owner / CEO - Access to everything',
    value: 3
  }
]

const DAYS: string[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

const WorkingHours = () => {
  const [width] = useState<number>(window.innerWidth)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isMobile = width <= 768

  return (
    <Layout pageTitle='Staff'>
      <StaffContextProvider>
        <WorkingHoursCalendar />
      </StaffContextProvider>
    </Layout>
  )
}

const StaffListShimmer = () => {
  return (
    <>
      {[1, 2, 3].map((item, index) => (
        <div
          style={{ padding: '10px 16px 10px 16px' }}
          key={index}
          className='flex px-4 py-2.5 border-b border-grey-100 space-x-4 items-center cursor-pointer h-[69px]'
        >
          <Skeleton width='40px' height='40px' className='rounded-full' />
          <div className='flex flex-col'>
            <ParagraphShimmer size='md' />
            <ParagraphShimmer size='md' />
          </div>
        </div>
      ))}
    </>
  )
}

const WorkingHoursShimmer = () => {
  return (
    <>
      {[1, 2, 3].map((item, staffIndex) => (
        <div
          key={staffIndex}
          className='w-full flex border-b border-grey-100 h-[69px] overflow-x-scroll'
        >
          {[1, 2, 3, 4, 5, 6, 7].map((item, dayIndex) => (
            <div
              className='flex p-1 justify-center items-center border-r border-grey-100 flex-1 min-w-[68px] h-[68px]'
              key={dayIndex}
            >
              <div className={`w-full h-full bg-slate-300 animate-pulse`}></div>
            </div>
          ))}
        </div>
      ))}
    </>
  )
}

const WorkingHoursCalendar = () => {
  const [loading, setLoading] = useState(false)
  const { getSalonStaffs, salonStaffs, closedPeriods, toast, days, setDays } = useStaffContext()
  const [salonStaff, setSalonStaff] = useState<SalonStaff | null>(null)
  const [timeOffId, setTimeOffId] = useState<string>(null)
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    if (Array?.isArray(days) && days?.length > 0) {
      getSalonStaffAsync()
    }
  }, [days])

  const getSalonStaffAsync = () => {
    setLoading(true)
    getSalonStaffs(days)
    setLoading(false)
  }

  const getWeekDays = (startDate: Date, endDate: Date) => {
    const days = []
    let day = startDate
    while (day <= endDate) {
      days.push(day)
      day = addDays(day, 1)
    }
    return days
  }

  useEffect(() => {
    const startDate = startOfWeek(currentDate)
    const endDate = endOfWeek(currentDate)
    const days = getWeekDays(startDate, endDate)
    setDays(days)
    getSalonStaffAsync()
  }, [currentDate])

  const {
    isVisible: isAddTimeOffModalVisible,
    openModal: openAddTimeOffModal,
    closeModal: closeAddTimeOffModal
  } = useModal()

  const {
    isVisible: isAddClosedPeriodModalVisible,
    openModal: openAddClosedPeriodModal,
    closeModal: closeAddClosedPeriodModal
  } = useModal()

  const {
    isVisible: isUploadStaffModalVisible,
    openModal: openUploadStaffModal,
    closeModal: closeUploadStaffModal
  } = useModal()

  const {
    isVisible: isAddStaffModalVisible,
    openModal: openAddStaffModal,
    closeModal: closeAddStaffModal
  } = useModal()

  const _closeAddStaffModal = () => {
    setSalonStaff(null)
    closeAddStaffModal()
  }

  const getPrevWeek = () => {
    setCurrentDate(addDays(currentDate, -7))
  }
  const fixDateToCurrentDate = () => {
    setCurrentDate(new Date())
  }
  const getNextWeek = () => {
    setCurrentDate(addDays(currentDate, 7))
  }

  const getDayAndNumber = (date: Date | string) => {
    const day = new Date(date).toLocaleString('en-us', { weekday: 'short' })
    const number = new Date(date).getDate()
    return `${day} ${number}`
  }

  const handleOpenStaff = (staff: SalonStaff) => {
    setSalonStaff(staff)
    openAddStaffModal()
  }

  const editTimeOff = (staff: SalonStaff, timeOffId: string) => {
    setSalonStaff(staff)
    setTimeOffId(timeOffId)
    openAddTimeOffModal()
  }

  const initiateCloseAddTimeOffModal = () => {
    setSalonStaff(null)
    setTimeOffId(null)
    closeAddTimeOffModal()
  }

  const getWorkingHoursContent = () => {
    if (loading) {
      return (
        <>
          <div className='flex flex-col w-3/12 border-r border-grey-100'>
            <StaffListShimmer />
          </div>
          <div className='flex flex-col w-9/12'>
            <WorkingHoursShimmer />
          </div>
        </>
      )
    }

    if (Array.isArray(salonStaffs) && salonStaffs.length) {
      return (
        <div className='flex flex-col h-full w-full'>
          {Array.isArray(salonStaffs) &&
            salonStaffs?.map((staff: SalonStaff, staffIndex) => {
              const workingHours = staff.staffHours
              const timeOffs = staff.staffTimeOffs
              const staffHours = getWorkingHoursByStaffId(days, staff?.id, workingHours, timeOffs, closedPeriods)
              return (
                <div
                  style={{ minHeight: '69px' }}
                  className='flex border-b border-grey-100 min-h-[69px]'
                  key={staffIndex}
                >
                  <div
                    style={{ width: '25%' }}
                    className='flex flex-col w-6/12 xl:w-3/12 h-full border-r border-grey-100'
                  >
                    <div
                      className='flex px-4 py-2.5 space-x-0 xl:space-x-4 items-center cursor-pointer h-[69px]'
                      onClick={() => handleOpenStaff(staff)}
                    >
                      <NameAvatar
                        name={
                          staff?.user.firstName?.charAt(0).toLocaleUpperCase() +
                          staff?.user.lastName?.charAt(0).toLocaleUpperCase()
                        }
                      />
                      <div className='flex flex-col'>
                        <Paragraph size='b4' color={COLORS.GREY[900]}>
                          {staff.user.fullName}
                        </Paragraph>
                        <Paragraph size='b4' color={COLORS.GREY[400]}>
                          {staff.role}
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ width: '75%' }}
                    className='flex flex-col w-9/12'
                  >
                    <div className='w-full flex min-h-[69px] overflow-x-scroll'>
                      {Array.isArray(staffHours) && staffHours?.map((hours, hoursIndex) => (
                      <div className="flex flex-col flex-1 min-w-[68px]" key={hoursIndex}>
                        {Array.isArray(hours) && hours?.map((hour, hourIndex) => {
                          const handleHourClick = () => {
                            if (hour && hour?.type === 'off' && hour?.id) {
                              editTimeOff(staff, hour?.id);
                            } else {
                              handleOpenStaff(staff)
                            }
                          };
                          return hour && (
                            <div className='flex p-1 cursor-pointer justify-center items-center border-r border-grey-100 flex-1 w-full h-[68px]' onClick={handleHourClick} key={hourIndex}>
                              <div className={`w-full h-full flex flex-col p-2 justify-between ${hour?.status === 'open' ? 'bg-green-100' : 'bg-grey-100'}`}>
                                <Paragraph size='b6' weight="semiBold" className="capitalize" color={
                                  hour?.status === 'open' ? COLORS.GREEN[700] : COLORS.GREY[400]
                                }>{hour?.status === 'open' ? 'Working' : hour?.status || 'Off'}</Paragraph>

                                {hour?.openTime && hour?.closeTime ? (
                                  <Paragraph size='b7' color={COLORS.GREY[200]}>{`${formatTime(hour?.openTime)} - ${formatTime(hour?.closeTime)}`}</Paragraph>
                                ) : null}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    ))}
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      )
    }
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      <div className='flex flex-col w-full h-full'>
        <div className='flex flex-col'>
          <div className='flex items-center justify-between px-6 py-3 border-b border-grey-100'>
            <Paragraph size='b5' weight='bold'>
              {getNumberMonthAndYearFromDate(currentDate)}
            </Paragraph>

            <div className='flex items-center'>
              <Button
                variant='secondary'
                size='sm'
                type='button'
                rounded='md'
                fontWeight='bold'
                className='border-0'
                onClick={getPrevWeek}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Button
                variant='light'
                size='sm'
                type='button'
                rounded='md'
                fontWeight='bold'
                className=''
                onClick={fixDateToCurrentDate}
              >
                Today
              </Button>
              <Button
                variant='secondary'
                size='sm'
                type='button'
                rounded='md'
                fontWeight='bold'
                className='border-0'
                onClick={getNextWeek}
              >
                <SvgChevronRight width='24px' height='24px' />
              </Button>
            </div>

            <div className='relative space-x-2'>
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-white border border-grey-50 px-4 py-2 text-b5 text-grey-900 font-semibold hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <span>Manage</span>
                    <SvgSelectDropDown width='10px' height='10px' />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <div className='flex flex-col p-4 space-y-4'>
                      <Menu.Item>
                        <span
                          className='font-semibold text-b4 cursor-pointer'
                          onClick={() =>
                            (window.location.href = '/staff/closed-periods')
                          }
                        >
                          Business Closed Period
                        </span>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-semibold text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <span>Add</span>
                    <SvgSelectDropDown width='10px' height='10px' />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <div className='flex flex-col p-4 space-y-4'>
                      <Menu.Item>
                        <span
                          className='font-semibold text-b4 cursor-pointer'
                          onClick={openAddTimeOffModal}
                        >
                          Time Off
                        </span>
                      </Menu.Item>
                      <Menu.Item>
                        <span
                          className='font-semibold text-b4 cursor-pointer'
                          onClick={openAddClosedPeriodModal}
                        >
                          Business Closed Period
                        </span>
                      </Menu.Item>
                      <Menu.Item>
                        <span
                          className='font-semibold text-b4 cursor-pointer'
                          onClick={openAddStaffModal}
                        >
                          New Staff
                        </span>
                      </Menu.Item>
                      <Menu.Item>
                        <span
                          className='font-semibold text-b4 cursor-pointer'
                          onClick={openUploadStaffModal}
                        >
                          Upload Staff
                        </span>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <div className='flex w-full'>
          <div
            style={{ width: '25%' }}
            className='flex flex-col w-6/12 xl:w-3/12 h-full border-r border-grey-100'
          >
            <div className='hidden xl:flex px-4 py-2.5 border-b border-grey-100'>
              <Paragraph size='b4' weight='bold' color={COLORS.GREY[300]}>
                {salonStaffs?.length} staff members
              </Paragraph>
            </div>
          </div>
          <div
            style={{ width: '75%' }}
            className='flex flex-col h-full w-6/12 xl:w-9/12'
          >
            <div className='hidden xl:flex px-4 py-2.5 border-b border-grey-100 overflow-x-scroll'>
              {days?.map((day, index) => (
                <div
                  style={{ minWidth: '68px' }}
                  key={index}
                  className='flex-1 min-w-[68px]'
                >
                  <Paragraph
                    size='b4'
                    weight='bold'
                    color={
                      isSameDay(day, new Date())
                        ? COLORS.RED[400]
                        : COLORS.GREY[300]
                    }
                  >
                    {getDayAndNumber(day)}
                  </Paragraph>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='w-full h-full flex'>{getWorkingHoursContent()}</div>
      </div>
      <AddTimeOffModal
        isVisible={isAddTimeOffModalVisible}
        closeModal={initiateCloseAddTimeOffModal}
        timeOffId={timeOffId}
        staff={salonStaff}
      />
      <ClosedBusinessProvider>
        <AddClosedPeriodModal
          isVisible={isAddClosedPeriodModalVisible}
          closeModal={closeAddClosedPeriodModal}
        />
      </ClosedBusinessProvider>
      <AddStaffModal
        isVisible={isAddStaffModalVisible}
        closeModal={_closeAddStaffModal}
        salonStaff={salonStaff}
      />
      <UploadStaffModal
        isVisible={isUploadStaffModalVisible}
        closeModal={closeUploadStaffModal}
      />
    </>
  )
}

type AddTimeOffModalProps = {
  isVisible: boolean
  closeModal: () => void
  timeOffId?: string
  staff?: SalonStaff
}
const AddTimeOffModal = ({ isVisible, closeModal, staff, timeOffId }: AddTimeOffModalProps) => {
  const { salonStaffs, getSalonStaffs, createStaffTimeOff, toast, deleteStaffTimeOff } =
    useStaffContext()
  const [loading, setLoading] = useState(false)
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<CreateStaffTimeOffInput>({ delayError: 100, mode: 'onChange' })

  useEffect(() => {
    getSalonStaffs()
  }, [])

  const submitData = async (input: CreateStaffTimeOffInput) => {
    setLoading(true)
    await createStaffTimeOff({
      ...input,
      id: timeOffId,
    }, closeModal, resetTimeOffForm)
    setLoading(false)
    getSalonStaffs()
  }

  const deleteTimeOff = async () => {
    setLoading(true)
    const input = { id: timeOffId };
    await deleteStaffTimeOff(input, closeModal, resetTimeOffForm)
    setLoading(false)
  }

  const resetTimeOffForm = () => {
    setValue('title', '')
    setValue('salonStaffId', '')
    setValue('startDate', '')
    setValue('startTime', '')
    setValue('endDate', '')
    setValue('endTime', '')
  }

  const staffMembersOptions = (salonStaffs as SalonStaff[]).map((x) => ({
    value: x.id,
    label: x.user.fullName
  }))

  useEffect(() => {
    if (staff && timeOffId) {
      const staffTimeOffData = staff?.staffTimeOffs?.find(x => x.id === timeOffId);
      if (staffTimeOffData) {
        setValue('startDate', convertFullDateStringToDate(staffTimeOffData.startAt));
        setValue('endDate', convertFullDateStringToDate(staffTimeOffData.endAt));
        setValue('endTime', convertFullDateStringToTime(staffTimeOffData.endAt));
        setValue('startTime', convertFullDateStringToTime(staffTimeOffData.startAt));
        setValue('salonStaffId', staff?.id);
        setValue('title', staffTimeOffData.title);
      }
    }
  }, [staff, timeOffId])

  const handleTimeChange =
    (fieldName: 'startTime' | 'endTime') =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_value: any, dateString: string) => {
      if (dateString) {
        const timeString = convert12HourTo24Hour(dateString)
        if (timeString) {
          setValue(fieldName, timeString)
        }
      }
    }

  return (
    <Modal show={isVisible} title='Add time off' closeModal={closeModal}>
      <ToastWrapper toast={toast} />
      <form
        onSubmit={handleSubmit(submitData)}
        className='w-full mt-6 space-y-6'
        autoComplete='off'
      >
        <Input
          name='title'
          label='Title'
          id='title'
          type='text'
          placeholder='Enter title'
          control={control}
          rules={{
            required: ERRORS.TITLE_REQUIRED,
            pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH
          }}
          error={errors.title}
        />
        <SelectInput
          name='salonStaffId'
          label='Staff'
          id='staff'
          disabled={false}
          control={control}
          rules={{
            required: ERRORS.STAFF_REQUIRED
          }}
          error={errors.salonStaffId}
          options={staffMembersOptions}
          placeholder='Select Staff'
        />
        <div className='w-full flex gap-x-4'>
          <Input
            name='startDate'
            label='Start Date'
            id='start-at'
            type='date'
            placeholder='DD/MM/YY'
            control={control}
            rules={{
              required: ERRORS.DATE_REQUIRED
            }}
            error={errors.startDate}
          />
          <Controller
            control={control}
            name='startTime'
            render={({ field, formState: { errors } }) => {
              const errorMessage: string = errors?.startTime?.message
              return (
                <div className='w-full flex flex-col space-y-2'>
                  <FormLabel htmlFor='start-time'>Start Time</FormLabel>
                  <TimePicker
                    className='splice-time-input'
                    use12Hours
                    format='h:mm a'
                    value={
                      field.value ? dayjs(field.value, 'HH:mm') : undefined
                    }
                    onChange={handleTimeChange('startTime')}
                  />
                  {errorMessage && (
                    <FormHelperText variant='error'>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </div>
              )
            }}
            rules={{
              required: ERRORS.TIME_REQUIRED
            }}
          />
        </div>
        <div className='w-full flex gap-x-4'>
          <Input
            name='endDate'
            label='End Date'
            id='end-at'
            type='date'
            placeholder='DD/MM/YY'
            control={control}
            rules={{
              required: ERRORS.DATE_REQUIRED
            }}
            error={errors.endDate}
          />
          <Controller
            control={control}
            name='endTime'
            render={({ field: { value }, formState: { errors } }) => {
              const errorMessage: string = errors?.endTime?.message
              const timeValue = value || null
              return (
                <div className='w-full flex flex-col space-y-2'>
                  <FormLabel htmlFor='end-time'>End Time</FormLabel>
                  <TimePicker
                    className='splice-time-input'
                    use12Hours
                    format='h:mm a'
                    value={timeValue ? dayjs(value, 'HH:mm') : undefined}
                    onChange={handleTimeChange('endTime')}
                  />
                  {errorMessage && (
                    <FormHelperText variant='error'>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </div>
              )
            }}
            rules={{
              required: ERRORS.TIME_REQUIRED
            }}
          />
        </div>
        <Button
          variant='primary'
          className=''
          disabled={loading}
          loading={loading}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
        <Button
          variant='text'
          size='none'
          rounded='none'
          fontSize='b5'
          className='mx-auto text-grey-900'
          onClick={closeModal}
          type='button'
        >
          Cancel
        </Button>
        {timeOffId && (
          <Button
            variant='text'
            size='none'
            rounded='none'
            fontSize='b5'
            disabled={loading}
            loading={loading}
            className='mx-auto text-red-600'
            onClick={deleteTimeOff}
            type='button'
          >
            Delete
          </Button>
        )}
      </form>
    </Modal>
  )
}

type AddStaffModalProps = {
  isVisible: boolean
  closeModal: () => void
  salonStaff?: SalonStaff | null
}
type ICreateStaff = {
  staffInput: SalonStaffInput
}
const AddStaffModal = ({
  isVisible,
  closeModal,
  salonStaff
}: AddStaffModalProps) => {
  const { updateSalonStaff, deleteSalonStaff } = useStaffContext()
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [countryCode, setCountryCode] = useState('')
  const [callingCode, setCallingCode] = useState('')
  const { getSalonFieldValue } = useSalonCache()
  const generateFormValues = (staffHours: StaffHour[]) => {
    const result = {}
    staffHours.forEach((hour) => {
      result[hour.day] = {
        id: hour.id,
        openTime: hour.openTime,
        closeTime: hour.closeTime,
        status: hour.status ,
        isAvailable: hour.status === 'open'
      }
    })
    return result
  }
  const [formValues, setFormValues] = useState(generateFormValues([]))
  const {
    handleSubmit,
    control,
    setValue,
    register,
    watch,
    formState: { errors }
  } = useForm<ICreateStaff>()

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode)
    setValue('staffInput.phone', _phoneNumber)
  }

  useEffect(() => {
    if (salonStaff) {
      setFormValues(generateFormValues(salonStaff.staffHours))
      const { firstName, lastName, email, phone, callingCode, countryCode } =
        salonStaff.user
      setValue('staffInput.firstName', firstName)
      setValue('staffInput.lastName', lastName)
      setValue('staffInput.email', email)
      setValue('staffInput.phone', phone)
      setValue('staffInput.canLogin', salonStaff.canLogin || false)
      setCountryCode(countryCode)
      setCallingCode(callingCode)
      setValue('staffInput.role', RoleEnum[salonStaff.role.charAt(0).toUpperCase() + salonStaff.role.slice(1)])
      setValue('staffInput.specialty', salonStaff.specialty || '')
      setValue('staffInput.canServeCustomers', salonStaff.canServeCustomers)
      setValue('staffInput.level', salonStaff?.level)
      console.log(salonStaff.level)
      // console.log(formValues)
    } else {
      resetStaffForm()
    }
  }, [salonStaff])

  const handleCheckboxChange =
    (day: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const dayValue = formValues[day]
      const updatedFormValues = {
        ...formValues,
        [day]: {
          isAvailable: !dayValue.isAvailable,
          openTime: dayValue.isAvailable ? '' : '08:00',
          closeTime: dayValue.isAvailable ? '' : '20:00',
          id: dayValue.id
        }
      }
      setFormValues(updatedFormValues)
    }

  const handleTimeChange =
    (day: string, fieldName: 'openTime' | 'closeTime') =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any, dateString: string) => {
      if (dateString) {
        const timeString = convert12HourTo24Hour(dateString)
        setFormValues((prevValues) => {
          const newObj = {
            ...prevValues,
            [day]: {
              ...prevValues[day],
              [fieldName]: timeString
            }
          }
          return newObj
        })

      }

    }

  const addStaffSubmit = async (input: ICreateStaff) => {
    setLoading(true)
    const staffHours = []
    for (const property in formValues) {
      const obj = {
        id: formValues[property].id,
        closeTime: formValues[property].closeTime || null,
        openTime: formValues[property].openTime || null,
        day: property
      }
      staffHours.push(obj)
    }
    await updateSalonStaff({
      ...input.staffInput,
      id: salonStaff ? salonStaff.id : null,
      canLogin: input.staffInput.canLogin ? true : false,
      callingCode,
      countryCode,
      level: input.staffInput.canLogin ? Number(input.staffInput.level) : null

    }, staffHours, closeModal, resetStaffForm)
    setLoading(false)
  }

  const handleStaffDelete = async () => {
    setDeleting(true)
    await deleteSalonStaff({ salonStaffId: salonStaff.id }, closeModal, resetStaffForm )
    setDeleting(false)
  }

  const resetStaffForm = () => {
    setValue('staffInput.firstName', '')
    setValue('staffInput.lastName', '')
    setValue('staffInput.email', '')
    setValue('staffInput.phone', '')
    setValue('staffInput.role', RoleEnum.Staff)
    setValue('staffInput.specialty', '')
    setValue('staffInput.canLogin', false)
    setValue('staffInput.canServeCustomers', false)
    setValue('staffInput.level', 0)
    setFormValues([])
  }

  const _closeModal = () => {
    resetStaffForm()
    closeModal()
  }

  const getSalonHours = async () => {
    axios
      .post(
        '/graphql',
        {
          query: print(SalonHoursDocument),
          variables: { salonId: getSalonFieldValue('id') }
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { salonHours }
          }
        } = res;
        const hoursData = generateBusinessHoursFormValues(salonHours);
        setFormValues(hoursData);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((err) => {
        // addToast({ message: err.message, variant: 'error' })
      })
  }

  useEffect(() => {
    if (!salonStaff && isVisible) {
      getSalonHours();
    }
  }, [isVisible])

  return (
    <Modal show={isVisible} closeModal={_closeModal} size='xl'>
      <form
        onSubmit={handleSubmit(addStaffSubmit)}
        className='w-full flex flex-col xl:flex-row gap-6 xl:gap-20'
        autoComplete='off'
      >
        <div className='w-full flex flex-col space-y-4'>
          <Heading
            variant='h1'
            size='h8'
            weight='bold'
            className='mt-4 xl:mt-0 mx-auto mb-6'
          >
            Staff details
          </Heading>
          <div className='w-full flex gap-x-4'>
            <Input
              name='staffInput.firstName'
              label='First name'
              id='first-name'
              type='text'
              placeholder='Enter first name here'
              control={control}
              rules={{
                required: ACCOUNT_SETUP_ERRORS.FIRST_NAME_REQUIRED,
                pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH
              }}
              error={errors.staffInput?.firstName}
            />
            <Input
              name='staffInput.lastName'
              label='Last name'
              id='last-name'
              type='text'
              placeholder='Enter last name here'
              control={control}
              rules={{
                required: ACCOUNT_SETUP_ERRORS.LAST_NAME_REQUIRED,
                pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH
              }}
              error={errors.staffInput?.lastName}
            />
          </div>
          <SelectInput
            name='staffInput.role'
            id='role'
            label='Role'
            control={control}
            rules={{
              required: ERRORS.ROLE_REQUIRED
            }}
            error={errors.staffInput?.role}
            options={[...STAFF_ROLES]}
            placeholder='Select Role'
          />
          <SelectInput
            name='staffInput.specialty'
            id='specialty'
            label='Select Specialty'
            control={control}
            rules={{
              required: ERRORS.STAFF_SPECIALTY_REQUIRED
            }}
            error={errors.staffInput?.specialty}
            options={STAFF_SPECIALTIES}
            placeholder='Select a specialty'
          />
          <FormPhone
            country={{
              disabled: false,
              onSelect: (code, country: string) => {
                setCallingCode(code)
                setCountryCode(country)
              },
              value: countryCode
            }}
            phone={{
              name: 'staffInput.phone',
              type: 'text',
              placeholder: '9151930463',
              rules: {
                required: ERRORS.PHONE_REQUIRED,
                pattern: REGEX_PATTERNS.NUMBER,
                onChange: (e) => {
                  _sanitizePhoneNumber(e.target.value)
                },
                disabled: false
              },
              register,
              id: 'phone-number',
              label: 'Business Phone Number'
            }}
            helperText={getHelperTextForReactHookFormErrors(
              errors?.staffInput?.phone?.message as string
            )}
          />
          <Input
            name='staffInput.email'
            label='Email'
            id='email'
            type='email'
            placeholder='email@acme.com'
            control={control}
            rules={{
              required: watch('staffInput.canLogin')
                ? ACCOUNT_SETUP_ERRORS.EMAIL_REQUIRED
                : false,
              pattern: REGEX_PATTERNS.EMAIL
            }}
            error={errors.staffInput?.email}
          />
          <Controller
            control={control}
            name='staffInput.canServeCustomers'
            render={({ field: { onChange, value } }) => {
              return (
                <div className='w-full flex flex-col space-y-4'>
                  <label className='text-b6 xl:text-b4 text-black dark:text-grey-500 font-normal w-full block subpixel-antialiased'>
                    Is this a customer facing staff? (do they provide services
                    to customers)
                  </label>
                  <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                    {Array.isArray(YES_OR_NO_SETTINGS) &&
                    YES_OR_NO_SETTINGS.length
                      ? YES_OR_NO_SETTINGS.map(
                          (canServeCustomers: {
                            label: string
                            value: boolean
                          }) => {
                            return (
                              <div
                                className='flex'
                                onClick={() =>
                                  onChange(canServeCustomers?.value)
                                }
                                key={canServeCustomers?.label}
                              >
                                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                  <Checkbox
                                    isChecked={
                                      value === canServeCustomers?.value
                                    }
                                  />
                                  <span className='text-grey-900 whitespace-nowrap'>
                                    {canServeCustomers?.label}
                                  </span>
                                </div>
                              </div>
                            )
                          }
                        )
                      : null}
                  </div>
                </div>
              )
            }}
          />
          <Controller
            control={control}
            name='staffInput.canLogin'
            render={({ field: { onChange, value } }) => {
              return (
                <div className='w-full flex space-y-4 my-2'>
                  <div className='flex' onClick={() => onChange(!value)}>
                    <div className='flex justify-center items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                      <Checkbox isChecked={value} />
                      <span className='text-grey-900 whitespace-nowrap'>
                        Do you want to invite this staff to Splice
                      </span>
                    </div>
                  </div>
                </div>
              )
            }}
          />
          {watch('staffInput.canLogin') && (
            <SelectInput
              name='staffInput.level'
              id='level'
              label='Select permission level'
              control={control}
              rules={{
                required:
                  watch('staffInput.canLogin') &&
                  ERRORS.PERMISSION_LEVEL_REQUIRED
              }}
              error={errors.staffInput?.role}
              options={[...PERMISSION_LEVELS]}
              placeholder='Select Permission level'
            />
          )}
          {salonStaff && (
            <Button
              variant='transparent'
              className='text-red-500'
              disabled={false}
              loading={deleting}
              fontWeight='semiBold'
              fontSize='b4'
              type='button'
              onClick={() => handleStaffDelete()}
            >
              Delete staff member
            </Button>
          )}
        </div>
        <div className='w-full flex flex-col space-y-6'>
          <Heading
            variant='h1'
            size='h8'
            weight='bold'
            className='hidden xl:flex mx-auto mb-6'
          >
            Working hours
          </Heading>

          {DAYS.map((day) => (
            <div className='w-full flex' key={day}>
              <div className='relative w-1/5 flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                <Checkbox isChecked={formValues[day]?.isAvailable || false} />
                <input
                  type='checkbox'
                  onChange={handleCheckboxChange(day)}
                  style={{ width: '24px', height: '24px' }}
                  className='absolute left-0 w-[24px] h-[24px] opacity-0 cursor-pointer'
                />
                <span className='text-grey-900 font-semibold uppercase whitespace-nowrap'>
                  {limitString(day, 3, false)}
                </span>
              </div>
              <div className='w-4/5 flex space-y-2'>
                {!formValues[day]?.isAvailable ? (
                  <span className='text-b4 text-grey-400'>Unavailable</span>
                ) : (
                  <div className='w-full flex items-center gap-x-4'>
                    <Controller
                      control={control}
                      // @ts-expect-error Input type for form
                      name={`availability.${day}.openTime`}
                      render={({ field, formState: { errors } }) => {
                        return (
                          <div className='flex flex-col space-y-2'>
                            <TimePicker
                              className='splice-time-input'
                              use12Hours
                              format='h:mm a'
                              value={dayjs(
                                field.value || formValues[day]?.openTime,
                                'HH:mm'
                              )}
                              onChange={handleTimeChange(day, 'openTime')}
                            />
                          </div>
                        )
                      }}
                    />
                    <span className='text-b5 text-grey-900'>to</span>
                    <Controller
                      control={control}
                      // @ts-expect-error Input type for form
                      name={`availability.${day}.closeTime`}
                      render={({ field, formState: { errors } }) => {
                        return (
                          <div className='flex flex-col space-y-2'>
                            <TimePicker
                              className='splice-time-input'
                              use12Hours
                              format='h:mm a'
                              value={dayjs(
                                field.value || formValues[day]?.closeTime,
                                'HH:mm'
                              )}
                              onChange={handleTimeChange(day, 'closeTime')}
                            />
                          </div>
                        )
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}

          <Button
            variant='primary'
            className='w-full'
            disabled={false}
            loading={loading}
            size='lg'
            rounded='lg'
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  )
}

const UploadStaffModal = ({ isVisible, closeModal }) => {
  return (
      <Modal
        title='Upload Staff'
        subTitle=''
        show={isVisible}
        closeModal={closeModal}
      >
        <div className='flex space-x-6 w-full items-center justify-center my-12'>
          <ImportStaff />
          <ImportFileLink modules='staff' />
        </div>
        <Button
          variant='text'
          size='none'
          rounded='none'
          fontSize='b5'
          className='mx-auto'
          onClick={closeModal}
          type='button'
        >
          Close
        </Button>
      </Modal>
  )
}

const ImportStaff = () => {
  const [isLoading, setIsLoading] = useState(false)
  const {getSalonFieldValue} = useSalonCache()
  const {uploadStaff} = useStaffContext()

  const handleCsvUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    setIsLoading(true)
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: async function (results) {
          if (Array.isArray(results.data) && results.data.length > 0) {
            const staffData = formatStaffData(results.data);
            const payload = {
              salonStaffs: staffData,
              salonId: getSalonFieldValue('id')
            }
            await uploadStaff(payload)
            setIsLoading(false)
          }
        }
      })
    }
  }
  return (
    <Button
      variant='success'
      className='relative'
      size='sm'
      rounded='md'
      fontSize='b5'
      type='button'
    >
      {!isLoading ? (
        <>
          Import Staff
          <input
            className='cursor-pointer absolute block opacity-0 top-0 w-[128px] h-full'
            type='file'
            accept={CSV_UPLOAD_PATTERN}
            onChange={handleCsvUpload}
          />
        </>
      ) : (
        <div className='flex justify-center gap-x-2 cursor-pointer'>
          {[1, 2, 3].map((item, index) => (
            <div
              key={index}
              className='h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse'
            />
          ))}
        </div>
      )}
    </Button>
  )
}

export default WorkingHours
