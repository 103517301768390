import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCustomerSupport = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M17 10.805c0-.346 0-.519.052-.673.151-.448.55-.621.95-.803.448-.205.672-.307.895-.325.252-.02.505.034.721.155.286.16.486.466.69.714.943 1.146 1.415 1.719 1.587 2.35.14.51.14 1.044 0 1.553-.251.922-1.046 1.694-1.635 2.41-.301.365-.452.548-.642.655a1.27 1.27 0 0 1-.721.155c-.223-.018-.447-.12-.896-.325-.4-.182-.798-.355-.949-.803-.052-.154-.052-.327-.052-.673v-4.39Zm-10 0c0-.436-.012-.827-.364-1.133-.128-.111-.298-.188-.637-.343-.449-.204-.673-.307-.896-.325-.667-.054-1.026.402-1.41.87-.944 1.145-1.416 1.718-1.589 2.35a2.94 2.94 0 0 0 0 1.553c.252.921 1.048 1.694 1.636 2.409.371.45.726.861 1.363.81.223-.018.447-.12.896-.325.34-.154.509-.232.637-.343.352-.306.364-.697.364-1.132v-4.391Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 9c0-3.314 3.134-6 7-6s7 2.686 7 6m0 8v.8c0 1.767-1.79 3.2-4 3.2h-2"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCustomerSupport;
