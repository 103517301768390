import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { PAGE_ROUTES } from "../../../constants/routes";
import { ProtectedRouteProps } from "../types";
export const AuthGuard = ({ children }: ProtectedRouteProps) => {
  const { getAppAuthToken } = useAuth();
  const appAuthToken = getAppAuthToken();
  const location = useLocation();

  return appAuthToken?.token ? (
    children
  ) : (
    <Navigate
      to={PAGE_ROUTES.LOGIN}
      replace
      state={{ path: location.pathname }}
    />
  );
};
