import * as React from "react";
import { MultiSelect as RMultiSelectComponent } from "react-multi-select-component";
import { MultiSelectProps } from "./types";

const MultiSelect = ({ options, selected, setSelected, disabled, isLoading }: MultiSelectProps) => {
  return (
    <RMultiSelectComponent
      options={options}
      value={selected}
      onChange={setSelected}
      hasSelectAll={true}
      labelledBy="Select..."
      disabled={disabled || false}
      className="border border-grey-20 font-normal appearance-none text-b6 xl:text-b4 text-grey-400 rounded-md focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-900"
      isLoading={isLoading || false}
    />
  );
};

export default MultiSelect;
