import React from 'react'
import Paragraph from '../ui/atoms/paragraph/Paragraph'
import { Modal } from '../ui/templates/modal/Modal'
import { COLORS } from '../constants/colors'
import { convertSalonServicesNamesToString } from './bookingSales/utils'
import { formatInToPrice, getDayMonthAndNumberAndTimeFromDate } from '../utils/misc'
import Button from '../ui/atoms/button/Button'
import { ClientBookingModalProps } from './types'

const BookingDetailsModal = ({
  isOpen,
  onClose,
  bookingOrSaleData,
}: ClientBookingModalProps) => {
  return (
    <Modal
      title={bookingOrSaleData?.type === 'appointment' ? "Booking details" : "Sale details"}
      show={isOpen}
      closeModal={onClose}
    >
      <>
        {bookingOrSaleData && (
          <div className='w-full flex flex-col my-12 space-y-6'>
            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Services</Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>{convertSalonServicesNamesToString(bookingOrSaleData?.services)}</Paragraph>
            </div>
            <div className='flex flex-col xl:grid xl:grid-cols-2 gap-6'>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Appointment date</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{getDayMonthAndNumberAndTimeFromDate(bookingOrSaleData?.startAt) || "-"}</Paragraph>
              </div>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Check out</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.status === "completed" && getDayMonthAndNumberAndTimeFromDate(bookingOrSaleData?.endAt) || "-"}</Paragraph>
              </div>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Payment method</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.paymentMethod}</Paragraph>
              </div>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Amount</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(bookingOrSaleData?.amount)}</Paragraph>
              </div>
              {bookingOrSaleData.packageName ? <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Voucher name</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.packageName}</Paragraph>
              </div> : null}
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Points</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.points}</Paragraph>
              </div>
              {bookingOrSaleData?.appointmentMilestone ?
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>Reward</Paragraph>
                  <Paragraph
                    size='b4'
                    color={COLORS.BLACK}
                    weight='semiBold'
                    className='flex space-x-1'
                  >
                    <span>{
                      bookingOrSaleData?.appointmentMilestone?.milestone?.customerReceivesType === 'percentage'
                        ? `${bookingOrSaleData?.appointmentMilestone?.milestone?.customerReceives}%`
                        : `${formatInToPrice(bookingOrSaleData?.appointmentMilestone?.milestone?.customerReceives)?.replace(".00", "")}`
                    } - </span>
                    <span>Reward discount in use</span>
                    
                  </Paragraph>
                </div> : null}
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Location</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.address}</Paragraph>
              </div>
            </div>
            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Payment reference</Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.paymentReference}</Paragraph>
            </div>
            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Note</Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.note}</Paragraph>
            </div>
          </div>
        )}
        <Button
          variant='primary'
          className=''
          size='lg'
          rounded='lg'
          onClick={() => onClose()}
        >
          Done
        </Button>
      </>
    </Modal>
  )
}

export default BookingDetailsModal