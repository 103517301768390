import * as React from "react";
import { BasePillProps } from "./types";
import BasePill from "./BasePill";

const WarningPill = ({ className, style, children = "" }: BasePillProps) => {
  return (
    <BasePill
      style={style}
      className={`text-yellow-900 bg-yellow-50 ${className}`}
    >
      {children}
    </BasePill>
  );
};

export default WarningPill;
