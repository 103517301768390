import React from 'react'
import { ReceiptPrintViewProps } from '../types'
import Heading from '../../../ui/atoms/heading/Heading';
import { useSalonCache } from '../../../hooks/useSalonCache';
import Paragraph from '../../../ui/atoms/paragraph/Paragraph';
import { formatInToPrice } from '../../../utils/misc';
import SvgLogo from '../../../ui/icons/SvgLogo';
import { COLORS } from '../../../constants/colors';

const ReceiptPrintView = ({
  appointment,
  business,
  hasMadePayment
}: ReceiptPrintViewProps) => {
  const { getSalonData } = useSalonCache();
  const salon = getSalonData();
  const discount = appointment?.discountAmount + (appointment?.appointmentPromo?.amount || 0)
  return (
    <>
      {appointment ? (
        <div className='w-full py-[40px] bg-white h-full flex flex-col items-center space-y-5 rounded-md overflow-y-auto'>
          <Heading variant='h1' size='h8' weight='bold'>{business?.name}</Heading>
          <div className='space-y-2 flex flex-col items-center px-12'>
            <Paragraph size='b5' weight='normal'>{salon?.address}, {salon?.city}, {salon?.country}</Paragraph>
          </div>
          <div className='w-full border-t border-grey-100'></div>
          <div className='space-y-3 flex flex-col items-center px-12'>
            <Heading variant='h2' size='h9' weight='bold'>Thank you for your order.</Heading>
            <Paragraph size='b6' weight='normal' className='text-center'>{hasMadePayment === 'full' && appointment?.paymentMethod === 'card' ? `Your payment has been received and confirmed` : hasMadePayment === 'deposit' && appointment?.depositPaymentMethod === 'card' ? 'Your deposit has been received and confirmed' : 'Your order is confirmed'}, get ready to experience beauty at its best!</Paragraph>
          </div>
          <div className='w-full border-t border-grey-100'></div>
            <div className='space-y-3 flex flex-col items-center px-12'>
              <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>Billed to: {appointment?.client?.firstName} {appointment?.client?.lastName}</Paragraph>
            </div>
          <div className='w-full flex flex-col space-y-2 px-[40px]'>
            <div className='flex justify-between items-center bg-grey-50 px-4 py-2'>
              <Heading variant='h1' size='h11'>Item</Heading>
              <Heading variant='h1' size='h11'>Amount</Heading>
            </div>

            {appointment?.appointmentServices?.map((service) => (
              <div className='flex justify-between items-center px-4 py-2' key={service?.id}>
                <Paragraph size='b5'>{service?.name} x{service?.quantity}</Paragraph>
                <Paragraph size='b5'>{formatInToPrice(service?.price)}</Paragraph>
              </div>
            ))}

            {appointment?.appointmentProducts?.map((product) => (
              <div className='flex justify-between items-center px-4 py-2' key={product?.id}>
                <Paragraph size='b5'>{product?.name} x{product?.quantity}</Paragraph>
                <Paragraph size='b5'>{formatInToPrice(product?.price)}</Paragraph>
              </div>
            ))}

            <div className='border-t border-grey-50'></div>
            <div className='flex justify-between items-center px-4 py-2'>
              <Paragraph size='b5'>Subtotal</Paragraph>
              <Paragraph size='b5'>{formatInToPrice(appointment?.totalPrice)}</Paragraph>
            </div>
            {appointment?.addOnAmount ? (
              <div className='flex justify-between px-4 py-2 items-center'>
                <Paragraph size='b4' color={COLORS.GREY[400]} weight="bold">Addon: <span className='text-b5 font-semibold'>{appointment?.addOnReason}</span></Paragraph>
                <Paragraph size='b4' weight="bold">{formatInToPrice(appointment?.addOnAmount)}</Paragraph>
              </div>
            ) : null}
            {salon?.isTaxVisible ? (
              <div className='flex justify-between items-center px-4 py-2'>
                <Paragraph size='b5'>Tax</Paragraph>
                <Paragraph size='b5'>{formatInToPrice(appointment?.taxAmount)}</Paragraph>
              </div>
            ) : null}
            {appointment?.processingFeeAmount ? (
              <div className='flex justify-between items-center px-4 py-2'>
                <Paragraph size='b5'>Fees</Paragraph>
                <Paragraph size='b5'>{formatInToPrice(appointment?.processingFeeAmount)}</Paragraph>
              </div>
            ) : null}
            <div className='flex justify-between items-center px-4 py-2'>
              {appointment?.milestone ? <Paragraph
                size='b5'
                className='flex space-x-3'
              >
                <span>Reward discount -</span>
                <span>{
                  appointment?.milestone?.customerReceivesType === 'percentage'
                    ? `${appointment?.milestone?.customerReceives}%`
                    : `${formatInToPrice(appointment?.milestone?.customerReceives)?.replace(".00", "")}`
                }</span>
              </Paragraph> : <Paragraph size='b5'>Discount</Paragraph>}
              <Paragraph size='b5'>-{formatInToPrice(discount) || 0}</Paragraph>
            </div>
            <div className='border-t border-grey-50'></div>
            <div className='flex justify-between items-center px-4 py-2'>
              <Paragraph size='b5' weight='semiBold' className='inline'>Total</Paragraph>
              <Paragraph size='b5' weight='semiBold' className='inline'>{formatInToPrice(appointment?.totalPaid)}</Paragraph>
            </div>
          </div>

          <div className='flex justify-center items-end space-x-2 mt-[20px]'>
            <Paragraph size='b5' weight='normal'>Powered by</Paragraph> <SvgLogo width="50px" height="100%" />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ReceiptPrintView