import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGrowthIndicator = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={8} cy={8} r={8} fill="#7EA05C" />
    <path
      d="m9.796 7.148-.215.214-3.446 3.447c-.233.232-.507.283-.782.156-.264-.124-.409-.352-.37-.627.034-.198.128-.382.269-.526 1.11-1.131 2.236-2.248 3.357-3.369l.212-.212a1.954 1.954 0 0 0-.207-.024c-.45-.002-.9.002-1.348-.003-.356-.004-.601-.25-.606-.59-.005-.37.218-.62.59-.622a270.47 270.47 0 0 1 3.1-.002c.417.002.652.228.66.64.019 1.026.03 2.051.034 3.076 0 .376-.244.597-.617.594-.352-.003-.584-.23-.595-.595-.011-.433-.012-.867-.018-1.3-.002-.069-.01-.136-.018-.257Z"
      fill="#fff"
    />
  </svg>
);
export default SvgGrowthIndicator;
