import { GraphData, LocationSummary } from "../graphql/generated";

export const businessPerformanceAppointmentTrendGraphData = (appointmentTrendGraphData: GraphData[]) => {
  const labels = appointmentTrendGraphData?.map((data) => data.label);

  return {
    labels,
    datasets: [
      {
        label: "Appointment",
        data: appointmentTrendGraphData?.map((data) => data.value),
        borderColor: "rgba(126, 160, 92, 1)",
        backgroundColor: "rgba(207, 232, 183, 1)",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
    ],
  };
}

export const businessPerformanceSaleTrendGraphData = (products: GraphData[], services: GraphData[]) => {
  const labels = products?.map((data) => data.label);

  return {
    labels,
    datasets: [
      {
        label: "Products",
        data: products?.map((data) => data.value),
        borderColor: "#E4B91F",
        borderWidth: 2,
        fill: false,
        tension: 0.4
      }, {
        label: "Services",
        data: services?.map((data) => data.value),
        borderColor: "#7EA05C",
        borderWidth: 2,
        fill: false,
        tension: 0.4
      },
    ],
  };
}

export const businessPerformanceCustomersGraphData = (newCustomers: GraphData[], oldCustomers: GraphData[]) => {
  const labels = newCustomers?.map((data) => data.label);

  return {
    labels,
    datasets: [
      {
        label: "New",
        data: newCustomers?.map((data) => data.value),
        backgroundColor: '#E2E8E9',
      },
      {
        label: "Returning",
        data: oldCustomers?.map((data) => data.value),
        backgroundColor: '#7EA05C',
      },
    ],
  };
}

export const businessPerfomanceAppointmentPieGraphData = (data: GraphData[]) => {
  const colors = ["#A8DC96", "#CFE6C1", "#E2E8E9", "#7EA05C"];
  const labels = ["online", "walk in", "phone"]
  const counts = [];
  // loop through labels
  labels.forEach((label) => {
    const count = data?.find((data) => data.label?.toLowerCase() === label?.toLowerCase())?.value || 0;
    counts.push(count);
  })

  const sample = {
    labels: labels, // Use the array directly
    datasets: [
      {
        label: "Appointment",
        backgroundColor: colors,
        data: counts, // Use the array directly
      },
    ],
  };

  return sample;
}

export const getSumTotal = (data: GraphData[]) => {
  return data?.reduce((acc, data) => acc + +data.value, 0);
}

export const businessPerformanceSaleGraphData = (productSummary: LocationSummary, servicesSummary: LocationSummary, vouchersSummary: LocationSummary) => {
  const labels = ["Products", "Services", "Vouchers"];
  const gross = [productSummary?.grossSales, servicesSummary?.grossSales, vouchersSummary?.grossSales];
  const taxes = [productSummary?.taxes, servicesSummary?.taxes, vouchersSummary?.taxes];
  const net = [productSummary?.netSales, servicesSummary?.netSales, vouchersSummary?.netSales];

  return {
    labels,
    datasets: [
      {
        label: "gross",
        data: gross,
        backgroundColor: '#E2E8E9',
      },
      {
        label: "net",
        data: net,
        backgroundColor: '#7EA05C',
      },
      {
        label: "taxes",
        data: taxes,
        backgroundColor: '#CFE6C1',
      },
    ],
  };
}

export const customerSpendGraphData = (products: GraphData[], services: GraphData[]) => {
  const labels = products?.map((data) => data.label);

  return {
    labels,
    datasets: [
      {
        label: "Products",
        data: products?.map((data) => data.value),
        borderColor: "#E4B91F",
        borderWidth: 2,
        fill: true,
        tension: 0.4
      }, {
        label: "Services",
        data: services?.map((data) => data.value),
        borderColor: "#7EA05C",
        borderWidth: 2,
        fill: true,
        tension: 0.4
      },
    ],
  };
};

export const appointmentTrendCountGraphData = (counts: GraphData[]) => {
  const labels = counts?.map((data) => data.label);

  return {
    labels,
    datasets: [
      {
        label: "Appointments",
        data: counts?.map((data) => data.value),
        borderColor: "rgba(126, 160, 92, 1)",
        borderWidth: 2,
        fill: true,
        tension: 0.4
      },
    ],
  };
}

export const activeStaffRosterGraphData = (activeStaff: GraphData[], staff: GraphData[]) => {
  const labels = staff?.map((data) => data.label);

  return {
    labels,
    datasets: [
      {
        label: "Total staff",
        data: staff?.map((data) => data.value),
        backgroundColor: '#7EA05C',
      },
      {
        label: "Active staff",
        data: activeStaff?.map((data) => data.value),
        backgroundColor: '#E4B91F',
      },
    ],
  };
};

export const createServiceSpendGraphData = (sellers: GraphData[]) => {
  const colors = ["#BAC0B3", "#ADCA91", "#E2E2E2", "#668844", "#E2E2E2"];
  const labels = sellers.map((seller) => seller?.label);
  const counts = sellers.map((seller) => seller?.value);
  
  return {
    labels,
    datasets: [
      {
        label: 'Services Spend',
        data: counts,
        backgroundColor: colors,
      },
    ]
  };
};

export const createExpensesLineGraphDataGroup = (appointmentTrendGraphData: GraphData[]) => {
  const labels = appointmentTrendGraphData?.map((data) => data.label);

  return {
    labels,
    datasets: [
      {
        label: "Expenses",
        data: appointmentTrendGraphData?.map((data) => data.value),
        borderColor: "rgba(126, 160, 92, 1)",
        borderWidth: 2,
        fill: true,
        lineTension: 0
      },
    ],
  };
}

export const expensesPieGraphData = (expenses: GraphData[]) => {
  const colors = ["#BAC0B3", "#ADCA91", "#E2E2E2", "#668844", "#E2E2E2"];
  const labels = expenses.map((seller) => seller?.label);
  const counts = expenses.map((seller) => seller?.value);
  
  return {
    labels,
    datasets: [
      {
        label: 'Expenses',
        data: counts,
        backgroundColor: colors,
      },
    ]
  };
}

export const deductiblesPieGraphData = (deductibles: GraphData[]) => {
  const colors = ["#BAC0B3", "#ADCA91", "#E2E2E2", "#668844", "#E2E2E2"];
  const labels = deductibles.map((seller) => seller?.label);
  const counts = deductibles.map((seller) => seller?.value);
  
  return {
    labels,
    datasets: [
      {
        label: 'Deductibles',
        data: counts,
        backgroundColor: colors,
      },
    ]
  };
}

export const salesReportsGeneralOverviewGraphData = (gross: GraphData[], net: GraphData[]) => {
  const labels = gross?.map((data) => data.label);

  return {
    labels,
    datasets: [
      {
        label: "gross",
        data: gross?.map((data) => data.value),
        borderColor: "#E2E8E9",
      }, {
        label: "net",
        data: net?.map((data) => data.value),
        borderColor: "#7EA05C",
      },
    ],
  };
}

export const getCategorySum = (category: string, data: GraphData[]) => {
  // return sum of values for a given category
  return data?.filter((data) => data.category === category).reduce((acc, data) => acc + +data.value, 0);
}

export const monthlySpendGraphData = (summary: GraphData[]) => {
  const labels = summary?.map((data) => data.label) || [];

  const getDataSet = (category) => {
    return labels.map(label => {
      const item = summary.find(data => data.label === label && data.category === category);
      return item ? +item.value : 0;
    });
  };

  return {
    labels,
    datasets: [
      {
        label: "Online",
        data: getDataSet('online'),
        backgroundColor: '#CFE6C1',
      },
      {
        label: "Walk in",
        data: getDataSet('walk_in'),
        backgroundColor: '#7EA05C',
      },
      {
        label: "Phone",
        data: getDataSet('phone'),
        backgroundColor: '#A8DC96',
      },
      {
        label: "Promos",
        data: getDataSet('promos'),
        backgroundColor: '#E2E8E9',
      },
    ],
  };
} 

export const calculateWidthPercentages = (data: GraphData[]) => {
  if (!data || data.length === 0) return [];
  // Calculate the total sum of all values
  const total = data.reduce((sum, item) => sum + Number(item.value), 0);

  // Calculate the percentage for each value
  return data.map(item => ({
    label: item.label,
    value: total > 0 ? ((Number(item.value) / total) * 100).toFixed(2) + '%' : '0%'
  }));
};