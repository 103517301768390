import * as React from 'react'

const HelloWorld = () => {
  return (
    <div>
      <h1 className='text-3xl bg-red-400 font-bold underline'>Hello world sss!</h1>
    </div>
  )
}

export default HelloWorld
