// import { StaffProps } from "modules/account/types";

import { SalonStaff } from "../../../graphql/generated";

// export const convertStaffListToCalendarResources = (staffList?: StaffProps) => {
//   const list = {
//     id: 'unassigned-appointments',
//     title: 'Unassigned',
//     type1: 999999,
//   }

//   if (!staffList || !Array.isArray(staffList)) return [list];

//   return [list].concat(staffList?.map((staff, index) => ({
//     id: staff.staffId,
//     title: staff.firstName,
//     type1: index + 1,
//   })));
// }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertStaffListToCalendarResources = (staffList?: SalonStaff[]) => {
  if (!staffList || !Array.isArray(staffList)) return [];

  const filteredStaff = staffList.filter(staff => staff.canServeCustomers);

  return filteredStaff.map((staff, index) => ({
    id: staff.id,
    title: staff.user?.firstName,
    type1: index + 1,
  }));
};

export const convertStaffToWorkingOptions = (staff?: SalonStaff[]) => {
  const list = [{
    label: "Working",
    value: "working",
  }, {
    label: "All",
    value: "all",
  }];
  
  if (!staff || !Array.isArray(staff)) return list;

  const filteredStaff = staff.filter(staff => staff.canServeCustomers);

  return list.concat(filteredStaff.map((staff) => ({
    label: staff.user?.firstName,
    value: staff.id,
  })));
}

export const calendarViewOptions = [
  {
    label: "Day",
    value: "day",
  }, {
    label: "Week",
    value: "week",
  }
];

export const getWorkingStaff = (calendarCurrentDate: Date, staffList: SalonStaff[]): SalonStaff[] => {
  const currentDate = calendarCurrentDate.toISOString().split('T')[0];
  const date = new Date();
  const currentDateTime = date.toLocaleTimeString();
  const workingStaff = [];
  
  // check if the staff is working today
  Array?.isArray(staffList) && staffList?.forEach(staffMember => {
    const currentDay = calendarCurrentDate.toLocaleString("en-US", { weekday: "long" });
    const hour = staffMember?.staffHours?.find(day => day.day === currentDay.toLowerCase());
    if (hour?.status === 'open') {
      const timeOffs = staffMember?.staffTimeOffs;
      if (timeOffs) {
        let isWorking = true;
        timeOffs.forEach(timeOff => {
          const timeOffStartDate = timeOff.startAt.split('T')[0];
          const timeOffEndDate = timeOff.endAt.split('T')[0];
          const timeOffEndTime = timeOff.endAt.split('T')[1].slice(0, -1);
          const timeOffStartTime = timeOff.startAt.split('T')[1].slice(0, -1);
          isWorking = isDateTimeInRange(`${timeOffStartDate}T${timeOffStartTime}`, `${timeOffEndDate}T${timeOffEndTime}`, `${currentDate}T${currentDateTime}`) ? false : true;
        });
        if (isWorking) {
          workingStaff.push(staffMember);
        }
      } else {
        workingStaff.push(staffMember);
      }
    }
  });
  return workingStaff;
};

const isDateTimeInRange = (timeOffStartDate, timeOffEndDate, currentDate) => {
  const startDate = new Date(timeOffStartDate).getTime();
  const endDate = new Date(timeOffEndDate).getTime();
  const dateTimeToCheck = new Date(`${currentDate}`).getTime();

  // check if the dateTimeToCheck is between the start and end date
  return startDate <= dateTimeToCheck && dateTimeToCheck <= endDate;
}

export const getHeadbarTitle = (start: Date, end: Date) => {
  // remove an hour from end Date
  end?.setHours(end?.getHours() - 1);
  const startDate = start?.toISOString()?.split('T')[0];
  const endDate = end?.toISOString()?.split('T')[0];
  // check if the startDate and endDate are the same
  if (startDate === endDate) {
    // return short month, number and year
    return `${start?.toLocaleString('default', { month: 'short' })} ${start?.getDate()}, ${start?.getFullYear()}`;
  }

  // if they are not the same date but the same months return
  if (start?.getMonth() === end?.getMonth()) {
    // return month, number and year
    return `${start?.toLocaleString('default', { month: 'short' })} ${start?.getDate()} - ${end?.getDate()}, ${start?.getFullYear()}`;
  }

  return `${start?.toLocaleString('default', { month: 'short' })} ${start?.getDate()} - ${end?.toLocaleString('default', { month: 'short' })} ${end?.getDate()}, ${start?.getFullYear()}`;
}