import Papa from 'papaparse'
import React, { useState } from 'react'
import axios from 'axios'
import Button from '../ui/atoms/button/Button'
import { CSV_UPLOAD_PATTERN } from '../constants/pattern'
import {
  UploadStaffDocument
} from '../graphql/generated'
import { print } from 'graphql'
import { useSalonCache } from '../hooks/useSalonCache'
import StaffContextProvider, { useStaffContext } from '../context/staffContext'
import { formatStaffData } from './utils'

const Wrapper = (props) => {
  return (
    <StaffContextProvider>
      <ImportStaff {...props}/>
    </StaffContextProvider>
  )
}

const ImportStaff = (props) => {
  const { getSalonFieldValue } = useSalonCache()
  const { getSalonStaffs } = useStaffContext()
  const [isLoading, setIsLoading] = useState(false)

  const handleCsvUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    setIsLoading(true)
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: async function (results) {
          if (Array.isArray(results.data) && results.data.length > 0) {
            const staffData = formatStaffData(results.data);
            axios
              .post(
                '/graphql',
                {
                  query: print(UploadStaffDocument),
                  variables: {
                    input: {
                      salonStaffs: staffData,
                      salonId: getSalonFieldValue('id')
                    }
                  }
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  }
                }
              )
              .then((res) => {
                setIsLoading(false)
                const {
                  data: {
                    data: { uploadStaff }
                  }
                } = res

                if (uploadStaff.status === 200) {
                  getSalonStaffs()
                  props.addToast({
                    message: 'Upload successful',
                    variant: 'success'
                  })
                  props.onClose && props.onClose()
                  props.refetch && props.refetch()
                } else {
                  props.addToast({
                    message: uploadStaff.errors[0].message,
                    variant: 'error'
                  })
                }

              })
              .catch((err) => {
                setIsLoading(false)
                props.addToast({ message: err.message, variant: 'error' })
              })
          }
        }
      })
    }
  }
  return (
    <Button
      variant='success'
      className='relative'
      size='sm'
      rounded={props.rounded || 'md'}
      fontSize='b5'
      type='button'
    >
      {!isLoading ? (
        <>
          Import Staff
          <input
            className='cursor-pointer absolute block opacity-0 top-0 w-[128px] h-full'
            type='file'
            accept={CSV_UPLOAD_PATTERN}
            onChange={handleCsvUpload}
          />
        </>
      ) : (
        <div className='flex justify-center gap-x-2 cursor-pointer'>
          {[1, 2, 3].map((item, index) => (
            <div
              key={index}
              className='h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse'
            />
          ))}
        </div>
      )}
    </Button>
  )
}


export default Wrapper
