import { IOpeningHours } from "../types";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface AccountSetupTemplateProps {
  children: React.ReactNode;
  className?: string;
  title: string;
  subtitle?: string;
  skip?: SkipProps,
}

type SkipProps = {
  text: string;
  onClick?: () => void;
}

export type PageProps = {
  onNextPage: () => void;
  business: any;
  moveToNextPage?: () => void;
  onPrevPage?: () => void;
};

export type BvnPageProps = {
  recipient?: string;
  setStep?: (step: string) => void;
} & PageProps;

export interface BvnInput {
  bvn: string;
}

export interface LocationInput {
  branchName: string;
  address: string;
  city: string;
  state: string;
  locationSetupHome: boolean;
  locationSetupSalon: boolean;
  logoUrl: string;
  taxInclusivePricing: boolean;
  isTaxVisible?: boolean;
  bearsProcessingFee?: boolean;
  email?: string;
  phone?: string;
  isDepositRequired: boolean;
  depositType?: string;
  depositValue?: number;
  depositLinkValidityHours?: number;
}

export enum LocationSetupEnum {
  SALON = "salon",
  HOME = "home",
}

export interface IStaffProp {
  staffId: string;
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string,
  callingCode: string,
  phone: string;
  createdAt: string;
  updatedAt: string;
  specialty?: string;
  canServeCustomers: boolean;
  role?: string;
  level?: number;
  canLogin: boolean;
  workingHours?: IOpeningHours;
}

export type HeaderImageInput = {
  headerImageUrl: string;
  about: string;
  cancellationPolicy: string;
  acceptsOnlineBookings?: boolean;
}

export type AddSocialLinksArgs = {
  data: Record<string, string | null>
}

export type SalonSocialInput = {
  name: string;
  url: string;
  isAvailable: boolean;
  prefix?: 'insta' | 'fb' | 'twitter';
  id: number;
  icon: ({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) => React.JSX.Element;
}

interface SVGRProps {
  title?: string;
  titleId?: string;
}

// type SvgComponentType = (
//   { title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps
// ) => JSX.Element;

export type SocialLinkInput = {
  facebook: SalonSocialInput
  instagram: SalonSocialInput;
  twitter: SalonSocialInput;
}