import axios from 'axios';
import { API_ERRORS } from '../constants/errors';
import { CategoriesDocument, CreateCategoryDocument, CreateCategoryInput, CreateClientDocument, UserDocument } from '../graphql/generated';
import { print } from 'graphql';
import { AddClientFunctionApiProps } from './types';
import { ToastProps } from '../ui';

const token = localStorage.getItem('token');

export const createSalonClient = async ({client, addToast, salonId} : AddClientFunctionApiProps) => {
  try {
    await axios.post(
      '/graphql',
      {
        query: print(CreateClientDocument),
        variables: { input: { ...client, salonId } },
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    ).then(({ data }) => {
      if (data?.data?.createClient?.client) {
        return data?.data?.createClient?.client;
      }
      if (data?.errors?.length) {
        const message = data?.errors[0]?.message || API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
      if (data?.data?.createClient?.errors?.length) {
        const message = data?.data?.createClient?.errors[0]?.message || API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    })
  } catch (createClientError) {
    if (axios.isAxiosError(createClientError)) {
      const message = createClientError?.response?.data?.message || API_ERRORS.BUSINESS_CLIENTS_CREATE_FAILED;
      addToast && addToast({
        message,
        variant: 'error',
      })
    }
  }
}

export const createCategory = async (input: CreateCategoryInput, addToast?: (toast: ToastProps) => void) => {
  try {
    const {
      data: {
        data: { createCategory: createCategory_ }
      }
    } = await axios
      .post(
        '/graphql',
        {
          query: print(CreateCategoryDocument),
          variables: { input: { ...input } }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (createCategory_.status === 200) {
        return createCategory_?.category;
      } else {
        addToast && addToast({
          message: createCategory_.errors[0].message,
          variant: 'error'
        })
      }
  } catch (err) {
    console.log(err)
  }
}

export const getCategoriesApi = async (salonId: string, addToast?: (toast: ToastProps) => void) => {
  try {
    const res = await axios.post(
      '/graphql',
      {
        query: print(CategoriesDocument),
        variables: { salonId }
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    const {
      data: {
        data: { categories: categories_ }
      }
    } = res;
    return categories_;
  } catch (err) {
    addToast && addToast({ message: err.message, variant: 'error' })
  }
}

export const getBusinessDetails = () => {
  axios
    .post(
      '/graphql',
      {
        query: print(UserDocument),
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
    )
    .then((res) => {
      const {
        data: {
          data: { user }
        }
      } = res
      localStorage.setItem('userData', JSON.stringify(user))
      localStorage.setItem('role', JSON.stringify(user?.salonStaff?.staffRole))
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch((err) => {
      // addToast({ message: err.message, variant: 'error' })
    })
}