import React, { createContext, useContext, useState } from 'react'
import {
  ClosedPeriod,
  CreateSalonStaffDocument,
  CreateSalonStaffInput,
  CreateStaffHourDocument,
  CreateStaffTimeOffDocument,
  CreateStaffTimeOffInput,
  DeleteSalonStaffDocument,
  DeleteSalonStaffInput,
  DeleteStaffTimeOffDocument,
  DeleteStaffTimeOffInput,
  Salon,
  SalonHourInput,
  SalonStaff,
  SalonStaffDetails,
  SalonStaffDocument,
  SalonStaffInput,
  StaffManagementDocument,
  SalonsDocument,
  StaffManagement,
  UpdateSalonStaffDetailsDocument,
  UpdateSalonStaffDetailsInput,
  UpdateSalonStaffDocument,
  UploadStaffDocument,
  UploadStaffMutationVariables
} from '../graphql/generated'
import { useSalonCache } from '../hooks/useSalonCache'
import { useToast } from '../hooks'
import axios from 'axios'
import { print } from 'graphql'
import { formatDateToOriginalDate } from '../utils/misc'
import { fetchClosedPeriodsData } from '../uicomponents/appointmentComponents/api'
import { logger } from '../core/logger'
import { ToastProps } from '../ui'

const StaffContext = createContext(undefined)

const StaffContextProvider = ({ children }) => {
  const token = localStorage.getItem('token')
  const [salonStaffManagement, setSalonStaffManagement] = useState<StaffManagement>()
  const [salonStaffs, setSalonStaffs] = useState<SalonStaff[]>([])
  const [salons, setSalons] = useState<Salon[]>([])
  const [currentSalonStaff, setCurrentSalonStaff] = useState<SalonStaffDetails>()
  const [closedPeriods, setClosedPeriods] = useState<ClosedPeriod[]>([])
  const { getSalonFieldValue } = useSalonCache()
  const { toast, addToast } = useToast()
  const [days, setDays] = useState<Date[]>([])
  const [staffCreationPayload, setStaffCreationPayload] = useState({})

  const setStaffInContext = (payload) => {
    // console.log(payload)
    setStaffCreationPayload({...staffCreationPayload, payload})
  }

  const getSalonStaffs = (q?: string) => {
    const startDate = Array.isArray(days) && days?.length ? formatDateToOriginalDate(days[0], 'start') as string : null;
    const endDate = Array.isArray(days) && days?.length ?  formatDateToOriginalDate(days[days.length - 1], "end") : null;
    axios
      .post(
        '/graphql',
        {
          query: print(StaffManagementDocument),
          variables: {q: q, salonId: getSalonFieldValue('id'), startDate, endDate }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        // console.log(res.data.data.salonStaffs);
        const {
          data: {
            data: { staffManagement: sf }
          }
        } = res
        setSalonStaffManagement(sf)
        setSalonStaffs(sf.staffs)

        getClosedPeriods()
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  const getSalons = () => {
    axios
      .post(
        '/graphql',
        { query: print(SalonsDocument) },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then((res) => {
        const salons = res.data.data.salons as Salon[]
        setSalons(salons)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getSalonStaffDetails = (salonStaffId: string) => {
    axios
      .post(
        '/graphql',
        {
          query: print(SalonStaffDocument),
          variables: { salonStaffId: salonStaffId}
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        // console.log("Logging salon staffs");
        // console.log(res.data.data.salonStaff);
        const {
          data: {
            data: { salonStaff: sf }
          }
        } = res
        // console.log("currentSalonStaff: ", sf)
        setCurrentSalonStaff(sf)
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  const getClosedPeriods = async () => {
    const businessClosedPeriodData = await fetchClosedPeriodsData({ salonId: getSalonFieldValue('id') });
    setClosedPeriods(businessClosedPeriodData);
  }

  const createStaffTimeOff = (input: CreateStaffTimeOffInput, closeModal: () => void, reset: () => void, addTimeOffToast: (toast: ToastProps) => void) => {
    axios
      .post(
        '/graphql',
        {
          query: print(CreateStaffTimeOffDocument),
          variables: { input: { ...input, salonId: getSalonFieldValue('id') } }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { createStaffTimeOff: csto }
          }
        } = res

        if (csto.status === 200) {
          addTimeOffToast({
            message: 'Staff time off saved successfuly',
            variant: 'success'
          })
          closeModal()
          reset()
        } else {
          addTimeOffToast({ message: csto.errors[0].message, variant: 'error' })
        }
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  const createStaffHour = (
    salonStaffId: string,
    staffHours: SalonHourInput[]
  ) => {
    axios
      .post(
        '/graphql',
        {
          query: print(CreateStaffHourDocument),
          variables: { input: { salonStaffId, staffHours } }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((res) => {
        getSalonStaffs()
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const updateSalonStaff = (
    input: SalonStaffInput,
    staffHours: SalonHourInput[],
    closeModal: () => void,
    reset: () => void,
  ) => {
    axios
      .post(
        '/graphql',
        {
          query: print(UpdateSalonStaffDocument),
          variables: {
            input: { salonStaff: input, salonId: getSalonFieldValue('id') }
          }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { updateSalonStaff: uss }
          }
        } = res
        if (uss.status === 200) {
          createStaffHour(uss.salonStaff.id, staffHours)
          addToast({
            message: 'Staff updated successfully',
            variant: 'success'
          })
          getSalonStaffs()
          closeModal()
          reset()
        } else {
          addToast({ variant: 'error', message: uss.errors[0].message })
        }
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  const createSalonStaff = (
    input: CreateSalonStaffInput,
    closeModal: () => void,
    reset: () => void,
  ) => {
    // console.log("create salon staff works");
    axios
      .post(
        '/graphql',
        {
          query: print(CreateSalonStaffDocument),
          variables: {
            input: {...input, salonId: getSalonFieldValue('id') }
          }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { createSalonStaff: uss }
          }
        } = res
        // console.log("RES: ", res)
        if (uss.status === 200) {
          addToast({
            message: 'Staff created successfully',
            variant: 'success'
          })
          closeModal()
          getSalonStaffs()
        } else {
          addToast({ variant: 'error', message: uss.errors[0].message })
        }
      })
      .catch((err) => {
        logger.error(`error creating staff`, input)
        addToast({ variant: 'error', message: err.message })
      })
  }

  const updateSalonStaffDetails = (
    input: UpdateSalonStaffDetailsInput,
    closeModal: () => void,
    reset: () => void,
    toastMessage?: string
  ) => {
    axios
      .post(
        '/graphql',
        {
          query: print(UpdateSalonStaffDetailsDocument),
          variables: {
            input: {...input, salonId: getSalonFieldValue('id') }
          }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { updateSalonStaffDetails: uss }
          }
        } = res
        if (uss.status === 200) {
          addToast({
            message: toastMessage || 'Staff updated successfully',
            variant: 'success'
          })
          getSalonStaffs()
          closeModal()
        } else {
          addToast({ variant: 'error', message: uss.errors[0].message })
        }
      })
      .catch((err) => {
        logger.error(`error updating staff`, input)
        addToast({ variant: 'error', message: err.message })
      })
  }

  const deleteSalonStaff = (
    input: DeleteSalonStaffInput,
    closeModal: () => void,
    reset?: () => void,
  ) => {
    axios
      .post(
        '/graphql',
        { query: print(DeleteSalonStaffDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((res) => {
        addToast({ variant: 'success', message: 'Staff deleted successfully' })
        getSalonStaffs()
        closeModal()
        reset && reset()
      })
      .catch((err) => {
        logger.error(`error deleting staff`, input)
        addToast({ variant: 'error', message: err.message })
      })
  }

  const deleteStaffTimeOff = (
    input: DeleteStaffTimeOffInput,
    closeModal: () => void,
    reset: () => void,
  ) => {
    axios
      .post(
        '/graphql',
        { query: print(DeleteStaffTimeOffDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((res) => {
        getSalonStaffs()
        closeModal()
        reset()
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  const uploadStaff = async (input: UploadStaffMutationVariables) => {
    try {
      const {
        data: {
          data: { uploadStaff: us }
        }
      } = await axios.post(
        '/graphql',
        { query: print(UploadStaffDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (us.status === 200) {
        getSalonStaffs()
        addToast({ message: 'Staff upload successful', variant: 'success' })
      } else {
        addToast({ message: us.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  return (
    <StaffContext.Provider
      value={{
        salonStaffManagement,
        salonStaffs,
        addToast,
        toast,
        getSalonStaffs,
        salons,
        getSalons,
        currentSalonStaff,
        setCurrentSalonStaff,
        getSalonStaffDetails,
        createStaffTimeOff,
        createSalonStaff,
        updateSalonStaffDetails,
        updateSalonStaff,
        createStaffHour,
        deleteSalonStaff,
        uploadStaff,
        getClosedPeriods,
        closedPeriods,
        deleteStaffTimeOff,
        days,
        setDays,
        setStaffInContext
      }}
    >
      {children}
    </StaffContext.Provider>
  )
}

// Staff Context custom hook
export const useStaffContext = () => {
  const context = useContext(StaffContext)
  if (!context) {
    throw new Error('useStaffContext must be used with a StaffContextProvider')
  }
  return context
}

export default StaffContextProvider
