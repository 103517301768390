import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNotificationReview = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={16} fill="#F3F6F6" />
    <path d="M17 21h4v1h-4v-1Zm0-2h6v1h-6v-1Zm0-2h6v1h-6v-1Z" fill="#667368" />
    <path
      d="M18.274 13.608 16 9l-2.274 4.608-5.086.74 3.68 3.587L11.451 23 15 21.134v-1.13l-2.22 1.168.526-3.068.089-.518-.377-.367-2.229-2.174 3.08-.447.52-.075.234-.472L16 11.259l1.378 2.792.232.472.52.075 3.728.542.142-.99-3.726-.542Z"
      fill="#667368"
    />
  </svg>
);
export default SvgNotificationReview;
