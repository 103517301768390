import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPadlock = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 116 225"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M0 69.268c0-2.193 1.443-4.386 4.348-6.07l8.55-4.934c5.772-3.347 15.208-3.347 21.008 0l77.517 44.756c2.886 1.674 4.348 3.848 4.348 6.07v102.381c0 2.203-1.443 4.406-4.348 6.079l-8.551 4.935c-5.771 3.347-15.207 3.347-21.007 0L4.348 177.729C1.462 176.055 0 173.881 0 171.669v-102.4Z"
      fill="#136B62"
    />
    <path
      opacity={0.4}
      d="M0 69.268c0-2.193 1.443-4.386 4.348-6.07l8.55-4.934c5.772-3.347 15.208-3.347 21.008 0l77.517 44.756c2.886 1.674 4.348 3.848 4.348 6.07v102.381c0 2.203-1.443 4.406-4.348 6.079l-8.551 4.935c-5.771 3.347-15.207 3.347-21.007 0L4.348 177.729C1.462 176.055 0 173.881 0 171.669v-102.4Z"
      fill="#fff"
    />
    <path
      opacity={0.4}
      d="m102.872 120.084 8.551-4.935c5.771-3.347 5.771-8.781 0-12.129L33.906 58.264c-5.771-3.347-15.207-3.347-21.007 0L4.348 63.2c-5.772 3.357-5.772 8.782 0 12.129l77.517 44.756c5.8 3.347 15.207 3.347 21.007 0Z"
      fill="#fff"
    />
    <path
      opacity={0.3}
      d="M92.359 122.594v102.401a21.415 21.415 0 0 0 10.503-2.501l8.561-4.944c2.886-1.673 4.367-3.847 4.338-6.088V109.08c0 2.212-1.433 4.396-4.338 6.069l-8.561 4.944a21.415 21.415 0 0 1-10.503 2.501Z"
      fill="#136B62"
    />
    <path
      d="m35.945 173.246 18.516 10.696-2.886-33.482c2.213-1.174 3.627-4.05 3.627-8.186 0-7.377-4.483-15.938-10.013-19.131-5.531-3.194-9.965.202-9.965 7.56a25.157 25.157 0 0 0 3.626 12.37l-2.905 30.173Z"
      fill="#455A64"
    />
    <path
      d="m35.945 173.246 16.765-9.686 1.741 20.372-18.506-10.686ZM45.198 123.132c3.617 2.087 6.782 6.483 8.542 11.331l-14.928 8.656a25.15 25.15 0 0 1-3.588-12.417c0-7.359 4.444-10.764 9.974-7.57Z"
      fill="#37474F"
    />
    <path
      d="M20.94 71.547V30.6c0-12.687 4.81-22.719 13.149-27.547 7.974-4.608 17.843-3.983 27.779 1.76 18.275 10.532 32.54 36.243 32.54 58.53V104.5c0 1.241-.818 2.482-2.453 3.424a13.095 13.095 0 0 1-11.84 0c-1.636-.962-2.463-2.183-2.463-3.424V63.343c0-16.352-11.062-36.455-24.162-44.025-4.473-2.578-8.484-3.223-11.023-1.75-2.982 1.72-4.81 6.598-4.81 13.032v40.938c0 1.231-.836 2.453-2.452 3.386a13.1 13.1 0 0 1-11.841 0c-1.626-.962-2.443-2.155-2.453-3.386l.029.01Z"
      fill="#455A64"
    />
  </svg>
);
export default SvgPadlock;
