/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, ChangeEvent, SVGProps, Fragment, useRef } from 'react'
import ReactQuill from 'react-quill'
import {
  Button,
  Checkbox,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Layout,
  Modal,
  MultiSelect,
  MultiSelectOption,
  Paragraph,
  Pill,
  Skeleton,
  Table,
  TableSkeleton,
  Tabs,
  ToastProps
} from '../ui'
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper'
import { useModal, useToast } from '../hooks'
import {
  AutomatedMessage,
  Card,
  CardsDocument,
  Client,
  CreateMessageInput,
  Message,
  MessageRecipient,
  MessageRecipientsDocument,
  PageInfo,
  User,
  UserDocument
} from '../graphql/generated'
import SvgCalendarFrame from '../ui/icons/CalendarFrame'
import { COLORS } from '../constants/colors'
import {
  convertDateTimeStringToTimeZone,
  formatTextIntoCaps,
  getDayMonthAndNumberFromDateString,
  getNumberMonthAndYearFromDate,
  limitString
} from '../utils/misc'
import MessageProvider, { useMessageContext } from '../context/messageContext'
import { Controller, useForm } from 'react-hook-form'
import { ERRORS } from '../constants/errors'
import { getImageUploadContent } from '../utils/upload'
import { IMAGE_UPLOAD_PATTERN, REGEX_PATTERNS } from '../constants/pattern'
import { useFileUploader_ } from '../hooks/useUploader'
import { useSalonCache } from '../hooks/useSalonCache'
import { Listbox, Menu, Transition } from '@headlessui/react'
import { SvgBrandMessaging, SvgDropDown, SvgGreenSignal, SvgGreySignal, SvgPlus, SvgSelectDropDown } from '../ui/icons'
import DOMPurify from 'dompurify';
import axios from 'axios'
import { print } from 'graphql'
import { canPerformAction, canShowPage, checkPageRightStatus } from '../utils/permission'
import { PERMISSION_CONSTANTS } from '../constants/permission'
import AddonSubscription from '../uicomponents/AddonSubscription'
import BlockPage from '../uicomponents/BlockPage'
import { getAddon } from '../uicomponents/settingsUtils'
import AddonDetails from '../uicomponents/AddonDetails'
import { getBusinessDetails } from '../modals/api'

type AutomatedMessageProps = {
  message: AutomatedMessage
  openMessageSettings: (tag: string) => void
  smsEnabled?: boolean
}

type AutomatedMessgeSettingProps = {
  message?: AutomatedMessage
  closeModal: (refetch?: boolean) => void
  isVisible: boolean
}

const CUSTOM_MESSAGE_HEADINGS = [
  { label: 'Subject Line', value: 'title' },
  { label: 'Type', value: 'type' },
  { label: 'Recipient Category', value: 'recipientCategory' },
  { label: 'Recipient(s)', value: 'recipients' },
  { label: 'Status', value: 'status' },
  { label: 'Date Sent', value: 'dateSent' }
];

const MOBILE_CUSTOM_MESSAGE_HEADINGS = [
  {
    title: 'Message',
    key: 'message'
  },
  {
    title: 'Status',
    key: 'status'
  }
]

const MESSAGING_TAB_NAME = {
  AUTOMATED: 'Automated messages',
  CUSTOM: 'Custom messages',
  SENT: 'Sent messages'
}

const CREATE_CUSTOM_MESSAGING_TAB = {
  COMPOSE_MESSAGE: "Compose message",
  RECIPIENT_INFORMATION: "Recipient's information",
}

const CREATE_CUSTOM_MESSAGING_TABS = [
  {
    key: CREATE_CUSTOM_MESSAGING_TAB.COMPOSE_MESSAGE,
    title: CREATE_CUSTOM_MESSAGING_TAB.COMPOSE_MESSAGE
  },
  {
    key: CREATE_CUSTOM_MESSAGING_TAB.RECIPIENT_INFORMATION,
    title: CREATE_CUSTOM_MESSAGING_TAB.RECIPIENT_INFORMATION
  }
]

const CUSTOM_MESSAGE_GROUP_RECIPIENT = [
  {
    key: 'all_clients',
    title: 'All clients',
    description: 'All the clients in your database (102)'
  }, {
    key: 'new_clients',
    title: 'New clients',
    description: 'Clients added in the last 30 days'
  }, {
    key: 'recent_clients',
    title: 'Recent clients',
    description: 'Clients with their last appointment in the last 30 days who booked any service at any location with any staff'
  }, {
    key: 'loyal_clients',
    title: 'Loyal clients',
    description: 'Clients with 2 or more sales in the last 5 months, who spent any amount and booked any service at any location with any staff'
  }, {
    key: 'lapsed_clients',
    title: 'Lasped clients',
    description: 'Clients with 3 or more sales in the last 12 months, who booked any service at any location with any staff and did not return in the last 2 months'
  }, {
    key: 'clients_by_appointment_date',
    title: 'Clients by Appointment date',
    description: 'Clients by appointments in the next 30 days, who booked any service at any location with any staff'
  }, {
    key: 'clients_with_sales',
    title: 'Clients with Sales',
    description: 'Clients with sales in the last 30 days, who spent any amount and purchased any service, product, gift card or membership at any location'
  }
]

const Messaging = () => {
  const MESSAGING_TABS = [
    {
      key: MESSAGING_TAB_NAME.AUTOMATED,
      title: MESSAGING_TAB_NAME.AUTOMATED,
      show: true
    },
    {
      key: MESSAGING_TAB_NAME.CUSTOM,
      title: MESSAGING_TAB_NAME.CUSTOM,
      show: true
    },
    {
      key: MESSAGING_TAB_NAME.SENT,
      title: MESSAGING_TAB_NAME.SENT,
      show: canPerformAction(PERMISSION_CONSTANTS.messaging.viewSentMessages),
      disabled: true,
    }
  ]
  const urlParams = new URLSearchParams(window.location.search);
  const messagingAction = urlParams.get("action");
  const [activeTab, setActiveTab] = useState<string>(MESSAGING_TAB_NAME.AUTOMATED)
  const { toast, addToast } = useToast()
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reloadMessagingPage, setReloadMessagingPage] = useState(false);
  const [sendToRetentionCustomers, setSendToRetentionCustomers] = useState(false);
  const [customMessagePageStatus, setCustomMessagePageStatus] = useState(checkPageRightStatus('messaging', 'Messaging::SendMessage'))

  const reloadPage = async() => {
    await getBusinessDetails()
    setReloadMessagingPage(prev => !prev)
  }

  useEffect(() => {
    setCustomMessagePageStatus(checkPageRightStatus('messaging', 'Messaging::SendMessage'))
  }, [reloadMessagingPage])

  const getTabView = () => {
    switch (activeTab) {
      case MESSAGING_TAB_NAME.AUTOMATED:
        return <AutomatedMessaging />
      case MESSAGING_TAB_NAME.CUSTOM:
        return customMessagePageStatus === 'showPage' ? <CustomMessaging setActiveTab={setActiveTab} setSelectedMessage={setSelectedMessage} retentionMessaging={sendToRetentionCustomers} /> : customMessagePageStatus === 'shouldSubscribe' ? <AddonSubscription module='messaging' addToast={addToast} reloadPage={reloadPage} /> : customMessagePageStatus === 'hidePage' ? <BlockPage /> : null
      case MESSAGING_TAB_NAME.SENT:
        return <SentMessages message={selectedMessage} />
    }
  }

  useEffect(() => {
    if (messagingAction && messagingAction === 'send') {
      setActiveTab(MESSAGING_TAB_NAME.CUSTOM)
      setSendToRetentionCustomers(true)
    }
  }, [])

  return (
    <MessageProvider>
      <Layout pageTitle='Messaging'>
        <ToastWrapper toast={toast as ToastProps} />
        <Tabs
          tabs={MESSAGING_TABS}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {getTabView()}
      </Layout>
    </MessageProvider>
  )
}

const AutomatedMessaging = () => {
  const { isVisible, openModal, closeModal } = useModal()
  const {
    isVisible: isAddonModalVisible,
    openModal: openAddonModal,
    closeModal: closeAddonModal
  } = useModal()
  const [cards, setCards] = useState<Card[]>([])
  const { getAutomatedMessages, automatedMessages, toast, addToast } = useMessageContext()
  const [automatedMessageTag, setAutomatedMessageTag] = useState<string | null>(
    null
  )
  const [reloadPage, setReloadPage] = useState(false);
  const [smsEnabled, setSmsEnabled] = useState<boolean>(canPerformAction("Messaging::SmsReminder"))
  const [isFetchingAutomatedMessages, setIsFetchingAutomatedMessages] = useState(false)
  const addon = getAddon("sms reminders")

  const getCards = () => {
    axios
      .post(
        '/graphql',
        {
          query: print(CardsDocument),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { cards }
          }
        } = res
        setCards(cards);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((err) => {
        // addToast({ message: err.message, variant: 'error' })
      })
  }

  const getBusinessDetails = () => {
    axios
      .post(
        '/graphql',
        {
          query: print(UserDocument),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { user }
          }
        } = res
        localStorage.setItem('userData', JSON.stringify(user))
        localStorage.setItem('role', JSON.stringify(user?.salonStaff?.staffRole))
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((err) => {
        // addToast({ message: err.message, variant: 'error' })
      })
  }

  useEffect(() => {
    setIsFetchingAutomatedMessages(true)
    getAutomatedMessages()
    getCards()
    getBusinessDetails()
    setIsFetchingAutomatedMessages(false)
  }, [])

  useEffect(() => {
    setSmsEnabled(canPerformAction("Messaging::SmsReminder"))
  }, [reloadPage])

  const openMessageSettings = (tag: string) => {
    setAutomatedMessageTag(tag)
    openModal()
  }

  const AutomatedMessagesShimmer = () => {
    return (
      <>
        {[1, 2, 3, 4].map((item) => (
          <div
            style={{ maxWidth: '286px' }}
            className='w-full flex flex-col p-6 space-y-6 border border-grey-100 rounded-md'
            key={item}
          >
            <Skeleton width='40px' height='40px' />
            <Skeleton width='40%' height='1rem' />
            <Skeleton width='80%' height='1rem' />
            <div className='flex flex-wrap gap-2'>
              <Skeleton width='4rem' height='1rem' />
              <Skeleton width='4rem' height='1rem' />
            </div>
          </div>
        ))}
      </>
    )
  }

  const AutomatedMessageCard = ({
    message,
    openMessageSettings,
    smsEnabled
  }: AutomatedMessageProps) => {
    return (
      <div
        style={{ maxWidth: '286px' }}
        className='w-full flex flex-col p-6 space-y-6 border border-grey-100 rounded-md cursor-pointer'
        onClick={() => openMessageSettings(message.tag)}
      >
        <SvgCalendarFrame width='40px' height='40px' />

        <Heading variant='h3' size='h11' weight='semiBold'>
          {message?.title}
        </Heading>

        <Paragraph size='b5' color={COLORS.GREY[400]}>
          {limitString(message?.body, 100)}
        </Paragraph>

        <div className='flex flex-wrap gap-2'>
          {message.preference.email && (
            <Pill variant={'success'} className='uppercase'>
              email
            </Pill>
          )}
          {message.preference.sms && (
            <Pill variant={smsEnabled ? 'success' : 'light'} className={`uppercase ${!smsEnabled ? 'line-through' : ''}`}>
              sms
            </Pill>
          )}
        </div>
        {message.preference.sms && !smsEnabled && canPerformAction(PERMISSION_CONSTANTS?.settings?.addons) ?
          <Button
            variant='primary'
            size='lg'
            type='button'
            rounded='lg'
            onClick={openAddonModal}
          >
            Subscribe
          </Button> : null}
      </div>
    )
  }

  const AutomatedMessageSettings = ({
    isVisible,
    message,
    closeModal
  }: AutomatedMessgeSettingProps) => {
    return (
      <Modal
        title={message?.title}
        subTitle=''
        show={isVisible}
        closeModal={closeModal}
      >
        <form className='w-full mt-6 space-y-6' autoComplete='off'>
          <div className='flex flex-col'>
            <span className='text-b5'>Message</span>
            <div className='w-full mt-2 bg-grey-50 border border-grey-20 p-2 rounded-md'>
              <Paragraph size='b5' color={COLORS.GREY[400]}>
                {message?.body}
              </Paragraph>
            </div>
          </div>
          <div style={{ paddingTop: '40px' }} className='w-full'>
            <Button
              variant='text'
              size='none'
              rounded='none'
              fontSize='b5'
              className='mx-auto'
              onClick={() => closeModal()}
              type='button'
            >
              Close
            </Button>
          </div>
        </form>
      </Modal>
    )
  }

  const initiateCloseAddonModal = async (action?: string) => {
    await getBusinessDetails()
    await getCards()
    setReloadPage(true)
    closeAddonModal()
    if (action === 'reload') {
      window.location.reload()
    }
  }

  const getAutomatedMessagesContent = () => {
    if (isFetchingAutomatedMessages) {
      return (
        <div
          style={{ gap: '2.5rem' }}
          className='flex flex-wrap w-full gap-10 py-12 px-6'
        >
          <AutomatedMessagesShimmer />
        </div>
      )
    }

    if (Array.isArray(automatedMessages) && automatedMessages.length) {
      return (
        <div
          style={{ gap: '2.5rem' }}
          className='flex flex-wrap w-full py-12 px-6'
        >
          {automatedMessages.map((message, index) => (
            <AutomatedMessageCard
              key={index}
              message={message}
              openMessageSettings={openMessageSettings}
              smsEnabled={smsEnabled}
            />
          ))}
        </div>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
          <Heading variant='h2' size='h6'>
            Automated Messages
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You don't have any automated messages yet.
          </Paragraph>
        </div>
      </div>
    )
  }

  const getAutomatedMessageById = (tag: string | null) => {
    if (Array.isArray(automatedMessages) && automatedMessages.length > 0) {
      return automatedMessages.find((message) => message?.tag === tag)
    }
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {getAutomatedMessagesContent()}
      <AutomatedMessageSettings
        isVisible={isVisible}
        closeModal={() => closeModal()}
        message={getAutomatedMessageById(automatedMessageTag)}
      />
      <AddonDetails addon={addon} isOpen={isAddonModalVisible} closeModal={initiateCloseAddonModal} cards={cards} addToast={addToast} />
    </>
  )
}

const CustomMessaging = ({ setActiveTab, setSelectedMessage, retentionMessaging }) => {
  const { getCustomMessages, customMessages, toast } = useMessageContext()
  const [customMessage, setCustomMessage] = useState<Message>()
  const [messageType, setMessageType] = useState<string>('')
  const [isLoadingMessages, setIsLoadingMessages] = useState(false)
  const action = {
    createCustomMessage: canPerformAction(PERMISSION_CONSTANTS.messaging.sendMessage)
  }
  const selectMessageType = (type: string) => {
    setMessageType(type)
  }

  const handleClick = (id: number) => {
    const m = customMessages[id]
    if (!m) {
      return;
    }
    setCustomMessage(customMessages[id])

    if (m?.status === 'draft' || m?.status === 'test') {
      selectMessageType('email')
    } else {
      setSelectedMessage(m)
      setActiveTab(MESSAGING_TAB_NAME.SENT)
    }
  }

  const generateCustomMessagesTableData = (
    messages: Message[],
    countryCode: string
  ) => {
    return messages.map((message) => ({
      title: message.title,
      type: () => {
        const type =
          message.emailType === 'oneOff' ? 'one-off' : message.emailType
        return type ? (
          <Pill variant='light' className='uppercase'>
            {type}
          </Pill>
        ) : null
      },
      recipientCategory: message?.sendGroup === 'groups' ? formatTextIntoCaps(message?.mailCategory) : 'Selected Individual(s)',
      recipients: `Customers (${message.recipientCount})`,
      status: () => {
        const status = message.status
        return status === 'draft' ? (
          <div className='flex items-center'>
            <SvgGreySignal width="16px" height="16px" /> <span className='ml-2 text-grey-900 text-b5'>Draft</span>
          </div>
        ) : status === 'sent' ? (
          <div className='flex items-center'>
            <SvgGreenSignal width="16px" height="16px" /> <span className='ml-2 text-grey-900 text-b5'>Sent</span>
          </div>
        ) : status === 'test' ? (
          <div className='flex items-center'>
            <SvgGreySignal width="16px" height="16px" /> <span className='ml-2 text-grey-900 text-b5'>Test</span>
          </div>
        ) : null
      },
      date: getNumberMonthAndYearFromDate(
        convertDateTimeStringToTimeZone(message.createdAt, countryCode)
      )
    }))
  }

  useEffect(() => {
    setIsLoadingMessages(true)
    getCustomMessages()
    setIsLoadingMessages(false)
  }, [])

  useEffect(() => {
    if (retentionMessaging) {
      selectMessageType('email')
    }
  }, [retentionMessaging])

  const closeMessageForm = (type: string) => {
    setMessageType(type)
    setCustomMessage(null)
  }

  const getCustomMessagesContent = () => {
    if (isLoadingMessages) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    } else if (Array.isArray(customMessages) && customMessages.length) {
      const tableClientsData = generateCustomMessagesTableData(
        customMessages,
        'NG'
      )
      return (
        <>
          <div className='py-4 px-6 flex justify-between items-center xl:flex'>
            <Heading variant='h2' size='h11' weight='semiBold'>
              Custom messages ({customMessages.length})
            </Heading>
            {action.createCustomMessage ? (
              <Button
                variant='primary'
                size='sm'
                disabled={false}
                loading={false}
                type='button'
                rounded='md'
                fontSize='b5'
                onClick={() => selectMessageType('email')}
              >
                Create Custom Message
              </Button>
            ): null}
          </div>
          <Table
            headers={CUSTOM_MESSAGE_HEADINGS}
            mobileHeaders={MOBILE_CUSTOM_MESSAGE_HEADINGS}
            rows={tableClientsData}
            onClick={handleClick}
          />
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
          <SvgBrandMessaging width="100%" height="100%" />
          <Heading variant='h2' size='h6'>
            Custom Message(s)
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You don't have any custom message(s) yet.
          </Paragraph>
          {action?.createCustomMessage ?
            <Button
              variant='primary'
              size='md'
              disabled={false}
              loading={false}
              type='button'
              rounded='lg'
              onClick={() => selectMessageType('email')}
            >
              Create Custom Message
            </Button> : null}
        </div>
      </div>
    )
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {messageType === 'sms' ? (
        <p>sms</p>
      ) : messageType === 'email' ? (
        <CreateCustomMessage
          customMessage={customMessage}
          retentionMessaging={retentionMessaging}
          handleCancel={closeMessageForm}
        />
      ) : (
        getCustomMessagesContent()
      )}
    </>
  )
}

type SentMessageProps = {
  message: Message
}
type MessageRecipientsConnection = {
  nodes: MessageRecipient[]
  pageInfo: PageInfo
}
const SentMessages = ({message}: SentMessageProps) => {
  const MESSAGE_REPORT_TAB_NAME = {
    ALL: "All",
    CLICKED: "Clicked",
    OPENED: "Opened",
  };
  const MESSAGE_REPORT_TABS = [
    {
      key: MESSAGE_REPORT_TAB_NAME.ALL,
      title: MESSAGE_REPORT_TAB_NAME.ALL,
    },
    {
      key: MESSAGE_REPORT_TAB_NAME.CLICKED,
      title: MESSAGE_REPORT_TAB_NAME.CLICKED,
    },
    {
      key: MESSAGE_REPORT_TAB_NAME.OPENED,
      title: MESSAGE_REPORT_TAB_NAME.OPENED,
    },
  ];

  const SENT_MESSAGE_HEADINGS = [
    { label: 'Emails', value: 'email' },
    { label: 'Client Name', value: 'clientName' },
    { label: 'Delivered', value: 'delivered' },
    { label: 'Clicks', value: 'clickCount' },
    { label: 'Opens', value: 'openCount' }
  ];

  const MOBILE_HEADINGS = [
    {
      title: 'Client Name',
      key: 'clientName'
    },
    {
      title: 'Delivered',
      key: 'delivered'
    },
    {
      title: 'Clicks',
      key: 'clickCount'
    },
    {
      title: 'Opens',
      key: 'openCount'
    }
  ]

  const [activeTab, setActiveTab] = useState(MESSAGE_REPORT_TAB_NAME.ALL)
  const [messageRecipientsData, setMessageRecipientsData] = useState<MessageRecipientsConnection>({
    nodes: [],
    pageInfo: null
  })
  const [isEndReached, setIsEndReached] = useState(false);
  const targetRef = useRef(null);
  const token = localStorage.getItem('token')

  const getMessageRecipients = (after?: string) => {
    axios
      .post(
        '/graphql',
        {
          query: print(MessageRecipientsDocument),
          variables: { messageId: message?.id, first: 30, after }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { messageRecipients }
          }
        } = res;
        // update clients, merge with new messageRecipients?.nodes with existing data
        if (!after) {
          setMessageRecipientsData(messageRecipients)
        } else {
          if (Array?.isArray(messageRecipients?.nodes) && messageRecipients?.nodes.length > 0) {
            setMessageRecipientsData({
              // eslint-disable-next-line no-unsafe-optional-chaining
              nodes: [...messageRecipientsData?.nodes, ...messageRecipients?.nodes],
              pageInfo: messageRecipients?.pageInfo
            })
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsEndReached(true);
          } else {
            setIsEndReached(false);
          }
        });
      },
      { threshold: 0.5 } // Trigger when at least 50% of the target is visible
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Cleanup function
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  useEffect(() => {
    getMessageRecipients()
  }, [])

  useEffect(() => {
    if (isEndReached && messageRecipientsData && messageRecipientsData?.pageInfo && messageRecipientsData?.pageInfo?.hasNextPage) {
      getMessageRecipients(messageRecipientsData?.pageInfo?.endCursor);
    }
  }, [isEndReached, messageRecipientsData]);

  const generateSentMessagesTableData = (messages: MessageRecipient[]) => {
    return messages.map((message) => ({
      email: message.client?.email,
      clientName: message?.client?.firstName + " " + message?.client?.lastName,
      delivered: () => {
        const status = message?.deliveryStatus;
        return status ? (
          <Pill variant={
            status ? 'success' : 'light'
          }>Yes</Pill>
        ) : <Pill variant='danger'>No</Pill>
      },
      clickCount: message.clickCount?.toString(),
      openCount: message.openCount?.toString(),
    }))
  }

  const AllSentMessagesTable = () => {
    const tableClientsData = () => {
      if (Array.isArray(messageRecipientsData?.nodes) && messageRecipientsData?.nodes?.length) {
        return generateSentMessagesTableData(
          messageRecipientsData?.nodes
        )
      }
      return []
    }

    return (
      <div>
        <Table headers={SENT_MESSAGE_HEADINGS} mobileHeaders={MOBILE_HEADINGS} rows={tableClientsData()} />
      </div>
    )
  }

  const ClickedMessagesTable = () => {
    const tableClientsData = () => {
      if (Array.isArray(messageRecipientsData?.nodes) && messageRecipientsData?.nodes?.length) {
        return generateSentMessagesTableData(
          messageRecipientsData?.nodes?.filter(x => x.clickCount > 1)
        )
      }
      return []
    }
    return (
      <div>
        <Table headers={SENT_MESSAGE_HEADINGS} mobileHeaders={MOBILE_HEADINGS} rows={tableClientsData()} />
      </div>
    )
  }

  const OpenedMessagesTable = () => {
    const tableClientsData = () => {
      if (Array.isArray(messageRecipientsData?.nodes) && messageRecipientsData?.nodes?.length) {
        return generateSentMessagesTableData(
          messageRecipientsData?.nodes?.filter(x => x.openCount > 0)
        )
      }
      return []
    }
    return (
      <div>
        <Table headers={SENT_MESSAGE_HEADINGS} mobileHeaders={MOBILE_HEADINGS} rows={tableClientsData()} />
      </div>
    )
  }

  const getTabView = () => {
    switch (activeTab) {
      case MESSAGE_REPORT_TAB_NAME.ALL:
        return <AllSentMessagesTable />
      case MESSAGE_REPORT_TAB_NAME.CLICKED:
        return <ClickedMessagesTable />
      default:
        return <OpenedMessagesTable />
    }
  }
  return (
    <>
      <div className=''>
        <div className='border-b border-grey-100'>
          <div className='p-5 flex items-center justify-between'>
            <Paragraph size='b3' className='mt-4' weight='bold'>
              {message.title}
            </Paragraph>
            {/* <Button

              variant='primary'
              size='sm'
              disabled={false}
              loading={false}
              type='button'
              rounded='md'
              fontSize='b5'
              className='invisible'
            >
              Download report
            </Button> */}
          </div>
        </div>

        <div className='mt-1 border-b border-grey-100'>
          <div style={{display: 'flex', justifyContent: 'space-between'}} className='sm:flex sm:justify-between p-5'>
            <div style={{width: '360px', borderColor: 'rgb(243 246 246 / var(--tw-border-opacity))'}} className='flex w-[360px] p-3 items-center border border-[#f3f6f6]'>
              <div className='p-2 rounded-full mr-2 bg-[#f3f6f6]'>
                <SvgSendEmailIcon />
              </div>
              <div>
                <Paragraph className='mb-1'>Delivery rate</Paragraph>
                <Paragraph size='b1' weight='bold'>
                  {message?.insights.deliveryRate?.toFixed(2)}%
                </Paragraph>
              </div>
            </div>

            <div style={{width: '360px', borderColor: 'rgb(243 246 246 / var(--tw-border-opacity))'}} className='flex w-[360px] p-3 items-center border border-[#f3f6f6]'>
              <div style={{backgroundColor: 'rgb(243 246 246 / var(1))'}} className='p-2 rounded-full mr-2 bg-[#f3f6f6]'>
                <SvgClickRateIcon />
              </div>
              <div>
                <Paragraph className='mb-1'>Click rate</Paragraph>
                <Paragraph size='b1' weight='bold'>
                  {message.insights?.clickRate?.toFixed(2)}%
                </Paragraph>
              </div>
            </div>

            <div style={{width: '360px', borderColor: 'rgb(243 246 246 / var(--tw-border-opacity))'}} className='flex w-[360px] p-3 items-center border border-[#f3f6f6]'>
              <div className='p-2 rounded-full mr-2 bg-[#f3f6f6]'>
                <SvgOpenRateIcon />
              </div>
              <div>
                <Paragraph className='mb-1'>Open rate</Paragraph>
                <Paragraph size='b1' weight='bold'>
                  {message.insights?.openRate?.toFixed(2)}%
                </Paragraph>
              </div>
            </div>
          </div>
        </div>

        <div>
          {
            <Tabs
              tabs={MESSAGE_REPORT_TABS}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          }
          <div className=''>{getTabView()}</div>
          <div className='w-full flex py-4 justify-center items-center' ref={targetRef}>
          </div>
        </div>
      </div>
    </>
  )
}

type IOption = {
  label: string
  value: string
}
type CreateMessageProps = {
  customMessage?: Message
  handleCancel: (string) => void
  retentionMessaging: boolean
}

type CreateMessageInputProps = CreateMessageInput;
const CreateCustomMessage = ({
  customMessage,
  handleCancel,
  retentionMessaging
}: CreateMessageProps) => {
  const { clients, createMessage, deleteMessage, getClients } = useMessageContext()
  const { getSalonFieldValue } = useSalonCache()
  const [saving, setSaving] = useState(false)
  const [messageTab, setMessageTab] = useState(CREATE_CUSTOM_MESSAGING_TAB.COMPOSE_MESSAGE)
  const [selectedGroupRecipient, setSelectedGroupRecipient] = useState<string | null>(null)
  const clientsCount = getSalonFieldValue("totalNumberOfClients");
  const { toast, addToast } = useToast()
  const retentionClients = JSON.parse(localStorage.getItem('retentionClients'))
  const [clientsOptions, setClientOptions] = useState<MultiSelectOption[]>([])
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues
  } = useForm<CreateMessageInputProps>({
    defaultValues: {
      emailType: 'campaign',
      sendGroup: 'groups'
    }
  })
  const [status, setStatus] = useState('sent')

  const {
    isVisible: isDeleteMessageDialogModalVisible,
    openModal: openDeleteMessageDialogModal,
    closeModal: closeDeleteMessageDialogModal
  } = useModal()

  const {
    isVisible: isTestMessageDialogModalVisible,
    openModal: openTestMessageDialogModal,
    closeModal: closeTestMessageDialogModal
  } = useModal()

  useEffect(() => {
    if (customMessage) {
      setValue('title', customMessage.title)
      setValue('headerImageUrl', customMessage?.headerImageUrl || '')
      setImageUrl(customMessage.headerImageUrl)
      setValue('body', customMessage.body)
      setValue(
        'recipients',
        // @ts-expect-error no check required here
        customMessage?.messageRecipients?.map((x) => ({
          value: x.client.id,
          label: `${x.client?.firstName + " " + x.client?.lastName} - ${x.client?.phone}`
        })) || []
      )
      setBody(customMessage.body || '')
      setValue('emailType', customMessage.emailType || '')
      setValue('sendGroup', customMessage.sendGroup || "groups")
    }
  }, [customMessage, setValue])

  useEffect(() => {
    if (retentionMessaging) {
      setValue('sendGroup', "individual");
      const filteredCustomers = Array.isArray(retentionClients) && retentionClients.length 
        ? retentionClients
            .map(client => clientsOptions.find(option => option?.label?.includes(client?.phoneNumber)))
            .filter(Boolean)
        : [];
      setValue("recipients", filteredCustomers as [])
    }
  }, [retentionMessaging, clientsOptions]);

  const resetForm = () => {
    setValue("recipients", []);
    setValue("sendGroup", "groups")
    setValue("title", "")
    setValue("body", "")
    handleCancel(null)
    localStorage.setItem("retentionClients", JSON.stringify([]))
  }

  const onSubmit = async (input: CreateMessageInputProps) => {
    if (messageTab === CREATE_CUSTOM_MESSAGING_TAB.COMPOSE_MESSAGE && status === "sent") {
      setMessageTab(CREATE_CUSTOM_MESSAGING_TAB.RECIPIENT_INFORMATION)
      return;
    }

    // flag if fields are empty
    if (status === "sent" && !body || !input?.title) {
      addToast({
        message: "Email body or subject is required",
        variant: "error"
      })
      return;
    } else if (status === "sent" && input?.sendGroup === SEND_MESSAGE_EMAIL_TYPES[1]?.value && !selectedGroupRecipient) {
      addToast({
        message: 'Kindly select recipient',
        variant: 'error'
      })
      return;
    } else if (status === "sent" && input?.sendGroup === SEND_MESSAGE_EMAIL_TYPES[0]?.value && !input?.recipients?.length) {
      addToast({
        message: 'Kindly select recipient',
        variant: 'error'
      })
      return;
    }
    
    setSaving(true)
    const payload = {
      ...input,
      id: customMessage ? customMessage.id : null,
      body,
      status,
      headerImageUrl: imageUrl,
      recipients: input?.recipients ? (
        JSON.parse(JSON.stringify(input?.recipients)) as IOption[]
      ).map((x) => x.value!) : [],
      mailCategory: input?.sendGroup === 'groups' ? selectedGroupRecipient : null,
      salonId: getSalonFieldValue('id')
    }
    createMessage(payload, resetForm)
    setSaving(false)
  }

  const user = JSON.parse(localStorage.getItem('userData')) as User
  const [imageUrl, setImageUrl] = useState('')
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [body, setBody] = useState('')

  const formatClientsToSelectField = (clients: Client[]): MultiSelectOption[] => {
    return Array?.isArray(clients) && clients?.length && clients?.map((client) => ({
      label: `${client?.firstName + " " + client?.lastName} - ${client?.phone}`,
      value: client?.id
    }))
  }

  useEffect(() => {
    getClients()
  }, [])

  useEffect(() => {
    if (clients && clients?.nodes) {
      const options = formatClientsToSelectField(clients?.nodes) || [];
      setClientOptions([...clientsOptions, ...options]);

      if (clients?.pageInfo?.hasNextPage) {
        getClients(clients?.pageInfo?.endCursor)
      }
    }
  }, [clients])

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsFileUploading(true)
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      const { url } = await useFileUploader_(base64DataUri)
      setImageUrl(url)
      setIsFileUploading(false)
    }

    reader.readAsDataURL(file)
  }

  const handleSetBody = (msg: string) => {
    setBody(msg)
  }

  const initiateCloseDeleteProductDialogModal = (deleteMessage: boolean) => {
    if (deleteMessage) {
      closeDeleteMessageDialogModal()
      handleCancel('')
    } else {
      closeDeleteMessageDialogModal()
    }
  }

  const initiateCloseTestMessageModal = (email?: string) => {
    if (email) {
      closeTestMessageDialogModal()
      handleCancel('')
    } else {
      closeTestMessageDialogModal()
    }
  }

  const ADD_MESSAGE_EMAIL_TYPES = [
    {
      label: 'One-off',
      value: 'one_off'
    },
    {
      label: 'Campaign',
      value: 'campaign'
    }
  ]

  const SEND_MESSAGE_EMAIL_TYPES = [
    {
      label: 'Individual',
      value: 'individual'
    },
    {
      label: 'Group',
      value: 'groups'
    }
  ]

  const onChangeMessagingGroup = (option) => {
    setSelectedGroupRecipient(option.key);
  }

  const cleanHtml = DOMPurify.sanitize(body);

  const sendTestMessage = () => {
    // flag if fields are empty
    if (!body || !watch("title")) {
      addToast({
        message: "All field are required",
        variant: "error"
      })
      return;
    }
    openTestMessageDialogModal()
  }

  const getMessageValues = () => {
    return {
      id: customMessage?.id,
      title: watch('title'),
      body,
      emailType: watch('emailType'),
      recipients: watch('recipients'),
      mailCategory: customMessage?.sendGroup === 'groups' ? selectedGroupRecipient : null,
      salonId: getSalonFieldValue('id'),
      sendGroup: watch('sendGroup'),
      headerImageUrl: imageUrl
    } as CreateMessageInputProps;
  }

  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <div className='p-4 xl:p-12 w-full flex flex-col-reverse xl:flex-row gap-12'>
        <div className='w-full max-w-[450px] space-y-6'>
          <Heading size='h8' weight='bold' variant='h1'>
            New Email message
          </Heading>
          <Paragraph size='b5' color={COLORS.GREY[400]}>
            Create a new mail message to send to your customers.
          </Paragraph>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className='w-full space-y-6'
            autoComplete='off'
          >
            <div className={`w-full overflow-x-auto flex space-x-2`}>
              {CREATE_CUSTOM_MESSAGING_TABS.map((tab, index) => {
                const tabActiveClassName = tab?.key === messageTab ? 'border-none bg-grey-300 text-white cursor-text rounded-full' : 'bg-whit cursor-pointer';
                return (
                  <button
                    className={`w-fit xl:w-auto text-b5 3xl:text-b4 px-3 py-1.5 antialiased font-normal whitespace-normal ${tabActiveClassName}`}
                    type="button"
                    onClick={() => {
                      setMessageTab(tab?.key)
                    }}
                    key={index}
                    disabled={
                      tab?.key === CREATE_CUSTOM_MESSAGING_TAB?.RECIPIENT_INFORMATION && body === "" && watch("title") === "" ? true : false
                    }
                  >
                    {tab?.title}
                  </button>
                )
              })}
            </div>
            {messageTab === CREATE_CUSTOM_MESSAGING_TAB.COMPOSE_MESSAGE ? (
              <div className='flex flex-col space-y-6 mb-4'>
                <Input
                  name='title'
                  id='title'
                  label='Subject Line'
                  type='text'
                  placeholder='Enter title'
                  control={control}
                  rules={{
                    required: ERRORS.MESSAGING_TITLE_REQUIRED
                  }}
                  error={errors.title}
                />
                <div className='w-full flex border rounded-sm border-grey-20 border-dashed h-[110px] justify-center items-center'>
                  <Controller
                    control={control}
                    name='headerImageUrl'
                    render={({ formState: { errors } }) => {
                      return (
                        <div className='flex flex-col'>
                          <div className='flex items-center space-x-4'>
                            <div className='relative cursor-pointer'>
                              {getImageUploadContent({
                                imageUrl,
                                uploadIsLoading: isFileUploading
                              })}
                              <input
                                className='cursor-pointer absolute block opacity-0 top-0 w-[128px] h-[96px]'
                                type='file'
                                accept={IMAGE_UPLOAD_PATTERN}
                                onChange={handleFileUpload}
                              />
                            </div>
                          </div>
                          {errors?.headerImageUrl?.message && (
                            <FormHelperText variant='error'>
                              {errors.headerImageUrl.message}
                            </FormHelperText>
                          )}
                        </div>
                      )
                    }}
                  />
                </div>

                <div className='flex flex-col space-y-2'>
                  <FormLabel htmlFor="body">
                    Body
                  </FormLabel>
                  <TextEditor handleSetBody={handleSetBody} defaultValue={body} />
                </div>
                <div className='bg-white w-full h-[50px]'></div>
              </div>
            ) : (
              <>
                <div>
                  <Controller
                    control={control}
                    name='emailType'
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div className='w-full flex flex-col space-y-4'>
                          <FormLabel htmlFor="body">
                            Email type
                          </FormLabel>
                          <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                            {Array.isArray(ADD_MESSAGE_EMAIL_TYPES) &&
                            ADD_MESSAGE_EMAIL_TYPES.length
                              ? ADD_MESSAGE_EMAIL_TYPES.map(
                                  (messageType: { label: string; value: string }) => {
                                    return (
                                      <div
                                        className='flex'
                                        onClick={() => onChange(messageType?.value)}
                                        key={messageType?.value}
                                      >
                                        <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                          <Checkbox
                                            isChecked={value === messageType?.value}
                                          />
                                          <span className='text-grey-900 whitespace-nowrap'>
                                            {messageType?.label}
                                          </span>
                                        </div>
                                      </div>
                                    )
                                  }
                                )
                              : null}
                          </div>
                        </div>
                      )
                    }}
                    rules={{
                      required: 'Select email type you'
                    }}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name='sendGroup'
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div className='w-full flex flex-col space-y-4'>
                          <FormLabel htmlFor="body">
                            Send to
                          </FormLabel>
                          <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                            {Array.isArray(SEND_MESSAGE_EMAIL_TYPES) &&
                            SEND_MESSAGE_EMAIL_TYPES.length
                              ? SEND_MESSAGE_EMAIL_TYPES.map(
                                  (messageType: { label: string; value: string }) => {
                                    return (
                                      <div
                                        className='flex'
                                        onClick={() => onChange(messageType?.value)}
                                        key={messageType?.value}
                                      >
                                        <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                          <Checkbox
                                            isChecked={value === messageType?.value}
                                          />
                                          <span className='text-grey-900 whitespace-nowrap'>
                                            {messageType?.label}
                                          </span>
                                        </div>
                                      </div>
                                    )
                                  }
                                )
                              : null}
                          </div>
                        </div>
                      )
                    }}
                  />
                </div>
                {watch('sendGroup') === SEND_MESSAGE_EMAIL_TYPES[0]?.value ? (
                  <div
                    className='flex flex-col space-y-2'
                  >
                    <Controller
                      control={control}
                      name='recipients'
                      render={({
                        field: { onChange, value },
                        formState: { errors }
                      }) => {
                        const errorMessage: string = errors?.recipients?.message
                        return (
                          <>
                            <FormLabel htmlFor='service'>Select Recipients</FormLabel>
                            <MultiSelect
                              selected={(value as unknown as MultiSelectOption[]) || []}
                              options={clientsOptions}
                              setSelected={onChange}
                            />
                            {errorMessage && (
                              <FormHelperText variant='error'>
                                {errorMessage}
                              </FormHelperText>
                            )}
                          </>
                        )
                      }}
                      rules={{
                        required: watch('sendGroup') === SEND_MESSAGE_EMAIL_TYPES[0]?.value ? "Add a recipent" : false,
                      }}
                    />
                  </div>
                ) : (
                  <div className='w-full flex flex-col space-y-2'>
                    <FormLabel htmlFor="body">
                      Send to
                    </FormLabel>
                    <div className='w-full flex justify-between items-center space-y-2 p-3 bg-white border border-grey-75 rounded-lg'>
                      <Listbox value={selectedGroupRecipient} onChange={onChangeMessagingGroup}>
                        <div className="relative mt-1 w-full">
                          <div className="flex flex-col w-full">
                            <Listbox.Button
                              className="relative w-full cursor-pointer bg-transparent flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-b6"
                            >
                              <div className='w-full flex space-x-2'>
                                <span className='text-grey-900 text-b6'>{formatTextIntoCaps(selectedGroupRecipient) || "Select a mailing list"}</span>
                                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center'>
                                  <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                                </span>
                              </div>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options
                                id='mail-list-options'
                                className="absolute max-h-60 w-full min-w-[100px] overflow-auto rounded-sm bg-white py-2 text-b4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-b6 z-[90]"
                                style={{
                                  top: 50,
                                  bottom: 'auto',
                                }}
                              >
                                {Array?.isArray(CUSTOM_MESSAGE_GROUP_RECIPIENT) && CUSTOM_MESSAGE_GROUP_RECIPIENT.map((view, viewIdx) => (
                                  <Listbox.Option
                                    key={viewIdx}
                                    className={({ active }) =>
                                      `relative cursor-pointer select-none p-2 ${active ? 'bg-grey-900 text-white' : 'text-grey-900'
                                      }`
                                    }
                                    value={view}
                                  >
                                    {(item) => (
                                      <div className='w-full flex space-x-2'>
                                        <div className={`min-w-[20px] h-[20px] border border-grey-300 rounded-full flex justify-center items-center`}>
                                          {view?.key === selectedGroupRecipient ? (
                                            <div className='min-w-[16px] h-[16px] bg-grey-300 rounded-full'></div>
                                          ) : null}
                                        </div>
                                        <div className='flex flex-col'>
                                          <span
                                            className='text-b5 block truncate font-medium'
                                          >
                                            {view?.title}
                                          </span>
                                          <span className='text-b6 block font-normal text-grey-400'>
                                            {view?.description && clientsCount ? view?.description.replace('102', clientsCount) : view?.description?.replace('(102)', '')}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </div>
                      </Listbox>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className='flex flex-col xl:flex-row mt-4 gap-2 mb-6'>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-white px-4 py-2 text-b5 font-medium text-grey-900 border border-grey-100 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                    <span className='hidden xl:inline-block'>More options</span>
                    <SvgSelectDropDown width="10px" height="10px" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]">
                    <div className="flex flex-col p-4 space-y-4">
                      <Menu.Item>
                        <span className="text-b4 cursor-pointer" onClick={sendTestMessage}>Send test email</span>
                      </Menu.Item>
                      {customMessage ? (
                        <Menu.Item>
                          <span className="text-b4 text-red-500 cursor-pointer" onClick={openDeleteMessageDialogModal}>Delete message</span>
                        </Menu.Item>
                      ) : null}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <Button
                variant='secondary'
                className='text-red-600'
                fontWeight='bold'
                size='sm'
                type='button'
                rounded='md'
                onClick={handleCancel}
              >
                Cancel
              </Button>
              {!customMessage && (
                <Button
                  variant='secondary'
                  className=''
                  fontWeight='bold'
                  size='sm'
                  rounded='md'
                  disabled={saving}
                  loading={saving}
                  onClick={() => setStatus('draft')}
                >
                  Save as draft
                </Button>
              )}
              <Button
                variant='primary'
                className=''
                fontWeight='bold'
                size='sm'
                rounded='md'
                disabled={saving}
                loading={saving}
              >
                {messageTab === CREATE_CUSTOM_MESSAGING_TAB.COMPOSE_MESSAGE ? 'Proceed' : 'Send message'}
              </Button>
            </div>
          </form>
        </div>
        <div className='hidden xl:block w-full h-fit xl:w-[600px] p-6 bg-grey-100 space-y-6'>
          <div className='flex flex-col space-y-6 py-4 px-6 bg-white'>
            {user.business?.logoUrl ? (
              <img
                src={user.business?.logoUrl}
                alt='business logo'
                className='w-full max-w-[150px]'
                loading='lazy'
              />
            ) : null}

            <div className='flex h-full w-full max-w-[400px] max-h-[200px]'>
              <img
                src={imageUrl || 'https://placehold.co/1000x500'}
                alt='business header image'
                className='w-full object-cover rounded-3xl'
                loading='lazy'
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />
          </div>

          <Paragraph size='b5' className='text-center' color={COLORS.GREY[400]}>
            Splice inc © 2023
          </Paragraph>
        </div>
      </div>
      <DeleteCustomMessageDialog
        customMessage={customMessage}
        isVisible={isDeleteMessageDialogModalVisible}
        closeModal={initiateCloseDeleteProductDialogModal}
      />
      <TestCustomMessageDialog
        message={getMessageValues()}
        isVisible={isTestMessageDialogModalVisible}
        closeModal={initiateCloseTestMessageModal}
      />
    </>
  )
}

const TextEditor = (props) => {
  const [message, setMessage] = useState('')
  const modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { align: [] }
      ],
      [
        {
          color: [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            'custom-color'
          ]
        }
      ]
    ]
  }

  const formats = [
    'header',
    'height',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'color',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'size'
  ]

  const handleProcedureContentChange = (content) => {
    props.handleSetBody(content)
    setMessage(content)
  }

  useEffect(() => {
    setMessage(props.defaultValue)
  }, [props.defaultValue])

  return (
    <div>
      <div
        style={{ display: 'grid', justifyContent: 'center', maxWidth: '450px' }}
      >
        <ReactQuill
          id='message-body'
          theme='snow'
          modules={modules}
          formats={formats}
          placeholder='write your content ....'
          onChange={handleProcedureContentChange}
          style={{ height: '220px', border: 'none' }}
          value={message}
        ></ReactQuill>
      </div>
    </div>
  )
}

type DeleteCustomMessageDialogProps = {
  customMessage: Message
  isVisible: boolean
  closeModal: (deleteMessage?: boolean) => void
}

type SendTestInput = {
  email: string
}

type TestCustomMessageDialogProps = {
  message: CreateMessageInputProps
  isVisible: boolean
  closeModal: (email?: string) => void
}

const DeleteCustomMessageDialog = ({
  customMessage,
  isVisible,
  closeModal,
}: DeleteCustomMessageDialogProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { deleteMessage } = useMessageContext()

  const handleDelete = () => {
    setIsLoading(true)
    deleteMessage(customMessage.id)
    setIsLoading(false)
    closeModal(true)
  }

  const cancelCustomMessageDeletion = () => {
    closeModal()
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Message
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete this message? This means that
                this message will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => cancelCustomMessageDeletion()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}


const TestCustomMessageDialog = ({
  message,
  isVisible,
  closeModal,
}: TestCustomMessageDialogProps) => {
  const { createMessage } = useMessageContext()
  const [saving, setSaving] = useState(false)
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid }
  } = useForm<SendTestInput>({
    delayError: 100,
    mode: 'onChange'
  })

  const cancel = () => {
    closeModal()
  }

  const closeTestMessageModal = () => {
    closeModal('email sent successfully')
  }

  const onSubmit = async (input: SendTestInput) => {
    setSaving(true)
    const payload = {
      ...message,
      testEmail: input?.email,
      body: message?.body,
      headerImageUrl: message?.headerImageUrl,
      recipients: message?.recipients ? (
        JSON.parse(JSON.stringify(message?.recipients)) as IOption[]
      ).map((x) => x.value!) : [],
      mailCategory: message?.mailCategory || "all_clients",
      salonId: message?.salonId,
      status: "test"
    }
    createMessage(payload, closeTestMessageModal)
    setSaving(false)
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Send test email
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Enter an email address for the test email
              </Paragraph>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className='w-full flex  flex-col space-y-6'
                autoComplete='off'
              >
                <Input
                  name='email'
                  label='Email'
                  id='email'
                  type='email'
                  placeholder='email@acme.com'
                  control={control}
                  rules={{
                    required: ERRORS.EMAIL_REQUIRED,
                    pattern: REGEX_PATTERNS.EMAIL
                  }}
                  error={errors.email}
                />
                <Button
                  variant='primary'
                  size='lg'
                  rounded='lg'
                  fontSize='b5'
                  className='capitalize'
                  disabled={saving}
                  loading={saving}
                >
                  Send
                </Button>
                <Button
                  variant='text'
                  size='none'
                  rounded='none'
                  fontSize='b5'
                  className='mx-0'
                  onClick={() => cancel()}
                  disabled={saving}
                  type='button'
                >
                  Cancel
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

interface SVGRProps {
  title?: string
  titleId?: string
}

const SvgSendEmailIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g id='streamline:send-email' clipPath='url(#clip0_8457_1804)'>
      <path
        id='Vector'
        d='M22.4676 1.35421L6.10991 14.9862M9.96362 18.8571L13.6973 22.5736C13.9266 22.8087 14.2133 22.9797 14.529 23.0699C14.8447 23.1601 15.1785 23.1663 15.4973 23.0879C15.8179 23.0132 16.1139 22.8573 16.3568 22.6351C16.5997 22.413 16.7813 22.132 16.8842 21.8194L23.0145 3.44221C23.1427 3.09802 23.1694 2.72422 23.0912 2.36532C23.0131 2.00642 22.8335 1.67752 22.5738 1.4178C22.314 1.15807 21.9851 0.978446 21.6262 0.900311C21.2673 0.822176 20.8935 0.84882 20.5493 0.977071L2.17219 7.10907C1.84875 7.21929 1.56062 7.41391 1.3376 7.6728C1.11457 7.93169 0.964752 8.24545 0.903621 8.58164C0.840481 8.88762 0.854426 9.2045 0.944201 9.50375C1.03398 9.80301 1.19676 10.0752 1.41791 10.2959L6.10991 14.9879L5.95562 20.9296L9.96362 18.8588V18.8571Z'
        stroke='#667368'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8457_1804'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const SvgClickRateIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
        { title ? <title id={titleId}>{title}</title> : null }
    <g id="mynaui:click">
      <path
        id="Vector"
        d="M7.55967 7.27467L5.59967 5.29733M18.0117 7.27467L19.9717 5.29733M5.59834 19.804L7.55834 17.8253M12.785 4.63867V2M4.94501 12.5507H2.33301M20.833 20.448L25.9183 18.44C26.041 18.3909 26.1462 18.3062 26.2203 18.1967C26.2944 18.0873 26.334 17.9582 26.334 17.826C26.334 17.6938 26.2944 17.5647 26.2203 17.4553C26.1462 17.3458 26.041 17.2611 25.9183 17.212L13.6983 12.392C13.5794 12.346 13.4496 12.3356 13.3248 12.3622C13.2001 12.3888 13.0858 12.4512 12.9959 12.5417C12.9061 12.6323 12.8446 12.7471 12.8189 12.872C12.7933 12.997 12.8047 13.1267 12.8517 13.2453L17.6277 25.58C17.8437 26.14 18.6277 26.14 18.8437 25.58L20.833 20.448Z"
        stroke="#667368"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const SvgOpenRateIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g id="akar-icons:eye-open">
      <g id="Group">
        <path
          id="Vector"
          d="M21.9235 10.962C22.3975 11.582 22.3975 12.419 21.9235 13.038C20.4305 14.987 16.8485 19 12.6665 19C8.48452 19 4.90251 14.987 3.40951 13.038C3.1789 12.7411 3.05371 12.3759 3.05371 12C3.05371 11.6241 3.1789 11.2589 3.40951 10.962C4.90251 9.013 8.48452 5 12.6665 5C16.8485 5 20.4305 9.013 21.9235 10.962Z"
          stroke="#667368"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M12.667 15C14.3238 15 15.667 13.6569 15.667 12C15.667 10.3431 14.3238 9 12.667 9C11.0101 9 9.66699 10.3431 9.66699 12C9.66699 13.6569 11.0101 15 12.667 15Z"
          stroke="#667368"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default Messaging
