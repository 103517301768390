import Papa from 'papaparse';
import React, { useState } from 'react'
import { BatchUploadProps } from './types';
import axios from 'axios';
// import { useUserCache } from '../hooks/useUserCache';
// import { useSalonCache } from '../hooks/useSalonCache';
import { API_ERRORS } from '../constants/errors';
import Button from '../ui/atoms/button/Button';
import { CSV_UPLOAD_PATTERN } from '../constants/pattern';
import { useSalonCache } from '../hooks/useSalonCache';
import { formatClientsData } from './utils';
import { UploadClientDocument } from '../graphql/generated';
import { print } from 'graphql'

const ImportClients = (props: BatchUploadProps) => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const [isLoading, setIsLoading] = useState(false)

  const handleCsvUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setIsLoading(true)
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: async function (results) {
          if (Array.isArray(results.data) && results.data.length > 0) {
            try {
              const clients = formatClientsData(results.data);

              await axios.post(
                '/graphql',
                {
                  query: print(UploadClientDocument),
                  variables: { input: { clientData: clients, salonId } },
                },
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
              ).then(({ data }) => {
                setIsLoading(false);
                if (data?.data?.uploadClient?.status === 200) {
                  props?.onNextPage && props.onNextPage();
                  props?.addToast && props.addToast({
                    variant: "success",
                    message: "Upload successful",
                  })
                  props?.refetch && props.refetch();
                }

                if (data?.errors?.length) {
                  const message = data?.errors[0]?.message || API_ERRORS.BUSINESS_CLIENTS_CREATE_FAILED;
                  props?.addToast({
                    variant: 'error',
                    message,
                  })
                }


                if (data?.data?.uploadClient?.errors?.length) {
                  const message = data?.data?.uploadClient?.errors[0]?.message || API_ERRORS.BUSINESS_CLIENTS_CREATE_FAILED;
                  props?.addToast({
                    variant: 'error',
                    message,
                  })
                }
              })
            } catch (uploadClientsError) {
              setIsLoading(false)
              if (axios.isAxiosError(uploadClientsError)) {
                const message = uploadClientsError?.response?.data?.message || API_ERRORS.BUSINESS_CLIENTS_CREATE_FAILED;
                props?.addToast && props.addToast({
                  variant: "error",
                  message,
                });
              }
            }
          }
        }
      });
    }
  }

  return (
    <Button
      variant='success'
      className='relative'
      // disabled={uploadSalonClients.isLoading}
      size={props.size || 'sm'}
      rounded={props.rounded || 'md'}
      fontSize='b5'
      type='button'
    >
      {!isLoading ?
        (
          <>
            Import Clients
            <input
              className="cursor-pointer absolute block opacity-0 top-0 w-[128px] h-full"
              type="file"
              accept={CSV_UPLOAD_PATTERN}
              onChange={handleCsvUpload}
            />
          </>
        ) : (
          <div className="flex justify-center gap-x-2 cursor-pointer">
            {[1, 2, 3].map((item, index) => (
              <div key={index} className="h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse" />
            ))}
          </div>
        )}
    </Button>
  )
}

export default ImportClients