import React from 'react'

const LoginClientPlus = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#16796F" fillOpacity="0.06"/>
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#16796F" strokeOpacity="0.1"/>
      <path d="M20.1719 11.0625C20.6462 11.0625 21.0312 11.4475 21.0312 11.9219V19.3125H28.4219C28.6498 19.3125 28.8684 19.403 29.0295 19.5642C29.1907 19.7254 29.2812 19.944 29.2812 20.1719C29.2812 20.3998 29.1907 20.6184 29.0295 20.7795C28.8684 20.9407 28.6498 21.0312 28.4219 21.0312H21.0312V28.4219C21.0312 28.6498 20.9407 28.8684 20.7795 29.0295C20.6184 29.1907 20.3998 29.2812 20.1719 29.2812C19.944 29.2812 19.7254 29.1907 19.5642 29.0295C19.403 28.8684 19.3125 28.6498 19.3125 28.4219V21.0312H11.9219C11.694 21.0312 11.4754 20.9407 11.3142 20.7795C11.153 20.6184 11.0625 20.3998 11.0625 20.1719C11.0625 19.944 11.153 19.7254 11.3142 19.5642C11.4754 19.403 11.694 19.3125 11.9219 19.3125H19.3125V11.9219C19.3125 11.4475 19.6975 11.0625 20.1719 11.0625Z" fill="#16796F"/>
    </svg>
  )
}

export default LoginClientPlus
