import * as React from "react";
import { PillProps } from "./types";
const PrimaryPill = React.lazy(() => import("./Primary"));
import { PILL_VARIANT_TO_COMPONENT_MAPPING } from "./constants";

const Pill = ({ variant, ...props }: PillProps) => {
  const PillComponent = PILL_VARIANT_TO_COMPONENT_MAPPING[variant]
    ? PILL_VARIANT_TO_COMPONENT_MAPPING[variant]
    : PrimaryPill;

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <PillComponent {...props} />
    </React.Suspense>
  );
};

export default Pill;
