import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { API_ERRORS, ERRORS } from '../../../../constants/errors';
import { useToast } from '../../../../hooks/useToast';
import { REGEX_PATTERNS } from '../../../../constants/pattern';
import Input from '../../../../ui/molecules/input/Input';
import ToastWrapper from '../../../../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../../../../ui/atoms/toast/types';
import AccountSetupTemplate from '../../AccountSetupTemplate';
import Button from '../../../../ui/atoms/button/Button';
import { BvnInput, BvnPageProps } from '../../types';
import { PAYMENT_FORM_STEPS } from '../../constants';
import { usePaymentSettings } from '../../../../hooks/usePaymentSettings';
import { print } from 'graphql';
import { RequestBvnVerificationDocument } from '../../../../graphql/generated';
import { COLORS } from '../../../../constants/colors';
import { useUserCache } from '../../../../hooks/useUserCache';
import Paragraph from '../../../../ui/atoms/paragraph/Paragraph';

const BvnFormComponent = ({ ...props }: BvnPageProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<BvnInput>({});
  const { addToast, toast } = useToast();
  const [loading, setLoading] = React.useState(false);
  const { getBusinessData } = useUserCache();
  const _business = getBusinessData();

  const { addBvnRecipient, getBvnRecipient } = usePaymentSettings();
  const bvnRecipient = getBvnRecipient();

  const onSubmitData = async (input: BvnInput) => {
    setLoading(true)
    axios.post(
      '/graphql',
      {
        query: print(RequestBvnVerificationDocument),
        variables: { input },
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    ).then(({ data }) => {
      if (data?.data?.requestBvnVerification?.status === 200) {
        props?.setStep && props.setStep(PAYMENT_FORM_STEPS.BVN_VERIFICATION);
        addBvnRecipient('phone', input.bvn);
      }

      if (data?.errors?.length) {
        const message = data?.errors[0]?.message || API_ERRORS.BUSINESS_SERVICES_DELETE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }


      if (data?.data?.requestBvnVerification?.errors?.length) {
        const message = data?.data?.requestBvnVerification?.errors[0]?.message || API_ERRORS.BUSINESS_SERVICES_DELETE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
      setLoading(false);
    }).catch(errorUpdateBvn => {
      setLoading(false)
      const message = errorUpdateBvn?.response?.data?.message || API_ERRORS.BUSINESS_BVN_FAILED;
      addToast({
        variant: "error",
        message,
      });
    })
  };

  useEffect(() => {
    if (bvnRecipient) {
      setValue('bvn', bvnRecipient)
    }
  }, [bvnRecipient])

  return (
    <AccountSetupTemplate
      title='Secure your account by entering your BVN.'
      subtitle="Your BVN is used to verify your identify and enable to you to receive online payments and deposits. These details will be used only to verify your account information and won’t be shared with your clients or any third parties"
      skip={{
        text: 'Skip Business Setup',
      }}
    >
      <ToastWrapper toast={toast as ToastProps} />
      <form onSubmit={handleSubmit(onSubmitData)} className="w-full space-y-6 pt-6">
        <Input name="bvn" id="bvn" label="Bank Verification Number (BVN)" type="text" placeholder='Enter BVN here' control={control} rules={{
          required: ERRORS.BVN_REQUIRED,
          pattern: REGEX_PATTERNS.BVN_NUMBER
        }} error={errors.bvn} />
        {_business?.bvn && _business?.isBvnVerified ? (
          <div className='w-[70%] text-center mx-auto my-6'>
            <Paragraph size='b5' color={COLORS.GREY[300]} >Please contact customer support at <a className='text-green-600 font-semibold' href="mailto:info@withsplice.com">info@withsplice.com</a> for bvn related issue(s) </Paragraph>
          </div>
        ): null}
        <div className='w-full flex space-x-2'>
          <Button
            variant='secondary'
            className=''
            disabled={false}
            onClick={props.onPrevPage}
            size='lg'
            rounded='lg'
            type='button'
          >
            Prev
          </Button>
          {_business?.bvn && _business?.isBvnVerified ? (
            <Button
              variant='primary'
              className=''
              disabled={loading}
              loading={loading}
              size='lg'
              rounded='lg'
              type='button'
              onClick={props.onNextPage}
            >
              Next
            </Button>
          ) : (
            <Button
              variant='primary'
              className=''
              disabled={loading}
              loading={loading}
              size='lg'
              rounded='lg'
            >
              Proceed
            </Button>
          )}
        </div>
        {/* <Button
          variant='text'
          className='mx-auto'
          disabled={false}
          loading={false}
          size='none'
          fontSize='b5'
          fontWeight='bold'
          type='button'
          onClick={props.onNextPage}
        >
          Skip Bvn
        </Button> */}
      </form>
    </AccountSetupTemplate>
  )
}

export default BvnFormComponent