import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLinkedin = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={16} fill="#16796F" fillOpacity={0.1} />
    <path
      d="M12.205 10.75a1.5 1.5 0 1 1-3-.002 1.5 1.5 0 0 1 3 .002Zm.045 2.61h-3v9.39h3v-9.39Zm4.74 0h-2.985v9.39h2.955v-4.927c0-2.745 3.578-3 3.578 0v4.927H23.5v-5.948c0-4.627-5.295-4.454-6.54-2.182l.03-1.26Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgLinkedin;
