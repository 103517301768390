import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTimeLine = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#time-line_svg__a)">
      <path
        d="M8 1.334a6.667 6.667 0 1 1 0 13.333A6.667 6.667 0 0 1 8 1.334Zm0 1.333a5.333 5.333 0 1 0 0 10.667A5.333 5.333 0 0 0 8 2.667Zm0 1.334a.667.667 0 0 1 .662.588l.005.078v3.058l1.804 1.804a.667.667 0 0 1-.88.998l-.062-.055-2-2a.667.667 0 0 1-.19-.384l-.006-.087V4.667A.667.667 0 0 1 8 4.001Z"
        fill="#667368"
      />
    </g>
    <defs>
      <clipPath id="time-line_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTimeLine;
