export const SALES_TAB_NAME = {
  DAILY_SALES: 'Sales',
  APPOINTMENTS: 'Appointments',
  SALES_REPORT: 'Sales Report',
  PAYMENT_TRANSACTIONS: 'Payment Transactions'
} as const;

export const APPOINTMENT_HEADINGS = [
  {
    label: "Date scheduled",
    value: "date",
    show: true,
  },
  {
    label: "Client",
    value: "client",
    show: true,
  },
  {
    label: "Item",
    value: "item",
    show: true,
  },
  {
    label: "Location",
    value: "location",
    show: true,
  },
  {
    label: "Staff",
    value: "staff",
    show: true,
  },
  {
    label: "Status",
    value: "status",
    show: true,
  },
  {
    label: "Price",
    value: "price",
    show: true,
  },
];

export const MOBILE_APPOINTMENT_SALES_HEADINGS = [
  {
    title: "Client",
    key: "client",
  },
  {
    title: "Status",
    key: "status",
  }
]

export const SALES_REPORT_HEADINGS = [
  {
    label: "Client",
    value: "client",
    show: true,
  },
  {
    label: "Item",
    value: "item",
    show: true,
  },
  {
    label: "Qty Sold",
    value: "quantity",
    show: true,
  },
  {
    label: "Discounts",
    value: "discounts",
    show: true,
  },
  {
    label: "Net Sales",
    value: "net",
    show: true,
  },
  {
    label: "Tax Sales",
    value: "tax",
    show: true,
  },
  {
    label: "Total Sales",
    value: "total",
    show: true,
  },
  {
    label: "Balance",
    value: "balance",
    show: true,
  },
  {
    label: "Status",
    value: "status",
    show: true,
  },
];

export const MOBILE_SALES_REPORT_HEADINGS = [
  {
    title: "Item",
    key: "item",
  },
  {
    title: "Total sales",
    key: "total",
  }
]

export const PAYMENT_TRANSACTIONS_HEADINGS = [
  {
    label: "Date of Payment",
    value: "date",
    show: true,
  },
  {
    label: "Method",
    value: "method",
    show: true,
  },
  {
    label: "Client",
    value: "client",
    show: true,
  },
  {
    label: "Staff",
    value: "staff",
    show: true,
  },
  {
    label: "Price",
    value: "price",
    show: true,
  },
];

export const MOBILE_PAYMENT_TRANSACTIONS_HEADINGS = [
  {
    title: "Client",
    key: "client",
  },
  {
    title: "Price",
    key: "price",
  }
]

export const TRANSACTION_SUMMARY_HEADINGS = [
  {
    label: "Item Type",
    value: "item",
    show: true,
  },
  {
    label: "Sales Quantity",
    value: "quantity",
    show: true,
  },
  {
    label: "Gross Total",
    value: "gross",
    show: true,
  },
];

export const MOBILE_TRANSACTION_SUMMARY_HEADINGS = [
  {
    title: "Item",
    key: "item",
  },
  {
    title: "Gross Total",
    key: "gross",
  }
]

export const PAYMENT_COLLECTED_HEADINGS = [
  {
    label: "Payment Type",
    value: "payment",
    show: true,
  },
  {
    label: "Amount",
    value: "amount",
    show: true,
  },
  {
    label: "Quantity",
    value: "quantity",
    show: true,
  },
];

export const MOBILE_PAYMENT_COLLECTED_HEADINGS = [
  {
    title: "Payment Type",
    key: "payment",
  },
  {
    title: "Total",
    key: "total",
  }
]