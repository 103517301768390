import React from 'react'
import { Button, Heading, Paragraph } from '../ui'
import { COLORS } from '../constants/colors'

const BlockPage = () => {
  return (
    <div className="absolute top-0 left-0 z-[99999] flex items-center justify-center w-full h-screen bg-white/70 backdrop-blur-[5px]">
      <div className="w-full flex flex-col items-center space-y-6 mx-auto max-w-[300px] xl:max-w-[400px]">
        <Heading
          variant='h3'
          size='h9'
          color={COLORS.GREY[900]}
          weight='bold'
          className='w-[60%]'
        >
          Permission denied
        </Heading>
        <Paragraph size="b6" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
          You do not have the permission to view this page
        </Paragraph>
        <div className="w-full flex space-x-4">
          {/* <Button
            variant="secondary"
            size='lg'
            rounded='lg'
            fontSize='b5'
            fontWeight="bold"
            className=''
          >
            Previous page
          </Button> */}
          <Button
            variant="primary"
            size='lg'
            rounded='lg'
            fontSize='b5'
            fontWeight="bold"
            className=''
            onClick={() => window.location.href = '/home'}
          >
            Home
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BlockPage
