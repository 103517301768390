import React from 'react'
import { COLORS } from '../../constants/colors'
import { Button, Heading, Paragraph } from '../../ui'
import { ActionModalProps } from '../appointmentComponents/types'
import { formatInToPrice } from '../../utils/misc'

const ProceedSalePaymentInfo = ({
  isVisible,
  closeModal,
  amountClientPaid
}: ActionModalProps) => {
  const amount = amountClientPaid ? Number(amountClientPaid?.toString()?.replaceAll(",", "")) : 0;
  return (
    isVisible ? (
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
        <div className="table-row">
          <div className="table-cell align-middle">
            <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
              <div className="flex flex-col items-center space-y-6">
                <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Save Sale</Heading>
                <Paragraph size="b5" weight='semiBold' className='w-full text-center max-w-[400px] py-2' color={COLORS.GREY[400]}>
                  {amountClientPaid ? `You're about to pay the remaining balance of ${formatInToPrice(amount)}.` : 'Full payment has not been recorded for this sale. You can save it as part-paid, and balance it up later in the future.'}
                </Paragraph>
                
                <Button
                  variant="primary"
                  size='lg'
                  rounded='lg'
                  fontSize='b5'
                  className='capitalize'
                  onClick={() => closeModal('proceed')}
                >
                  Proceed
                </Button>
                <Button
                  variant="text"
                  size='none'
                  rounded='none'
                  fontSize='b5'
                  className='mx-0'
                  onClick={() => closeModal('back')}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null
  )
}

export default ProceedSalePaymentInfo