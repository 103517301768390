import * as React from 'react'
import { useController } from 'react-hook-form';
import { SelectInputProps } from './types';
import { getHelperTextForReactHookFormErrors } from '../../../utils/form';
import { FormHelperTextList, FormLabel, HelperTextProps } from '../..';
import { SvgSelectDropDown } from '../../icons';

const SelectWithChangeHandlerInput: React.FC<SelectInputProps> = ({ name, label, control, className, placeholder, options, rules, error, id, ...rest }) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
    rules,
  });

  const helperText = getHelperTextForReactHookFormErrors(error?.message || "");

  const helperTexts: HelperTextProps[] = React.useMemo(() => {
    if (Array.isArray(helperText)) {
      return helperText;
    }

    if (helperText) {
      return [helperText];
    }

    return [];
  }, [helperText]);

  return (
    <div className='w-full relative flex flex-col space-y-2'>
      {label && (
        <FormLabel htmlFor={id}>{label}</FormLabel>
      )}
      <div className='relative'>
        <select
          className={`w-full text-grey-900 flex invalid:text-grey-400 items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder-grey-400 ${className || ""
            }`}
          {...{
            ref, control, rules, id, ...rest, ...inputProps
          }}
          disabled={rest?.disabled || false}
          onChange={rest?.onChange}
          value={rest?.value}
        >
          {/* --- Select placeholder --- */}
          <option value="" defaultChecked>
            {placeholder ? placeholder : `Select ${label}`}
          </option>

          {/* --- Select options --- */}
          {options?.map((option, i) => (
            <option value={option.value} key={i}>
              {option.label}
            </option>
          ))}
        </select>
        <SvgSelectDropDown width="10px" height="10px" className="absolute right-4 top-1/2 transform -translate-y-1/2" />
      </div>
      {error && (
        <FormHelperTextList helperTexts={helperTexts} />
      )}
    </div>
  );
}

export default SelectWithChangeHandlerInput
