import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHideEye = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 85 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m16.1 53.3 2.9-2.9c-5.8-3.7-10.8-8.8-14.4-15 7.8-13.6 22.2-21.9 37.9-21.9 4 0 8 .6 11.7 1.6l3.2-3.2c-4.7-1.5-9.7-2.4-14.9-2.4C24 9.5 8 20 0 35.4c3.8 7.2 9.3 13.4 16.1 17.9ZM68.9 17.4 66 20.3c5.9 3.7 10.9 8.8 14.5 15-7.9 13.6-22.3 21.9-38 21.9-4.1 0-8-.6-11.8-1.6l-3.2 3.2c4.7 1.6 9.8 2.4 15 2.4C61 61.2 77 50.7 85 35.3c-3.8-7.2-9.3-13.4-16.1-17.9ZM34.7 46l18.5-18.5 2.8-2.9 6.4-6.3 2.9-3L77.8 2.9 74.9 0 61.5 13.4l-3 3.1-5.3 5.3-2.9 2.8-18.5 18.5L29 46l-6.5 6.4-2.9 3L7.2 67.7l2.9 2.9 13.3-13.4 3-3 5.4-5.4 2.9-2.8Z"
      fill="currentColor"
    />
    <path
      d="M42.5 22.1c1.4 0 2.8.2 4.1.7l3.1-3.1c-2.2-1-4.6-1.6-7.2-1.6-9.5 0-17.2 7.7-17.2 17.2 0 2.6.6 5 1.6 7.2l3.1-3.1c-.4-1.3-.7-2.7-.7-4.1 0-7.3 5.9-13.2 13.2-13.2ZM42.5 48.5c-1.4 0-2.8-.2-4.1-.7l-3.1 3.1c2.2 1 4.6 1.6 7.2 1.6 9.5 0 17.2-7.7 17.2-17.2 0-2.6-.6-5-1.6-7.2L55 31.2c.4 1.3.7 2.7.7 4.1 0 7.3-5.9 13.2-13.2 13.2Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgHideEye;
