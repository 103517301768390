import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGiftBox = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 72 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect
      y={0.5}
      width={72}
      height={72}
      rx={36}
      fill="#667368"
      fillOpacity={0.05}
    />
    <path
      d="M49.75 27.75h-5.481c.06-.051.123-.101.183-.156A4.614 4.614 0 0 0 46 24.245 5.098 5.098 0 0 0 40.756 19a4.615 4.615 0 0 0-3.35 1.549A8.585 8.585 0 0 0 36 22.81a8.585 8.585 0 0 0-1.406-2.262A4.615 4.615 0 0 0 31.244 19 5.098 5.098 0 0 0 26 24.245a4.614 4.614 0 0 0 1.548 3.349c.06.051.122.101.183.156H22.25a2.5 2.5 0 0 0-2.5 2.5v5a2.5 2.5 0 0 0 2.5 2.5v10a2.5 2.5 0 0 0 2.5 2.5h22.5a2.5 2.5 0 0 0 2.5-2.5v-10a2.5 2.5 0 0 0 2.5-2.5v-5a2.5 2.5 0 0 0-2.5-2.5Zm-10.469-5.545a2.14 2.14 0 0 1 1.563-.703h.076a2.597 2.597 0 0 1 2.58 2.667 2.14 2.14 0 0 1-.703 1.563c-1.483 1.312-3.944 1.774-5.469 1.937.188-1.655.703-4.06 1.953-5.464Zm-10.014.056a2.598 2.598 0 0 1 1.825-.76h.077a2.14 2.14 0 0 1 1.562.702c1.311 1.482 1.774 3.938 1.936 5.457-1.519-.157-3.975-.625-5.456-1.936a2.14 2.14 0 0 1-.703-1.563 2.595 2.595 0 0 1 .753-1.9h.006Zm-7.017 7.99h12.5v5h-12.5v-5Zm2.5 7.5h10v10h-10v-10Zm22.5 10h-10v-10h10v10Zm2.5-12.5h-12.5v-5h12.5v5Z"
      fill="#667368"
    />
  </svg>
);
export default SvgGiftBox;
