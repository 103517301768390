import * as React from 'react';
import { Logo } from '../ui/atoms/logo';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import { COLORS } from '../constants/colors';
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../ui/atoms/toast/types';
import { useToast } from '../hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import {
  RegisterDocument,
  RegisterInput,
  CountriesDocument,
  RoleEnum,
  InvitationCodeDetailsDocument,
  WaitlistEntry,
} from '../graphql/generated';
import Input from '../ui/molecules/input/Input';
import { ERRORS } from '../constants/errors';
import { REGEX_PATTERNS } from '../constants/pattern';
import PasswordInput from '../ui/molecules/input/PasswordInput';
import {
  BOOKING_MANAGEMENT,
  ROLES,
  SPECIALTIES,
  TEAM_SIZE,
  YES_OR_NO_SETTINGS,
  BUSINESS_CATEGORIES,
} from '../constants/information';
import FormLabel from '../ui/atoms/formLabel/Label';
import { FormHelperText } from '../ui/atoms/helperText/FormHelperText';
import MultiSelect from '../ui/molecules/multiselect/multiselect';
import SelectWithChangeHandlerInput from '../ui/molecules/input/SelectWithChangeHandlerInput';
import FormPhone from '../ui/molecules/input/FormPhone';
import { sanitizePhoneNumber, validatePhoneNumber } from '../utils/misc';
import { getHelperTextForReactHookFormErrors } from '../utils/form';
import Checkbox from '../ui/atoms/checkbox/Checkbox';
import { useModal } from '../hooks';
import Button from '../ui/atoms/button/Button';
import { Modal } from '../ui/templates/modal/Modal';
import { TermsAndConditionModalProps } from '../ui/templates/modal/types';
import { IOption } from '../utils/types';
import { print } from 'graphql';
import axios from 'axios';
import { MultiSelectOption } from '../ui';
import { jwtDecode } from 'jwt-decode';
import { PAGE_ROUTES } from '../constants/routes';
import FullStory from 'react-fullstory';
import { Turnstile } from '@marsidev/react-turnstile';

export const TermAndConditionsModal = (props: TermsAndConditionModalProps) => {
  return (
    <Modal
      title="Terms of Service – WithSplice Limited"
      subTitle=""
      show={props.isVisible}
      size="md"
      closeModal={props.closeModal}
    >
      <div className="flex flex-col space-y-2 my-4">
        <Paragraph size="b5">
          Please read these Terms of Service (“Terms”), which set forth the
          legally binding terms and conditions between you and WithSplice
          Limited (&apos;the Company&apos; or &apos;WithSplice&apos; or
          &apos;we&apos;, &apos;our&apos; or &apos;us&apos;). It governs your
          access to and the use of WithSplice&apos;s website, mobile application
          and the services provided by WithSplice
        </Paragraph>
        <Paragraph size="b5">
          Our collection and use of your personal information in connection with
          your use of our services are described in our{' '}
          <a href="http://www.withsplice.com/privacy-policy">Privacy Policy</a>
          .&nbsp;
        </Paragraph>
        <Paragraph size="b5">
          Your access to use our services is conditioned on your acceptance of
          and compliance with these Terms. These Terms apply to all visitors,
          business owners, and any other person who accesses or uses our
          services.
        </Paragraph>
        <Paragraph size="b5">
          Our website is not directed at you if we are prohibited by any law of
          any jurisdiction from making the information on our website available
          to you and is not intended for any use that would be contrary to your
          local law or regulation.
        </Paragraph>
        <Paragraph size="b5">
          By accessing or using our services, you agree to be bound by these
          Terms. If you disagree with any part of the terms then, you may not
          access our services.
        </Paragraph>
        <ol className="list-disc" role="list">
          <li>
            <strong>Definitions</strong>
          </li>
        </ol>
        <Paragraph size="b5">
          In these Terms,&nbsp;<strong>“Account”</strong>
        </Paragraph>
        <Paragraph size="b5">
          means a unique account registered with the details &nbsp;of the User
          and which contains details of the User&apos;s transactions/operations
          on the Platform (as defined below).
          <br />
        </Paragraph>
        <Paragraph size="b5">
          <strong>“Applicable Laws”</strong>
          <br />
        </Paragraph>
        <Paragraph size="b5">
          means all applicable regulations, laws, codes, guidelines, policies,
          rules, and directives of any competent regulatory authority in the
          Federal Republic of Nigeria.
          <br />
        </Paragraph>
        <Paragraph size="b5">
          <strong>“Platform”</strong>
          <br />
        </Paragraph>
        <Paragraph size="b5">
          means collectively WithSplice&apos;s website and other related
          applications provided by WithSplice.
        </Paragraph>
        <Paragraph size="b5">
          <strong>“Service(s)”</strong>
        </Paragraph>
        <Paragraph size="b5">
          means all products and services provided to you by WithSplice and as
          described in clause 3 of these Terms.
          <br />
        </Paragraph>
        <Paragraph size="b5">
          <strong>“User”</strong>
        </Paragraph>
        <Paragraph size="b5">
          means persons, customers, businesses, and visitors who access the
          Service
        </Paragraph>
        <ol role="list">
          <li>
            <strong>Acceptance of Terms</strong>
          </li>
        </ol>
        <Paragraph size="b5">
          The Service is offered subject to acceptance of all the terms and
          conditions contained in these Terms and all other operating rules,
          policies, and procedures that may be published on the Platform, which
          are incorporated by reference, including operating rules, policies,
          and procedures of third-party service providers to the Platform that
          are referenced herein. These Terms apply to every user of the Service.
          In addition, some Services offered through the Platform may be subject
          to additional terms and conditions adopted by the Platform. Your use
          of those Services is subject to those additional terms and conditions,
          which are incorporated into these Terms by this reference.
        </Paragraph>
        <Paragraph size="b5">
          WithSplice reserves the right, at its sole discretion, to modify or
          replace these Terms from time to time by posting the updated terms on
          the Platform. It is your responsibility to check the Terms
          periodically for changes. If you object to any such changes, your sole
          recourse will be to cease using the Platform and the Service. Your
          continued use of the Service following the posting of any changes to
          the Terms will indicate your acknowledgement of such changes and
          agreement to be bound by the terms and conditions of such changes.
        </Paragraph>
        <Paragraph size="b5">
          WithSplice reserves the right to change, suspend, or discontinue the
          Service (including, but not limited to, the availability of any
          feature, database, or content) at any time for any reason. WithSplice
          may also impose limits on certain features and Services or restrict
          your access to parts of or the entire Platform without notice or
          liability.
        </Paragraph>
        <ol role="list">
          <li>
            <strong>Scope of WithSplice&apos;s Services&nbsp;</strong>
          </li>
        </ol>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            WithSplice is a company which connects customers to businesses which
            offer a range of beauty and wellness products and services.
          </li>
          <li>
            WithSplice provides [www.withsplice.com] and the WithSplice software
            application which allow customers discover, book sessions, and
            purchase products from beauty and wellness businesses including
            salons, spas, cosmetologists, beauticians and other
            businesses.&nbsp;
          </li>
          <li>
            WithSplice&apos;s services include the management of business
            inventory and customer appointments. By using our Services, you
            enter into a contract with WithSplice under these Terms of Service.
          </li>
          <li>
            WithSplice serves&nbsp; as an intermediary between customers and the
            businesses they book appointments or order products from as our
            Services are limited to facilitating the booking of appointments and
            placement of orders, as may be required by the terms of the
            contracts between the customers and businesses.
          </li>
          <li>
            When you book an appointment or place an order, you agree to a
            contract between you and the vendor/business. WithSplice shall not,
            in the absence of an express written agreement to the contrary, be a
            party to contracts between customers and businesses. Each customer
            and business shall be at liberty to review the underlying terms and
            conditions prior to execution and performance.&nbsp;
          </li>
          <li>
            All our Services can be accessed through our website and the
            WithSplice mobile application.
          </li>
          <li>
            WithSplice provides business and customer Accounts for Users. To
            access our Services, Users are required to sign up on the Platform
            and create an Account by providing their email addresses and setting
            up a password. Each User will be also required to select an
            Account(business or individual) tailored to their needs and the
            Services we provide. Users will subsequently be required to provide
            the necessary information for the creation and their use of the
            Account.
          </li>
          <li>
            A User is granted access to use the Platform and has a
            responsibility to ensure that no third party shall gain access to
            the User&apos;s Account information.
          </li>
          <li>
            WithSplice may make access to the Platform and the Services, or
            certain areas or features of the Platform, subject to certain
            conditions or requirements, such as meeting specific eligibility
            criteria .
          </li>
          <li>
            You must be at least 18 years old and able to enter into legally
            binding contracts to access and use our Services. By accessing or
            using the Platform, you represent and warrant that you are 18 or
            older and have the legal capacity and authority to enter into a
            contract.
          </li>
          <li>
            WithSplice does not assume any responsibility for the confirmation
            of any User&apos;s identity or ownership of a business.
            Notwithstanding the above, for transparency and as permitted by
            Applicable Laws, we may ask Users to provide a form of government
            identification or other information or undertake additional checks
            designed to help verify the identities or backgrounds of the Users
            and their ownership of the businesses.&nbsp;
          </li>
          <li>
            You agree to strictly observe the security and authentication
            procedures of the Platform and you will log out from the Platform at
            the end of every visit.
          </li>
          <li>
            When you create an account with us, you must provide us with
            information that is accurate, complete, and current at all times.
            Failure to do so constitutes a breach of these Terms, which may
            result in immediate termination of your account on our Platform and
            the Service.
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Information</strong>
          </li>
        </ol>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            You agree to grant WithSplice an irrevocable right to collect and
            use any information you provide Us, or we collect from You, for the
            specific purpose of providing you with our services.
          </li>
          <li>
            You hereby agree and authorise us to verify the information provided
            by you to us against your information including but not limited to
            your phone number, date of birth, Identification (I.D) card, or
            Passport Number, business registration details and contact
            information, and any other<strong> </strong>information that will
            enable us to identify your or your business and comply with the
            regulatory “Know Your Customer” (KYC) requirements in your country.
          </li>
          <li>
            WithSplice reserves the right to request for further information
            from you pertaining to you or the creation of your Account at any
            time. Failure to provide such information within the time required
            by WithSplice may result in WithSplice declining to accept your
            request or a delay in the creation of your Account.
          </li>
          <li>
            You consent that we may disclose and or transfer your information to
            third parties or any other entity we deem necessary to perform our
            obligations to you under this Agreement.
          </li>
          <li>
            All other provisions regarding the use of your personal information
            are contained in our{' '}
            <a href="http://www.withsplice.com/privacy-policy">
              Privacy Policy
            </a>
            .
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Submissions&nbsp;</strong>
          </li>
        </ol>
        <Paragraph size="b5">
          If you submit to us or post through our Services any business
          information, testimonial, comment, review, suggestion, or any work of
          authorship (collectively a “submission”), including without
          limitation, submissions about any of our products or services, you
          agree that such submissions may be used by us in any manner. Please do
          not make or send any submission to us that you consider containing
          confidential or proprietary information. No submission sent to us
          shall be treated or considered as confidential information.&nbsp;
        </Paragraph>
        <Paragraph size="b5">
          By submitting or sending a submission to us, you:&nbsp;
        </Paragraph>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            represent and warrant to us that the submission is original to you,
            that no other party has any rights thereto, and that any moral
            rights in such submission have been waived; and&nbsp;
          </li>
          <li>
            that you grant to us a royalty-free, unrestricted, worldwide,
            perpetual, irrevocable, non-exclusive and fully transferable,
            assignable and sublicensable right and license to use, reproduce,
            publish, distribute, display, translate, summarise, modify and adapt
            such submission (in whole or in part) and or/to incorporate it in
            other works in any form, media, or technology now known or later
            developed, in our sole discretion, with or without your name.&nbsp;
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Users&apos; Representations &amp; Warranties</strong>
          </li>
        </ol>
        <Paragraph size="b5">
          Representations and warranties are statements and promises made by you
          to WithSplice, which we rely on as being accurate in our dealings with
          you. You make the following representations and warranties to us at
          the time of agreeing to these Terms and every time you use our
          Services;
        </Paragraph>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>you are over the age of 18 (eighteen) years</li>
          <li>
            you are of sound mind and have the capacity to enter into a legally
            binding contract;
          </li>
          <li>
            all personal information that you provide about yourself is accurate
            and true to the best of your knowledge;
          </li>
          <li>
            all information that you provide about your business is accurate and
            true;
          </li>
          <li>
            all information that you provide about your properties is accurate
            and true;
          </li>
          <li>
            you shall not create more than one account or create an account for
            anyone other than yourself (with the exception of accounts created
            for corporate organisations); and
          </li>
          <li>
            you are not breaching any Applicable Laws or regulations that are
            applicable to you or any company, or legal entity.&nbsp;&nbsp;
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Payment</strong>
          </li>
        </ol>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            For business Users, the Service shall be provided via a flexible,
            affordable and automated monthly subscription payment arrangement
            for which each business shall be required to pay the stipulated fees
            based on your subscription.
          </li>
          <li>
            For other Users, WithSplice may process payments for appointments
            and orders made with businesses onboarded on the Platform. For these
            payments, WithSplice shall use the payment methods you provide, and
            payments shall be remitted to business in the applicable
            currency.&nbsp;
          </li>
          <li>
            All Users shall be required to provide some financial information
            for our payment service providers. Each User warrants to provide
            complete and accurate payment information.
          </li>
          <li>
            Payments to WithSplice shall be made through any of the stipulated
            payment gateways provided on the Platform.
          </li>
          <li>
            Payments processed by WithSplice shall include any applicable taxes
            and additional fees including currency conversion costs which shall
            be borne by the customers.&nbsp;
          </li>
          <li>
            WithSplice shall notify you of the applicable price for the Service
            and reserves the right to adjust its pricing where necessary.
          </li>
          <li>
            By providing your credit card other payment information accepted by
            our payment service provider, you are expressly agreeing that we are
            authorised to charge the relevant fees for your use of the Service,
            together with any applicable taxes.
          </li>
          <li>
            WithSplice utilises a third-party payment processor to link your
            payment card to the platform. Payment processing and other services
            related to your use of the platform are subject to the privacy
            policies, terms, and conditions of the payment processor and your
            credit card issuer, in addition to these Terms of Service.&nbsp;
          </li>
          <li>
            WithSplice strives to ensure the payment services are always
            available, but cannot guarantee uninterrupted access or guarantee
            the absence of errors or viruses. WithSplice shall not be
            responsible for errors or liabilities resulting from errors made by
            the payment processor or third parties.&nbsp;
          </li>
          <li>
            Businesses may have a Cancellation Policy, which will be disclosed
            and confirmed upon booking an appointment. If the business has this
            policy in place, WithSplice may charge your card up to the full
            appointment cost as determined by the business. If you cancel
            outside of the time frame specified in the business's policy, you
            shall not be&nbsp; eligible for a refund unless agreed upon directly
            with the business,
          </li>
          <li>
            If you disagree with any payment transaction, you can submit such
            complaint to{' '}
            <a href="mailto:info@withsplice.com">info@withsplice.com</a>&nbsp;
            within 30 (thirty) days of the payment transaction (“Dispute
            Period”).&nbsp;
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Intellectual Property&nbsp;</strong>
          </li>
        </ol>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            The Service and all rights in our product name, trade names, logos,
            service marks, slogans, product packaging, its original content,
            features and functionality are and will remain the exclusive
            property of WithSplice. Our trademarks may not be used in connection
            with any product or service without our prior written consent.
          </li>
          <li>
            WithSplice may, at its sole discretion, enable Users to (i) create,
            upload, post, send, receive and store content, such as text, photos,
            audio, video, or other materials and information on or through the
            Platform ("User Content"); and (ii) access and view User Content and
            any content that WithSplice itself makes available on or through the
            Platform, including proprietary content and any content licensed or
            authorised for use by or through WithSplice from a third party
            ("WithSplice&apos;s Content" and together with User Content,
            "Collective Content").
          </li>
          <li>
            You will not use, copy, adapt, modify, prepare derivative works of,
            distribute, licence, sell, transfer, publicly display, publicly
            perform, transmit, broadcast or otherwise exploit the Platform or
            Collective Content, except to the extent that you are the legal
            owner of certain User Content or as expressly permitted in these
            Terms. No licences or rights are granted to you by implication or
            otherwise under any intellectual property rights owned or controlled
            by WithSplice or its licensors, except for the licences and rights
            expressly granted in these Terms.
          </li>
          <li>
            Subject to your compliance with these Terms, WithSplice grants you a
            limited, non-exclusive, non-sublicensable, revocable,
            non-transferable licence to access and view any Collective Content
            made available on or through the Platform and accessible to you,
            solely for your personal and non-commercial use.
          </li>
          <li>
            Users shall not directly or indirectly: (i) decipher, decompile,
            disassemble, reverse engineer, or otherwise attempt to derive any
            source code or underlying ideas or algorithms of any part of the
            Service, except to the extent applicable laws specifically prohibit
            such restriction; (ii) modify, translate, or otherwise create
            derivative works of any part of the Service; or (iii) copy, rent,
            lease, distribute, or otherwise transfer any of the rights that you
            receive hereunder.
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Prohibited Activities</strong>
          </li>
        </ol>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            You shall not use the Platform for any purpose that is prohibited by
            these Terms. You are responsible for all of your activity in
            connection with the Service. Violation of our rules may result in
            the termination and cancellation of your Account. You acknowledge
            and agree that we may terminate any WithSplice Account at any time
            for any reason (including, but not limited to, our independent
            assessment or the receipt of claims or allegations from third
            parties or authorities).
          </li>
          <li>
            Users shall not utilise the Platform or the Services for any illegal
            purpose.&nbsp;
          </li>
          <li>
            You are solely responsible for compliance with any and all laws,
            rules, regulations, and tax obligations that may apply to your use
            of the Services. In connection with your use of the Platform, you
            will not and will not assist or enable others to:
          </li>
        </ol>
        <ul
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            breach or circumvent any applicable laws or regulations, agreements
            with third-parties, third-party rights, or our Terms;
          </li>
          <li>
            use the Platform or any content therein for any commercial or other
            purposes that are not expressly permitted by these Terms or in a
            manner that falsely implies endorsement, partnership or otherwise
            misleads others as to your affiliation with WithSplice;
          </li>
          <li>
            copy, store or otherwise access or use any information, including
            personally identifiable information about any other User, contained
            on the Platform in any way that is inconsistent with
            WithSplice&apos;s Privacy Policy or these Terms or that otherwise
            violates the privacy rights of Users or third parties;
          </li>
          <li>
            use the Platform in connection with the distribution of unsolicited
            commercial messages ("spam");
          </li>
          <li>
            use the Platform with the intention to circumvent any subscription
            fees or for any other reason;
          </li>
          <li>
            request, accept or make any payment for orders outside of the
            Platform. If you do so, you acknowledge and agree that you: (i)
            would be in breach of these Terms; (ii) accept all risks and
            responsibility for such payment, and (iii) hold WithSplice harmless
            from any liability for such payment;
          </li>
          <li>
            discriminate against or harass anyone on the basis of race, tribe,
            origin, religion, gender, physical or mental disability, medical
            condition, marital status, or age, or otherwise engage in any
            abusive or disruptive behaviour;
          </li>
          <li>
            use, display, mirror or frame the Platform or Collective Content, or
            any individual element within the Platform, WithSplice&apos;s name,
            any trademark, logo or other proprietary information belonging to
            WithSplice, or the layout and design of any page or form contained
            on a page in the Platform, without WithSplice&apos;s express written
            consent;
          </li>
          <li>
            dilute, tarnish or otherwise harm WithSplice&apos;s brand in any
            way, including through unauthorised use of Collective Content,
            registering and/or using derivative terms in domain names, trade
            names, trademarks or other source identifiers, or registering and/or
            using domains names, trade names, trademarks or other source
            identifiers that closely imitate or are confusingly similar to
            WithSplice&apos;s domains, trademarks, taglines, promotional
            campaigns or Collective Content;
          </li>
          <li>
            use any robots, spider, crawler, scraper or other automated means or
            processes to access, collect data or other content from or otherwise
            interact with the Platform for any purpose;
          </li>
          <li>
            avoid, bypass, remove, deactivate, impair, descramble, or otherwise
            attempt to circumvent any technological measure implemented by
            WithSplice or any of WithSplice&apos;s providers or any other third
            party to protect the Platform;
          </li>
          <li>
            attempt to decipher, decompile, disassemble or reverse engineer any
            of the software used to provide the Platform;
          </li>
          <li>
            take any action that damages or adversely affects, or could damage
            or adversely affect the performance or proper functioning of the
            Platform;
          </li>
          <li>
            violate or infringe anyone else&apos;s rights or otherwise cause
            harm to anyone.
          </li>
        </ul>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            You acknowledge that WithSplice has no obligation to monitor the
            access to or use of the Platform by any User or to review, disable
            access to, or edit any User Content, but has the right to do so to
            (i) operate, secure and improve the Platform (including without
            limitation for fraud prevention, risk assessment, investigation and
            customer support purposes); (ii) ensure Users&apos; compliance with
            these Terms; (iii) comply with applicable law or the order or
            requirement of a court, law enforcement or other administrative
            agency or governmental body; (iv) respond to User Content that it
            determines is harmful or objectionable; or (v) as otherwise set
            forth in these Terms. Users agree to cooperate with and assist
            WithSplice in good faith, and to provide WithSplice with such
            information and take such actions as may be reasonably requested by
            WithSplice with respect to any investigation undertaken by
            WithSplice or a representative of WithSplice regarding the use or
            abuse of the Platform.
            <br />
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Your Responsibilities</strong>
          </li>
        </ol>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            You are responsible for checking, confirming and satisfying yourself
            as to the accuracy of any information provided on our Platform.
          </li>
          <li>
            You are responsible for ensuring that you act in good faith towards
            other parties.&nbsp;
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Links to Other Websites</strong>
          </li>
        </ol>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            The Platform may contain links to third-party websites or services
            that are not owned or controlled by WithSplice.
          </li>
          <li>
            WithSplice has no control over and assumes no responsibility for the
            content, privacy policies, or practices of any third-party websites
            or services. You further acknowledge and agree that WithSplice shall
            not be responsible or liable, directly or indirectly, for any damage
            or loss caused or alleged to be caused by or in connection with use
            of or reliance on any such content, goods or services available on
            or through any such websites or services. We strongly advise you to
            read the terms and conditions and privacy policies of any
            third-party websites or services that you visit.
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Termination</strong>
          </li>
        </ol>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            We may terminate or suspend our Service to you immediately, without
            prior notice or liability, for any reason whatsoever, including
            without limitation if you breach these Terms.
          </li>
          <li>
            Upon termination, your right to use the Platform and our Service
            will immediately cease. If you wish to terminate your Account, you
            may simply send an email to{' '}
            <a href="http://info@withsplice.com">info@withsplice.com</a>.
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Indemnity</strong>
          </li>
        </ol>
        <Paragraph size="b5">
          You agree to indemnify and hold harmless WithSplice, its affiliates
          and subsidiaries, its officers, directors, employees and agents,
          against all liabilities, costs, expenses, damages and losses
          (including any direct, indirect or consequential losses, loss of
          profit, loss of reputation and all interest penalties and legal and
          other reasonable professional costs and expenses) suffered or incurred
          as a result of:&nbsp;
        </Paragraph>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            your fraudulent or illegal use of the Services or the Platform;
          </li>
          <li>your negligence or any default by you of any of these Terms;</li>
          <li>
            any inaccurate or incomplete information that you have knowingly
            provided to us;
          </li>
          <li>
            you allowing any other person to access your Account either with
            your permission or as a result of your failure to keep your username
            and password private;
          </li>
          <li>
            any claim made against you for actual or alleged infringement of
            WithSplice&apos;s Intellectual Property or any actual or alleged
            infringement of a third party&apos;s Intellectual Property arising
            out of or in connection with our Services or your use of the
            Platform.
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Limitation of Liability</strong>
          </li>
        </ol>
        <Paragraph size="b5">
          In no event shall WithSplice, its directors, employees, partners,
          agents, suppliers, or affiliates, be liable for any indirect,
          incidental, special, consequential or punitive damages, including
          without limitation, loss of profits, data, use, goodwill, or other
          intangible losses, resulting from:&nbsp;&nbsp;
        </Paragraph>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            your use of the Platform or the Services or your inability to use
            the Platform or the Services;
          </li>
          <li>
            any conduct or content of any third party on the Platform;&nbsp;
          </li>
          <li>
            any unauthorised access, use or alteration of your transmissions or
            content, whether based on warranty, contract, tort (including
            negligence) or any other legal theory, whether or not we have been
            informed of the possibility of such damage, and even if a remedy set
            forth herein is found to have failed of its essential purpose;
          </li>
          <li>any legal proceedings between you and any third parties.</li>
        </ol>
        <ol role="list">
          <li>
            <strong>Disclaimer</strong>
          </li>
        </ol>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            Your use of the Service is at your sole risk. The Platform is
            provided on an “AS IS” and “AS AVAILABLE” basis. The Platform is
            provided without warranties of any kind, whether express or implied,
            including, but not limited to, implied warranties of
            merchantability, fitness for a particular purpose, non-infringement
            or course of performance.
          </li>
          <li>
            WithSplice, its subsidiaries, affiliates, and its licensors do not
            warrant that a) the Platform will function uninterrupted, secure or
            available at any particular time or location; b) any errors or
            defects will be corrected; c) the Platform is free of viruses or
            other harmful components; or d) the results of using the Platform
            will meet your requirements.
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Governing Law</strong>
          </li>
        </ol>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            These Terms shall be governed and construed in accordance with the
            laws of the Federal Republic of Nigeria, without regard to its
            conflict of law provisions.&nbsp;
          </li>
          <li>
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service, and supersede and replace any prior agreements we might
            have between us regarding the Service.
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Dispute Resolution</strong>
          </li>
        </ol>
        <ol
          className="list-decimal text-grey-800 flex flex-col space-y-2"
          role="list"
        >
          <li>
            Any disputes arising under or in connection with the validity,
            interpretation and performance of this Terms between you and
            WithSplice or between WithSplice and any third party that cannot be
            resolved amicably by the parties through negotiation within 30
            (thirty) days shall be resolved by Arbitration at the Lagos Court of
            Arbitration (LCA) before a single arbitrator in accordance with the
            Arbitration and Conciliation Act, Cap A18, Laws of the Federation of
            Nigeria.
          </li>
          <li>
            The Parties shall endeavour in good faith to mutually agree on the
            selection of an arbitrator. If the Parties cannot mutually agree on
            the selection of an arbitrator within ten (10) days of the request,
            they shall apply to the LCA to appoint an arbitrator. Arbitration
            proceedings shall be conducted in Lagos. The arbitrator will be
            requested to render an award within ninety (90) days and to provide,
            in writing, the reasoning for the award. The decision of any such
            arbitrator shall be final and binding on the parties.&nbsp;
          </li>
          <li>
            Each party shall bear its cost in connection with the Arbitration
            and the arbitrator&apos;s fees shall be split equally between both
            parties.&nbsp;
          </li>
        </ol>
        <ol role="list">
          <li>
            <strong>Force Majeure</strong>
          </li>
        </ol>
        <Paragraph size="b5">
          WithSplice shall not be liable for failure to perform, or for delay in
          performing its obligations hereunder if such failure or delay shall be
          due to natural disasters, war, riot, civil commotion, weather,
          pandemics, epidemics, labour disputes, failure of sub-contractors or
          any other cause beyond its reasonable control and whether or not of a
          similar nature to the foregoing.
        </Paragraph>
        <ol role="list">
          <li>
            <strong>Feedback</strong>
          </li>
        </ol>
        <Paragraph size="b5">
          We welcome and encourage you to provide feedback, comments and
          suggestions for improvements to WithSplice&apos;s Platform or
          Services. You may submit your feedback by emailing us at
          <a href="mailto:info@withsplice.com">info@withsplicee.com</a>. Any
          feedback you submit to us will be considered non-confidential and
          non-proprietary to you. By submitting your feedback to us, you grant
          us a non-exclusive, worldwide, royalty-free, irrevocable,
          sub-licensable, perpetual licence to use and publish those ideas and
          materials for any purpose, without compensation to you.
        </Paragraph>
        <ol role="list">
          <li>
            <strong>Changes to Terms &amp; Conditions</strong>
          </li>
        </ol>
        <Paragraph size="b5">
          WithSplice reserves the right, in its sole discretion, to change the
          Terms of Service. WithSplice encourages you to periodically review the
          Terms to stay informed of our updates.
        </Paragraph>
        <ol role="list">
          <li>
            <strong>Contact Us</strong>
          </li>
        </ol>
        <Paragraph size="b5">
          If you have any questions about these Terms, please contact us at{' '}
          <a href="mailto:info@withsplice.com">info@withsplice.com</a>.
        </Paragraph>
        <Paragraph size="b5">
          This document was last updated in May 2023.
        </Paragraph>
      </div>
      <Button
        variant="primary"
        type="button"
        onClick={props.closeModal}
        rounded="lg"
      >
        Accept & Close
      </Button>
    </Modal>
  );
};

const Register = () => {
  const [countries, setCountries] = React.useState([]);
  const [countryCode, setCountryCode] = React.useState('NG');
  const [callingCode, setCallingCode] = React.useState('234');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isHuman, setIsHuman] = React.useState(false);
  const [teamSize, setTeamSize] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [bookingManagement, setBookingManagement] = React.useState(null);
  const [businessCategory, setBusinessCategory] = React.useState(null);
  const [teamSizeDisabled, setTeamSizeDisabled] = React.useState(false);
  const [roleDisabled, setRoleDisabled] = React.useState(false);
  const [showRcNumberInput, setShowRcNumberInput] = React.useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const invitationCode = urlParams.get("invitation_code");
  const { addToast, toast } = useToast();
  const { openModal, closeModal, isVisible } = useModal();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<RegisterInput>({
    defaultValues: {
      invitationCode: invitationCode || "",
    },
    delayError: 100,
    mode: 'onChange',
  });

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue('businessData.phone', _phoneNumber);

    // Validate the phone number using `awesome-phonenumber`
    const pn = validatePhoneNumber(_phoneNumber, countryCode);

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('businessData.phone', {
        type: 'manual',
        message: 'Invalid phone number',
      });
    } else {
      // Clear the error if the phone number is valid
      clearErrors('businessData.phone');
    }
  };

  const handleCountryChange = (selectedOption) => {
    const countryCode = selectedOption.target.value;
    if (countryCode === 'NG') {
      setShowRcNumberInput(true);
    } else {
      setShowRcNumberInput(false);
    }

    const callingCode = countries.find(
      (country) => country.code === countryCode
    )?.countryCode;
    setCountryCode(countryCode);
    setValue('businessData.countryCode', countryCode);
    setCallingCode(callingCode);
  };

  const handleBusinessCategoryChange = (selectedOption) => {
    const businessCategory = selectedOption.target.value;
    setValue('businessData.category', businessCategory);
    if (businessCategory === 'independent_professional') {
      setTeamSize('just_me');
      setValue('businessData.teamSize', 'just_me');
      setRole('owner');
      setValue('role', RoleEnum.Owner);
      setTeamSizeDisabled(true);
      setRoleDisabled(true);
    } else {
      setTeamSizeDisabled(false);
      setRoleDisabled(false);
    }
  };

  const handleTeamSizeChange = (selectedOption) => {
    const teamSize = selectedOption.target.value;
    setTeamSize(teamSize);
    setValue('businessData.teamSize', teamSize);
  };

  const handleRoleChange = (selectedOption) => {
    const role = selectedOption.target.value;
    setValue('role', role);
  };

  const handleBookingManagementChange = (selectedOption) => {
    const bookingManagement = selectedOption.target.value;
    setValue('businessData.bookingManagement', bookingManagement);
    setBookingManagement(bookingManagement);
  };

  React.useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      // decode token using jwt-decode
      const decodedToken = token ? jwtDecode(token) : null;
      if (decodedToken && decodedToken.exp * 1000 > Date.now()) {
        window.location.href = PAGE_ROUTES.HOME;
      }
    }
  }, []);

  React.useEffect(() => {
    axios
      .post('/graphql', {
        query: print(CountriesDocument),
      })
      .then((response) => {
        const countries = response.data.data.countries;
        setCountries(countries);
        setValue('businessData.countryCode', countryCode);
      });
  }, []);

  React.useEffect(() => {
    if (invitationCode) {
      setValue('invitationCode', invitationCode);
      axios.post(
        '/graphql',
        {
          query: print(InvitationCodeDetailsDocument),
          variables: {
            invitationCode
          },
        },
      ).then(({ data }) => {
        const userData: WaitlistEntry = data.data.invitationCodeDetails;
        setValue('userData.email', userData.email);
        setValue('userData.firstName', userData.firstName);
        setValue('userData.lastName', userData.lastName);
        setValue('userData.phone', userData.phone);
        setValue('businessData.countryCode', userData?.country?.countryCode)
        setValue('businessData.category', userData?.category);
        setValue("businessData.bookingManagement", userData?.bookingManagement)
        setBookingManagement(userData?.bookingManagement)
        setBusinessCategory(userData?.category)
        setCallingCode(userData?.country?.countryCode)
        setCountryCode(userData?.country?.code)
        setValue('businessData.phone', userData.phone);
        setValue('businessData.name', userData.businessName);
      })
      .catch((error) => {
        console.log({ error });
        addToast({ variant: 'error', message: error.message });
      });
    }
  }, [invitationCode])

  async function onSubmit(input: RegisterInput) {
    setIsLoading(true);
    const businessSpecialties = input.businessSpecialties.map(
      (x: unknown) => (x as IOption).value
    );
    const phone = input.businessData.phone;
    try {
      const {
        data: {
          data: { register },
        },
      } = await axios.post('/graphql', {
        query: print(RegisterDocument),
        variables: {
          input: {
            ...input,
            userData: {
              ...input.userData,
              email: input?.userData?.email?.toLowerCase(),
              countryCode,
              callingCode,
              phone,
            },
            businessData: {
              ...input.businessData,
              countryCode,
              callingCode,
              phone,
            },
            businessSpecialties,
            role: input.role,
            invitationCode: input?.invitationCode,
          },
        },
      });

      if (register.status === 201) {
        localStorage.setItem('token', register.token);
        localStorage.setItem('userData', JSON.stringify(register.user));
        localStorage.setItem('country', JSON.stringify(register.user.business.country));
        window.location.href = '/otp';
      } else {
        addToast({ variant: 'error', message: register.errors[0].message });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      addToast({ variant: 'error', message: error.message });
    }
  }

  const goToLoginPage = () => {
    window.location.href = '/login';
  };
  // @ts-expect-error Env variables has been set in the application.html.erb
  const ORG_ID = envVariables.fullstoryOrgId;
  // @ts-expect-error Env variables has been set in the application.html.erb
  const turnstileKey = envVariables.turnstileKey;

  return (
    <>
      <FullStory org={ORG_ID} />
      <main className="flex items-center justify-center min-h-screen bg-white xl:bg-grey-100">
        <div className="table-row">
          <div className="table-cell align-middle">
            <div className="w-full xl:border-none bg-white shadow-medium mx-auto py-8 px-9 max-w-[480px] xl:w-[580px] xl:max-w-[700px] space-y-6">
              <div className="flex flex-col items-center space-y-6">
                <Logo />
                <Paragraph
                  size="b5"
                  weight="medium"
                  className="w-full text-center max-w-[400px]"
                  color={COLORS.GREY[400]}
                >
                  Let's get you all set up so you can start managing customer
                  appointments and payments
                </Paragraph>
                <div className="border-t border-grey-20 w-full"></div>
              </div>
              <ToastWrapper toast={toast as ToastProps} />
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="w-full space-y-6"
                autoComplete="off"
              >
                <div className="w-full flex gap-x-4">
                  <Input
                    name="userData.firstName"
                    label="First name"
                    id="first-name"
                    type="text"
                    placeholder="Enter first name here"
                    control={control}
                    rules={{
                      required: ERRORS.FIRST_NAME_REQUIRED,
                      pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH,
                    }}
                    error={errors.userData?.firstName}
                  />

                  <Input
                    name="userData.lastName"
                    label="Last name"
                    id="last-name"
                    type="text"
                    placeholder="Enter last name here"
                    control={control}
                    rules={{
                      required: ERRORS.LAST_NAME_REQUIRED,
                      pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH,
                    }}
                    error={errors.userData?.lastName}
                  />
                </div>
                <Input
                  name="userData.email"
                  label="Email"
                  id="email"
                  type="email"
                  placeholder="email@acme.com"
                  control={control}
                  rules={{
                    required: ERRORS.EMAIL_REQUIRED,
                    pattern: REGEX_PATTERNS.EMAIL,
                  }}
                  error={errors.userData?.email}
                />
                <div className="w-full relative block xl:flex">
                  <PasswordInput
                    name="userData.password"
                    label="Password"
                    id="password"
                    type="password"
                    placeholder="**********"
                    control={control}
                    rules={{
                      required: ERRORS.PASSWORD_REQUIRED,
                      pattern: REGEX_PATTERNS.PASSWORD_STRENGTH,
                    }}
                    error={errors.userData?.password}
                  />
                  <span className="bg-white xl:shadow-md relative xl:absolute xl:top-6 xl:-right-[35%] transition-all rounded p-0 xl:p-2 text-b6 text-grey-400 scale-100">
                    Password must: <br />
                    Be a minimum of 8 characters <br />
                    Include the following: <br />
                    <ul>
                      <li>A digit(s)</li>
                      <li>A lowercase letter(s)</li>
                      <li>An uppercase letter(s)</li>
                      <li>A symbol(s)</li>
                    </ul>
                  </span>
                </div>
                <Input
                  name="businessData.name"
                  id="business-name"
                  label="Business name"
                  type="text"
                  placeholder="Enter business name here"
                  control={control}
                  rules={{
                    required: ERRORS.BUSINESS_NAME_REQUIRED,
                  }}
                  error={errors.businessData?.name}
                />
                <SelectWithChangeHandlerInput
                  name="businessData.category"
                  id="category"
                  label="What type of business do you run?"
                  control={control}
                  rules={{
                    required: ERRORS.CATEGORY_REQUIRED,
                  }}
                  error={errors.businessData?.category}
                  options={BUSINESS_CATEGORIES}
                  value={businessCategory}
                  placeholder="Select a category"
                  onChange={handleBusinessCategoryChange}
                />
                <div className="flex flex-col space-y-2">
                  <Controller
                    control={control}
                    name="businessSpecialties"
                    render={({
                      field: { onChange, value },
                      formState: { errors },
                    }) => {
                      const errorMessage: string =
                        errors.businessSpecialties?.message;
                      return (
                        <>
                          <FormLabel htmlFor="specialty">
                            What's your specialty? (Max 3)
                          </FormLabel>
                          <MultiSelect
                            selected={
                              (value as unknown as MultiSelectOption[]) || []
                            }
                            options={SPECIALTIES}
                            setSelected={onChange}
                          />
                          {errorMessage && (
                            <FormHelperText variant="error">
                              {errorMessage}
                            </FormHelperText>
                          )}
                        </>
                      );
                    }}
                    rules={{
                      required: 'Select a specialty',
                    }}
                  />
                </div>
                <SelectWithChangeHandlerInput
                  name="businessData.teamSize"
                  id="teamSize"
                  label="How big is your team?"
                  control={control}
                  rules={{
                    required: ERRORS.TEAM_SIZE_REQUIRED,
                  }}
                  error={errors.businessData?.teamSize}
                  options={TEAM_SIZE}
                  placeholder="Select a team size"
                  value={teamSize}
                  disabled={teamSizeDisabled}
                  onChange={handleTeamSizeChange}
                />

                <SelectWithChangeHandlerInput
                  name="role"
                  id="role"
                  label="Role at Business"
                  control={control}
                  rules={{
                    required: ERRORS.ROLE_REQUIRED,
                  }}
                  error={errors?.role}
                  options={ROLES}
                  placeholder="Select Role"
                  value={role}
                  disabled={roleDisabled}
                  onChange={handleRoleChange}
                />

                <SelectWithChangeHandlerInput
                  name="businessData.bookingManagement"
                  id="manageBooking"
                  label="How do you manage your business"
                  control={control}
                  rules={{
                    required: ERRORS.BOOKING_MANAGEMENT_REQUIRED,
                  }}
                  error={errors.businessData?.bookingManagement}
                  options={BOOKING_MANAGEMENT}
                  placeholder="Select..."
                  value={bookingManagement}
                  onChange={handleBookingManagementChange}
                />

                <SelectWithChangeHandlerInput
                  name="businessData.countryCode"
                  id="country"
                  label="Country"
                  control={control}
                  rules={{
                    required: ERRORS.COUNTRY_REQUIRED,
                  }}
                  error={errors.businessData?.countryCode}
                  options={countries.map((country) => ({
                    label: country.name,
                    value: country.code,
                  }))}
                  placeholder="Select country"
                  onChange={handleCountryChange}
                  value={countryCode}
                />

                <FormPhone
                  country={{
                    disabled: true,
                    onSelect: (code, country: string) => {
                      setCallingCode(code);
                      setCountryCode(country);
                    },
                    value: countryCode,
                  }}
                  phone={{
                    name: 'businessData.phone',
                    type: 'text',
                    placeholder: '9151930463',
                    rules: {
                      required: ERRORS.PHONE_REQUIRED,
                      pattern: REGEX_PATTERNS.NUMBER,
                      onChange: (e) => {
                        _sanitizePhoneNumber(e.target.value);
                      },
                      disabled: false,
                    },
                    register,
                    id: 'phone-number',
                    label: 'Business Phone Number',
                  }}
                  helperText={getHelperTextForReactHookFormErrors(
                    errors?.businessData?.phone?.message as string
                  )}
                />
                {showRcNumberInput ? (
                  <Input
                    name="businessData.rcNumber"
                    id="rc-number"
                    label="RC Number"
                    type="text"
                    placeholder="Enter RC Number"
                    control={control}
                  />
                ) : null}
                <Input
                  name="invitationCode"
                  label="Invitation Code"
                  id="invitation"
                  type="text"
                  placeholder="Enter invitation code"
                  className='uppercase'
                  control={control}
                    rules={{
                      required: 'Invitation code is required',
                    }}
                    error={errors.invitationCode}
                />
                <Controller
                  control={control}
                  name="canServeCustomers"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className="w-full flex flex-col space-y-4">
                        <FormLabel htmlFor="canServeCustomers">
                          Do you as the business owner/manager/supervisor,
                          personally service customers?
                        </FormLabel>
                        <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                          {Array.isArray(YES_OR_NO_SETTINGS) &&
                          YES_OR_NO_SETTINGS.length
                            ? YES_OR_NO_SETTINGS.map(
                                (canServeCustomers: {
                                  label: string;
                                  value: boolean;
                                }) => {
                                  return (
                                    <div
                                      className="flex"
                                      onClick={() =>
                                        onChange(canServeCustomers?.value)
                                      }
                                      key={canServeCustomers?.label}
                                    >
                                      <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                        <Checkbox
                                          isChecked={
                                            value === canServeCustomers?.value
                                          }
                                        />
                                        <span className="text-grey-900 whitespace-nowrap">
                                          {canServeCustomers?.label}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            : null}
                        </div>
                      </div>
                    );
                  }}
                />
                <Paragraph
                  size="b5"
                  weight="normal"
                  className="w-full text-center pt-4"
                  color={COLORS.GREY[400]}
                >
                  By clicking &apos;Sign Up&apos; you are accepting
                  Splice&apos;s{' '}
                  <span
                    className="text-black"
                    onClick={openModal}
                    role="button"
                  >
                    Terms & Conditions
                  </span>
                </Paragraph>
                <div className="w-full flex justify-center space-x-2">
                  <Turnstile
                    siteKey={turnstileKey}
                    onSuccess={() => setIsHuman(true)}
                    onError={() => setIsHuman(false)}
                  />
                </div>
                <Button
                  variant="primary"
                  className=""
                  disabled={isLoading || !isHuman}
                  loading={isLoading}
                  size="lg"
                  rounded="lg"
                >
                  Sign Up
                </Button>
                <div className="w-full flex justify-center space-x-2">
                  <Paragraph size="b5" className="" color={COLORS.GREY[400]}>
                    Don&apos;t have a Splice Business account?
                  </Paragraph>
                  <Button
                    variant="text"
                    className="text-b5 mx-0"
                    fontWeight="bold"
                    size="none"
                    type="button"
                    onClick={goToLoginPage}
                  >
                    Login
                  </Button>
                </div>
              </form>
              <TermAndConditionsModal
                isVisible={isVisible}
                closeModal={closeModal}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Register;
