import React from 'react'
import SvgCalendarFrame from '../../ui/icons/CalendarFrame';
import SvgBellFrame from '../../ui/icons/BellFrame';
import Paragraph from '../../ui/atoms/paragraph/Paragraph';
import { EmptyStateProps } from './types';

const HomeEmptyCard = (props: EmptyStateProps) => {
  const icon = props.variant === "appointment" ? (
    <SvgCalendarFrame width='40px' height='40px' />
  ) : (
    <SvgBellFrame width='40px' height='40px' />
  );

  return (
    <div className='flex flex-col space-y-4 p-6 justify-center items-center'>
      {icon}
      <Paragraph size='b5' color='grey-300' className='text-center pt-4'>{props.title}</Paragraph>
    </div>
  );
}

export default HomeEmptyCard