import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGiftCardAmico = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 230 230"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M115 221.674c55.489 0 100.473-25.972 100.473-58.011 0-32.038-44.984-58.01-100.473-58.01-55.49 0-100.474 25.972-100.474 58.01 0 32.039 44.984 58.011 100.474 58.011Z"
      fill="#F5F5F5"
    />
    <path
      d="m50.55 177.905-24.482-14.131 24.482-14.136 24.48 14.136-24.48 14.131Z"
      fill="#E0E0E0"
    />
    <path
      d="m71.967 181.359-18.184-10.492 18.184-10.497 18.18 10.497-18.18 10.492ZM112.498 209.088c14.745 0 26.699-6.901 26.699-15.414s-11.954-15.415-26.699-15.415c-14.745 0-26.698 6.902-26.698 15.415 0 8.513 11.953 15.414 26.698 15.414ZM187.036 184.556l-103.72-59.703 13.924-8.036 103.716 59.703-13.92 8.036Z"
      fill="#E0E0E0"
    />
    <path
      d="m186.36 168.153 6.238 3.625s-3.147-10.047-1.38-20.153c.657-3.813 2.042-5.708 2.534-6.725.492-1.017 4.251-7.319.92-10.226-3.33-2.907-11.077 2.875-10.727 18.437.169 5.096.98 10.15 2.415 15.042Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.3}
      d="m186.36 168.153 6.238 3.625s-3.147-10.047-1.38-20.153c.657-3.813 2.042-5.708 2.534-6.725.492-1.017 4.251-7.319.92-10.226-3.33-2.907-11.077 2.875-10.727 18.437.169 5.096.98 10.15 2.415 15.042Z"
      fill="#000"
    />
    <path
      d="M189.258 170.066a.232.232 0 0 1-.211-.151c-.023-.065-2.378-6.601-2.254-17.094.124-10.493 5.662-16.16 5.718-16.201a.222.222 0 0 1 .16-.065.224.224 0 0 1 .224.227c0 .06-.024.118-.067.16-.055.05-5.46 5.552-5.58 15.897-.119 10.346 2.204 16.873 2.227 16.933a.233.233 0 0 1-.062.245.225.225 0 0 1-.076.045.152.152 0 0 1-.079.004Z"
      fill="#fff"
    />
    <path
      d="m188.14 169.091 10.304 6.063s4.715-.699 4.356-5.138-2.272-5.488.115-7.438c2.387-1.951 3.004-2.08 2.889-3.993-.115-1.914-1.403-2.76-2.006-3.505-.602-.746-2.3-2.346-1.108-4.651 1.191-2.305 2.796-4.536.409-5.594-1.426-.63-3.823.161-5.414 1.84-1.592 1.679-2.898 2.898-4.407 3.289s-4.646.424-4.54 3.018c.106 2.594.782 3.491.152 4.646-1.21 2.176-6.27 3.344-.75 11.463Z"
      fill="#7EA05C"
    />
    <path
      d="M192.313 171.787a.23.23 0 0 1-.152-.061.23.23 0 0 1-.074-.146c0-.157-1.131-15.488 9.522-25.558a.228.228 0 0 1 .304.022.226.226 0 0 1 .005.305c-10.493 9.917-9.394 25.042-9.38 25.194a.228.228 0 0 1-.207.244h-.018Z"
      fill="#fff"
    />
    <path
      d="M194.157 158.622a.223.223 0 0 1-.221-.207 8.823 8.823 0 0 0-3.155-6.155.232.232 0 0 1-.061-.064.24.24 0 0 1-.031-.082.237.237 0 0 1 .004-.088.238.238 0 0 1 .037-.079.226.226 0 0 1 .313-.055 9.088 9.088 0 0 1 3.34 6.5.234.234 0 0 1-.053.165.227.227 0 0 1-.154.078l-.019-.013ZM193.398 161.244a.22.22 0 0 1-.152-.06.224.224 0 0 1 0-.317c.11-.125 2.76-3.004 8.956-2.158a.224.224 0 0 1 .193.253.22.22 0 0 1-.253.193c-5.924-.814-8.537 1.988-8.56 2.015a.24.24 0 0 1-.184.074Z"
      fill="#fff"
    />
    <path
      d="m189.52 90.28-48.99-79.603a4.646 4.646 0 0 0-6.21-1.638L90.55 33.12l98.97 57.16Z"
      fill="#7EA05C"
    />
    <path
      d="M189.52 90.28 90.55 33.12v86.172a8.25 8.25 0 0 0 4.14 7.148l87.93 50.761a4.63 4.63 0 0 0 4.598-.03 4.63 4.63 0 0 0 2.302-3.981V90.28Z"
      fill="#7EA05C"
    />
    <g opacity={0.3} fill="#fff">
      <path d="m189.52 90.28-48.99-79.603a4.646 4.646 0 0 0-6.21-1.638L90.55 33.12l98.97 57.16Z" />
      <path d="M189.52 90.28 90.55 33.12v86.172a8.25 8.25 0 0 0 4.14 7.148l87.93 50.761a4.63 4.63 0 0 0 4.598-.03 4.63 4.63 0 0 0 2.302-3.981V90.28Z" />
    </g>
    <path
      d="M187.22 91.209 138.203 11.6a4.649 4.649 0 0 0-6.183-1.637L88.224 34.04 187.22 91.21Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.5}
      d="M187.22 91.209 138.203 11.6a4.649 4.649 0 0 0-6.183-1.637L88.224 34.04 187.22 91.21Z"
      fill="#fff"
    />
    <path
      d="M187.22 91.209 88.224 34.04v86.176a8.277 8.277 0 0 0 4.14 7.153l87.906 50.757a4.626 4.626 0 0 0 4.628-.001 4.623 4.623 0 0 0 2.318-4.006l.004-82.91Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.5}
      d="M187.22 91.209 88.224 34.04v86.176a8.277 8.277 0 0 0 4.14 7.153l87.906 50.757a4.626 4.626 0 0 0 4.628-.001 4.623 4.623 0 0 0 2.318-4.006l.004-82.91Z"
      fill="#fff"
    />
    <path
      d="m92.354 127.369 87.916 50.757a4.548 4.548 0 0 0 4.475.073l-52.472-79.005-44.05 21.022a8.283 8.283 0 0 0 4.131 7.153Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.7}
      d="m92.354 127.369 87.916 50.757a4.548 4.548 0 0 0 4.475.073l-52.472-79.005-44.05 21.022a8.283 8.283 0 0 0 4.131 7.153Z"
      fill="#fff"
    />
    <path
      d="m88.224 34.054 42.862 73.066 56.13-15.911-98.992-57.155Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.3}
      d="m88.224 34.054 42.862 73.066 56.13-15.911-98.992-57.155Z"
      fill="#fff"
    />
    <path
      d="M176.355 59.699v34.587l-45.269 12.829-31.744-54.114v-37.77l77.013 44.468Z"
      fill="#F0F0F0"
    />
    <path
      d="m50.55 175.31-19.27-11.127v-26.418l19.27 11.128v26.417ZM50.55 175.31v-26.417l19.27-11.128v26.418L50.55 175.31Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.5}
      d="m50.55 175.31-19.27-11.127v-26.418l19.27 11.128v26.417Z"
      fill="#000"
    />
    <path
      opacity={0.7}
      d="M50.55 175.31v-26.417l19.27-11.128v26.418L50.55 175.31Z"
      fill="#000"
    />
    <path
      d="m50.55 147.816-20.765-11.992 20.765-11.988 20.764 11.988-20.764 11.992Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.1}
      d="m50.55 147.816-20.765-11.992 20.765-11.988 20.764 11.988-20.764 11.992Z"
      fill="#000"
    />
    <path
      d="M38.815 130.612 59.58 142.6l2.705-1.559-20.765-11.993-2.705 1.564Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.3}
      d="M38.815 130.612 59.58 142.6l2.705-1.559-20.765-11.993-2.705 1.564Z"
      fill="#fff"
    />
    <path
      d="m50.55 151.961-20.765-11.988v-4.149l20.765 11.992v4.145ZM50.55 151.961v-4.145l20.764-11.992v4.149L50.55 151.961Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.3}
      d="m50.55 151.961-20.765-11.988v-4.149l20.765 11.992v4.145Z"
      fill="#000"
    />
    <path
      opacity={0.5}
      d="M50.55 151.961v-4.145l20.764-11.992v4.149L50.55 151.961Z"
      fill="#000"
    />
    <path
      d="m41.52 170.113-2.705-1.551v-27.521l2.705 1.559v27.513ZM59.58 170.113l2.704-1.551v-27.521l-2.705 1.559v27.513Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.2}
      d="m41.52 170.113-2.705-1.551v-27.521l2.705 1.559v27.513Z"
      fill="#000"
    />
    <path
      opacity={0.4}
      d="m59.58 170.113 2.704-1.551v-27.521l-2.705 1.559v27.513Z"
      fill="#000"
    />
    <path
      d="M62.285 130.612 41.52 142.6l-2.705-1.559 20.765-11.993 2.705 1.564Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.3}
      d="M62.285 130.612 41.52 142.6l-2.705-1.559 20.765-11.993 2.705 1.564Z"
      fill="#fff"
    />
    <path
      d="m71.967 179.211-14.08-8.128v-19.302l14.08 8.128v19.302ZM71.967 179.211v-19.302l14.076-8.128v19.302l-14.076 8.128Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.5}
      d="m71.967 179.211-14.08-8.128v-19.302l14.08 8.128v19.302Z"
      fill="#000"
    />
    <path
      opacity={0.7}
      d="M71.967 179.211v-19.302l14.076-8.128v19.302l-14.076 8.128Z"
      fill="#000"
    />
    <path
      d="m71.967 159.123-15.175-8.758 15.175-8.759 15.171 8.759-15.17 8.758Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.1}
      d="m71.967 159.123-15.175-8.758 15.175-8.759 15.171 8.759-15.17 8.758Z"
      fill="#000"
    />
    <path
      d="m63.393 146.556 15.17 8.758 1.978-1.141-15.175-8.758-1.973 1.141Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.3}
      d="m63.393 146.556 15.17 8.758 1.978-1.141-15.175-8.758-1.973 1.141Z"
      fill="#fff"
    />
    <path
      d="m71.967 162.155-15.175-8.763v-3.027l15.175 8.758v3.032ZM71.967 162.155v-3.032l15.17-8.758v3.027l-15.17 8.763Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.3}
      d="m71.967 162.155-15.175-8.763v-3.027l15.175 8.758v3.032Z"
      fill="#000"
    />
    <path
      opacity={0.5}
      d="M71.967 162.155v-3.032l15.17-8.758v3.027l-15.17 8.763Z"
      fill="#000"
    />
    <path
      d="m65.366 175.412-1.973-1.132v-20.107l1.973 1.141v20.098ZM78.564 175.412l1.978-1.132v-20.107l-1.978 1.141v20.098Z"
      fill="#7EA05C"
    />
    <path
      d="m80.541 146.556-15.175 8.758-1.973-1.141 15.17-8.758 1.978 1.141Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.3}
      d="m80.541 146.556-15.175 8.758-1.973-1.141 15.17-8.758 1.978 1.141Z"
      fill="#fff"
    />
    <path
      d="m87.548 82.257 8.28 14.026 9.411-5.037a15.914 15.914 0 0 1 5.948-1.813l-5.8 11.625-9.274 4.522a4.363 4.363 0 0 1-2.83.362 4.362 4.362 0 0 1-2.446-1.466c-1.049-1.275-2.208-3.358-3.29-6.735-3.077-9.623-4.512-14.646-4.512-14.646l1.748-2.7 2.765 1.862Z"
      fill="#FFA8A7"
    />
    <path
      d="M108.767 176.208c.028-.225.051-.46.078-.649.46-3.776 3.137-16.1 2.76-21.62l8.174-31.091-13.445-2.259s-5.52 19.937-6.201 30.82c-.373 5.98.106 16.528.607 24.794 2.589.963 5.437.964 8.027.005Z"
      fill="#455A64"
    />
    <path
      d="m104.172 191.42 3.68-5.414s.46-5.957.897-9.798a11.54 11.54 0 0 1-8.027.023 667.91 667.91 0 0 0 .841 12.001l2.609 3.188Z"
      fill="#FFA8A7"
    />
    <path
      d="m110.773 121.344-2.226.257c-1.84 8.28 3.928 12.131 3.928 12.131s-3.979 12.746-5.759 22.121c-.865 4.559 1.247 7.194 3.965 8.657.617-3.96 1.104-7.99.92-10.58l8.174-31.091-9.002-1.495Z"
      fill="#37474F"
    />
    <path
      d="M107.976 186.903a5.413 5.413 0 0 0-2.213 2.76 3.476 3.476 0 0 1-1.591 1.752s.372-1.619-.281-2.12c-.653-.502-2.953-2.3-3.197.244-.078.795-.152 1.591-.152 1.591s-3.054 3.312-4.54 4.034c-1.486.723-5.709.631-4.674 4.485 1.035 3.855 8.303 1.983 10.691-.105 2.387-2.089 8.252-1.629 8.169-4.416-.082-2.788-2.028-5.99-2.212-8.225Z"
      fill="#263238"
    />
    <path
      d="M107.87 186.006a5.427 5.427 0 0 0-2.222 2.783 3.521 3.521 0 0 1-1.61 1.766s.377-1.628-.28-2.139c-.658-.51-2.972-2.3-3.22.249l-.152 1.605s-3.073 3.33-4.568 4.057c-1.495.727-5.75.635-4.706 4.517 1.044 3.883 8.359 1.997 10.764-.105 2.406-2.103 8.308-1.643 8.22-4.449-.087-2.806-2.037-6.035-2.226-8.284Z"
      fill="#37474F"
    />
    <path
      d="M102.778 193.2a.22.22 0 0 1-.207-.156 3.798 3.798 0 0 0-2.25-2.553.227.227 0 0 1-.077-.04.198.198 0 0 1-.055-.067.208.208 0 0 1-.015-.169.216.216 0 0 1 .109-.128.22.22 0 0 1 .167-.015 4.22 4.22 0 0 1 2.539 2.838.23.23 0 0 1 .011.086.216.216 0 0 1-.153.19l-.069.014ZM101.449 194.437a.214.214 0 0 1-.212-.156 3.79 3.79 0 0 0-2.25-2.548.224.224 0 0 1-.128-.109.215.215 0 0 1 .023-.243.218.218 0 0 1 .235-.072 4.21 4.21 0 0 1 2.539 2.843.223.223 0 0 1-.015.168.218.218 0 0 1-.128.108l-.064.009ZM100.133 195.762a.222.222 0 0 1-.211-.156 3.764 3.764 0 0 0-2.25-2.549.222.222 0 0 1-.128-.108.222.222 0 0 1 .262-.315 4.226 4.226 0 0 1 2.539 2.843.227.227 0 0 1-.016.169.234.234 0 0 1-.131.107l-.065.009Z"
      fill="#F0F0F0"
    />
    <path
      d="M95.818 194.327c-1.495.727-5.75.635-4.706 4.517.677 2.503 3.956 2.609 6.845 1.804a8.51 8.51 0 0 0-.152-3.441c-.584-2.167-1.987-2.88-1.987-2.88Z"
      fill="#FAFAFA"
    />
    <path
      d="M137.218 158.875a5.393 5.393 0 0 0 2.093 2.852 3.512 3.512 0 0 1 1.279 1.996s-1.467-.782-2.125-.28c-.658.501-2.967 2.258-.589 3.151l1.495.561s2.401 3.804 2.714 5.428c.313 1.624-.874 5.676 3.119 5.676 3.993 0 4.071-7.502 2.677-10.35-1.394-2.847.571-8.39-2.143-9.034-2.714-.644-6.316.4-8.52 0Z"
      fill="#263238"
    />
    <path
      d="M131.041 157.624c-.796-.212-1.62-.46-2.448-.722a56.97 56.97 0 0 0-8.004-1.946s6.284-15.392 7.664-23.557-1.881-13.694-1.881-13.694l-18.147 3.22c-1.38 8.354 5.644 12.627 5.644 12.627-.276-.069-4.016 12.236-5.465 21.671-1.449 9.434 6.836 9.738 10.805 9.66 1.896-.028 6.758-.033 11.316-.023 1.495-3.322 1.003-5.884.516-7.236Z"
      fill="#455A64"
    />
    <path
      d="M136.62 158.787a53.691 53.691 0 0 1-5.571-1.163c.488 1.352.957 3.914-.515 7.231l9.6.023c-.009-4.711-3.514-6.091-3.514-6.091Z"
      fill="#FFA8A7"
    />
    <path
      d="M136.321 158.742a5.452 5.452 0 0 0 2.112 2.87 3.557 3.557 0 0 1 1.288 2.01s-1.477-.786-2.139-.28c-.663.506-2.99 2.272-.598 3.169l1.508.566s2.42 3.832 2.733 5.465c.313 1.633-.879 5.717 3.142 5.717 4.02 0 4.098-7.553 2.691-10.419-1.408-2.865.575-8.45-2.158-9.098-2.732-.649-6.357.418-8.579 0Z"
      fill="#37474F"
    />
    <path
      d="M138.493 167.298a.211.211 0 0 1-.115-.033.209.209 0 0 1-.099-.137.207.207 0 0 1-.002-.086.207.207 0 0 1 .032-.08 4.192 4.192 0 0 1 3.399-1.716.209.209 0 0 1 .16.057.198.198 0 0 1 .051.07.207.207 0 0 1 .019.085.223.223 0 0 1-.058.159.219.219 0 0 1-.07.05.216.216 0 0 1-.083.021 3.764 3.764 0 0 0-3.046 1.508.224.224 0 0 1-.188.102ZM139.38 168.912a.231.231 0 0 1-.119-.037.22.22 0 0 1-.069-.303 4.222 4.222 0 0 1 3.404-1.716.22.22 0 0 1 .161.372.225.225 0 0 1-.152.07 3.845 3.845 0 0 0-3.05 1.508.209.209 0 0 1-.175.106ZM140.3 170.522a.216.216 0 0 1-.189-.336 4.222 4.222 0 0 1 3.404-1.715.218.218 0 0 1 .161.056.223.223 0 0 1 .01.313.223.223 0 0 1-.152.072 3.8 3.8 0 0 0-3.05 1.509.215.215 0 0 1-.184.101Z"
      fill="#F0F0F0"
    />
    <path
      d="M141.221 172.542c.317 1.633-.879 5.717 3.141 5.717 2.595 0 3.543-3.141 3.515-6.141a8.425 8.425 0 0 0-3.358-.749c-2.254 0-3.298 1.173-3.298 1.173Z"
      fill="#FAFAFA"
    />
    <path
      d="M127.42 69.69s.083-4.19 1.256-4.273c1.173-.083.322 2.888.322 2.888s1.223-.814 1.688-.082c.465.731-2.263 1.927-2.263 1.927l-1.003-.46Z"
      fill="#263238"
    />
    <path
      d="M102.865 77.901s.833 6.942 11.367 2.254c0 0-9.996 18.432 7.93 18.432 7.871 0 13.188-3.85 12.121-10.34 0 0 4.407-.097 4.6-3.63 0 0-5.396 2.36-7.742-2.976s.874-10.612-3.123-12.747c0 0-2.231-7.088-15.806-3.307a4.277 4.277 0 0 0-4.08-.419c-2.396.92-3.008 5.148-2.396 8.35.611 3.201-.051 5.731-2.871 4.383Z"
      fill="#263238"
    />
    <path
      d="m124.761 90.846 2.13.58v14.071l-2.13 6.785a57.698 57.698 0 0 1 2.383 8.041 20.965 20.965 0 0 1-10.888 3.137c-6.321 0-9.922-2.861-9.922-2.861l-.801-9.366s-4.687-2.995-2.387-8.975c2.3-5.98 8.054-12.824 8.054-12.824h2.07s4.798 2.888 11.491 1.412Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.6}
      d="m124.761 90.846 2.13.58v14.071l-2.13 6.785a57.698 57.698 0 0 1 2.383 8.041 20.965 20.965 0 0 1-10.888 3.137c-6.321 0-9.922-2.861-9.922-2.861l-.801-9.366s-4.687-2.995-2.387-8.975c2.3-5.98 8.054-12.824 8.054-12.824h2.07s4.798 2.888 11.491 1.412Z"
      fill="#000"
    />
    <path
      d="m121.602 90.031 3.16.815s-3.28 2.01-4.508 8.514a1.483 1.483 0 0 1-1.266 1.2 1.492 1.492 0 0 1-.616-.045l-8.491-2.498a1.49 1.49 0 0 1-1.05-1.521c.015-.222.08-.438.19-.632 1.048-1.867 2.829-4.871 4.268-6.44h2.829l5.484.607Z"
      fill="#FFA8A7"
    />
    <path d="m121.602 90.031-7.37-.552v-9.324h7.37v9.876Z" fill="#FFA8A7" />
    <path
      d="M114.232 89.075a11.846 11.846 0 0 0 6.772-6.44v-2.48h-6.772v8.92Z"
      fill="#F28F8F"
    />
    <path
      d="M112.512 68.08s-6.739.396-5.907 11.923c.387 5.327 1.633 8.087 5.737 7.885 4.65-.226 11.251-3.423 10.819-12.544-.433-9.122-10.649-7.264-10.649-7.264Z"
      fill="#FFA8A7"
    />
    <path
      d="M121.602 80.155s-1.84.488-1.84-2.162c0 0-8.211-.557-10.024-8.703 0 0 .81-2.443 5.934-2.443 5.125 0 9.439 4.536 8.101 10.769l-2.171 2.539Z"
      fill="#263238"
    />
    <path d="M111.546 77.68v3.96l-2.318-.708 2.318-3.252Z" fill="#F28F8F" />
    <path
      d="M114.232 78.909a.81.81 0 1 0 .001-1.619.81.81 0 0 0-.001 1.619ZM109.729 77.538a.813.813 0 0 1-.965.8.812.812 0 0 1-.639-.636.808.808 0 0 1 .344-.832.809.809 0 0 1 1.26.668ZM107.935 75.859l1.582-.92s-.607-.63-1.209-.253a1.107 1.107 0 0 0-.373 1.173Z"
      fill="#263238"
    />
    <path
      d="M111.748 82.957a4.14 4.14 0 0 0 2.599-.7s.175 1.38-.971 1.633c-1.145.253-1.628-.933-1.628-.933Z"
      fill="#F28F8F"
    />
    <path
      d="M120.948 80.454c-.028-.67.096-1.34.364-1.955.308-.685.851-1.38 1.794-1.403 2.07-.06 2.35 1.937 2.323 2.645-.074 1.863-1.583 3.878-4.393 3.956l-.088-3.243ZM126.188 91.232s-3.933 3.51-2.112 8.537c1.822 5.028 5.52 11.275 6.565 13.506 0 0-8.823 1.003-11.04 1.224l1.255 5.782s12.563.253 15.263-.741c2.7-.993 3.956-3.376 2.544-7.084-1.058-2.76-5.663-12.765-7.889-17.572a6.442 6.442 0 0 0-3.979-3.46l-.607-.192Z"
      fill="#FFA8A7"
    />
    <path
      d="M59.253 70.353v42.402a4.683 4.683 0 0 0 2.341 4.053l52.555 30.286a2.107 2.107 0 0 0 3.156-1.84v-42.333a5.88 5.88 0 0 0-2.944-5.097L62.965 68.209a2.475 2.475 0 0 0-3.712 2.144Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.3}
      d="M59.253 70.353v42.402a4.683 4.683 0 0 0 2.341 4.053l52.555 30.286a2.107 2.107 0 0 0 3.156-1.84v-42.333a5.88 5.88 0 0 0-2.944-5.097L62.965 68.209a2.475 2.475 0 0 0-3.712 2.144Z"
      fill="#000"
    />
    <path
      d="M58.107 70.78v42.403a4.685 4.685 0 0 0 2.337 4.048l52.56 30.287a2.105 2.105 0 0 0 2.881-.781c.184-.323.278-.688.274-1.059v-42.334a5.884 5.884 0 0 0-2.944-5.097L61.82 68.632a2.475 2.475 0 0 0-3.713 2.148Z"
      fill="#7EA05C"
    />
    <path
      d="m78.324 78.145.998-.51 2.02 1.163-.893.57-2.125-1.223Z"
      fill="#7EA05C"
    />
    <path
      d="m78.324 127.535 2.125 1.224v-49.39l-2.125-1.224v49.39Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.4}
      d="m78.324 127.535 2.125 1.224v-49.39l-2.125-1.224v49.39Z"
      fill="#fff"
    />
    <path
      d="m88.444 82.888-2.12-1.224s-.41.607-.709 3.579c-.248 2.48-9.255-2.76-5.465-7.521 2.388-2.986 9.55 2.778 8.294 5.166ZM119.6 114.499a31.439 31.439 0 0 1-3.446 0c-.671-.124-2.829-2.217-3.404-2.3-.575-.083-2.042.842-.92 4.356 1.123 3.514 2.76 5.111 5.318 4.733 2.558-.377 3.68-1.002 3.68-1.002l-1.228-5.787Z"
      fill="#FFA8A7"
    />
    <path
      d="M119.6 114.499a20.872 20.872 0 0 0-2.3-1.592v2.553l2.3-.961Z"
      fill="#FFA8A7"
    />
    <path
      d="m58.107 92.97 58.052 33.516v-2.681L58.107 90.289v2.682Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.4}
      d="m58.107 92.97 58.052 33.516v-2.681L58.107 90.289v2.682Z"
      fill="#fff"
    />
    <path
      d="M79.01 102.447s-2.41-8.137-.534-8.781c1.514-.52 3.05.864 2.498 3.887a55.308 55.308 0 0 0-.672 5.865 1.027 1.027 0 0 0 .873.078 1.026 1.026 0 0 0 .618-.621c.92-2.544 2.948-6.518 1.739-9.554-.86-2.162-4.49-5.088-7.209-3.901-2.718 1.187-1.706 7.093 1.206 12.843 0 0 .538 1.297 1.48.184Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.6}
      d="M79.01 102.447s-2.41-8.137-.534-8.781c1.514-.52 3.05.864 2.498 3.887a55.308 55.308 0 0 0-.672 5.865 1.027 1.027 0 0 0 .873.078 1.026 1.026 0 0 0 .618-.621c.92-2.544 2.948-6.518 1.739-9.554-.86-2.162-4.49-5.088-7.209-3.901-2.718 1.187-1.706 7.093 1.206 12.843 0 0 .538 1.297 1.48.184Z"
      fill="#fff"
    />
    <path
      d="M79.63 106.306s2.411 8.138.534 8.782c-1.513.519-3.05-.865-2.498-3.887a55.34 55.34 0 0 0 .672-5.861 1.03 1.03 0 0 0-1.49.548c-.92 2.543-2.949 6.513-1.74 9.549.861 2.162 4.49 5.088 7.21 3.901 2.718-1.187 1.706-7.093-1.206-12.843 0-.005-.538-1.302-1.481-.189Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.6}
      d="M79.63 106.306s2.411 8.138.534 8.782c-1.513.519-3.05-.865-2.498-3.887a55.34 55.34 0 0 0 .672-5.861 1.03 1.03 0 0 0-1.49.548c-.92 2.543-2.949 6.513-1.74 9.549.861 2.162 4.49 5.088 7.21 3.901 2.718-1.187 1.706-7.093-1.206-12.843 0-.005-.538-1.302-1.481-.189Z"
      fill="#fff"
    />
    <path
      d="m80.546 108.132-2.732-1.578a2.43 2.43 0 0 1-1.22-2.106v-2.834a1.273 1.273 0 0 1 1.914-1.104l2.337 1.348a2.67 2.67 0 0 1 1.334 2.3v3.022a1.09 1.09 0 0 1-1.633.952Z"
      fill="#7EA05C"
    />
    <path
      opacity={0.6}
      d="m80.546 108.132-2.732-1.578a2.43 2.43 0 0 1-1.22-2.106v-2.834a1.273 1.273 0 0 1 1.914-1.104l2.337 1.348a2.67 2.67 0 0 1 1.334 2.3v3.022a1.09 1.09 0 0 1-1.633.952Z"
      fill="#fff"
    />
    <path
      d="m116.159 126.486 1.146-.68v-2.765l-1.146.764v2.681Z"
      fill="#7EA05C"
    />
  </svg>
);
export default SvgGiftCardAmico;
