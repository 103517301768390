import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgVerve = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 216 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.765 39.669c0 20.942-16.741 37.927-37.383 37.927C16.742 77.596 0 60.634 0 39.669 0 18.703 16.74 1.74 37.383 1.74c20.663 0 37.382 16.985 37.382 37.928Z"
      fill="#EE312A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.382 55.928c-8.36-19.223-14.49-37.203-14.49-37.203H10.076s7.802 23.068 22.291 51.904h10.031C56.887 41.793 64.69 18.725 64.69 18.725H51.87s-6.13 17.957-14.49 37.203ZM198.392 37.7c-7.245 0-7.802 7.916-7.802 7.916h15.604s-.557-7.916-7.802-7.916Zm17.298 15.831h-25.1s.557 8.481 11.703 8.481c5.573 0 11.146-1.696 11.146-1.696l1.114 9.047s-5.572 2.261-13.374 2.261c-11.146 0-21.177-5.654-21.177-21.485 0-12.439 7.802-20.355 18.947-20.355 16.741 0 17.856 16.962 16.741 23.747Zm-85.308-13.366 1.716-9.34s-13.33-4.094-24.164 3.505v37.362h11.503v-30.35c4.614-3.506 10.945-1.177 10.945-1.177ZM82.433 37.7c-7.244 0-7.802 7.916-7.802 7.916h15.604s-.557-7.916-7.802-7.916ZM99.71 53.531H74.63s.558 8.481 11.703 8.481c5.573 0 11.146-1.696 11.146-1.696l1.114 9.047s-5.572 2.261-13.374 2.261c-11.146 0-21.177-5.654-21.177-21.485 0-12.439 7.802-20.355 18.947-20.355 16.719 0 17.833 16.962 16.719 23.747Zm58.76 3.868c-5.841-14.226-8.761-27.615-8.761-27.615h-11.68s5.84 22.888 15.782 41.84h9.34c9.919-18.952 15.782-41.84 15.782-41.84h-11.681c-.022 0-2.942 13.39-8.782 27.615Z"
      fill="#fff"
    />
  </svg>
);
export default SvgVerve;
