import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLoyaltyStatusPageIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={28} height={28} rx={4} fill="#F3F6F6" />
    <path
      d="m21.368 11.751.015.042.055.182.046.163.02.075.032.135a7.693 7.693 0 0 1-.853 5.518 7.693 7.693 0 0 1-4.362 3.5l-.13.039-.16.041-.23.055a.607.607 0 0 1-.735-.501.684.684 0 0 1 .516-.768l.186-.047c.115-.03.215-.06.298-.087a6.407 6.407 0 0 0 3.503-2.875 6.407 6.407 0 0 0 .737-4.475l-.022-.1-.03-.118-.037-.134-.044-.152a.686.686 0 0 1 .405-.836.609.609 0 0 1 .79.343Zm-13.994-.348a.684.684 0 0 1 .448.846c-.058.194-.1.356-.125.486a6.407 6.407 0 0 0 .737 4.471 6.407 6.407 0 0 0 3.507 2.876l.099.031.056.016.125.034.223.055a.686.686 0 0 1 .522.77.609.609 0 0 1-.692.512l-.044-.008-.186-.044-.164-.041-.143-.04-.064-.02a7.693 7.693 0 0 1-4.352-3.498 7.693 7.693 0 0 1-.851-5.528l.025-.103.032-.12.062-.214.025-.08a.607.607 0 0 1 .76-.4Zm6.642-2.545a5.143 5.143 0 1 1 0 10.285 5.143 5.143 0 0 1 0-10.285Zm0 1.285a3.857 3.857 0 1 0 0 7.715 3.857 3.857 0 0 0 0-7.715Zm0-3.857a7.692 7.692 0 0 1 5.213 2.027l.077.073.088.088.1.104.111.118a.607.607 0 0 1-.066.887.684.684 0 0 1-.923-.063 7.003 7.003 0 0 0-.195-.198l-.112-.107-.052-.046a6.407 6.407 0 0 0-4.24-1.597 6.407 6.407 0 0 0-4.245 1.6l-.036.032-.082.077-.092.092-.159.165a.686.686 0 0 1-.927.068.609.609 0 0 1-.098-.856l.03-.034.13-.138.118-.122c.056-.056.108-.106.155-.15a7.693 7.693 0 0 1 5.205-2.02"
      fill="#667368"
    />
  </svg>
);
export default SvgLoyaltyStatusPageIcon;
