import React from 'react'
import ParagraphShimmer from '../../ui/atoms/paragraph/ParagraphShimmer'

const CustomerVoucherPageShimmer = () => {
  return (
    <>
      {[...Array(4)].map((_, index) => (
        <div className={`flex bg-slate-50 border rounded-md p-3 space-x-3 cursor-pointer`} key={`tcs${index}`}>
          <div className='flex flex-col space-y-2'>
            <ParagraphShimmer size="md" />
            <ParagraphShimmer size="lg" />
          </div>
        </div>
      ))}
    </>
  )
}

export default CustomerVoucherPageShimmer