import React, { useEffect, useRef, useState } from 'react'
import { Button, Heading, Modal, Paragraph, ToastProps, ToastWrapper } from '../../ui'
import { COLORS } from '../../constants/colors';
import { copyTextToClipboard, formatInToPrice } from '../../utils/misc';
import ReactToPrint from 'react-to-print';
import { SvgArrowBack, SvgCheckMarkGreen, SvgChevronLeft, SvgFluentPrint28Regular } from '../../ui/icons';
import ReceiptPrintView from '../appointmentComponents/modals/ReceiptPrintView';
import { ReceiptPrintViewProps } from '../appointmentComponents/types';
import { useUserCache } from '../../hooks/useUserCache';
import { Appointment, EmailAppointmentReceiptDocument, EmailSaleReceiptDocument, Sale, SalesOverviewByService } from '../../graphql/generated';
import { print } from 'graphql';
import axios from 'axios';
import { API_ERRORS } from '../../constants/errors';
import { fetchAppointmentById, fetchSaleById } from '../appointmentComponents/api';
import { SPLICE_RECEIPT_LINK, SPLICE_SALE_RECEIPT_LINK } from '../../constants/information';
import { useSalonCache } from '../../hooks/useSalonCache';
import { SaleReceiptPrintViewProps } from '../../modals/types';
import SaleReceiptPrintView from '../../modals/SaleReceiptPrintView';
import { useToast } from '../../hooks';
import { formatSaleStatusPill } from './StatusUtils';

type ClientBookingModalProps = {
  isOpen: boolean;
  onClose: (action?: string) => void;
  bookingOrSaleData?: SalesOverviewByService | null
};

class AppointmentComponentToPrint extends React.Component<ReceiptPrintViewProps> {
  render() {
    const { appointment, business, hasMadePayment } = this.props;
    return (
      <ReceiptPrintView appointment={appointment} business={business} hasMadePayment={hasMadePayment} />
    );
  }
}

class SaleComponentToPrint extends React.Component<SaleReceiptPrintViewProps> {
  render() {
    const { sale, business } = this.props;
    return (
      <SaleReceiptPrintView sale={sale} business={business} />
    );
  }
}
const  ViewSaleReportModal = ({
  isOpen,
  onClose,
  bookingOrSaleData,
}: ClientBookingModalProps) => {
  const { toast, addToast } = useToast()
  const { getSalonFieldValue } = useSalonCache();
  const { getBusinessData } = useUserCache();
  const business = getBusinessData();
  const appointmentComponentRef = useRef<AppointmentComponentToPrint>(null);
  const saleComponentRef = useRef<SaleComponentToPrint>(null);
  const salonId = getSalonFieldValue('id');
  const [isLoading, setIsLoading] = useState(false)
  const [appointment, setAppointment] = useState<Appointment | null>()
  const [sale, setSale] = useState<Sale | null>()

  const getAppointmentDetailsAsync = async () => {
    setIsLoading(true)
    const appointmentDetailsData = await fetchAppointmentById({
      salonId,
      id: bookingOrSaleData?.appointmentId as string
    })
    if (!appointmentDetailsData) {
      setIsLoading(false)
      return
    }
    setIsLoading(false)
    setAppointment(appointmentDetailsData)
  }

  const getSaleDetailsAsync = async () => {
    setIsLoading(true)
    const saleDetailsData = await fetchSaleById({
      id: bookingOrSaleData?.saleId as string
    })
    if (!saleDetailsData) {
      setIsLoading(false)
      return
    }
    setIsLoading(false)
    setSale(saleDetailsData)
  }

  useEffect(() => {
    if (isOpen && bookingOrSaleData) {
      if (bookingOrSaleData?.saleType === 'appointment') {
        getAppointmentDetailsAsync()
      } else if (bookingOrSaleData?.saleType === 'sale') {
        getSaleDetailsAsync()
      }
    }
  }, [isOpen])

  const copyLink = () => {
    copyTextToClipboard(bookingOrSaleData?.saleType === 'appointment' ? `${SPLICE_RECEIPT_LINK}${appointment?.bookingId}` : `${SPLICE_SALE_RECEIPT_LINK}${sale?.id}`);
    addToast({
      variant: "success",
      message: 'Receipt link copied to clipboard',
    });
  };

  const emailReceiptToCustomer = async () => {
    try {
      setIsLoading(true)
      await axios.post(
        '/graphql',
        {
          query: print(EmailAppointmentReceiptDocument),
          variables: { input: { appointmentId: appointment?.id } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        setIsLoading(false)
        if (data?.data?.emailAppointmentReceipt?.status === 200) {
          addToast({
            message: "Email sent successfully",
            variant: 'success',
          })
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.FAILED_TO_MAIL_CUSTOMER;
          addToast({
            variant: 'error',
            message,
          })
        }


        if (data?.data?.emailAppointmentReceipt?.errors?.length) {
          const message = data?.data?.emailAppointmentReceipt?.errors[0]?.message || API_ERRORS.FAILED_TO_MAIL_CUSTOMER;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (createProductError) {
      setIsLoading(false)
      if (axios.isAxiosError(createProductError)) {
        const message = createProductError?.response?.data?.message || API_ERRORS.FAILED_TO_MAIL_CUSTOMER;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const emailSaleReceiptToCustomer = async () => {
    try {
      setIsLoading(true)
      await axios.post(
        '/graphql',
        {
          query: print(EmailSaleReceiptDocument),
          variables: { input: { salonId, id: sale?.id } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        setIsLoading(false)
        if (data?.data?.emailSaleReceipt?.status === 200) {
          addToast({
            message: "Email sent successfully",
            variant: 'success',
          })
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.FAILED_TO_MAIL_CUSTOMER;
          addToast({
            variant: 'error',
            message,
          })
        }


        if (data?.data?.emailSaleReceipt?.errors?.length) {
          const message = data?.data?.emailSaleReceipt?.errors[0]?.message || API_ERRORS.FAILED_TO_MAIL_CUSTOMER;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (createProductError) {
      setIsLoading(false)
      if (axios.isAxiosError(createProductError)) {
        const message = createProductError?.response?.data?.message || API_ERRORS.FAILED_TO_MAIL_CUSTOMER;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const onCloseModal = () => {
    setAppointment(null)
    setSale(null)
    onClose()
  }

  return (
    <>
      <Modal
        show={isOpen}
        closeModal={onCloseModal}
        variant='right'
      >
        <>
          <ToastWrapper toast={toast as ToastProps} />
          {bookingOrSaleData ? (
            <div className='w-full relative my-[80px]'>
              <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
                <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
                  <Button
                    variant='text'
                    size='none'
                    type='button'
                    className='w-fit'
                    fontSize='b4'
                    onClick={onCloseModal}
                  >
                    <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
                  </Button>
                </div>
                <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
                  <Button
                    variant='icon'
                    size='square'
                    type='button'
                    rounded='md'
                    className='w-fit'
                    fontSize='b4'
                    onClick={onCloseModal}
                  >
                    <SvgChevronLeft width='24px' height='24px' />
                  </Button>
                  <Paragraph size='b3' weight='bold'>
                    Sale details
                  </Paragraph>
                </div>
              </div>
              <div className='w-full flex justify-between items-start px-6 py-4'>
                <div className='w-full xl:w-1/2 flex flex-col space-y-6'>
                  <Heading variant='h1' size='h9' weight='semiBold' className='flex gap-3 items-center'>
                    Sale details {formatSaleStatusPill(bookingOrSaleData?.saleStatus)}
                  </Heading>
                  <Paragraph size='b4'>
                    Find information about sale here
                  </Paragraph>
                </div>

                {bookingOrSaleData?.saleType !== 'voucher' && bookingOrSaleData?.saleStatus !== 'paid' && bookingOrSaleData?.saleStatus !== 'void' ?
                  <Button variant='secondary' rounded='md' size='sm' fontSize='b5' fontWeight='semiBold' onClick={() => onClose('edit')} disabled={isLoading}>
                    {bookingOrSaleData?.saleType === 'appointment' || (bookingOrSaleData?.saleType === 'sale' && sale && sale?.amountClientPaid === 0) ? 'Edit details' : 'Pay now'}
                  </Button> : null}
              </div>
              {bookingOrSaleData?.saleType === 'appointment' && bookingOrSaleData?.paymentMethod === 'voucher' ?
                <div className='w-full px-6'>
                  <div className='w-full flex p-4 rounded-md bg-green-75 space-x-4 items-start'>
                    <SvgCheckMarkGreen width='24px' height='24px' />
                    <Paragraph>This sale was paid for using a voucher</Paragraph>
                  </div>
                </div> : null}
              <div className='w-full px-6 py-4'>
                <div className='w-full flex flex-col my-12 space-y-2 border border-grey-50 rounded-md p-4'>
                  <div className='flex justify-between items-center bg-grey-10 p-2'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Item</Paragraph>
                    <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.service}</Paragraph>
                  </div>
                  {sale || appointment ?
                    <div className='flex justify-between items-center bg-grey-10 p-2'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Staff</Paragraph>
                      <Paragraph size='b4' color={COLORS.BLACK}>
                        {sale 
                          ? sale.saleStaff?.map((saleStaff) => saleStaff?.salonStaff?.user?.firstName).join(', ') 
                          : appointment 
                            ? appointment.appointmentServices?.map((service) => service.appointmentServiceStaff?.map((staff) => staff?.salonStaff?.user?.firstName).join(', ')).join(', ')
                            : "-"
                        }
                      </Paragraph>
                    </div> : null}
                  <div className='flex justify-between items-center bg-grey-10 p-2'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Quantity</Paragraph>
                    <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.itemSold || "-"}</Paragraph>
                  </div>
                  <div className='flex justify-between items-center bg-grey-10 p-2'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Subtotal</Paragraph>
                    <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(bookingOrSaleData?.grossSales)}</Paragraph>
                  </div>
                  <div className='flex justify-between items-center bg-grey-10 p-2'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Discount</Paragraph>
                    <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(bookingOrSaleData?.discount)}</Paragraph>
                  </div>
                  <div className='flex justify-between items-center bg-grey-10 p-2'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Refunds</Paragraph>
                    <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(bookingOrSaleData?.refunds)}</Paragraph>
                  </div>
                  <div className='flex justify-between items-center bg-grey-10 p-2'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Tax</Paragraph>
                    <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(bookingOrSaleData?.tax)}</Paragraph>
                  </div>
                  {/* <div className='flex justify-between items-center bg-grey-10 p-2'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Total to be paid</Paragraph>
                    <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(bookingOrSaleData?.netSales)}</Paragraph>
                  </div> */}
                  {bookingOrSaleData?.saleStatus === 'partially_paid' && sale ? (
                    <>
                      <div className='flex justify-between items-center bg-grey-10 p-2'>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Total to be paid</Paragraph>
                        <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(bookingOrSaleData?.totalSales)}</Paragraph>
                      </div>
                      <div className='flex justify-between items-center bg-grey-10 p-2'>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Amount paid</Paragraph>
                        <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(sale?.amountClientPaid)}</Paragraph>
                      </div>
                      <div className='flex justify-between items-center bg-grey-10 p-2'>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Balance</Paragraph>
                        <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(bookingOrSaleData?.totalSales - sale?.amountClientPaid)}</Paragraph>
                      </div>
                    </>
                  ) : bookingOrSaleData?.saleStatus === 'unpaid' ? (
                    <div className='flex justify-between items-center bg-grey-10 p-2'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Total to be paid</Paragraph>
                      <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(bookingOrSaleData?.totalSales)}</Paragraph>
                    </div>
                  ) : (
                    <div className='flex justify-between items-center bg-grey-10 p-2'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Total paid</Paragraph>
                      <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(bookingOrSaleData?.totalSales)}</Paragraph>
                    </div>
                  )}
                </div>
              </div>
              {bookingOrSaleData?.saleType !== 'voucher' ? (
                <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
                  <div className='w-full flex justify-between py-4 px-8'>
                    <ReactToPrint
                      trigger={() => <Button variant='text' size='sm' fontSize='b5' fontWeight='semiBold'>
                        <SvgFluentPrint28Regular width='20px' height='20px' /> Print receipt
                      </Button>}
                      content={() => bookingOrSaleData?.saleType === 'sale' ? saleComponentRef.current : appointmentComponentRef.current}
                    />
                    <div className='flex space-x-4'>
                      <Button variant='secondary' rounded='md' size='sm' fontSize='b5' fontWeight='semiBold' onClick={copyLink}>
                        Copy link
                      </Button>
                      <Button variant='primary' rounded='md' size='sm' fontSize='b5' fontWeight='semiBold' onClick={bookingOrSaleData?.saleType === 'appointment' ? emailReceiptToCustomer : emailSaleReceiptToCustomer} disabled={isLoading} loading={isLoading}>
                        Email
                      </Button>
                    </div>
                  </div>
                </div>) : null}
            </div>
          ) : null}
        </>
      </Modal>
      {isOpen ?
        <AppointmentComponentToPrint ref={appointmentComponentRef}
          appointment={appointment}
          business={business}
          hasMadePayment=''
        /> : null}
      {isOpen ?
        <SaleComponentToPrint ref={saleComponentRef}
          sale={sale}
          business={business}
        /> : null}
    </>
  )
}

export default ViewSaleReportModal