import { TableOptions } from "./types";

export const isStatusActive = (statusObj) => {
  return statusObj && statusObj.props && statusObj.props.children === 'Active';
};

export const formatTableOptions = (tableOptions: TableOptions, isActive) => {
  if (!tableOptions) return;

  if (!tableOptions.activate && !tableOptions.deactivate) {
    return tableOptions;
  }

  if (tableOptions.activate && tableOptions.deactivate) {
    return {
      ...tableOptions,
      deactivate: isActive ? true : false,
      activate: isActive ? false : true
    };
  }
}