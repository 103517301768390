import axios from 'axios';
import React, { useEffect, useState } from 'react'
// import { useSalonCache } from '../../../hooks/useSalonCache';
import { useToast } from '../../../hooks/useToast';
import { useModal } from '../../../hooks/useModal';
import { PageProps } from '../types';
import { API_ERRORS } from '../../../constants/errors';
import { COLORS } from '../../../constants/colors';
import AccountSetupArrayShimmer from '../../../ui/organism/accountSetupArrayShimmer/accountSetupArrayShimmer';
import Heading from '../../../ui/atoms/heading/Heading';
import SvgTrash from '../../../ui/icons/Trash';
import SvgEdit from '../../../ui/icons/Edit';
import Paragraph from '../../../ui/atoms/paragraph/Paragraph';
import AccountSetupTemplate from '../AccountSetupTemplate';
import ToastWrapper from '../../../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../../../ui/atoms/toast/types';
import Button from '../../../ui/atoms/button/Button';
import SvgPlus from '../../../ui/icons/Plus';
import { DeletePosDeviceDocument, MarkOnboardingAsCompleteDocument, PosDevice, PosDevicesDocument, UserDocument } from '../../../graphql/generated';
import { print } from 'graphql';
import { useSalonCache } from '../../../hooks/useSalonCache';
import { PAGE_ROUTES } from '../../../constants/routes';
import { useUserCache } from '../../../hooks/useUserCache';
import AddOrEditPosDevice from '../../../modals/AddOrEditPosDevice';

const PosSetup = ({ onPrevPage }: PageProps) => {
  const [selectedPos, setSelectedPos] = useState<PosDevice | null>(null);
  const {
    isVisible,
    openModal,
    closeModal,
  } = useModal();
  const { addToast, toast } = useToast();
  const { getSalonFieldValue } = useSalonCache();
  const { skipSetUp } = useUserCache();
  const salonId = getSalonFieldValue('id');
  const [posDevices, setPosDevices] = useState<PosDevice[] | null>(null);
  const [posDevicesLoading, setPosDevicesLoading] = useState(false)
  const { setSalonData } = useSalonCache()
  const { setBusinessData } = useUserCache()
  const [buttonLoading, setButtonLoading] = useState(false)

  const showInitialLoadingShimmer = posDevicesLoading && !posDevices && salonId;

  const getPosDevices = async () => {
    setPosDevicesLoading(true);
    axios
      .post(
        '/graphql',
        {
          query: print(PosDevicesDocument),
          variables: { salonId }
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { posDevices }
          }
        } = res
        setPosDevicesLoading(false);
        setPosDevices(posDevices);
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
        setPosDevicesLoading(false);
      })
  }

  useEffect(() => {
    getPosDevices()
  }, [salonId])

  const deletePos = (posId: string) => async () => {
    try {
      await axios.post(
        '/graphql',
        {
          query: print(DeletePosDeviceDocument),
          variables: { input: { id: posId, salonId } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        if (data?.data?.deletePosDevice?.status === 200) {
          addToast({
            message: 'POS Device deleted successfully',
            variant: 'success',
          })
          getPosDevices();
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.SALON_POS_DELETION_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }


        if (data?.data?.deletePosDevice?.errors?.length) {
          const message = data?.data?.deletePosDevice?.errors[0]?.message || API_ERRORS.SALON_POS_DELETION_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (deletePosError) {
      if (axios.isAxiosError(deletePosError)) {
        const message = deletePosError?.response?.data?.message || API_ERRORS.SALON_POS_DELETION_FAILED;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const editPos = (posMachineId: string) => () => {
    const posMachine = posDevices?.find((posMachine) => posMachine?.id === posMachineId);
    if (!posMachine) return;
    setSelectedPos(posMachine);
    openModal();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getUser = async () => {
    axios
      .post(
        '/graphql',
        { query: print(UserDocument) },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then((res) => {
        localStorage.setItem('userData', JSON.stringify(res.data.data.user))
        const s = res?.data?.data?.user?.business?.salons?.find((x) => x?.id === salonId)
        setSalonData(s)
        setBusinessData(res.data.data.user.business)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const redirectToHome = async () => {
    setButtonLoading(true)
    axios
      .post(
        '/graphql',
        {
          query: print(MarkOnboardingAsCompleteDocument),
          variables: { input: { salonId } },
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then((res) => {
        setButtonLoading(false)
        if (res.data.data.markOnboardingAsComplete.status === 200) {
          window.location.href = PAGE_ROUTES.HOME
        }
      })
      .catch((err) => {
        setButtonLoading(false)
        console.log(err)
      })
  }

  const getPosContent = () => {
    if (showInitialLoadingShimmer) {
      return <AccountSetupArrayShimmer />
    }
    if (Array.isArray(posDevices) && posDevices.length) {
      return (
        <>
          {posDevices.map((pos) => (
            <div className='flex items-center justify-between p-2 border-b border-grey-20'>
              <div className='flex flex-col space-y-1'>
                <Heading variant='h2' size='h10' weight='normal' className='text-black'>{pos?.name}</Heading>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]} className=''>{pos?.bankName}</Paragraph>
              </div>
              <div className='flex space-x-6'>
                <SvgEdit width="24px" height="24px" className='cursor-pointer' onClick={editPos(pos?.id)} />
                <SvgTrash width="24px" height="24px" className='cursor-pointer' onClick={deletePos(pos?.id)} />
              </div>
            </div>
          ))}
        </>
      )
    }

    return (
      <div className='flex w-full justify-center items-center py-4'>
        <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]} className=''>You haven't added any point of sale terminal yet.</Paragraph>
      </div>
    )
  }

  return (
    <>
      <AccountSetupTemplate
        title='Point-of-Sale Terminals (POS)'
        subtitle='Add Point-of-Sale Terminals'
        skip={{
          text: 'Skip Business Setup',
        }}
      >
        <ToastWrapper toast={toast as ToastProps} />
        <div className='flex space-x-4'>
          <Button
            variant='primary'
            className=''
            size='sm'
            rounded='md'
            type='button'
            onClick={openModal}
          >
            <SvgPlus width="24px" height="24px" color='text-white' />
            Add Point-of-Sale Terminal (POS)
          </Button>
        </div>
        <div className='w-full flex flex-col space-y-2 max-h-[400px] overflow-y-scroll'>
          {getPosContent()}
        </div>
        <div className='w-full flex space-x-2'>
          <Button
            variant='secondary'
            className=''
            disabled={false}
            onClick={onPrevPage}
            size='lg'
            rounded='lg'
            type='button'
          >
            Prev
          </Button>
          <Button
            variant='primary'
            className=''
            disabled={buttonLoading}
            loading={buttonLoading}
            onClick={() => {
              redirectToHome();
              skipSetUp({
                skip: true
              })
            }}
            size='lg'
            rounded='lg'
          >
            Finish
          </Button>
        </div>
      </AccountSetupTemplate>
      <AddOrEditPosDevice
        isVisible={isVisible}
        closeModal={closeModal}
        addToast={addToast}
        refetch={getPosDevices}
        pos={selectedPos}
      />
    </>
  )
}

export default PosSetup
