import * as React from 'react'
import { Logo } from '../ui/atoms/logo'
import axios from 'axios'
import Paragraph from '../ui/atoms/paragraph/Paragraph'
import { COLORS } from '../constants/colors'
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper'
import { useToast } from '../hooks'
import OtpInput from '../ui/molecules/input/OtpInput'
import { OTP_COUNTER } from '../constants/otpCounter'
import Button from '../ui/atoms/button/Button'
import { print } from 'graphql'
import {
  ResendOtpCodeDocument,
  VerifyOtpCodeDocument
} from '../graphql/generated'
import FullStory from 'react-fullstory'

const Otp = () => {
  const [otp, setOtp] = React.useState('')
  const [disabled, setDisabled] = React.useState(true)
  const [loading, setLoading] = React.useState(false)

  const { addToast, toast } = useToast()
  const email = JSON.parse(localStorage.getItem('userData'))?.email

  const handleChange = (otp: string) => {
    setOtp(otp)
    if (otp.length === OTP_COUNTER.INPUTS) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const resendOtpCode = async () => {
    setDisabled(true)
    setLoading(true)
    await axios.post(
      '/graphql',
      { query: print(ResendOtpCodeDocument) },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    )
    setLoading(false)
  }

  const verifyOtp = async () => {
    setLoading(true);
    const {
      data: {
        data: { verifyOtpCode }
      }
    } = await axios.post(
      '/graphql',
      {
        query: print(VerifyOtpCodeDocument),
        variables: { input: { otpCode: otp } }
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    )

    if (verifyOtpCode.status === 200) {
      window.location.href = '/account-setup'
    } else {
      addToast({ variant: 'error', message: verifyOtpCode.errors[0].message })
      setLoading(false);
    }
  }

  const logout = () => {
    localStorage.removeItem('token')
    window.location.href = '/'
  }
	// @ts-expect-error Env variables has been set in the application.html.erb
	const ORG_ID = envVariables.fullstoryOrgId

  return (
    <>
      <FullStory org={ORG_ID} />
      <main className='flex items-center justify-center h-screen bg-white xl:bg-grey-100'>
        <div className='table-row'>
          <div className='table-cell align-middle'>
            <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-8 px-9 max-w-[400px] xl:max-w-[500px]'>
              <div className='flex flex-col items-center space-y-9'>
                <Logo />
                <Paragraph
                  size='b5'
                  weight='medium'
                  className='w-full text-center max-w-[400px]'
                  color={COLORS.GREY[400]}
                >
                  A One-time Passcode (OTP) has been sent to {email}. Enter code
                  below.
                </Paragraph>
                <ToastWrapper toast={toast} />
                <OtpInput
                  value={otp}
                  id="otp-code-input"
                  onChange={handleChange}
                  numInputs={OTP_COUNTER.INPUTS}
                  separator={<span></span>}
                  isInputNum={true}
                  className='custom-otp-input'
                />

                <div className='w-full flex justify-center space-x-2'>
                  <Paragraph size='b5' className='' color={COLORS.GREY[400]}>
                    Haven’t received an OTP?
                  </Paragraph>
                  <Button
                    variant='text'
                    className='text-b5 mx-0'
                    fontWeight='bold'
                    size='none'
                    type='button'
                    onClick={resendOtpCode}
                  >
                    Resend OTP
                  </Button>
                </div>

                <Button
                  variant='primary'
                  className=''
                  disabled={loading || disabled}
                  loading={loading}
                  size='lg'
                  rounded='lg'
                  onClick={verifyOtp}
                >
                  Submit
                </Button>
                <Button
                  variant='transparent'
                  className='mx-auto'
                  disabled={loading}
                  loading={loading}
                  size='none'
                  onClick={logout}
                >
                  Logout
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Otp
