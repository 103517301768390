export const isObject = (obj?: Record<string, number>): boolean => {
  return obj instanceof Object;
};

type BaseRecord = Record<string, string>;
export const groupArrayBy = (items: BaseRecord[], field: string) => {
  const groupedItems: Record<string, BaseRecord[]> = {};

  items.forEach((item) => {
    const value = item[field];

    if (groupedItems[value]) {
      groupedItems[value].push(item);
    } else {
      groupedItems[value] = [item];
    }
  });

  return groupedItems;
};

export const getLastItemFromList = (list: unknown) => {
  if (Array.isArray(list)) {
    const [lastItem] = [...list].reverse();
    return lastItem;
  }
  return;
};
