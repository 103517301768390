import { countries, Country } from "countries-list";
import { ReactFlagsCustomLabels, CountryAlphaTwoCode } from "./types";

export const REACT_FLAGS_COUNTRY_CODES: ReactFlagsCustomLabels = {};

Object.keys(countries)?.forEach((alpha2Code: string) => {
  const country = countries[alpha2Code as CountryAlphaTwoCode] as Country;
  REACT_FLAGS_COUNTRY_CODES[alpha2Code] = {
    primary: country?.name,
    secondary: `+${country.phone}`,
  };
});
const country = JSON.parse(localStorage.getItem('country'));
export const REACT_FLAGS_DEFAULT_COUNTRY_CODE = country?.code;
