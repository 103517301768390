import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLink = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={16} fill="#E2E8E9" fillOpacity={0.2} />
    <path
      d="M23.875 13.204c-.028.979-.431 1.91-1.125 2.601l-2.443 2.445a3.817 3.817 0 0 1-2.717 1.125h-.003a3.842 3.842 0 0 1-3.837-3.949.563.563 0 0 1 1.125.032 2.716 2.716 0 0 0 4.633 1.997l2.443-2.444a2.716 2.716 0 0 0-3.841-3.84l-.773.773a.563.563 0 0 1-.796-.796l.774-.773a3.844 3.844 0 0 1 6.56 2.829Zm-9.211 6.85-.774.774a2.7 2.7 0 0 1-1.926.797 2.716 2.716 0 0 1-1.919-4.636l2.44-2.444a2.716 2.716 0 0 1 4.64 1.997.563.563 0 0 0 1.125.032 3.857 3.857 0 0 0-1.125-2.824 3.843 3.843 0 0 0-5.433 0L9.25 16.193a3.84 3.84 0 0 0 5.428 5.432l.773-.773a.563.563 0 0 0-.787-.798Z"
      fill="#667368"
    />
  </svg>
);
export default SvgLink;
