import { useCallback } from 'react'
import { Salon } from '../graphql/generated'
import { LOCAL_STORAGE_KEYS } from '../constants/information'

export const useSalonCache = () => {
  const setSalonData = useCallback((salon: Salon) => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.APP_SPLICE_SALON,
      JSON.stringify(salon)
    )
  }, [])

  const setSalons = useCallback((salons: Salon[]) => {
    localStorage.setItem('salons', JSON.stringify(salons))
  }, [])

  const getSalons = useCallback(() => {
    const salons = localStorage.getItem('salons')
    return JSON.parse(salons) || null
  }, [])

  const getSalonData = useCallback(() => {
    const salon = localStorage.getItem(LOCAL_STORAGE_KEYS.APP_SPLICE_SALON) as string

    if (salon === undefined || salon === 'undefined') {
      localStorage.clear()
      window.location.href = '/'
    }
    if (salon) return JSON.parse(salon)
  }, [])

  const getSalonFieldValue = useCallback(
    (field: keyof Salon) => {
      return getSalonData()?.[field]
    },
    [getSalonData]
  )

  return {
    setSalonData,
    getSalonData,
    getSalonFieldValue,
    getSalons,
    setSalons
  }
}
