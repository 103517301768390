/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useContext, useEffect, useState } from "react";
import BookContext from "./context";
import { BOOKING_PAGE_TABS, BOOKING_PAGE_TAB_NAME, TIME_AND_DATE, USER_DETAILS_STEP, VOUCHER_SERVICE_SELECTION, STAFF_SELECTION } from "./constants";
import { useModal } from "../../hooks/useModal";
import { convertSalonServicesNamesToString, groupWorkingHoursByDay, showPackageDetails } from "./utils";
import { RedeemVoucherCloseProp } from "./types";
import React from "react";
import BusinessNotFound from "./BusinessNotFound";
import Heading from "../../ui/atoms/heading/Heading";
import Paragraph from "../../ui/atoms/paragraph/Paragraph";
import { COLORS } from "../../constants/colors";
import Button from "../../ui/atoms/button/Button";
import Disclaimer from "./Disclaimer";
import { Listbox, Transition } from "@headlessui/react";
import SvgLocation from "../../ui/icons/Location";
import Tabs from "../../ui/molecules/tabs/Tabs";
import SvgArrowUp from "../../ui/icons/ArrowUp";
import SvgArrowDown from "../../ui/icons/ArrowDown";
import SvgCartBag from "../../ui/icons/CartBag";
import { formatInToPrice, formatTime, getHoursAndMinutesString, limitString } from "../../utils/misc";
import SvgGreyPlus from "../../ui/icons/GreyPlus";
import PackageBookingCard from "./PackageBookingCard";
import { FormatNumber } from "../../ui/atoms/formatNumber/FormatNumber";
import { SvgArrowRight, SvgClose, SvgFaqQuestion, SvgFluentSearch20Regular, SvgGreyMinus, SvgWhiteShoppingBag } from "../../ui/icons";
import BookingRedeemVoucherModal from "./modals/BookingRedeemVoucherModal";
import { BookingPackage } from "../../modals/types";
import { BookingSiteSearch, ParagraphShimmer, Skeleton } from "../../ui";
import { Carousel } from "rsuite";

export const BusinessInfo = () => {
  const { formattedServices: services, loadingServices, selectService, setVoucherServices, activeTab, setActiveTab, showOrHideCategory, handleNextStep, selectedServices, businessInfo, salons, selectedSalon, setSelectedSalon, formattedPackages, modifyPackageInCart, selectedPackages, setVoucherToBeRedeemed, addToast, setServiceClient, openVoucherRedemptionModal, setOpenVoucherRedemptionModal, debouncedSearchQuery, searchCount, closeSearch, openSearchWrapper, setDebouncedSearchQuery, searchWrapperIsOpen, faqs } = useContext(BookContext);
  const [salonPackage, setSalonPackage] = useState<BookingPackage | null>(null);
  const [selectedPackageDetails, setSelectedPackageDetails] = useState<{ label: string, value: string }[] | null>(null);
  const [cartIsEmpty, setCartIsEmpty] = useState(true);
  const tabs = formattedPackages.length === 0 ? [BOOKING_PAGE_TABS[0]] : BOOKING_PAGE_TABS;
  const {
    isVisible: isRedeemVoucherModalVisible,
    openModal: openRedeemVoucherPage,
    closeModal: closeRedeemVoucherPage,
  } = useModal();

  useEffect(() => {
    if (salonPackage) {
      const salonPackageDetails = showPackageDetails(salonPackage);
      setSelectedPackageDetails(salonPackageDetails)
    }
  }, [salonPackage])

  useEffect(() => {
    setCartIsEmpty(true)
    if (Array.isArray(selectedServices) && selectedServices?.length > 0) {
      setCartIsEmpty(false)
    }
    if (Array.isArray(selectedPackages) && selectedPackages?.length > 0) {
      setCartIsEmpty(false)
    }
  }, [selectedServices, selectedPackages])

  const initiateCloseRedeemVoucherPage = (data?: RedeemVoucherCloseProp) => {
    setOpenVoucherRedemptionModal(false);
    closeRedeemVoucherPage();
    setVoucherToBeRedeemed(data?.voucher || null);
    setServiceClient(data?.client || null);
    const formattedVoucherServices = Array.isArray(data?.voucher?.voucherServices) ? data?.voucher?.voucherServices.map(service => ({ 
      ...service, 
      cartQuantity: 1
    })) : [];
    setVoucherServices(formattedVoucherServices)
    if (data?.client && data?.voucher) {
      handleNextStep(VOUCHER_SERVICE_SELECTION);
    }
  }

  useEffect(() => {
    if (openVoucherRedemptionModal) {
      openRedeemVoucherPage()
    }
  }, [openRedeemVoucherPage, openVoucherRedemptionModal])

  const goToFaqsSection = () => {
    // use react scroller
    scrollTo({
      top: document.getElementById('faq-section')?.offsetTop,
      behavior: 'smooth',
    });
  }
  return (
    <>
      {!businessInfo ? (
        <BusinessNotFound />
      ) : (
        <>
          <div className='w-full flex flex-row justify-between xl:gap-5 p-0 lg:pl-4 xl:pl-[100px] min-h-[300px] lg:min-h-[600px] border-b border-grey-20'>
            <div className='w-full hidden lg:flex flex-col space-y-6 max-w-[450px] justify-center md:pb-4'>
              {/* business name, hours */}
              <div className='flex flex-col space-y-6'>
                <Heading variant='h1' size='h5' weight='bold'>{businessInfo?.name}</Heading>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{selectedSalon?.address}, {selectedSalon?.city}, {selectedSalon?.state}</Paragraph>
                <div className='flex flex-col'>
                  <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{businessInfo?.about}</Paragraph>
                </div>
                <div className="w-full flex flex-col space-y-1">
                  <Heading size='h11' variant="h2" weight='bold' color={COLORS.GREY['300']}>Opening Days</Heading>
                  {Array.isArray(selectedSalon?.salonHours) && selectedSalon?.salonHours?.length && groupWorkingHoursByDay(selectedSalon?.salonHours).map((day, index) => day?.closeTime && day?.openTime ? (
                    <div key={index} className='flex justify-between items-center'>
                      <Paragraph size="b5" color={COLORS.GREY[300]} weight='semiBold'>{day?.day.charAt(0).toUpperCase() + day?.day.slice(1)}</Paragraph>
                      <Paragraph size="b5" color={COLORS.GREY[300]}>{formatTime(day?.openTime)} - {formatTime(day?.closeTime)}</Paragraph>
                    </div>
                  ) : null )}
                </div>
                {Array?.isArray(faqs) && faqs?.length ?
                  <Button
                    variant='light'
                    size='xs'
                    rounded='lg'
                    type="button"
                    className='w-fit border border-grey-100 text-grey-300'
                    onClick={goToFaqsSection}
                  >
                    <SvgFaqQuestion width="14px" height="14px" />
                    Check out frequently asked questions
                  </Button> : null}
                {formattedPackages.length > 0 ? (
                  <div className="w-full flex gap-1">
                    <Paragraph size='b4' color={COLORS.GREY[300]} className='italic'>Have a voucher?</Paragraph>
                    <Button
                      variant='text'
                      size='xs'
                      rounded='none'
                      fontWeight='semiBold'
                      className="underline text-grey-300"
                      onClick={openRedeemVoucherPage}
                    >
                      Redeem a voucher
                    </Button>
                  </div>
                ) : null}
              </div>
              {/* disclaimers */}
              <Disclaimer title="Cancellation Policy" type='orange' message={businessInfo?.cancellationPolicy} />
              {!businessInfo?.acceptsOnlineBookings ? (
                <Disclaimer title="Online Appointment" type='grey' message={`This business doesn’t accept online appointments, please contact them on ${selectedSalon?.callingCode + "" + selectedSalon?.phone}`} />
              ) : null}
              {/* select location */}
              <div className='flex justify-between items-center space-y-2 py-4 px-6 bg-white border border-green-600 shadow-md rounded-lg'>
                <Listbox value={selectedSalon} onChange={setSelectedSalon}>
                  <div className="relative mt-1 w-full">
                    <div className="flex flex-col w-full">
                      <Listbox.Button
                        className="relative w-full cursor-pointer bg-transparent flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-b6"
                      >
                        <div className='flex space-x-2'>
                          <SvgLocation width="20px" height="20px" />
                          <div className='flex flex-col justify-start space-y-1'>
                            <span className='text-grey-400 text-b6'>Select location</span>
                            <span className="text-left">{
                              selectedSalon?.branchName || 'Pick a location'
                            }</span>
                          </div>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <Button
                              variant='text'
                              size='none'
                              className='text-green-700 border-b border-green-700 text-b6'
                            >Change</Button>
                          </div>
                        </div>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          className="absolute max-h-60 w-full overflow-auto rounded-sm bg-white py-1 text-b4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-b6"
                          style={{
                            top: 50,
                            bottom: 'auto',
                          }}
                        >
                          {salons?.map((salon, salonIdx) => (
                            <Listbox.Option
                              key={`salon${salonIdx}`}
                              className={({ active }) =>
                                `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-grey-900 text-white' : 'text-grey-900'
                                }`
                              }
                              value={salon}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {salon?.branchName}
                                  </span>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </div>
                </Listbox>
              </div>
            </div>
            {Array?.isArray(businessInfo?.headerImageUrls) && businessInfo?.headerImageUrls?.length ? (
              <div className='relative flex flex-col w-full lg:max-w-[600px] h-[300px] md:h-[450px] lg:h-full'>
                <Carousel
                  key={`bottom.dot`}
                  placement="bottom"
                  shape="dot"
                  className="h-full custom-slider"
                  autoplay
                >
                  {businessInfo?.headerImageUrls.map((image) => {
                    return (
                      <img src={image?.imageUrl} className="w-full object-cover" loading="lazy" alt="header image" />
                    )
                  })}
                </Carousel>
                <div className="w-full absolute bottom-0 pb-[50px] flex lg:hidden flex-col px-4">
                  <Heading variant='h1' size='h9' weight='bold' color={COLORS.WHITE}>{businessInfo?.name}</Heading>
                </div>
              </div>
            ) : null}
          </div>
          <div className='flex lg:hidden flex-col p-4 space-y-4'>
            <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{businessInfo?.about}</Paragraph>
            <div className="w-full flex gap-3 items-center">
              {formattedPackages.length > 0 ? (
                <div className="w-full flex gap-1">
                  <Paragraph size='b4' color={COLORS.GREY[300]} className='italic'>Have a voucher?</Paragraph>
                  <Button
                    variant='text'
                    size='xs'
                    rounded='none'
                    fontWeight='semiBold'
                    className="underline text-grey-300"
                    onClick={openRedeemVoucherPage}
                  >
                    Redeem a voucher
                  </Button>
                </div>
              ) : null}
              {Array?.isArray(faqs) && faqs?.length ?
                <Button
                  variant='light'
                  size='xs'
                  rounded='lg'
                  type="button"
                  className='w-fit border border-grey-100 text-grey-300'
                  onClick={goToFaqsSection}
                >
                  <SvgFaqQuestion width="14px" height="14px" />
                  FAQs
                </Button> : null}
            </div>
            <Disclaimer title="Cancellation Policy" type='orange' message={businessInfo?.cancellationPolicy} />
            {!businessInfo?.acceptsOnlineBookings ? (
              <Disclaimer title="Online Appointment" type='grey' message={`This business doesn’t accept online appointments, please contact them on ${selectedSalon?.callingCode + "" + selectedSalon?.phone}`} />
            ) : null}
          </div>

          <div className='flex flex-col gap-y-4 w-full px-4 xl:px-[100px]'>
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              variant='underline'
              fontWeight="semiBold"
            />
            {activeTab === BOOKING_PAGE_TAB_NAME.SERVICES ? (
              <>
                <p className="text-b5 text-white py-[6px] px-5 font-semibold bg-green-300 rounded-full w-fit">All ({services?.length})</p>    
                <div className="w-full border-t border-grey-100 flex justify-between py-2 items-center">
                  <Paragraph size='b2' color={COLORS.GREY[900]} weight="semiBold">All</Paragraph>
                  {!searchWrapperIsOpen ?
                    <Button
                      variant="icon"
                      size="xs"
                      rounded="none"
                      type="button"
                      className=""
                      onClick={() => openSearchWrapper()}
                    >
                      <SvgFluentSearch20Regular width="24px" height="24px" />
                    </Button>
                    : (
                      <div className='flex flex-col relative'>
                        <BookingSiteSearch setDebouncedSearchQuery={setDebouncedSearchQuery} />
                      </div>
                    )}
                </div>
                {debouncedSearchQuery?.length ? (
                  <div className="w-full border-b border-grey-50 flex justify-between py-3 items-center">
                    <Paragraph size='b4' color={COLORS.GREY['300']}>{searchCount} results for "<span className="font-semibold">{debouncedSearchQuery}</span>"</Paragraph>
                    
                    <Button
                      variant="icon"
                      size="xs"
                      rounded="md"
                      type="button"
                      className=""
                      onClick={closeSearch}
                    >
                      <SvgClose width="24px" height="24px" />
                    </Button>
                  </div>
                ) : null}
              </>
            ) : null}
            <div className="w-full flex flex-col lg:flex-row gap-[10px] md:gap-[20px] justify-between">
              {activeTab === BOOKING_PAGE_TAB_NAME.SERVICES ? (
                <div className='flex flex-col space-y-6 border border-grey-100 rounded-2xl p-6 w-full h-fit md:max-w-[60%]'>
                  {!loadingServices && Array.isArray(services) && services.map((category) => {
                    return (
                      <>
                        <div className='flex flex-col space-y-4' key={category.category}>
                          <div className='flex justify-between cursor-pointer items-center' onClick={() => showOrHideCategory(category?.name)}>
                            <Heading variant='h3' size='h11' weight='semiBold' className='capitalize'>{category?.name}</Heading>
                            {category?.show ? (
                              <SvgArrowUp />
                            ) : (
                              <SvgArrowDown />
                            )}
                          </div>
                          {category?.show && Array.isArray(category?.services) && category?.services.map((service) => {
                            return (
                              <div className='flex flex-row justify-between items-center gap-2' key={service.id}>
                                <div className='w-full xl:max-w-[70%] flex flex-col justify-start'>
                                  <Paragraph size='b4' weight='semiBold' color={COLORS.GREY[300]}>{service?.name}</Paragraph>
                                  <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]}>{service?.description}</Paragraph>
                                  <Paragraph size='b5' weight='normal' color={COLORS.BLACK}> {service?.pricingType === 'from' ? (<span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full mr-2'>from</span>) : null} {businessInfo?.country?.currency?.symbol}<FormatNumber value={service?.price} /> · <span className='text-grey-300'>{getHoursAndMinutesString(service?.duration)}</span></Paragraph>
                                </div>
                                {businessInfo?.acceptsOnlineBookings && (
                                  <Button
                                    variant={service?.selected ? 'primary' : 'light'}
                                    className={`px-4 ${service?.selected ? 'text-white' : 'text-green-300'}`}
                                    size='sm'
                                    rounded='lg'
                                    type='button'
                                    fontWeight='semiBold'
                                    onClick={() => selectService(service?.id)}
                                  >
                                    {service?.selected ? 'Added' : 'Add'}
                                  </Button>
                                )}
                              </div>
                            )
                          })}
                        </div>
                        {category?.name !== services[services.length - 1]?.name && (
                          <div className='border-t border-grey-100'></div>
                        )}
                      </>
                    )
                  })}
                  {!loadingServices && services && Array.isArray(services) && services?.length === 0 && (
                    <div className='flex justify-center items-center'>
                      <Paragraph size='b4' weight='normal' color={COLORS.BLACK}>No services available</Paragraph>
                    </div>
                  )}
                  {loadingServices && !services ? (
                    <div className='w-full flex flex-col space-y-6 max-w-[360px]'>
                      <Skeleton width="150px" height="50px" className="rounded-md" />

                      <div className='flex flex-col space-y-2'>
                        <ParagraphShimmer size="md" />
                        <ParagraphShimmer size="md" />
                        <ParagraphShimmer size="md" />
                      </div>

                      <Skeleton width="150px" height="30px" className="rounded-full" />
                    </div>
                  ) : null}
                </div>
              ) : activeTab === BOOKING_PAGE_TAB_NAME.VOUCHERS && !salonPackage ? (
                <div className='w-full grid grid-cols-1 sm:grid-cols-2 gap-[5px] sm:gap-[15px]'>
                  {Array.isArray(formattedPackages) && formattedPackages.map((salonPackage) => {
                    return (
                      <PackageBookingCard businessInfo={businessInfo} salonPackage={salonPackage} selectBookingPackage={() => setSalonPackage(salonPackage)} key={salonPackage?.id} />
                    )
                  })}
                </div>
              ) : activeTab === BOOKING_PAGE_TAB_NAME.VOUCHERS && salonPackage ? (
                <div className='w-full flex flex-col gap-10'>
                  <div className="w-full flex flex-col xl:flex-row gap-4 items-center">
                    <div className='w-full flex flex-col md:max-w-[50%] gap-6 p-6 border border-grey-50 rounded-md shadow-sm'>
                      {Array.isArray(selectedPackageDetails) && selectedPackageDetails.map((selectedPackageDetail, index) => {
                        return (
                          <div key={`pc${index}`} className="flex justify-between spac-x-2">
                            <Paragraph size='b5' weight='normal' className="w-1/2" color={COLORS.GREY['300']}>{selectedPackageDetail?.label}</Paragraph>
                            <Paragraph size='b5' weight='normal' className="w-1/2" color={COLORS.GREY['900']}>{selectedPackageDetail?.value}</Paragraph>
                          </div>
                        )
                      })}
                    </div>
                    <div className='md:w-full xl:max-w-[50%]'>
                      <PackageBookingCard businessInfo={businessInfo} salonPackage={salonPackage} />
                    </div>
                  </div>

                  <div className='w-full flex flex-col gap-4 md:max-w-[50%]'>
                    <Button
                      variant='primary'
                      className=''
                      size='lg'
                      rounded='lg'
                      onClick={() => modifyPackageInCart(salonPackage.id, 'add')}
                    >
                      Add to cart
                    </Button>
                    <Button
                      variant='secondary'
                      className=''
                      size='lg'
                      rounded='lg'
                      onClick={() => setSalonPackage(null)}
                    >
                      Choose another
                    </Button>
                  </div>
                </div>
              ) : null}
              {/* cart */}
              <div id="cart-bag-section" className='hidden lg:flex flex-col gap-4 border border-grey-100 rounded-2xl p-6 w-full max-w-[100%] md:max-w-[30%] max-h-[400px] overflow-y-auto'>
                <Paragraph size={`${!cartIsEmpty ? "b3" : "b5"}`} weight="semiBold" color={COLORS.GREY[900]} className={`w-full ${!cartIsEmpty ? "text-left" : "text-center"}`}>
                  Your cart
                </Paragraph>
                <Paragraph size="b5" color={COLORS.GREY[300]} className={`w-full ${!cartIsEmpty ? "text-left" : "text-center"}`}>
                  {!cartIsEmpty ? "View the summary of your cart here while you shop" : "You have no items in your cart yet, start by booking an appointment or purchasing an item today"}
                </Paragraph>

                {cartIsEmpty ? (
                  <div className='w-full flex justify-center items-center'>
                    <SvgCartBag width="65px" height="65px" />
                  </div>
                ) : null}

                {Array.isArray(selectedServices) && selectedServices.map((service, index) => {
                  const quantity = service?.quantity || 1
                  return (
                    <div className='w-full flex justify-between py-2' key={`ses${index}`}>
                      <div className='flex flex-col gap-2'>
                        <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{service?.name}</Paragraph>
                        {/* <div className="w-full flex space-x-2 items-end">
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Qty:</Paragraph>
                          <div className="w-full flex pt-2">
                            <Button
                              variant='icon'
                              size="none"
                              type="button"
                              className="border-0"
                              onClick={() => modifyServiceInCart(service?.id, 'add')}
                            >
                              <SvgGreyPlus width="22px" height="22px" />
                            </Button>
                            <div className="flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center">
                              <Paragraph size='b6' weight='normal' color={COLORS.BLACK}>{quantity}</Paragraph>
                            </div>
                            <Button
                              variant='icon'
                              size="none"
                              type="button"
                              className="border-0"
                              onClick={() => modifyServiceInCart(service?.id, 'remove')}
                            >
                              <SvgGreyMinus width="22px" height="22px" />
                            </Button>
                          </div>
                        </div> */}
                      </div>
                      <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>{formatInToPrice(service?.price * quantity, businessInfo?.country?.currency?.symbol)}</Paragraph>
                    </div>
                  )
                })}
                {Array.isArray(selectedPackages) && selectedPackages.map((selectedSalonPackage, index) => {
                  const price = selectedSalonPackage?.usesCustomPrice ? selectedSalonPackage?.customPrice : selectedSalonPackage?.totalPrice;
                  return (
                    <div className='w-full flex justify-between py-2' key={`ssp${index}`}>
                      <div className='flex flex-col gap-2'>
                        <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{selectedSalonPackage?.name}</Paragraph>
                        <Paragraph size='b6' weight='normal' color={COLORS.GREY['400']}>{limitString(convertSalonServicesNamesToString(selectedSalonPackage?.services), 20)}</Paragraph>
                        <div className="w-full flex space-x-2 items-end">
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Qty:</Paragraph>
                          <div className="w-full flex pt-2">
                            <Button
                              variant='icon'
                              size="none"
                              type="button"
                              className="border-0"
                              onClick={() => modifyPackageInCart(selectedSalonPackage.id, 'add')}
                            >
                              <SvgGreyPlus width="22px" height="22px" />
                            </Button>
                            <div className="flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center">
                              <Paragraph size='b6' weight='normal' color={COLORS.BLACK}>{selectedSalonPackage?.quantity}</Paragraph>
                            </div>
                            <Button
                              variant='icon'
                              size="none"
                              type="button"
                              className="border-0"
                              onClick={() => modifyPackageInCart(selectedSalonPackage.id, 'remove')}
                            >
                              <SvgGreyMinus width="22px" height="22px" />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>{formatInToPrice(price * selectedSalonPackage?.quantity, businessInfo?.country?.currency?.symbol)}</Paragraph>
                    </div>
                  )
                })}
                {!cartIsEmpty ? (
                  <Button
                    variant='primary'
                    className=''
                    size='lg'
                    rounded='lg'
                    onClick={() => handleNextStep(Array.isArray(selectedServices) && selectedServices?.length ? businessInfo?.allowServiceStaffSelection ? STAFF_SELECTION : TIME_AND_DATE : USER_DETAILS_STEP)}
                  >
                    Proceed to checkout
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
          
          {!cartIsEmpty ? (
            <div className="w-full flex lg:hidden fixed bottom-[20px] h-[60px] px-[20px]">
              <Button
                variant='primary'
                className=''
                size='lg'
                rounded='lg'
                onClick={() => handleNextStep(Array.isArray(selectedServices) && selectedServices?.length ? businessInfo?.allowServiceStaffSelection ? STAFF_SELECTION : TIME_AND_DATE : USER_DETAILS_STEP)}
              >
                <div className='w-full flex justify-between items-center px-4 py-2'>
                  <div className="flex gap-2 items-center text-white">
                    <SvgWhiteShoppingBag width="24px" height="24px" />
                    <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE}>View cart ({selectedServices?.length ? selectedServices?.length : selectedPackages?.length ? selectedPackages?.length : 0})</Paragraph>
                  </div>
                  <SvgArrowRight width="16px" height="16px" />
                </div>
              </Button>
            </div>
          ) : null}
        </>
      )}
      <BookingRedeemVoucherModal
        isVisible={isRedeemVoucherModalVisible}
        closeModal={initiateCloseRedeemVoucherPage}
        addToast={addToast}
      />
    </>
  )
}