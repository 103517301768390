import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRoundDownload = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect y={0.5} width={29} height={29} rx={14.5} fill="#F2F8F4" />
    <path
      d="M9.667 18.5a.5.5 0 0 1 .5.5v1.5c0 .092.075.167.167.167h8.333a.167.167 0 0 0 .167-.167V19a.5.5 0 1 1 1 0v1.5a1.167 1.167 0 0 1-1.167 1.167h-8.333A1.167 1.167 0 0 1 9.167 20.5V19a.5.5 0 0 1 .5-.5Z"
      fill="#027A48"
    />
    <path
      d="M9.98 13.647a.5.5 0 0 1 .707 0l3.314 3.312V8.833a.5.5 0 0 1 1 0v8.126l3.313-3.312a.5.5 0 0 1 .706.706l-4.166 4.167a.499.499 0 0 1-.707 0L9.98 14.353a.498.498 0 0 1 0-.706Z"
      fill="#027A48"
    />
  </svg>
);
export default SvgRoundDownload;
