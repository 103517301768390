import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLocationPin = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#location-pin_svg__a)">
      <path
        d="M8.057-.006C4.777-.006 2 2.787 2 6.096c0 3.465 3.22 7.009 5.385 9.5.008.01.358.398.79.398h.038c.43 0 .778-.389.787-.399 2.032-2.336 5-6.188 5-9.499 0-3.309-2.166-6.102-5.943-6.102Zm.2 14.93a.571.571 0 0 1-.065.054.572.572 0 0 1-.066-.053l-.262-.301C5.811 12.269 3 9.044 3 6.096 3 3.331 5.316.994 8.057.994 11.472.994 13 3.556 13 6.096c0 2.238-1.596 5.208-4.742 8.829Zm-.24-11.902a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 5A2.04 2.04 0 0 1 5.994 6c0-1.103.898-2 2-2 1.104 0 2 .897 2 2 .001 1.103-.873 2.022-1.977 2.022Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="location-pin_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLocationPin;
