import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgIonTimeOutline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g
      clipPath="url(#ion_time-outline_svg__a)"
      stroke="#7EA05C"
      strokeWidth={0.75}
    >
      <path
        d="M5 1.25a3.751 3.751 0 0 0 0 7.5 3.751 3.751 0 0 0 0-7.5Z"
        strokeMiterlimit={10}
      />
      <path
        d="M5 2.5v2.813h1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="ion_time-outline_svg__a">
        <path fill="#fff" d="M0 0h10v10H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIonTimeOutline;
