import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFluentPrint28Regular = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.272 12.574h1.994c.233 0 .423-.19.423-.423V7.64a2.82 2.82 0 0 0-2.82-2.82H4.82A2.82 2.82 0 0 0 2 7.64v4.511c0 .234.19.423.423.423h1.994M5.525 4.82V2.423c0-.234.189-.423.423-.423h4.793c.234 0 .423.19.423.423V4.82"
      stroke="#667368"
      strokeWidth={1.057}
    />
    <path
      d="m4.184 14.206.233-1.632.35-2.457a.423.423 0 0 1 .42-.363h6.315c.21 0 .39.155.419.363l.35 2.457.234 1.632a.423.423 0 0 1-.419.483H4.602a.423.423 0 0 1-.418-.483Z"
      stroke="#667368"
      strokeWidth={1.057}
    />
    <path
      d="m11.87 6.942.006-.008"
      stroke="#667368"
      strokeWidth={1.057}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgFluentPrint28Regular;
