import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSvgDownGrowthIndicator = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={8} cy={8} r={8} transform="rotate(90.057 8 8)" fill="#AD0404" />
    <path
      d="m8.85 9.797-.214-.215-3.443-3.45c-.232-.232-.283-.507-.156-.781.125-.264.352-.41.627-.37.199.034.382.128.527.27 1.13 1.111 2.245 2.237 3.365 3.36l.212.212c.012-.069.02-.138.024-.207.003-.45-.001-.9.005-1.348.004-.356.25-.601.59-.605.37-.005.62.218.621.59.006 1.034.006 2.067 0 3.1-.003.417-.23.652-.642.66-1.025.017-2.05.028-3.075.03-.376.001-.597-.243-.594-.617.004-.352.23-.584.596-.594.433-.011.867-.012 1.3-.018.069 0 .136-.01.257-.017Z"
      fill="#fff"
    />
  </svg>
);
export default SvgSvgDownGrowthIndicator;
