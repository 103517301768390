import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgIonCopyOutline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.719 4.5H5.78C4.797 4.5 4 5.297 4 6.281v6.938C4 14.203 4.797 15 5.781 15h6.938c.984 0 1.781-.797 1.781-1.781V6.28c0-.984-.797-1.781-1.781-1.781Z"
      stroke="#000"
      strokeLinejoin="round"
    />
    <path
      d="M11.984 4.5 12 3.75A1.755 1.755 0 0 0 10.25 2H3.5a2.006 2.006 0 0 0-2 2v6.75a1.755 1.755 0 0 0 1.75 1.75H4"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIonCopyOutline;
