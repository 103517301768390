export const SIZES = {
  xxxs: "1",
  xxs: "2",
  xs: "3", // 12px
  sm: "4", // 16px
  md: "6", // 24px
  lg: "8", // 32px
  xl: "10", // 48px
  xxl: "16", // 64px
  xxxl: "24", // 96px
  xxxxl: "32", // 128px
};
