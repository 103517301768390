import * as React from "react";
import { useState } from 'react';
import { Paragraph } from '../paragraph';
import { CollapseProps } from './types';
import { SvgDropDown } from '../../icons';
import { COLORS } from '../../../constants/colors';

const Collapse = ({ title, children }: CollapseProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="overflow-hidden">
      <div
        className="flex items-center px-2 py-4 cursor-pointer border-b border-grey-100"
        onClick={toggle}
      >
        <SvgDropDown width="24px" height="24px" />
        <Paragraph size="b4" weight='bold' color={COLORS.GREY[300]}>{title}</Paragraph>
      </div>
      {isOpen && (
        <div className="flex flex-col p-6 bg-white space-y-4">{children}</div>
      )}
    </div>
  );
};

export default Collapse;