import * as React from "react"
import { SVGProps } from "react"
const EmptyCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={95}
    className="Icon__StyledInlineSVG-sc-c98r68-0 iWMDoQ Icon-sc-c98r68-1 kMfJYW"
    viewBox="0 0 95 69"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#F2F2F7" d="M7.39 0h60.765v35.309H7.39z" />
      <path
        fill="#101928"
        d="M75.184 26.852h-57.94a1.232 1.232 0 1 0 0 2.463h57.94a1.232 1.232 0 1 0 0-2.463z"
      />
      <path
        fill="#101928"
        d="M18.476 60.477v-45.82h56.166V39.24a1.232 1.232 0 0 0 2.464 0V13.426c0-.68-.552-1.232-1.232-1.232h-58.63c-.68 0-1.232.551-1.232 1.232v48.283c0 .68.552 1.232 1.232 1.232h24.002a1.232 1.232 0 1 0 0-2.464h-22.77z"
      />
      <path
        fill="#101928"
        d="M29.463 7.39v12.416a1.232 1.232 0 1 0 2.463 0V7.39a1.232 1.232 0 1 0-2.463 0zm31.039 0v12.416a1.232 1.232 0 1 0 2.463 0V7.39a1.232 1.232 0 1 0-2.463 0zm1.232 33.947c7.537 0 13.647 6.11 13.647 13.647S69.271 68.63 61.734 68.63s-13.648-6.11-13.648-13.647 6.11-13.648 13.648-13.648zm0 2.463c-6.177 0-11.184 5.007-11.184 11.184s5.007 11.184 11.184 11.184c6.176 0 11.184-5.007 11.184-11.184S67.91 43.8 61.734 43.8z"
      />
      <path
        fill="#101928"
        d="M62.965 49.81a1.232 1.232 0 1 0-2.463 0v5.174c0 .68.551 1.232 1.232 1.232h5.173a1.232 1.232 0 1 0 0-2.464h-3.942v-3.941z"
      />
    </g>
  </svg>
)
export default EmptyCalendar
