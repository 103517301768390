import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  Button,
  CalendarPicker,
  Checkbox,
  ContactLink,
  FormHelperText,
  FormLabel,
  FormPhone,
  FormTextarea,
  FormatNumber,
  Heading,
  Input,
  Layout,
  Modal,
  NameAvatar,
  Paginator,
  Paragraph,
  Pill,
  SearchTerm,
  Table,
  TableFilterProps,
  TableSkeleton,
  Tabs,
  ToastWrapper
} from '../ui'
import { saveAs } from 'file-saver'
import { PhotoProvider, PhotoView } from 'react-photo-view'

import ClientProvider, { useClientContext } from '../context/clientContext'
import { useModal } from '../hooks'
import {
  Appointment,
  AppointmentMilestone,
  Client,
  ClientMilestone,
  ClientNote,
  ClientRewardHistory,
  CommunicationLog,
  CreateClientInput,
  CreateClientNoteInput,
  PackageVoucher,
  RecordRefundInput,
  Sale,
  Service
} from '../graphql/generated'
import { Controller, useForm } from 'react-hook-form'
import { ACCOUNT_SETUP_ERRORS } from '../constants/information'
import { CSV_UPLOAD_PATTERN, REGEX_PATTERNS } from '../constants/pattern'
import {
  convertDateTimeStringToTimeZone,
  convertFullDateStringToDate,
  formatDateToOriginalDate,
  formatInToPrice,
  formatNumber,
  formatSnakeCaseText,
  formatTableHeadersFilterArray,
  getDayMonthAndNumberAndTimeFromDate,
  getDefaultQueryDates,
  getNumberMonthAndYearFromDate,
  limitString,
  sanitizePhoneNumber,
  validatePhoneNumber
} from '../utils/misc'
import {
  getHelpTextForCharacterLeft,
  getHelperTextForReactHookFormErrors
} from '../utils/form'
import { COLORS } from '../constants/colors'
import { Menu, Transition } from '@headlessui/react'
import * as ReactMenu from '@szhsin/react-menu'
import {
  SvgArrowBack,
  SvgArrowDown,
  SvgCancelled,
  SvgCartBag,
  SvgCharmMenuKebab,
  SvgChevronLeft,
  SvgDeleteRegular,
  SvgDiamond,
  SvgEditRegular,
  SvgFileTextFrame,
  SvgFilter,
  SvgGreenSignal,
  SvgGreySignal,
  SvgMdiLightImage,
  SvgNoShow,
  SvgPassed,
  SvgPercentFrame,
  SvgPlus,
  SvgRedSignal,
  SvgRoundArrowDown,
  SvgRoundArrowUp,
  SvgRoundDownload,
  SvgRoundEye,
  SvgRoundTrash,
  SvgShopping,
  SvgUploadCloud
} from '../ui/icons'
import Label from '../ui/atoms/formLabel/Label'
import FullTable from '../ui/organism/table/Table'
import { DEFAULT_CURRENCY } from '../constants/currency'
import { ERRORS } from '../constants/errors'
import { useFileUploader_ } from '../hooks/useUploader'
import ImportFileLink from '../uicomponents/ImportFileLink'
import SvgUploadImage from '../ui/icons/UploadImage'
import {
  getClientRewardFromTable,
  getClientAppointmentFromTable,
  getClientSaleFromTable,
  getClientVoucherFromTable
} from '../uicomponents/utils'
import { useSalonCache } from '../hooks/useSalonCache'
import SingleSelect from '../ui/molecules/singleSelect/SingleSelect'
import { convertServicesNamesToString } from '../uicomponents/bookingSales/utils'
import ClientPackageDetails from '../uicomponents/ClientPackageDetails'
import BookingDetailsModal from '../uicomponents/BookingDetails'
import { IAppointmentAndSaleProp } from '../uicomponents/types'
import { canPerformAction } from '../utils/permission'
import { PERMISSION_CONSTANTS } from '../constants/permission'
import { PermissionActionProp } from '../utils/types'
import {
  formatRewardStatusPill,
  formatSaleStatusPill
} from '../uicomponents/salesComponents/StatusUtils'
import {
  ACTVITY_LOYALTY_TABLE_HEADERS,
  MOBILE_ACTIVITY_LOYALTY_TABLE_HEADERS
} from '../uicomponents/customerDasboard/constants'
import { formatDate } from '@fullcalendar/core'

const CLIENTS_HEADINGS = [
  {
    label: 'Client name',
    value: 'name',
    show: true
  },
  {
    label: 'Date',
    value: 'date',
    show: true
  },
  {
    label: 'Mobile number',
    value: 'phone',
    show: true
  },
  {
    label: 'Email address',
    value: 'email',
    show: true
  },
  {
    label: 'Total sales',
    value: 'total',
    show: true
  }
]

const MOBILE_CLIENTS_HEADINGS = [
  {
    title: 'Client name',
    key: 'name'
  },
  {
    title: 'Date',
    key: 'date'
  },
  {
    title: 'Mobile',
    key: 'phone'
  },
  {
    title: 'Email',
    key: 'email'
  },
  {
    title: 'Total sales',
    key: 'total'
  }
]

const CLIENT_TAB_NAME = {
  BOOKING: 'Booking',
  PACKAGES: 'Vouchers',
  SALES: 'Sales',
  REWARDS: 'Rewards',
  ACTIVITY: 'Activity',
  COMMUNICATION_LOG_HISTORY: 'Messages'
}

const CLIENT_TABS = [
  {
    key: CLIENT_TAB_NAME.BOOKING,
    title: CLIENT_TAB_NAME.BOOKING
  },
  {
    key: CLIENT_TAB_NAME.SALES,
    title: CLIENT_TAB_NAME.SALES
  },
  {
    key: CLIENT_TAB_NAME.PACKAGES,
    title: CLIENT_TAB_NAME.PACKAGES
  },
  {
    key: CLIENT_TAB_NAME.REWARDS,
    title: CLIENT_TAB_NAME.REWARDS
  },
  {
    key: CLIENT_TAB_NAME.ACTIVITY,
    title: CLIENT_TAB_NAME.ACTIVITY
  },
  {
    key: CLIENT_TAB_NAME.COMMUNICATION_LOG_HISTORY,
    title: CLIENT_TAB_NAME.COMMUNICATION_LOG_HISTORY
  }
]

const BOOKING_HISTORY_HEADERS = [
  { label: 'Service', value: 'service' },
  { label: 'Appointment Date', value: 'date' },
  { label: 'Payment Method', value: 'paymentMethod' },
  { label: 'Amount', value: 'amount' },
  { label: 'Status', value: 'status' },
  { label: 'Points', value: 'points' }
]

const BOOKING_HISTORY_MOBILE_HEADERS = [
  {
    title: 'Service',
    key: 'service'
  },
  {
    title: 'Payment',
    key: 'amount'
  }
]
const REWARDS_HISTORY_MOBILE_HEADERS = [
  {
    title: 'Date Reached',
    key: 'milestone'
  },
  {
    title: 'Reward value',
    key: 'date'
  },
  {
    title: 'Status',
    key: 'reward'
  }
]

const CLIENT_PACKAGES_HISTORY_HEADERS = [
  { label: 'Name', value: 'name' },
  { label: 'Date of Purchase', value: 'purchasedAt' },
  { label: 'Expiry Date', value: 'validityMonths' },
  { label: 'Status', value: 'status' },
  { label: 'Total Price', value: 'price' },
  { label: 'Total Redeemed', value: 'redeemed' }
]
const CLIENT_PACKAGES_HISTORY_MOBILE_HEADERS = [
  {
    title: 'Name',
    key: 'name'
  },
  {
    title: 'Price',
    key: 'price'
  }
]

const SALES_HISTORY_HEADERS = [
  { label: 'Service', value: 'service' },
  { label: 'Date', value: 'date' },
  { label: 'Payment Method', value: 'paymentMethod' },
  { label: 'Discount', value: 'discount' },
  { label: 'Amount', value: 'amount' },
  { label: 'Points', value: 'points' },
  { label: 'Status', value: 'status' }
]

const REWARDS_HISTORY_HEADERS = [
  { label: 'Date Reached', value: 'milestone' },
  { label: 'Reward value', value: 'date' },
  { label: 'Status', value: 'reward' }
]

const COMMUNICATION_LOG_HISTORY_HEADERS = [
  { label: 'Subject line', value: 'subject' },
  { label: 'Body', value: 'body' },
  { label: 'Type', value: 'communicationType' },
  { label: 'Status', value: 'deliveryStatus' }
]

const Clients = () => {
  const [openAddClientModal, setOpenAddClientModal] = useState(false)

  const openClientModal = () => {
    setOpenAddClientModal(true)
  }

  const closeClientModal = () => {
    setOpenAddClientModal(false)
  }
  return (
    <>
      <ClientProvider>
        <Layout pageTitle='Clients' openModal={openClientModal} pageLevel={2}>
          <ClientsContent
            openPage={openAddClientModal}
            closePage={closeClientModal}
          />
        </Layout>
      </ClientProvider>
    </>
  )
}

export const ClientAvatarAndNameView = (client: Client) => {
  let charater = '--'
  if (client?.fullName) {
    // split fullName
    const fullName = client?.fullName.split(' ')
    // get first and second letter of each name
    if (fullName.length > 1) {
      charater = `${fullName[0]?.charAt(0)}${
        fullName[1]?.length > 0
          ? fullName[1]?.charAt(0)
          : fullName[0]?.charAt(1)
      }`
    } else {
      charater = fullName[0]?.charAt(0) + fullName[0]?.charAt(1)
    }
  }

  return (
    <span className='flex items-center space-x-2'>
      <NameAvatar name={charater} />
      <Paragraph size='b4' color={COLORS.GREY[900]}>
        {client?.firstName} {client?.lastName}
      </Paragraph>
    </span>
  )
}

const ClientsContent = ({
  openPage,
  closePage
}: {
  openPage: boolean
  closePage: () => void
}) => {
  const { clients, getSalonClients, toast, isLoading } = useClientContext()
  const [client, setClient] = useState<Client | null>(null)
  const [clientHeadings, setClientHeadings] = useState(CLIENTS_HEADINGS)
  const { openModal, closeModal, isVisible } = useModal()
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('')
  const [isEndReached, setIsEndReached] = useState(false)
  const targetRef = useRef(null)
  const actions = {
    addClient: canPerformAction(`Clients::${PERMISSION_CONSTANTS.client.add}`),
    viewClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view}`
    ),
    deleteClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.delete}`
    ),
    recordRefund: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.refund}`
    ),
    editClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.edit}`
    ),
    addNotes: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.add_note}`
    ),
    viewNotes: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view_note}`
    ),
    editNote: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.edit_note}`
    ),
    deleteNote: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.delete_note}`
    ),
    viewHistory: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view_appointment_history}`
    )
  }
  const {
    openModal: openViewClientModal,
    closeModal: closeViewClientModal,
    isVisible: isViewClientModalVisible
  } = useModal()

  const {
    openModal: openDeleteClientModal,
    closeModal: closeDeleteClientModal,
    isVisible: isDeleteClientModalVisible
  } = useModal()

  const {
    openModal: openImportClientModal,
    closeModal: closeImportClientModal,
    isVisible: isImportClientModalVisible
  } = useModal()

  const getClientAsync = async () => {
    await getSalonClients()
  }

  useEffect(() => {
    getClientAsync
  }, [])

  useEffect(() => {
    if (openPage) {
      openModal()
    }
  }, [openPage])

  useEffect(() => {
    getSalonClients(debouncedSearchQuery)
  }, [debouncedSearchQuery])

  const handleCloseModal = () => {
    closeModal()
    closePage()
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsEndReached(true)
          } else {
            setIsEndReached(false)
          }
        })
      },
      { threshold: 0.5 } // Trigger when at least 50% of the target is visible
    )

    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    // Cleanup function
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (
      isEndReached &&
      clients &&
      clients?.pageInfo &&
      clients?.pageInfo?.hasNextPage
    ) {
      getSalonClients('', clients?.pageInfo?.endCursor)
    }
  }, [isEndReached, clients])

  const loadClientsShimmer =
    isLoading && !clients?.nodes?.length && !debouncedSearchQuery

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in clientHeadings then update the show
    const updatedHeadings = clientHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setClientHeadings(updatedHeadings)
  }

  const generateClientsTableData = (
    clients: Client[],
    tableHeadings: TableFilterProps,
    countryCode?: string
  ) => {
    return clients?.map?.((client) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = ClientAvatarAndNameView(client)
              break
            case 'date':
              rowData[heading.value] = actions.viewClient
                ? getNumberMonthAndYearFromDate(
                    convertDateTimeStringToTimeZone(
                      client?.createdAt,
                      countryCode
                    )
                  )
                : null
              break
            case 'phone':
              rowData[heading.value] = actions?.viewClient
                ? client?.callingCode + '' + client?.phone
                : '-'
              break
            case 'email':
              rowData[heading.value] = actions?.viewClient ? client?.email : '-'
              break
            case 'total':
              rowData[heading.value] = actions?.viewClient
                ? formatInToPrice(client?.totalTransactions)
                : '-'
              break
          }
        }
      })
      return rowData
    })
  }

  const formatTableHeadersFilterArray = (data: TableFilterProps) => {
    // return an array of string to be used in the table filter, select the labels only if show is true
    const results = data
      ?.filter((item) => item?.show)
      ?.map((item) => ({ label: item.label, value: item.value }))

    return results
  }

  const openClientPage = (id: number, action?: string) => {
    const client_ = clients.nodes[id]
    if (!action && !actions.viewClient) return

    setClient(client_)

    switch (action) {
      case 'view':
        openViewClientModal()
        break
      case 'edit':
        openModal()
        break
      case 'delete':
        openDeleteClientModal()
        break
      default:
        openViewClientModal()
        break
    }
  }

  const getClientsContent = () => {
    if (loadClientsShimmer) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }

    if (
      (Array.isArray(clients?.nodes) && clients?.nodes.length) ||
      debouncedSearchQuery
    ) {
      const tableClientsData = generateClientsTableData(
        clients?.nodes || [],
        clientHeadings,
        'NG'
      )
      return (
        <>
          <div className='flex flex-col md:flex-row py-4 px-8 justify-between md:items-center gap-4 border-b border-grey-20'>
            <Heading variant='h2' size='h11' weight='bold'>
              Clients
            </Heading>
            {actions?.addClient ? (
              <div className='flex md:items-center space-x-2'>
                <Button
                  variant='secondary'
                  size='sm'
                  disabled={false}
                  loading={false}
                  type='button'
                  rounded='md'
                  fontSize='b5'
                  onClick={openImportClientModal}
                >
                  Import Clients
                </Button>
                <Button
                  variant='primary'
                  size='sm'
                  disabled={false}
                  loading={false}
                  type='button'
                  rounded='md'
                  fontSize='b5'
                  onClick={openModal}
                >
                  Add Client
                </Button>
              </div>
            ) : null}
          </div>
          <div className='flex flex-col xl:flex-row px-12 py-4 space-x-4'>
            <div className='w-full xl:w-6/12 flex items-center space-x-4'>
              <SearchTerm setDebouncedSearchQuery={setDebouncedSearchQuery} />
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <SvgFilter width='20px' height='20px' />
                    <span className='hidden xl:inline'>Filter</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                    <div className='flex flex-col p-4 space-y-4'>
                      {Array.isArray(clientHeadings) &&
                        clientHeadings.length &&
                        clientHeadings.map((heading, index) => (
                          <Label
                            className='flex space-x-2 items-center cursor-pointer'
                            key={index}
                            htmlFor={heading.value}
                            onClick={() =>
                              handleHeadingCheckboxChange(heading.value)
                            }
                          >
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <FullTable
            headers={formatTableHeadersFilterArray(clientHeadings)}
            mobileHeaders={MOBILE_CLIENTS_HEADINGS}
            rows={tableClientsData}
            onClick={openClientPage}
            tableOptions={{
              view: actions?.viewClient,
              edit: actions?.editClient,
              delete: actions?.deleteClient,
              duplicate: false
            }}
          />
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
          <Heading variant='h2' size='h3'>
            Clients
          </Heading>
          <p className='mt-4'>You don't have any clients yet.</p>
          <div className='w-full flex flex-col items-center xl:flex-row gap-2'>
            <Button
              variant='secondary'
              size='sm'
              disabled={false}
              loading={false}
              type='button'
              rounded='md'
              fontSize='b5'
              onClick={openImportClientModal}
            >
              Import Clients
            </Button>
            {actions?.addClient ? (
              <Button
                variant='primary'
                size='md'
                disabled={false}
                loading={false}
                type='button'
                rounded='md'
                onClick={openModal}
              >
                Add Client
              </Button>
            ) : null}
          </div>
          <ImportFileLink modules='client' />
        </div>
      </div>
    )
  }

  return (
    <>
      <div>
        <ToastWrapper toast={toast} />
        {getClientsContent()}
      </div>
      <div
        className='w-full flex py-4 justify-center items-center'
        ref={targetRef}
      ></div>
      <AddClientModal
        isVisible={isVisible}
        closeModal={handleCloseModal}
        client={client}
        setClient={setClient}
        actions={actions}
      />
      <ViewClientModal
        isVisible={isViewClientModalVisible}
        closeModal={closeViewClientModal}
        client={client}
        setClient={setClient}
        actions={actions}
      />
      <DeleteClientDialog
        isVisible={isDeleteClientModalVisible}
        closeModal={closeDeleteClientModal}
        client={client}
        setClient={setClient}
      />
      <ImportClientModal
        isVisible={isImportClientModalVisible}
        closeModal={closeImportClientModal}
      />
    </>
  )
}
type FileUploadInput = {
  fileUpload: File | null
}
const ImportClientModal = ({ isVisible, closeModal }) => {
  // @ts-expect-error envVariables is defined in application.html.erb
  const CLIENT_TEMPLATE_URL = `${envVariables.baseUrl}/home/download_template/client_import_template.csv`
  const { uploadClient } = useClientContext()
  const { getSalonFieldValue } = useSalonCache()
  const [loading, setLoading] = useState(false)
  const [uploadUrl, setUploadUrl] = useState(null)
  const { handleSubmit, control, setValue } = useForm<FileUploadInput>()

  const importClientsSubmit = async () => {
    setLoading(true)
    const payload = {
      salonId: getSalonFieldValue('id'),
      url: uploadUrl
    }
    await uploadClient(payload)
    setValue('fileUpload', null)
    setUploadUrl(null)
    closeModal()
    setLoading(false)
  }

  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true)
    const file = event.target.files?.[0]
    setValue('fileUpload', file)
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      const { url } = await useFileUploader_(base64DataUri)
      setUploadUrl(url)
      setLoading(false)
    }

    reader.readAsDataURL(file)
  }

  return (
    <Modal title='Import Clients' show={isVisible} closeModal={closeModal}>
      <div className='w-full flex flex-col my-6 items-center space-y-6'>
        <a href={CLIENT_TEMPLATE_URL} target='_blank' download>
          <Paragraph size='b5' color={COLORS.GREY[400]}>
            First, download the <span className='underline'>template</span> here
          </Paragraph>
        </a>
        <form
          onSubmit={handleSubmit(importClientsSubmit)}
          className='w-full mt-6 space-y-6'
          autoComplete='off'
        >
          <Controller
            control={control}
            name='fileUpload'
            render={({ field: { value }, formState: { errors } }) => {
              return (
                <div className='w-full flex flex-col py-6'>
                  <div className='w-full flex items-center space-x-4 border rounded-sm border-grey-20 border-dashed h-[130px] justify-center'>
                    <div className='relative cursor-pointer'>
                      {value ? (
                        <SvgMdiLightImage width='2rem' height='2rem' />
                      ) : (
                        <SvgUploadImage width='2rem' height='2rem' />
                      )}
                      <input
                        className='cursor-pointer absolute block opacity-0 top-0 w-[128px] h-[96px]'
                        type='file'
                        accept={CSV_UPLOAD_PATTERN}
                        onChange={handleCsvUpload}
                      />
                    </div>
                    <div className='flex flex-col'>
                      <Paragraph size='b5'>
                        {value ? value?.name : 'Upload Clients csv file'}
                      </Paragraph>
                    </div>
                  </div>
                  {errors?.fileUpload?.message && (
                    <FormHelperText variant='error'>
                      {errors.fileUpload.message}
                    </FormHelperText>
                  )}
                </div>
              )
            }}
            rules={{
              required: 'Client CSV file is required'
            }}
          />
          <Button
            variant='primary'
            className=''
            disabled={loading}
            loading={loading}
            size='lg'
            rounded='lg'
          >
            Upload
          </Button>
          <Button
            variant='text'
            fontWeight='bold'
            className={`mx-auto`}
            size='none'
            onClick={closeModal}
            type='button'
          >
            Cancel
          </Button>
        </form>
      </div>
    </Modal>
  )
}
type AddClientModalProps<T extends string> = {
  isVisible: boolean
  closeModal: () => void
  setClient?: (client: Client | null) => void
  client?: Client
  actions: PermissionActionProp<T>
}
const AddClientModal = ({
  isVisible,
  closeModal,
  setClient,
  client
}: AddClientModalProps<'editClient' | 'deleteClient'>) => {
  const country = JSON.parse(localStorage.getItem('country'));
  const { createClient } = useClientContext()
  const [isLoading, setIsLoading] = useState(false)
  const [countryCode, setCountryCode] = useState(country?.code)
  const [callingCode, setCallingCode] = useState(country?.countryCode)
  const [altCountryCode, setAltCountryCode] = useState(country?.code)
  const [altCallingCode, setAltCallingCode] = useState(country?.countryCode)

  const {
    handleSubmit,
    control,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<CreateClientInput>({ delayError: 100, mode: 'onChange' })

  useEffect(() => {
    if (client) {
      setValue('firstName', client?.firstName)
      setValue('lastName', client?.lastName)
      setValue('phone', client?.phone)
      setValue('altPhone', client?.altPhone)
      setValue('email', client?.email)
      setValue('dob', convertFullDateStringToDate(client.dob))
      setCountryCode(client?.countryCode)
      setCallingCode(client?.callingCode)
      setCountryCode(client?.altCountryCode)
      setCallingCode(client?.altCallingCode)
    } else {
      resetClientFormInput()
    }
  }, [client])

  const resetClientFormInput = () => {
    setValue('firstName', '')
    setValue('lastName', '')
    setValue('phone', '')
    setValue('altPhone', '')
    setValue('email', '')
    setValue('dob', '')
    setCountryCode(country?.code)
    setCallingCode(country?.countryCode)
    setAltCountryCode(country?.code)
    setAltCallingCode(country?.countryCode)
    setClient && setClient(null)
  }

  const _closeModal = () => {
    closeModal()
    resetClientFormInput()
  }

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode)
    setValue('phone', _phoneNumber)

    const pn = validatePhoneNumber(_phoneNumber, countryCode)

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('phone', {
        type: 'manual',
        message: 'Invalid phone number'
      })
    } else {
      // Clear the error if the phone number is valid
      clearErrors('phone')
    }
  }

  const _sanitizeAltPhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, altCallingCode)
    setValue('altPhone', _phoneNumber)

    const pn = validatePhoneNumber(_phoneNumber, countryCode)

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('phone', {
        type: 'manual',
        message: 'Invalid phone number'
      })
    } else {
      // Clear the error if the phone number is valid
      clearErrors('phone')
    }
  }

  const addClient = async (input: CreateClientInput) => {
    setIsLoading(true)

    const payload = {
      ...input,
      dob:
        input?.dob && input?.dob !== '-'
          ? formatDateToOriginalDate(input.dob as unknown as Date, 'start')
          : null,
      altCallingCode,
      callingCode,
      countryCode,
      altCountryCode,
      id: client ? client.id : null
    }
    await createClient(payload, closeModal, resetClientFormInput)
    setIsLoading(false)
  }

  return (
    <Modal
      title={client ? 'Edit client' : 'Add client'}
      subTitle={
        client ? 'Edit client details' : 'Enter client information below'
      }
      show={isVisible}
      closeModal={_closeModal}
    >
      <form
        onSubmit={handleSubmit(addClient)}
        className='w-full mt-6 space-y-6'
        autoComplete='off'
      >
        <div className='w-full flex gap-x-4'>
          <Input
            name='firstName'
            label='First name'
            id='first-name'
            type='text'
            placeholder='Enter first name here'
            control={control}
            rules={{
              required: ACCOUNT_SETUP_ERRORS.FIRST_NAME_REQUIRED
            }}
            error={errors.firstName}
          />
          <Input
            name='lastName'
            label='Last name'
            id='last-name'
            type='text'
            placeholder='Enter last name here'
            control={control}
            rules={{
              required: ACCOUNT_SETUP_ERRORS.LAST_NAME_REQUIRED
            }}
            error={errors.lastName}
          />
        </div>

        <FormPhone
          country={{
            disabled: false,
            onSelect: (code, country: string) => {
              setCallingCode(code)
              setCountryCode(country)
            },
            value: countryCode
          }}
          phone={{
            name: 'phone',
            type: 'text',
            placeholder: '9151930463',
            rules: {
              required: 'Phone number is required',
              pattern: REGEX_PATTERNS.NUMBER,
              onChange: (e) => {
                _sanitizePhoneNumber(e.target.value)
              },
              disabled: false
            },
            register,
            id: 'phone-number',
            label: 'Phone Number'
          }}
          helperText={getHelperTextForReactHookFormErrors(
            errors?.phone?.message as string
          )}
        />

        <FormPhone
          country={{
            disabled: false,
            onSelect: (code, country: string) => {
              setAltCallingCode(code)
              setAltCountryCode(country)
            },
            value: altCountryCode
          }}
          phone={{
            name: 'altPhone',
            type: 'text',
            placeholder: '9151930463',
            rules: {
              pattern: REGEX_PATTERNS.NUMBER,
              onChange: (e) => {
                _sanitizeAltPhoneNumber(e.target.value)
              },
              disabled: false
            },
            register,
            id: 'alt-phone-number',
            label: 'Secondary Phone Number'
          }}
          helperText={getHelperTextForReactHookFormErrors(
            errors?.altPhone?.message as string
          )}
        />

        <Input
          name='email'
          label='Email'
          id='email'
          type='email'
          placeholder='email@acme.com'
          control={control}
          rules={{
            pattern: REGEX_PATTERNS.EMAIL
          }}
          error={errors.email}
        />

        <Input
          name='address'
          label='Home Address'
          id='address'
          type='text'
          placeholder='address'
          control={control}
          error={errors.email}
        />

        <Input
          name='dob'
          label='Client Birthday'
          id='dob'
          type='date'
          placeholder='DD/MM/YY'
          control={control}
        />

        <div className='space-y-6'>
          <Button
            variant='primary'
            className=''
            disabled={false}
            loading={isLoading}
            size='lg'
            rounded='lg'
          >
            Save
          </Button>
          <Button
            variant='text'
            className={`mx-auto`}
            size='none'
            type='button'
            onClick={closeModal}
            disabled={false}
            loading={false}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  )
}
export const ClientBookingRedDot = (
  services: string,
  paid: boolean,
  mileStone?: AppointmentMilestone
) => {
  return (
    <div className='flex items-center space-x-2'>
      <div
        className='ml-1'
        style={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: paid ? 'green' : 'red'
        }}
      ></div>
      {mileStone ? <SvgDiamond width='14px' height='14px' /> : null}
      <Paragraph size='b4' color={COLORS.GREY[900]}>
        {services}
      </Paragraph>
    </div>
  )
}
const ViewClientModal = ({
  isVisible,
  closeModal,
  client: c,
  setClient,
  actions
}: AddClientModalProps<
  | 'addClient'
  | 'viewClient'
  | 'deleteClient'
  | 'editClient'
  | 'recordRefund'
  | 'viewHistory'
  | 'addNotes'
  | 'editNote'
  | 'viewNotes'
  | 'deleteNote'
>) => {
  const { getSalonClient, client } = useClientContext()
  const [activeTab, setActiveTab] = useState(CLIENT_TAB_NAME.BOOKING as string)
  const [voucher, setVoucher] = useState<PackageVoucher | null>(null)
  const [booking, setBooking] = useState<IAppointmentAndSaleProp | null>(null)
  const [reward, setReward] = useState<ClientMilestone>(null)
  const {
    openModal: openEditClientModal,
    closeModal: closeEditClientModal,
    isVisible: isEditClientModalVisible
  } = useModal()

  const {
    openModal: openAddNoteModal,
    closeModal: closeAddNoteModal,
    isVisible: isNoteModalVisible
  } = useModal()

  const {
    isVisible: notesModalIsVisible,
    openModal: openNotesModal,
    closeModal: closeNotesModal
  } = useModal()

  const {
    isVisible: clientPackageDetailsModalIsVisible,
    openModal: openClientPackageDetailsModal,
    closeModal: closeClientPackageDetailsModal
  } = useModal()

  const {
    isVisible: isRecordRefundModalVisible,
    openModal: openRecordRefundModal,
    closeModal: closeRecordRefundModal
  } = useModal()

  const {
    isVisible: bookingDetailsModalIsVisible,
    openModal: openBookingDetailsModal,
    closeModal: closeBookingDetailsModal
  } = useModal()

  const {
    isVisible: clientRewardDetailsModalIsVisible,
    openModal: openClientRewardDetailsModal,
    closeModal: closeClientRewardDetailsModal
  } = useModal()

  useEffect(() => {
    if (c) {
      getSalonClient(c.id)
    }
  }, [c])

  const initiateCloseAddNotwModal = (action?: 'added') => {
    closeAddNoteModal()
    if (action === 'added') {
      openNotesModal()
    }
  }

  const _closeModal = () => {
    closeModal()
    setClient && setClient(null)
  }

  const initiateCloseRewardModal = () => {
    closeClientRewardDetailsModal()
    setReward(null)
  }

  const generateBookingsTableData = (appointments: Appointment[]) => {
    return appointments
      ?.filter((appointment) => appointment?.isActive)
      .map((appointment) => {
        const serviceList = limitString(
          appointment.services.map((x) => x.name).join(', '),
          15
        )
        const paid = appointment?.paymentMethod !== 'unpaid'
        return {
          service: ClientBookingRedDot(
            serviceList,
            paid,
            appointment?.appointmentMilestone
          ),
          date: getNumberMonthAndYearFromDate(appointment?.startAt),
          paymentMethod: formatSnakeCaseText(appointment?.paymentMethod),
          amount: formatInToPrice(appointment?.totalPrice),
          status: formatSnakeCaseText(appointment?.appointmentStatus),
          points: appointment?.totalPoints
        }
      })
  }

  const generatePackagesTableDataForClient = (
    vouchers: PackageVoucher[],
    countryCode?: 'NG'
  ) => {
    return vouchers?.map?.((voucher) => {
      return {
        name: voucher?.package?.name,
        purchasedAt: getNumberMonthAndYearFromDate(
          convertDateTimeStringToTimeZone(voucher?.createdAt, countryCode) ||
            '-'
        ),
        validityMonths: getNumberMonthAndYearFromDate(
          convertDateTimeStringToTimeZone(voucher?.expiresAt, countryCode) ||
            '-'
        ),
        status: formatSnakeCaseText(voucher?.status),
        price: formatInToPrice(voucher.price),
        redeemed: formatInToPrice(voucher?.amountRedeemed)
      }
    })
  }

  const generateSalesTableData = (sales: Sale[]) => {
    return sales?.map?.((sale) => {
      return {
        service: limitString(sale.services.map((x) => x.name).join(', '), 15),
        date: getNumberMonthAndYearFromDate(sale?.startAt),
        paymentMethod: formatSnakeCaseText(sale?.paymentMethod),
        discount: formatInToPrice(sale?.discountAmount),
        amount: formatInToPrice(sale?.totalPrice),
        points: sale?.totalPoints,
        status: formatSaleStatusPill(sale?.saleStatus)
      }
    })
  }

  const appointments = generateBookingsTableData(client?.appointments)
  const packageVouchers = generatePackagesTableDataForClient(
    client?.packageVouchers
  )
  const sales = generateSalesTableData(client?.sales)

  const generateRewardsTableData = (clientMiles: ClientMilestone[]) => {
    return clientMiles?.map?.((clientMile) => {
      return {
        date: getNumberMonthAndYearFromDate(clientMile?.milestone?.createdAt),
        points:
          clientMile?.milestone?.customerReceivesType === 'percentage'
            ? `${clientMile?.milestone?.customerReceives}%`
            : formatInToPrice(clientMile?.milestone?.customerReceives),
        status: formatRewardStatusPill(clientMile?.status)
      }
    })
  }

  const rewards = generateRewardsTableData(client?.clientMilestones)

  const openSalonPackage = (id: number) => {
    const voucher = getClientVoucherFromTable(client?.packageVouchers || [], id)

    if (!voucher) {
      return
    }
    setVoucher(voucher)
    openClientPackageDetailsModal()
  }

  const openBooking = (id: number) => {
    const booking = getClientAppointmentFromTable(
      client?.appointments || [],
      id
    )

    if (!booking) {
      return
    }
    setBooking({
      id: booking.id,
      services: booking.services,
      type: 'appointment',
      startAt: booking.startAt,
      endAt: booking.endAt,
      paymentMethod: booking.paymentMethod,
      amount: booking.totalPrice,
      points: booking.totalPoints,
      paymentReference: '-',
      status: booking.appointmentStatus,
      note: booking.clientNote?.note,
      packageName: booking?.appointmentVoucher?.packageVoucher?.package?.name,
      appointmentMilestone: booking.appointmentMilestone
    })
    openBookingDetailsModal()
  }

  const openSale = (id: number) => {
    const booking = getClientSaleFromTable(client?.sales || [], id)

    if (!booking) {
      return
    }
    setBooking({
      id: booking.id,
      services: booking.services,
      type: 'sale',
      startAt: booking.startAt,
      endAt: booking.endAt,
      paymentMethod: booking.paymentMethod,
      amount: booking.totalPrice,
      points: booking.totalPoints,
      paymentReference: '-',
      status: 'completed',
      note: booking.note
    })
    openBookingDetailsModal()
  }

  const openReward = (id: number) => {
    const reward = getClientRewardFromTable(client?.clientMilestones || [], id)

    if (!reward) {
      return
    }
    setReward(reward)
    openClientRewardDetailsModal()
  }

  const generateActivityLoyaltyTableData = (
    activities: ClientRewardHistory[],
    countryCode?: string
  ) => {
    return activities?.map?.((activity) => {
      return {
        date: getNumberMonthAndYearFromDate(
          convertDateTimeStringToTimeZone(
            activity?.transactionDate,
            countryCode
          ) || '-'
        ),
        activityDone: activity?.description,
        pointsEarned: () => {
          if (activity?.pointsEarned.toString().includes('-')) {
            return (
              <span className='text-red-500 text-b4'>
                {activity?.pointsEarned}
              </span>
            )
          }
          return (
            <span className='text-green-500 text-b4'>
              +{activity?.pointsEarned}
            </span>
          )
        },
        pointsBalance: activity?.cumulativePointBalance
      }
    })
  }

  const activityLoyaltyTableData = generateActivityLoyaltyTableData(
    client?.clientRewardHistories || [],
    'NG'
  )
  return (
    <>
      <Modal show={isVisible} closeModal={_closeModal} variant='right'>
        <div className='relative'>
          <div className='fixed bg-white w-full flex border-b border-grey-100 cursor-pointer'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={_closeModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={_closeModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                {client?.firstName} {client?.lastName}
              </Paragraph>
            </div>
          </div>

          <div
            style={{ marginTop: '70px' }}
            className='w-full max-w-5xl flex flex-col space-y-6 h-fit p-6'
          >
            {client ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                  className='hidden xl:flex flex-col xl:flex-row xl:justify-between xl:items-center space-x-4'
                >
                  <div className='flex space-x-4 items-center'>
                    <NameAvatar
                      name={
                        client?.firstName?.charAt(0) +
                        client?.lastName?.charAt(0)
                      }
                      size='lg'
                    />
                    <div className='flex flex-col space-y-2'>
                      <Paragraph size='b2' weight='semiBold'>
                        {client?.firstName} {client?.lastName}
                      </Paragraph>
                      <Paragraph size='b4' weight='normal'>
                        {client?.email}
                      </Paragraph>
                    </div>
                  </div>
                  <div className='flex flex-row space-x-2'>
                    {actions?.editClient ? (
                      <Button
                        variant='secondary'
                        size='sm'
                        type='button'
                        rounded='md'
                        onClick={openEditClientModal}
                      >
                        Edit details
                      </Button>
                    ) : null}
                    {actions?.recordRefund ? (
                      <Button
                        variant='primary'
                        size='sm'
                        type='button'
                        rounded='md'
                        onClick={openRecordRefundModal}
                      >
                        Record refund
                      </Button>
                    ) : null}
                  </div>
                </div>
                <div className='flex xl:hidden justify-between items-center'>
                  <Paragraph size='b4' weight='normal'>
                    Client details
                  </Paragraph>
                  <div className='flex flex-row space-x-2'>
                    <Menu as='div' className='relative inline-block text-left'>
                      <div>
                        <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md border border-grey-50 bg-grey-10 px-3 py-2.5 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85 z-0'>
                          <SvgCharmMenuKebab width='24px' height='24px' />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
                          <div className='flex flex-col p-4 space-y-4'>
                            {actions?.recordRefund ? (
                              <Menu.Item>
                                <span
                                  className='text-b4 cursor-pointer flex items-center space-x-2'
                                  onClick={openRecordRefundModal}
                                >
                                  Record refund
                                </span>
                              </Menu.Item>
                            ) : null}
                            {actions?.editClient ? (
                              <Menu.Item>
                                <span
                                  className='text-b4 cursor-pointer flex items-center space-x-2'
                                  onClick={openEditClientModal}
                                >
                                  Edit details
                                </span>
                              </Menu.Item>
                            ) : null}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </>
            ) : null}

            <div className='flex flex-col justify-between border border-grey-100 rounded-md p-4 space-y-4'>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
                }}
                className='w-full grid grid-cols-2 gap-4 xl:grid-cols-4'
              >
                <div className='flex-col space-y-1'>
                  <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                    First name
                  </Paragraph>
                  <Paragraph size='b4' weight='normal'>
                    {client?.firstName}
                  </Paragraph>
                </div>
                <div className='flex-col space-y-1'>
                  <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                    Last name
                  </Paragraph>
                  <Paragraph size='b4' weight='normal'>
                    {client?.lastName}
                  </Paragraph>
                </div>
                <div className='flex-col space-y-1'>
                  <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                    Phone number
                  </Paragraph>
                  {client?.phone ? (
                    <ContactLink
                      variant='tel'
                      value={`${client?.callingCode}${client?.phone}`}
                      label={`${client?.callingCode}${client?.phone}`}
                    />
                  ) : (
                    <Paragraph size='b4' weight='normal'>
                      -
                    </Paragraph>
                  )}
                </div>
                <div className='flex-col space-y-1'>
                  <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                    Secondary phone number
                  </Paragraph>
                  {client?.altPhone ? (
                    <ContactLink
                      variant='tel'
                      value={`${client?.altCallingCode}${client?.altPhone}`}
                      label={`${client?.altCallingCode}${client?.altPhone}`}
                    />
                  ) : (
                    <Paragraph size='b4' weight='normal'>
                      -
                    </Paragraph>
                  )}
                </div>
                <div className='flex-col space-y-1 col-span-2'>
                  <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                    Email Address
                  </Paragraph>
                  {client?.email ? (
                    <ContactLink
                      variant='mailto'
                      value={client?.email}
                      label={client?.email}
                    />
                  ) : (
                    <Paragraph size='b4' weight='normal'>
                      -
                    </Paragraph>
                  )}
                </div>
                <div className='flex-col space-y-1 col-span-2'>
                  <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                    Birthday
                  </Paragraph>
                  <Paragraph size='b4' weight='normal'>
                    {client?.dob
                      ? getNumberMonthAndYearFromDate(client?.dob)
                      : '-'}
                  </Paragraph>
                </div>
                <div className='flex-col space-y-1 col-span-2'>
                  <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                    Home Address
                  </Paragraph>
                  <Paragraph size='b4' weight='normal'>
                    {client?.address || '-'}
                  </Paragraph>
                </div>
              </div>
              <div className='w-full border-t border-grey-50'></div>
              <div className='flex flex-col xl:flex-row gap-4'>
                <div className='flex-1 flex space-x-2'>
                  <SvgPassed width='24px' height='24px' />
                  <div className='flex flex-col space-y-2'>
                    <Paragraph
                      size='b5'
                      weight='normal'
                      color={COLORS.GREY[300]}
                    >
                      Completed appointments
                    </Paragraph>
                    <Paragraph size='b4' weight='normal'>
                      {client?.completedAppointments}
                    </Paragraph>
                  </div>
                </div>
                <div className='flex-1 flex space-x-2'>
                  <SvgNoShow width='24px' height='24px' />
                  <div className='flex flex-col space-y-2'>
                    <Paragraph
                      size='b5'
                      weight='normal'
                      color={COLORS.GREY[300]}
                    >
                      No-show appointments
                    </Paragraph>
                    <Paragraph size='b4' weight='normal'>
                      {client?.noShowAppointments}
                    </Paragraph>
                  </div>
                </div>
                <div className='flex-1 flex space-x-2'>
                  <SvgCancelled width='24px' height='24px' />
                  <div className='flex flex-col space-y-2'>
                    <Paragraph
                      size='b5'
                      weight='normal'
                      color={COLORS.GREY[300]}
                    >
                      Cancelled appointments
                    </Paragraph>
                    <Paragraph size='b4' weight='normal'>
                      {client?.cancelledAppointments}
                    </Paragraph>
                  </div>
                </div>
                <div className='flex-1 flex space-x-2'>
                  <SvgCartBag width='24px' height='24px' />
                  <div className='flex flex-col space-y-2'>
                    <Paragraph
                      size='b5'
                      weight='normal'
                      color={COLORS.GREY[300]}
                    >
                      Active vouchers
                    </Paragraph>
                    <Paragraph size='b4' weight='normal'>
                      {client?.packageVouchers?.filter(
                        (voucher) => voucher?.status === 'active'
                      )?.length || 0}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-4 justify-between xl:flex-row'>
              <div className='w-full xl:max-w-[300px] flex flex-col border border-grey-100 rounded-md p-6 space-y-6'>
                <SvgShopping width='40px' height='40px' />
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]}>
                    Total transactions
                  </Paragraph>
                  <Paragraph size='b4' weight='bold'>
                    {DEFAULT_CURRENCY}
                    <FormatNumber value={client?.totalTransactions || '0'} />
                  </Paragraph>
                </div>
              </div>
              <div className='w-full xl:max-w-[300px] flex flex-col border border-grey-100 rounded-md p-6 space-y-6'>
                <SvgPercentFrame width='40px' height='40px' />
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]}>
                    Total points
                  </Paragraph>
                  <Paragraph size='b4' weight='bold'>
                    <FormatNumber value={client?.pointsEarned || '0'} />
                  </Paragraph>
                </div>
              </div>
              <div className='w-full xl:max-w-[300px] flex flex-col border border-grey-100 rounded-md p-6 space-y-6'>
                <SvgFileTextFrame width='40px' height='40px' />
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]}>
                    Notes
                  </Paragraph>
                  <div className='flex space-x-4'>
                    {actions?.addNotes ? (
                      <Button
                        variant='light'
                        size='xs'
                        type='button'
                        rounded='lg'
                        className='border border-grey-100'
                        fontSize='b7'
                        onClick={openAddNoteModal}
                      >
                        Add
                        <SvgPlus width='14px' height='14px' />
                      </Button>
                    ) : null}
                    {actions?.viewNotes ? (
                      <Button
                        variant='light'
                        size='xs'
                        type='button'
                        className='border border-grey-100'
                        rounded='lg'
                        fontSize='b7'
                        onClick={openNotesModal}
                      >
                        View <SvgRoundEye width='24px' height='24px' />
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {actions?.viewHistory ? (
            <div className='w-full flex flex-col space-y-4 h-fit pt-6'>
              <div style={{ paddingLeft: '1.5rem' }} className='pl-6'>
                <Heading
                  variant='h2'
                  size='h11'
                  weight='semiBold'
                  color={COLORS.GREY[300]}
                >
                  History
                </Heading>
              </div>
              <Tabs
                tabs={CLIENT_TABS}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                variant='underline'
              />
              {activeTab === CLIENT_TAB_NAME.BOOKING ? (
                <Table
                  headers={BOOKING_HISTORY_HEADERS}
                  mobileHeaders={BOOKING_HISTORY_MOBILE_HEADERS}
                  rows={appointments}
                  onClick={openBooking}
                />
              ) : activeTab === CLIENT_TAB_NAME.PACKAGES ? (
                <Table
                  headers={CLIENT_PACKAGES_HISTORY_HEADERS}
                  mobileHeaders={CLIENT_PACKAGES_HISTORY_MOBILE_HEADERS}
                  rows={packageVouchers}
                  onClick={openSalonPackage}
                />
              ) : activeTab === CLIENT_TAB_NAME.SALES ? (
                <Table
                  headers={SALES_HISTORY_HEADERS}
                  mobileHeaders={BOOKING_HISTORY_MOBILE_HEADERS}
                  rows={sales}
                  onClick={openSale}
                />
              ) : activeTab === CLIENT_TAB_NAME.REWARDS ? (
                <Table
                  headers={REWARDS_HISTORY_HEADERS}
                  mobileHeaders={REWARDS_HISTORY_MOBILE_HEADERS}
                  rows={rewards}
                  onClick={openReward}
                />
              ) : activeTab === CLIENT_TAB_NAME.COMMUNICATION_LOG_HISTORY && actions.viewNotes ? (
                <ClientCommunicationLog />
              ) : (
                <Table
                  headers={formatTableHeadersFilterArray(
                    ACTVITY_LOYALTY_TABLE_HEADERS
                  )}
                  mobileHeaders={MOBILE_ACTIVITY_LOYALTY_TABLE_HEADERS}
                  rows={activityLoyaltyTableData}
                  hasBorder={true}
                />
              )}
            </div>
          ) : null}
        </div>
      </Modal>
      <AddClientModal
        client={client}
        isVisible={isEditClientModalVisible}
        closeModal={closeEditClientModal}
        actions={actions}
      />
      <AddNoteModal
        isVisible={isNoteModalVisible}
        closeModal={initiateCloseAddNotwModal}
        client={client}
      />
      <ViewNotesModal
        isVisible={notesModalIsVisible}
        closeModal={closeNotesModal}
        notes={client?.clientNotes}
        actions={actions}
      />
      <BookingDetailsModal
        isOpen={bookingDetailsModalIsVisible}
        onClose={closeBookingDetailsModal}
        bookingOrSaleData={booking}
      />

      <ClientPackageDetails
        isOpen={clientPackageDetailsModalIsVisible}
        closeModal={closeClientPackageDetailsModal}
        voucher={voucher}
      />
      <ClientRewardDetails
        isOpen={clientRewardDetailsModalIsVisible}
        closeModal={initiateCloseRewardModal}
        reward={reward}
      />
      <RecordRefundModal
        isVisible={isRecordRefundModalVisible}
        closeModal={closeRecordRefundModal}
        client={client}
      />
    </>
  )
}

const ClientCommunicationLog = () => {
  const COMMUNICATION_LOG_HEADINGS = [
    {
      label: 'Subject line',
      value: 'subject',
      show: true
    },
    {
      label: 'Location',
      value: 'salon',
      show: true
    },
    {
      label: 'Body',
      value: 'body',
      show: true
    },
    {
      label: 'Date',
      value: 'date',
      show: true
    },
    {
      label: 'Type',
      value: 'communicationType',
      show: true
    },
    {
      label: 'Status',
      value: 'deliveryStatus',
      show: true
    }
  ]
  const { getCommunicationLogHistory, communicationLogs } = useClientContext()
  const [communicationLogHeadings, setCommunicationLogHeadings] = useState(
    COMMUNICATION_LOG_HEADINGS
  )
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [communicationLog, setCommunicationLog] = useState<CommunicationLog>()
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
  const handleHeadingCheckboxChange = (value: string) => {
    const updatedHeadings = communicationLogHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setCommunicationLogHeadings(updatedHeadings)
  }

  const generateCommunicationLogsHistoryTableData = (
    communicationLogHistory: CommunicationLog[]
  ) => {
    return communicationLogHistory?.map?.((x) => {
      return {
        subject: limitString(x.subject, 25),
        locatin: limitString(x?.salon?.branchName, 25),
        body: limitString(x.body.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '').replace(/<\/?[^>]+(>|$)/g, '').replace(/\n/g, '').replace('/* Email styles need to be inline */', '').trim(), 25),
        date: getNumberMonthAndYearFromDate(
          convertDateTimeStringToTimeZone(
            x?.createdAt,
            "NG"
          )
        ),
        communicationType: () => (
          <Pill
            variant={x.communicationType === 'Reminder' ? 'success' : 'warning'}
          >
            {x.communicationType}
          </Pill>
        ),
        status: () =>
          x.deliveryStatus === 'sent' ? (
            <div className='flex items-center'>
              <SvgGreenSignal width='16px' height='16px' />{' '}
              <span className='ml-2 text-grey-900 text-b5'>Sent</span>
            </div>
          ) : x.deliveryStatus === 'pending' ? (
            <div className='flex items-center'>
              <SvgGreySignal width='16px' height='16px' />{' '}
              <span className='ml-2 text-grey-900 text-b5'>Pending</span>
            </div>
          ) : x.deliveryStatus === 'failed' ? (
            <div className='flex items-center'>
              <SvgRedSignal width='16px' height='16px' />{' '}
              <span className='ml-2 text-grey-900 text-b5'>Failed</span>
            </div>
          ) : null
      }
    })
  }

  const communicationLogHistory = generateCommunicationLogsHistoryTableData(
    communicationLogs?.nodes
  )

  const goToNext = () => {
    if (communicationLogs?.pageInfo?.hasNextPage) {
      getCommunicationLogHistory(
        null,
        null,
        null,
        communicationLogs.pageInfo.endCursor,
        debouncedSearchQuery
      )
    }
  }

  const goToPrev = () => {
    if (communicationLogs?.pageInfo?.hasPreviousPage) {
      getCommunicationLogHistory(
        null,
        null,
        communicationLogs.pageInfo.startCursor,
        null,
        debouncedSearchQuery
      )
    }
  }

  const { openModal, closeModal, isVisible } = useModal()

  const openMessage = (id: number) => {
    setCommunicationLog(communicationLogs.nodes[id])
    openModal()
  }

  useEffect(() => {
    getCommunicationLogHistory(selectedDates[0], selectedDates[1])
  }, [])

  useEffect(() => {
    if (debouncedSearchQuery)
      getCommunicationLogHistory(null, null, null, null, debouncedSearchQuery)
  }, [debouncedSearchQuery])

  return (
    <>
      <div className='w-full flex justify-between items-center space-x-4 p-3'>
        <div className='flex items-center gap-2 w-[300px]'>
          <SearchTerm
            placeholder='Search'
            setDebouncedSearchQuery={setDebouncedSearchQuery}
            size='md'
          />
          <Menu as='div' className='relative inline-block text-left'>
            <div>
              <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                <SvgFilter width='20px' height='20px' />
                <span className='hidden xl:inline'>Filter</span>
              </Menu.Button>
            </div>
            <Transition
              as={React.Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                <div className='flex flex-col p-4 space-y-4'>
                  {Array.isArray(communicationLogHeadings) &&
                    communicationLogHeadings.length &&
                    communicationLogHeadings.map((heading, index) => (
                      <Label
                        className='flex space-x-2 items-center cursor-pointer'
                        key={index}
                        htmlFor={heading.value}
                        onClick={() =>
                          handleHeadingCheckboxChange(heading.value)
                        }
                      >
                        <Checkbox
                          isChecked={heading.show}
                          borderType='Black'
                          size='Sm'
                        />
                        <span>{heading.label}</span>
                      </Label>
                    ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className='flex items-center max-w-[400px]'>
          <Paragraph className=''>Showing:</Paragraph>
          <div className='w-2/3'>
            <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          </div>
          <div className='ml-2'>
            <Paginator
              pageInfo={communicationLogs?.pageInfo}
              {...{ goToNext, goToPrev }}
            />
          </div>
        </div>
      </div>
      <Table
        headers={formatTableHeadersFilterArray(communicationLogHeadings)}
        rows={communicationLogHistory}
        onClick={openMessage}
      />
      <ViewMessageLog
        isVisible={isVisible}
        closeModal={closeModal}
        communicationLog={communicationLog}
      />
    </>
  )
}

type ViewMessageLogProps = {
  communicationLog: CommunicationLog
  closeModal: () => void
  isVisible: boolean
}
const ViewMessageLog = ({
  communicationLog,
  closeModal,
  isVisible
}: ViewMessageLogProps) => {
  const htmlContent = communicationLog?.body
  return (
    <Modal show={isVisible} closeModal={closeModal} variant='right'>
      <div className='relative'>
        <div className='fixed bg-white w-full flex border-b border-grey-100 cursor-pointer'>
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
            </Button>
          </div>
        </div>

        <div
          style={{ marginTop: '70px' }}
          className='w-full max-w-5xl flex flex-col space-y-6 h-fit p-6'
        >
          <Paragraph className='mb-2' size='b2' weight='extraBold'>
            Message details
          </Paragraph>
          <Paragraph size='b4' color={COLORS.GREY[400]}>
            Find information about message here
          </Paragraph>

          <div className='w-full border border-grey-100'>
            <div className='flex justify-between p-4 mb-6'>
              <Paragraph className='' color={COLORS.GREY[200]}>
                Type
              </Paragraph>
              <Pill
                variant={
                  communicationLog?.communicationType === 'Reminder'
                    ? 'success'
                    : 'warning'
                }
              >
                {communicationLog?.communicationType}
              </Pill>
            </div>

            <div className='flex justify-between p-4 mb-6'>
              <Paragraph color={COLORS.GREY[200]}>Status</Paragraph>
              {communicationLog?.deliveryStatus === 'sent' ? (
                <div className='flex items-center'>
                  <SvgGreenSignal width='16px' height='16px' />{' '}
                  <span className='ml-2 text-grey-900 text-b5'>Sent</span>
                </div>
              ) : communicationLog?.deliveryStatus === 'pending' ? (
                <div className='flex items-center'>
                  <SvgGreySignal width='16px' height='16px' />{' '}
                  <span className='ml-2 text-grey-900 text-b5'>Pending</span>
                </div>
              ) : communicationLog?.deliveryStatus === 'failed' ? (
                <div className='flex items-center'>
                  <SvgRedSignal width='16px' height='16px' />{' '}
                  <span className='ml-2 text-grey-900 text-b5'>Failed</span>
                </div>
              ) : null}
            </div>

            <div className='flex justify-between p-4 mb-6'>
              <Paragraph color={COLORS.GREY[200]}>Subject line</Paragraph>
              <Paragraph>{communicationLog?.subject}</Paragraph>
            </div>

            <div className='flex justify-center p-4 mb-6'>
              {/* <Paragraph className='w-[300px]' color={COLORS.GREY[200]}>Body</Paragraph> */}
              <div className='border border-grey-100 p-4'>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

type ViewNotesProps<T extends string> = {
  isVisible: boolean
  closeModal: () => void
  notes: IClientNoteToggleProps[]
  actions: PermissionActionProp<T>
}
type IClientNoteToggleProps = ClientNote & {
  show: boolean
}
const ViewNotesModal = ({
  isVisible,
  closeModal,
  notes: n,
  actions
}: ViewNotesProps<'editNote' | 'deleteNote'>) => {
  const [notes, setNotes] = useState<IClientNoteToggleProps[]>([])
  const {
    deleteClientNote,
    createClientNote,
    createClientNoteAttachment,
    deleteClientNoteAttachment
  } = useClientContext()
  const [sortBy, setSortBy] = useState<string>('recent')
  const [selectedNote, setSelectedNote] = useState<ClientNote | null>(null)
  const [editNoteScreen, setEditNoteScreen] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<string[]>([])
  const [saving, setSaving] = useState(false)
  const [savingAttachment, setSavingAttachment] = useState(false)
  const { handleSubmit, control, setValue } = useForm<CreateClientNoteInput>()
  const sortArray = (sortType: string) => {
    if (sortBy !== sortType) {
      const sortedNotes = notes.reverse()
      setSortBy(sortType)
      setNotes(sortedNotes)
    }
  }

  useEffect(() => {
    setNotes(n)
  }, [n])

  const _closeModal = () => {
    setEditNoteScreen(false)
    setSelectedNote(null)
    setAttachments([])
    // setNotes([])
    closeModal()
  }

  const editNote = (note: ClientNote) => {
    toggleShow(note?.id)
    setEditNoteScreen(true)
    setSelectedNote(note)
    setAttachments(note?.clientNoteAttachments.map((x) => x?.imageUrl))
  }

  useEffect(() => {
    if (selectedNote) {
      setValue('note', selectedNote?.note)
      setValue('title', selectedNote?.title)
    }
  }, [selectedNote, setValue])

  const toggleShow = (noteId: string) => {
    if (editNoteScreen) setEditNoteScreen(false)
    const updatedNotes = notes.map((note: IClientNoteToggleProps) => ({
      ...note,
      show: note.id === noteId ? !note.show : false // Toggle show for the clicked note, set others to false
    }))
    // fetch the current selected note from updateNotes
    const selectedNote = updatedNotes.find((note) => note.id === noteId)

    if (selectedNote && selectedNote.show) {
      setSelectedNote(selectedNote)
      setAttachments(selectedNote.clientNoteAttachments.map((x) => x.imageUrl))
    } else {
      setSelectedNote(null)
      setAttachments([])
    }
    // Update the notes state with the new array
    setNotes(updatedNotes)
  }

  const closeNoteEditForm = () => {
    setEditNoteScreen(false)
    setSelectedNote(null)
    setAttachments([])
  }

  const saveNote = async (input: CreateClientNoteInput) => {
    setSaving(true)
    await createClientNote({
      id: selectedNote.id,
      title: input.title,
      note: input.note,
      clientId: selectedNote.clientId
    })
    setSaving(false)
    closeNoteEditForm()
  }

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    note: ClientNote
  ) => {
    setSavingAttachment(true)
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      const { url } = await useFileUploader_(base64DataUri)
      await createClientNoteAttachment({ imageUrl: url, clientNoteId: note.id })
      setSavingAttachment(false)
    }

    reader.readAsDataURL(file)
  }

  const downloadFileAttachment = (fileName: string, fileUrl: string) => {
    saveAs(fileUrl, fileName) // Put your image URL here.
  }

  const removeFileAttachment = async (imageUrl: string) => {
    // Use the filter method to create a new array without imgUrlToRemove
    await deleteClientNoteAttachment(imageUrl)
    const updatedAttachments = attachments.filter(
      (imgUrl) => imgUrl !== imageUrl
    )

    // Update the state with the new array of attachments
    setAttachments(updatedAttachments)
    // modifyAttachment("removed", updatedAttachments);
  }

  const getNotesContent = () => {
    if (Array.isArray(notes) && notes.length > 0) {
      return notes.map((note: IClientNoteToggleProps, index) => {
        if (note?.note) {
          return (
            <form
              onSubmit={handleSubmit(saveNote)}
              key={index}
              className='flex flex-col space-y-4 border border-grey-20 rounded-md p-5'
            >
              <div className='flex flex-col space-y-2'>
                <div className='w-full flex justify-between'>
                  <div className='flex space-x-4 items-center'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>
                      {getNumberMonthAndYearFromDate(
                        convertDateTimeStringToTimeZone(note?.createdAt, 'NG')
                      )}
                    </Paragraph>
                    {note?.addedBy === 'appointment' ? (
                      <Pill variant={'light'}>Appointment note</Pill>
                    ) : !editNoteScreen ? (
                      <ReactMenu.Menu
                        menuButton={
                          <ReactMenu.MenuButton className=''>
                            <SvgCharmMenuKebab width='20px' height='20px' />
                          </ReactMenu.MenuButton>
                        }
                        key='bottom'
                        direction='bottom'
                        transition
                      >
                        {actions?.editNote ? (
                          <ReactMenu.MenuItem>
                            <span
                              className='text-b4 cursor-pointer flex items-center space-x-2'
                              onClick={() => {
                                editNote(note)
                              }}
                            >
                              <SvgEditRegular width='20px' height='20px' />{' '}
                              <span>Edit</span>
                            </span>
                          </ReactMenu.MenuItem>
                        ) : null}
                        {actions?.deleteNote ? (
                          <ReactMenu.MenuItem>
                            <span
                              className='text-b4 cursor-pointer flex items-center space-x-2'
                              onClick={() => deleteClientNote(note.id)}
                            >
                              <SvgDeleteRegular width='20px' height='20px' />{' '}
                              <span>Delete</span>
                            </span>
                          </ReactMenu.MenuItem>
                        ) : null}
                      </ReactMenu.Menu>
                    ) : null}
                  </div>
                  <>
                    {note?.show ? (
                      <Button
                        variant='icon'
                        size='none'
                        type='button'
                        className='border-0'
                        onClick={() => toggleShow(note.id)}
                      >
                        <SvgRoundArrowUp width='28px' height='28px' />
                      </Button>
                    ) : (
                      <Button
                        variant='icon'
                        size='none'
                        type='button'
                        className='border-0'
                        onClick={() => toggleShow(note.id)}
                      >
                        <SvgRoundArrowDown width='28px' height='28px' />
                      </Button>
                    )}
                  </>
                </div>
                {editNoteScreen &&
                selectedNote &&
                selectedNote?.id === note?.id ? (
                  <>
                    <Input
                      name='title'
                      label='Title'
                      id='title'
                      type='text'
                      placeholder='Write a title'
                      control={control}
                      // error={errors.title}
                    />
                    <Controller
                      control={control}
                      name='note'
                      render={({
                        field: { onChange, onBlur, name, ref, value },
                        formState: { errors }
                      }) => {
                        const maxLength = 1000
                        const formErrorsHelpTexts =
                          getHelperTextForReactHookFormErrors(
                            errors?.note?.message as string
                          )
                        const helperTexts = []

                        helperTexts.push(
                          getHelpTextForCharacterLeft(maxLength, value)
                        )
                        if (formErrorsHelpTexts)
                          helperTexts.push(formErrorsHelpTexts)

                        return (
                          <FormTextarea
                            type='text'
                            id='note'
                            label='Modify note'
                            placeholder='Add a note'
                            {...{
                              ref,
                              name,
                              value,
                              onChange,
                              onBlur,
                              maxLength
                            }}
                            helperText={helperTexts}
                          />
                        )
                      }}
                    />
                    <div className='flex flex-row space-x-2'>
                      <Button
                        variant='primary'
                        size='sm'
                        type='submit'
                        rounded='md'
                        disabled={saving}
                        loading={saving}
                      >
                        Save
                      </Button>
                      <Button
                        variant='secondary'
                        size='sm'
                        type='button'
                        rounded='md'
                        onClick={closeNoteEditForm}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : !note?.id ? (
                  note?.title ? (
                    <>
                      <Paragraph size='b4' color={COLORS.BLACK}>
                        {note?.title}
                      </Paragraph>
                    </>
                  ) : (
                    <Paragraph size='b4' color={COLORS.BLACK}>
                      {note?.note}
                    </Paragraph>
                  )
                ) : (
                  <>
                    <Paragraph size='b4' weight='semiBold' color={COLORS.BLACK}>
                      {note?.title}
                    </Paragraph>

                    <Paragraph size='b4' color={COLORS.BLACK}>
                      {note?.note}
                    </Paragraph>
                  </>
                )}
              </div>
              {note?.id ? (
                <div className='flex flex-col space-y-6'>
                  <div className='flex justify-between items-center'>
                    <div
                      className='cursor-pointer'
                      onClick={() => toggleShow(note?.id)}
                    >
                      <Paragraph
                        size='b4'
                        weight='normal'
                        color={COLORS.GREY[300]}
                      >
                        Attachments ({note?.clientNoteAttachments?.length || 0})
                      </Paragraph>
                    </div>
                    <div className='relative cursor-pointer'>
                      <Button
                        key={note.id}
                        variant='light'
                        size='xs'
                        type='button'
                        rounded='sm'
                        fontSize='b4'
                        className='text-grey-300'
                        disabled={savingAttachment}
                        loading={savingAttachment}
                      >
                        <SvgPlus width='24px' height='24px' />
                        Add new
                      </Button>
                      <input
                        className='cursor-pointer absolute block opacity-0 top-0 w-full h-[40px]'
                        type='file'
                        accept='*'
                        onChange={(e) => handleFileUpload(e, note)}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col space-y-4'>
                    {Array.isArray(attachments) &&
                      attachments.length > 0 &&
                      note.id === selectedNote.id &&
                      attachments.map((attachment, index) => (
                        <div
                          className='flex flex-col xl:flex-row space-y-3 xl:space-y-0 xl:justify-between items-center py-2 px-4 border border-grey-20 rounded-md'
                          key={index}
                        >
                          <div className='flex space-x-2 items-center'>
                            <SvgMdiLightImage width='2rem' height='2rem' />
                            <Paragraph size='b5'>
                              {limitString(attachment, 25)}
                            </Paragraph>
                          </div>
                          <div className='flex space-x-2'>
                            <PhotoProvider>
                              <PhotoView src={attachment}>
                                <Button
                                  variant='icon'
                                  size='none'
                                  type='button'
                                  className='border-0'
                                >
                                  <SvgRoundEye width='28px' height='28px' />
                                </Button>
                              </PhotoView>
                            </PhotoProvider>
                            <Button
                              variant='icon'
                              size='none'
                              type='button'
                              className='border-0'
                              onClick={() =>
                                downloadFileAttachment(attachment, attachment)
                              }
                            >
                              <SvgRoundDownload width='28px' height='28px' />
                            </Button>
                            <Button
                              variant='icon'
                              size='none'
                              type='button'
                              className='border-0'
                              onClick={() => removeFileAttachment(attachment)}
                            >
                              <SvgRoundTrash width='28px' height='28px' />
                            </Button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div className='border flex space-x-1 py-2 px-3 border-grey-300 w-fit bg-grey-75 rounded-md'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    {note?.clientNoteAttachments?.length}
                  </Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    attachments
                  </Paragraph>
                </div>
              )}
            </form>
          )
        }
      })
    }
  }

  return (
    <Modal show={isVisible} closeModal={_closeModal}>
      <div className='flex justify-between'>
        <div className='w-full flex flex-col py-4 space-y-6'>
          <Heading variant='h1' size='h9' weight='semiBold'>
            Notes
          </Heading>
          <Paragraph size='b4'>Notes about client</Paragraph>
        </div>
        <div className='w-1/2 flex justify-end items-center'>
          <ReactMenu.Menu
            menuButton={
              <ReactMenu.MenuButton
                style={{ padding: '0.5rem 1rem 0.5rem 1rem' }}
                className='flex items-center space-x-2 border border-grey-100 bg-grey-20 px-4 py-2 rounded-sm'
              >
                <span>Sort by: {sortBy}</span>{' '}
                <SvgArrowDown width='10px' height='10px' />
              </ReactMenu.MenuButton>
            }
            key='bottom'
            direction='bottom'
            transition
          >
            <ReactMenu.MenuItem>
              <span
                className='text-grey-300 text-b4 cursor-pointer'
                onClick={() => sortArray('recent')}
              >
                Recent
              </span>
            </ReactMenu.MenuItem>
            <ReactMenu.MenuItem>
              <span
                className='text-grey-300 text-b4 cursor-pointer'
                onClick={() => sortArray('oldest')}
              >
                Oldest
              </span>
            </ReactMenu.MenuItem>
          </ReactMenu.Menu>
        </div>
      </div>
      <div className='flex flex-col space-y-6 my-12'>{getNotesContent()}</div>
      <Button
        variant='primary'
        className=''
        size='lg'
        rounded='lg'
        onClick={_closeModal}
      >
        Close
      </Button>
    </Modal>
  )
}
type ClientNoteAdditionModalProps = {
  isVisible: boolean
  closeModal: (action?: string) => void
  client?: Client
  setClient?: (client: Client | null) => void
}
const AddNoteModal = ({
  isVisible,
  closeModal,
  client
}: ClientNoteAdditionModalProps) => {
  const { createClientNote } = useClientContext()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid }
  } = useForm<CreateClientNoteInput>({ delayError: 100, mode: 'onChange' })
  const [attachments, setAttachments] = useState<string[]>([])
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  // const [imageUrl, setImageUrl] = useState()

  const closeAddNoteModal = () => {
    closeModal()
  }

  const handleAdd = async (input: CreateClientNoteInput) => {
    setIsCreating(true)
    await createClientNote({
      ...input,
      clientId: client.id,
      imageUrl: attachments
    })
    setIsCreating(false)
    resetForm()
    setAttachments([])
    closeModal('added')
  }

  const resetForm = () => {
    setValue('title', '')
    setValue('note', '')
  }

  const removeFileAttachment = (imgUrlToRemove: string) => {
    // Use the filter method to create a new array without imgUrlToRemove
    const updatedAttachments = attachments.filter(
      (imgUrl) => imgUrl !== imgUrlToRemove
    )

    // Update the state with the new array of attachments
    setAttachments(updatedAttachments)
  }

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsFileUploading(true)
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      const { url } = await useFileUploader_(base64DataUri)
      setIsFileUploading(false)
      setAttachments([...attachments, url])
    }

    reader.readAsDataURL(file)
  }

  return (
    <Modal
      title='Notes'
      subTitle='Notes about client'
      show={isVisible}
      closeModal={closeModal}
    >
      <form
        onSubmit={handleSubmit(handleAdd)}
        className='w-full my-6 space-y-4'
        autoComplete='off'
      >
        <Input
          name='title'
          label='Title'
          id='title'
          type='text'
          placeholder='Write a title'
          control={control}
          rules={{
            required: ERRORS.TITLE_REQUIRED
          }}
          error={errors.title}
        />
        <Controller
          control={control}
          name='note'
          render={({
            field: { onChange, onBlur, name, ref, value },
            formState: { errors }
          }) => {
            const maxLength = 1000
            const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
              errors?.note?.message as string
            )
            const helperTexts = []

            helperTexts.push(getHelpTextForCharacterLeft(maxLength, value))
            if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts)

            return (
              <FormTextarea
                type='text'
                id='note'
                label='Write a note'
                placeholder='Add a note'
                {...{
                  ref,
                  name,
                  value,
                  onChange,
                  onBlur,
                  maxLength
                }}
                helperText={helperTexts}
              />
            )
          }}
        />
        {attachments.length === 0 ? (
          <div className='w-full flex flex-col space-y-2'>
            <FormLabel htmlFor='attachment'>Attachment</FormLabel>
            <div className='w-full relative items-center cursor-pointer border rounded-md py-3 border-grey-20 justify-center'>
              <input
                style={{ height: '58px' }}
                className='cursor-pointer absolute block opacity-0 top-0 w-full'
                type='file'
                accept='.png,.jpeg,.jpg'
                onChange={handleFileUpload}
              />
              {isFileUploading ? (
                <div className='flex justify-center gap-x-2 py-3 cursor-pointer'>
                  {[1, 2, 3].map((item, index) => (
                    <div
                      key={index}
                      className='h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse'
                    ></div>
                  ))}
                </div>
              ) : (
                <div className={`w-full flex space-x-2 px-4 justify-center`}>
                  <div className='flex space-x-2 items-center'>
                    <SvgUploadCloud width='2rem' height='2rem' />
                    <Paragraph size='b5'>
                      Upload file or drop file here
                    </Paragraph>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className='flex flex-col space-y-6'>
            <div className='flex justify-between items-center'>
              <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]}>
                Attachments ({attachments?.length || 0})
              </Paragraph>
              <div className='relative cursor-pointer'>
                <Button
                  variant='light'
                  size='xs'
                  type='button'
                  rounded='sm'
                  fontSize='b4'
                  className='text-grey-300'
                  disabled={isFileUploading}
                  loading={isFileUploading}
                >
                  <SvgPlus width='24px' height='24px' />
                  Add new
                </Button>
                <input
                  className='cursor-pointer absolute block opacity-0 top-0 w-full h-[40px]'
                  type='file'
                  accept='.png,.jpeg,.jpg'
                  onChange={handleFileUpload}
                />
              </div>
            </div>
            <div className='flex flex-col space-y-4'>
              {Array.isArray(attachments) &&
                attachments.length > 0 &&
                attachments.map((attachment, index) => (
                  <div
                    className='flex flex-col xl:flex-row space-y-3 xl:space-y-0 xl:justify-between items-center py-2 px-4 border border-grey-20 rounded-md'
                    key={index}
                  >
                    <div className='flex space-x-2 items-center w-[70%]'>
                      <SvgMdiLightImage width='2rem' height='2rem' />
                      <Paragraph size='b5'>{attachment}</Paragraph>
                    </div>
                    <div className='flex space-x-2'>
                      <Button
                        variant='icon'
                        size='none'
                        type='button'
                        className='border-0'
                        onClick={() => removeFileAttachment(attachment)}
                      >
                        <SvgRoundTrash width='28px' height='28px' />
                      </Button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        <Button
          variant='primary'
          className=''
          disabled={!isValid}
          loading={isCreating}
          size='lg'
          rounded='lg'
        >
          Submit
        </Button>
      </form>
      <div style={{ marginTop: '30px' }}>
        <Button
          variant='text'
          className='mx-auto'
          size='none'
          onClick={closeAddNoteModal}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

type RecordRefundModal = {
  isVisible: boolean
  closeModal: () => void
  client: Client
}
const RecordRefundModal = ({ isVisible, closeModal, client }) => {
  const { recordRefund, recordSaleRefund } = useClientContext()
  const [saving, setSaving] = useState(false)
  const { handleSubmit, setValue, control } = useForm<RecordRefundInput>()

  const submitRefund = async (input: RecordRefundInput) => {
    // @ts-expect-error Definition is correct
    const inputId = input?.appointmentId?.value || ''
    const id = inputId?.split(':')[0]
    const type = inputId?.split(':')[1]
    setSaving(true)
    const payload = {
      refundReason: input.refundReason,
      totalAmountRefunded: Number(
        input.totalAmountRefunded.toString().replace(/,/g, '')
      ),
      ...(type === 'appointment' ? { appointmentId: id } : { saleId: id })
    }
    if (type === 'appointment') {
      await recordRefund(payload, handleClose)
    } else {
      await recordSaleRefund(payload, handleClose)
    }
    setSaving(false)
  }

  const handleClose = () => {
    setValue('appointmentId', '')
    setValue('totalAmountRefunded', 0)
    setValue('refundReason', '')
    closeModal()
  }

  const formatBookingsToSelectField = (bookings: Appointment[]) => {
    const completedBookings = bookings?.filter(
      (booking) => booking.appointmentStatus === 'completed'
    )

    return completedBookings?.map((booking) => ({
      label:
        limitString(convertServicesNamesToString(booking.services), 15) +
        ' - ' +
        getDayMonthAndNumberAndTimeFromDate(booking.startAt),
      value: booking.id + ':appointment'
    }))
  }

  const formatSaleToSelectField = (bookings: Sale[]) => {
    return Array.isArray(bookings)
      ? bookings
          .filter((booking) => booking.saleStatus !== 'unpaid')
          .map((booking) => ({
            label:
              limitString(
                convertServicesNamesToString(
                  booking.services as unknown as Service[]
                ),
                15
              ) +
              ' - ' +
              getDayMonthAndNumberAndTimeFromDate(booking.startAt),
            value: booking.id + ':sale'
          }))
      : []
  }
  const appointmentOptions = formatBookingsToSelectField(
    client?.appointments ?? []
  )
  const saleOptions = formatSaleToSelectField(client?.sales ?? [])
  const options = [...appointmentOptions, ...saleOptions]
  return (
    <Modal title='Record a refund' show={isVisible} closeModal={handleClose}>
      <form
        onSubmit={handleSubmit(submitRefund)}
        className='w-full my-6 space-y-6'
        autoComplete='off'
      >
        <div className='flex flex-col space-y-2'>
          <Controller
            control={control}
            name='appointmentId'
            render={({ field: { onChange, value }, formState: { errors } }) => {
              const errorMessage: string = errors?.appointmentId?.message
              return (
                <>
                  <FormLabel htmlFor='service'>
                    Select sale/appointment
                  </FormLabel>
                  <SingleSelect
                    // @ts-expect-error Value is correct
                    selected={value || []}
                    options={options}
                    setSelected={onChange}
                  />
                  {errorMessage && (
                    <FormHelperText variant='error'>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </>
              )
            }}
            rules={{
              required: 'Select a client'
            }}
          />
        </div>
        <Controller
          control={control}
          name='totalAmountRefunded'
          rules={{ required: 'Please enter amount' }}
          render={({ field, formState: { errors } }) => (
            <div className='w-full flex flex-col space-y-2'>
              <FormLabel htmlFor='price'>Amount</FormLabel>
              <input
                className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                type='text'
                {...field}
                value={formatNumber(field.value)}
                onChange={(e) => field.onChange(formatNumber(e.target.value))}
                placeholder='Enter price'
              />
              {errors?.totalAmountRefunded?.message && (
                <FormHelperText variant='error'>
                  {errors.totalAmountRefunded.message}
                </FormHelperText>
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          name='refundReason'
          render={({
            field: { onChange, onBlur, name, ref, value },
            formState: { errors }
          }) => {
            const maxLength = 400
            const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
              errors?.refundReason?.message as string
            )
            const helperTexts = []

            helperTexts.push(getHelpTextForCharacterLeft(maxLength, value))
            if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts)

            return (
              <FormTextarea
                type='text'
                id='about'
                label='Write a reason for refund'
                placeholder='Add a note'
                {...{
                  ref,
                  name,
                  value,
                  onChange,
                  onBlur,
                  maxLength
                }}
                helperText={helperTexts}
              />
            )
          }}
        />
        <Button
          variant='primary'
          className=''
          disabled={saving}
          loading={saving}
          size='lg'
          rounded='lg'
        >
          Submit
        </Button>
      </form>
      <Button
        variant='text'
        className='mx-auto'
        size='none'
        onClick={handleClose}
      >
        Cancel
      </Button>
    </Modal>
  )
}

type DeleteClientModalProps = {
  isVisible: boolean
  closeModal: () => void
  client: Client
  setClient: (client: Client | null) => void
}
const DeleteClientDialog = ({
  client,
  isVisible,
  closeModal,
  setClient
}: DeleteClientModalProps) => {
  const [loading, setIsLoading] = useState(false)
  const { deleteClient } = useClientContext()

  const handleDelete = async () => {
    setIsLoading(true)
    await deleteClient({ id: client.id })
    setIsLoading(false)
    setClient(null), closeModal()
  }

  const cancelAction = () => {
    setClient(null)
    closeModal()
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Client
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {client?.firstName}? This means
                that this client will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={loading}
                loading={false}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={cancelAction}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

type ClientRewardDetails = {
  reward: ClientMilestone
  isOpen: boolean
  closeModal: () => void
}

const ClientRewardDetails = ({
  reward,
  isOpen,
  closeModal
}: ClientRewardDetails) => {
  const getUniqueStaffNames = (data) => {
    if (!data || !Array.isArray(data)) {
      return []
    }

    const staffNames = new Set()

    data.forEach((service) => {
      const staff =
        service?.appointmentServiceStaff?.[0]?.salonStaff?.user?.firstName
      if (staff) {
        staffNames.add(staff)
      }
    })

    return Array.from(staffNames) as unknown as string
  }
  const appointment = reward?.appointment || null
  const services = appointment?.appointmentServices || []
  const staff = services?.length ? getUniqueStaffNames(services) : 'N/A'
  const rewardMap = reward
    ? [
        {
          title: 'Date reached',
          value: getNumberMonthAndYearFromDate(
            convertDateTimeStringToTimeZone(reward?.createdAt, 'NG') || '-'
          )
        },
        {
          title: 'Date redeemed',
          value: appointment
            ? getNumberMonthAndYearFromDate(
                convertDateTimeStringToTimeZone(appointment?.startAt, 'NG')
              )
            : 'N/A'
        },
        {
          title: `Points ${appointment ? 'used' : 'earned'}`,
          value: reward?.milestone?.customerPointsAttained + 'pts'
        },
        {
          title: 'Services',
          value:
            Array?.isArray(services) && services?.length
              ? services?.map((service) => service?.name).join(', ')
              : 'N/A'
        },
        {
          title: 'Staff',
          value: staff
        },
        {
          title: 'Discount',
          value: appointment
            ? formatInToPrice(appointment?.discountAmount)
            : 'N/A'
        }
      ]
    : []
  return (
    <Modal show={isOpen} closeModal={closeModal} variant='right'>
      <div className='w-full relative my-[80px]'>
        <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
            </Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgChevronLeft width='24px' height='24px' />
            </Button>
            <Paragraph size='b3' weight='bold'>
              View Reward Details
            </Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          <div className='w-full flex items-center justify-between'>
            <div className='flex flex-col space-y-6'>
              <Heading variant='h1' size='h9' weight='semiBold'>
                Reward details
              </Heading>
              <Paragraph size='b4'>
                Find information about reward here
              </Paragraph>
            </div>
          </div>
          {reward && rewardMap?.length ? (
            <div className='w-full flex flex-col space-y-2 border border-grey-50 p-4'>
              {Array?.isArray(rewardMap) && rewardMap?.length
                ? rewardMap?.map((dataReward, index) => {
                    return (
                      <div
                        className='w-full p-3 flex justify-between items-center space-x-4 bg-grey-10'
                        key={index}
                      >
                        <Paragraph
                          size='b4'
                          color='grey-300'
                          className='text-left'
                        >
                          {dataReward?.title}
                        </Paragraph>

                        <Paragraph
                          size='b4'
                          color='grey-800'
                          weight='semiBold'
                          className='text-right'
                        >
                          {dataReward?.value}
                        </Paragraph>
                      </div>
                    )
                  })
                : null}
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default Clients
