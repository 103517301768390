export const ERRORS = {
  // Auth
  USER_NOT_FOUND: "Account doesn't exist",
  REGISTRATION_FAILED: "An error occurred while signing up, please try again",
  LOGIN_FAILED: "An error occurred while logging in, please try again",
  VERIFY_EMAIL_FAILED:
    "An error occurred while verifying your email, please try again",
  RESEND_VERIFICATION_EMAIL_FAILED:
    "An error occurred while resending verification email, please try again",
  LOGOUT_FAILED: "An error occurred while logging out, please try again",
  NOT_AUTHORIZED: "Not Authorised!",
  CLIENT_NOT_FOUND: "Client not found",

  // Form Input
  EMAIL_INVALID: "Email is not valid",
  EMAIL_REQUIRED: "Email is required",
  PHONE_INVALID: "Phone number is not valid",
  PHONE_REQUIRED: "Phone number is required",
  FIRST_NAME_REQUIRED: "First name is required",
  LAST_NAME_REQUIRED: "Last name is required",
  ONLY_LETTERS: "Only letters are allowed",
  ONLY_NUMBERS: "Only numbers are allowed",
  NUMBER_INVALID: "Number is not valid",
  BUSINESS_NAME_REQUIRED: "Business name is required",
  BUSINESS_BRANCH_NAME_REQUIRED: "Business branch name is required",
  ROLE_REQUIRED: "Role is required",
  PERMISSION_LEVEL_REQUIRED: "Permission level is required",
  PASSWORD_REQUIRED: "Password is required",
  PASSWORD_STRENGTH: "Password doesn't meet our strength requirements.",
  PASSWORD_MISMATCH: "Passwords do not match",
  CITY_REQUIRED: "City is required",
  STATE_REQUIRED: "State is required",
  ADDRESS_REQUIRED: "Business Address is required",
  HOME_ADDRESS_REQUIRED: "Home Address is required",
  BUSINESS_LOGO_REQUIRED: "Business Logo is required",
  BUSINESS_HEADER_IMAGE_REQUIRED: "Business Header Image is required",
  BVN_REQUIRED: "Bank Verification Number (BVN) is required",
  TIME_REQUIRED: "Time is required",
  WRONG_TIME_FORMAT: "Time format is wrong",
  PRICE_INVALID: "Price is not valid",
  SOCIAL_URL_INVALID: "Social URL is not valid",
  SOCIAL_URL_REQUIRED: "Social URL is required",
  APPOINTMENT_LINK_NAME_REQUIRED: "Booking link is required",
  NAME_REQUIRED: "Full name is required",
  DATE_REQUIRED: "Date is required",
  TITLE_REQUIRED: "Title is required",
  BOOKING_MANAGEMENT_REQUIRED: "Booking Management is required",
  PROMO_INVALID_DATES: "Kindly check your dates",
  PROMO_PREFERENCE_REQUIRED: "Promo Preference is required",
  LOYALTY_PREFERENCE_REQUIRED: "Loyalty Preference is required",
  CLIENTS_REQUIRED: "Clients is required",
  STAFF_SPECIALTY_REQUIRED: "Staff Specialty is required",
  AMOUNT_REQUIRED: "Amount is required",
  PRODUCT_TYPE_REQUIRED: "Product type is required",
  CSV_FILE_REQUIRED: "Product file is required",
  DEPOSIT_INTERVAL_REQUIRED: "Deposit duration is required",
  POS_REQUIRED: "Pos Device is required",
  TRANSFER_ACCOUNT_REQUIRED: "Transfer Account is required",
  COUNTRY_REQUIRED: "Country is required",
  RC_NUMBER_REQUIRED: "RC Number is required",

  // account number
  ACCOUNT_NUMBER_REQUIRED: "Account Number is required",
  ACCOUNT_NUMBER_INVALID: "Account Number must be 10 digits",
  BANK_NAME_REQUIRED: "Bank Name is required",

  // generic
  SOMETHING_WENT_WRONG: "Something went wrong",
  REFRESH_PAGE: "Please try refreshing this page.",

  FAILED_TO_INITIATE_PAYMENT:
    "Unable to initiate payment, please try again later.",

  // appointments
  APPOINTMENT_NOT_FOUND: "Appointment not found",
  APPOINTMENT_ALREADY_BOOKED: "Appointment already booked",
  APPOINTMENT_ALREADY_CANCELLED: "Appointment already cancelled",
  APPOINTMENT_ALREADY_COMPLETED: "Appointment already completed",
  APPOINTMENT_BOOKING_FAILED: "Appointment booking failed",
  APPOINTMENT_CANCEL_FAILED: "Appointment cancel failed",
  APPOINTMENT_BOOKING_COMPLETED: "Appointment booking completed",
  NOTE_REQUIRED: "Note is required",
  STAFF_REQUIRED: "Staff is required",
  APPOINTMENT_DATE_REQUIRED: "Appointment date is required",
  TEAM_SIZE_REQUIRED: "Team size is required",
  CATEGORY_REQUIRED: "Category is required",

  POINTS_REQUIRED: "Points is required",
  LOYALTY_NAME_REQUIRED: "Loyalty name is required",
  DESCRIPTION_REQUIRED: "Description is required",
  SPENDING_THRESHOLD_REQUIRED: "Promo value is required",
  DISCOUNT_REQUIRED: "Discount is required",
  PROMO_NAME_REQUIRED: "Promo name is required",
  PROMO_TYPE_REQUIRED: "Promo type is required",
  DEPOSIT_TYPE_REQUIRED: "Deposit type is required",
  BIRTHDAY_REQUIRED: "Birthday is required",
  INTERVAL_REQUIRED: "Interval is required",
  CLIENT_REQUIREMENT_REQUIRED: "Client requirement is required",
  MESSAGING_TITLE_REQUIRED: "Messaging title is required",
  BVN_NUMBER_INVALID: "Bvn is invalid",
  RECIPIENT_REQUIRED: "Recipient is required",
  POS_NAME_REQUIRED: "Point of sale terminal name is required",
  REASON_REQUIRED: "Reason is required",
  ADDON_AMOUNT_REQUIRED: "Addon amount is required",
  PRICE_TYPE_REQUIRED: "Price type is required",
  PACKAGE_NAME_REQUIRED: "Salon Package name is required",
  VALID_FOR_REQUIRED: "Valid for is required",
  SALON_PACKAGE_STATUS_REQUIRED: "Salon package status is required",
  VOUCHER_NAME_REQUIRED: "Voucher name is required",
  VOUCHER_CODE_REQUIRED: "Voucher code is required",
  VOUCHER_EXPIRY_REQUIRED: "Voucher expiry is required",
  VOUCHER_PERCENTAGE_REQUIRED: "Voucher type is required",
  CLIENT_SPEND_REQUIRED: "Client spend is required",
  CLIENT_USE_LIMIT_REQUIRED: "Client use limit is required",
};


export const API_ERRORS = {
  USER_NOT_FOUND: "Account doesn't exist",
  USER_UPDATE_FAILED: "An error occurred while updating user, please try again",
  REGISTRATION_FAILED: "An error occurred while signing up, please try again",
  LOGIN_FAILED: "An error occurred while logging in, please try again",
  FORGOT_PASSWORD_FAILED: "An error occurred, please try again",
  RESET_PASSWORD_FAILED: "An error occurred while resetting your password, please try again",
  PASSWORD_CHANGE_FAILED: "An error occurred while changing your password, please try again",
  VERIFY_EMAIL_FAILED: "An error occurred while verifying your email, please try again",
  RESEND_VERIFICATION_EMAIL_FAILED: "An error occurred while resending verification email, please try again",
  APPOINTMENT_LINK_FAILED: "An error occurred while creating appointment link, please try again",
  BANK_ACCOUNT_FAILED: "An error occurred while creating bank account, please try again",
  BANK_ACCOUNT_LIST_FAILED: "An error occurred while fetching banks, please try again",
  LOGOUT_FAILED: "An error occurred while logging out, please try again",
  IMAGE_UPLOAD_FAILED: "An error occurred while uploading image, please try again",
  CREATE_SALON_FAILED: "An error occurred while creating salon, please try again",
  BUSINESS_HEADER_IMAGE_UPLOAD_FAILED: "An error occurred while uploading business header image, please try again",
  BUSINESS_LOGO_UPLOAD_FAILED: "An error occurred while uploading business logo, please try again",
  BUSINESS_DETAILS_FAILED: "An error occurred while updating business details, please try again",
  BUSINESS_SOCIAL_LINKS_FAILED: "An error occurred while updating business socials, please try again",
  BUSINESS_SERVICES_FAILED: "An error occurred while updating business services, please try again",
  BUSINESS_SERVICES_DELETE_FAILED: "An error occurred while deleting business services, please try again",
  BUSINESS_SERVICES_UPDATE_FAILED: "An error occurred while updating business services, please try again",
  BUSINESS_SERVICES_CREATE_FAILED: "An error occurred while creating business services, please try again",
  BUSINESS_STAFF_DELETE_FAILED: "An error occurred while deleting business staff, please try again",
  BUSINESS_STAFF_UPDATE_FAILED: "An error occurred while updating business staff, please try again",
  BUSINESS_STAFF_CREATE_FAILED: "An error occurred while creating business staff, please try again",
  BUSINESS_CLIENTS_CREATE_FAILED: "An error occurred while updating business clients, please try again",
  BUSINESS_CLIENTS_DELETE_FAILED: "An error occurred while deleting business clients, please try again",
  BUSINESS_CLIENTS_UPDATE_FAILED: "An error occurred while updating business clients, please try again",
  BUSINESS_PRODUCTS_CREATE_FAILED: "An error occurred while creating business products, please try again",
  BUSINESS_PRODUCTS_STOCK_UPDATE_FAILED: "An error occurred while updating products stock, please try again",
  BUSINESS_PRODUCTS_FAILED: "An error occurred while updating business products, please try again",
  BUSINESS_PRODUCTS_DELETE_FAILED: "An error occurred while deleting business products, please try again",
  BUSINESS_PRODUCTS_UPDATE_FAILED: "An error occurred while updating business products, please try again",
  BUSINESS_BVN_FAILED: "An error occurred while updating business BVN, please try again",
  BUSINESS_BVN_VERIFY_OTP_FAILED: "An error occurred while verifying business BVN OTP, please try again",
  BUSINESS_BVN_RESEND_OTP_FAILED: "An error occurred while resending business BVN OTP, please try again",
  BUSINESS_TIME_BUFFER_FAILED: "An error occurred while updating business time buffer, please try again",
  BUSINESS_HOURS_FAILED: "An error occurred while updating business hours, please try again",
  NOT_AUTHORIZED: "Not Authorised!",
  SALON_UPDATE_FAILED: "An error occurred while updating salon",
  SOMETHING_WENT_WRONG: "Something went wrong",
  REFRESH_PAGE: "Please try refreshing this page.",
  FAILED_TO_MAIL_CUSTOMER: "An error occurred while sending receipt to customer, please try again",
  FAILED_TO_INITIATE_PAYMENT: "Unable to initiate payment, please try again later.",
  BUSINESS_LOYALTY_CREATE_FAILED: "An error occurred while creating business loyalty, please try again",
  BUSINESS_LOYALTY_DELETE_FAILED: "An error occurred while deleting business loyalty, please try again",
  BUSINESS_LOYALTY_UPDATE_FAILED: "An error occurred while updating business loyalty, please try again",
  BUSINESS_LOYALTY_POINT_FAILED: "An error occurred while updating loyalty point, please try again",
  BUSINESS_PROMO_CREATE_FAILED: "An error occurred while creating business promo, please try again",
  BUSINESS_PROMO_DELETE_FAILED: "An error occurred while deleting business promo, please try again",
  BUSINESS_PROMO_UPDATE_FAILED: "An error occurred while updating business promo, please try again",
  STAFF_TIME_OFF_CREATION_FAILED: "An error occurred while creating staff time off, please try again",
  STAFF_TIME_OFF_UPDATE_FAILED: "An error occurred while updating staff time off, please try again",
  STAFF_TIME_OFF_DELETE_FAILED: "An error occurred while deleting staff time off, please try again",
  STAFF_TIME_OFF_GET_FAILED: "An error occurred while getting staff time off, please try again",
  CLOSE_PERIOD_CREATION_FAILED: "An error occurred while creating close period, please try again",
  CLOSE_PERIOD_UPDATE_FAILED: "An error occurred while updating close period, please try again",
  CLOSE_PERIOD_DELETE_FAILED: "An error occurred while deleting close period, please try again",
  CLOSE_PERIOD_GET_FAILED: "An error occurred while getting close period, please try again",
  UPDATE_AUTOMATED_MESSAGE_FAILED: "An error occurred while updating automated message, please try again",
  FAILED_TO_FETCH_CLIENT_BY_PHONE_NUMBER: "An error occurred while fetching client by phone number, please try again",
  FAILED_TO_FETCH_SALON_AVAILABILITY: "An error occurred while fetching salon availability, please try again",
  APPOINTMENT_CREATION_FAILED: "An error occurred while creating appointment, please try again",
  APPOINTMENT_PRICE_SUMMARY_FAILED: "An error occurred, please try again",
  CUSTOM_MESSAGE_CREATION_FAILED: "An error occurred while creating custom message, please try again",
  CUSTOM_MESSAGE_DELETION_FAILED: "An error occurred while deleting custom message, please try again",
  APPOINTMENT_FAILED_TO_UPDATE: "An error occured while trying to updte appointment",
  APPOINTMENT_CHECKOUT_FAILED: "An error occurred, please try again",
  APPOINTMENT_DELETION_FAILED: "An error occurred, kindly try again",
  BUSINESS_ONLINE_DETAILS_UPDATE_FAILED: "An error occurred while updating business online details",
  SALON_EXPENSE_DELETION_FAILED: "An error occurred while trying to delete an expense",
  SALON_EXPENSE_CREATION_FAILED: "An error occurred while creating an expense",
  BUSINESS_SUBSCRIPTION_FAILED: "An error occurred while subscribing to a plan",
  APPOINTMENT_REVIEW_CREATE_FAILED: "An error occurred while reviewing an appointment",
  BANK_POS_FAILED: "An error occurred while creating point of sale terminal",
  SALON_BANK_ACCOUNT_DELETION_FAILED: "An error occurred while trying to delete bank account record",
  SALON_POS_DELETION_FAILED: "An error occurred while trying to delete a point of sale terminal",
  NOTE_CREATION_FAILED: "An error occurred while trying to create a note",
  NOTE_DELETION_FAILED: "An error occurred while trying to delete a note",
  APPOINTMENT_REFUND_FAILED: "An error occurred while trying to refund an appointment",
  APPOINTMENT_REFUND_REQUEST_FAILED: "An error occurred while trying to request an appointment refund",
  APPOINTMENT_REFUND_REQUEST_CANCELLED: "Appointment refund request cancelled",
  APPOINTMENT_REFUND_REQUEST_APPROVED: "Appointment refund request approved",
  APPOINTMENT_REFUND_REQUEST_REJECTED: "Appointment refund request rejected",
  APPOINTMENT_REFUND_REQUEST_EXPIRED: "Appointment refund request expired",
  APPOINTMENT_REFUND_REQUEST_NOT_FOUND: "Appointment refund request not found",
  APPOINTMENT_REFUND_REQUEST_ALREADY_APPROVED: "Appointment refund request already approved",
  APPOINTMENT_REFUND_REQUEST_ALREADY_REJECTED: "Appointment refund request already rejected",
  RECORD_REFUND_FAILED: "An error occurred while trying to record an appointment refund",
  SALE_CREATION_FAILED: "An error occurred while trying to create a sale",
  SALE_UPDATE_FAILED: "An error occurred while trying to update a sale",
  // package crud api errors
  PACKAGE_CREATION_FAILED: "An error occurred while trying to create a package",
  PACKAGE_UPDATE_FAILED: "An error occurred while trying to update a package",
  PACKAGE_DELETE_FAILED: "An error occurred while trying to delete a package",
  PACKAGE_VIEW_FAILED: "An error occurred while trying to view a package",
  // vouchers crus api errors
  VOUCHER_CREATION_FAILED: "An error occurred while trying to create a voucher",
  VOUCHER_UPDATE_FAILED: "An error occurred while trying to update a voucher",
  VOUCHER_DELETE_FAILED: "An error occurred while trying to delete a voucher",
  VOUCHER_VIEW_FAILED: "An error occurred while trying to view a voucher",
  REDEEM_VOUCHER_FAILED: "An error occurred while trying to redeem your voucher, kindly check the code, and try again",
  VOUCHER_SALE_FAILED: "An error occurred while trying to create a sale from a voucher",
  VOUCHER_PURCHASE_FAILED: "An error occurred while trying to purchase a voucher"
};
