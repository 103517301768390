import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNeutralIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M.506 7.914C.506 3.58 4.14-.006 8.527 0c4.346 0 7.967 3.6 7.974 7.913.008 4.58-3.464 8.085-8.004 8.087-4.539.002-7.991-3.487-7.991-8.086Zm7.974 6.092c3.242.017 5.984-2.622 6.002-5.773.02-3.54-2.501-6.17-5.93-6.184C5.15 2.034 2.608 4.58 2.522 7.95c-.08 3.11 2.559 6.114 5.958 6.053v.002Z"
      fill="#E1CC12"
    />
    <path
      d="M8.459 11.112c-.875 0-1.749.01-2.622 0-.679-.01-1.075-.393-1.075-1.004 0-.61.407-1.012 1.075-1.017a401.05 401.05 0 0 1 5.362 0c.666 0 1.04.41 1.031 1.039-.008.603-.37.968-1.024.98-.92.019-1.834.002-2.747.002ZM6.42 7.034a1.035 1.035 0 0 1-.965-1.361.974.974 0 0 1 .917-.653 1.025 1.025 0 0 1 1.032.946 1.06 1.06 0 0 1-.984 1.068ZM10.35 5.02a.977.977 0 0 1 1.026.942 1.04 1.04 0 0 1-.978 1.075c-.525.013-1.047-.497-1.043-1.022a1.048 1.048 0 0 1 .996-.995Z"
      fill="#E1CC12"
    />
  </svg>
);
export default SvgNeutralIcon;
