import { Status } from "../utils/types";


export const STATUS = [
  "successful",
  "payment",
  "joined",
  "active",
  "pending",
  "withdrawal",
  "failed",
  "abandoned",
  "churned",
  "removed",
];

export const STATUS_PILL: Status = {
  successful: "success",
  payment: "success",
  active: "success",
  joined: "active",
  pending: "warning",
  withdrawal: "danger",
  failed: "danger",
  abandoned: "danger",
  churned: "danger",
  removed: "danger",
};

export const PACKAGE_STATUS = {
  ACTIVE: "active",
  EXPIRED: "expired",
}