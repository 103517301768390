export enum FontSize {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  h7 = 'h7',
  h8 = 'h8',
  h9 = 'h9',
  h10 = 'h10',
  h11 = 'h11',
  base = 'base',
  b1 = 'b1',
  b2 = 'b2',
  b3 = 'b3',
  b4 = 'b4',
  b5 = 'b5',
  b6 = 'b6',
  b7 = 'b7',
  b8 = 'b8',
  b9 = 'b9',
}

export enum FontWeight {
  thin = 'thin',
  extraLight = 'extraLight',
  light = 'light',
  normal = 'normal',
  medium = 'medium',
  semiBold = 'semiBold',
  bold = 'bold',
  extraBold = 'extraBold',
  black = 'black',
}