import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
// import axios from 'axios';
import { AddVoucherSaleProps, BookingPackages, VoucherSaleInput } from './types';
// import { useUserCache } from '../hooks/useUserCache';
import { formatBankAccountsToSelectField, formatClientToSelectFieldAppointmentCreation, formatPosTerminalsToSelectField, formatSalonPackagesToSelectField } from '../utils/utils';
import { formatBookingPackages, formatInToPrice, limitString, sanitizePhoneNumber, validatePhoneNumber } from '../utils/misc';
import { convertPackagesToCommaSeparatedString, fetchClientsData, fetchPackagesData, fetchPosDevicesData, fetchTransferAccountsData } from './utils';
import { MultiSelectOption } from '../ui/molecules/multiselect/types';
import { Client, CreateVoucherSaleDocument, Package, PackageService, PosDevice, TransferAccount } from '../graphql/generated';
import { useSalonCache } from '../hooks/useSalonCache';
import { SALES_PAYMENT_METHODS } from './constants';
import { CartTypeEnum } from '../uicomponents/bookingSales/types';
import { COLORS } from '../constants/colors';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import { convertSalonPackageServicesNamesToString } from '../uicomponents/bookingSales/utils';
import Button from '../ui/atoms/button/Button';
import SvgGreyPlus from '../ui/icons/GreyPlus';
import { SvgArrowBack, SvgChevronLeft, SvgGreyMinus, SvgTrash } from '../ui/icons';
import { DEFAULT_CURRENCY } from '../constants/currency';
import { FormatNumber } from '../ui/atoms/formatNumber/FormatNumber';
import { Modal } from '../ui/templates/modal/Modal';
import Heading from '../ui/atoms/heading/Heading';
import CollapseRow from '../ui/organism/collapseRow/CollapseRow';
import { FormLabel } from '../ui/atoms/formLabel';
import SingleSelect from '../ui/molecules/singleSelect/SingleSelect';
import { FormHelperText } from '../ui/atoms/helperText/FormHelperText';
import Input from '../ui/molecules/input/Input';
import { API_ERRORS, ERRORS } from '../constants/errors';
import { REGEX_PATTERNS } from '../constants/pattern';
import FormPhone from '../ui/molecules/input/FormPhone';
import { getHelperTextForReactHookFormErrors } from '../utils/form';
import MultiSelect from '../ui/molecules/multiselect/multiselect';
import Checkbox from '../ui/atoms/checkbox/Checkbox';
import SelectInput from '../ui/molecules/input/SelectInput';
import axios from 'axios';
import { print } from 'graphql'
import { logger } from '../core/logger';
// import { logger } from 'core/logger';

const AddVoucherSaleModal = (props: AddVoucherSaleProps) => {
  // const { getBusinessData } = useUserCache();
  // const business = getBusinessData();
  // const businessId = business?.businessId as string;
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const [newClient, setNewClient] = useState(false);
  const [selectedPackages, setSelectedPackages] = useState<BookingPackages>([])
  const [formattedPackages, setFormattedPackages] = useState<BookingPackages>([]);
  const [countryCode, setCountryCode] = useState("");
  const [callingCode, setCallingCode] = useState("");


  const [clientsOptions, setClientsOptions] = useState<MultiSelectOption[]>([]);
  const [packagesOptions, setPackagesOptions] = useState<MultiSelectOption[]>([]);
  const [transfersOptions, setTransfersOptions] = useState<MultiSelectOption[]>([]);
  const [posTerminalsOptions, setPosTerminalsOptions] = useState<MultiSelectOption[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [bankTransfers, setBankTransfers] = useState<TransferAccount[]>([]);
  const [posTerminals, setPosTerminals] = useState<PosDevice[]>([])
  const [packages, setPackages] = useState<Package[]>([])
  const [createVoucherSaleIsLoading, setCreateVoucherSaleIsLoading] = useState(false)
  let searchTimeout: ReturnType<typeof setTimeout>;

  const getClients = async (q: string) => {
    if (q) {
      const clientsData = await fetchClientsData({ salonId, q });
      setClients(clientsData);
    }
  }


  const fetchDataAsync = async() => {
    const clientsData = await fetchClientsData({ salonId }, 10);
    setClients(clientsData);

    const bankTransfersData = await fetchTransferAccountsData({ salonId });
    setBankTransfers(bankTransfersData);

    const posTerminalsData = await fetchPosDevicesData({ salonId });
    setPosTerminals(posTerminalsData);

    const packages = await fetchPackagesData({ salonId });
    setPackages(packages);
  }

  useEffect(() => {
    if (salonId && props.isVisible) {
      fetchDataAsync();
    }
  }, [salonId, props.isVisible])

  useEffect(() => {
    if (Array?.isArray(clients) && clients?.length) {
      const options = formatClientToSelectFieldAppointmentCreation(clients);
      setClientsOptions(options);
    }
    if (Array?.isArray(packages) && packages?.length) {
      const options = formatSalonPackagesToSelectField(packages);
      setPackagesOptions(options);
      setFormattedPackages(formatBookingPackages(packages));
    }
    if (Array?.isArray(bankTransfers) && bankTransfers?.length) {
      const options = formatBankAccountsToSelectField(bankTransfers);
      setTransfersOptions(options);
    }
    if (Array?.isArray(posTerminals) && posTerminals?.length) {
      const options = formatPosTerminalsToSelectField(posTerminals);
      setPosTerminalsOptions(options);
    }
  }, [clients, packages, clients, posTerminals])

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<VoucherSaleInput>({
    defaultValues: {
      paymentMethod: SALES_PAYMENT_METHODS[0].value,
      newClient: false,
    } 
  });

  useEffect(() => {
    if (watch('clientId')) {
      const clientIdField = watch('clientId') as unknown as {
        label: string;
        value: string;
      };
      if (clientIdField?.value === 'new-client') {
        setNewClient(true);
        setValue('clientName', '');
        setValue('clientEmail', '');
        setValue('clientPhone', '');
        setValue('newClient', true);
      } else {
        setNewClient(false);
        setValue('newClient', false);
        const client = clients.find((client) => client?.id === clientIdField?.value) as Client;
        if (client) {
          setValue('clientName', `${client?.firstName} ${client?.lastName}`);
          setValue('clientEmail', client?.email);
          setValue('clientPhone', client?.phone);
          setCallingCode(client?.callingCode);
          setCountryCode(client?.countryCode);
        }
      }
    }
  }, [watch('clientId')])

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue('clientPhone', _phoneNumber)

    const pn = validatePhoneNumber(_phoneNumber, countryCode);

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('clientPhone', {
        type: 'manual',
        message: 'Invalid phone number',
      });
    } else {
      // Clear the error if the phone number is valid
      clearErrors('clientPhone');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getClientDetails = (input: VoucherSaleInput) => {
    const clientId = input?.clientId as unknown as {
      label: string;
      value: string;
    };

    if (clientId?.value === 'new-client') {
      return {
        firstName: input?.clientName?.split(' ')[0],
        lastName: input?.clientName?.split(' ')[1],
        email: input?.clientEmail,
        phone: input?.clientPhone,
        callingCode,
        countryCode,
      }
    }
    // check client details from the list of clients
    const client = clients.find((client) => client?.id === clientId?.value) as Client;
    return {
      firstName: client?.firstName,
      lastName: client?.lastName,
      email: client?.email,
      phone: client?.phone,
      callingCode: client?.callingCode,
      countryCode: client?.countryCode,
    }
  }

  const closeAddVoucherModal = () => {
    props.closeModal('addedVoucherSale');
    resetFormInput()
  }

  const getTotalPrice = () => {
    let totalPrice = 0;
    selectedPackages.forEach((packageItem) => {
      totalPrice += packageItem?.usesCustomPrice ? packageItem?.customPrice * packageItem.quantity : packageItem?.totalPrice * packageItem.quantity;
    })
    return totalPrice;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchClient = (e: any) => {
    // Clear any previously set timeout to avoid multiple calls
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
  
    // Set a new timeout to delay the execution of getClients
    searchTimeout = setTimeout(() => {
      getClients(e);
    }, 500);
  };

  const resetFormInput = () => {
    setValue('clientId', []);
    setValue('clientPhone', '');
    setValue('clientEmail', '');
    setValue('clientName', '');
    setValue('packages', []);
    setValue('paymentMethod', SALES_PAYMENT_METHODS[0].value)
    setSelectedPackages([]);
    setNewClient(false);
  }

  const sellVoucher = async (input: VoucherSaleInput) => {
    const client = {
      firstName: input?.clientName?.split(' ')[0],
      lastName: input?.clientName?.split(' ')[1] || ' ',
      email: input?.clientEmail,
      phone: input?.clientPhone,
      callingCode,
      countryCode,
    }
    const inputData = {
      client,
      packages: selectedPackages.length ? convertPackagesToCommaSeparatedString(selectedPackages) : [],
      paymentMethod: input?.paymentMethod,
      paymentMethodId: input?.paymentMethodId,
    }

    try {
      // logger.info(`Creating voucher sale: ${JSON.stringify(inputData)}`);
      setCreateVoucherSaleIsLoading(true)
      await axios.post(
        '/graphql',
        {
          query: print(CreateVoucherSaleDocument), variables: {
          input: { ...inputData, salonId: props?.salonId }
        }},
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        setCreateVoucherSaleIsLoading(false)
        if (data?.data?.createVoucherSale?.voucherSale) {
          props?.addToast && props.addToast({
            variant: "success",
            message: "Voucher sale created successfully",
          });
          props.closeModal('addedVoucherSale');
          resetFormInput();
        }

        if (data?.data?.createVoucherSale?.errors?.length) {
          const message = data?.data?.createSale?.errors[0]?.message || API_ERRORS.VOUCHER_SALE_FAILED;
          props?.addToast && props.addToast({
            variant: 'error',
            message,
          })
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.VOUCHER_SALE_FAILED;
          props?.addToast && props.addToast({
            variant: 'error',
            message,
          })
          logger.error(`Voucher Sale: message`, inputData)
        }
      });
    } catch (appointmentError) {
      setCreateVoucherSaleIsLoading(false)
      if (axios.isAxiosError(appointmentError)) {
        const message = appointmentError?.response?.data?.message || API_ERRORS.VOUCHER_SALE_FAILED;
        props?.addToast && props.addToast({
          variant: "error",
          message,
        });
        logger.error(`Failed to sell packages`, inputData)
        logger.exception(appointmentError as Error);
      }
    }
  }

  useEffect(() => {
    if (watch('packages')) {
      const packageSelectedFromOptions = packages.filter((salonPackage) => {
        const packageId = salonPackage?.id;
        const exists = selectedPackages.some((selectedPackage) => selectedPackage.id === packageId);
        return !exists && watch('packages')?.some((packageSelected: MultiSelectOption) => packageSelected?.value === packageId);
      }) as BookingPackages;

      const packagesWithQuantity = packageSelectedFromOptions.map((salonPackage) => ({
        ...salonPackage,
        selected: false, // Assuming you want to set selected to false for new packages
        quantity: 1,
      }));

      setSelectedPackages((prevSelectedPackages) => [
        ...prevSelectedPackages,
        ...packagesWithQuantity
      ]);
    }
  }, [watch('packages')]);

  const removePackageFromInput = (packageId: string) => {
    if (watch('packages')) {
      setValue('packages', watch('packages').filter((packageSelected: MultiSelectOption) => packageSelected?.value !== packageId) as []);
    }
  }

  const modifyPackageInCart = (packageId: string, action: CartTypeEnum) => {
    // if action is remove, remove package from cart
    if (action === 'remove') {
      // remove package from selectedPackages
      const existPackageItem = selectedPackages.find((packageItem) => packageItem.id === packageId);
      if (!existPackageItem) {
        return;
      }

      if (existPackageItem?.quantity > 1) {
        // decrease quantity if package exist
        setSelectedPackages(selectedPackages.map((packageItem) => {
          if (packageItem.id === packageId) {
            return {
              ...packageItem,
              quantity: packageItem.quantity - 1
            }
          }
          return packageItem;
        }));
      } else {
        setSelectedPackages(selectedPackages.filter((packageItem) => packageItem.id !== packageId));
      }
    } else if (action === 'add') {
      // check if package already exist
      const packageExist = selectedPackages.find((packageItem) => packageItem.id === packageId);
      // increase quantity if package exist
      if (packageExist) {
        setSelectedPackages(selectedPackages.map((packageItem) => {
          if (packageItem.id === packageId) {
            return {
              ...packageItem,
              quantity: packageItem.quantity + 1
            }
          }
          return packageItem;
        }));

      } else {
        // add package to cart, but check if selectedPackages is an array and have content, before add to cart
        const packageItem = formattedPackages.find((packageItem) => packageItem.id === packageId);

        if (!packageItem) {
          return;
        }

        // add newPackageItem to selectedPackages
        setSelectedPackages((prevSalonPackages) => {
          if (selectedPackages && Array.isArray(prevSalonPackages) && selectedPackages?.length > 0) {
            return [{
              ...packageItem,
              quantity: 1
            }, ...prevSalonPackages];
          } else {
            // If it's not an array or undefined, initialize it as an array
            return [{
              ...packageItem,
              quantity: 1
            }];
          }
        });
      }
    } else if (action === 'clear') {
      const existPackageItems = selectedPackages.filter((packageItem) => packageItem.id !== packageId);
      removePackageFromInput(packageId);
      setSelectedPackages(existPackageItems);
    }
  }

  const getVoucherSummary = () => {
    if (Array.isArray(selectedPackages) && selectedPackages.length > 0) {
      return (
        <div className='w-full flex flex-col gap-4 border border-grey-50 p-4 rounded-md'>
          {Array.isArray(selectedPackages) && selectedPackages.map((selectedSalonPackage, index) => {
            const price = selectedSalonPackage?.usesCustomPrice ? selectedSalonPackage?.customPrice : selectedSalonPackage?.totalPrice;
            return (
              <div className='w-full flex justify-between py-2' key={`ssp${index}`}>
                <div className='flex flex-col gap-2'>
                  <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{selectedSalonPackage?.name}</Paragraph>
                  <Paragraph size='b6' weight='normal' color={COLORS.GREY['400']}>{limitString(convertSalonPackageServicesNamesToString(selectedSalonPackage?.services as unknown as PackageService[]), 20)}</Paragraph>
                </div>
                <div className="flex space-x-2 items-start">
                  <div className="flex pt-2">
                    <Button
                      variant='icon'
                      size="none"
                      type="button"
                      className="border-0"
                      onClick={() => modifyPackageInCart(selectedSalonPackage.id, 'add')}
                    >
                      <SvgGreyPlus width="22px" height="22px" />
                    </Button>
                    <div className="flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center">
                      <Paragraph size='b6' weight='normal' color={COLORS.BLACK}>{selectedSalonPackage?.quantity}</Paragraph>
                    </div>
                    <Button
                      variant='icon'
                      size="none"
                      type="button"
                      className="border-0"
                      onClick={() => modifyPackageInCart(selectedSalonPackage.id, 'remove')}
                    >
                      <SvgGreyMinus width="22px" height="22px" />
                    </Button>
                  </div>
                </div>
                <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>{formatInToPrice(price * selectedSalonPackage?.quantity)}</Paragraph>
                <div className='w-[10%] flex justify-start py-1'>
                  <span className="text-red-600 text-b5 cursor-pointer" onClick={() => {
                    modifyPackageInCart(selectedSalonPackage.id, 'clear')
                  }}><SvgTrash width="14px" height="14px" /></span>
                </div>
              </div>
            )
          })}
          <div className='flex justify-between items-center border-t border-grey-50 py-3'>
            <Paragraph size='b4' color={COLORS.GREY[300]} weight="bold">Total</Paragraph>
            <Paragraph size='b4' color={COLORS.GREY[300]} weight="bold">{DEFAULT_CURRENCY}<FormatNumber value={getTotalPrice()} /></Paragraph>
          </div>
        </div>
      )
    }

    return null
  }
  return (
    <Modal
      show={props.isVisible}
      closeModal={closeAddVoucherModal}
      variant='right'
    >
      <form onSubmit={handleSubmit(sellVoucher)} className="w-full relative space-y-6 my-[80px]" autoComplete='off'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeAddVoucherModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeAddVoucherModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>Add Voucher Sale</Paragraph>
          </div>
        </div>
        <div className="w-full flex flex-col px-6 py-4 space-y-6">
          <Heading variant='h1' size='h9' weight='semiBold'>Add Voucher Sale</Heading>
          <Paragraph size="b4">Create new voucher sale for a client</Paragraph>
        </div>
        <div className="w-full px-6 pt-2 pb-4 space-y-6">
          <CollapseRow title="Client">
            <div className='w-full flex flex-col space-y-2'>
              <Controller
                control={control}
                name="clientId"
                render={({
                  field: { onChange, value },
                  formState: { errors },
                }) => {
                  const errorMessage: string = errors?.clientId?.message;
                  return (
                    <>
                      <FormLabel htmlFor="service">
                        Select a client
                      </FormLabel>
                      <SingleSelect
                        selected={value || []}
                        options={clientsOptions}
                        setSelected={onChange}
                        onInputChange={searchClient}
                        placeholder='Type client name'
                      />
                      {errorMessage && (
                        <FormHelperText variant="error">
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </>
                  );
                }}
                rules={{
                  required: "Select a client",
                }}
              />
            </div>
            {newClient && (
              <>
                <Input name="clientName" label="Client" id="clientName" type="text" placeholder='Enter client name' control={control} rules={{
                  required: newClient && ERRORS.NAME_REQUIRED,
                  pattern: REGEX_PATTERNS.ADDRESS,
                }} error={errors.clientName} />
                <div className='w-full flex gap-x-4'>
                  <div className='w-full xl:w-1/2'>
                    <FormPhone
                      country={{
                        disabled: false,
                        onSelect: (code, country: string) => {
                          setCallingCode(code);
                          setCountryCode(country);
                        },
                        value: countryCode,
                      }}
                      phone={{
                        name: "clientPhone",
                        type: "text",
                        placeholder: "9151930463",
                        rules: {
                          required: ERRORS.PHONE_REQUIRED,
                          pattern: REGEX_PATTERNS.NUMBER,
                          onChange: (e) => {
                            _sanitizePhoneNumber(e.target.value);
                          },
                          disabled: false,
                        },
                        register,
                        id: "phone-number",
                        label: "Phone Number",
                      }}
                      helperText={getHelperTextForReactHookFormErrors(
                        errors?.clientPhone?.message as string
                      )}
                    />
                  </div>
                  <div className='w-full xl:w-1/2'>
                    <Input name="clientEmail" label="Client email" id="clientEmail" type="email" placeholder='email@acme.com' control={control} rules={{
                      pattern: REGEX_PATTERNS.EMAIL,
                    }} error={errors.clientEmail} />
                  </div>
                </div>
              </>
            )}
          </CollapseRow>
          <CollapseRow title="Package">
            <div className='w-full flex space-x-4'>
              <Controller
                control={control}
                name="packages"
                render={({
                  field: { onChange, value },
                  formState: { errors },
                }) => {
                  // @ts-expect-error services is of type array but the error payload will be an object with "message" property
                  const errorMessage: string = errors?.services?.message;
                  return (
                    <div className='w-full flex flex-col space-y-2'>
                      <FormLabel htmlFor="service">
                        Select Package(s)
                      </FormLabel>
                      <MultiSelect
                        selected={value || []}
                        options={packagesOptions}
                        setSelected={onChange}
                      />
                      {errorMessage && (
                        <FormHelperText variant="error">
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </CollapseRow>
          {watch('packages') ? getVoucherSummary() : null}
          <Controller
            control={control}
            name="paymentMethod"
            render={({ field: { onChange, value } }) => {
              return (
                <div className="w-full flex flex-col space-y-4">
                  <label className="text-b6 xl:text-b4 text-grey-900 font-normal w-full block subpixel-antialiased">
                    Select payment method
                  </label>
                  <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                    {Array.isArray(SALES_PAYMENT_METHODS) && SALES_PAYMENT_METHODS.length
                      ? SALES_PAYMENT_METHODS
                        .map((paymentMethod: {
                          label: string;
                          value: string;
                        }) => {
                          return (
                            <div
                              className="flex"
                              onClick={() => onChange(paymentMethod?.value)}
                              key={paymentMethod?.value}
                            >
                              <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                <Checkbox
                                  isChecked={value === paymentMethod?.value}
                                />
                                <span className="text-grey-900 whitespace-nowrap">
                                  {paymentMethod?.label}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              );
            }}
            rules={{
              required: "Select payment method",
            }}
          />
          {watch("paymentMethod") === "pos" && posTerminals && posTerminals.length ? (
            <SelectInput name="paymentMethodId" id="transfers" label="Point-of-Sale Terminal (POS)" control={control} error={errors.paymentMethodId} options={posTerminalsOptions} placeholder="Select Point-of-Sale Terminal" />
          ) : null}
          {watch("paymentMethod") === "bank_transfer" && bankTransfers && bankTransfers.length ? (
            <SelectInput name="paymentMethodId" id="pos" label="Bank" control={control} error={errors.paymentMethodId} options={transfersOptions} placeholder="Select Bank" />
          ) : null}
        </div>
        <div className="w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer">
          <div className='w-full flex justify-between py-4 px-8'>
            <div className='bg-grey-50 py-2 px-4 rounded-md border border-grey-20 hidden xl:flex justify-center items-center'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>TOTAL: {DEFAULT_CURRENCY}<FormatNumber value={getTotalPrice()} /></Paragraph>
            </div>
            <div className='flex space-x-2'>
              <Button
                variant='text'
                className='text-red-600 mr-6'
                size='none'
                fontWeight='semiBold'
                type='button'
                onClick={
                  () => {
                    props.closeModal('close')
                  }
                }
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                className=''
                disabled={createVoucherSaleIsLoading}
                loading={createVoucherSaleIsLoading}
                size='md'
                rounded='lg'
              >
                Add voucher sale
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default AddVoucherSaleModal