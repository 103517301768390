import React from 'react';
import { COLORS } from "../constants/colors"
import { Appointment } from "../graphql/generated"
import { Paragraph } from "../ui";
import { formatInToPrice } from "../utils/misc";

export const AppointmentPriceCard = ({ appointment }: { appointment: Appointment | null }) => {
  if (!appointment || !appointment?.isActive) return null;

  if (appointment.paymentMethod === 'voucher') {
    return (
      <Paragraph size="b4" color={COLORS.GREEN[850]}>
        Paid by {appointment?.appointmentVoucher?.packageVoucher?.package?.name} voucher
      </Paragraph>
    );
  }

  if (appointment.depositAmount > 0 && appointment?.depositPaymentMethod !== 'unpaid' && appointment?.paymentMethod === 'unpaid') {
    return (
      <Paragraph size="b4" color={COLORS.GREEN[850]}>
        Deposit: {formatInToPrice(appointment.depositAmount)} paid by {appointment.depositPaymentMethod}
      </Paragraph>
    );
  }

  if (appointment.paymentMethod !== 'unpaid' && appointment.depositAmount > 0 && appointment?.depositPaymentMethod !== 'unpaid') {
    return (
      <>
        <Paragraph size="b4" color={COLORS.GREEN[850]}>
          Deposit: {formatInToPrice(appointment.depositAmount)} paid by {appointment.depositPaymentMethod}
        </Paragraph>
        <Paragraph size="b4" color={COLORS.GREEN[850]}>
          Balance: {formatInToPrice(appointment.totalPaid - appointment.depositAmount)} paid by {appointment.paymentMethod}
        </Paragraph>
      </>
    );
  }

  if (appointment.paymentMethod !== 'unpaid' && appointment.depositPaymentMethod === "unpaid") {
    return (
      <Paragraph size="b4" color={COLORS.GREEN[850]}>
        {formatInToPrice(appointment.actualTotalPaid)} paid by {appointment.paymentMethod}
      </Paragraph>
    );
  }
};