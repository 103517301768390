import * as React from "react";
import { IWalletCardProps } from './types';
import { COLORS } from "../../../constants/colors";
import { Button, Heading, Paragraph } from "../..";

const WalletCard: React.FC<IWalletCardProps> = ({
  icon,
  title,
  value,
  buttonLabel,
  buttonVariant,
  buttonSize,
  onClick
}) => {
  return (
    <div className="flex space-x-4 w-full max-w-[370px]">
      <div className="flex space-x-4">
        {icon}
        <div className="flex flex-col space-y-4">
          <Heading size="h11" variant="h2" color={COLORS.GREY['400']}>
            {title}
          </Heading>
          <Paragraph size="b1" weight="bold" color={COLORS.WHITE}>
            {value}
          </Paragraph>
          {buttonLabel && (
            <Button
              variant={buttonVariant || 'secondary'}
              size={buttonSize || 'sm'}
              type='button'
              rounded="md"
              onClick={onClick && onClick}
            >
              {buttonLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WalletCard