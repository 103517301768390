import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBrangMessaging = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 550 550"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M550 420.641H0v.275h550v-.275ZM98.45 428.131H57.706v.275H98.45v-.275ZM264 434.082h-78.1v.275H264v-.275ZM160.237 428.264h-55.825v.275h55.825v-.275ZM500.995 436.49h-53.537v.275h53.537v-.275ZM442.563 436.49h-35.167v.275h35.167v-.275ZM373.142 431.85h-68.211v.275h68.211v-.275ZM260.7 371.58H48.301a6.28 6.28 0 0 1-6.27-6.281V66.726a6.281 6.281 0 0 1 6.27-6.226H260.7a6.282 6.282 0 0 1 6.281 6.281v298.518a6.28 6.28 0 0 1-6.281 6.281ZM48.301 60.72a6.006 6.006 0 0 0-5.995 6.006v298.573a6.005 6.005 0 0 0 5.995 6.006H260.7a6.017 6.017 0 0 0 6.006-6.006V66.726a6.016 6.016 0 0 0-6.006-6.006H48.301ZM498.641 371.58h-212.41a6.293 6.293 0 0 1-6.281-6.281V66.726a6.293 6.293 0 0 1 6.281-6.226h212.41a6.281 6.281 0 0 1 6.259 6.226v298.573a6.284 6.284 0 0 1-6.259 6.281ZM286.231 60.72a6.014 6.014 0 0 0-6.006 6.006v298.573a6.018 6.018 0 0 0 6.006 6.006h212.41a6.017 6.017 0 0 0 6.006-6.006V66.726a6.016 6.016 0 0 0-6.006-6.006h-212.41Z"
      fill="#EBEBEB"
    />
    <path d="M223.773 197.791h264.495V79.266H223.773v118.525Z" fill="#E6E6E6" />
    <path d="M218.262 197.791h267.443V79.266H218.262v118.525Z" fill="#F0F0F0" />
    <path d="M223.773 208.055h264.495v-10.263H223.773v10.263Z" fill="#E6E6E6" />
    <path d="M209.055 208.055h267.443v-10.263H209.055v10.263Z" fill="#F0F0F0" />
    <path d="M477.961 190.057V86.998H225.984v103.059h251.977Z" fill="#FAFAFA" />
    <path
      d="M463.705 190.068 418.33 86.998h-21.417l45.375 103.07h21.417Z"
      fill="#fff"
    />
    <path
      d="M474.386 182.368a.55.55 0 0 0 .55-.55V92.036a.55.55 0 0 0-1.1 0v89.782a.55.55 0 0 0 .55.55Z"
      fill="#F0F0F0"
    />
    <path
      d="m437.69 190.068-45.386-103.07h-8.349l45.375 103.07h8.36Z"
      fill="#fff"
    />
    <path d="M226.974 190.057V86.998h-.979v103.059h.979Z" fill="#E6E6E6" />
    <path
      opacity={0.6}
      d="M216.425 98.66h264.506l.704-8.646H217.14l-.715 8.646ZM216.425 112.849h264.506l.704-8.646H217.14l-.715 8.646ZM216.425 127.039h264.506l.704-8.646H217.14l-.715 8.646ZM216.425 141.228h264.506l.704-8.646H217.14l-.715 8.646ZM216.425 155.419h264.506l.704-8.646H217.14l-.715 8.646ZM216.425 169.609h264.506l.704-8.646H217.14l-.715 8.646Z"
      fill="#EBEBEB"
    />
    <path d="M145.596 416.604h20.471V243.596h-20.471v173.008Z" fill="#E0E0E0" />
    <path d="M72.93 420.617h89.21v-4.026H72.93v4.026Z" fill="#F0F0F0" />
    <path d="M145.607 243.584H69.003v173.008h76.604V243.584Z" fill="#F5F5F5" />
    <path d="M142.692 278.234H71.896v33.264h70.796v-33.264Z" fill="#fff" />
    <path
      d="M107.305 291.314a4.609 4.609 0 1 0 0-9.218 4.609 4.609 0 0 0 0 9.218Z"
      fill="#F5F5F5"
    />
    <path
      d="M142.692 247.115H71.896v24.541h70.796v-24.541ZM142.692 318.076H71.896v90.563h70.796v-90.563Z"
      fill="#F0F0F0"
    />
    <path
      d="M86.835 247.115H71.897v24.541h14.938v-24.541ZM86.835 318.076H71.897v90.563h14.938v-90.563Z"
      fill="#E0E0E0"
    />
    <path d="M146.883 422.137h77.748V270.898h-77.748v151.239Z" fill="#F5F5F5" />
    <path d="M146.872 422.137h6.413V270.898h-6.413v151.239Z" fill="#F0F0F0" />
    <path d="M397.661 275.209H218.636v24.288h179.025v-24.288Z" fill="#E6E6E6" />
    <path
      d="M397.661 325.148H218.636v24.288h179.025v-24.288ZM389.037 270.896H146.883v11.341h242.154v-11.341Z"
      fill="#F0F0F0"
    />
    <path d="M389.037 422.137h77.748V270.898h-77.748v151.239Z" fill="#F5F5F5" />
    <path
      d="M389.037 422.137h6.413V270.898h-6.413v151.239ZM301.521 270.896h16.775l-4.081-32.648H297.44l4.081 32.648Z"
      fill="#F0F0F0"
    />
    <path
      d="M297.814 270.896h16.775l-4.081-32.648h-16.775l4.081 32.648Z"
      fill="#F5F5F5"
    />
    <path
      d="M295.768 254.572h16.775l-2.035-16.324h-16.775l2.035 16.324Z"
      fill="#F0F0F0"
    />
    <path
      d="M357.412 250.669H238.755a1.664 1.664 0 0 1-1.235-.555 1.661 1.661 0 0 1-.404-1.293l9.152-73.161a1.642 1.642 0 0 1 1.628-1.441h118.657a1.62 1.62 0 0 1 .676.145 1.623 1.623 0 0 1 .894 1.014c.068.223.088.458.058.689l-9.141 73.161a1.636 1.636 0 0 1-1.628 1.441Z"
      fill="#F0F0F0"
    />
    <path
      d="M355.652 250.669H236.984a1.65 1.65 0 0 1-1.203-.572 1.647 1.647 0 0 1-.381-1.276l9.141-73.161a1.652 1.652 0 0 1 1.639-1.441h118.613c.233-.001.464.049.676.145.213.095.402.235.556.41a1.64 1.64 0 0 1 .396 1.293l-9.141 73.161a1.637 1.637 0 0 1-1.628 1.441Z"
      fill="#F5F5F5"
    />
    <path
      d="m239.184 246.621 8.558-68.42a.463.463 0 0 1 .462-.396h113.938a.458.458 0 0 1 .451.517l-8.558 68.475a.45.45 0 0 1-.451.396H239.646a.47.47 0 0 1-.373-.172.46.46 0 0 1-.089-.4Z"
      fill="#E6E6E6"
    />
    <path
      d="M285.263 267.84h21.879a2.703 2.703 0 0 1 1.944.807 2.713 2.713 0 0 1 .784 1.954v.341h-27.324v-.341a2.707 2.707 0 0 1 .78-1.95 2.719 2.719 0 0 1 1.937-.811Z"
      fill="#F5F5F5"
    />
    <path
      d="M309.43 267.84h13.09a2.705 2.705 0 0 1 1.921.796 2.72 2.72 0 0 1 .796 1.921v.341h-18.535v-.297a2.708 2.708 0 0 1 .783-1.954c.254-.257.557-.461.891-.599.334-.139.692-.21 1.054-.208Z"
      fill="#F0F0F0"
    />
    <path
      d="M252.351 270.897h84.48l2.277-4.686H263.34l-10.989 4.686Z"
      fill="#F5F5F5"
    />
    <path
      d="M327.536 270.897h13.948l1.892-4.686h-13.959l-1.881 4.686ZM231 265.232h-12.716a5.678 5.678 0 0 0-2.171.428 5.687 5.687 0 0 0-3.072 3.067 5.655 5.655 0 0 0-.433 2.17h24.046a5.664 5.664 0 0 0-5.654-5.665ZM65.44 85.063v117.865h85.843V85.063H65.44Z"
      fill="#F0F0F0"
    />
    <path d="M69.938 198.43h76.846V89.552H69.938V198.43Z" fill="#fff" />
    <path
      d="M122.023 192.225a1.834 1.834 0 0 0 1.265-2.266L96.954 97.031a1.837 1.837 0 1 0-3.531 1.012l26.323 92.917a1.83 1.83 0 0 0 1.56 1.326c.241.027.484.007.717-.061ZM275 470.316c117.79 0 213.279-5.575 213.279-12.452S392.79 445.412 275 445.412c-117.791 0-213.28 5.575-213.28 12.452S157.21 470.316 275 470.316Z"
      fill="#F5F5F5"
    />
    <path
      d="M356.729 99.54H219.977c-9.592 0-17.369 7.775-17.369 17.368v323.125c0 9.593 7.777 17.369 17.369 17.369h136.752c9.593 0 17.369-7.776 17.369-17.369V116.908c0-9.593-7.776-17.369-17.369-17.369Z"
      fill="#16796F"
    />
    <path
      d="M372.625 160.578h-1.562a3.64 3.64 0 0 0-3.641 3.641v53.262a3.64 3.64 0 0 0 3.641 3.641h1.562a3.64 3.64 0 0 0 3.641-3.641v-53.262a3.64 3.64 0 0 0-3.641-3.641ZM202.96 172.447h-2.684a3.08 3.08 0 0 0-3.08 3.08v17.578a3.08 3.08 0 0 0 3.08 3.08h2.684a3.08 3.08 0 0 0 3.08-3.08v-17.578a3.08 3.08 0 0 0-3.08-3.08ZM305.436 95.69H277.32v1.1h28.116v-1.1ZM317.053 95.69h-6.116v1.1h6.116v-1.1Z"
      fill="#16796F"
    />
    <path
      d="M353.837 99.54H217.085c-9.593 0-17.369 7.775-17.369 17.368v323.125c0 9.593 7.776 17.369 17.369 17.369h136.752c9.592 0 17.369-7.776 17.369-17.369V116.908c0-9.593-7.777-17.369-17.369-17.369Z"
      fill="#16796F"
    />
    <path
      d="M366.201 119.912H204.71a3.377 3.377 0 0 0-3.377 3.377v310.948a3.377 3.377 0 0 0 3.377 3.377h161.491a3.377 3.377 0 0 0 3.377-3.377V123.289a3.377 3.377 0 0 0-3.377-3.377Z"
      fill="#fff"
    />
    <path
      opacity={0.05}
      d="M331.869 135.543H229.778c-9.112 0-16.5 7.387-16.5 16.5v148.225c0 9.113 7.388 16.5 16.5 16.5h102.091c9.113 0 16.5-7.387 16.5-16.5V152.043c0-9.113-7.387-16.5-16.5-16.5Z"
      fill="#16796F"
    />
    <path
      opacity={0.05}
      d="M300.948 228.801a11.09 11.09 0 0 0-11.088 11.088v99.649a11.103 11.103 0 0 0 3.244 7.847 11.092 11.092 0 0 0 7.844 3.252h68.651V228.801h-68.651Z"
      fill="#16796F"
    />
    <path
      d="M263.769 110.066H159.775a7.93 7.93 0 0 0-5.612 2.32 7.932 7.932 0 0 0-2.319 5.611v53.383a7.938 7.938 0 0 0 2.323 5.608 7.918 7.918 0 0 0 5.608 2.323H233.2l15.917 8.118V179.3h14.674a7.93 7.93 0 0 0 7.909-7.92v-53.383a7.934 7.934 0 0 0-7.931-7.931Z"
      fill="#263238"
    />
    <path
      d="M257.829 130.252h-79.915a4.542 4.542 0 0 1 0-9.086h79.915a4.542 4.542 0 0 1 0 9.086Zm-79.915-8.019a3.49 3.49 0 0 0-3.291 3.482 3.487 3.487 0 0 0 3.291 3.481h79.915a3.487 3.487 0 0 0 0-6.963h-79.915ZM257.828 145.673h-55a4.467 4.467 0 0 1-3.013-7.539 4.467 4.467 0 0 1 3.013-1.382h55a4.468 4.468 0 0 1 3.014 7.539 4.468 4.468 0 0 1-3.014 1.382Zm-55-8.019a3.554 3.554 0 0 0-2.647.969 3.572 3.572 0 0 0-1.115 2.589 3.56 3.56 0 0 0 1.115 2.59 3.57 3.57 0 0 0 2.647.969h55a3.559 3.559 0 0 0 2.647-.969 3.56 3.56 0 0 0 .825-3.999 3.56 3.56 0 0 0-3.472-2.149h-55ZM257.829 161.073H190.3a4.507 4.507 0 0 1-4.287-4.504 4.508 4.508 0 0 1 4.287-4.505h67.551a4.512 4.512 0 0 1 3.044 7.614 4.507 4.507 0 0 1-3.044 1.395h-.022Zm-67.529-8.019a3.525 3.525 0 0 0-3.717 3.515 3.522 3.522 0 0 0 3.717 3.514h67.551a3.522 3.522 0 0 0 3.43-2.122 3.522 3.522 0 0 0-3.43-4.907H190.3Z"
      fill="#fff"
    />
    <path
      d="M411.026 159.941H291.808a7.933 7.933 0 0 0-7.931 7.931v30.613a7.92 7.92 0 0 0 2.323 5.608 7.929 7.929 0 0 0 5.608 2.323h14.674v8.085l15.906-8.118h88.638a7.928 7.928 0 0 0 7.931-7.931v-30.58a7.934 7.934 0 0 0-7.931-7.931Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="M411.026 159.941H291.808a7.933 7.933 0 0 0-7.931 7.931v30.613a7.92 7.92 0 0 0 2.323 5.608 7.929 7.929 0 0 0 5.608 2.323h14.674v8.085l15.906-8.118h88.638a7.928 7.928 0 0 0 7.931-7.931v-30.58a7.934 7.934 0 0 0-7.931-7.931Z"
      fill="#fff"
    />
    <path
      d="M377.828 179.971h-79.915a4.542 4.542 0 0 1 0-9.086h79.915a4.544 4.544 0 1 1 0 9.086Zm-79.915-8.019a3.476 3.476 0 1 0 0 6.952h79.915a3.476 3.476 0 1 0 0-6.952h-79.915ZM352.957 195.383h-55a4.455 4.455 0 0 1 0-8.91h55a4.456 4.456 0 1 1 0 8.91Zm-55-8.019a3.561 3.561 0 0 0-3.564 3.564 3.563 3.563 0 0 0 3.564 3.564h55a3.565 3.565 0 0 0 0-7.128h-55Z"
      fill="#fff"
    />
    <path
      d="M182.545 373.01H369.6v19.536h-187a6.469 6.469 0 0 1-6.468-6.468v-6.6a6.467 6.467 0 0 1 6.413-6.468Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M182.545 373.01H369.6v19.536h-187a6.469 6.469 0 0 1-6.468-6.468v-6.6a6.467 6.467 0 0 1 6.413-6.468Z"
      fill="#263238"
    />
    <path
      opacity={0.3}
      d="M327.305 373.01H369.6v19.536h-42.295a6.469 6.469 0 0 1-6.468-6.468v-6.6a6.468 6.468 0 0 1 6.468-6.468Z"
      fill="#263238"
    />
    <path
      opacity={0.3}
      d="m336.424 392.557 25.212 27.291c2.926 3.179 7.942.924 7.942-3.564v-23.727h-33.154ZM215.809 392.557l25.212 27.291c2.937 3.179 7.953.924 7.953-3.564v-23.727h-33.165Z"
      fill="#16796F"
    />
    <path
      d="M264.693 295.779c-.165 2.046-.407 3.938-.671 5.885a144.34 144.34 0 0 1-.891 5.797c-.66 3.861-1.452 7.7-2.42 11.528a110.46 110.46 0 0 1-1.562 5.72 65.93 65.93 0 0 1-.913 2.849 23.615 23.615 0 0 1-1.1 2.981c-.264.539-.539 1.1-.902 1.683-.418.737-.92 1.424-1.496 2.046a7.24 7.24 0 0 1-1.672 1.32 5.404 5.404 0 0 1-3.454.638 5.22 5.22 0 0 1-2.662-1.276 6.694 6.694 0 0 1-1.1-1.32 10.58 10.58 0 0 1-1.298-3.784 21.057 21.057 0 0 1-.209-3.3l5.214-1.1c.462.704.891 1.529 1.386 2.2.495.671.99 1.32 1.221 1.331 0 0 .055-.077-.099-.264a2.425 2.425 0 0 0-1.386-.682 3.404 3.404 0 0 0-2.079.319c-.407.209-.55.341-.616.363-.066.022 0-.132 0-.385l.209-.957c.132-.682.352-1.617.594-2.486.242-.869.429-1.771.638-2.662.407-1.793.803-3.586 1.1-5.401.704-3.63 1.32-7.304 1.881-11 .561-3.696 1.1-7.425 1.518-11l10.769.957Z"
      fill="#B55B52"
    />
    <path
      d="M255.2 284.9c-2.497 3.718-5.005 18.128-5.005 18.128l17.534 7.7s4.928-23.463 1.001-27.764c-4.048-4.499-10.725-2.233-13.53 1.936Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M258.654 294.349c-2.387 2.75-3.498 7.117-3.828 10.648l12.947 5.72a143.06 143.06 0 0 0 2.321-15.983c-2.871-3.058-7.359-5.104-11.44-.385Z"
      fill="#000"
    />
    <path
      d="m174.328 447.568-8.723-5.896 12.199-21.197 8.723 5.896-12.199 21.197ZM232.001 456.457l-10.098-3.597 3.531-24.178 10.098 3.597-3.531 24.178Z"
      fill="#B55B52"
    />
    <path
      d="m221.342 451.451 11.825.495a.834.834 0 0 1 .814.748l.957 8.998a1.693 1.693 0 0 1-1.76 1.804c-4.125-.242-6.105-.154-11.297-.363-3.201-.143-9.702.66-14.113.473-4.411-.187-4.477-4.554-2.618-4.873 8.316-1.441 11.539-4.488 14.41-6.699a2.735 2.735 0 0 1 1.782-.583ZM166.793 438.526l9.746 6.71a.834.834 0 0 1 .275 1.1l-4.191 8.47a1.685 1.685 0 0 1-2.453.583c-3.3-2.398-4.51-3.3-8.8-6.248-2.629-1.815-8.8-5.555-12.452-8.063-3.652-2.508-1.166-6.402.572-5.687 7.799 3.223 11.869 3.047 15.477 2.695a2.847 2.847 0 0 1 1.826.44Z"
      fill="#263238"
    />
    <g opacity={0.2} fill="#000">
      <path d="m177.793 420.475-7.172 12.485 9.262 4.961 6.644-11.539-8.734-5.907ZM235.532 432.288l-10.109-3.608-2.123 14.663 10.318 2.09 1.914-13.145Z" />
    </g>
    <path
      d="M255.53 289.673c.121 7.92 1.705 23.793 7.7 55.561l42.757-1.1c-1.166-17.38-1.463-28.116 5.94-64.042a126.707 126.707 0 0 0-18.227-1.925 136.205 136.205 0 0 0-19.426.495c-3.894.462-8.041 1.177-11.341 1.815a9.193 9.193 0 0 0-7.403 9.196Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M310.817 285.637a16.92 16.92 0 0 0-8.536 8.283c-2.068 4.719 3.113 14.421 4.345 16.599.836-6.71 2.178-14.696 4.191-24.882Z"
      fill="#000"
    />
    <path
      d="M280.247 233.419c-4.257 4.763-8.107 24.937 5.104 34.1-.572 8.415 19.591 2.871 22-7.414 2.266-9.504-14.322-40.997-27.104-26.686Z"
      fill="#263238"
    />
    <path
      d="M297.253 269.182a.297.297 0 0 1-.297-.286.308.308 0 0 1 .286-.319c7.766-.352 10.318-4.598 11.088-8.096 1.793-8.14-3.993-18.92-9.988-22a.3.3 0 0 1-.121-.407.297.297 0 0 1 .396-.121c6.182 3.212 12.1 14.3 10.296 22.693-.814 3.696-3.498 8.195-11.649 8.558l-.011-.022Z"
      fill="#263238"
    />
    <path
      d="M299.2 270.601a25.16 25.16 0 0 1-3.564-.275.284.284 0 0 1-.121-.03.282.282 0 0 1-.098-.077.284.284 0 0 1-.058-.11.289.289 0 0 1 .036-.241.295.295 0 0 1 .329-.125c7.876 1.1 13.013-1.485 14.476-7.326 2.101-8.459-4.18-21.23-11.792-23.991a.29.29 0 0 1-.119-.049.293.293 0 0 1-.111-.345.298.298 0 0 1 .428-.156c8.008 2.893 14.377 15.818 12.166 24.684-.814 3.19-3.366 8.041-11.572 8.041Z"
      fill="#263238"
    />
    <path
      d="M291.291 258.697c-1.1 6.281-2.046 15.4 2.431 19.47 0 0-8.701 4.774-17.853 19.91-7.975-12.441-1.573-19.415-1.573-19.415 6.93-1.837 6.677-4.664 5.335-9.482l11.66-10.483Z"
      fill="#B55B52"
    />
    <path
      d="M280.324 286.286a3.575 3.575 0 0 1-3.014 2.673c-1.276 0-2.002-1.1-1.606-2.596a3.539 3.539 0 0 1 3.014-2.673c1.276-.022 1.991 1.133 1.606 2.596Z"
      fill="#fff"
    />
    <path
      d="M279.4 284.899a.265.265 0 0 1-.264-.275c0-.073.029-.142.081-.194a.272.272 0 0 1 .194-.081c.242 0 6.754-.077 14.168-6.391a.277.277 0 0 1 .305-.038.275.275 0 0 1 .058.456c-7.513 6.369-13.948 6.523-14.542 6.523ZM278.014 284.791s-3.949-.682-3.949-6.127a.276.276 0 0 1 .55 0c0 4.961 3.3 5.5 3.487 5.577a.275.275 0 0 1 .22.319.272.272 0 0 1-.106.178.28.28 0 0 1-.202.053Z"
      fill="#fff"
    />
    <path
      opacity={0.2}
      d="m286.54 262.977-6.897 6.193a20.44 20.44 0 0 1 .682 3.432c2.651-.451 6.787-2.827 7.282-5.731.231-1.375-.825-3.256-1.067-3.894Z"
      fill="#000"
    />
    <path
      d="M263.131 230.341c-4.29 4.796-9.691 21.428 3.608 30.602-.572 8.47 19.008 1.76 22.517-8.294 3.509-10.054-13.255-36.718-26.125-22.308Z"
      fill="#263238"
    />
    <path
      d="M292.413 242.452c1.716 10.307 2.673 14.63-1.265 21.032-5.918 9.636-18.953 9.482-23.716-.099-4.29-8.635-6.38-24.046 2.64-30.382a14.296 14.296 0 0 1 13.79-1.469 14.304 14.304 0 0 1 8.551 10.918Z"
      fill="#B55B52"
    />
    <path
      d="M267.784 231.748c.627 5.5 11.462 19.646 24.992 16.357 5.115 5.225 12.023-11.209 7.579-19.228-4.444-8.019-34.452-13.673-32.571 2.871ZM263.241 345.236c-20.79-1.386-63.536 3.883-70.136 24.2-9.9 30.602-21.45 56.727-21.45 56.727l13.354 6.391s25.685-31.174 33.583-51.491c15.4 1.716 35.816-3.08 41.965-3.839 22.572-2.761 31.823-17.369 33.99-32.802l-31.306.814Z"
      fill="#263238"
    />
    <path
      opacity={0.2}
      d="M246.004 355.52c-16.698-.253-36.949 10.329-34.672 39.952a103.254 103.254 0 0 0 7.26-14.399 85.792 85.792 0 0 0 15.686 0c5.269-11.671 15.741-25.487 11.726-25.553Z"
      fill="#000"
    />
    <path
      d="m184.008 435.887-14.806-7.744 1.375-5.291 16.885 8.096-3.454 4.939Z"
      fill="#16796F"
    />
    <path
      opacity={0.4}
      d="m184.008 435.887-14.806-7.744 1.375-5.291 16.885 8.096-3.454 4.939Z"
      fill="#000"
    />
    <path
      d="M274.527 344.949s-52.162 11.825-51.997 27.401c.363 33.308-.297 65.505-.297 65.505l14.267 2.827s11.891-37.708 11.99-57.893c22.286-8.338 66 10.56 57.541-38.654l-31.504.814Z"
      fill="#263238"
    />
    <path
      d="m237.92 443.004-17.523-3.234-.847-5.852 20.779 2.882-2.409 6.204Z"
      fill="#16796F"
    />
    <path
      opacity={0.4}
      d="m237.92 443.004-17.523-3.234-.847-5.852 20.779 2.882-2.409 6.204Z"
      fill="#000"
    />
    <path
      d="M276.827 248.886c.187.825-.099 1.595-.638 1.716s-1.1-.44-1.32-1.265c-.22-.825.088-1.606.627-1.727s1.144.44 1.331 1.276ZM267.465 251.042c.198.825-.088 1.595-.627 1.716s-1.1-.44-1.331-1.265c-.231-.825.099-1.606.638-1.727s1.155.44 1.32 1.276Z"
      fill="#263238"
    />
    <path d="m266.387 249.755-2.112-.11s1.375 1.309 2.112.11Z" fill="#263238" />
    <path
      d="M270.699 251.9a22.577 22.577 0 0 1-1.738 5.962 3.632 3.632 0 0 0 3.08-.132l-1.342-5.83Z"
      fill="#A02724"
    />
    <path
      d="M277.299 259.908c-.389.151-.79.273-1.199.363a.244.244 0 0 1-.297-.187.249.249 0 0 1 .03-.189.239.239 0 0 1 .068-.07.227.227 0 0 1 .089-.038 6.712 6.712 0 0 0 4.752-3.795.252.252 0 0 1 .319-.132.252.252 0 0 1 .154.308 7.008 7.008 0 0 1-3.916 3.74Z"
      fill="#263238"
    />
    <path
      d="M296.451 250.416a7.701 7.701 0 0 1-3.025 4.994c-2.2 1.573-4.18-.143-4.345-2.673-.165-2.277.781-5.841 3.3-6.413a3.44 3.44 0 0 1 3.151.936 3.433 3.433 0 0 1 .919 3.156Z"
      fill="#B55B52"
    />
    <path
      d="M278.234 245.3a.542.542 0 0 1-.55-.209c-1.254-1.936-4.224-2.068-4.257-2.068a.504.504 0 0 1-.484-.517.508.508 0 0 1 .517-.484c.143 0 3.52.154 5.06 2.519a.494.494 0 0 1-.143.693l-.143.066ZM262.526 247.324a.488.488 0 0 1-.326.029.492.492 0 0 1-.257-.799 4.923 4.923 0 0 1 4.07-2.024.486.486 0 0 1 .341.168.493.493 0 0 1 .121.36.486.486 0 0 1-.168.341.477.477 0 0 1-.168.096.482.482 0 0 1-.192.025 3.963 3.963 0 0 0-3.212 1.639.497.497 0 0 1-.209.165ZM215.777 452.594a.307.307 0 0 1-.088-.088 1.4 1.4 0 0 1-.363-1.331.838.838 0 0 1 .527-.682c1.243-.506 4.4 1.694 4.708 1.936a.254.254 0 0 1 .1.264.252.252 0 0 1-.199.187c-1.331.319-3.673.55-4.685-.286Zm3.992-.099c-1.265-.836-3.069-1.804-3.729-1.54a.344.344 0 0 0-.22.308.905.905 0 0 0 .22.891c.506.528 1.881.627 3.729.341Z"
      fill="#263238"
    />
    <path
      d="M220.001 452.616c-1.001-.836-2.453-2.849-2.2-3.905a.867.867 0 0 1 .869-.66 1.328 1.328 0 0 1 1.1.374c1.1 1.1.99 4.092.979 4.224a.244.244 0 0 1-.132.209.241.241 0 0 1-.242 0 2.414 2.414 0 0 1-.374-.242Zm-.693-3.883a.832.832 0 0 0-.594-.187c-.319 0-.396.165-.429.286-.165.715.957 2.464 1.925 3.3 0-1.012-.187-2.728-.847-3.355l-.055-.044ZM162.493 437.8a3.934 3.934 0 0 1-1.738-1.991 1.373 1.373 0 0 1 .319-1.254.812.812 0 0 1 .726-.308c1.463.154 3.696 4.301 3.949 4.774a.26.26 0 0 1 .044.143.26.26 0 0 1-.044.143.255.255 0 0 1-.286.055 18.36 18.36 0 0 1-2.97-1.562Zm-.319-2.86a.924.924 0 0 0-.429-.198.29.29 0 0 0-.162.025.286.286 0 0 0-.124.107.897.897 0 0 0-.22.825c.198.836 1.573 1.892 3.707 2.904a13.212 13.212 0 0 0-2.772-3.652v-.011Z"
      fill="#263238"
    />
    <path
      d="m165.385 439.35-.066-.077c-.715-1.177-1.474-4.4-.638-5.335.198-.198.561-.374 1.177 0a1.806 1.806 0 0 1 .891 1.1c.462 1.65-.946 4.125-1.001 4.235a.253.253 0 0 1-.22.121.237.237 0 0 1-.143-.044Zm.363-4.939-.132-.077c-.407-.22-.528-.088-.572 0-.506.517-.132 2.948.484 4.312a5.693 5.693 0 0 0 .737-3.432 1.297 1.297 0 0 0-.517-.803ZM275.748 247.598l-2.123-.11s1.375 1.309 2.123.11Z"
      fill="#263238"
    />
    <path
      d="m306.57 341.605 1.199 3.806c.154.286-.187.605-.682.616l-44 1.1c-.385 0-.704-.176-.737-.418l-.484-3.817c0-.264.297-.495.715-.506l43.296-1.1a.768.768 0 0 1 .693.319Z"
      fill="#fff"
    />
    <path
      d="M300.741 346.611h1.166c.231 0 .396-.132.374-.275l-.671-4.961c0-.143-.22-.264-.451-.253h-1.1c-.231 0-.407.132-.385.275l.671 4.95c-.044.154.165.264.396.264ZM266.343 347.491h1.166c.231 0 .407-.132.385-.275l-.671-4.961c0-.143-.231-.253-.462-.253h-1.1c-.231 0-.407.132-.385.275l.671 4.961c-.033.143.165.253.396.253ZM283.471 347.05h1.166c.231 0 .396-.132.385-.275l-.671-4.961c0-.143-.231-.253-.462-.253h-1.155c-.231 0-.407.132-.385.275l.671 4.961c.022.143.22.253.451.253Z"
      fill="#263238"
    />
    <path
      d="m278.917 321.563-10.648-4.026 3.575 10.098s6.666 1.1 9.339-2.365l-2.266-3.707Z"
      fill="#B55B52"
    />
    <path
      d="M316.328 294.58a47.4 47.4 0 0 1 1.034 4.246c.286 1.419.539 2.794.737 4.202.423 2.866.629 5.76.616 8.657a36.42 36.42 0 0 1-1.1 9.163c-.22.81-.484 1.607-.792 2.387a12.63 12.63 0 0 1-1.507 2.926 11.989 11.989 0 0 1-5.115 3.949c-1.6.652-3.28 1.089-4.994 1.298a29.95 29.95 0 0 1-4.587.275 56.29 56.29 0 0 1-8.536-.814 89.847 89.847 0 0 1-15.983-4.279l1.43-5.137c5.098.84 10.239 1.398 15.4 1.672a56.8 56.8 0 0 0 7.403-.099 17.01 17.01 0 0 0 5.995-1.419 2.477 2.477 0 0 0 1.1-.935c.214-.693.386-1.398.517-2.112a30.12 30.12 0 0 0 .297-6.688 66.39 66.39 0 0 0-.792-7.227 101.368 101.368 0 0 0-.649-3.652c-.242-1.21-.539-2.486-.781-3.531l10.307-2.882Z"
      fill="#B55B52"
    />
    <path
      d="M311.938 280.092c4.312 1.188 7.634 18.7 7.634 18.7l-16.334 8.107s-4.148-10.835-2.498-16.423c1.76-5.819 6.27-11.748 11.198-10.384Z"
      fill="#16796F"
    />
    <path
      d="M265.387 332.85h-9.603a1.678 1.678 0 0 1-.972-.372 1.676 1.676 0 0 1-.568-.871l-4.994-20.306a.931.931 0 0 1 .132-.867.935.935 0 0 1 .792-.376h9.614a1.686 1.686 0 0 1 1.54 1.243l4.994 20.295a.944.944 0 0 1-.488 1.163.96.96 0 0 1-.447.091Z"
      fill="#263238"
    />
    <path
      d="m245.235 321.112 5.852-2.475L249.8 330c-.555.204-1.125.363-1.705.473a4.918 4.918 0 0 1-5.5-6.545 4.928 4.928 0 0 1 2.64-2.816Z"
      fill="#B55B52"
    />
    <path
      d="m256.268 320.122-1.078 8.657-5.39 1.21 1.287-11.352 5.181 1.485ZM259.82 320.177l3.443 8.294 8.58-.836-3.575-10.098-8.448 2.64Z"
      fill="#B55B52"
    />
  </svg>
);
export default SvgBrangMessaging;
