/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { useSalonCache } from '../../../hooks/useSalonCache';
import { BlockTimeInput, ModalProps } from '../types';
import { formatStaffToSelectField, formatTimeToIncludeSeconds } from '../../../utils/utils';
import { checkDatesValidity, convert12HourTo24Hour, convertFullDateStringToDate, convertFullDateStringToTime } from '../../../utils/misc';
import { API_ERRORS, ERRORS } from '../../../constants/errors';
import { Modal } from '../../../ui/templates/modal/Modal';
import Input from '../../../ui/molecules/input/Input';
import { REGEX_PATTERNS } from '../../../constants/pattern';
import SelectInput from '../../../ui/molecules/input/SelectInput';
import { FormLabel } from '../../../ui/atoms/formLabel';
import { FormHelperText } from '../../../ui/atoms/helperText/FormHelperText';
import Button from '../../../ui/atoms/button/Button';
import { CreateStaffBlockedTimeDocument, DeleteStaffBlockedTimeDocument, SalonStaff } from '../../../graphql/generated';
import { print } from 'graphql'
import { fetchStaffListData } from '../../../modals/utils';
import { MultiSelectOption } from '../../../ui/molecules/multiselect';
import { canPerformAction } from '../../../utils/permission';
import { PERMISSION_CONSTANTS } from '../../../constants/permission';

const BlockTimeModal = ({ blockedTime, ...props }: ModalProps) => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const [addBlockTimeIsLoading, setAddBlockTimeIsLoading] = useState(false);
  const [deleteBlockTimeIsLoading, setDeleteBlockTimeIsLoading] = useState(false);
  const actions = {
    manageAllBlockedTime: canPerformAction(PERMISSION_CONSTANTS.calendar.addBlockTime),
    manageeOwnBlockedTime: canPerformAction(PERMISSION_CONSTANTS.calendar.addOwnBlockTime)
  }
  const [staff, setStaff] = useState<SalonStaff[]>([])
  const [staffMembersOptions, setStaffMembersOptions] = useState<MultiSelectOption[]>([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<BlockTimeInput>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const staffData = await fetchStaffListData({ salonId });
        setStaff(staffData?.staffs);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (salonId && props?.isVisible) {
      fetchData();
    }
  }, [salonId, props?.isVisible]);

  useEffect(() => {
    if (Array?.isArray(staff) && staff?.length) {
      if (actions?.manageAllBlockedTime) {
        setStaffMembersOptions(formatStaffToSelectField(staff));
      } else {
        const me = staff?.filter((staffMember) => staffMember?.id === props?.user?.salonStaff?.id)
        setStaffMembersOptions(formatStaffToSelectField(me));
      }
    }
  }, [staff])

  useEffect(() => {
    if (blockedTime) {
      setValue('date', convertFullDateStringToDate(blockedTime.endAt));
      setValue('endTime', convertFullDateStringToTime(blockedTime.endAt));
      setValue('startTime', convertFullDateStringToTime(blockedTime.startAt));
      setValue('staffId', blockedTime?.salonStaff?.id);
      setValue('title', blockedTime.title);
    }
  }, [blockedTime, setValue])


  const submitData = async (input: BlockTimeInput) => {
    const startAt = `${input.date}T${input.startTime}`;
    const endAt = `${input.date}T${input.endTime}`;
    const validateDates = checkDatesValidity(startAt, endAt);
    if (!validateDates) {
      props?.addToast && props.addToast({
        message: ERRORS.PROMO_INVALID_DATES,
        variant: 'error',
      })
      return;
    }
    setAddBlockTimeIsLoading(true)
    try {
      const inputData = {
        title: input.title,
        salonStaffId: input.staffId,
        startAt: `${input?.date}T${formatTimeToIncludeSeconds(input?.startTime)}`,
        endAt: `${input?.date}T${formatTimeToIncludeSeconds(input?.endTime)}`,
      }
      if (!blockedTime) {
        await axios.post(
          '/graphql',
          {
            query: print(CreateStaffBlockedTimeDocument),
            variables: { input: { ...inputData, salonId } },
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        ).then(({ data }) => {
          setAddBlockTimeIsLoading(false)
          if (data?.data?.createStaffBlockedTime?.staffBlockedTime) {
            props?.addToast && props.addToast({
              message: 'Block time added successfully',
              variant: 'success',
            })
            closeBlockTimeModal();
          }

          if (data?.errors?.length) {
            const message = data?.errors[0]?.message || API_ERRORS.STAFF_TIME_OFF_CREATION_FAILED;
            props?.addToast({
              variant: 'error',
              message,
            })
          }

          if (data?.data?.createStaffBlockedTime?.errors?.length) {
            const message = data?.data?.createStaffBlockedTime?.errors[0]?.message || API_ERRORS.STAFF_TIME_OFF_CREATION_FAILED;
            props?.addToast({
              variant: 'error',
              message,
            })
          }
        })
      } else {
        await axios.post(
          '/graphql',
          {
            query: print(CreateStaffBlockedTimeDocument),
            variables: { input: { ...inputData, id: blockedTime.id, salonId } },
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        ).then(({ data }) => {
          setAddBlockTimeIsLoading(false)
          if (data?.data?.createStaffBlockedTime?.staffBlockedTime) {
            props?.addToast && props.addToast({
              message: 'Block time updated successfully',
              variant: 'success',
            })
            closeBlockTimeModal();
          }

          if (data?.errors?.length) {
            const message = data?.errors[0]?.message || API_ERRORS.STAFF_TIME_OFF_CREATION_FAILED;
            props?.addToast({
              variant: 'error',
              message,
            })
          }

          if (data?.data?.createStaffBlockedTime?.errors?.length) {
            const message = data?.data?.createStaffBlockedTime?.errors[0]?.message || API_ERRORS.STAFF_TIME_OFF_CREATION_FAILED;
            props?.addToast({
              variant: 'error',
              message,
            })
          }
        })
      }
    } catch (addBlockTimeError) {
      setAddBlockTimeIsLoading(false)
      if (axios.isAxiosError(addBlockTimeError)) {
        const message = addBlockTimeError?.response?.data?.message || API_ERRORS.STAFF_TIME_OFF_CREATION_FAILED;
        props?.addToast && props.addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const resetFormInput = () => {
    setValue('staffId', '');
    setValue('date', '');
    setValue('startTime', '');
    setValue('endTime', '');
    setValue('title', '');
  }

  const closeBlockTimeModal = () => {
    props.closeModal();
    resetFormInput();
    props?.refetchCalendarData && props.refetchCalendarData()
    props?.setBlockedTimeId && props.setBlockedTimeId ? props.setBlockedTimeId(null) : null
  }

  const deleteTimeOff = async () => {
    setDeleteBlockTimeIsLoading(true)
    try {
      await axios.post(
        '/graphql',
        {
          query: print(DeleteStaffBlockedTimeDocument),
          variables: { input: { staffBlockedTimeId: blockedTime?.id } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        setDeleteBlockTimeIsLoading(false)
        if (data?.data?.deleteStaffBlockedTime?.status === 200) {
          props?.addToast && props.addToast({
            message: "Blocked time deleted successfully",
            variant: 'success',
          })
          closeBlockTimeModal();
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.STAFF_TIME_OFF_DELETE_FAILED;
          props?.addToast({
            variant: 'error',
            message,
          })
        }

        if (data?.data?.deleteTransferAccount?.errors?.length) {
          const message = data?.data?.deleteTransferAccount?.errors[0]?.message || API_ERRORS.STAFF_TIME_OFF_DELETE_FAILED;
          props?.addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (deleteBlockTimeError) {
      setDeleteBlockTimeIsLoading(false)
      if (axios.isAxiosError(deleteBlockTimeError)) {
        const message = deleteBlockTimeError?.response?.data?.message || API_ERRORS.STAFF_TIME_OFF_DELETE_FAILED;
        props?.addToast && props.addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const handleTimeChange = (fieldName: "startTime" | "endTime") => (value: any, dateString: string) => {
    if (dateString) {
      const timeString = convert12HourTo24Hour(dateString);
      if (timeString) {
        setValue(fieldName, timeString);
      }
    }
  };
  return (
    <Modal
      title={blockedTime ? "Edit Blocked Time" : "Add Blocked Time"}
      subTitle=""
      show={props.isVisible}
      closeModal={
        blockedTime ? closeBlockTimeModal : props.closeModal
      }
    >
      <form onSubmit={handleSubmit(submitData)} className="w-full mt-6 space-y-6" autoComplete='off'>
        <Input name="title" label="Title" id="title" type="text" placeholder='Enter title' control={control} rules={{
          required: ERRORS.TITLE_REQUIRED,
          pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH
        }} error={errors.title} />
        <SelectInput name="staffId" label="Staff" id="staff" control={control} rules={{
          required: ERRORS.STAFF_REQUIRED,
        }} error={errors.staffId} options={staffMembersOptions} placeholder='Select Staff' />
        <div className='w-full flex gap-x-4'>
          <Input name="date" label="Date" id="date" type="date" placeholder='DD/MM/YY' control={control} rules={{
            required: ERRORS.DATE_REQUIRED,
          }} error={errors.date} />
        </div>
        <div className='w-full flex gap-x-4'>
          <Controller
            control={control}
            name="startTime"
            render={({
              field,
              formState: { errors },
            }) => {
              const errorMessage: string = errors?.startTime?.message;
              return (
                <div className="w-full flex flex-col space-y-2">
                  <FormLabel htmlFor="start-time">
                    Start Time
                  </FormLabel>
                  <TimePicker className="splice-time-input" use12Hours format="h:mm a" value={field.value ? dayjs(field.value, 'HH:mm') : undefined} onChange={handleTimeChange('startTime')} />
                  {errorMessage && (
                    <FormHelperText variant="error">
                      {errorMessage}
                    </FormHelperText>
                  )}
                </div>
              );
            }}
            rules={{
              required: ERRORS.TIME_REQUIRED,
            }}
          />
          <Controller
            control={control}
            name="endTime"
            render={({
              field: { value },
              formState: { errors },
            }) => {
              const errorMessage: string = errors?.endTime?.message;
              const timeValue = value || null;
              return (
                <div className="w-full flex flex-col space-y-2">
                  <FormLabel htmlFor="end-time">
                    End Time
                  </FormLabel>
                  <TimePicker className="splice-time-input" use12Hours format="h:mm a" value={timeValue ? dayjs(value, 'HH:mm') : undefined} onChange={handleTimeChange('endTime')} />
                  {errorMessage && (
                    <FormHelperText variant="error">
                      {errorMessage}
                    </FormHelperText>
                  )}
                </div>
              );
            }}
            rules={{
              required: ERRORS.TIME_REQUIRED,
            }}
          />
        </div>
        <Button
          variant='primary'
          className=''
          disabled={addBlockTimeIsLoading || deleteBlockTimeIsLoading}
          loading={addBlockTimeIsLoading || deleteBlockTimeIsLoading}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
        {blockedTime && (
          <Button
            variant="text"
            size='none'
            rounded='none'
            fontSize='b5'
            className='mx-auto text-red-600'
            onClick={deleteTimeOff}
            disabled={addBlockTimeIsLoading || deleteBlockTimeIsLoading}
            loading={addBlockTimeIsLoading || deleteBlockTimeIsLoading}
            type="button"
          >
            Delete
          </Button>
        )}
      </form>
    </Modal>
  )
}

export default BlockTimeModal
