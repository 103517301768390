import React, { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react';
import TableSkeleton from '../../ui/organism/tableSkeleton/TableSkeleton';
import Label from '../../ui/atoms/formLabel/Label';
import Checkbox from '../../ui/atoms/checkbox/Checkbox';
import SearchTerm from '../../ui/organism/debounceQuery/SearchTerm';
import { SvgFilter } from '../../ui/icons';
import CalendarPicker from '../../ui/molecules/calendarPicker/CalendarPicker';
import { formatDateToOriginalDate, formatTableHeadersFilterArray, getTodayQueryDates } from '../../utils/misc';
import Table from '../../ui/organism/table/Table';
import { APPOINTMENT_HEADINGS, MOBILE_APPOINTMENT_SALES_HEADINGS } from './constants';
import { SalesPageProps } from './types';
import { Appointment, DeleteAppointmentDocument, RevertAppointmentCancellationDocument, UpdateAppointmentStatusDocument } from '../../graphql/generated';
import { fetchAppointments } from '../../modals/utils';
import { generateAppointmentSalesTableData } from './StatusUtils';
import { useModal } from '../../hooks';
import axios from 'axios';
import { print } from 'graphql';
import NoShowAppointmentModal from '../appointmentComponents/modals/NoShowAppointmentModal';
import DeleteAppointmentModal from '../appointmentComponents/modals/DeleteAppointmentModal';
import CancelAppointmentModal from '../appointmentComponents/modals/CancelAppointmentModal';
import { API_ERRORS } from '../../constants/errors';
import ViewAppointmentModal from '../../modals/ViewAppointmentModal';

const AppointmentSales = (props: SalesPageProps) => {
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getTodayQueryDates());
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [appointmentHeadings, setAppointmentHeadings] = useState(APPOINTMENT_HEADINGS);

  const [start, end] = selectedDates || getTodayQueryDates();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [appointment, setAppointment] = useState<Appointment>();
  const [ isLoading, setIsLoading ] = useState(false);

  const {
    isVisible: isViewAppointmentModalVisible,
    openModal: openViewAppointmentModal,
    closeModal: closeViewAppointmentModal,
  } = useModal();

  const {
    isVisible: isNoShowAppointmentModalVisible,
    openModal: openNoShowAppointmentModal,
    closeModal: closeNoShowAppointmentModal
  } = useModal();

  const {
    isVisible: isCancelAppointmentModalVisible,
    openModal: openCancelAppointmentModal,
    closeModal: closeCancelAppointmentModal,
  } = useModal();

  const {
    isVisible: isDeleteAppointmentModalVisible,
    openModal: openDeleteAppointmentModal,
    closeModal: closeDeleteAppointmentModal,
  } = useModal();

  const getAppointments = async () => {
    setIsLoading(true);
    const data = await fetchAppointments({
      salonId: props?.salonId,
      startDate: formatDateToOriginalDate(start, "start"),
      endDate: formatDateToOriginalDate(end, "end")
    })
    setAppointments(data);
    props?.setRefreshData(false)
    setIsLoading(false);
  }

  useEffect(() => {
    if (props?.salonId) {
      getAppointments()
    }
  }, [props?.salonId, start, end])

  const showInitialLoadingShimmer = isLoading && !appointments && !debouncedSearchQuery;

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in Headings then update the show
    const updatedHeadings = appointmentHeadings.map(heading => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setAppointmentHeadings(updatedHeadings)
  }

  const openAppointment = (id: number) => {
    const appointment = Array?.isArray(appointments) ? appointments[id] : undefined;

    if (!appointment) return;
    setAppointment(appointment)
    openViewAppointmentModal()
  }

  const checkInOrOutAppointmentAsync = async ({
    appointmentId, status, applyCancellationFee
  }: {
    appointmentId: string,
    status: string,
    applyCancellationFee: boolean
  }) => {
    try {
      const payload = {
        appointmentStatus: status,
        id: appointmentId,
        salonId: props?.salonId,
        applyCancellationFee
      }

      await axios.post(
        '/graphql',
        {
          query: print(UpdateAppointmentStatusDocument),
          variables: { input: { ...payload } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        if (data?.data?.updateAppointmentStatus?.status === 200) {
          getAppointments();
          closeViewAppointmentModal();
          props?.addToast({
            message: "Appointment updated successfully",
            variant: 'success',
          })
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          props?.addToast && props?.addToast({
            variant: 'error',
            message,
          })
        }


        if (data?.data?.updateAppointmentStatus?.errors?.length) {
          const message = data?.data?.updateAppointment?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          props?.addToast && props?.addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (checkoutAppointmentError) {
      if (axios.isAxiosError(checkoutAppointmentError)) {
        const message = checkoutAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
        props?.addToast({
          message,
          variant: 'error',
        })
      }
    }
  };

  const finalizeAppointmentCancellation = (action: string, applyCancellationFee = false) => {
    if (action === 'cancel') {
      checkInOrOutAppointmentAsync({
        appointmentId: appointment?.id as string, status: 'cancelled', applyCancellationFee
      })
      closeCancelAppointmentModal();
    } else if (action === 'no_show') {
      checkInOrOutAppointmentAsync({
        appointmentId: appointment?.id as string, status: 'no_show', applyCancellationFee
      })
      closeNoShowAppointmentModal()
    }
    else {
      closeCancelAppointmentModal();
      closeNoShowAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const deleteAppointmentAsync = async (id: string | null) => {
    if (!id) return;
    const userId = JSON.parse(localStorage.getItem('userData')).id

    try {
      await axios.post(
        '/graphql',
        {
          query: print(DeleteAppointmentDocument),
          variables: {
            input: {
              salonId: props?.salonId,
              id,
              userId
            }
          },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        const clientDetailsData = data?.data?.deleteAppointment;
        if (clientDetailsData) {
          getAppointments();
          closeViewAppointmentModal();
          props?.addToast && props.addToast({
            message: "Appointment removed successfully",
            variant: 'success',
          })
        } else {
          const message = API_ERRORS.APPOINTMENT_DELETION_FAILED;
          props?.addToast && props.addToast({
            message,
            variant: 'error',
          })
        }
      })
      } catch (deleteAppointmentError) {
      if (axios.isAxiosError(deleteAppointmentError)) {
        const message = deleteAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_DELETION_FAILED;
        props?.addToast && props.addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const finalizeAppointmentDeletion = (action: string) => {
    if (action === 'delete') {
      deleteAppointmentAsync(appointment?.id)
      closeDeleteAppointmentModal();
    } else {
      closeDeleteAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const getContent = () => {
    if (showInitialLoadingShimmer) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }

    if (Array.isArray(appointments) || debouncedSearchQuery) {
      const tableClientsData = generateAppointmentSalesTableData(appointments || [], appointmentHeadings)
      return (
        <Table
          headers={formatTableHeadersFilterArray(appointmentHeadings)}
          mobileHeaders={MOBILE_APPOINTMENT_SALES_HEADINGS}
          rows={tableClientsData}
          loading={showInitialLoadingShimmer}
          onClick={openAppointment}
        />
      )
    }
  }

  useEffect(() => {
    if (props.salonBranchName) {
      getAppointments()
    }
  }, [props.salonBranchName])

  useEffect(() => {
    if (props.refreshData) {
      getAppointments()
    }
  }, [props.refreshData])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const initiateCloseViewAppointmentModal = (action: string) => {
    setAppointment(null);
    closeViewAppointmentModal();
  }

  const initiateNoShowAppointment = () => {
    closeViewAppointmentModal();
    openNoShowAppointmentModal();
  }

  const initiateCancelAppointment = () => {
    closeViewAppointmentModal();
    openCancelAppointmentModal();
  }

  const initiateDeleteAppointment = () => {
    closeViewAppointmentModal();
    openDeleteAppointmentModal();
  }

  const revertAppointmentCancellation = async () => {
    if (!appointment) return;
    try {
      const payload = {
        appointmentId: appointment?.id
      }
      const { data } = await axios.post('/graphql', {query: print(RevertAppointmentCancellationDocument), variables: {input: {...payload}}}, {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}})

      if (data.data.revertAppointmentCancellation.status === 200) {
        closeViewAppointmentModal()
        getAppointments()
        props?.addToast && props.addToast({message: 'Appointment updated successfully', variant: 'success'})
      } else {
        const message = data?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          props?.addToast && props?.addToast({
            variant: 'error',
            message,
          })
      }
    } catch (error) {
      const message = error?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
      props?.addToast({
        message,
        variant: 'error'
      })
    }
  }

  // useEffect(() => {
  //   refetchAppointments();
  // }, [debouncedSearchQuery]);
  return (
    <>
      <div className='flex flex-col xl:flex-row justify-between items-center py-4 px-8 space-x-4'>
        <div className='w-full xl:w-6/12 flex items-center space-x-4'>
          <SearchTerm placeholder='Search' setDebouncedSearchQuery={setDebouncedSearchQuery} />
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                <SvgFilter width="20px" height="20px" />
                <span className='hidden xl:inline'>Filter</span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]">
                <div className="flex flex-col p-4 space-y-4">
                  {Array.isArray(appointmentHeadings) && appointmentHeadings.length && appointmentHeadings.map((heading, index) => (
                    <Label className='flex space-x-2 items-center cursor-pointer' key={index} htmlFor={heading.value} onClick={() => handleHeadingCheckboxChange(heading.value)}>
                      <Checkbox
                        isChecked={heading.show}
                        borderType='Black'
                        size='Sm'
                      />
                      <span>{heading.label}</span>
                    </Label>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <CalendarPicker {...{ selectedDates, setSelectedDates }} />
      </div>
      {getContent()}
      <ViewAppointmentModal
        isVisible={isViewAppointmentModalVisible}
        closeModal={initiateCloseViewAppointmentModal}
        cancelAppointment={initiateCancelAppointment}
        deleteAppointment={initiateDeleteAppointment}
        salonId={props?.salonId}
        addToast={props?.addToast}
        appointmentId={appointment?.id}
        markAsNoShow={initiateNoShowAppointment}
        refetchCalendarData={getAppointments}
        revertCancellation={revertAppointmentCancellation}
      />
      <CancelAppointmentModal
        isVisible={isCancelAppointmentModalVisible}
        closeModal={finalizeAppointmentCancellation}
      />
      <NoShowAppointmentModal
        isVisible={isNoShowAppointmentModalVisible}
        closeModal={finalizeAppointmentCancellation}
      />
      <DeleteAppointmentModal
        isVisible={isDeleteAppointmentModalVisible}
        closeModal={finalizeAppointmentDeletion}
      />
    </>
  )
}

export default AppointmentSales