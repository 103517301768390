import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRoundArrowDown = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={28} height={28} rx={14} fill="#F3F6F6" />
    <path
      d="m21.137 11.139-6.665 6.666a.666.666 0 0 1-.944 0l-6.666-6.666a.667.667 0 0 1 .944-.944L14 16.39l6.194-6.195a.666.666 0 1 1 .943.944Z"
      fill="#696969"
    />
  </svg>
);
export default SvgRoundArrowDown;
