import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { AddClientModalProps } from './types';
import { ClientInput } from '../uicomponents/types';
import { convertFullDateStringToDate, formatDateToOriginalDate, sanitizePhoneNumber, validatePhoneNumber } from '../utils/misc';
import { API_ERRORS, ERRORS } from '../constants/errors';
// import { PAGE_ROUTES } from '../constants/routes';
import { Modal } from '../ui/templates/modal/Modal';
import Input from '../ui/molecules/input/Input';
import { ACCOUNT_SETUP_ERRORS } from '../uicomponents/accountSetupComponents/constants';
import FormPhone from '../ui/molecules/input/FormPhone';
import { REGEX_PATTERNS } from '../constants/pattern';
import { getHelperTextForReactHookFormErrors } from '../utils/form';
import Button from '../ui/atoms/button/Button';
import { CreateClientDocument, DeleteClientDocument, UpdateClientDocument } from '../graphql/generated';
import { print } from 'graphql'

const AddClientModal = ({
  isVisible,
  closeModal,
  salonId,
  client,
  // navigateToPage,
  refetch,
  addToast,
  removeClientFromState
}: AddClientModalProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<ClientInput>();
  const country = JSON.parse(localStorage.getItem('country'));
  const [countryCode, setCountryCode] = useState(country?.code);
  const [callingCode, setCallingCode] = useState(country?.countryCode);
  const [altCountryCode, setAltCountryCode] = useState(country?.code);
  const [altCallingCode, setAltCallingCode] = useState(country?.countryCode);
  const [isLoading, setIsLoading] = useState(false)
  const [deleteClientIsLoading, setDeleteClientIsLoading] = useState(false)

  const closeClientModal = () => {
    resetFormInput();
    refetch && refetch();
    closeModal();
    removeClientFromState && removeClientFromState();
  }

  const resetFormInput = () => {
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('email', '');
    setValue('phone', '');
    setValue('altPhone', '');
    setValue('dob', '');
  };

  useEffect(() => {
    if (client) {
      setValue('firstName', client?.firstName);
      setValue('lastName', client?.lastName);
      setValue('email', client?.email);
      setValue('phone', client?.phone);
      setValue('altPhone', client?.altPhone);
      setValue('dob', convertFullDateStringToDate(client?.dob));
      setCountryCode(client?.countryCode);
      setCallingCode(client?.callingCode);
      setCountryCode(client?.altCountryCode);
      setCallingCode(client?.altCallingCode);
    }
  }, [client, setValue])

  const addClientSubmit = async (input: ClientInput) => {
    const clientData = {
      firstName: input.firstName,
      lastName: input.lastName,
      email: input?.email || null,
      phone: input.phone,
      altPhone: input?.altPhone ? input.altPhone : null,
      ...(input?.dob && input?.dob !== '-' && { dob: formatDateToOriginalDate(input.dob as unknown as Date, 'start') }),
      countryCode,
      callingCode,
      altCountryCode,
      altCallingCode,
    };

    try {
      setIsLoading(true);
      if (!client) {
        await axios.post(
          '/graphql',
          {
            query: print(CreateClientDocument),
            variables: { input: { ...clientData, salonId } },
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        ).then(({ data }) => {
          setIsLoading(false);
          if (data?.data?.createClient?.client) {
            closeClientModal();
            addToast({
              message: 'Client added successfully',
              variant: 'success',
            })
          }
          if (data?.errors?.length) {
            const message = data?.errors[0]?.message || API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
            addToast({
              variant: 'error',
              message,
            })
          }
          if (data?.data?.createClient?.errors?.length) {
            const message = data?.data?.createClient?.errors[0]?.message || API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
            addToast({
              variant: 'error',
              message,
            })
          }
        })
      } else {
        await axios.post(
          '/graphql',
          {
            query: print(UpdateClientDocument),
            variables: { input: { ...clientData, id: client?.id, salonId } },
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        ).then(({ data }) => {
          setIsLoading(false);
          if (data?.data?.updateClient?.status === 200) {
            closeClientModal();
            addToast({
              message: 'Client updated successfully',
              variant: 'success',
            })
          }
          if (data?.errors?.length) {
            const message = data?.errors[0]?.message || API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
            addToast({
              variant: 'error',
              message,
            })
          }
          if (data?.data?.updateClient?.errors?.length) {
            const message = data?.data?.updateClient?.errors[0]?.message || API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
            addToast({
              variant: 'error',
              message,
            })
          }
        })
      }
    } catch (createClientError) {
      setIsLoading(false);
      if (axios.isAxiosError(createClientError)) {
        const message = createClientError?.response?.data?.message || API_ERRORS.BUSINESS_CLIENTS_CREATE_FAILED;
        addToast && addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const deleteClient = () => {
    setDeleteClientIsLoading(true);
      axios.post(
        '/graphql',
        {
          query: print(DeleteClientDocument),
          variables: { input: { id: client?.id, salonId } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        setDeleteClientIsLoading(false)
        if (data?.data?.deleteClient?.status === 200) {
          closeClientModal()
          addToast({
            variant: "success",
            message: "Client deleted successfully",
          });
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.BUSINESS_CLIENTS_DELETE_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      }).catch(deleteClientError => {
        setDeleteClientIsLoading(false)
        const message = deleteClientError?.response?.data?.message || API_ERRORS.BUSINESS_CLIENTS_DELETE_FAILED;
        addToast({
          variant: "error",
          message,
        });
      })
  }

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue('phone', _phoneNumber)

    const pn = validatePhoneNumber(_phoneNumber, countryCode);

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('phone', {
        type: 'manual',
        message: 'Invalid phone number',
      });
    } else {
      // Clear the error if the phone number is valid
      clearErrors('phone');
    }
  }

  const _sanitizeAltPhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, altCallingCode);
    setValue('altPhone', _phoneNumber)

    const pn = validatePhoneNumber(_phoneNumber, countryCode);

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('altPhone', {
        type: 'manual',
        message: 'Invalid phone number',
      });
    } else {
      // Clear the error if the phone number is valid
      clearErrors('altPhone');
    }
  }

  return (
    <Modal
      title={
        client ? 'Edit client' : 'Add client'
      }
      subTitle={
        client ? 'Edit client details' : 'Enter client information below'
      }
      show={isVisible}
      closeModal={closeClientModal}
    >
      <form onSubmit={handleSubmit(addClientSubmit)} className="w-full mt-6 space-y-6" autoComplete='off'>
        <div className='w-full flex gap-x-4'>
          <Input name="firstName" label="First name" id="first-name" type="text" placeholder='Enter first name here' control={control} rules={{
            required: ACCOUNT_SETUP_ERRORS.FIRST_NAME_REQUIRED,
          }} error={errors.firstName} />
          <Input name="lastName" label="Last name" id="last-name" type="text" placeholder='Enter last name here' control={control} rules={{
            required: ACCOUNT_SETUP_ERRORS.LAST_NAME_REQUIRED,
          }} error={errors.lastName} />
        </div>
        <FormPhone
          country={{
            disabled: false,
            onSelect: (code, country: string) => {
              setCallingCode(code);
              setCountryCode(country);
            },
            value: countryCode,
          }}
          phone={{
            name: "phone",
            type: "text",
            placeholder: "9151930463",
            rules: {
              required: ERRORS.PHONE_REQUIRED,
              pattern: REGEX_PATTERNS.NUMBER,
              onChange: (e) => {
                _sanitizePhoneNumber(e.target.value);
              },
              disabled: false,
            },
            register,
            id: "phone-number",
            label: "Phone Number",
          }}
          helperText={getHelperTextForReactHookFormErrors(
            errors?.phone?.message as string
          )}
        />
        <FormPhone
          country={{
            disabled: false,
            onSelect: (code, country: string) => {
              setAltCallingCode(code);
              setAltCountryCode(country);
            },
            value: altCountryCode,
          }}
          phone={{
            name: "altPhone",
            type: "text",
            placeholder: "9151930463",
            rules: {
              pattern: REGEX_PATTERNS.NUMBER,
              onChange: (e) => {
                _sanitizeAltPhoneNumber(e.target.value);
              },
              disabled: false,
            },
            register,
            id: "alt-phone-number",
            label: "Secondary Phone Number",
          }}
          helperText={getHelperTextForReactHookFormErrors(
            errors?.altPhone?.message as string
          )}
        />
        <Input name="email" label="Email" id="email" type="email" placeholder='email@acme.com' control={control} rules={{
          pattern: REGEX_PATTERNS.EMAIL,
        }} error={errors.email} />
        <Input name="dob" label="Client Birthday" id="dob" type="date" placeholder='DD/MM/YY' control={control} />
        <div className='space-y-6'>
          <Button
            variant='primary'
            className=''
            size='lg'
            rounded='lg'
            disabled={isLoading || deleteClientIsLoading}
            loading={isLoading || deleteClientIsLoading}
          >
            Save
          </Button>
          <Button
            variant='text'
            className={`mx-auto ${client && 'text-red-500'}`}
            size='none'
            type='button'
            onClick={client ? deleteClient : closeClientModal}
            disabled={isLoading || deleteClientIsLoading}
            loading={isLoading || deleteClientIsLoading}
          >
            {client ? 'Delete' : 'Cancel'}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default AddClientModal
