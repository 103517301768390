import { useState } from "react";

export const useMobileSideMenu = () => {
  const [isMobileSideMenuOpen, setIsMobileSideMenuOpen] = useState(false);

  const toggleMobileSideMenu = () => {
    setIsMobileSideMenuOpen(!isMobileSideMenuOpen);
  };

  return {
    isMobileSideMenuOpen,
    toggleMobileSideMenu,
  };
}