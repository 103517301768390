import React from 'react'
import { ActionModalProps } from './appointmentComponents/types'
import { Button, Heading, Paragraph } from '../ui'
import { COLORS } from '../constants/colors'

const PromptLoyaltyDeletion = ({
  isVisible,
  closeModal,
  isLoading
}: ActionModalProps) => {
  return (
    isVisible ? (
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
        <div className="table-row">
          <div className="table-cell align-middle">
            <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
              <div className="flex flex-col items-center space-y-6">
                <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Delete Loyalty Program</Heading>
                <Paragraph size="b5" weight='semiBold' className='w-full text-center max-w-[400px] py-2' color={COLORS.GREY[400]}>
                Are you sure you want to perform this action? This is a permanent action that can't be reversed
                </Paragraph>
                
                <Button
                  variant="danger"
                  size='lg'
                  rounded='lg'
                  fontSize='b5'
                  className='capitalize'
                  onClick={() => closeModal('delete')}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Yes
                </Button>
                <Button
                  variant="text"
                  size='none'
                  rounded='none'
                  fontSize='b5'
                  className='mx-0'
                  onClick={() => closeModal('back')}
                  disabled={isLoading}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null
  )
}

export default PromptLoyaltyDeletion
