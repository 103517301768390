import * as React from "react";
import { TableCellProps } from "./types";

const TableCell = ({ children, isBoldText }: TableCellProps) => {
  return (
    <td align="left" className={`first:pl-3 xl:first:pl-11 py-2 px-2 md:py-4 ${isBoldText ? "font-semibold" : "font-normal"} whitespace-nowrap lg:whitespace-normal`}>
      {children}
    </td>
  );
};

export default TableCell;
