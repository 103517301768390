import * as React from "react";
import { ModalHeaderProps } from './types'
import { SvgArrowBack } from "../../icons";
import { Button, Heading, Paragraph } from "../..";
import { COLORS } from "../../../constants/colors";

export const ModalHeader = ({ className, title, subTitle, position, onClose }: ModalHeaderProps) => {
  return (
    <div className={`w-full flex flex-row xl:flex-col border-b border-grey-100 p-4 xl:p-0 xl:border-none ${position === 'center' ? 'items-center' : 'items-start'} ${className || ""}`}>
      <Button
        variant='icon'
        size='square'
        rounded='md'
        type='button'
        className='flex xl:hidden'
        onClick={onClose}
      >
        <SvgArrowBack width="24px" height="24px" />
      </Button>
      <Heading
        variant='h1'
        size='h8'
        weight='bold'
        color={COLORS.BLACK}
        className='flex-1 text-center'
      >{title}</Heading>
      {subTitle && (
        <Paragraph size="b5" weight='normal' className={`hidden xl:block flex-1 text-center max-w-[400px] ${position === 'center' && 'pt-4'}`} color={COLORS.GREY[400]}>
          {subTitle}
        </Paragraph>
      )}
    </div>
  );
};
