export const SERVICE_PRICE_TYPES = [
  { label: "Fixed", value: "fixed" },
  { label: "From", value: "starting_at" },
]

export const SALES_PAYMENT_METHODS = [
  {
    label: 'Cash',
    value: 'cash'
  },
  {
    label: 'POS',
    value: 'pos'
  },
  {
    label: 'Bank transfer',
    value: 'bank_transfer'
  },
  {
    label: 'Gift Card',
    value: 'gift_card'
  }
]