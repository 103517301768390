import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLoyaltyStepThree = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={16} cy={16} r={16} fill="#16796F" fillOpacity={0.1} />
    <mask
      id="loyalty-step-three_svg__a"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={7}
      y={7}
      width={18}
      height={18}
    >
      <path
        d="M16 24a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M16.003 11.2v4.804l3.392 3.392"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#loyalty-step-three_svg__a)">
      <path d="M6.4 6.4h19.2v19.2H6.4V6.4Z" fill="#16796F" />
    </g>
  </svg>
);
export default SvgLoyaltyStepThree;
