import * as React from "react";
import BaseButton from './Base';
import { BaseButtonProps } from './types';

const Primary = (props: BaseButtonProps) => {
  const className = props.className ? props.className : "";
  return (
    <BaseButton
      {...props}
      className={`bg-green-300 focus:outline-none text-white ${props.disabled
        ? "bg-green-300/50 dark:hover:bg-green-300/50 focus:ring-green-300/50"
        : "hover:bg-green-300/90 focus:ring-green-300/50 dark:hover:bg-green-300/90"
        } ${className || ""}`}
    />
  )
}

export default Primary