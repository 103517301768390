import { FaStar } from 'react-icons/fa';
import { RatingProps } from './types';
import * as React from 'react';

const AddRating = ({ initialRating, handleRatingChange }: RatingProps) => {
  return (
    <div className="flex space-x-2">
      {[...Array(5)].map((_, index) => (
        <FaStar
          key={index}
          color={index < initialRating ? '#F4B832' : '#E2E8E9'}
          style={{ cursor: 'pointer' }}
          size={38}
          onClick={() => handleRatingChange ? handleRatingChange(index + 1) : null}
        />
      ))}
    </div>
  );
};

export default AddRating;