import * as React from "react";
import { BasePillProps } from "./types";
import { ButtonRounded } from "../button/types";

const BasePill = ({ children, className, rounded = ButtonRounded.md, style }: BasePillProps) => {

  const borderRadius = {
    [ButtonRounded.none]: '',
    [ButtonRounded.sm]: 'rounded-sm',
    [ButtonRounded.md]: 'rounded-lg',
    [ButtonRounded.lg]: 'rounded-full'
  }

  return (
    <label
      style={{ ...style }}
      className={`flex text-center py-1 text-b6 w-fit ${borderRadius[rounded]} justify-center items-center space-x-1 px-3 ${className || ""
        }`}
    >
      {children}
    </label>
  );
};

export default BasePill;
