import React, { useEffect, useState } from 'react'
import { RedeemVoucherPageProps } from './types'
import { useSalonCache } from '../../hooks/useSalonCache';
import { Button, Heading, Paragraph, Table } from '../../ui';
import { ACTVITY_LOYALTY_TABLE_HEADERS, LOYALTY_ACTIVITY_TAB, LOYALTY_ACTIVITY_TABS, MOBILE_ACTIVITY_LOYALTY_TABLE_HEADERS } from './constants';
import RedeemVoucherCard from './RedeemVoucherCard';
import CustomerDashboardShimmer from './DashboardShimmer';
import { COLORS } from '../../constants/colors';
import { SvgDropDown, SvgInfo, SvgLocationPin } from '../../ui/icons';
import { Business, CustomerLoyaltyOverviewData } from '../../graphql/generated';
import { fetchClientLoyaltyOverviewData } from './api';
import { formatTableHeadersFilterArray, limitString } from '../../utils/misc';
import { generateActivityLoyaltyTableData } from './Utils';
import { Listbox, Transition } from '@headlessui/react';
import LoyaltyCard from '../../ui/molecules/loyaltyCard/LoyaltyCard';
import ReactCardFlip from 'react-card-flip';
import LoyaltyBackCard from '../../ui/molecules/loyaltyCard/LoyaltyBackCard';

const LoyaltyTablePage = ({
  customerInfo,
}: RedeemVoucherPageProps) => {
  const { getSalonData } = useSalonCache();
  const salon = getSalonData();
  const [selected, setSelected] = React.useState<Business | null>(salon);
  const [businesses, setBusinesses] = useState<Business[]>([])
  const [activityAndRewardActiveTab, setActivityAndRewardActiveTab] = useState(LOYALTY_ACTIVITY_TAB.REWARDS)
  const [isLoading, setIsLoading] = useState(false);
  const [customerLoyaltyOverview, setCustomerLoyaltyOverview] = useState<CustomerLoyaltyOverviewData>(null);
  const [clientMilestones, setClientMilestones] = useState([])

  const fetchData = async () => {
    const res = await fetchClientLoyaltyOverviewData({
      businessId: selected?.id,
    })
    setCustomerLoyaltyOverview(res || null)
    // Sort milestones to show active ones first
    const sortedMilestones = res?.clientMilestones?.sort((a, b) => {
      // Assuming "active" status should come first, otherwise adjust the condition
      if (a.status === 'active' && b.status !== 'active') return -1;
      if (a.status !== 'active' && b.status === 'active') return 1;
      return 0;
    });
    
    // Map the sorted milestones
    setClientMilestones(
      sortedMilestones?.map((mile) => {
        return {
          ...mile,
          isFlipped: false,
        };
      })
    );
  }

  const fetchCustomerLoyaltyOverviewDataAsync = async () => {
    setIsLoading(true);
    await fetchData();
    setIsLoading(false);
  }

  useEffect(() => {
    if (selected && customerInfo) {
      fetchCustomerLoyaltyOverviewDataAsync();
    }
  }, [selected])

  useEffect(() => {
    if (customerInfo && Array?.isArray(customerInfo?.businesses) && customerInfo?.businesses?.length) {
      setBusinesses(customerInfo?.businesses)
      setSelected(customerInfo?.businesses[0])
    }
  }, [customerInfo])

  const onChange = (business: Business) => {
    setSelected(business)
  }

  const activityLoyaltyTableData = generateActivityLoyaltyTableData(customerLoyaltyOverview?.activity || [], salon?.countryCode)

  const flipCard = (index: number) => {
    const updatedClientMilestones = [...clientMilestones];
    updatedClientMilestones[index].isFlipped = !updatedClientMilestones[index].isFlipped;
    setClientMilestones(updatedClientMilestones);
  }

  const getRewardsContent = () => {
    return (
      <div className='w-full flex flex-wrap gap-4 p-4 lg:p-0'>
        {Array?.isArray(clientMilestones) && clientMilestones ? clientMilestones?.map((clientMilestone, index) => {
          return (
            <div className='w-full max-w-[500px]' key={index}>
              <ReactCardFlip isFlipped={clientMilestone?.isFlipped} flipDirection="vertical" flipSpeedFrontToBack={0.5}>
                <LoyaltyCard loyalty={clientMilestone?.milestone?.loyaltyProgram} business={selected} clientMilestone={clientMilestone} onClick={() => flipCard(index)} />
                <LoyaltyBackCard loyalty={clientMilestone?.milestone?.loyaltyProgram} clientMilestone={clientMilestone} onClick={() => flipCard(index)} />
              </ReactCardFlip>
            </div>
          )
        }) : null}
      </div>
    )
  }

  const showInitialLoadingShimmer = isLoading && !customerLoyaltyOverview;
  const cucumlativePercentage = (customerLoyaltyOverview?.activeRewards / customerLoyaltyOverview?.clientMilestones?.length) * 100;
  return (
    <div className='w-full p-0 md:p-4 xl:p-10'>
        <div className='w-full flex flex-col py-8 px-0 md:px-3 xl:px-6 md:border border-grey-50 rounded-lg space-y-12'>
          <div className='w-full flex flex-col px-4 md:px-0 space-y-6'>
            <div className='w-full flex items-center justify-between border-b border-grey-50 md:border-0 pb-6'>
              <div className='flex flex-col space-y-6'>
                <Heading variant='h1' size='h8' weight='bold'>Hello, {customerInfo?.firstName}</Heading>
              </div>
              {Array.isArray(businesses) && businesses.length > 0 ? (
                <Listbox value={selected} onChange={onChange}>
                  <div className='relative xl:hidden mb-1'>
                    <Listbox.Button style={{
                      width: '180px'
                    }} className='relative w-full cursor-pointer rounded-full bg-white border border-grey-100 p-3 flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
                      <div className='flex items-center space-x-2'>
                        <span className='text-grey-300'>
                          <SvgLocationPin width="16px" height="16px" />
                        </span>
                        <span className='inline truncate'>
                          {limitString(selected?.name, 15) || 'Select Business'}
                        </span>
                        <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                          <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                        </span>
                      </div>
                    </Listbox.Button>
                    <Transition
                      as={React.Fragment}
                      leave='transition ease-in duration-100'
                      leaveFrom='opacity-100'
                      leaveTo='opacity-0'
                    >
                      <Listbox.Options
                        className='absolute max-h-60 w-full z-[9] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                        style={{
                          top: 60,
                          bottom: 'auto'
                        }}
                      >
                        {businesses.map((business, businessIdx) => (
                          <Listbox.Option
                            key={businessIdx}
                            className={({ active }) =>
                              `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                active ? 'bg-grey-900 text-white' : 'text-grey-900'
                              }`
                            }
                            value={business}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? 'font-medium' : 'font-normal'
                                  }`}
                                >
                                  {business?.name}
                                </span>
                                {selected ? (
                                  <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-grey-900'></span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              ) : null}
            </div>
            {showInitialLoadingShimmer ? (
              <CustomerDashboardShimmer />
            ) : (
              <div className='w-full flex flex-col lg:flex-row lg:justify-between border border-grey-50 rounded-md py-4 px-2 md:px-8 gap-8'>
                <div className='w-full lg:w-[40%] flex flex-col justify-between'>
                  <div className='flex justify-between items-start'>
                    <div className='flex flex-col space-y-2'>
                      <Paragraph size='b4' color={COLORS.GREEN[300]} weight='semiBold' className='flex space-x-2 items-center'>
                        <span className='text-b5 font-semibold'>Cumulative points </span>
                        <div className='group flex relative text-grey-300'>
                          <SvgInfo width='15px' height='15px' />
                          <span className='w-[200px] group-hover:opacity-100 transition-opacity bg-grey-50 p-2 text-b6 text-grey-300 rounded-md absolute left-2 -translate-x-2 opacity-0 m-4 mx-auto'>
                            These reward points are only redeemable in this location.
                          </span>
                        </div>
                      </Paragraph>
                      
                      <Paragraph size='h4' color={COLORS.GREY[3900]} weight='semiBold'>
                        {customerLoyaltyOverview?.cumulativePoints}
                      </Paragraph>
                    </div>
                    {Array.isArray(businesses) && businesses.length > 0 ? (
                      <Listbox value={selected} onChange={onChange}>
                        <div className='hidden xl:inline-block relative mb-1'>
                          <Listbox.Button className='relative w-full md:min-w-[200px] cursor-pointer rounded-full bg-white border border-grey-100 py-4 px-3 flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
                            <div className='flex items-center space-x-2'>
                              <span className='text-grey-300'>
                                <SvgLocationPin width="16px" height="16px" />
                              </span>
                              <span className='inline truncate'>
                                {limitString(selected?.name, 15) || 'Select Business'}
                              </span>
                              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                                <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                              </span>
                            </div>
                          </Listbox.Button>
                          <Transition
                            as={React.Fragment}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Listbox.Options
                              className='absolute max-h-60 w-full z-[9] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                              style={{
                                top: 60,
                                bottom: 'auto'
                              }}
                            >
                              {businesses.map((business, businessIdx) => (
                                <Listbox.Option
                                  key={businessIdx}
                                  className={({ active }) =>
                                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                      active ? 'bg-grey-900 text-white' : 'text-grey-900'
                                    }`
                                  }
                                  value={business}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected ? 'font-medium' : 'font-normal'
                                        }`}
                                      >
                                        {business?.name}
                                      </span>
                                      {selected ? (
                                        <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-grey-900'></span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    ) : null}
                  </div>

                  <div className='w-full h-[5px] rounded-full bg-grey-50'>
                    <div className={`h-full rounded-full w-[${cucumlativePercentage}] bg-green-300`}></div>
                  </div>
                </div>
                <div className='w-full xl:max-w-[400px] flex flex-col justify-between md:h-[135px] p-4 border border-grey-50 rounded-md'>
                  <div className='w-full flex flex-col'>
                    <Heading variant='h1' size='h11' weight='bold' color={COLORS.GREEN['300']}>Active rewards</Heading>
                    <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']} className='hidden md:flex'>Number of rewards active</Paragraph>
                  </div>

                  <Paragraph size='b1' weight='normal' color={COLORS.GREY['900']}>{customerLoyaltyOverview?.activeRewards}</Paragraph>
                </div>
              </div>
            )}
          </div>
            <div className='w-full flex flex-col space-y-4'>
              <div className='w-full flex border-l border-t border-r border-grey-50'>
                {Array?.isArray(LOYALTY_ACTIVITY_TABS) && LOYALTY_ACTIVITY_TABS?.length ? LOYALTY_ACTIVITY_TABS.map((tab, index) => {
                  return (
                    <Button
                      key={index}
                      variant={activityAndRewardActiveTab === tab.key ? 'light' : 'text'}
                      className='text-grey-300 w-fit focus:outline-none'
                      size='md'
                      onClick={() => setActivityAndRewardActiveTab(tab.key)}
                    >
                      {tab.title}
                    </Button>
                  )
                }) : null}
              </div>
              {activityAndRewardActiveTab === LOYALTY_ACTIVITY_TAB.REWARDS ?
                getRewardsContent()
                 : (
                  <Table headers={formatTableHeadersFilterArray(ACTVITY_LOYALTY_TABLE_HEADERS)} mobileHeaders={MOBILE_ACTIVITY_LOYALTY_TABLE_HEADERS} rows={activityLoyaltyTableData} hasBorder={true} />
              )}
            </div>
        </div>
      </div >
  )
}

export default LoyaltyTablePage