import { endOfMonth, startOfMonth } from 'date-fns';
import React from 'react'
import { DatePicker } from 'rsuite'
import { CalendarDateProps } from './types'

const MonthPicker = ({
  selectedDates,
  setSelectedDates
}: CalendarDateProps) => {
  const handleDateChange = (date) => {
    const dates = [startOfMonth(date), endOfMonth(date)] as [Date, Date]
    setSelectedDates(dates)
  }

  return (
    <div className="relative">
      <DatePicker
        onChange={handleDateChange}
        value={selectedDates[0]}
        oneTap
        format="yyyy-MM"
        placeholder="Select month"
        cleanable={false}
        placement='bottomEnd'
        preventOverflow
      />
    </div>
  )
}

export default MonthPicker
