import * as React from 'react'
import ReactModal from "react-modal";
import { ModalProps } from './types';
import { ModalHeader } from '../..';

const ModalSafeForReact18 = ReactModal as React.ComponentType<ReactModal['props']>;

const TopCenter = ({ children, show, closeModal, size = 'md', ...props }: ModalProps) => {
  return (
    <ModalSafeForReact18
      isOpen={show}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className={`absolute w-full rounded-0 xl:rounded-xl block outline-0 max-h-[100%] xl:h-fit xl:max-h-[calc(100%-80px)] overflow-auto
        ${size === 'md' ? 'max-w-[600px]' : size === 'lg' ? 'max-w-[900px]' : 'max-w-[1200px]'}
      `}
      overlayClassName="fixed inset-0 bg-black/70 backdrop-blur-[2px] z-40 flex justify-center xl:py-[40px]"
      shouldCloseOnOverlayClick={true}
    >
      <section className="overflow-hidden min-h-screen xl:min-h-fit bg-white border border-grey-100 p-0 pb-12 xl:p-12">
        <ModalHeader
          title={props.title || ''}
          subTitle={props.subTitle}
          onClose={closeModal}
          position={
            size === 'md' ? 'center' : 'left'
          }
        />
        <div className="overflow-y-scroll px-6 xl:px-0">
          <div className="w-full min-h-fit flex flex-col">
            {children}
          </div>
        </div>
      </section>
    </ModalSafeForReact18>
  )
}
export default TopCenter