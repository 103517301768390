import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNoShow = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect x={0.5} width={32} height={32} rx={8} fill="#F8F7F2" />
    <path
      d="M13 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM20 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM16.5 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="#707A02"
    />
    <path
      d="M16.5 23a7 7 0 1 1 7-7 7.008 7.008 0 0 1-7 7Zm0-13a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6Z"
      fill="#707A02"
    />
  </svg>
);
export default SvgNoShow;
