/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import { Controller, useForm } from 'react-hook-form'
import axios from 'axios'
import type { DatePickerProps } from 'antd'
import { DatePicker, TimePicker } from 'antd'
import dayjs from 'dayjs'
import {
  Appointment,
  AppointmentPreviewDocument,
  AppointmentProduct,
  AppointmentService,
  Client,
  DeleteAppointmentMilestoneDocument,
  PosDevice,
  Product,
  SalonStaff,
  Service,
  TransferAccount,
  UpdateAppointmentDocument
} from '../graphql/generated'
import {
  IAppointmentPriceSummary,
  SelectedProduct,
  UpdateAppointmentServiceInputProps,
  ViewAppointmentModalProps
} from './types'
import { useUserCache } from '../hooks/useUserCache'
import { useModal } from '../hooks/useModal'
import {
  AppointmentCurrentServiceStaff,
  AppointmentInput,
  IAppointment,
  IAppointmentProduct,
  IAppointmentService
} from '../uicomponents/appointmentComponents/types'
import { MultiSelectOption } from '../ui/molecules/multiselect/types'
import {
  convertServicesToAppointment,
  fetchClientsData,
  fetchPosDevicesData,
  fetchProductsData,
  fetchServicesData,
  fetchStaffListData,
  fetchTransferAccountsData,
  getUpdateAppointmentServiceInputData,
  removeFromOptions,
  removeProductFromSelectedServices,
  removeServiceFromSelectedServices
} from './utils'
import {
  addStartAtToViewAppointmentService,
  convertUpdateServicesToAppointmentStaffServiceForPreviewPrice,
  formatBankAccountsToSelectField,
  formatClientToSelectFieldAppointmentCreation,
  formatPosTerminalsToSelectField,
  formatProductsToSelectField,
  formatServicesToSelectField,
  formatStaffToSelectFieldWithEmptyFirstValue,
  getServiceTime
} from '../utils/utils'
import {
  convert12HourTo24Hour,
  convertFullDateStringToDate,
  convertFullDateStringToTime,
  formatInToPrice,
  formatNumber,
  formatSnakeCaseText,
  getHoursAndMinutesString
} from '../utils/misc'
import { convertProductsToCommaSeparatedString } from '../utils/misc'
import Heading from '../ui/atoms/heading/Heading'
import { COLORS } from '../constants/colors'
import Paragraph from '../ui/atoms/paragraph/Paragraph'
import SvgClose from '../ui/icons/Close'
import SelectOnchangeField from '../ui/molecules/input/SelectOnchangeField'
import { DEFAULT_CURRENCY } from '../constants/currency'
import {
  APPOINTMENT_PAYMENT_METHOD,
  APPOINTMENT_STATUS,
  APPOINTMENT_TYPES,
  PAYMENT_METHODS
} from '../uicomponents/bookingSales/constants'
import { FormatNumber } from '../ui/atoms/formatNumber/FormatNumber'
import SvgTrash from '../ui/icons/Trash'
import Button from '../ui/atoms/button/Button'
import ViewAppointmentShimmer from '../uicomponents/appointmentComponents/modals/ViewAppointmentShimmer'
import SvgArrowBack from '../ui/icons/ArrowBack'
import SvgChevronLeft from '../ui/icons/ChevronLeft'
import SvgCheckMarkGreen from '../ui/icons/CheckMarkGreen'
import SvgUser from '../ui/icons/User'
import SvgSolarGiftLinear from '../ui/icons/SolarGiftLinear'
import { FormLabel } from '../ui/atoms/formLabel'
import Checkbox from '../ui/atoms/checkbox/Checkbox'
import { FormHelperText } from '../ui/atoms/helperText/FormHelperText'
import { API_ERRORS, ERRORS } from '../constants/errors'
import CollapseRow from '../ui/organism/collapseRow/CollapseRow'
import MultiSelect from '../ui/molecules/multiselect/multiselect'
import SvgPlus from '../ui/icons/Plus'
import Input from '../ui/molecules/input/Input'
import SearchTerm from '../ui/organism/debounceQuery/SearchTerm'
import { MAXIMUM_NOTE_LENGTH } from '../constants/form'
import {
  getHelpTextForCharacterLeft,
  getHelperTextForReactHookFormErrors
} from '../utils/form'
import FormTextarea from '../ui/molecules/input/FormTextarea'
import SvgCharmMenuKebab from '../ui/icons/CharmMenuKebab'
import SelectInput from '../ui/molecules/input/SelectInput'
import { Modal } from '../ui/templates/modal/Modal'
import ViewReceiptModal from '../uicomponents/appointmentComponents/modals/ViewReceiptModal'
import { useSalonCache } from '../hooks/useSalonCache'
import { fetchAppointmentById } from '../uicomponents/appointmentComponents/api'
import { print } from 'graphql'
import { SvgEdit, SvgGreyMinus, SvgGreyPlus, SvgHome, SvgWhiteDiamond } from '../ui/icons'
import { YES_OR_NO_SETTINGS } from '../constants/information'
import { AppointmentPriceCard } from './AppointmentPriceBreakDown'
import { ContactLink, Pill } from '../ui'
import { REGEX_PATTERNS } from '../constants/pattern'
import { canPerformAction } from '../utils/permission'
import { PERMISSION_CONSTANTS } from '../constants/permission'
import { getStaffWithHighestPay } from '../uicomponents/appointmentComponents/utils'
import RemoveStaffFromAppointmentAlertModal from './RemoveStaffFromAppoinntmentAlertModal'

const ViewAppointmentModal = ({
  isVisible,
  closeModal,
  cancelAppointment,
  salonId,
  appointmentId,
  addToast,
  // refetchData,
  checkInOrOutAppointment,
  deleteAppointment,
  markAsNoShow,
  refetchCalendarData,
  revertCancellation
}: ViewAppointmentModalProps) => {
  const [actionIsLoading, setActionIsLoading] = useState(false)
  const [updatedAppointment, setUpdatedAppointment] =
    useState<Appointment | null>(null)
  const { getBusinessData } = useUserCache()
  const business = getBusinessData()
  const businessId = business?.businessId as string
  const { getSalonData } = useSalonCache()
  const salon = getSalonData()
  const [hasMadePayment, setHasMadePayment] = useState<string | null>(null)
  const [appointmentStatus, setAppointmentStatus] = useState<string>('pending')
  const [enableAddOns, setEnableAddons] = useState(false)
  const [enablePromo, setEnablePromo] = useState(false)
  const [enableDiscount, setEnableDiscount] = useState(false)
  const [isDiscountApplied, setIsDiscountApplied] = useState(false)
  const [discountType, setDiscountType] = useState(null)
  const [discountValue, setDiscountValue] = useState(null)
  const [enableProductAddition, setEnableProductAddition] = useState(false)
  const [selectedServices, setSelectedServices] = useState<
    UpdateAppointmentServiceInputProps[]
  >([])
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    []
  )
  const [changeStaffView, setChangeStaffView] = useState<boolean>(false)
  const [serviceStaffSelected, setServiceStaffSelected] = useState<
    string | null
  >(null)
  const [promoCode, setPromoCode] = useState<{
    promoType: string
    promoValue: number
  } | null>(null)
  const [appointmentSummary, setAppointmentSummary] =
    useState<IAppointmentPriceSummary | null>(null)
  const [selectedStaff, setSelectedStaff] = useState('')
  const [showReceipt, setShowReceipt] = useState<boolean>(false)
  const [clients, setClients] = useState<Client[]>([])
  const [services, setServices] = useState<Service[]>([])
  const [products, setProducts] = useState<Product[]>([])
  const [bankTransfers, setBankTransfers] = useState<TransferAccount[]>([])
  const [posTerminals, setPosTerminals] = useState<PosDevice[]>([])
  const [staff, setStaff] = useState<SalonStaff[]>([])
  const [clientsOptions, setClientsOptions] = useState<MultiSelectOption[]>([])
  const [servicesOptions, setServicesOptions] = useState<MultiSelectOption[]>(
    []
  )
  const [productsOptions, setProductsOptions] = useState<MultiSelectOption[]>(
    []
  )
  const [transfersOptions, setTransfersOptions] = useState<MultiSelectOption[]>(
    []
  )
  const [posTerminalsOptions, setPosTerminalsOptions] = useState<
    MultiSelectOption[]
  >([])
  const [staffMembersOptions, setStaffMembersOptions] = useState<
    MultiSelectOption[]
  >([])
  const [updateAppointmentIsLoading, setUpdateAppointmentIsLoading] =
    useState(false)
  const [previewAppointmentIsLoading, setPreviewAppointmentIsLoading] =
    useState(false)
  const [currentServiceStaff, setCurrentServiceStaff] = useState<AppointmentCurrentServiceStaff>(null)

  const actions = {
    deleteAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.deleteAppointment),
    rescheduleAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.rescheduleAppointment),
    cancelAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.cancelAppointment),
    markNoShow: canPerformAction(PERMISSION_CONSTANTS?.calendar?.markNoShow),
    applyDiscounts: canPerformAction(PERMISSION_CONSTANTS?.calendar?.applyDiscounts),
    applyPromo: canPerformAction(PERMISSION_CONSTANTS?.calendar?.applyPromo),
    checkInOrOut: canPerformAction(PERMISSION_CONSTANTS?.calendar?.checkInOrOut),
    revertCancellation: canPerformAction(PERMISSION_CONSTANTS?.calendar?.revertCancellation),
    editPrices: canPerformAction(PERMISSION_CONSTANTS?.calendar?.editPrices),
    modifyAppointmennt: canPerformAction(PERMISSION_CONSTANTS?.calendar?.modifyAppointmennt)
  }
  const {
    isVisible: isReceiptModalVisible,
    openModal: openReceiptModal,
    closeModal: closeReceiptModal
  } = useModal()
  const {
    isVisible: isRemoveStaffAssignmentToServiceModalVisible,
    openModal: openRemoveStaffAssignmentToServiceModal,
    closeModal: closeRemoveStaffAssignmentToServiceModal
  } = useModal()
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<AppointmentInput>({
    defaultValues: {
      appointmentType: 'online',
      paymentMethod: 'unpaid',
      newClient: false
    }
  })

  const [appointment, setAppointment] = useState<Appointment | null>()
  const [isLoading, setIsLoading] = useState(true)
  const showInitialLoadingShimmer =
    isLoading && !appointment && salonId && appointmentId

  useEffect(() => {
    if (isVisible) {
      const fetchData = async () => {
        try {
          const clientsData = await fetchClientsData({ salonId })
          setClients(clientsData)

          const productsData = await fetchProductsData({
            salonId,
            isPurchasable: true
          })
          setProducts(productsData)

          const servicesData = await fetchServicesData({ salonId })
          setServices(servicesData)

          const bankTransfersData = await fetchTransferAccountsData({ salonId })
          setBankTransfers(bankTransfersData)

          const posTerminalsData = await fetchPosDevicesData({ salonId })
          setPosTerminals(posTerminalsData)

          const staffData = await fetchStaffListData({ salonId })
          setStaff(staffData?.staffs)
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }

      if (salonId) {
        fetchData()
      }
    }
  }, [salonId, isVisible])

  useEffect(() => {
    const updateOptions = () => {
      if (Array?.isArray(clients) && clients?.length) {
        setClientsOptions(formatClientToSelectFieldAppointmentCreation(clients))
      }
      if (Array?.isArray(services) && services?.length) {
        setServicesOptions(formatServicesToSelectField(services))
      }
      if (Array?.isArray(products) && products?.length) {
        setProductsOptions(formatProductsToSelectField(products))
      }
      if (Array?.isArray(bankTransfers) && bankTransfers?.length) {
        setTransfersOptions(formatBankAccountsToSelectField(bankTransfers))
      }
      if (Array?.isArray(posTerminals) && posTerminals?.length) {
        setPosTerminalsOptions(formatPosTerminalsToSelectField(posTerminals))
      }
      if (Array?.isArray(staff) && staff?.length) {
        setStaffMembersOptions(formatStaffToSelectFieldWithEmptyFirstValue(staff, true));
      }
    }

    updateOptions()
  }, [clients, products, services, staff, posTerminals, bankTransfers])

  const updateAppointmentAsync = async (input: AppointmentInput) => {
    const appointmentDate = input?.appointmentDate.replace(/\//g, '-')
    // const startAt = new Date(`${input.appointmentDate} ${input.appointmentTime}`).toISOString();
    const startAt = `${appointmentDate}T${convert12HourTo24Hour(
      input.appointmentTime
    )}`
    // throw an error is selectedServices is empty
    if (!selectedServices.length) {
      addToast &&
        addToast({
          message: 'Kindly select a service',
          variant: 'error'
        })
      return
    }

    const appointmentServices = getUpdateAppointmentServiceInputData(
      selectedServices,
      appointmentDate
    )

    const servicesWithoutStaff = appointmentServices.filter(
      (service) => service.staffIds === null || service?.staffIds?.length === 0
    )
    if (servicesWithoutStaff && servicesWithoutStaff.length) {
      addToast &&
        addToast({
          message: 'Kindly select a staff for each service',
          variant: 'error'
        })
      return
    }

    const servicesWithNullStartAt = appointmentServices.filter(
      (service) => service.startAt === null
    )
    if (servicesWithNullStartAt && servicesWithNullStartAt.length) {
      addToast &&
        addToast({
          message: 'Kindly select a time for each service',
          variant: 'error'
        })
      return
    }
    let status = appointmentStatus
    if (appointmentStatus === APPOINTMENT_STATUS.pending) {
      status = APPOINTMENT_STATUS?.confirmed
    } else if (
      input?.isDepositCompleted &&
      input?.paymentMethod !== 'unpaid' &&
      status === APPOINTMENT_STATUS.deposit_pending
    ) {
      status = APPOINTMENT_STATUS?.confirmed
    } else if (
      input?.paymentMethod !== 'unpaid' &&
      status === APPOINTMENT_STATUS.deposit_pending
    ) {
      status = APPOINTMENT_STATUS?.confirmed
    }

    let paymentMethodsInput
    if (
      appointment?.depositPaymentMethod !== 'unpaid' &&
      input?.paymentMethod === 'unpaid'
    ) {
      paymentMethodsInput = {
        appointmentPaymentMethodId: appointment?.depositPaymentMethodId,
        appointmentPaymentMethod: appointment?.depositPaymentMethod,
        paymentType: 'deposit_payment'
      }
    } else if (
      appointment?.depositAmount > 0 &&
      appointment?.paymentMethod === 'unpaid' &&
      input?.paymentMethod !== 'unpaid' &&
      appointment?.depositPaymentMethod !== 'unpaid'
    ) {
      paymentMethodsInput = {
        appointmentPaymentMethodId: input?.paymentMethodId,
        appointmentPaymentMethod: input?.paymentMethod,
        paymentType: 'balance_payment'
      }
    } else if (
      !input?.isDepositCompleted &&
      appointment?.paymentMethod === 'unpaid' &&
      input?.paymentMethod !== 'unpaid' &&
      appointment?.depositPaymentMethod === 'unpaid'
    ) {
      paymentMethodsInput = {
        appointmentPaymentMethodId: input?.paymentMethodId,
        appointmentPaymentMethod: input?.paymentMethod,
        paymentType: 'full_payment'
      }
    } else if (input?.isDepositCompleted) {
      paymentMethodsInput = {
        appointmentPaymentMethodId: input?.paymentMethodId,
        appointmentPaymentMethod: input?.paymentMethod,
        paymentType: 'deposit_payment'
      }
    } else {
      paymentMethodsInput = {
        appointmentPaymentMethodId: appointment?.paymentMethodId,
        appointmentPaymentMethod: appointment?.paymentMethod,
        ...(input?.paymentMethod !== 'unpaid' &&
        appointment?.paymentMethod !== 'voucher'
          ? { paymentType: 'full_payment' }
          : {})
      }
    }
    const payload = {
      id: appointmentId,
      salonId,
      services: appointmentServices,
      products: selectedProducts.length
        ? convertProductsToCommaSeparatedString(selectedProducts)
        : [],
      startAt,
      note: input?.note,
      appointmentStatus: status,
      appointmentType: input?.appointmentType,
      ...(input?.addOnAmount
        ? {
            addOnAmount: parseFloat(
              input?.addOnAmount.toString().replace(',', '')
            )
          }
        : {}),
      appointmentPaymentMethod: input?.paymentMethod,
      appointmentPaymentMethodId: input?.paymentMethodId,
      addOnReason: input?.addOnReason,
      ...paymentMethodsInput,
      ...(input?.promoCode &&
      input?.promoCode !== appointment?.appointmentPromo?.title
        ? { promoCode: input?.promoCode }
        : {}),
      discountType,
      discountValue: Number(watch('discountValue'))
    }
    try {
      setUpdateAppointmentIsLoading(true)
      await axios
        .post(
          '/graphql',
          {
            query: print(UpdateAppointmentDocument),
            variables: { input: { ...payload } }
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        )
        .then(({ data }) => {
          setUpdateAppointmentIsLoading(false)
          if (data?.data?.updateAppointment?.appointment) {
            const appointmentData = data?.data?.updateAppointment?.appointment
            setUpdatedAppointment(appointmentData || null)
            if (
              appointmentData?.paymentMethod ===
                APPOINTMENT_PAYMENT_METHOD.unpaid &&
              appointmentData?.depositPaymentMethod !==
                APPOINTMENT_PAYMENT_METHOD.unpaid
            ) {
              setHasMadePayment('deposit')
            } else if (
              appointmentData?.paymentMethod !==
              APPOINTMENT_PAYMENT_METHOD.unpaid
            ) {
              setHasMadePayment('full')
            } else {
              setHasMadePayment(null)
            }
            if (showReceipt) {
              openReceiptModal()
              setShowReceipt(false)
            }
            if (appointmentStatus === 'completed') {
              openReceiptModal()
            }

            if (!showReceipt) {
              resetFormInput()
              closeModal('updatedAppointment')
              addToast &&
                addToast({
                  message: 'Appointment updated successfully',
                  variant: 'success'
                })
            }
          } else if (data?.errors?.length) {
            const message =
              data?.errors[0]?.message ||
              API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
            addToast &&
              addToast({
                variant: 'error',
                message
              })
          } else if (data?.data?.updateAppointment?.errors?.length) {
            const message =
              data?.data?.updateAppointment?.errors[0]?.message ||
              API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
            addToast &&
              addToast({
                variant: 'error',
                message
              })
          } else {
            const message = API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
            addToast &&
              addToast({
                variant: 'error',
                message
              })
          }
        })
    } catch (updateAppointmentError) {
      setUpdateAppointmentIsLoading(false)
      if (axios.isAxiosError(updateAppointmentError)) {
        const message =
          updateAppointmentError?.response?.data?.message ||
          API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
        addToast &&
          addToast({
            message,
            variant: 'error'
          })
      }
    }
  }

  const removeService = (serviceId?: string) => {
    const watchServices = removeFromOptions(watch('services'), serviceId)
    setValue('services', watchServices as [])

    const getSelectedServices = removeServiceFromSelectedServices(
      selectedServices,
      serviceId
    )
    if (Array.isArray(getSelectedServices) && getSelectedServices.length)
      setSelectedServices(getSelectedServices)
  }

  const removeReward = async() => {
    try {
      setUpdateAppointmentIsLoading(true)
      await axios
        .post(
          '/graphql',
          {
            query: print(DeleteAppointmentMilestoneDocument),
            variables: { input: { appointmentId: appointment?.id } }
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        )
        .then(({ data }) => {
          setUpdateAppointmentIsLoading(false)
          if (data?.data?.deleteAppointmentMilestone?.appointment) {
            const appointmentData = data?.data?.updateAppointment?.appointment
            setAppointment({
              ...appointment,
              milestone: null,
              discountAmount: appointmentData?.discountAmount,
              discountType: appointmentData?.discountType,
              discountValue: appointmentData?.discountValue
            })
            setDiscountType(null)
            setDiscountValue(0)
            setValue('discountType', null)
            setValue('discountValue', 0)
            setIsDiscountApplied(false)
            setEnableDiscount(false)
            previewPrice()
            addToast &&
              addToast({
                message: 'Reward removed successfully',
                variant: 'success'
              })
          } else if (data?.errors?.length) {
            const message =
              data?.errors[0]?.message ||
              API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
            addToast &&
              addToast({
                variant: 'error',
                message
              })
          } else if (data?.data?.updateAppointment?.errors?.length) {
            const message =
              data?.data?.updateAppointment?.errors[0]?.message ||
              API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
            addToast &&
              addToast({
                variant: 'error',
                message
              })
          } else {
            const message = API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
            addToast &&
              addToast({
                variant: 'error',
                message
              })
          }
        })
    } catch (updateAppointmentError) {
      setUpdateAppointmentIsLoading(false)
      if (axios.isAxiosError(updateAppointmentError)) {
        const message =
          updateAppointmentError?.response?.data?.message ||
          API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
        addToast &&
          addToast({
            message,
            variant: 'error'
          })
      }
    }
  }

  const removeProduct = (productId?: string) => {
    const watchProducts = removeFromOptions(watch('products'), productId)
    setValue('products', watchProducts as [])

    const getSelectedProducts = removeProductFromSelectedServices(
      selectedProducts,
      productId
    )
    if (Array.isArray(getSelectedProducts) && getSelectedProducts.length)
      setSelectedProducts(getSelectedProducts)
  }

  const getAppointmentDetailsAsync = async () => {
    setIsLoading(true)
    const appointmentDetailsData = await fetchAppointmentById({
      salonId: salonId as string,
      id: appointmentId as string
    })
    if (!appointmentDetailsData) {
      setIsLoading(false)
      return
    }
    setIsLoading(false)
    setAppointment(appointmentDetailsData)
    setDiscountType(appointmentDetailsData.discountType)
    setDiscountValue(appointmentDetailsData.discountValue)
    setIsDiscountApplied(appointmentDetailsData.discountType ? true : false)
    getAppointmentDetails(appointmentDetailsData)
  }

  const applyDiscount = async () => {
    await previewPrice()
    setIsDiscountApplied(true)
  }

  const groupAndSortServicesByTime = (services: AppointmentService[]) => {
    return services
      .sort((a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime()) // Convert dates to numbers for subtraction
      .map(service => ({
        ...service
      }));
  }

  const getAppointmentDetails = async (appointment: Appointment) => {
    if (appointment) {
      setValue(
        'appointmentDate',
        convertFullDateStringToDate(appointment?.startAt)
      )
      setValue(
        'appointmentTime',
        convertFullDateStringToTime(appointment?.startAt as string)
      )
      const groupedServices = groupAndSortServicesByTime(appointment?.appointmentServices)
      const appointmentServices = groupedServices
        ?.map((service: AppointmentService) => {
          const matchedOption = services?.find(
            (option: { id: string }) => option?.id === service?.serviceId
          )
          return matchedOption
            ? {
                ...matchedOption,
                staff: service?.appointmentServiceStaff?.map((staff) => {
                  return {
                    firstName: staff?.salonStaff?.user?.firstName,
                    staffId: staff?.salonStaff?.id
                  }
                }) ?? null,
                quantity: service?.quantity,
                startAt:
                  convertFullDateStringToTime(service?.startAt as string) ||
                  new Date()
              }
            : null
        })
        .filter(Boolean) as UpdateAppointmentServiceInputProps[]
      setSelectedServices(appointmentServices)
      const appointmentProducts = appointment?.appointmentProducts
        ?.map((product: AppointmentProduct) => {
          const matchedOption = products?.find(
            (option: { id: string }) => option?.id === product?.productId
          )
          return { ...matchedOption, quantity: product?.quantity }
        })
        .filter(Boolean) as Product[]
      setSelectedProducts(appointmentProducts)
      if (appointmentProducts?.length > 0) {
        setEnableProductAddition(true)
      }
      setValue('note', appointment?.clientNote?.note || '')
      setValue('appointmentType', appointment?.appointmentType)
      setValue('addOnReason', appointment?.addOnReason)
      setValue('addOnAmount', appointment?.addOnAmount)
      if (appointment?.addOnAmount && appointment?.addOnAmount !== 0.0) {
        setEnableAddons(true)
      }
      setValue('paymentMethod', appointment?.paymentMethod)
      setValue('paymentMethodId', appointment?.paymentMethodId)
      setValue('discountValue', appointment?.discountValue)
      if (
        appointment?.appointmentPromo !== null &&
        appointment?.appointmentPromo?.amount != 0.0
      ) {
        setPromoCode({
          promoType: 'fixed_value',
          promoValue: appointment?.appointmentPromo?.amount
        })
        setValue('promoCode', appointment?.appointmentPromo?.title)
        setEnablePromo(true)
      }

      if (
        appointment?.paymentMethod === APPOINTMENT_PAYMENT_METHOD.unpaid &&
        appointment?.depositPaymentMethod !== APPOINTMENT_PAYMENT_METHOD.unpaid
      ) {
        setHasMadePayment('deposit')
      } else {
        if (appointment?.paymentMethod !== APPOINTMENT_PAYMENT_METHOD.unpaid) {
          setHasMadePayment('full')
        }
      }
      setAppointmentStatus(appointment?.appointmentStatus || 'confirmed')
    }
  }

  useEffect(() => {
    if (services?.length && appointmentId) {
      getAppointmentDetailsAsync()
    }
  }, [services, appointmentId])

  const getServiceSummary = () => {
    return getServiceSummaryUtils(
      selectedServices,
      services,
      selectedProducts,
      products,
      removeService,
      removeProduct
    )
  }

  const removeStaff = (staffId: string, serviceId: string) => {
    const serviceSelectedFromOptions = selectedServices.map((service) => {
      if (service?.id === serviceId) {
        return {
          ...service,
          staff: service?.staff?.filter((staffItem) => staffItem?.staffId !== staffId)
        }
      }
      return service
    })
    setSelectedServices(serviceSelectedFromOptions)
  }

  const handleServiceTimeChange = (
    serviceId: string,
    time: string,
    index?: number
  ) => {
    const timeString = convert12HourTo24Hour(time) as string
    if (serviceId) {
      const serviceSelectedFromOptions = selectedServices.map((service) => {
        if (service?.id === serviceId) {
          return {
            ...service,
            startAt: timeString
          }
        }
        return service
      })
      setSelectedServices(serviceSelectedFromOptions)
      if (index === 0) {
        setValue('appointmentTime', timeString)
      }
    }
  }

  const modifyCart = (
    id: string,
    type: 'add' | 'remove',
    inventory: 'product' | 'service' | 'voucher'
  ) => {
    // modify cart for services
    if (inventory === 'service') {
      let remove = false
      const serviceSelectedFromOptions = selectedServices.map((service) => {
        if (service?.id === id) {
          const quantity = service?.quantity || 1
          if (type === 'add') {
            return {
              ...service,
              quantity: quantity + 1
            }
          }
          if (type === 'remove') {
            if (quantity > 1) {
              return {
                ...service,
                quantity: quantity - 1
              }
            } else {
              remove = true
            }
          }
        }
        return service
      })
      if (remove) {
        // get serive index from watch("services") via id
        removeService(id)
        return
      }
      setSelectedServices(serviceSelectedFromOptions)
    }

    // modify cart for products
    if (inventory === 'product') {
      let remove = false
      const productSelectedFromOptions = selectedProducts.map((product) => {
        if (product?.id === id) {
          const getAppointmentProduct = appointment?.appointmentProducts?.find(
            (item) => item?.productId === id
          )
          const quantity = product?.quantity || 1
          if (type === 'add') {
            const newQuantity = quantity + 1
            if (
              newQuantity - getAppointmentProduct?.quantity >
              product?.stockCount
            ) {
              // flag error stockCount
              addToast({
                variant: 'error',
                message: 'Product quantity exceeded'
              })
            } else {
              return {
                ...product,
                quantity: newQuantity
              }
            }
          }
          if (type === 'remove') {
            if (quantity > 1) {
              return {
                ...product,
                quantity: quantity - 1
              }
            } else {
              remove = true
            }
          }
        }
        return product
      })
      if (remove) {
        // get serive index from watch("services") via id
        removeProduct(id)
        return
      }
      setSelectedProducts(productSelectedFromOptions)
    }
  }

  const formatServiceStaffOptions = (service: UpdateAppointmentServiceInputProps): MultiSelectOption[] => {
    if (!staff || !staff.length || !staffMembersOptions?.length) return [];

    if (!service?.staff?.length) {
      return staffMembersOptions;
    }

    const staffOptions: MultiSelectOption[] = [];
    const selectedStaffIds = service.staff.map((staff) => staff.staffId);
    // show all staff in staffMembersOptions that is not in selectedStaffIds
    staffMembersOptions.forEach((staffOption) => {
      if (!selectedStaffIds.includes(staffOption.value)) {
        staffOptions.push(staffOption);
      }
    })

    return staffOptions;
  };

  const onChangeStaff = (selectedStaff: string, serviceId: string) => {
    const matchedItem = staff.find((item) => item.id === selectedStaff);

    // changeStaff(value, serviceId)
    const serviceSelectedFromOptions = selectedServices.map((service) => {
      if (service?.id === serviceId) {
        return {
          ...service,
          staff: Array?.isArray(service?.staff) && service?.staff?.length ? [...service.staff, {
            firstName: matchedItem?.user?.firstName,
            staffId: matchedItem?.id
          }] : [{
            firstName: matchedItem?.user?.firstName,
            staffId: matchedItem?.id
          }]
        }
      }

      return service
    })
    setSelectedServices(serviceSelectedFromOptions)
    previewPrice()
  }

  const initiateCloseStaffAssignmentToServiceModal = (action?: string) => {
    if (action === 'back') {
      setCurrentServiceStaff(null)
      closeRemoveStaffAssignmentToServiceModal()
      return;
    }

    if (action === 'remove') {
      if (currentServiceStaff) {
        removeStaff(currentServiceStaff?.staff?.staffId, currentServiceStaff?.service?.id)
      }
      setCurrentServiceStaff(null)
      closeRemoveStaffAssignmentToServiceModal()
      return;
    }
  }

  const getServiceSummaryUtils = (
    selectedServices: UpdateAppointmentServiceInputProps[],
    services: Service[],
    selectedProducts: SelectedProduct[],
    products: Product[],
    servicesCanBeRemoved?: (serviceId?: string) => void,
    productsCanBeRemoved?: (productId?: string) => void
  ) => {
    if (
      (Array.isArray(selectedProducts) && selectedProducts.length !== 0) ||
      (Array.isArray(selectedServices) && selectedServices.length !== 0)
    ) {
      return (
        <div className='flex flex-col p-3 space-y-6 border border-grey-20 rounded-md'>
          {Array.isArray(selectedServices) && selectedServices.length > 0 ? (
            <div className='flex flex-col space-y-4'>
              <div className='hidden lg:flex space-x-2'>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[45%]'
                >
                  SELECTED SERVICES
                </Heading>
                {/* <Heading variant='h3' size='b6' color={COLORS.GREY[300]} weight="bold" className='w-[15%]'>QUANTITY</Heading> */}
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[30%]'
                >
                  ASSIGN STAFF
                </Heading>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[15%]'
                >
                  TIME
                </Heading>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[15%] flex justify-end'
                >
                  PRICE
                </Heading>
                <div className='w-[5%] flex justify-end'></div>
              </div>
              <>
                {selectedServices.map((service, index) => {
                  const quantity = service?.quantity || 1
                  const staff = getStaffWithHighestPay(service?.serviceStaffs, service?.staff);
                  const canBeDeleted = (appointment?.appointmentStatus !==
                    APPOINTMENT_STATUS.completed && !appointment?.appointmentVoucher) || (appointment?.appointmentVoucher && appointment?.appointmentVoucher?.packageVoucher?.redemptionMode !== 'services');
                  const priceSlashed = appointment?.appointmentVoucher && appointment?.appointmentVoucher?.packageVoucher?.redemptionMode === 'services' && appointment?.appointmentVoucher?.packageVoucher?.voucherServices?.find((voucherService) => voucherService?.serviceId === service?.id);
                  const serviceTime = !service?.startAt ? getServiceTime(index, selectedServices[index - 1]?.startAt, selectedServices[index - 1]?.duration) : service?.startAt

                  return (
                    <div
                      className='grid grid-cols-2 xl:flex xl:flex-row items-start gap-2'
                      key={service?.id}
                    >
                      <div className='flex flex-col w-full xl:w-[45%]'>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREY[300]}
                          className=''
                        >
                          {service?.name}
                        </Paragraph>{' '}
                        <span className='text-grey-300 text-b6'>
                          {getHoursAndMinutesString(service?.duration)}
                        </span>
                      </div>

                      <div className='flex gap-2 flex-wrap w-[30%]'>
                        {Array.isArray(service?.staff) && service?.staff.length
                          ? service?.staff.map((eachStaff, index) => (
                              <div
                                className='flex space-x-2 items-center bg-white border border-grey-50 px-[10px] py-2 rounded-full'
                                key={index}
                              >
                                <Paragraph size='b6' color={COLORS.GREY[300]}>
                                  {eachStaff.firstName}
                              </Paragraph>
                              {appointment?.appointmentStatus !== 'completed' && actions?.modifyAppointmennt ?
                                <span
                                  className='text-b6 cursor-pointer'
                                  onClick={() => {
                                    setCurrentServiceStaff({
                                      staff: {
                                        firstName: eachStaff.firstName,
                                        staffId: eachStaff.staffId
                                      },
                                      service
                                    })
                                    openRemoveStaffAssignmentToServiceModal()
                                    // removeStaff(eachStaff?.staffId, service?.id)
                                  }}
                                >
                                  <SvgClose width='14px' height='14px' />
                                </span> : null}
                              </div>
                            ))
                          : null}
                        {appointment?.appointmentStatus !== 'completed' && actions?.modifyAppointmennt ?
                          <SelectOnchangeField
                            options={formatServiceStaffOptions(service) || []}
                            onChange={(selectedValue) => {
                              onChangeStaff(selectedValue, service?.id) // Call the changeStaff function with the selected value
                            }}
                          /> : null}
                      </div>
                      <div className='flex flex-col w-full xl:w-[15%]'>
                        <TimePicker
                          use12Hours
                          disabled={
                            appointment?.appointmentStatus ===
                            APPOINTMENT_STATUS.completed || !actions.modifyAppointmennt
                          }
                          format='h:mm a'
                          value={
                            serviceTime
                              ? dayjs(serviceTime, 'HH:mm')
                              : undefined
                          }
                          onChange={(value, dateString) => {
                            handleServiceTimeChange(
                              service?.id,
                              dateString,
                              index
                            )
                          }}
                        />
                      </div>
                      <div className='w-full xl:w-[20%] flex'>
                        <div
                          className={`flex w-[70%] space-x-2 xl:justify-end items-center`}
                        >
                          <Paragraph
                            size='b4'
                            color={COLORS.GREY[300]}
                            className={`${priceSlashed ? 'line-through' : ''}`}
                          >
                            {DEFAULT_CURRENCY}
                            <FormatNumber
                              value={
                                (staff?.price || service?.price) * quantity
                              }
                            />
                            {service?.pricingType === 'from' ? (
                              <span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full ml-2'>
                                from
                              </span>
                            ) : null}
                          </Paragraph>
                        </div>
                        <div className='w-[30%] flex xl:justify-end items-center'>
                          {canBeDeleted && actions?.modifyAppointmennt ? (
                            <span
                              className='text-red-600 text-b5 cursor-pointer'
                              onClick={() => {
                                servicesCanBeRemoved &&
                                  servicesCanBeRemoved(service?.id)
                              }}
                            >
                              <SvgTrash width='14px' height='14px' />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            </div>
          ) : null}
          {Array.isArray(selectedProducts) && selectedProducts.length > 0 ? (
            <div className='flex flex-col space-y-4'>
              <div className='flex space-x-2 mt-4'>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[50%]'
                >
                  SELECTED PRODUCTS
                </Heading>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[20%]'
                >
                  SELECT QUANTITY
                </Heading>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[20%] flex justify-end'
                >
                  AMOUNT
                </Heading>
                <div className='w-[10%] flex justify-end'></div>
              </div>
              <>
                {selectedProducts?.map((product) => {
                  const quantity = product?.quantity || 1
                  return (
                    <div
                      className='flex items-start space-x-2'
                      key={product?.id}
                    >
                      <div className='flex w-[50%]'>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREY[300]}
                          className='flex-1'
                        >
                          {product?.name}
                        </Paragraph>
                      </div>
                      <div className='w-[20%] flex space-x-2'>
                        <div className='w-full flex'>
                          <Button
                            variant='icon'
                            size='none'
                            type='button'
                            className='border-0'
                            onClick={() =>
                              modifyCart(product?.id, 'add', 'product')
                            }
                          >
                            <SvgGreyPlus width='22px' height='22px' />
                          </Button>
                          <div className='flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center'>
                            <Paragraph
                              size='b6'
                              weight='normal'
                              color={COLORS.BLACK}
                            >
                              {quantity || 1}
                            </Paragraph>
                          </div>
                          <Button
                            variant='icon'
                            size='none'
                            type='button'
                            className='border-0'
                            onClick={() =>
                              modifyCart(product?.id, 'remove', 'product')
                            }
                          >
                            <SvgGreyMinus width='22px' height='22px' />
                          </Button>
                        </div>
                      </div>
                      <div className='flex justify-end w-[20%]'>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREY[300]}
                          className=''
                        >
                          {DEFAULT_CURRENCY}
                          <FormatNumber value={product?.retailPrice} />
                        </Paragraph>
                      </div>
                      <div className='w-[10%] flex justify-end items-center'>
                        {actions?.modifyAppointmennt ?
                          <span
                            className='text-red-600 text-b5 cursor-pointer'
                            onClick={() => {
                              productsCanBeRemoved &&
                                productsCanBeRemoved(product?.id)
                            }}
                          >
                            <SvgTrash width='14px' height='14px' />
                          </span> : null}
                      </div>
                    </div>
                  )
                })}
              </>
            </div>
          ) : null}

          {appointmentSummary ? (
            <div className='w-full flex flex-col space-y-2 border-t border-grey-100 py-3'>
              <div className='flex justify-between items-center'>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                  Subtotal
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                  {formatInToPrice(
                    ((appointmentSummary?.totalServicesAmount as number) +
                      appointmentSummary?.totalProductsAmount) as number
                  )}
                </Paragraph>
              </div>

              {watch('addOnAmount') ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Addon
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    +{DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.addOnAmount} />
                  </Paragraph>
                </div>
              ) : null}
              <div className='flex justify-between items-center'>
                <Paragraph size='b4' color={COLORS.GREY[300]}>
                  Tax
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[300]}>
                  {DEFAULT_CURRENCY}
                  <FormatNumber value={appointmentSummary?.taxAmount} />
                </Paragraph>
              </div>
              {appointment?.milestone ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Reward Discount - {
                      appointment?.milestone?.customerReceivesType === 'percentage'
                        ? `${appointment?.milestone?.customerReceives}%`
                        : `${formatInToPrice(appointment?.milestone?.customerReceives)?.replace(".00", "")}`
                    }
                  </Paragraph>
                  <div className='flex items-center space-x-3'>
                    <Paragraph size='b4' color={COLORS.GREY[800]}>
                      {formatInToPrice(appointment?.discountAmount)}
                    </Paragraph>
                    <span
                      className='text-red-600 text-b5 cursor-pointer'
                      onClick={removeReward}
                    >
                      <SvgTrash width='14px' height='14px' />
                    </span>
                  </div>
              </div>
              ) : null}
              {!appointment?.milestone && ((enableDiscount && !isDiscountApplied && actions?.applyDiscounts && appointment?.appointmentStatus !== 'checked_in') ||
                (appointment?.appointmentStatus === 'checked_in' && actions?.editPrices && enableDiscount && !isDiscountApplied && actions?.applyDiscounts)) ? (
                <div className='flex justify-between items-center'>
                  <div>
                    <Paragraph size='b4' color={COLORS.GREY[300]}>
                      Discount
                    </Paragraph>
                  </div>
                  <div className='flex w-1/2 border rounded-lg'>
                    <div className='pl-2 py-2 rounded-l '>
                      <Button
                        rounded='sm'
                        size='sm'
                        type='button'
                        variant='light'
                        className={
                          discountType === 'percentage'
                            ? 'border-2 text-green-500'
                            : ''
                        }
                        onClick={() => setDiscountType('percentage')}
                      >
                        %
                      </Button>
                    </div>
                    <div className=' py-2'>
                      <Button
                        rounded='sm'
                        size='sm'
                        type='button'
                        variant='light'
                        className={
                          discountType === 'fixed_value'
                            ? 'border-2 text-green-500'
                            : ''
                        }
                        onClick={() => setDiscountType('fixed_value')}
                      >
                        {DEFAULT_CURRENCY}
                      </Button>
                    </div>
                    <div className='py-2 flex-1'>
                      <Input
                        control={control}
                        type='number'
                        id='discount'
                        name='discountValue'
                        className='h-5 py-4 border-0'
                        rules={{
                          pattern: REGEX_PATTERNS.NUMBER
                        }}
                      />
                    </div>
                    <div className='py-2 rounded-r'>
                      <Button
                        rounded='sm'
                        size='sm'
                        type='button'
                        variant='transparent'
                        onClick={() => applyDiscount()}
                        disabled={!discountType}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}

              {!appointment?.milestone && appointmentSummary.discountAmount && isDiscountApplied ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Discount
                  </Paragraph>
                  <div className='flex items-center'>
                    <Paragraph
                      className='mr-4'
                      size='b4'
                      color={COLORS.GREY[300]}
                    >
                      -{DEFAULT_CURRENCY}
                      <FormatNumber
                        value={appointmentSummary?.discountAmount}
                      />
                    </Paragraph>

                    {(actions?.applyDiscounts && appointment?.appointmentStatus !== 'checked_in') || (appointment?.appointmentStatus === 'checked_in' && actions?.applyDiscounts && actions?.editPrices) ?
                      <>
                        <span
                          className='mr-3'
                          style={{
                            marginRight: '10px',
                            marginLeft: '10px',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setIsDiscountApplied(false)
                            setEnableDiscount(true)
                          }}
                        >
                          <SvgEdit width='15px' height='15px' />
                        </span>
                        {actions?.modifyAppointmennt ?
                          <span
                            className='text-red-600 cursor-pointer'
                            onClick={() => {
                              setIsDiscountApplied(false)
                              setEnableDiscount(false)
                              setValue('discountValue', null)
                              setDiscountType(null)
                            }}
                          >
                            <SvgTrash width='15px' height='15px' />
                          </span> : null}
                      </> : null}
                  </div>
                </div>
              ) : null}

              {appointmentSummary?.appointmentPromo ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Promo code
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    -{DEFAULT_CURRENCY}
                    <FormatNumber
                      value={appointmentSummary?.appointmentPromo?.amount}
                    />
                  </Paragraph>
                </div>
              ) : null}

              {(appointmentSummary?.depositAmount &&
                appointment?.depositPaymentMethod ===
                  APPOINTMENT_PAYMENT_METHOD.unpaid &&
                appointment?.appointmentStatus === 'deposit_pending') ||
              appointment?.appointmentStatus === 'deposit_abandoned' ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>
                    Deposit Required
                  </Paragraph>
                  <Paragraph size='b4'>
                    {formatInToPrice(appointmentSummary?.depositAmount)}
                  </Paragraph>
                </div>
              ) : null}

              {appointment.outstandingFee && appointment?.outstandingFee > 0 ? <div className='flex justify-between items-center'>
                <Paragraph size='b4' color={COLORS.GREY[400]}>
                  Cancellation fee
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                  {DEFAULT_CURRENCY}
                  <FormatNumber
                    value={
                      appointment?.outstandingFee
                    }
                  />
                </Paragraph>
              </div> : null}

              <div className='flex justify-between items-center'>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                  Total
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                  {DEFAULT_CURRENCY}
                  {appointment?.appointmentStatus !== 'completed' ?
                    <FormatNumber
                      value={
                        appointmentSummary?.totalPaid -
                        appointmentSummary?.processingFeeAmount + appointment?.outstandingFee
                      }
                    /> :
                    <FormatNumber
                      value={
                        appointment?.actualTotalPaid
                      }
                    />}
                </Paragraph>
              </div>

              {appointment?.paymentMethod ===
                APPOINTMENT_PAYMENT_METHOD.unpaid &&
              appointment?.depositAmount &&
              appointment?.depositPaymentMethod !==
                APPOINTMENT_PAYMENT_METHOD.unpaid &&
              appointment?.appointmentStatus !== 'deposit_pending' &&
              appointment?.appointmentStatus !== 'deposit_abandoned' ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>
                    Deposit Made
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    {formatInToPrice(appointment?.depositAmount)}
                  </Paragraph>
                </div>
              ) : null}
              {appointmentSummary && appointmentSummary?.totalPaid && appointment?.paymentMethod ===
                APPOINTMENT_PAYMENT_METHOD.unpaid &&
              appointment?.totalPaid - appointment?.totalAmountPaid !== 0 &&
              appointment?.depositAmount &&
              appointment?.appointmentStatus !== 'deposit_pending' &&
              appointment?.appointmentStatus !== 'deposit_abandoned' ? (
                <div className='w-full flex flex-col space-y-2 border-t border-grey-100'>
                  <div className='flex justify-between items-center'>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                      Outstanding Balance
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                      {formatInToPrice(
                        appointmentSummary?.totalPaid - appointment?.depositAmount
                      )}
                    </Paragraph>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )
    }
    return null
  }

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setValue('appointmentDate', dateString)
  }

  const dateFormat = 'YYYY/MM/DD'

  const onChangeTime = (value: any, dateString: string) => {
    const timeString = convert12HourTo24Hour(dateString) as string
    setValue('appointmentTime', timeString)

    const firstService = selectedServices[0]
    if (firstService && dateString) {
      handleServiceTimeChange(firstService?.id, timeString)
    }
  }

  const previewPrice = async () => {
    if (Array.isArray(selectedServices) && selectedServices.length === 0) return
    let startAt = new Date().toISOString()
    if (watch('appointmentDate') && watch('appointmentTime')) {
      startAt = `${watch('appointmentDate')?.replace(
        /\//g,
        '-'
      )}T${convert12HourTo24Hour(watch('appointmentTime'))}`
    }
    if (!appointment?.client?.phone) return
    let services = selectedServices;
    if (appointment?.appointmentVoucher?.packageVoucher?.redemptionMode === 'services') {
      const selectedServicesForPreview = selectedServices?.map((service) => {
        const checkIfServiceExistInVoucherService = appointment?.appointmentVoucher?.packageVoucher?.voucherServices?.find((voucherService) => voucherService?.serviceId === service?.id)
        return !checkIfServiceExistInVoucherService && service
      }).filter((service) => service)

      services = selectedServicesForPreview
    }
    
    const appointmentData = {
      client: {
        phone: appointment?.client?.phone,
        countryCode: appointment?.client?.countryCode
      },
      services: convertUpdateServicesToAppointmentStaffServiceForPreviewPrice(
        services,
        startAt
      ),
      ...(selectedProducts.length && {
        products: convertProductsToCommaSeparatedString(selectedProducts)
      }),
      ...(watch('addOnAmount') && {
        addOnAmount: parseFloat(
          watch('addOnAmount')?.toString()?.replace(',', '')
        )
      }),
      ...(watch('promoCode') && watch('promoCode') !== ''
        ? { promoCode: watch('promoCode') }
        : {}),
      paymentMethod: watch('paymentMethod'),
      discountType,
      discountValue: Number(watch('discountValue'))
    }

    try {
      setPreviewAppointmentIsLoading(true)
      await axios
        .post(
          '/graphql',
          {
            query: print(AppointmentPreviewDocument),
            variables: { input: { ...appointmentData, salonId: salon?.id } }
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        )
        .then(({ data }) => {
          setPreviewAppointmentIsLoading(false)
          const priceSummary = data?.data?.appointmentPreview?.data
          if (priceSummary) {
            setAppointmentSummary(priceSummary)
          }

          if (priceSummary?.appointmentPromo) {
            setPromoCode({
              promoType: 'amount',
              promoValue: priceSummary?.appointmentPromo?.amount
            })
          }
        })
    } catch (appointmentError) {
      setPreviewAppointmentIsLoading(false)
      // if (axios.isAxiosError(appointmentError)) {
      //   // const message = appointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_PRICE_SUMMARY_FAILED;
      // }
    }
  }

  useEffect(() => {
    const startTime = convert12HourTo24Hour(watch('appointmentTime'))
    const appointmentServices =
      Array?.isArray(services) && services?.length
        ? convertServicesToAppointment(services)
        : []
    if (watch('services') && appointmentServices?.length) {
      const serviceSelectedFromOptions =
        Array?.isArray(appointmentServices) &&
        (appointmentServices?.filter((service) => {
          const serviceId = service?.id
          const exists = selectedServices.some(
            (selectedService) => selectedService?.id === serviceId
          )
          return (
            !exists &&
            watch('services')?.some(
              (serviceSelected: MultiSelectOption) =>
                serviceSelected?.value === serviceId
            )
          )
        }) as UpdateAppointmentServiceInputProps[])
      
      const newServices = [
        ...selectedServices,
        ...serviceSelectedFromOptions
      ]
      setSelectedServices(addStartAtToViewAppointmentService(newServices, startTime))
    }
  }, [watch('services')])

  useEffect(() => {
    if (watch('products')) {
      const productSelectedFromOptions =
        Array?.isArray(products) &&
        products
          ?.filter((product) => {
            const productId = product?.id
            const existsInProductsField = watch('products')?.some(
              (selectedProduct: MultiSelectOption) =>
                selectedProduct?.value === productId
            )
            const existsInSelectedProducts = selectedProducts.some(
              (selectedProduct) => selectedProduct.id === productId
            )

            if (!existsInProductsField && existsInSelectedProducts) {
              // Return null to indicate that this service should be removed
              setSelectedProducts((prevSelectedProducts) =>
                prevSelectedProducts.filter((p) => p.id !== productId)
              )
              return false
            }
            return existsInProductsField && !existsInSelectedProducts
          })
          .filter((product) => product !== null)
      if (
        Array?.isArray(productSelectedFromOptions) &&
        productSelectedFromOptions?.length
      ) {
        setSelectedProducts((prevSelectedProducts) => [
          ...prevSelectedProducts,
          ...productSelectedFromOptions
        ])
      }
    }
  }, [watch('products')])

  useEffect(() => {
    previewPrice()
  }, [
    selectedServices,
    selectedProducts,
    watch('promoCode'),
    watch('paymentMethod'),
    watch('appointmentTime'),
    watch('appointmentDate'),
    watch('addOnAmount'),
    watch('discountValue')
  ])

  const getCheckOutOrCheckInButton = () => {
    // const appointmentHasStarted = checkIfAppointmentHasStarted(
    //   appointment?.startAt as string
    // );

    // const appointmentCanCheckInOrCheckOut = appointment?.appointmentStatus === 'pending' || appointment?.appointmentStatus === 'confirmed' || appointment?.appointmentStatus === 'checked_in';
    // const appointmentCanCheckIn = appointmentHasStarted && (appointment?.appointmentStatus === 'pending' || appointment?.appointmentStatus === 'confirmed');
    // const appointmentCanCheckOut = appointmentHasStarted && appointment?.appointmentStatus === 'checked_in';
    // const status = appointment?.appointmentStatus && appointment?.appointmentStatus === 'pending' ? 'confirmed' : null;
    // let status = appointment?.appointmentStatus;

    // if (appointment?.appointmentStatus === 'pending') {
    //   status = 'confirmed';
    // }
    return (
      <>
        {appointment?.appointmentStatus === 'checked_in' ? (
          <Button
            variant='primary'
            className=''
            disabled={
              updateAppointmentIsLoading ||
              watch('paymentMethod') === 'unpaid' ||
              actionIsLoading ||
              isLoading ||
              previewAppointmentIsLoading
            }
            loading={updateAppointmentIsLoading || actionIsLoading}
            size='md'
            rounded='lg'
            onClick={() => setAppointmentStatus('completed')}
          >
            {watch('paymentMethod') === 'unpaid'
              ? 'Select Payment Method'
              : 'Check Out'}
          </Button>
        ) : appointment?.appointmentStatus !== 'completed' ? (
          <Button
            variant='primary'
            className=''
            disabled={
              updateAppointmentIsLoading ||
              actionIsLoading ||
              isLoading ||
              previewAppointmentIsLoading ||
              appointment?.appointmentStatus === APPOINTMENT_STATUS.cancelled ||
              appointment?.appointmentStatus === APPOINTMENT_STATUS.no_show
            }
            loading={updateAppointmentIsLoading || actionIsLoading}
            size='md'
            rounded='lg'
            onClick={() => setAppointmentStatus('checked_in')}
          >
            Check in
          </Button>
        ) : null}
      </>
    )
  }

  const closeAddAppointmentModal = () => {
    resetFormInput()
    setAppointment(null)
    setHasMadePayment(null)
    setUpdatedAppointment(null)
    setAppointmentStatus('pending')
    setEnableAddons(false)
    setEnablePromo(false)
    setEnableDiscount(false)
    setEnableProductAddition(false)
    setAppointmentSummary(null)
    closeModal('close')
  }

  const resetFormInput = () => {
    setValue('services', [])
    setValue('products', [])
    setValue('appointmentTime', '')
    setEnableAddons(false)
    setEnablePromo(false)
    setValue('appointmentDate', '')
    setValue('appointmentTime', '')
    setValue('promoCode', '')
    setValue('addOnAmount', undefined)
    setValue('paymentMethod', 'unpaid')
    setValue('addOnReason', '')
    setValue('isDepositCompleted', false)
    setSelectedServices([])
    setSelectedProducts([])
  }

  const checkPromoCodeValidity = async () => {
    if (appointment) {
      // try {
      //   const { data, success } = await redeemPromo.mutateAsync({
      //     client: {
      //       countryCode: appointment?.client?.callingCode,
      //       callingCode: appointment?.client?.countryCode,
      //       phone: appointment?.client?.phone,
      //     },
      //     promoCode: debouncedSearchQuery,
      //     salonId: salonId as string,
      //     businessId: businessId as string
      //   });
      //   if (success && data) {
      //     setPromoCode({
      //       promoType: data?.promoType,
      //       promoValue: data?.promoValue
      //     })
      //   }
      // } catch (errorBusinessPromoCode) {
      //   if (axios.isAxiosError(errorBusinessPromoCode)) {
      //     const message = errorBusinessPromoCode?.response?.data?.message || 'Promo code could not be applied';
      //     addToast && addToast({
      //       variant: "error",
      //       message,
      //     });
      //   }
      // }
    }
  }

  useEffect(() => {
    if (
      watch('promoCode') !== '' &&
      appointment &&
      watch('promoCode') !== appointment?.appointmentPromo?.title
    ) {
      checkPromoCodeValidity()
    }
  }, [watch('promoCode')])

  const initiateCloseReceiptModal = () => {
    closeReceiptModal()
    setUpdatedAppointment(null)
  }

  const enableViewReceiptModalAsync = () => {
    setShowReceipt(true)
  }

  const getAppointmentContent = () => {
    if (showInitialLoadingShimmer) {
      return <ViewAppointmentShimmer />
    }
    if (appointment) {
      return (
        <form
          onSubmit={handleSubmit(updateAppointmentAsync)}
          className='w-full relative pt-[80px] pb-[220px] md:pb-[120px]'
          autoComplete='off'
        >
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={closeAddAppointmentModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={closeAddAppointmentModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                Appointment Details
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col px-6 py-4 space-y-6'>
            <Heading variant='h1' size='h9' weight='semiBold' className='flex items-center space-x-4'>
              <span>{actions?.modifyAppointmennt ? 'Edit' :'View'} appointment</span> {appointment && (appointment?.appointmentStatus === 'cancelled' || appointment?.appointmentStatus === 'no_show') ? <Pill variant={appointment?.appointmentStatus === "cancelled" ? "danger" : "primary"}>
                {formatSnakeCaseText(appointment?.appointmentStatus)}
              </Pill> : null}
            </Heading>
            <Paragraph size='b4'>Make changes to appointment details</Paragraph>
          </div>
          {appointment && hasMadePayment ? (
            <div className='w-full px-6'>
              <div className='w-full flex p-4 rounded-md bg-green-75 space-x-4 items-start'>
                <SvgCheckMarkGreen width='24px' height='24px' />
                <div className='w-full flex flex-col'>
                  <Paragraph
                    size='b4'
                    color={COLORS.GREEN[850]}
                    weight='semiBold'
                  >
                    {appointment?.client?.firstName +
                      ' ' +
                      appointment?.client?.lastName }
                    {appointment?.isActive ? ' has made ' : "'s "}
                    {appointment?.isActive ? hasMadePayment === 'full' ? 'full payment' : 'a deposit' : 'payment is yet to be confirmed.'}
                  </Paragraph>
                  {appointment?.client?.firstName?.toLowerCase() !== 'walkin' && actions?.checkInOrOut ?
                    <ContactLink
                      variant='tel'
                      value={`${appointment?.client?.callingCode}${appointment?.client?.phone}`}
                      label={`${appointment?.client?.callingCode}${appointment?.client?.phone}`}
                    /> : null}
                  <AppointmentPriceCard appointment={appointment} />
                </div>
              </div>
            </div>
          ) : (
            <div className='w-full px-6'>
              <div className='w-full flex p-4 rounded-md bg-green-75 space-x-4 items-start'>
                <SvgUser width='24px' height='24px' />
                <div className='w-full flex flex-col'>
                  <Paragraph
                    size='b4'
                    color={COLORS.GREEN[850]}
                    weight='semiBold'
                  >
                    Client:{' '}
                    {appointment?.client?.firstName +
                      ' ' +
                      appointment?.client?.lastName }
                  </Paragraph>
                  {appointment?.client?.firstName?.toLowerCase() !== 'walkin' ?
                    <ContactLink
                      variant='tel'
                      value={`${appointment?.client?.callingCode}${appointment?.client?.phone}`}
                      label={`${appointment?.client?.callingCode}${appointment?.client?.phone}`}
                    /> : null}
                </div>
              </div>
            </div>
          )}
          {appointment && appointment?.appointmentVoucher ? (
            <div className='w-full px-6 my-4'>
              <div className='w-full flex p-4 rounded-md bg-grey-75 space-x-4 items-start'>
                <SvgSolarGiftLinear width='24px' height='24px' />
                <div className='w-full flex flex-col space-y-4 '>
                  <Paragraph
                    size='b4'
                    color={COLORS.GREY[800]}
                    weight='semiBold'
                  >
                    The{' '}
                    {
                      appointment?.appointmentVoucher?.packageVoucher?.package
                        ?.name
                    }{' '}
                    voucher was used
                  </Paragraph>
                </div>
              </div>
            </div>
          ) : null}
          {appointment && appointment?.milestone ? (
            <div className='w-full px-6 my-4'>
              <div className='w-full flex p-4 rounded-md bg-[#E4B91F] space-x-4 items-center
              '>
                <SvgWhiteDiamond width="30px" height="30px" />
                <div className='w-full flex flex-col space-y-4 '>
                  <Paragraph
                    size='b4'
                    color={COLORS.GREY[800]}
                    weight='semiBold'
                    className='flex space-x-1'
                  >
                    <span>{
                      appointment?.milestone?.customerReceivesType === 'percentage'
                        ? `${appointment?.milestone?.customerReceives}%`
                        : `${formatInToPrice(appointment?.milestone?.customerReceives)?.replace(".00", "")}`
                    } - </span>
                    <span>Reward discount in use</span>
                    
                  </Paragraph>
                </div>
              </div>
            </div>
          ) : null}
          {appointment &&
          appointment?.address &&
          appointment?.locationType === 'home' ? (
            <div className='w-full px-6 my-4'>
              <div className='w-full flex p-4 rounded-md bg-grey-75 space-x-4 items-start'>
                <SvgHome width='24px' height='24px' />
                <div className='w-full flex flex-col space-y-4 '>
                  <Paragraph
                    size='b4'
                    color={COLORS.GREY[800]}
                    weight='semiBold'
                  >
                    Home Address: {appointment?.address}
                  </Paragraph>
                </div>
              </div>
            </div>
          ) : null}
          <div className='w-full p-6 space-y-6'>
            <Controller
              control={control}
              name='appointmentType'
              render={({ field: { onChange, value } }) => {
                return (
                  <div className='w-full flex flex-col space-y-4'>
                    <FormLabel htmlFor='appointment-type'>
                      Appointment type
                    </FormLabel>
                    <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                      {Array.isArray(APPOINTMENT_TYPES) &&
                      APPOINTMENT_TYPES.length
                        ? APPOINTMENT_TYPES.map(
                            (appointmentType: {
                              label: string
                              value: string
                            }) => {
                              return (
                                <div
                                  className='flex'
                                  onClick={() =>
                                    onChange(appointmentType?.value)
                                  }
                                  key={appointmentType?.value}
                                >
                                  <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                    <Checkbox
                                      isChecked={
                                        value === appointmentType?.value
                                      }
                                    />
                                    <span className='text-grey-900 whitespace-nowrap'>
                                      {appointmentType?.label}
                                    </span>
                                  </div>
                                </div>
                              )
                            }
                          )
                        : null}
                    </div>
                  </div>
                )
              }}
              rules={{
                required: 'Select where you serve clients'
              }}
            />
            <div className='w-full flex flex-col xl:flex-row gap-4'>
              <div className='w-full xl:w-1/2'>
                <Controller
                  control={control}
                  name='appointmentDate'
                  render={({ field: { value }, formState: { errors } }) => {
                    const errorMessage: string =
                      errors?.appointmentDate?.message
                    return (
                      <div className='flex flex-col space-y-2'>
                        <FormLabel htmlFor='appointment-date'>
                          Appointment Date
                        </FormLabel>
                        <DatePicker
                          className='splice-time-input'
                          value={dayjs(
                            value ||
                              convertFullDateStringToDate(appointment?.startAt),
                            dateFormat
                          )}
                          onChange={onChangeDate}
                          format={dateFormat}
                          disabled={!actions?.rescheduleAppointment}
                        />
                        {errorMessage && (
                          <FormHelperText variant='error'>
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </div>
                    )
                  }}
                  rules={{
                    required: ERRORS.APPOINTMENT_DATE_REQUIRED
                  }}
                />
              </div>
              <div className='w-full xl:w-1/2'>
                <Controller
                  control={control}
                  name='appointmentTime'
                  render={({ field: { value }, formState: { errors } }) => {
                    const errorMessage: string =
                      errors?.appointmentTime?.message
                    return (
                      <div className='flex flex-col space-y-2'>
                        <FormLabel htmlFor='service'>
                          Appointment Time
                        </FormLabel>
                        <TimePicker
                          className='splice-time-input'
                          use12Hours
                          format='h:mm a'
                          value={dayjs(
                            value ||
                              convertFullDateStringToTime(
                                appointment?.startAt as string
                              ),
                            'HH:mm'
                          )}
                          onChange={onChangeTime}
                        />
                        {errorMessage && (
                          <FormHelperText variant='error'>
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </div>
                    )
                  }}
                  rules={{
                    required: 'Add appointment time'
                  }}
                />
              </div>
            </div>
            <CollapseRow title='Service'>
              <div className='w-full flex flex-col xl:flex-row gap-4'>
                <Controller
                  control={control}
                  name='services'
                  render={({
                    field: { onChange, value },
                    formState: { errors }
                  }) => {
                    const errorMessage: string = errors?.services?.message
                    return (
                      <div className='w-full flex flex-col space-y-2'>
                        <FormLabel htmlFor='service'>Add a service</FormLabel>
                        <MultiSelect
                          selected={value || []}
                          options={servicesOptions}
                          setSelected={onChange}
                          disabled={
                            !actions?.modifyAppointmennt || appointment?.appointmentStatus ===
                            APPOINTMENT_STATUS.completed
                          }
                        />
                        {errorMessage && (
                          <FormHelperText variant='error'>
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </div>
                    )
                  }}
                  rules={{
                    required: selectedServices?.length === 0 && 'Add a service'
                  }}
                />
              </div>
            </CollapseRow>
            {(Array.isArray(selectedServices) && selectedServices?.length) ||
            (Array.isArray(selectedProducts) && selectedProducts?.length)
              ? getServiceSummary()
              : null}
            <div className='flex flex-wrap gap-2'>
              {!enableAddOns && appointment?.appointmentStatus !== 'checked_in' || (!enableAddOns && appointment?.appointmentStatus === 'checked_in' && actions?.editPrices) ? (
                <Button
                  variant='light'
                  size='xs'
                  rounded='lg'
                  disabled={['no_show', 'cancelled'].includes(
                    appointment.appointmentStatus
                  )}
                  type='button'
                  className='border border-grey-100'
                  onClick={() => {
                    setEnableAddons(true)
                  }}
                >
                  Add add-on
                  <SvgPlus width='14px' height='14px' />
                </Button>
              ) : null}
              {!enableProductAddition ? (
                <Button
                  variant='light'
                  size='xs'
                  rounded='lg'
                  disabled={['no_show', 'cancelled'].includes(
                    appointment.appointmentStatus
                  )}
                  type='button'
                  className='border border-grey-100'
                  onClick={() => {
                    setEnableProductAddition(true)
                  }}
                >
                  Add product
                  <SvgPlus width='14px' height='14px' />
                </Button>
              ) : null}
              {(!enablePromo && actions?.applyPromo && appointment?.appointmentStatus !== 'checked_in') || (!enablePromo && actions?.applyPromo && appointment?.appointmentStatus === 'checked_in' && actions?.editPrices) ? (
                <Button
                  variant='light'
                  size='xs'
                  rounded='lg'
                  type='button'
                  disabled={['no_show', 'cancelled'].includes(
                    appointment.appointmentStatus
                  )}
                  className='border border-grey-100'
                  onClick={() => {
                    setEnablePromo(true)
                  }}
                >
                  Add promo code
                  <SvgPlus width='14px' height='14px' />
                </Button>
              ) : null}
              {!enableDiscount && !appointment.discountType && actions?.applyDiscounts ? (
                <Button
                  variant='light'
                  size='xs'
                  rounded='lg'
                  type='button'
                  disabled={['no_show', 'cancelled'].includes(
                    appointment.appointmentStatus
                  )}
                  className='border border-grey-100'
                  onClick={() => {
                    setEnableDiscount(true)
                  }}
                >
                  Add discount
                  <SvgPlus width='14px' height='14px' />
                </Button>
              ) : null}
            </div>
            {enableProductAddition ? (
              <Controller
                control={control}
                name='products'
                render={({
                  field: { onChange, value },
                  formState: { errors }
                }) => {
                  const errorMessage: string = errors?.products?.message
                  return (
                    <div className='w-full flex flex-col space-y-2'>
                      <FormLabel htmlFor='service'>Add Product(s)</FormLabel>
                      <MultiSelect
                        selected={value || []}
                        options={productsOptions}
                        setSelected={onChange}
                        disabled={
                          appointment?.appointmentStatus ===
                          APPOINTMENT_STATUS.completed || !actions?.modifyAppointmennt
                        }
                      />
                      {errorMessage && (
                        <FormHelperText variant='error'>
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </div>
                  )
                }}
              />
            ) : null}
            {enableAddOns ? (
              <div className='w-full flex gap-x-4'>
                <div className='w-1/2'>
                  <Input
                    name='addOnReason'
                    disabled={
                      appointment?.appointmentStatus ===
                      APPOINTMENT_STATUS.completed
                    }
                    label='Add-ons'
                    id='addons'
                    type='text'
                    placeholder='Addon reason'
                    control={control}
                    error={errors.addOnReason}
                  />
                </div>
                <Controller
                  control={control}
                  name='addOnAmount'
                  rules={{
                    required: watch('addOnReason')
                      ? ERRORS.ADDON_AMOUNT_REQUIRED
                      : false
                  }}
                  render={({ field }) => (
                    <div className='w-1/2 flex flex-col space-y-2'>
                      <FormLabel htmlFor='adds-on-amount'>
                        Add-ons amount
                      </FormLabel>
                      <input
                        className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                        type='text'
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder='addon amount'
                        disabled={
                          appointment?.appointmentStatus ===
                          APPOINTMENT_STATUS.completed || (appointment?.appointmentStatus ===
                            APPOINTMENT_STATUS.checked_in && !actions?.editPrices)
                        }
                      />
                      {errors?.addOnAmount?.message && (
                        <FormHelperText variant='error'>
                          {errors.addOnAmount.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                />
              </div>
            ) : null}
            {enablePromo ? (
              <div className='w-1/2 pr-2'>
                <Input
                  name='promoCode'
                  disabled={!!appointment?.appointmentPromo?.title || !actions?.applyPromo}
                  label='Enter promo code'
                  id='promoCode'
                  type='text'
                  placeholder='Promo code'
                  control={control}
                  error={errors.promoCode}
                />
              </div>
            ) : null}
            {appointment?.appointmentStatus ===
              APPOINTMENT_STATUS.deposit_pending ||
            appointment?.appointmentStatus ===
              APPOINTMENT_STATUS.deposit_abandoned ? (
              <Controller
                control={control}
                name='isDepositCompleted'
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className='w-full flex flex-col space-y-4'>
                      <FormLabel htmlFor='isDepositCompleted'>
                        How would client like to pay?
                      </FormLabel>
                      <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                        {Array.isArray(YES_OR_NO_SETTINGS) &&
                        YES_OR_NO_SETTINGS.length
                          ? YES_OR_NO_SETTINGS.map(
                              (isDepositCompleted: {
                                label: string
                                value: boolean
                              }) => {
                                return (
                                  <div
                                    className='flex'
                                    onClick={() =>
                                      onChange(isDepositCompleted?.value)
                                    }
                                    key={isDepositCompleted?.label}
                                  >
                                    <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                      <Checkbox
                                        isChecked={
                                          value === isDepositCompleted?.value
                                        }
                                      />
                                      <span className='text-grey-900 whitespace-nowrap'>
                                        {isDepositCompleted?.label?.toLowerCase() ===
                                        'yes'
                                          ? 'Partial payment (deposit)'
                                          : 'Full payment'}
                                      </span>
                                    </div>
                                  </div>
                                )
                              }
                            )
                          : null}
                      </div>
                    </div>
                  )
                }}
              />
            ) : null}
            <Controller
              control={control}
              name='note'
              render={({
                field: { onChange, onBlur, name, ref, value },
                formState: { errors }
              }) => {
                const maxLength = MAXIMUM_NOTE_LENGTH
                const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                  errors?.note?.message as string
                )
                const helperTexts = []

                helperTexts.push(getHelpTextForCharacterLeft(maxLength, value))
                if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts)

                return (
                  <FormTextarea
                    type='text'
                    id='about'
                    label='Note'
                    placeholder='Add a note'
                    {...{
                      ref,
                      name,
                      value,
                      onChange,
                      onBlur,
                      maxLength
                    }}
                    helperText={helperTexts}
                  />
                )
              }}
            />
            {appointment.paymentMethod === 'unpaid' && (
              <Controller
                control={control}
                name='paymentMethod'
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className='w-full flex flex-col space-y-4'>
                      <FormLabel htmlFor='payment-method'>
                        Select {watch('isDepositCompleted') ? ' deposit ' : ''}{' '}
                        payment method
                      </FormLabel>
                      <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                        {Array.isArray(PAYMENT_METHODS) &&
                        PAYMENT_METHODS.length
                          ? PAYMENT_METHODS.map(
                              (paymentMethod: {
                                label: string
                                value: string
                              }) => {
                                return (
                                  <div
                                    className='flex'
                                    onClick={() =>
                                      onChange(paymentMethod?.value)
                                    }
                                    key={paymentMethod?.value}
                                  >
                                    <div className='flex items-center cursor-pointer text-b5 text-grey-300 space-x-2'>
                                      <Checkbox
                                        isChecked={
                                          value === paymentMethod?.value
                                        }
                                      />
                                      <span className='text-grey-300 whitespace-nowrap'>
                                        {paymentMethod?.label}
                                      </span>
                                    </div>
                                  </div>
                                )
                              }
                            )
                          : null}
                      </div>
                    </div>
                  )
                }}
                rules={{
                  required: 'Select where you serve clients'
                }}
              />
            )}
            {watch('paymentMethod') === 'pos' &&
            posTerminals &&
            posTerminals.length ? (
              <SelectInput
                name='paymentMethodId'
                id='transfers'
                label='Point-of-Sale Terminal (POS)'
                rules={{
                  required: ERRORS.POS_REQUIRED
                }}
                control={control}
                error={errors.paymentMethodId}
                options={posTerminalsOptions}
                placeholder='Select Point-of-Sale Terminal'
              />
            ) : null}
            {watch('paymentMethod') === 'bank_transfer' &&
            bankTransfers &&
            bankTransfers.length ? (
              <SelectInput
                name='paymentMethodId'
                id='pos'
                label='Bank'
                control={control}
                rules={{
                  required: ERRORS.TRANSFER_ACCOUNT_REQUIRED
                }}
                error={errors.paymentMethodId}
                options={transfersOptions}
                placeholder='Select Bank'
              />
            ) : null}
          </div>
          <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
            <div className='w-full flex flex-col-reverse md:flex-row gap-2 md:justify-between py-4 px-8'>
              {actions?.modifyAppointmennt ?
                <div className='bg-white py-2 flex'>
                  <Button
                    variant='light'
                    size='md'
                    rounded='lg'
                    className=''
                    fontWeight='semiBold'
                    onClick={
                      appointment?.appointmentStatus !==
                        APPOINTMENT_STATUS.no_show &&
                        appointment?.appointmentStatus !==
                        APPOINTMENT_STATUS.cancelled &&
                        appointment?.appointmentStatus !==
                        APPOINTMENT_STATUS.completed
                        ? enableViewReceiptModalAsync
                        : openReceiptModal
                    }
                    type={
                      appointment?.appointmentStatus !==
                        APPOINTMENT_STATUS.no_show &&
                        appointment?.appointmentStatus !==
                        APPOINTMENT_STATUS.cancelled &&
                        appointment?.appointmentStatus !==
                        APPOINTMENT_STATUS.completed
                        ? 'submit'
                        : 'button'
                    }
                    disabled={
                      updateAppointmentIsLoading || previewAppointmentIsLoading
                    }
                    loading={
                      updateAppointmentIsLoading || previewAppointmentIsLoading
                    }
                  >
                    View Receipt
                  </Button>
                </div> : null}
              <div className='w-full md:w-[60%] py-2 flex space-x-2 justify-start md:justify-end'>
                {actions?.checkInOrOut ? getCheckOutOrCheckInButton() : null}
                {actions?.modifyAppointmennt && appointment?.appointmentStatus !==
                  APPOINTMENT_STATUS.cancelled &&
                appointment?.appointmentStatus !==
                  APPOINTMENT_STATUS.completed ? (
                  <Button
                    variant='secondary'
                    className=''
                    disabled={
                      updateAppointmentIsLoading || previewAppointmentIsLoading
                    }
                    loading={
                      updateAppointmentIsLoading || previewAppointmentIsLoading
                    }
                    size='md'
                    rounded='lg'
                  >
                    Save
                  </Button>
                ) : null}
                {(actions?.cancelAppointment || actions?.deleteAppointment || actions?.markNoShow) && appointment?.appointmentStatus !==
                  APPOINTMENT_STATUS.completed &&
                appointment?.appointmentStatus !== APPOINTMENT_STATUS.no_show &&
                appointment?.appointmentStatus !==
                  APPOINTMENT_STATUS.cancelled ? (
                  <Menu
                    menuButton={
                      <MenuButton className='border border-grey-900 px-[14px] py-2 rounded-md'>
                        <SvgCharmMenuKebab width='24px' height='24px' />
                      </MenuButton>
                    }
                    key='top'
                    direction='top'
                    transition
                  >
                      {actions?.markNoShow ?
                        <MenuItem>
                          <span
                            className='text-b4 cursor-pointer'
                            onClick={markAsNoShow}
                          >
                            Mark as No Show
                          </span>
                        </MenuItem> : null}
                      {actions?.cancelAppointment ?
                        <MenuItem>
                          <span
                            className='text-red-600 text-b4 cursor-pointer'
                            onClick={cancelAppointment}
                          >
                            Cancel Appointment
                          </span>
                        </MenuItem> : null}
                      {actions?.deleteAppointment ?
                        <MenuItem>
                          <span
                            className='text-red-600 text-b4 cursor-pointer'
                            onClick={deleteAppointment}
                          >
                            Delete Appointment
                          </span>
                        </MenuItem> : null}
                  </Menu>
                ) : null}
                {actions?.revertCancellation && appointment.appointmentStatus ===
                  APPOINTMENT_STATUS.cancelled && (
                  <Menu
                    menuButton={
                      <MenuButton className='border border-grey-900 px-[14px] py-2 rounded-md'>
                        <SvgCharmMenuKebab width='24px' height='24px' />
                      </MenuButton>
                    }
                    key='top'
                    direction='top'
                    transition
                  >
                    <MenuItem>
                      <span
                        className='text-b4 cursor-pointer'
                        onClick={revertCancellation}
                      >
                        Undo cancellation
                      </span>
                    </MenuItem>
                  </Menu>
                )}
                {actions?.revertCancellation && appointment.appointmentStatus ===
                  APPOINTMENT_STATUS.no_show && (
                  <Menu
                    menuButton={
                      <MenuButton className='border border-grey-900 px-[14px] py-2 rounded-md'>
                        <SvgCharmMenuKebab width='24px' height='24px' />
                      </MenuButton>
                    }
                    key='top'
                    direction='top'
                    transition
                  >
                    <MenuItem className='w-[150px]'>
                      <span
                        className='text-b4 cursor-pointer'
                        onClick={revertCancellation}
                      >
                        Undo no show
                      </span>
                    </MenuItem>
                  </Menu>
                )}
              </div>
            </div>
          </div>
        </form>
      )
    }

    return null
  }

  const closeAppointmentForRebook = () => {
    closeModal('')
    setHasMadePayment(null)
    resetFormInput()
  }
  return (
    <>
      <Modal
        show={isVisible}
        closeModal={closeAddAppointmentModal}
        variant='right'
      >
        {getAppointmentContent()}
      </Modal>
      <ViewReceiptModal
        isVisible={isReceiptModalVisible}
        closeModal={initiateCloseReceiptModal}
        closeViewReceiptModal={closeAppointmentForRebook}
        appointmentSummary={appointmentSummary}
        hasMadePayment={hasMadePayment}
        refetchCalendarData={refetchCalendarData}
        appointment={updatedAppointment || appointment}
        salonId={salonId as string}
        addToast={addToast}
      />
      <RemoveStaffFromAppointmentAlertModal
        isVisible={isRemoveStaffAssignmentToServiceModalVisible}
        closeModal={initiateCloseStaffAssignmentToServiceModal}
        serviceStaff={currentServiceStaff}
      />
    </>
  )
}

export default ViewAppointmentModal
