import React from 'react'
import { Button, Heading, Paragraph } from '../ui'
import { ActionModalProps } from '../uicomponents/appointmentComponents/types'
import { COLORS } from '../constants/colors'

const CancelPaymentPrompt = ({
  isVisible,
  closeModal,
  isLoading,
  paymentBookingType
}: ActionModalProps) => {
  return (
    isVisible ? (
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[99999]">
        <div className="table-row">
          <div className="table-cell align-middle">
            <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
              <div className="flex flex-col items-center space-y-6">
                <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Are you sure?</Heading>
                <Paragraph size="b5" weight='semiBold' className='w-full text-center max-w-[400px] py-2' color={COLORS.GREY[400]}>
                  If you cancel this payment, you will need to {paymentBookingType === 'appointment' ? 'book a new appointment' : 'make a new payment' } if you wish to proceed. Do you want to proceed?
                </Paragraph>


                <Paragraph size="b6" weight='semiBold' className='w-full text-center max-w-[400px] py-5' color={COLORS.GREY[400]}>
                Please note: If you have completed the payment, kindly wait for the confirmation to appear on your screen.
                </Paragraph>
                
                <Button
                  variant="danger"
                  size='lg'
                  rounded='lg'
                  fontSize='b5'
                  className='capitalize'
                  onClick={() => closeModal('cancel')}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Yes, Cancel Payment
                </Button>
                <Button
                  variant="text"
                  size='none'
                  rounded='none'
                  fontSize='b5'
                  className='mx-0'
                  onClick={() => closeModal('back')}
                  disabled={isLoading}
                >
                  Continue with Payment
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null
  )
}

export default CancelPaymentPrompt
