/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceDayTimeColsView from '@fullcalendar/scrollgrid';
import { CalendarCustomButtonSegmentProps, CalendarProps, ICalendarEventProps } from './types';
import { calendarViewOptions, convertStaffListToCalendarResources, convertStaffToWorkingOptions, getHeadbarTitle, getWorkingStaff } from './utils';
import { Listbox, Menu, Transition } from '@headlessui/react';
import SvgSelectDropDown from '../../icons/SelectDropDown';
import { SvgChevronLeft, SvgChevronRight, SvgDropDown } from '../../icons';
import { SalonStaff } from '../../../graphql/generated';
import { Button, Checkbox, Paragraph } from '../..';
import { DatePicker, DatePickerProps } from 'rsuite';
import { addDays } from 'rsuite/esm/utils/dateUtils';
import { RangeType } from 'rsuite/esm/DatePicker';
import { COLORS } from '../../../constants/colors';

const CustomButton = ({ openAddAppointmentModal, openBlockTimeModal, staffList, handleStaffChange, handleCalendarViewChange, calendarCurrentDate, calendarCurrentEndDate, moveToNext, moveToPrev, jumpToToday, jumpToASpecificDate, actions, showNoShowAndCancelled, setShowNoShowAndCancelled }: CalendarCustomButtonSegmentProps<'addAppointment' | 'addBlockTime'>) => {
  const [selected, setSelected] = React.useState<{
    value: string;
    label: string;
  } | null>({
    label: 'Working',
    value: 'working'
  });
  const [selectedCalendarView, setSelectedCalendarView] = React.useState<{
    value: string;
    label: string;
  } | null>({
    label: 'Day',
    value: 'day'
  });
  const staffOptions = convertStaffToWorkingOptions(staffList)
  const onChange = (option: {
    value: string;
    label: string;
  }) => {
    setSelected(option);
    // if option.value is all pick the the staff
    sortStaffCalendarView(option.value)
  }

  const sortStaffCalendarView = (value: string) => {
    if (value === 'all') {
      handleStaffChange(staffList)
    } else if (value === 'working' && calendarCurrentDate) {
      const workingStaff = getWorkingStaff(calendarCurrentDate, staffList)
      handleStaffChange(workingStaff)
    } else {
      const selectedStaff = staffList.filter(s => s.id === value)
      handleStaffChange(selectedStaff)
    }
  }
  const shortDate = calendarCurrentDate?.toISOString()?.split("T")[0]

  const onChangeCalendarView = (option: {
    value: string;
    label: string;
  }) => {
    setSelectedCalendarView(option);
    // if option.value is all pick the the staff
    const calendarView = option?.value === 'day' ? 'resourceTimeGridDay' : 'timeGridWeek';
    handleCalendarViewChange(calendarView)
  }

  useEffect(() => {
    if (selectedCalendarView?.value === 'day' && selected?.value === 'working' && calendarCurrentDate) {
      sortStaffCalendarView(selected?.value)
    }
  }, [shortDate, staffList])

  const isToday = new Date()?.toISOString()?.split("T")[0] === calendarCurrentDate?.toISOString()?.split("T")[0];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    jumpToASpecificDate(date);
  };

  const predefinedRanges: RangeType<Date>[] = [
    {
      label: 'Yesterday',
      value: addDays(new Date(), -1),
      placement: 'left'
    },
    {
      label: 'Tomorrow',
      value: addDays(new Date(), +1),
      placement: 'left'
    },
    {
      label: 'In 1 week',
      value: addDays(new Date(), +7),
      placement: 'left'
    },
    {
      label: 'In 2 weeks',
      value: addDays(new Date(), +14),
      placement: 'left'
    },
    {
      label: 'In 3 weeks',
      value: addDays(new Date(), +28),
      placement: 'left'
    },
    {
      label: 'In 4 weeks',
      value: addDays(new Date(), +35),
      placement: 'left'
    },
    {
      label: 'In 5 weeks',
      value: addDays(new Date(), +42),
      placement: 'left'
    },
    {
      label: 'Cancel',
      closeOverlay: false,
      value: new Date(),
    }
  ];

  return (
    <div className='w-full flex justify-between items-center h-[59px] 3xl:h-[50px] p-1 md:px-2 xl:px-4'>
      <div className='flex'>
        <Button
          variant='icon'
          size='square'
          type='button'
          className='hidden md:flex w-fit'
          fontSize='b4'
          onClick={moveToPrev}
        ><SvgChevronLeft width="24px" height="24px" /></Button>
        <div className='flex border border-grey-100 shadow-sm rounded-sm'>
          <div className={`py-1 xl:py-2 px-2 xl:px-4 pb rounded-tl-sm rounded-bl-sm ${isToday ? 'bg-grey-50 cursor-pointer' : 'bg-black cursor-pointer'} flex justify-center items-center`} role='button' onClick={jumpToToday}>
            <span className={`${isToday ? 'text-grey-300' : 'text-white'} text-b4 font-semibold`}>Today</span>
          </div>
          <div className='py-1 xl:py-2 px-2 xl:px-6 flex relative bg-grey-25 cursor-pointer justify-center items-center'>
            {getHeadbarTitle(calendarCurrentDate, calendarCurrentEndDate)}
            <div className="cursor-pointer absolute block opacity-0 top-0 w-full">
              <DatePicker
                onChange={onChangeDate}
                ranges={predefinedRanges}
                placeholder="Select date"
                cleanable={false}
                placement='bottomEnd'
                preventOverflow
                className='cursor-pointer'
              />
            </div>
          </div>
        </div>
        <Button
          variant='icon'
          size='square'
          type='button'
          className='hidden md:flex w-fit'
          fontSize='b4'
          onClick={moveToNext}
        ><SvgChevronRight width="24px" height="24px" /></Button>
      </div>
      <div className='h-full flex gap-2 xl:gap-4 items-center'>
        <div className='flex items-center space-x-2 cursor-pointer' onClick={() => setShowNoShowAndCancelled(!showNoShowAndCancelled)}>
          <Checkbox isChecked={showNoShowAndCancelled} />
          <Paragraph size='b6' color={COLORS.GREY[300]}>Show no-show/cancelled</Paragraph>
        </div>
        <div className='w-[60px] md:w-[100px] flex justify-between items-center space-y-2 p-2 bg-white border border-grey-50 rounded-lg'>
          <Listbox value={selectedCalendarView} onChange={onChangeCalendarView}>
            <div className="relative mt-1 w-full">
              <div className="flex flex-col w-full">
                <Listbox.Button
                  className="relative w-full cursor-pointer bg-transparent flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-b6"
                >
                  <div className='flex space-x-2'>
                    <div className='hidden xl:flex flex-col justify-start space-y-1'>
                      <span className='text-grey-400 text-b6'>View</span>
                    </div>
                    <span className='text-grey-900 text-b6'>{selectedCalendarView?.label}</span>
                    <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center'>
                      <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                    </span>
                  </div>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className="absolute max-h-60 w-full min-w-[100px] overflow-auto rounded-sm bg-white py-1 text-b4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-b6 z-[90]"
                    style={{
                      top: 50,
                      bottom: 'auto',
                    }}
                  >
                    {calendarViewOptions.map((view, viewIdx) => (
                      <Listbox.Option
                        key={viewIdx}
                        className={({ active }) =>
                          `relative cursor-pointer select-none p-2 ${
                            active ? 'bg-grey-900 text-white' : 'text-grey-900'
                          }`
                        }
                        value={view}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {view?.label}
                            </span>
                            {selected ? (
                              <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-grey-900'></span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
          </Listbox>
        </div>
        <div className='w-[60px] md:w-[120px] flex justify-between items-center space-y-2 p-2 bg-white border border-grey-50 rounded-lg'>
          <Listbox value={selected} onChange={onChange}>
            <div className="relative mt-1 w-full">
              <div className="flex flex-col w-full">
                <Listbox.Button
                  className="relative w-full cursor-pointer bg-transparent flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-b6"
                >
                  <div className='flex space-x-2'>
                    <div className='hidden xl:flex flex-col justify-start space-y-1'>
                      <span className='text-grey-400 text-b6'>Staff</span>
                    </div>
                    <span className='text-grey-900 text-b6'>{selected?.label}</span>
                    <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center'>
                      <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                    </span>
                  </div>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className="absolute max-h-60 w-full min-w-[100px] overflow-auto rounded-sm bg-white py-1 text-b4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-b6 z-[90]"
                    style={{
                      top: 50,
                      bottom: 'auto',
                    }}
                  >
                    {staffOptions.map((staff, staffIdx) => (
                      <Listbox.Option
                        key={staffIdx}
                        className={({ active }) =>
                          `relative cursor-pointer select-none p-2 ${
                            active ? 'bg-grey-900 text-white' : 'text-grey-900'
                          }`
                        }
                        value={staff}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {staff?.label}
                            </span>
                            {selected ? (
                              <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-grey-900'></span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
          </Listbox>
        </div>
        {actions?.addAppointment || actions?.addBlockTime ?
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-black px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                <span className='hidden xl:inline-block'>Add</span>
                <span className='inline-block xl:hidden'>+</span>
                <SvgSelectDropDown width="10px" height="10px" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]">
                <div className="flex flex-col p-4 space-y-4">
                  {actions?.addAppointment ?
                    <Menu.Item>
                      <span className="text-b4 cursor-pointer" onClick={openAddAppointmentModal}>Add Appointment</span>
                    </Menu.Item> : null}
                  {actions?.addBlockTime ?
                    <Menu.Item>
                      <span className="text-b4 cursor-pointer" onClick={openBlockTimeModal}>Add Block Time</span>
                    </Menu.Item> : null}
                </div>
              </Menu.Items>
            </Transition>
          </Menu> : null}
      </div>
    </div>
  )
}

const Calendar = ({
  openAddAppointmentModal,
  openAppointment,
  events,
  changeCalendarView,
  changeDates,
  staff,
  openBlockTimeModal,
  initiateEditBlockedTimeProcess,
  setCreateAppointmentDetails,
  initiateEditClosedPeriodProcess,
  actions
}: CalendarProps<'addAppointment' | 'viewAppointment' | 'editBlockedTime' | 'editClosedPeriod' | 'addBlockTime'>) => {
  const [selectedStaff, setSelectedStaff] = useState<SalonStaff[]>([])
  const [calendarView, setCalendarView] = useState('resourceTimeGridDay')
  const [calendarCurrentDate, setCalendarCurrentDate] = useState<Date>();
  const [calendarCurrentEndDate, setCalendarCurrentEndDate] = useState<Date>();
  const [showNoShowAndCancelled, setShowNoShowAndCancelled] = useState(false)
  const [formattedEvents, setFormattedEvennts] = useState<ICalendarEventProps[]>([])
  const calendarRef = useRef(null);
  const country = JSON.parse(localStorage.getItem('country') as string);

  // useEffect(() => {
  //   if (staff && staff.length) {
  //     console.log('staff line 352')
  //     setSelectedStaff(staff)
  //   }
  // }, [staff])


  const getFormatedEvents = () => {
    if (!showNoShowAndCancelled) {
      const formattedEventsData = events?.reduce((acc, event) => {
        if (event?.extendedProps?.type === 'appointment' &&
            event?.extendedProps?.status !== 'no_show' &&
            event?.extendedProps?.status !== 'cancelled') {
          acc.push({
            ...event,
          });
        } else if (event?.extendedProps?.type !== 'appointment') {
          acc.push({
            ...event,
          });
        }
        return acc;
      }, []);
      setFormattedEvennts(formattedEventsData)
    } else {
      setFormattedEvennts(events)
    }
  }

  useEffect(() => {
    getFormatedEvents()
  }, [events, showNoShowAndCancelled])

  useEffect(() => {
    if (calendarRef.current) {
      const currentView = calendarRef.current.getApi().view;
      const startDate = currentView.activeStart;
      const endDate = currentView.activeEnd;

      setCalendarCurrentDate(startDate);
      setCalendarCurrentEndDate(endDate);
    }
  }, [calendarRef])

  const handleStaffChange = (staffMembers: SalonStaff[]) => {
    setSelectedStaff(staffMembers)
    setCalendarView('resourceTimeGridDay')
    calendarRef.current.getApi().changeView('resourceTimeGridDay');

    setCalendarStartAndEndDate()
  }
  const handleCalendarViewChange = (view: string) => {
    setCalendarView(view);
    calendarRef.current.getApi().changeView(view);

    setCalendarStartAndEndDate()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEventClick = (clickInfo: any) => {
    const id = clickInfo?.el?.fcSeg?.eventRange?.def?.publicId;
    const appointmentId = clickInfo?.el?.fcSeg?.eventRange?.def?.groupId;
    const type = clickInfo?.el?.fcSeg?.eventRange?.def?.extendedProps?.type;
    if (type === 'appointment' && actions?.viewAppointment) {
      openAppointment(id, appointmentId);
    }

    if (type === 'blocked' && actions?.editBlockedTime) {
      initiateEditBlockedTimeProcess(id);
    }

    if (type === 'closed' && actions?.editClosedPeriod) {
      initiateEditClosedPeriodProcess(id?.split("T")[1]);
    }
  };

  const setCalendarStartAndEndDate = () => {
    // Get the current view object
    const currentView = calendarRef.current.getApi().view;

    // Get the start date of the current view
    const startDate = currentView.activeStart;
    const endDate = currentView.activeEnd;
    setCalendarCurrentDate(startDate);
    setCalendarCurrentEndDate(endDate);
  }

  const addAppointmentEvent = (selectInfo: any) => {
    const { startStr, resource } = selectInfo;

    setCreateAppointmentDetails({ startAt: startStr, staffId: resource?._resource?.id || null, })
    openAddAppointmentModal();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleViewChange = (view: any) => {
    changeCalendarView(view.view.type || null);
    changeDates && changeDates({
      start: view.startStr,
      end: view.endStr,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderEventContent = (eventInfo: any) => {
    return (
      <div className='cursor-pointer w-full'>
        <p className={`text-b6 font-semibold`}>{eventInfo?.event?.title}</p>
        <span className='text-b7'>{eventInfo?.event?.extendedProps?.status} · {eventInfo?.event?.extendedProps?.client}</span>
      </div>
    )
  };

  const moveToNext = () => {
    const calendar = calendarRef.current.getApi();
    calendar.next();

    setCalendarStartAndEndDate()
  }

  const moveToPrev = () => {
    const calendar = calendarRef.current.getApi();
    calendar.prev();

    setCalendarStartAndEndDate()
  }

  const jumpToToday = () => {
    const calendar = calendarRef.current.getApi();
    calendar.today();

    setCalendarStartAndEndDate()
  };

  const jumpToASpecificDate = (date: Date) => {
    const calendar = calendarRef.current.getApi();
    calendar.gotoDate(date);

    setCalendarStartAndEndDate()
  }

  const calendarOptions = {
    schedulerLicenseKey: '0257077607-fcs-1690554734',
    timeZone: country?.timezone,
    resourceOrder: '-type1',
    resources: convertStaffListToCalendarResources(selectedStaff),
    dayMinWidth: 150,
    allDaySlot: false,
    eventOrderStrict: true,
    stickyFooterScrollbar: true,
    initialView: calendarView
  };

  return (
    <div className='w-full flex flex-col'>
      <CustomButton
        openAddAppointmentModal={openAddAppointmentModal}
        openBlockTimeModal={openBlockTimeModal}
        staffList={staff}
        handleStaffChange={handleStaffChange}
        handleCalendarViewChange={handleCalendarViewChange}
        calendarCurrentDate={calendarCurrentDate}
        calendarCurrentEndDate={calendarCurrentEndDate}
        moveToNext={moveToNext}
        moveToPrev={moveToPrev}
        jumpToToday={jumpToToday}
        jumpToASpecificDate={jumpToASpecificDate}
        actions={actions}
        showNoShowAndCancelled={showNoShowAndCancelled}
        setShowNoShowAndCancelled={setShowNoShowAndCancelled}
      />
      <FullCalendar
        ref={calendarRef}
        plugins={[resourceDayTimeColsView, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin]}
        eventDisplay="auto"
        selectable={true}
        select={addAppointmentEvent}
        initialView={calendarView}
        droppable={true}
        eventMaxStack={2}
        events={formattedEvents}
        dayMaxEvents={false}
        slotMinTime='08:00:00'
        slotMaxTime='24:00:00'
        slotDuration='00:30:00'
        eventClick={handleEventClick}
        nowIndicator={true}
        viewDidMount={handleViewChange}
        datesSet={handleViewChange}
        headerToolbar={false}
        dayHeaderFormat={{ weekday: 'short', day: 'numeric' }}
        eventContent={renderEventContent}
        {...calendarOptions}
      />
    </div>
  )
}

export default Calendar