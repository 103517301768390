import { ValueOf } from './types'

export const LOCAL_STORAGE_KEYS = {
  APP_AUTH_INFO: 'splice:auth-info',
  APP_AUTH_TOKEN: 'splice:auth-token',
  APP_SPLICE_USER: 'splice:user',
  APP_SPLICE_USER_DATA: 'splice:userData',
  APP_SPLICE_CUSTOMER: 'splice:customer',
  APP_SPLICE_SALON: 'splice:salon',
  APP_SPLICE_BUSINESS: 'splice:business',
  APP_SPLICE_SKIP_SETUP: 'splice:skip-setup'
}

export const AUTH_FLOWS = {
  REGISTER: 'register',
  LOGIN: 'login',
  FORGOT: 'forgot'
} as const

type SetAppAuthInfoProps = {
  flow: ValueOf<typeof AUTH_FLOWS>
  value: string
}

type SetAppAuthTokenProps = {
  token: string
}

type TimestampProps = {
  timestamp: string
}

export const useAuth = () => {
  const setAppAuthInfo = ({ flow, value }: SetAppAuthInfoProps) => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.APP_AUTH_INFO,
      JSON.stringify({
        flow,
        value,
        timestamp: new Date()
      })
    )
  }

  const getAppAuthInfo = ():
    | (SetAppAuthInfoProps & TimestampProps)
    | undefined => {
    try {
      return JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.APP_AUTH_INFO) as string
      )
    } catch (error) {
      return undefined
    }
  }

  const setAppAuthToken = ({ token }: SetAppAuthTokenProps) => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.APP_AUTH_TOKEN,
      JSON.stringify({
        token,
        timestamp: new Date()
      })
    )
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_AUTH_INFO)
  }

  const getAppAuthToken = ():
    | (SetAppAuthTokenProps & TimestampProps)
    | undefined => {
    try {
      return JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.APP_AUTH_TOKEN) as string
      )
    } catch (error) {
      return undefined
    }
  }

  const logoutCustomer = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_CUSTOMER)
  }

  const logout = () => {
    // clear cache
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_AUTH_INFO)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_AUTH_TOKEN)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_USER)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_USER_DATA)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_SALON)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_BUSINESS)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_SKIP_SETUP)
  }

  return {
    setAppAuthInfo,
    getAppAuthInfo,
    setAppAuthToken,
    getAppAuthToken,
    logoutCustomer,
    logout
  }
}
