/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { BOOKING_PAGE_TAB_NAME, INITIAL_STEP, PACKAGE_ONLY_IN_CART_ERROR, PHONE_FORM_STEP, PURCHASE_SUCCESSFUL_STEP, SERVICE_ONLY_IN_CART_ERROR, TIME_AND_DATE, USER_DETAILS_STEP, VOUCHER_SERVICE_SELECTION, STAFF_SELECTION } from '../uicomponents/bookingSales/constants';
import { useToast } from '../hooks/useToast';
import { AppointmentPreview, Business, ClientMilestone, Faq, Package, PackageVoucher, Salon, Service, VoucherPreview } from '../graphql/generated';
import { BookingPersonalInformationFormInput, BookingService, CartTypeEnum, GroupedServices, PromoApplicationProps, PurchaseSalonPackageDataProps } from '../uicomponents/bookingSales/types';
import { formatBookingPackages, formatServicesCategory } from '../uicomponents/bookingSales/utils';
import Button from '../ui/atoms/button/Button';
import { Logo } from '../ui/atoms/logo';
import SvgArrowBack from '../ui/icons/ArrowBack';
import SvgChevronRight from '../ui/icons/ChevronRight';
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../ui/atoms/toast/types';
import BookContext from '../uicomponents/bookingSales/context';
import BookingPageShimmer from '../uicomponents/bookingSales/BookingPageShimmer';
import { BusinessInfo } from '../uicomponents/bookingSales/BusinessInfo';
import CheckoutPhoneFormInput from '../uicomponents/bookingSales/CheckoutPhoneFormInput';
import CheckoutUserDetails from '../uicomponents/bookingSales/CheckoutUserDetails';
import CheckoutDateTime from '../uicomponents/bookingSales/CheckoutDateTime';
import PurchaseSuccessful from '../uicomponents/bookingSales/PurchaseSuccessful';
import { fetchBusinessByUrl, fetchBusinessFaq } from '../uicomponents/bookingSales/api';
import CheckoutStaffSelection from '../uicomponents/bookingSales/CheckoutStaffSelection';
import { BookingPackages, BookingVoucherServices } from '../modals/types';
import FullStory from 'react-fullstory';
import SelectVoucherService from '../uicomponents/bookingSales/VoucherServiceSelection';
import { COLORS } from '../constants/colors';
import { BookingSiteSearch, Heading, Paragraph } from '../ui';
import FaqRow from '../uicomponents/bookingSales/FaqRow';
import { useMobileSideMenu } from '../hooks/useMobileSideMenu';
import { SvgArrowRight, SvgClose, SvgFaqQuestion, SvgFluentSearch20Regular, SvgLocationPin, SvgMenu, SvgWhiteShoppingBag } from '../ui/icons';
import { Listbox, Transition } from '@headlessui/react';


const BOOK_PAGES = [
  {
    title: 'Business Information',
    component: BusinessInfo,
    step: INITIAL_STEP
  },
  {
    title: 'Select staff',
    component: CheckoutStaffSelection,
    step: STAFF_SELECTION
  },
  {
    title: 'Select Voucher Service',
    component: SelectVoucherService,
    step: VOUCHER_SERVICE_SELECTION
  },
  {
    title: 'Enter Phone',
    component: CheckoutPhoneFormInput,
    step: PHONE_FORM_STEP
  },
  {
    title: 'Checkout User Details',
    component: CheckoutUserDetails,
    step: USER_DETAILS_STEP
  },
  {
    title: 'Checkout Date Time',
    component: CheckoutDateTime,
    step: TIME_AND_DATE
  },
  {
    title: 'Purchase Successful',
    component: PurchaseSuccessful,
    step: PURCHASE_SUCCESSFUL_STEP
  }
]

const Booking = () => {
  // @ts-expect-error Key already defined
  const GOOGLE_MAPS_API_KEY = envVariables.googleMapsApiKey
  const [step, setStep] = useState(INITIAL_STEP)
  const [activeTab, setActiveTab] = useState(
    BOOKING_PAGE_TAB_NAME.SERVICES as string
  )
  const [selectedSalon, setSelectedSalon] = useState<Salon | null>(null)
  const [appointmentDateTime, setAppointmentDateTime] = useState<string | null>(null)
  const [priceSummary, setPriceSummary] = useState<AppointmentPreview | null>(null)
  const [packagesPriceSummary, setPackagesPriceSummary] = useState<VoucherPreview | null>(null)
  const [voucherToBeRedeemed, setVoucherToBeRedeemed] = useState<PackageVoucher | null>(null)
  const [promoCodeApplication, setPromoCodeApplication] = useState<PromoApplicationProps | null>(null);
  const [businessInfo, setBusinessInfo] = useState<Business | null>(null);
  const [showInitialLoadingShimmer, setShowInitialLoadingShimmer] = useState(false);
  const [salons, setSalons] = useState<Salon[]>([]);
  const { toast, addToast } = useToast();
  const [services, setServices] = useState<Service[]>([]);
  const [packages, setPackages] = useState<Package[]>([]);
  const [rewards, setRewards] = useState<ClientMilestone[]>([])
  const [selectedReward, setSelectedReward] = useState<ClientMilestone>(null)
  const [formattedServices, setFormattedServices] = useState<GroupedServices[]>([]);
  const [formattedPackages, setFormattedPackages] = useState<BookingPackages>([]);
  const [selectedServices, setSelectedServices] = useState<BookingService[]>([]);
  const [selectedPackages, setSelectedPackages] = useState<BookingPackages>([]);
  const [serviceClient, setServiceClient] = useState<BookingPersonalInformationFormInput | null>(null);
  const [openVoucherRedemptionModal, setOpenVoucherRedemptionModal] = useState(false);
  const [bookedAppointment, setBookedAppointment] = useState<any | null>(null);
  const [voucherServices, setVoucherServices] = useState<BookingVoucherServices>([])
  const [voucherPurchasedData, setVoucherPurchasedData] = useState<PurchaseSalonPackageDataProps | null>(null)
  const [appointmentPaymentType, setAppointmentPaymentType] = useState<string | null>(null)
  const [loadingServices, setLoadingServices] = useState(true)
  const [staffOption, setStaffOption] = useState<string | null>(null)
  const [faqs, setFaqs] = useState<Faq[]>([])
  const { isMobileSideMenuOpen, toggleMobileSideMenu } = useMobileSideMenu();
  const [searchWrapperIsOpen, setSearchWrapperIsOpen] = useState(false);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [searchCount, setSearchCount] = useState<number>(0)
  const [rewardSelected, setRewardSelected] = useState(false)
  const [width] = useState<number>(window.innerWidth)
  const isMobile = width <= 1000;
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(100);

  // Get the current URL
  const currentUrl = window.location.href

  // Split the URL using '/' as the delimiter and get the last part
  const parts = currentUrl.split('/')
  const bookingUrlIdentifier = parts[parts.length - 1].split('?')[0]

  const getBusinessInfo = async () => {
    setShowInitialLoadingShimmer(true)
    await fetchBusinessByUrl({ bookingUrlIdentifier }, setBusinessInfo)
    setShowInitialLoadingShimmer(false)
  }

  useEffect(() => {
    if (bookingUrlIdentifier) {
      getBusinessInfo()
    }
  }, [bookingUrlIdentifier])

  useEffect(() => {
    if (businessInfo && businessInfo?.salons?.length) {
      setSelectedSalon(businessInfo?.salons[0])
      setSalons(businessInfo?.salons)
    }
  }, [businessInfo])

  const getApiData = async () => {
    const getSalon = businessInfo?.salons?.find(
      (salon) => salon.id === selectedSalon?.id
    )
    if (!getSalon) return
    await setServices(getSalon?.services)
    await setFormattedServices(formatServicesCategory(getSalon?.services))
    await setPackages(getSalon?.packages?.filter((salonPackage) => salonPackage?.allowSaleOn !== 'salon'))
    setSelectedPackages([])
    setSelectedServices([])
    setLoadingServices(false)
  }

  const getFaqsAsync = async () => {
    await fetchBusinessFaq({ salonId: selectedSalon?.id }, setFaqs)
  }

  useEffect(() => {
    if (selectedSalon) {
      getApiData()
      getFaqsAsync()
    }
  }, [selectedSalon])

  useEffect(() => {
    if (Array?.isArray(services) && services?.length) {
      setFormattedServices(formatServicesCategory(services))
    }
  }, [services])

  useEffect(() => {
    if (Array?.isArray(packages) && packages?.length) {
      setFormattedPackages(formatBookingPackages(packages))
    }
  }, [packages])

  useEffect(() => {
    // Get the height of the header after the component mounts
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [businessInfo, headerRef])

  const showOrHideCategory = (categoryName: string) => {
    const newFormattedServices = formattedServices?.map((category) => {
      if (category?.name === categoryName) {
        return {
          ...category,
          show: !category.show
        }
      }
      return category
    })
    setFormattedServices(newFormattedServices)
  }

  const addOrRemoveService = useCallback((service: Service) => {
    setSelectedServices((prevSelectedServices) => {
      const isServiceSelected = prevSelectedServices.some(
        (selectedService) => selectedService.id === service?.id
      )
      if (isServiceSelected) {
        return prevSelectedServices.filter(
          (selectedService) => selectedService.id !== service.id
        )
      } else {
        return [...prevSelectedServices, service]
      }
    })
  }, [])

  const clearServicesInCart = () => {
    setSelectedServices([])
    if (services) {
      setFormattedServices(formatServicesCategory(services))
    }
  }

  const deleteService = (serviceId: string) => {
    setSelectedServices((prevSelectedServices) =>
      prevSelectedServices.filter((service) => service.id !== serviceId)
    )
  }

  const selectService = (serviceId: string) => {
    if (Array.isArray(selectedPackages) && selectedPackages?.length > 0) {
      addToast({
        message: SERVICE_ONLY_IN_CART_ERROR,
        variant: 'error'
      })
      return
    }
    const newServices = formattedServices.map((category) => {
      return {
        ...category,
        services: category.services.map((service) => {
          if (service.id === serviceId) {
            // add or remove service from selectedServices
            addOrRemoveService(service)
            return {
              ...service,
              selected: !service.selected
            }
          }
          return service
        })
      }
    })
    setFormattedServices(newServices)
  }

  const modifyPackageInCart = (packageId: string, action: CartTypeEnum) => {
    if (
      Array.isArray(selectedServices) &&
      selectedServices?.length > 0 &&
      action !== 'clear'
    ) {
      addToast({
        message: PACKAGE_ONLY_IN_CART_ERROR,
        variant: 'error'
      })
      return
    }
    // if action is remove, remove package from cart
    if (action === 'remove') {
      // remove package from selectedPackages
      const existPackageItem = selectedPackages.find(
        (packageItem) => packageItem.id === packageId
      )
      if (!existPackageItem) {
        return
      }

      if (existPackageItem?.quantity > 1) {
        // decrease quantity if package exist
        setSelectedPackages(
          selectedPackages.map((packageItem) => {
            if (packageItem.id === packageId) {
              return {
                ...packageItem,
                quantity: packageItem.quantity - 1
              }
            }
            return packageItem
          })
        )
      } else {
        setSelectedPackages(
          selectedPackages.filter((packageItem) => packageItem.id !== packageId)
        )
      }
      addToast({
        message: `${existPackageItem?.name} has been successfully removed from cart`,
        variant: 'success'
      })
    } else if (action === 'add') {
      // check if package already exist
      const packageExist = selectedPackages.find(
        (packageItem) => packageItem.id === packageId
      )
      // increase quantity if package exist
      if (packageExist) {
        setSelectedPackages(
          selectedPackages.map((packageItem) => {
            if (packageItem.id === packageId) {
              return {
                ...packageItem,
                quantity: packageItem.quantity + 1
              }
            }
            return packageItem
          })
        )

        // increased quantity quantity by 1 toast
        addToast({
          message: `${packageExist?.name} quantity has been successfully increased in cart`,
          variant: 'success'
        })
      } else {
        // add package to cart, but check if selectedPackages is an array and have content, before add to cart
        const packageItem = formattedPackages.find(
          (packageItem) => packageItem.id === packageId
        )

        if (!packageItem) {
          return
        }

        // add newPackageItem to selectedPackages
        setSelectedPackages((prevSalonPackages) => {
          if (
            selectedPackages &&
            Array.isArray(prevSalonPackages) &&
            selectedPackages?.length > 0
          ) {
            return [
              {
                ...packageItem,
                quantity: 1
              },
              ...prevSalonPackages
            ]
          } else {
            // If it's not an array or undefined, initialize it as an array
            return [
              {
                ...packageItem,
                quantity: 1
              }
            ]
          }
        })
        addToast({
          message: `${packageItem?.name} has been successfully added to cart`,
          variant: 'success'
        })
      }
    } else if (action === 'clear') {
      setSelectedPackages([])
    }
  }

  const modifyServiceInCart = (serviceId: string, action: CartTypeEnum) => {
    if (
      Array.isArray(selectedPackages) &&
      selectedPackages?.length > 0 &&
      action !== 'clear'
    ) {
      addToast({
        message: SERVICE_ONLY_IN_CART_ERROR,
        variant: 'error'
      })
      return
    }
    // if action is remove, remove service from cart
    if (action === 'remove') {
      // remove service from selectedServices
      const existServiceItem = selectedServices.find(
        (serviceItem) => serviceItem.id === serviceId
      )
      if (!existServiceItem) {
        return
      }

      if (existServiceItem?.quantity > 1) {
        // decrease quantity if package exist
        setSelectedServices(
          selectedServices.map((serviceItem) => {
            if (serviceItem.id === serviceId) {
              return {
                ...serviceItem,
                quantity: serviceItem.quantity - 1
              }
            }
            return serviceItem
          })
        )
      } else {
        setSelectedServices(
          selectedServices.filter((serviceItem) => serviceItem.id !== serviceId)
        )
      }
      addToast({
        message: `${existServiceItem?.name} has been successfully removed from cart`,
        variant: 'success'
      })
    } else if (action === 'add') {
      // check if service already exist
      const serviceExist = selectedServices.find(
        (serviceItem) => serviceItem.id === serviceId
      )
      // increase quantity if service exist
      if (serviceExist) {
        setSelectedServices(
          selectedServices.map((serviceItem) => {
            if (serviceItem.id === serviceId) {
              return {
                ...serviceItem,
                quantity: serviceItem.quantity + 1
              }
            }
            return serviceItem
          })
        )

        // increased quantity quantity by 1 toast
        addToast({
          message: `${serviceExist?.name} quantity has been successfully increased in cart`,
          variant: 'success'
        })
      } else {
        // add package to cart, but check if selectedPackages is an array and have content, before add to cart
        const serviceItem = selectedServices.find(
          (serviceItem) => serviceItem.id === serviceId
        )

        if (!serviceItem) {
          return
        }

        // add newServiceItem to selectedServices
        setSelectedServices((prevServices) => {
          if (
            selectedServices &&
            Array.isArray(prevServices) &&
            selectedServices?.length > 0
          ) {
            return [
              {
                ...serviceItem,
                quantity: 1
              },
              ...prevServices
            ]
          } else {
            // If it's not an array or undefined, initialize it as an array
            return [
              {
                ...serviceItem,
                quantity: 1
              }
            ]
          }
        })
        addToast({
          message: `${serviceItem?.name} has been successfully added to cart`,
          variant: 'success'
        })
      }
    } else if (action === 'clear') {
      setSelectedPackages([])
    }
  }

  const handleNextStep = (page?: number) => {
    scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (page) {
      return setStep(page)
    }
    const newPage = step + 1;
    setStep(newPage);
  }

  // @ts-expect-error Env variables has been set in the application.html.erb
  const ORG_ID = envVariables.fullstoryOrgId

  const mobileAction = (action: string) => {
    if (action === 'faq') {
      toggleMobileSideMenu()
      goToFaqsSection()
    } else if (action === 'cart') {
      if (
        (Array.isArray(selectedServices) && selectedServices?.length) ||
        (Array.isArray(selectedPackages) && selectedPackages?.length)
      ) {
        toggleMobileSideMenu()
        handleNextStep(
          Array.isArray(selectedServices) && selectedServices?.length
            ? businessInfo?.allowServiceStaffSelection
              ? STAFF_SELECTION
              : TIME_AND_DATE
            : USER_DETAILS_STEP
        )
      }
    }
  }

  const goToCartBagSection = () => {
    // use react scroller
    scrollTo({
      top: document.getElementById('cart-bag-section')?.offsetTop,
      behavior: 'smooth'
    })
  }

  const openSearchWrapper = (box?: boolean) => {
    setSearchWrapperIsOpen(true)
    setSuggestionBox(box)
  }

  useEffect(() => {
    if (debouncedSearchQuery?.length && Array?.isArray(services) && services?.length) {
      // filter services
      const filteredServices = services?.filter(service => {
        // Convert both name and search term to lowercase for case-insensitive search
        const serviceName = service.name.toLowerCase();
        
        // Check if the name contains the search term
        return serviceName.includes(debouncedSearchQuery.toLowerCase());
      });

      setSuggestionBox(false);
      setSearchCount(filteredServices?.length)
      setFormattedServices(formatServicesCategory(filteredServices, true));
    } else if (debouncedSearchQuery?.length === 0) {
      setSearchCount(0)
      setFormattedServices(formatServicesCategory(services));
    }
  }, [debouncedSearchQuery])

  const pickSearchSuggestion = (name: string) => {
    setDebouncedSearchQuery(name);
    setSuggestionBox(false);
  }

  const getThreeRandomServices = () => {
    const shuffled = services?.sort(() => 0.5 - Math.random());
    const shuffledServices = shuffled?.slice(0, 3);

    return <div className='flex flex-col space-y-3'>
      {Array?.isArray(shuffledServices) && shuffledServices?.length ? shuffledServices?.map((serv) => {
        return (
          <div className='flex space-x-3' onClick={() => pickSearchSuggestion(serv?.name)}>
            <SvgFluentSearch20Regular width="18px" height="18px" />
            <Paragraph size='b6' color={COLORS.GREY['900']}>
              {serv?.name}
            </Paragraph>
          </div>
        )
      }) : null}
    </div>
  }

  const closeSearch = () => {
    setDebouncedSearchQuery('')
    if (Array?.isArray(services) && services?.length) {
      setFormattedServices(formatServicesCategory(services));
    }
    setSearchWrapperIsOpen(false)
  }

  const values = {
    step,
    services,
    handleNextStep,
    formattedServices,
    formattedPackages,
    setFormattedServices,
    showOrHideCategory,
    selectService,
    selectedServices,
    setSelectedServices,
    selectedPackages,
    modifyPackageInCart,
    modifyServiceInCart,
    businessInfo,
    salons,
    selectedSalon,
    setSelectedSalon,
    appointmentDateTime,
    setAppointmentDateTime,
    addToast,
    serviceClient,
    setServiceClient,
    activeTab,
    setActiveTab,
    setPriceSummary,
    priceSummary,
    clearServicesInCart,
    voucherToBeRedeemed,
    setVoucherToBeRedeemed,
    openVoucherRedemptionModal,
    setOpenVoucherRedemptionModal,
    bookedAppointment,
    setBookedAppointment,
    voucherPurchasedData,
    setVoucherPurchasedData,
    promoCodeApplication,
    setPromoCodeApplication,
    packagesPriceSummary,
    setPackagesPriceSummary,
    appointmentPaymentType,
    setAppointmentPaymentType,
    voucherServices,
    setVoucherServices,
    loadingServices,
    staffOption,
    setStaffOption,
    rewards,
    setRewards,
    selectedReward,
    setSelectedReward,
    debouncedSearchQuery,
    searchCount,
    closeSearch,
    openSearchWrapper,
    setDebouncedSearchQuery,
    searchWrapperIsOpen,
    rewardSelected,
    setRewardSelected,
    faqs,
    deleteService,
    isMobile
  }

  const goToBookingInitialPage = () => {
    setAppointmentDateTime(null);
    setVoucherToBeRedeemed(null);
    setPromoCodeApplication(null);
    setVoucherServices([])
    setServiceClient(null)
    clearServicesInCart();
    modifyPackageInCart('', "clear");
    setAppointmentPaymentType(null)
    handleNextStep(INITIAL_STEP);
    setPackagesPriceSummary(null)
    setBookedAppointment(null)
    setVoucherPurchasedData(null)
    setPriceSummary(null)
    setRewards([])
    setSelectedReward(null)
    setRewardSelected(false)
  }

  const processStepBack = () => {
    if (step === TIME_AND_DATE) {
      if (businessInfo?.allowServiceStaffSelection) {
        handleNextStep(STAFF_SELECTION);
        return;
      }
      if (voucherToBeRedeemed) {
        handleNextStep(VOUCHER_SERVICE_SELECTION);
        return;
      }

      if (Array.isArray(selectedServices) && selectedServices?.length) {
        handleNextStep(INITIAL_STEP);
        return;
      }
    }

    if (step === PHONE_FORM_STEP) {
      if (Array.isArray(selectedServices) && selectedServices?.length) {
        handleNextStep(TIME_AND_DATE);
        return;
      }
    }

    if (step === USER_DETAILS_STEP) {
      if (Array.isArray(selectedServices) && selectedServices?.length) {
        handleNextStep(PHONE_FORM_STEP);
        return;
      }

      if (voucherToBeRedeemed) {
        handleNextStep(TIME_AND_DATE);
        return;
      }

      if (Array.isArray(selectedPackages) && selectedPackages?.length) {
        handleNextStep(INITIAL_STEP);
        return;
      }
    }

    if (step === STAFF_SELECTION) {
      if (Array.isArray(selectedServices) && selectedServices?.length) {
        handleNextStep(INITIAL_STEP);
        return;
      }

      if (voucherToBeRedeemed) {
        handleNextStep(VOUCHER_SERVICE_SELECTION);
        return;
      }
    }

    // scroll to the top
    scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const goToFaqsSection = () => {
    // use react scroller
    scrollTo({
      top: document.getElementById('faq-section')?.offsetTop,
      behavior: 'smooth',
    });
  }

  return (
    <>
      <FullStory org={ORG_ID} />
      <header ref={headerRef} className='w-full fixed flex min-h-auto justify-between bg-white z-30 items-start lg:items-center px-4 py-4 lg:px-[80px] border-b top-0 border-grey-20'>
        <div className='flex flex-col gap-1'>
          <div className='flex'>
            {businessInfo?.logoUrl ? (
              <img
                src={businessInfo?.logoUrl}
                alt='business logo'
                className='w-full max-w-[80px]'
                loading='lazy'
              />
            ) : (
              <Logo className='max-w-[80px]'/>
            )}
          </div>
          {/* select location */}
          <div className='flex lg:hidden justify-between items-center space-y-2'>
            <Listbox value={selectedSalon} onChange={setSelectedSalon}>
              <div className="relative mt-1 w-full">
                <div className="flex flex-col w-full">
                  <Listbox.Button
                    className="relative w-full cursor-pointer bg-transparent flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-b6"
                  >
                    <div className='flex space-x-2 items-center'>
                      <span className="text-left">{
                        selectedSalon?.branchName || 'Select'
                      }</span>
                      {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center"> */}
                        <Button
                          variant='text'
                          size='none'
                          fontWeight='semiBold'
                          className='text-green-700 border-b border-green-700 text-b6'
                        >Change</Button>
                      {/* </div> */}
                    </div>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      className="absolute max-h-60 w-full min-w-[100px] overflow-auto rounded-sm z-50 bg-white py-1 text-b4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-b6"
                      style={{
                        top: 50,
                        bottom: 'auto',
                      }}
                    >
                      {salons?.map((salon, salonIdx) => (
                        <Listbox.Option
                          key={`salon${salonIdx}`}
                          className={({ active }) =>
                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-grey-900 text-white' : 'text-grey-900'
                            }`
                          }
                          value={salon}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                  }`}
                              >
                                {salon?.branchName}
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </div>
            </Listbox>
          </div>
        </div>
        <div className='flex space-x-4 h-fit'>
          {!searchWrapperIsOpen ? 
            <Button
              variant="icon"
              size="square"
              rounded="none"
              type="button"
              className="border-0"
              onClick={() => openSearchWrapper(true)}
            >
              <SvgFluentSearch20Regular width="24px" height="24px" />
            </Button>
            : (
              <div className='flex flex-col relative'>
                <BookingSiteSearch setDebouncedSearchQuery={setDebouncedSearchQuery} />

                {suggestionBox ? (
                  <div className='absolute top-[60px] min-w-[200px] h-[150px] rounded-sm shadow-md flex flex-col space-y-3 p-3 bg-white'>
                    <Paragraph size='b6' color={COLORS.GREY['300']}>
                      Search suggestions
                    </Paragraph>
                    {getThreeRandomServices()}
                  </div>
                ) : null}
              </div>
            )}
          <div className='hidden lg:flex space-x-4'>
            <Button
              variant='text'
              className='text-grey-300'
              size='sm'
              rounded='none'
              fontWeight='semiBold'
              onClick={goToCartBagSection}
            >
              My cart ({step === PURCHASE_SUCCESSFUL_STEP ? 0 : selectedServices?.length ? selectedServices?.length : selectedPackages?.length ? selectedPackages?.length : 0})
            </Button>
            {faqs && faqs?.length ?
              <Button
                variant='text'
                className='text-grey-300'
                size='sm'
                rounded='none'
                fontWeight='semiBold'
                onClick={goToFaqsSection}
              >
                FAQs
              </Button> : null}
            {step === PURCHASE_SUCCESSFUL_STEP ? (
              <Button
                variant='secondary'
                className=''
                size='sm'
                rounded='lg'
                onClick={goToBookingInitialPage}
              >
                <SvgArrowBack width="24px" height="24px" /> <span>Go home</span>
              </Button>
            ) : step > INITIAL_STEP ? (
              <Button
                variant='secondary'
                className=''
                size='sm'
                rounded='lg'
                onClick={processStepBack}
              >
                <SvgArrowBack width="24px" height="24px" /> <span>Back</span>
              </Button>
            ) : formattedPackages.length > 0 ? (
              <Button
                variant='light'
                className=''
                size='sm'
                rounded='md'
                onClick={() => setOpenVoucherRedemptionModal(true)}
              >
                <span>Redeem your voucher </span> <SvgChevronRight width="24px" height="24px" />
              </Button>
            ) : null}
          </div>
          <div className='flex lg:hidden'>
            <Button
              variant="icon"
              size="square"
              rounded="md"
              type="button"
              className="flex lg:hidden"
              onClick={toggleMobileSideMenu}
            >
              <SvgMenu width="24px" height="24px" />
            </Button>
          </div>
        </div>
      </header>
      <main className={`w-full flex flex-col`} style={{
        marginTop: headerHeight
      }}>
        <ToastWrapper toast={toast as ToastProps} />
        {!showInitialLoadingShimmer ? (
          <BookContext.Provider value={values}>
            {BOOK_PAGES.map((page, index) => {
              const Node = page.component
              return <div key={index}>{page.step === step && <Node />}</div>
            })}
          </BookContext.Provider>
        ) : (
          <BookingPageShimmer />
        )}
        {Array?.isArray(faqs) && faqs?.length && step === INITIAL_STEP ? (
          <div id="faq-section" className='w-full bg-grey-50 flex flex-col items-center justify-center px-[10px] lg:px-0 py-[60px] gap-[40px] mt-[50px]'>
            <div className='w-full max-w-[80%] flex flex-col justify-center items-center'>
              <Heading
                size='h8'
                variant='h2'
                weight='bold'
                color={COLORS.GREY['900']}
              >
                Frequently Asked Questions (FAQs)
              </Heading>
              <Paragraph size='b4' color={COLORS.GREY['300']}>
                Get meticulously curated responses to frequently asked questions.
              </Paragraph>
            </div>

            <div className='bg-white rounded-xl shadow-lg w-full max-w-[680px] min-h-[200px] px-[10px] lg:px-[20px] xl:px-[40px] py-[10px]'>
              <div className='w-full px-[5px] lg:px-[10px] xl:px-[20px] py-[5px] lg:py-[10px] xl:py-[24px]'>
                {Array?.isArray(faqs) && faqs?.length
                  ? faqs?.map((faq) => {
                    return <FaqRow key={faq?.id} faq={faq} />
                  })
                  : null}
              </div>
            </div>
            {step > INITIAL_STEP ? (
              <div className='w-full flex lg:hidden fixed bottom-[20px] h-[60px] px-[20px]'>
                <Button
                  variant='primary'
                  className=''
                  size='lg'
                  rounded='lg'
                  onClick={processStepBack}
                >
                  <div className='w-full flex justify-left items-center px-4 py-2'>
                    <SvgArrowBack color='#fff' width='24px' height='24px' />{' '}
                    <span>Back</span>
                  </div>
                </Button>
              </div>
            ) : null}
          </div>
        ) : null}

        {selectedSalon?.address && step === INITIAL_STEP ? (
          <div
            id='google-map-section'
            className='w-full flex-col items-center px-4 xl:px-10 my-[20px]'
          >
            <div className='flex flex-col lg:flex-row justify-between px-4 xl:px-10'>
              <div>
                <Paragraph size='h7'>Get directions here</Paragraph>
                <Paragraph className='flex items-center'><SvgLocationPin width='15' height='15' /> {selectedSalon?.address}</Paragraph>
              </div>
              <div className='w-full xl:w-[1024px]'>
                <iframe
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}
                    &q=${selectedSalon?.address.replace(/ /g, '+')}`}>
                </iframe>
              </div>
            </div>
          </div>
        ) : null}
      </main>
      {isMobileSideMenuOpen ? (
        <div className='w-full h-full fixed top-0 bg-black/70 backdrop-blur-[2px] z-40 no-doc-scroll'>
          <div className='w-[80%] h-full bg-white flex flex-col space-y-6'>
            <div className='w-full flex justify-between px-4 py-4 h-[85px] xl:px-[80px] border-b border-grey-20'>
              {businessInfo?.logoUrl ? (
                <img
                  src={businessInfo?.logoUrl}
                  alt='business logo'
                  className='w-full max-w-[80px]'
                  loading='lazy'
                />
              ) : (
                <Logo className='max-w-[80px]' />
              )}

              <div className='flex lg:hidden'>
                <Button
                  variant='icon'
                  size='square'
                  rounded='md'
                  type='button'
                  className='flex lg:hidden'
                  onClick={toggleMobileSideMenu}
                >
                  {isMobileSideMenuOpen ? (
                    <SvgClose width='24px' height='24px' />
                  ) : (
                    <SvgMenu width='24px' height='24px' />
                  )}
                </Button>
              </div>
            </div>
            <ul className='w-full flex flex-col gap-[30px] px-3 py-5'>
              <li
                className='w-full flex justify-between items-center cursor-pointer text-grey-400'
                onClick={() => mobileAction('cart')}
              >
                <div className='flex gap-2 items-center text-grey-400'>
                  <SvgWhiteShoppingBag width='24px' height='24px' />
                  <Paragraph
                    size='b4'
                    weight='semiBold'
                    color={COLORS.GREY[400]}
                  >
                    View cart (
                    {selectedServices?.length
                      ? selectedServices?.length
                      : selectedPackages?.length
                      ? selectedPackages?.length
                      : 0}
                    )
                  </Paragraph>
                </div>
                <SvgArrowRight width='16px' height='16px' />
              </li>
              {faqs && faqs?.length ?
                <li
                  className='w-full flex justify-between items-center cursor-pointer text-grey-400'
                  onClick={() => mobileAction('faq')}
                >
                  <div className='flex gap-2 items-center text-grey-400'>
                    <SvgFaqQuestion width='24px' height='24px' />
                    <Paragraph
                      size='b4'
                      weight='semiBold'
                      color={COLORS.GREY[400]}
                    >
                      FAQs
                    </Paragraph>
                  </div>
                  <SvgArrowRight width='16px' height='16px' />
                </li> : null}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Booking
