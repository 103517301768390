import * as React from "react";
import { FormPhoneProps } from "./types";
import useHelperText from "../../atoms/helperText/useHelperText";
import FormLabel from "../../atoms/formLabel/Label";
import  CountrySelect  from "../../atoms/countrySelect/CountrySelect";
import { FormHelperTextList } from "../../atoms/helperText/FormHelperTextList";
// import { CountrySelect, FormHelperTextList, FormLabel, useHelperText } from "../../";

const FormPhone = (
  {
    country: { disabled: countrySelectDisabled, onSelect, ...countryProps },
    phone: { name, rules, register, label, id, ...props },
    className,
    helperText,
    inputMode = "tel",
  }: FormPhoneProps,
  ref: React.Ref<HTMLInputElement>
) => {
  const { helperTexts, borderColorClassName } = useHelperText({ helperText });

  return (
    <div className='w-full flex flex-col space-y-2'>
      <FormLabel htmlFor={id}>
        {label}
      </FormLabel>
      <div className="flex w-full">
        <CountrySelect
          {...{
            onSelect,
            className: borderColorClassName,
            disabled: countrySelectDisabled,
            ...countryProps,
          }}
        />
        <input
          className={`w-full border border-grey-100 px-4 py-3 font-normal text-b6 xl:text-b5 text-grey-900 rounded-md rounded-tl-none rounded-bl-none focus:outline-none placeholder:text-grey-400 ${
            className || ""
          }`}
          {...{
            ref,
            id,
            name,
            inputMode,
            ...props,
          }}
          {...(register && register(name, rules))}
        />
      </div>
      <FormHelperTextList helperTexts={helperTexts} />
    </div>
  );
};

export default React.forwardRef(FormPhone);
