import React from 'react'
import { COLORS } from '../constants/colors'
import { DashboardProps } from './types'
import SLogo from '../ui/icons/SLogo'
import Heading from '../ui/atoms/heading/Heading'
import Paragraph from '../ui/atoms/paragraph/Paragraph'

const WelcomeMessageTemplate: React.FunctionComponent<DashboardProps> = (props: DashboardProps) => {
  return (
    <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px]">
      <div className="table-row">
        <div className="table-cell align-middle">
          <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
            <div className="flex flex-col items-center space-y-6">
              <SLogo width="100%" height="100%" className="w-[32px]" />
              <Heading variant='h1' size='h4' weight='bold' color={COLORS.BLACK}>Welcome!</Heading>
              <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                Welcome to Splice! The all-in-one software is designed to simplify and streamline your business so you can grow your revenue and delight customers. Let’s get you started.
              </Paragraph>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeMessageTemplate