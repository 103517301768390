import React, { useState } from 'react'
import { Button, Heading, Layout, Paragraph, Tabs, ToastWrapper } from '../ui'
import { useToast } from '../hooks'
import { COLORS } from '../constants/colors'
import { SvgCopy, SvgFacebook, SvgInstagram, SvgTiktok } from '../ui/icons'
import { copyTextToClipboard } from '../utils/misc'
import FrontChat from '../ui/atoms/frontChat/FrontChat'
import SvgLinkedin from '../ui/icons/Linkedin'
import SvgLink from '../ui/icons/Link'

const Support = () => {
  const SUPPORT_TABS = [{
      key: 'contact',
      title: 'Support',
      show: true
    }
  ]

  const [activeTab, setActiveTab] = useState<string>(SUPPORT_TABS.filter((tab) => tab?.show)[0]?.key)

  const getCurrentTabView = () => {
    switch (activeTab) {
      case 'contact':
        return <ContactUs />
      default:
        break
    }
  }

  return (
    <Layout pageTitle='Support' pageLevel={2}>
      <Tabs
        tabs={SUPPORT_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {getCurrentTabView()}
    </Layout>
  )
}

export const ContactUs = () => {
  const { addToast, toast } = useToast();
  const [showLiveChat, setShowLiveChat] = useState(false)

  const openHelpCentre = () => {
    window.open('https://help.withsplice.com', '_blank')
  }

  const copyBookingLink = (link: string) => {
    copyTextToClipboard(link)
    addToast({
      variant: 'success',
      message: 'Copied to clipboard'
    })
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {showLiveChat ? <FrontChat /> : null}
      <div className='w-full flex flex-col xl:flex-row space-y-6 xl:space-y-0 p-0'>

        {/* <div className='w-full border-l border-grey-100 flex flex-col shadow-sm'>
          {getTabHeaderView()} */}
        <div className='w-full grid grid-cols-1 lg:grid-cols-3 gap-4 h-full px-10 py-6'>
          <div className='w-full border border-grey-100 hover:border-green-300 hover:shadow-md rounded-md flex p-4 flex-col space-y-2 cursor-pointer' role='link' onClick={openHelpCentre}>
            <Paragraph size='b4'>Help center</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[400]} weight='normal'>
              We're here to assist you with any questions or concerns.
            </Paragraph>
            <SvgLink width="32px" height="32px" />
          </div>
          <div className='w-full border border-grey-100 hover:border-green-300 hover:shadow-md rounded-md flex p-4 flex-col space-y-2 cursor-pointer' role='link' onClick={() => setShowLiveChat(true)}>
            <Paragraph size='b4'>Live chat</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[400]} weight='normal'>
              We're here to assist you with any questions or concerns.
            </Paragraph>
            <SvgLink width="32px" height="32px" />
          </div>
          <div className='w-full border border-grey-100 hover:border-green-300 hover:shadow-md rounded-md flex p-4 flex-col space-y-2'>
            <Paragraph size='b4'>Phone</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[400]} weight='normal'>
              Place a call through to us
            </Paragraph>
            <div className='flex items-center space-x-3'>
              <a className='text-grey-900 hover:text-grey-900' href="tel:2348019201923">
                <Heading variant='h1' size='h8' weight='semiBold'>+2348019201923</Heading>
              </a>
              <Button
                variant='icon'
                size='none'
                rounded='lg'
                className="w-[32px] h-[32px] flex justify-center items-center bg-grey-100/20 text-grey-300 border-0"
                onClick={() => copyBookingLink('2348019201923')}
              >
                <SvgCopy width="16px" height="16px" />
              </Button>
            </div>
          </div>

          <div className='w-full border border-grey-100 hover:border-green-300 hover:shadow-md rounded-md flex p-4 flex-col space-y-2'>
            <Paragraph size='b4'>Email</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[400]} weight='normal'>
              Send us an email
            </Paragraph>
            <div className='flex items-center space-x-3'>
              <a className='text-grey-900 hover:text-grey-900' href="mailto:support@withsplice.com">
                <Heading variant='h1' size='h8' weight='semiBold'>support@withsplice.com</Heading>
              </a>
              <Button
                variant='icon'
                size='none'
                rounded='lg'
                className="w-[32px] h-[32px] flex justify-center items-center bg-grey-100/20 text-grey-300 border-0"
                onClick={() => copyBookingLink('support@withsplice.com')}
              >
                <SvgCopy width="16px" height="16px" />
              </Button>
            </div>
          </div>

          <div className='w-full border border-grey-100 hover:border-green-300 hover:shadow-md rounded-md flex p-4 flex-col space-y-3'>
            <Paragraph size='b4'>Social media</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[400]} weight='normal'>
              Follow us on:
            </Paragraph>
            <div className='flex items-center space-x-3 px-3'>
              <a className='text-grey-900 flex justify-center items-center space-x-4 hover:text-grey-900' href="https://www.instagram.com/withsplice" target='_new'>
                <SvgInstagram width="32px" height="32px" />
              </a>
              <a className='text-grey-900 flex justify-center items-center space-x-4 hover:text-grey-900' href="https://www.facebook.com/withsplice" target='_new'>
                <SvgFacebook width="32px" height="32px" />
              </a>
              <a className='text-grey-900 flex justify-center items-center space-x-4 hover:text-grey-900' href="https://www.linkedin.com/company/withsplice" target='_new'>
                <SvgLinkedin width="32px" height="32px" />
              </a>
              <a className='text-grey-900 flex justify-center items-center space-x-4 hover:text-grey-900' href="https://www.tiktok.com/@withsplice" target='_new'>
                <SvgTiktok width="32px" height="32px" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Support
