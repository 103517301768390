import React, { createContext, useContext, useState } from 'react'
import {
  CreatePromoDocument,
  CreatePromoInput,
  DeletePromoDocument,
  Promo,
  PromosDocument
} from '../graphql/generated'
import axios from 'axios'
import { print } from 'graphql'
import { useToast } from '../hooks'
import { ToastProps } from '../ui'

const PromoContext = createContext(undefined)

const PromoProvider = ({ children }) => {
  const token = localStorage.getItem('token')
  const [promos, setPromos] = useState<Promo[]>([])
  const [topPerformingPromo, setTopPerformingPromo] = useState<Promo>()
  const { toast, addToast } = useToast()

  const getPromos = async (q = '') => {
    axios
      .post(
        '/graphql',
        { query: print(PromosDocument), variables: { q} },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { promos: {promos, topPerformingPromo} }
          }
        } = res
        setPromos(promos)
        setTopPerformingPromo(topPerformingPromo)
      })
      .catch((err) => {
        addToast({message: err.message, variant: 'error'})
      })
  }

  const deletePromo = async (id: string, handleClose: () => void) => {
    axios
      .post(
        '/graphql',
        {
          query: print(DeletePromoDocument),
          variables: { input: { promoId: id } }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((res) => {
        addToast({ variant: 'success', message: 'Promo Deleted Successfully' })
        getPromos('')
        handleClose()
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  const createPromo = async (input: CreatePromoInput, handleClose: () => void, _addToast: (toast: ToastProps) => void) => {
    try {
      const {
        data: {
          data: { createPromo: cp }
        }
      } = await axios.post(
        '/graphql',
        { query: print(CreatePromoDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (cp.status === 201) {
        await getPromos('')
        _addToast({ variant: 'success', message: 'Promo saved successfully' })
        handleClose()
      } else {
        _addToast({ variant: 'error', message: cp.errors[0].message })
      }
    } catch (error) {
      _addToast({ variant: 'error', message: error.message })
    }
  }

  return (
    <PromoContext.Provider
      value={{ promos, topPerformingPromo, getPromos, toast, addToast, deletePromo, createPromo }}
    >
      {children}
    </PromoContext.Provider>
  )
}

// Promo Context custom hook
export const usePromoContext = () => {
  const context = useContext(PromoContext)
  if (!context) {
    throw new Error('usePromoContext must be used with a PromoProvider')
  }

  return context
}

export default PromoProvider
