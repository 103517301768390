import * as React from "react";
import BasePill from "./BasePill";
import { BasePillProps } from "./types";
import { APPOINTMENT_COLORS } from "../../../constants/colors";

const CustomPill = ({ className, style, children, appointmentStatus }: BasePillProps) => {
  const getCustomBg = () => {
    switch (appointmentStatus) {
      case "confirmed":
        return APPOINTMENT_COLORS?.CONFIRMED;
      case "cancelled":
        return APPOINTMENT_COLORS?.CANCELLED;
      case "deposit_pending":
        return APPOINTMENT_COLORS?.CANCELLED;
      case "pending":
        return APPOINTMENT_COLORS?.PENDING;
      case "no_show":
        return APPOINTMENT_COLORS?.NO_SHOW;
      case "completed":
        return APPOINTMENT_COLORS?.DONE;
      case "checked_in":
        return APPOINTMENT_COLORS?.CHECKED_IN
    }
  };

  const getCustomTextColor = () => {
    switch (appointmentStatus) {
      case "confirmed":
        return APPOINTMENT_COLORS?.CONFIRMED_TEXT;
      case "cancelled":
        return APPOINTMENT_COLORS?.CANCELLED_TEXT;
      case "deposit_pending":
        return APPOINTMENT_COLORS?.CANCELLED_TEXT;
      case "pending":
        return APPOINTMENT_COLORS?.PENDING_TEXT;
      case "no_show":
        return APPOINTMENT_COLORS?.NO_SHOW_TEXT;
      case "completed":
        return APPOINTMENT_COLORS?.DONE_TEXT;
      case "checked_in":
        return APPOINTMENT_COLORS?.CHECKED_IN_TEXT;
    }
  };

  console.log({
    appointmentStatus,
    color: getCustomTextColor(),
    an: getCustomBg()
  })

  // Render
  return (
    <BasePill
      style={{
        color: getCustomTextColor(),
        backgroundColor: getCustomBg(),
        ...style
      }}
      className={`flex text-center  ${className || ""}`}
    >
      {children}
    </BasePill>
  );
};

export default CustomPill;
