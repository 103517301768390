import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';
// import { useSalonCache } from '../hooks/useSalonCache';
import { BankInput } from '../uicomponents/types';
import { API_ERRORS, ERRORS } from '../constants/errors';
import { Modal } from '../ui/templates/modal/Modal';
import { REGEX_PATTERNS } from '../constants/pattern';
import Input from '../ui/molecules/input/Input';
import SelectInput from '../ui/molecules/input/SelectInput';
import Button from '../ui/atoms/button/Button';
import { MoneyModalProps } from './types';
import { useSalonCache } from '../hooks/useSalonCache';
import { BanksDocument, CreateTransferAccountDocument, DeleteTransferAccountDocument, UpdateTransferAccountDocument } from '../graphql/generated';
import { print } from 'graphql';

const AddOrEditTransferAccount = (props: MoneyModalProps) => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const [isLoading, setIsLoading] = useState(false);
  const [deleteTransferAccountIsLoading, setDeleteTransferAccountIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<BankInput>({});
  
  const [bankListOptions, setBankListOptions] = useState<{
    value: string;
    label: string;
  }[]>([])

  const getBanks = async () => {
    try {
      await axios.post(
        '/graphql',
        { query: print(BanksDocument) },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        const banks = data?.data?.banks;
        const list = Array.isArray(banks)
          ? banks.map((bank) => ({
            value: bank.code,
            label: bank.name,
          }))
          : [];
        setBankListOptions(list);
      })
    } catch (getBanksError) {
      const message = getBanksError?.response?.data?.message || API_ERRORS.BANK_ACCOUNT_LIST_FAILED;
      props?.addToast({
        variant: "error",
        message,
      });
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  const onSubmitData = async (input: BankInput) => {
    setIsLoading(true)
    try {
      // update or create bank account transfer
      if (!props.transferInfo) {
        await axios.post(
          '/graphql',
          {
            query: print(CreateTransferAccountDocument),
            variables: { input: { accountNumber: input?.accountNumber, bankCode: input?.bankCode, salonId } },
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        ).then(({ data }) => {
          setIsLoading(false)
          if (data?.data?.createTransferAccount?.status === 201) {
            closeBankModal()
            props?.addToast && props.addToast({
              message: 'Account created successfully',
              variant: 'success',
            })
          }

          if (data?.errors?.length) {
            const message = data?.errors[0]?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
            props?.addToast({
              variant: 'error',
              message,
            })
          }
    
    
          if (data?.data?.createTransferAccount?.errors?.length) {
            const message = data?.data?.createTransferAccount?.errors[0]?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
            props?.addToast({
              variant: 'error',
              message,
            })
          }
        })
      } else {
        await axios.post(
          '/graphql',
          {
            query: print(UpdateTransferAccountDocument),
            variables: { input: { id: props?.transferInfo?.id, accountNumber: input?.accountNumber, bankCode: input?.bankCode, salonId } },
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        ).then(({ data }) => {
          setIsLoading(false)
          if (data?.data?.updateTransferAccount?.status === 200) {
            closeBankModal()
            props?.addToast && props.addToast({
              message: 'Account updated successfully',
              variant: 'success',
            })
          }

          if (data?.errors?.length) {
            const message = data?.errors[0]?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
            props?.addToast({
              variant: 'error',
              message,
            })
          }
    
    
          if (data?.data?.updateTransferAccount?.errors?.length) {
            const message = data?.data?.updateTransferAccount?.errors[0]?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
            props?.addToast({
              variant: 'error',
              message,
            })
          }
        })
      }
    } catch (createBusinessTransferError) {
      if (axios.isAxiosError(createBusinessTransferError)) {
        const message = createBusinessTransferError?.response?.data?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
        props?.addToast && props.addToast({
          variant: "error",
          message,
        });
      }
    }
  };

  const closeBankModal = () => {
    props.refetch && props.refetch();
    props.closeModal();
    resetFormInput();
  }

  const resetFormInput = () => {
    setValue('accountNumber', '');
    setValue('bankCode', '');
  }

  useEffect(() => {
    if (props.transferInfo) {
      setValue('accountNumber', props.transferInfo.accountNumber)
      setValue('bankCode', props.transferInfo.bankCode)
    }
  }, [props.transferInfo, setValue])

  const deleteTransferAccount = async () => {
    try {
      setDeleteTransferAccountIsLoading(true);
      await axios.post(
        '/graphql',
        {
          query: print(DeleteTransferAccountDocument),
          variables: { input: { id: props.transferInfo?.id, salonId } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        setDeleteTransferAccountIsLoading(false)
        if (data?.data?.deleteTransferAccount?.status === 200) {
          props?.addToast && props.addToast({
            message: 'Transfer Account deleted successfully',
            variant: 'success',
          })
          closeBankModal();
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.SALON_BANK_ACCOUNT_DELETION_FAILED;
          props?.addToast({
            variant: 'error',
            message,
          })
        }
  
  
        if (data?.data?.deleteTransferAccount?.errors?.length) {
          const message = data?.data?.deleteTransferAccount?.errors[0]?.message || API_ERRORS.SALON_BANK_ACCOUNT_DELETION_FAILED;
          props?.addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (deletePosError) {
      if (axios.isAxiosError(deletePosError)) {
        setDeleteTransferAccountIsLoading(false)
        const message = deletePosError?.response?.data?.message || API_ERRORS.SALON_BANK_ACCOUNT_DELETION_FAILED;
        props?.addToast && props.addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  return (
    <Modal
      title={props?.expense ? 'Edit Bank Account' : 'Add Bank Account'}
      show={props.isVisible}
      closeModal={!props?.expense ? props.closeModal : () => closeBankModal()}
    >
      <form onSubmit={handleSubmit(onSubmitData)} className="w-full space-y-6 pt-6">
        <Input name="accountNumber" id="account-number" label="Account Number" type="text" placeholder='Enter account number here' control={control} rules={{
          required: ERRORS.ACCOUNT_NUMBER_REQUIRED,
          pattern: REGEX_PATTERNS.ACCOUNT_NUMBER
        }} error={errors.accountNumber} />
        <SelectInput name="bankCode" id="bank-name" label="Bank Name " control={control} rules={{
          required: ERRORS.BANK_NAME_REQUIRED,
        }} error={errors.bankCode} options={bankListOptions} placeholder="Select Bank" />

        <Button
          variant='primary'
          className=''
          disabled={isLoading}
          loading={isLoading}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
        <Button
          variant='text'
          className={`mx-auto ${props?.transferInfo && 'text-red-500'}`}
          disabled={deleteTransferAccountIsLoading}
          loading={deleteTransferAccountIsLoading}
          size='none'
          type='button'
          onClick={props?.transferInfo ? deleteTransferAccount : props.closeModal}
        >
          {props?.transferInfo ? 'Delete' : 'Cancel'}
        </Button>
      </form>
    </Modal>
  )
}

export default AddOrEditTransferAccount