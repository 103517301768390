import { ChangeEvent, useState } from 'react';
import { getImageSize } from 'react-image-size';
import { FILE_SIZE } from '../constants/information';
// import { ISignCloudinaryMediaAssetUploadProps } from './types';
import axios from 'axios';
import { API_ERRORS } from '../constants/errors';
import { UploadFileDocument } from '../graphql/generated';
import { print } from 'graphql'

export const useImageUploader = (dimensions: { width: number, height: number }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadIsLoading, setUploadingIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target) {
      return;
    }
    const imageFiles = e.target.files;
    if (imageFiles) {
        const file = imageFiles[0];
        // check the file size
        if (file.size > FILE_SIZE) {
          const message = `Image size should not exceed 2MB`;
          setErrorMessage(message);
        }
        const image = new Image();
        image.src = URL.createObjectURL(file);
        const imageDimensions = await getImageSize(image.src);

        if (imageDimensions.width > dimensions.width || imageDimensions.height > dimensions.height) {
          const message = `Image dimensions should not exceed ${dimensions.width}px by ${dimensions.height}px`;
          setErrorMessage(message);
          return;
        }

        setUploadingIsLoading(true);

        const fileContentString = await readFileAsString(file);

        await axios.post(
          '/graphql',
          {
            query: print(UploadFileDocument),
            variables: { input: { data: fileContentString as string } },
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        ).then(({ data }) => {
          if (data?.data?.uploadFile?.status === 200) {
            setImageUrl(data.data?.uploadFile?.url);
            setUploadingIsLoading(false);
            setErrorMessage(null);
          }

          if (data?.data?.uploadFile?.errors) {
            setErrorMessage(data.data.uploadFile?.errors[0]?.message || API_ERRORS.IMAGE_UPLOAD_FAILED);
            setUploadingIsLoading(false);
          }
      }).catch (error => {
        if (axios.isAxiosError(error)) {
          const message =
            error?.response?.data?.message || API_ERRORS.IMAGE_UPLOAD_FAILED;
          setErrorMessage(message);
        }
        setUploadingIsLoading(false);
      })
    }
  };

  if (errorMessage) {
    setInterval(() => {
      setErrorMessage(null)
    }, 5000);
  }

  return {
    imageUrl,
    errorMessage,
    handleImageUpload,
    uploadIsLoading,
  };
};

export const useFileUploader_ = async (data) => {
  const token = localStorage.getItem('token')
  const {
    data: {
      data: { uploadFile }
    }
  } = await axios.post(
    '/graphql',
    { query: print(UploadFileDocument), variables: { input: { data } } },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return uploadFile
}

export const useFileUploader = () => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [uploadIsLoading, setUploadingIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target) {
      return;
    }
    const imageFiles = e.target.files;
    if (imageFiles) {
      const file = imageFiles[0];
      setFileName(file?.name)
      setUploadingIsLoading(true);

      const fileContentString = await readFileAsString(file);

      await axios.post(
        '/graphql',
        {
          query: print(UploadFileDocument),
          variables: { input: { data: fileContentString as string } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        if (data?.data?.uploadFile?.status === 200) {
          setFileUrl(data.data?.uploadFile?.url);
          setUploadingIsLoading(false);
          setErrorMessage(null);
        }

        if (data?.data?.uploadFile?.errors) {
          setErrorMessage(data.data.uploadFile?.errors[0]?.message || API_ERRORS.IMAGE_UPLOAD_FAILED);
          setUploadingIsLoading(false);
        }
      }).catch(error => {
        if (axios.isAxiosError(error)) {
          const message =
            error?.response?.data?.message || API_ERRORS.IMAGE_UPLOAD_FAILED;
          setErrorMessage(message);
        }
        setUploadingIsLoading(false);
      })
    }
  };

  if (errorMessage) {
    setInterval(() => {
      setErrorMessage(null)
    }, 5000);
  }

  return {
    fileUrl,
    fileName,
    errorMessage,
    handleFileUpload,
    uploadIsLoading,
  };
};

const readFileAsString = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
})
