import * as React from "react";
import { LabelProps } from "./types";

// lazy load heading components
const Base = React.lazy(() => import("./Base"));

const Label = (props: LabelProps) => {
  return (
    <React.Suspense fallback={<label>...</label>}>
      <Base {...props} />
    </React.Suspense>
  );
};

export default Label;
