import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSortIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4.28 1.249a1 1 0 0 1 1.44 0L7.7 3.307C8.313 3.942 7.863 5 6.98 5H3.02c-.882 0-1.332-1.058-.72-1.693l1.98-2.058ZM5.72 13.751a1 1 0 0 1-1.44 0L2.3 11.693C1.687 11.058 2.137 10 3.02 10h3.96c.882 0 1.332 1.058.72 1.693l-1.98 2.058Z"
      fill="#9AA2A5"
    />
  </svg>
);
export default SvgSortIcon;
