import React, { createContext, useContext, useState } from 'react'
import { useSalonCache } from '../hooks/useSalonCache'
import axios from 'axios'
import { print } from 'graphql'
import {
  CreatePackageDocument,
  CreatePackageInput,
  DeletePackageDocument,
  DeletePackageInput,
  Package,
  PackagesDocument
} from '../graphql/generated'
import { useToast } from '../hooks'

const PackageContext = createContext(undefined)

const PackageProvider = ({ children }) => {
  const token = localStorage.getItem('token')
  const [packages, setPackages] = useState<Package[]>([])
  const { toast, addToast } = useToast()
  const { getSalonFieldValue } = useSalonCache()

  const getPackages = (q?: string) => {
    axios
      .post(
        '/graphql',
        {
          query: print(PackagesDocument),
          variables: { salonId: getSalonFieldValue('id'), q }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { packages: p }
          }
        } = res
        setPackages(p)
      })
      .catch((err) => {
        addToast({ variant: 'error', message: err.message })
      })
  }

  const createPackage = async (input: CreatePackageInput, closeModal: () => void, reset: () => void) => {
    try {
      const {
        data: {
          data: { createPackage: cp }
        }
      } = await axios.post(
        '/graphql',
        { query: print(CreatePackageDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (cp.status === 200) {
        getPackages()
        addToast({ message: 'Voucher saved successfully', variant: 'success' })
        closeModal()
        reset()
      } else {
        addToast({ message: cp.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const deletePackage = async (input: DeletePackageInput) => {
    try {
      const {
        data: {
          data: { deletePackage: dp }
        }
      } = await axios.post(
        '/graphql',
        { query: print(DeletePackageDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (dp.status === 200) {
        await getPackages()
        addToast({
          message: 'Voucher deleted successfully',
          variant: 'success'
        })
      } else {
        addToast({ message: dp.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  return (
    <PackageContext.Provider
      value={{ toast, packages, getPackages, createPackage, deletePackage }}
    >
      {children}
    </PackageContext.Provider>
  )
}

// Package Context custom hook
export const usePackageContext = () => {
  const context = useContext(PackageContext)
  if (!context) {
    throw new Error('usePackageContext must be used with a PackageProvider')
  }
  return context
}

export default PackageProvider
