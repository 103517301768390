/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Appointment, AppointmentPreview, Business, ClientMilestone, Faq, PackageVoucher, Salon, Service, VoucherPreview } from '../../graphql/generated';
import { BookingPersonalInformationFormInput, BookingService, CartTypeEnum, GroupedServices, PromoApplicationProps, PurchaseSalonPackageDataProps } from './types';
import { ToastProps } from '../../ui/atoms/toast/types';
import { BookingPackages, BookingVoucherServices } from '../../modals/types';

type BookContextType = {
  step: number;
  services: Service[];
  handleNextStep: (step?: number) => void;
  formattedServices: GroupedServices[];
  formattedPackages: BookingPackages;
  selectedPackages: BookingPackages;
  voucherServices: BookingVoucherServices;
  setVoucherServices: (voucherServices: BookingVoucherServices) => void;
  setFormattedServices: (formattedServices: GroupedServices[]) => void;
  showOrHideCategory: (category: string) => void;
  selectService: (serviceId: string) => void;
  modifyPackageInCart: (salonPackageId: string, action: CartTypeEnum) => void;
  selectedServices: BookingService[];
  setSelectedServices: (services: BookingService[]) => void;
  modifyServiceInCart: (serviceId: string, action: CartTypeEnum) => void;
  businessInfo: Business;
  salons: Salon[] | undefined;
  selectedSalon: Salon | null;
  setSelectedSalon: (salon: Salon) => void;
  appointmentDateTime: string | null;
  setAppointmentDateTime: (date: string | null) => void;
  addToast: (toast: ToastProps) => void;
  serviceClient: BookingPersonalInformationFormInput | null;
  setServiceClient: (client: BookingPersonalInformationFormInput | null) => void;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  priceSummary: AppointmentPreview | null;
  setPriceSummary: (summary: AppointmentPreview) => void;
  clearServicesInCart: () => void;
  voucherToBeRedeemed: PackageVoucher | null;
  setVoucherToBeRedeemed: (voucher: PackageVoucher | null) => void;
  openVoucherRedemptionModal: boolean;
  setOpenVoucherRedemptionModal: (open: boolean) => void;
  bookedAppointment: Appointment | null;
  setBookedAppointment: (appointment: Appointment) => void;
  voucherPurchasedData: PurchaseSalonPackageDataProps | null;
  setVoucherPurchasedData: (voucher: PurchaseSalonPackageDataProps | null) => void;
  promoCodeApplication: PromoApplicationProps | null;
  setPromoCodeApplication: (promo: PromoApplicationProps | null) => void;
  packagesPriceSummary : VoucherPreview | null;
  setPackagesPriceSummary: (summary: VoucherPreview | null) => void;
  appointmentPaymentType: string | null;
  setAppointmentPaymentType: (paymentType: string) => void;
  loadingServices: boolean;
  staffOption: string | null;
  setStaffOption: (option: string | null) => void;
  rewards: ClientMilestone[],
  setRewards: (rewards: ClientMilestone[]) => void;
  selectedReward: ClientMilestone,
  setSelectedReward: (reward: ClientMilestone) => void;
  debouncedSearchQuery: string;
  searchCount: number;
  closeSearch: () => void;
  openSearchWrapper: (box?: boolean) => void;
  setDebouncedSearchQuery: (query: string) => void;
  searchWrapperIsOpen: boolean;
  setRewardSelected: (reward: boolean) => void;
  rewardSelected: boolean;
  faqs: Faq[];
  deleteService: (serviceId: string) => void;
  isMobile: boolean;
};

const BookContext = React.createContext<BookContextType>({
  step: 1,
  services: [],
  handleNextStep: () => {
    console.log();
  },
  formattedServices: [],
  formattedPackages: [],
  selectedPackages: [],
  setFormattedServices: () => {
    console.log();
  },
  voucherServices: [],
  setVoucherServices: () => {
    console.log();
  },
  showOrHideCategory: () => {
    console.log();
  },
  selectService: () => {
    console.log();
  },
  modifyPackageInCart: () => {
    console.log();
  },
  modifyServiceInCart: () => {
    console.log();
  },
  selectedServices: [],
  setSelectedServices: () => {
    console.log();
  },
  businessInfo: {} as Business,
  salons: [],
  selectedSalon: null,
  setSelectedSalon: () => {
    console.log();
  },
  appointmentDateTime: null,
  setAppointmentDateTime: () => {
    console.log();
  },
  addToast: () => {
    console.log();
  },
  serviceClient: null,
  setServiceClient: () => {
    console.log();
  },
  activeTab: '',
  setActiveTab: () => {
    console.log();
  },
  priceSummary: null,
  setPriceSummary: () => {
    console.log();
  },
  clearServicesInCart: () => {
    console.log();
  },
  voucherToBeRedeemed: null,
  setVoucherToBeRedeemed: () => {
    console.log();
  },
  openVoucherRedemptionModal: false,
  setOpenVoucherRedemptionModal: () => {
    console.log();
  },
  bookedAppointment: null,
  setBookedAppointment: () => {
    console.log()
  },
  voucherPurchasedData: null,
  setVoucherPurchasedData: () => {
    console.log()
  },
  promoCodeApplication: null,
  setPromoCodeApplication: () => {
    console.log()
  },
  packagesPriceSummary: null,
  setPackagesPriceSummary: () => {
    console.log()
  },
  appointmentPaymentType: null,
  setAppointmentPaymentType: () => {
    console.log()
  },
  loadingServices: false,
  staffOption: null,
  setStaffOption: () => {
    console.log()
  },
  rewards: [],
  setRewards: () => {
    console.log()
  },
  selectedReward: null,
  setSelectedReward: () => {
    console.log()
  },
  debouncedSearchQuery: '',
  searchCount: 0,
  closeSearch: () => {
    console.log()
  },
  openSearchWrapper: () => {
    console.log()
  },
  setDebouncedSearchQuery: () => {
    console.log()
  },
  searchWrapperIsOpen: false,
  setRewardSelected: () => {
    console.log()
  },
  rewardSelected: false,
  faqs: [],
  deleteService: () => {
    console.log()
  },
  isMobile: false,
});

export default BookContext;