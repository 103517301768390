import React, { useEffect, useState } from 'react'
import { PaymentModalProps } from '../uicomponents/bookingSales/types'
import Button from '../ui/atoms/button/Button'
import { COLORS } from '../constants/colors'
import { Heading, ToastProps, ToastWrapper } from '../ui'
import axios from 'axios'
import { print } from 'graphql'
import ActionCable from "actioncable";
import { DeleteAppointmentDocument } from '../graphql/generated'
import { useModal, useToast } from '../hooks'
import CancelPaymentPrompt from './CancelPaymentPrompt'

const PaymentModal = (props: PaymentModalProps) => {
	// @ts-expect-error Env variables has been set in the application.html.erb
	const webSocketUrl = envVariables.webSocketUrl;
  const { toast, addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false)
  const {
    isVisible: isCancelPaymemtModal,
    closeModal: closeCancelPaymentModal,
    openModal: openCancelPaymentModal
  } = useModal()

  const processCancellation = () => {
    if (props?.propertyType === 'appointment') {
      deleteAppointmentAsync()
      return;
    }

    props?.closeModal()
  }

  useEffect(() => {
		if (props?.paymentAuthorization && props?.isVisible) {
			const cable = ActionCable.createConsumer(webSocketUrl);
			cable.subscriptions.create(
				{ channel: "PaymentChannel", salon_id: props?.salonId },
				{
					connected() {
						console.log("Connected to Action Cable");
					},
					received: (data) => {
						// handle notification here
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
            const notifcation = data.notification as any;
            console.log({
              notifcation
            })
						// Add the new message to the notifications state
            if (notifcation && notifcation?.payment_reference === props?.paymentAuthorization?.reference) {
              props?.confirmPayment()
						}
					},
				}
			);
		}
	}, [props?.isVisible]);

  const deleteAppointmentAsync = async () => {
    if (!props?.propertyId || !props?.salonId) return;
    setIsLoading(true)
    try {
      await axios.post(
        '/graphql',
        {
          query: print(DeleteAppointmentDocument),
          variables: {
            input: {
              salonId: props?.salonId,
              id: props?.propertyId,
              isPermanentDeletion: true
            }
          },
        }
      ).then(({ data }) => {
        setIsLoading(false)
        const appointmentDetailsData = data?.data?.deleteAppointment;
        if (appointmentDetailsData?.status == 200) {
          closeCancelPaymentModal()
          props?.closeModal();
        } else {
          addToast({
            message: 'Kindly try again',
            variant: 'error',
          })
        }
      })
    } catch (deleteAppointmentError) {
      setIsLoading(false)
      if (axios.isAxiosError(deleteAppointmentError)) {
        addToast({
          message: 'Kindly try again',
          variant: 'error',
        })
      }
    }
  }

  const handleCancelPaymentModal = (action: string) => {
    if (action === 'cancel') {
      processCancellation()
    }
    closeCancelPaymentModal()
  }

  return (
    props?.isVisible ? (
      <>
        <ToastWrapper toast={toast as ToastProps} />
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
          <div className="w-full bg-white rounded-lg shadow-medium py-12 px-9 max-w-[500px]">
            <div className="w-full flex flex-col items-center space-y-6 overflow-auto">
              <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Make Payment</Heading>
              <iframe
                className="w-full h-[500px]"
                src={props?.paymentAuthorization?.authorizationUrl || ""}
              ></iframe>

              <div className='w-full flex flex-col lg:flex-row gap-4'>
                <Button
                  variant="secondary"
                  size='lg'
                  rounded='md'
                  fontSize='b5'
                  className='capitalize'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={openCancelPaymentModal}
                >
                  Cancel payment
                </Button>
              </div>
            </div>
          </div>
        </div>
        <CancelPaymentPrompt
          isVisible={isCancelPaymemtModal}
          isLoading={isLoading}
          closeModal={handleCancelPaymentModal}
          paymentBookingType={props?.propertyType}
        />
      </>
    ) : null
  )
}

export default PaymentModal