export const PERMISSION_CONSTANTS = {
  appointment: {
    add: 'AddAppointment',
    view: 'ViewAppointment',
    cancel: 'CancelAppointment'
  },
  sale: {
    add: 'AddSale',
    view: 'ViewSale',
    edit: 'EditSale',
  },
  client: {
    add: 'AddClient',
    view: 'ViewContactInfo',
    edit: 'ModifyClient',
    delete: 'DeleteClient',
    add_note: 'AddNote',
    view_note: 'ViewNotes',
    edit_note: 'ModifyNote',
    delete_note: 'DeleteNote',
    refund: 'RecordRefund',
    view_appointment_history: 'ViewAppointmentHistory',
  },
  inventory: {
    add: 'AddInventory',
    view: 'ViewInventory',
    edit: 'EditInventory',
    delete: 'DeleteInventory'
  },
  staff: {
    add: 'Staff::AddStaff',
    view: 'Staff::ViewStaff',
    edit: 'Staff::ModifyStaff',
    delete: 'Staff::DeleteStaff',
    activate_staff: 'Staff::ActivateStaff',
    deactivate_staff: 'Staff::DeactivateStaff',
    add_time_off: 'Staff::AddTimeOff',
    edit_time_off: 'Staff::ModifyTimeOff',
    delete_time_off: 'Staff::DeleteTimeOff',
    add_closed_period: 'Staff::AddClosedPeriod',
    delete_closed_period: 'Staff::ModifyClosedPeriod',
    edit_closed_period: 'Staff::ModifyClosedPeriod',
    schedule: "Staff::ManageStaffWorkingHours",
  },
  account: {
    setup: 'SetupProfile',
    locations: "Location::ViewAll",
  },
  service: {
    add: 'AddService',
    view: 'ViewService',
    edit: 'ModifyService',
    delete: 'DeleteService'
  },
  product: {
    add: 'AddProduct',
    view: 'ViewProduct',
    edit: 'ModifyProduct',
    delete: 'DeleteProduct',
    increase_stock: 'IncreaseStock',
    decrease_stock: 'DecreaseStock',
    import: 'ImportProducts'
  },
  voucher: {
    add: 'AddPackage',
    view: 'ViewPackage',
    edit: 'ModifyPackage',
    delete: 'DeletePackage'
  },
  money: {
    viewWallet: 'ViewWallet',
    viewPayment: 'ViewPaymentsTab',
    viewExpense: 'ViewExpense',
    addExpense: 'AddExpense',
    editExpense: 'ModifyExpense',
    withdraw: 'Withdraw'
  },
  settings: {
    editPersonalInfo: 'Settings::EditPersonalInfo',
    editBusinessInfo: 'Settings::EditBusinessInfo',
    changePassword: 'Settings::ChangePassword',
    addLocation: 'Settings::AddLocation',
    businessHours: 'Settings::ModifyBusinessHours',
    publicUrl: 'Settings::ChangePublicUrl',
    addFaq: 'Settings::AddFAQ',
    editFaq: 'Settings::ModifyFAQ',
    deleteFaq: 'Settings::DeleteFAQ',
    cancellationFees: 'Settings::AddModifyCancellationFees',
    noShowFees: 'Settings::AddModifyNoShowFees',
    addPos: 'Settings::AddPOSDevices',
    deletePos: 'Settings::DeletePOSDevices',
    addBankTransfers: 'Settings::AddBankAccounts',
    deleteBankTransfers: 'Settings::DeleteBankAccounts',
    processingFees: 'Settings::ModifyProcessingFees',
    taxes: 'Settings::ModifyTaxSettings',
    permissions: "Settings::ModifyPermission",
    addons: "Settings::ManageAddons",
    billing: "Settings::ManageBilling",
  },
  sales: {
    dailySales: 'Sales::ViewDailySales',
    appointmentList: 'Calendar::ViewAppointment',
    paymentTransactions: 'Sales::ViewPaymentTransactions',
    salesReport: 'Sales::ViewSalesReport',
    addSale: 'Sales::AddSale',
    addAppointment: 'Sales::AddAppointment',
    addVoucherSale: 'Sales::AddVoucherSale',
    voidSales: "Sales::VoidSale",
    editSales: "Sales::EditSale",
  },
  messaging: {
    sendMessage: 'Messaging::SendMessage',
    viewCustomList: 'Messaging::ViewCustomMessageList',
    viewSentMessages: 'Messaging::ViewSentMessages'
  },
  promos: {
    add: 'Promos::AddPromo',
    edit: 'Promos::ModifyPromo',
    delete: 'Promos::DeletePromo',
  },
  calendar: {
    addAppointment: 'Calendar::AddAppointment',
    addBlockTime: 'Staff::ManageAllBlockedTime',
    addOwnBlockTime: 'Calendar::ManageOwnBlockedTime',
    applyDiscounts: 'Calendar::ApplyDiscounts',
    applyPromo: 'Calendar::ApplyPromo',
    cancelAppointment: 'Calendar::CancelAppointment',
    revertCancellation: 'Calendar::RevertAppointmentCancellation',
    deleteAppointment: 'Calendar::DeleteAppointment',
    viewAppointment: 'Calendar::ViewAppointment',
    rescheduleAppointment: 'Calendar::RescheduleAppointment',
    markNoShow: 'Calendar::MarkNoShow',
    editBlockTime: 'Staff::ManageAllBlockedTime',
    editClosedPeriod: 'Calendar::ModifyClosedPeriod',
    checkInOrOut: "Home::CheckoutCheckinAppointment",
    editPrices: "Calendar::ModifyPriceAtCheckout",
    canViewOwnCalendar: "Calendar::ViewOwnCalendar",
    canViewAllCalendar: "Calendar::ViewAll",
    modifyAppointmennt: "Calendar::ModifyAppointment"
  },
  reports: {
    appointment: {
      cancelled: 'Reports::Appointments::ViewCancelledList',
      list: 'Reports::Appointments::ViewList',
      overview: 'Reports::Appointments::ViewOverview'
    },
    business: {
      exportBusiessPerformance: 'Reports::BusinessPerformance::ExportReports',
      customerSpend: "Reports::BusinessPerformance::ViewCustomerSpend",
      executiveSummary: "Reports::BusinessPerformance::ViewExecutiveSummary",
      expenseReport: "Reports::BusinessPerformance::ViewExpenseReport",
      monthlySummary: "Reports::BusinessPerformance::ViewMonthlySummary",
      reviews: "Reports::BusinessPerformance::ViewReviews"
    },
    client: {
      retentionList: 'Reports::Clients::ViewRetentionList',
      overview: 'Reports::Clients::ViewOverview'
    },
    inventory: {
      stockFlowr: 'Reports::Inventory::ViewStockFlow',
      stockMovementLog: 'Reports::Inventory::ViewStockMovementLog',
      stockOnHand: 'Reports::Inventory::ViewStockOnHand'
    },
    sales: {
      promoActivity: 'Reports::Sales::ViewPromoActivity',
      overview: 'Reports::Sales::ViewOverview',
      totalSales: 'Reports::Sales::ViewTotalSales',
      outstandingSales: 'Reports::Sales::ViewOutstandingSales'
    },
    staff: {
      roster: 'Reports::Staff::ViewRoster',
      appointmentSummary: 'Reports::Staff::ViewAppointmentSummary'
    },
    packages: {
      sales: 'Reports::Packages::ViewSales',
      redeeemedPackages: 'Reports::Packages::ViewRedeemPackages',
      overview: 'Reports::Packages::ViewOverview'
    },
  }
}