import * as React from "react";
import { ToastWrapperBaseProps } from "./types";
import { useEffect, useState } from "react";
import { ToastProps, ToastType } from "../../atoms/toast/types";
import { Toast } from "../../atoms/toast";

const Base = ({ children, toast, duration }: ToastWrapperBaseProps) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  useEffect(() => {
    if (toast) {
      const existingToast = toasts.find(
        (t) => t.message === toast.message && t.variant === toast.variant
      );
      if (!existingToast) {
        setToasts([...toasts, toast]);
      }
    }
  }, [toast]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toasts.length && toasts.length) {
        setToasts(toasts.slice(1));
      }
    }, duration);

    return () => {
      clearInterval(interval);
    };
  }, [toasts, duration]);

  const removeToast = (index: number) => {
    setToasts(toasts.filter((toast, i) => i !== index));
  };
  return (
    <div className="fixed top-5 mx-auto inset-x-0 flex flex-col items-center space-y-2 z-[999999999]">
      {Array.isArray(toasts) &&
        toasts.map((toast) => (
          <Toast
            variant={toast.variant as ToastType}
            onClick={() => removeToast(toasts.indexOf(toast))}
            key={toasts.indexOf(toast)}
          >
            {children}
          </Toast>
        ))}
    </div>
  );
};

export default Base;
