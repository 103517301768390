import * as React from 'react'
import { Layout } from '../ui'

const ComingSoon = () => {
  return (
    <Layout pageTitle='Coming Soon'>
      <div>ComingSoon</div>
    </Layout>
  )
}

export default ComingSoon