import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLoyaltyStepOne = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={16} cy={16} r={16} fill="#16796F" fillOpacity={0.1} />
    <path
      d="m22.871 17.817 1.802 3.123a.917.917 0 0 1-.646 1.364l-.103.01-.103-.001-2.689-.174-1.194 2.416a.916.916 0 0 1-1.474.238l-.075-.086-.066-.1-1.804-3.124a8.25 8.25 0 0 0 6.352-3.666Zm-7.394 3.666-1.802 3.124a.916.916 0 0 1-1.487.144l-.07-.092-.058-.104-1.195-2.415-2.687.174a.917.917 0 0 1-.937-1.183l.037-.098.046-.092 1.804-3.124a8.25 8.25 0 0 0 6.35 3.666ZM16 6.833l.22.004a6.416 6.416 0 0 1 6.197 6.413l-.003.177-.007.176-.016.224-.024.222-.022.163c-.06.396-.158.785-.29 1.162l-.107.283-.14.319a6.417 6.417 0 0 1-11.63-.026l-.12-.272-.048-.122-.073-.199-.087-.27a6.432 6.432 0 0 1-.085-.315l-.055-.248-.045-.249-.018-.127-.036-.296-.022-.335-.006-.267a6.417 6.417 0 0 1 6.197-6.413l.22-.004Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgLoyaltyStepOne;
