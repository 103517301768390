import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgInstagram = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={16} fill="#16796F" fillOpacity={0.1} />
    <path
      d="M16 12.395A3.6 3.6 0 0 0 12.395 16 3.6 3.6 0 0 0 16 19.605 3.6 3.6 0 0 0 19.605 16 3.6 3.6 0 0 0 16 12.395Zm0 5.948A2.348 2.348 0 0 1 13.657 16 2.348 2.348 0 0 1 16 13.657 2.348 2.348 0 0 1 18.343 16 2.348 2.348 0 0 1 16 18.343Zm3.753-6.936a.841.841 0 1 0 0 1.684.84.84 0 0 0 .778-1.164.84.84 0 0 0-.778-.52ZM23.028 16c0-.97.009-1.932-.046-2.9-.055-1.125-.311-2.124-1.134-2.947-.824-.824-1.82-1.079-2.946-1.133-.97-.055-1.932-.046-2.9-.046-.97 0-1.932-.009-2.9.046-1.126.054-2.124.31-2.947 1.133-.824.825-1.08 1.822-1.134 2.947-.054.97-.045 1.931-.045 2.9 0 .969-.01 1.932.045 2.9.055 1.125.312 2.124 1.134 2.947.825.824 1.821 1.079 2.946 1.133.97.055 1.932.046 2.9.046.971 0 1.933.009 2.901-.046 1.125-.054 2.123-.31 2.946-1.134.825-.824 1.08-1.82 1.134-2.946.056-.968.046-1.93.046-2.9Zm-1.547 4.145c-.129.32-.283.559-.531.805a2.25 2.25 0 0 1-.805.53c-.925.368-3.12.286-4.145.286-1.025 0-3.222.082-4.147-.283a2.234 2.234 0 0 1-.805-.531 2.251 2.251 0 0 1-.53-.805c-.366-.927-.284-3.122-.284-4.147 0-1.025-.082-3.222.283-4.147.129-.32.283-.559.531-.805.248-.246.485-.402.805-.53.925-.366 3.122-.284 4.147-.284 1.025 0 3.222-.082 4.147.283.32.129.559.283.805.531.248.248.402.485.53.805.366.925.284 3.122.284 4.147 0 1.025.082 3.22-.285 4.145Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgInstagram;
