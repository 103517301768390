import * as React from "react";
import BasePill from "./BasePill";
import { BasePillProps } from "./types";

const BlackPill = ({ className, style, children }: BasePillProps) => {
  return (
    <BasePill
      style={style}
      className={`flex text-center text-white bg-grey-900 ${className || ""}`}
    >
      {children}
    </BasePill>
  );
};

export default BlackPill;
