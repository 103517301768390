import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgEditRegular = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13.745 2.342a2.38 2.38 0 0 0-3.407-.044l-7.5 7.5a1.95 1.95 0 0 0-.525.956l-.702 3.16a.4.4 0 0 0 .477.477l3.142-.698c.373-.083.716-.272.987-.543l7.486-7.486a2.38 2.38 0 0 0 .042-3.322Zm-2.842.522a1.58 1.58 0 1 1 2.234 2.234l-.536.537-2.235-2.233.537-.538ZM9.801 3.968l2.234 2.234-6.384 6.384c-.163.163-.37.277-.595.327l-2.528.561.566-2.546c.048-.214.155-.409.31-.563L9.8 3.968Z"
      fill="#000"
    />
  </svg>
);
export default SvgEditRegular;
