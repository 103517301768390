import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFluentEdit = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.028 2.55a2.082 2.082 0 0 0-2.982-.038L2.484 9.074c-.23.23-.389.52-.46.836l-.613 2.765a.35.35 0 0 0 .417.418l2.749-.61c.327-.074.627-.238.864-.476l6.55-6.55a2.082 2.082 0 0 0 .037-2.907Zm-2.487.457a1.382 1.382 0 1 1 1.955 1.955l-.47.47-1.954-1.955.47-.47Zm-.964.966 1.955 1.954-5.586 5.586a1.063 1.063 0 0 1-.521.287l-2.212.491.496-2.228c.04-.187.135-.358.27-.493l5.598-5.597Z"
      fill="#667368"
    />
  </svg>
);
export default SvgFluentEdit;
