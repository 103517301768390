import * as React from "react";
import { TABLE_SHIMMER_DATA } from "./constants";
import { HeadingShimmer, TableCellShimmer } from "../..";

const TableSkeleton = ({
  showHeader = true,
  rowCount = TABLE_SHIMMER_DATA,
}) => {
  return (
    <div className="animate-pulse w-full flex flex-col">
      {showHeader ? (
        <div className="flex space-x-4 py-6 items-center justify-between">
          {[...Array(TABLE_SHIMMER_DATA)].map((_, index) => (
            <HeadingShimmer key={`hs${index}`} />
          ))}
        </div>
      ) : null}
      {[...Array(rowCount)].map((_, index) => (
        <TableCellShimmer key={`tcs${index}`} />
      ))}
    </div>
  );
};

export default TableSkeleton;
