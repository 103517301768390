import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFacebook = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={16} fill="#16796F" fillOpacity={0.1} />
    <path
      d="M17.5 17.125h1.875l.75-3H17.5v-1.5c0-.773 0-1.5 1.5-1.5h1.125v-2.52a21.126 21.126 0 0 0-2.143-.105c-2.036 0-3.482 1.243-3.482 3.525v2.1h-2.25v3h2.25V23.5h3v-6.375Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgFacebook;
