import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBiFilter = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7.857 16.143a.857.857 0 0 1 .857-.857h5.143a.857.857 0 1 1 0 1.714H8.714a.857.857 0 0 1-.857-.857ZM4.43 11a.857.857 0 0 1 .857-.857h12a.857.857 0 1 1 0 1.714h-12A.857.857 0 0 1 4.429 11ZM1 5.857A.857.857 0 0 1 1.857 5h18.857a.857.857 0 0 1 0 1.714H1.857A.857.857 0 0 1 1 5.857Z"
      fill="#667368"
    />
  </svg>
);
export default SvgBiFilter;
