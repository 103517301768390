import * as React from "react";

const Skeleton = ({
  width,
  height,
  className
}: {
  width: string
  height: string
  className?: string
}) => {
  return (
    <div
      className={`bg-slate-300 animate-pulse ${className || ''}`}
      style={{ width, height }}
    ></div>
  )
}

export default Skeleton