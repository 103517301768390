import React from 'react'
import { SvgIonTimeOutline, SvgSpliceFaintLogo } from '../../../ui/icons'
import { FormatNumber, Paragraph, Pill } from '../../../ui'
import { COLORS } from '../../../constants/colors'
import { CustomVoucherCardProps } from '../types'
import { DEFAULT_CURRENCY } from '../../../constants/currency'
import { convertDateTimeStringToTimeZone, formatSnakeCaseText, getNumberMonthAndYearFromDate } from '../../../utils/misc'

const PackageCustomerCard = (props: CustomVoucherCardProps) => {
  return (
    <div className="bg-[url('https://res.cloudinary.com/dqqrgidob/image/upload/v1722945105/am6lrwkmexijtjyrm3fr.png')] w-full max-w-[360px] bg-cover bg-center bg-no-repeat h-[100px] sm:h-[150px] cursor-pointer rounded-md" onClick={() => props?.onClick()}>
      <div className='w-full h-full flex justify-between py-5 px-6'>
        <div className='w-full max-w-[80%] md:max-w-[70%] flex flex-col justify-center sm:justify-between'>
          <div className='flex gap-2'>
            <div className='w-[14px] h-[14px] flex justify-center items-center rounded bg-grey-50'>
              <SvgIonTimeOutline width="10px" height="10px" />
            </div>
            <Paragraph size='b6' weight='normal' color={COLORS.WHITE} className='uppercase'>Expires {getNumberMonthAndYearFromDate(convertDateTimeStringToTimeZone(props?.voucher?.expiresAt, "NG") || "-")}</Paragraph>
          </div>
          <Paragraph size='b5' weight='normal' color={COLORS.WHITE}>{props?.voucher?.package?.name}</Paragraph>
          <Paragraph size='b5' weight='semiBold' className='uppercase' color={COLORS.WHITE}>{props?.voucher?.code}</Paragraph>
          <div className='flex space-x-4'>
            <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE}>{DEFAULT_CURRENCY || "₦"}<FormatNumber value={props?.voucher?.price} /></Paragraph>
            <Pill variant={ props?.voucher?.status === 'active' ? 'success' : props?.voucher?.status === 'redeemed' ? 'primary' : props?.voucher?.status === 'expired' ? 'light' : 'light' }>{formatSnakeCaseText(props?.voucher?.status || "")}</Pill>
          </div>
        </div>
        <div className='flex flex-col justify-center items-center w-[50px] md:w-[50px] space-y-1 md:space-y-3'>
          <SvgSpliceFaintLogo width="25px" height="35px" />
          <div className='flex flex-col gap-1'>
            <Paragraph size='b8' weight='normal' color={COLORS.WHITE} className='text-center'>{props?.voucher?.business?.name}</Paragraph>
            <Paragraph size='b9' weight='normal' color={COLORS.GREY[400]} className='text-center'>Powered by Splice</Paragraph>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PackageCustomerCard
