import * as React from "react";
import PlainToast from "./PlainToast";
import { TOAST_WRAPPER_VARIANT_TO_COMPONENT_MAPPING } from "./constants";
import { ToastWrapperProps } from "./types";

const ToastWrapper = ({ variant, ...props }: ToastWrapperProps) => {
  const ToastWrapperComponent =
    variant && TOAST_WRAPPER_VARIANT_TO_COMPONENT_MAPPING[variant]
      ? TOAST_WRAPPER_VARIANT_TO_COMPONENT_MAPPING[variant]
      : PlainToast;
  return (
    <React.Suspense fallback={null}>
      <ToastWrapperComponent {...props} />
    </React.Suspense>
  );
};

export default ToastWrapper;
