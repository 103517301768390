import React from 'react'
import TableSkeleton from '../../ui/organism/tableSkeleton/TableSkeleton'
import ParagraphShimmer from '../../ui/atoms/paragraph/ParagraphShimmer'

const CustomerDashboardShimmer = () => {
  return (
    <div className='w-full flex flex-col space-y-7'>
      <div className='w-full grid grid-cols-2 xl:grid-cols-4 gap-2 md:gap-8 border border-grey-50 md:border-0 rounded-md'>
        {[...Array(4)].map((_, index) => (
          <div className={`flex bg-slate-50 border rounded-md p-3 space-x-3 h-[135px] cursor-pointer`} key={`tcs${index}`}>
            <div className='flex flex-col space-y-4'>
              <ParagraphShimmer size="md" />
              <ParagraphShimmer size="lg" />
            </div>
          </div>
        ))}
      </div>

      <div className='flex flex-col xl:flex-row px-5 py-4'>
        <TableSkeleton />
      </div>
    </div>
  )
}

export default CustomerDashboardShimmer