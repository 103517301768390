import * as React from "react";
import { TABLE_CELL_SHIMMER_DATA } from "./constants";
import { PARAGRAPH_SHIMMER_SIZES } from "../../atoms/paragraph/constants";
import { ParagraphShimmer } from "../..";

const TableCellShimmer = () => {
  const size = "md";
  const widthClass = PARAGRAPH_SHIMMER_SIZES["md"];

  return (
    <div className="flex justify-between border-t border-grey-100 py-6">
      {[...Array(TABLE_CELL_SHIMMER_DATA)].map((_, index) => {
        return (
          <div key={index}>
            <div className={`w-${widthClass} flex justify-end`}>
              <ParagraphShimmer size={size} key={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TableCellShimmer;
