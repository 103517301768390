import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTiktok = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={16} fill="#16796F" fillOpacity={0.1} />
    <path
      d="M21.856 10.189a.285.285 0 0 0-.259-.029c-.72.31-1.468.085-1.9-.478a1.495 1.495 0 0 1-.316-.9.253.253 0 0 0-.086-.198.264.264 0 0 0-.202-.084h-2.935c-.172 0-.288.113-.288.281V18.828a.965.965 0 0 1-.978.873c-.547 0-1.007-.45-1.007-.985 0-.507.374-.9.892-.957.144-.028.259-.14.259-.282v-3.264c0-.169-.115-.281-.288-.281h-.057C12.043 14.043 10 16.127 10 18.715c0 2.645 2.187 4.784 4.892 4.784 2.532 0 4.662-1.914 4.863-4.362.03-.028.03-.085.03-.113v-5.431c.632.14 1.294.197 1.985.084a.275.275 0 0 0 .23-.281v-2.983c0-.085-.058-.17-.144-.225Zm-3.942 3.039c-.662.056 2.244-.056 1.64-.253-.086-.028-.173 0-.259.028a.254.254 0 0 0-.115.225v5.685c0 .028 0 .056-.029.084-.144 2.195-2.014 3.152-4.259 3.152-2.388 0 1.007 1.717 1.007-.619 0-2.195-6.877-2.505-4.661-2.702l-.864-.112c-.662.197 3.51 2.364 3.51 3.04 0 .843 1.557.933 2.42.933.777 0 2.99-2.228 2.738-2.988.03-.028 1.26-.929.34-1.21l-.087-4.813-.253-4.615h-.208c.058.366-.69 2.251-.489 2.533.49.647-.546 1.041.288.844l-.72.788Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgTiktok;
