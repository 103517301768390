import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgExternal = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20.833 19.792a.618.618 0 0 1-.441-.184.629.629 0 0 1 0-.883l6.833-6.833a.629.629 0 0 1 .883 0 .629.629 0 0 1 0 .883l-6.833 6.833a.618.618 0 0 1-.442.184Z"
      fill="#150326"
    />
    <path
      d="M28.333 16.292a.63.63 0 0 1-.625-.625v-3.375h-3.375a.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625h4a.63.63 0 0 1 .625.625v4a.63.63 0 0 1-.625.625ZM22.5 28.958h-5c-4.525 0-6.458-1.933-6.458-6.458v-5c0-4.525 1.933-6.458 6.458-6.458h1.667a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625H17.5c-3.842 0-5.208 1.366-5.208 5.208v5c0 3.842 1.366 5.208 5.208 5.208h5c3.842 0 5.208-1.366 5.208-5.208v-1.667a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625V22.5c0 4.525-1.933 6.458-6.458 6.458Z"
      fill="#150326"
    />
    <rect x={0.5} y={0.5} width={39} height={39} rx={19.5} stroke="#F2F2F4" />
  </svg>
);

export default SvgExternal;
