import * as React from "react";
const MobileBackgroundModal = React.lazy(() => import("./MobileBackgroundModal"));
const TopCenter = React.lazy(() => import("./TopCenter"));
const PopOver = React.lazy(() => import("./PopOver"));
const Right = React.lazy(() => import("./Right"));

export const MODAL_VARIANT_TO_COMPONENT_MAPPING = {
  topCenter: TopCenter,
  popOver: PopOver,
  right: Right,
  mobileCenter: MobileBackgroundModal
} as const;