import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Appointment, CreateReviewDocument } from '../graphql/generated';
import { ReviewAppointmentInput } from '../uicomponents/types';
import SvgReviewDash from '../ui/icons/ReviewDash';
import AddRating from '../ui/atoms/rating/AddRating';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import Heading from '../ui/atoms/heading/Heading';
import { convertAppointmentServicesNamesToString, convertAppointmentServicesToStaffString } from '../uicomponents/bookingSales/utils';
import { getNumberMonthAndYearFromDate } from '../utils/misc';
import { COLORS } from '../constants/colors';
import { ToastProps } from '../ui/atoms/toast/types';
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper';
import { API_ERRORS } from '../constants/errors';
import { useToast } from '../hooks/useToast';
import { fetchAppointmentSummaryByBookingId } from '../uicomponents/appointmentComponents/api';
import TextInput from '../ui/molecules/input/TextInput';
import Button from '../ui/atoms/button/Button';
import SuccessFeedBackModal from '../uicomponents/SuccessFeedBackModal';
import { Logo } from '../ui/atoms/logo';
import { print } from 'graphql';
import { APPOINTMENT_STATUS } from '../uicomponents/bookingSales/constants';

const Review = () => {
  const { toast, addToast } = useToast();
  const [rating, setRating] = useState<number>(0);
  const [saveReviewIsLoading, setSaveReviewIsLoading] = useState<boolean>(false);
  // Get the current URL
  const currentUrl = window.location.href;

  // Split the URL using '/' as the delimiter and get the last part
  const parts = currentUrl.split('/');
  const bookingId = parts[parts.length - 1];

  const getAppointmentSummaryAsync = async () => {
    const res = await fetchAppointmentSummaryByBookingId({
      bookingId
    })
    if (res?.appointment) {
      setAppointment(res?.appointment)
      if (Array.isArray(res?.appointment?.reviews) && res?.appointment?.reviews?.length) {
        setAppointmentHasBeenReviewed(true);
        setRating(res?.appointment?.reviews[0]?.stars || 0)
      }
    }
  }

  useEffect(() => {
    if (bookingId) {
      // Fetch the appointment details from the server using the booking ID
      // and update the appointment state accordingly
      getAppointmentSummaryAsync()
    }
  }, [bookingId])

  const [appointmentHasBeenReviewed, setAppointmentHasBeenReviewed] = useState(false);
  const {
    control,
    handleSubmit,
  } = useForm<ReviewAppointmentInput>({});
  const [appointment, setAppointment] = useState<Appointment | null>(null)

  const onSubmit = async (input: ReviewAppointmentInput) => {
    try {
      setSaveReviewIsLoading(true)
      await axios.post(
        '/graphql',
        {
          query: print(CreateReviewDocument), variables: {
            input: {
              message: input?.comment || null,
              stars: rating,
              appointmentId: appointment?.id,
              salonId: appointment?.salon?.id,
              clientId: appointment?.clientId,
          }
        } },
      ).then(({ data }) => {
        setSaveReviewIsLoading(false)
        if (data?.data?.createReview.review) {
          setAppointmentHasBeenReviewed(true);
          addToast && addToast({
            message: 'Review created successfully',
            variant: 'success',
          })
        }

        if (data?.data?.createReview.errors?.length) {
          addToast && addToast({
            message: data?.data?.createReview.errors[0].message || API_ERRORS.APPOINTMENT_REVIEW_CREATE_FAILED,
            variant: 'error',
          })
        }

        if (data?.errors?.length) {
          addToast && addToast({
            message: data?.errors[0].message || API_ERRORS.APPOINTMENT_REVIEW_CREATE_FAILED,
            variant: 'error',
          })
        }
      })
    } catch (createReviewError) {
      setSaveReviewIsLoading(false)
      if (axios.isAxiosError(createReviewError)) {
        const message = createReviewError?.response?.data?.message || API_ERRORS.APPOINTMENT_REVIEW_CREATE_FAILED;
        addToast && addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const handleRatingChange = (newRating: number) => {
    setRating(newRating);
  };
  return (
    <>
      <main className='w-full flex bg-grey-100 h-screen p-0 xl:py-[49px] xl:px-0 justify-center'>
        <ToastWrapper toast={toast as ToastProps} />
        {appointment && appointment?.appointmentStatus === APPOINTMENT_STATUS.completed ? (
          <div className='w-full max-w-[490px] p-[48px] bg-white h-full flex flex-col items-center space-y-5 rounded-md overflow-y-auto'>
            {appointment?.salon?.business?.logoUrl ? (
              <img
                src={appointment?.salon?.business?.logoUrl}
                alt="business logo"
                className="w-full max-w-[140px]"
                loading="lazy"
              />
            ) : (
              <Logo />
            )}
            <div className='space-y-2 flex flex-col items-center'>
              <Heading variant='h1' size='h8' weight='bold'>{appointment?.salon?.business?.name}</Heading>
              <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{appointment?.address}</Paragraph>
            </div>
            <div className='w-full border-t border-grey-100'></div>
            <Heading variant='h2' size='h8' weight='bold'>Leave a review</Heading>
            <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Thank you for your recent visit. </Paragraph>
            <div className='w-full flex flex-col space-y-4'>
              <div className='w-full flex space-x-8 justify-between items-start'>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Service</Paragraph>
                <Paragraph size='b5' weight='normal' className='my-0 text-right'>{convertAppointmentServicesNamesToString(appointment?.appointmentServices)}</Paragraph>
              </div>
              <div className='w-full flex space-x-8 justify-between items-start'>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Staff</Paragraph>
                <Paragraph size='b5' weight='normal' className='my-0'>{convertAppointmentServicesToStaffString(appointment?.appointmentServices)}</Paragraph>
              </div>
              <div className='w-full flex space-x-8 justify-between items-start'>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Date</Paragraph>
                <Paragraph size='b5' weight='normal' className='my-0'>{getNumberMonthAndYearFromDate(appointment?.startAt)}</Paragraph>
              </div>
            </div>
            <div className='w-full flex flex-col space-y-4 items-center justify-center'>
              {rating > 0 ? (
                <Heading variant='h3' size='h3' weight='bold' className='text-center'>{rating}</Heading>
              ) : (
                <SvgReviewDash width="30px" height="30px" />
              )}
              <AddRating initialRating={rating} handleRatingChange={handleRatingChange} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-4" autoComplete='off'>
              <TextInput name="comment" label="Comment" id="comment" type="text" placeholder='Drop a comment' control={control} />
              <Button
                variant='primary'
                className=''
                disabled={rating === 0 || saveReviewIsLoading}
                loading={saveReviewIsLoading}
                size='lg'
                rounded='lg'
              >
                Submit
              </Button>
            </form>
            <div className='flex justify-center items-end space-x-2'>
              <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Powered by</Paragraph> <Logo className='max-w-[60px]' />
            </div>
          </div>
        ) : null}
      </main>
      {appointmentHasBeenReviewed && (<SuccessFeedBackModal />)}
    </>
  )
}

export default Review;