import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSolarGiftLinear = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M22 12H2M12 2v20m1-10a4 4 0 0 0 4 4m-6-4a4 4 0 0 1-4 4"
      stroke="#FFF"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M12 10.036a3.247 3.247 0 0 1 2.46-3.15c1.604-.4 3.057 1.051 2.656 2.655a3.247 3.247 0 0 1-3.15 2.46H12m0-1.965v1.966m0-1.966a3.246 3.246 0 0 0-2.46-3.15c-1.603-.4-3.056 1.051-2.655 2.655a3.247 3.247 0 0 0 3.15 2.46H12"
      stroke="#FFF"
      strokeWidth={1.5}
    />
    <path
      d="M2 12c0-4.714 0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12Z"
      stroke="#FFF"
      strokeWidth={1.5}
    />
  </svg>
);
export default SvgSolarGiftLinear;
