import { useMemo } from "react";
import { VARIANT_TO_BORDER_COLOR_CLASS_NAME } from "./constants";
import { UseHelperTextProps } from "./types";

const useHelperText = ({ helperText }: UseHelperTextProps) => {
  const helperTexts = useMemo(() => {
    if (Array.isArray(helperText)) {
      return helperText
    }

    if (helperText) {
      return [helperText]
    }

    return []
  }, [helperText])

  const borderColorClassName =
    VARIANT_TO_BORDER_COLOR_CLASS_NAME[helperTexts[0]?.variant] || ''

  return {
    helperTexts,
    borderColorClassName
  }
}

export default useHelperText
