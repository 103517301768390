import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSale = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#sale_svg__a)">
      <path
        d="m6.909 6.909-6-6m21.805 15.137-6.668 6.668a1.285 1.285 0 0 1-1.817 0l-11.16-11.16a.617.617 0 0 1-.189-.497l1.012-6.583a.634.634 0 0 1 .582-.583l6.583-1.011a.617.617 0 0 1 .497.189l11.16 11.16a1.285 1.285 0 0 1 0 1.817Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="sale_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSale;
