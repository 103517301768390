import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDate = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={26} height={26} rx={4} fill="#F3F6F6" />
    <g clipPath="url(#date_svg__a)">
      <path
        d="M18.25 12.788a4.09 4.09 0 0 1 1.285 1.415c.155.283.269.581.342.896.073.314.114.635.123.963a3.893 3.893 0 0 1-1.155 2.782c-.36.356-.777.636-1.251.841a3.948 3.948 0 0 1-2.734.13 3.772 3.772 0 0 1-1.067-.533 4.144 4.144 0 0 1-.861-.834 3.765 3.765 0 0 1-.581-1.073H6.875v-10.5h1.75V6H9.5v.875h6.125V6h.875v.875h1.75v5.913ZM7.75 7.75V9.5h9.625V7.75H16.5v.875h-.875V7.75H9.5v.875h-.875V7.75H7.75Zm4.396 8.75a4.533 4.533 0 0 1-.021-.438c0-.391.055-.772.164-1.141.11-.37.276-.718.5-1.046h-.664V13H13v.588c.187-.232.394-.438.622-.615.228-.178.474-.33.738-.458.265-.128.54-.224.828-.288.287-.063.578-.097.874-.102.456 0 .894.075 1.313.226v-1.976H7.75V16.5h4.396Zm3.916 2.625c.424 0 .82-.08 1.19-.24a3.095 3.095 0 0 0 1.627-1.626 3.068 3.068 0 0 0 .007-2.386 3.095 3.095 0 0 0-1.627-1.627 3.068 3.068 0 0 0-2.386-.007 3.095 3.095 0 0 0-1.627 1.627 3.068 3.068 0 0 0-.007 2.386 3.095 3.095 0 0 0 1.627 1.627c.37.16.768.241 1.197.246Zm.438-3.5h1.313v.875h-2.188v-2.625h.875v1.75ZM8.625 13H9.5v.875h-.875V13Zm1.75 0h.875v.875h-.875V13Zm0-1.75h.875v.875h-.875v-.875Zm-1.75 3.5H9.5v.875h-.875v-.875Zm1.75 0h.875v.875h-.875v-.875ZM13 12.125h-.875v-.875H13v.875Zm1.75 0h-.875v-.875h.875v.875Zm1.75 0h-.875v-.875h.875v.875Z"
        fill="#667368"
      />
    </g>
    <defs>
      <clipPath id="date_svg__a">
        <path fill="#fff" transform="translate(6 6)" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDate;
